/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { AlertRefDto, AlertRefDtoFromJSON, AlertRefDtoFromJSONTyped, AlertRefDtoToJSON } from "./"

/**
 *
 * @export
 * @interface AlertRefListDto
 */
export interface AlertRefListDto {
  /**
   * List of alerts refs
   * @type {Array<AlertRefDto>}
   * @memberof AlertRefListDto
   */
  alertRefs: Array<AlertRefDto>
}

export function AlertRefListDtoFromJSON(json: any): AlertRefListDto {
  return AlertRefListDtoFromJSONTyped(json, false)
}

export function AlertRefListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRefListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    alertRefs: (json["alertRefs"] as Array<any>).map(AlertRefDtoFromJSON),
  }
}

export function AlertRefListDtoToJSON(value?: AlertRefListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    alertRefs: (value.alertRefs as Array<any>).map(AlertRefDtoToJSON),
  }
}
