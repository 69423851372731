import React from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useApiClient } from "../../state/api"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import {
  createTextColumnConfig,
  createNumericColumnConfig,
  fetchAndAddSourcesToRows,
} from "../../component/MaterialTable/material-table-helpers"
import {
  extractColumnConfigAndExportConfig,
  createDateColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import { MaterialTable } from "component/MaterialTable"
import { useHistory } from "react-router"
import { AppRoute } from "../../state/route"
import { buildUrl } from "../../component/AppLink"
import { SerieSyncTask } from "../../api/timelight-api/models/SerieSyncTask"
import { formatDuration } from "../../lib/date"
import { createTaskStatusColumnConfig } from "../../component/TaskStatusActivity"
import AddIcon from "@material-ui/icons/Add"
import AppLink from "../../component/AppLink/index"
import { AppButton } from "../../component/AppButton"
import {
  RowWithSource,
  createTypeORMCrudPage,
  createTypeORMCrudLimit,
  createTypeORMCrudSort,
  createTypeORMCrudFilter,
} from "../../component/MaterialTable/material-table-helpers"
import { isTaskSuccessfullyEnded } from "../../state/tasksStream"
import { EmptyStateRedirect } from "../../component/EmptyStateRedirect"
import {
  createSourceIdColumnConfig,
  Query,
  createTableExportCsv,
} from "../../component/MaterialTable/material-table-helpers"
import { QueryResult } from "material-table"

export function SerieSyncTaskList() {
  return (
    <PageContainer title="Synchronisation de séries">
      <AppHeader>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Synchronisation de séries
          </Typography>

          <AppLink route={AppRoute.SERIE_SYNC_TASK_CREATE}>
            <AppButton variant="outlined" style={{ color: "white", borderColor: "white" }}>
              <AddIcon /> Nouvelle modélisation
            </AppButton>
          </AppLink>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SerieSyncTaskTable />
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const SerieSyncTaskTable = React.memo(function SerieSyncTaskTableComponent() {
  const history = useHistory()
  const api = useApiClient()
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false)

  type RowType = RowWithSource<RowWithSource<SerieSyncTask, "toSyncSource">, "refSource">

  const { columns, exportConfig } = extractColumnConfigAndExportConfig<RowType>([
    createTextColumnConfig({
      title: "Nom",
      getTextValue: (row) => row.title,
      field: "title",
    }),
    createTextColumnConfig({
      title: "Durée d'exécution",
      getTextValue: (row) => formatDuration(row.durationMs),
    }),
    createSourceIdColumnConfig({
      title: "Source de référence",
      getTextValue: (row) => row.refSource.name,
      field: "refSourceId",
    }),
    createSourceIdColumnConfig({
      title: "Source synchronisée",
      getTextValue: (row) => row.toSyncSource.name,
      field: "toSyncSourceId",
    }),
    createNumericColumnConfig({
      title: "Indice de corrélation Pearson global",
      getValue: (row) => row?.globalPearsonValue,
      field: "globalPearsonValue",
    }),
    createTextColumnConfig({
      title: "Décalage global",
      getTextValue: (row) => (isTaskSuccessfullyEnded(row) ? formatDuration(row.globalOffsetMs) : undefined),
    }),
    createDateColumnConfig({
      title: "Début de synchronisation",
      getDate: (row) => row.trainBegin,
      field: "trainBegin",
    }),
    createDateColumnConfig({
      title: "Fin de synchronisation",
      getDate: (row) => row.trainEnd,
      field: "trainEnd",
    }),
    createTaskStatusColumnConfig<RowType>(),
  ])

  async function getData(query: Query<RowType>): Promise<QueryResult<RowType>> {
    const res = await api.getManyBaseSyncTaskControllerSerieSyncTask({
      page: createTypeORMCrudPage(query),
      limit: createTypeORMCrudLimit(query),
      sort: createTypeORMCrudSort(query),
      filter: createTypeORMCrudFilter(query),
    })
    if (query.filters.length === 0 && query.search.length === 0 && res.count === 0) {
      setIsEmpty(true)
    }
    return {
      page: query.page,
      totalCount: res.total,
      data: await fetchAndAddSourcesToRows(
        api,
        await fetchAndAddSourcesToRows(api, res.data, (row) => row.refSourceId, "refSource"),
        (row) => row.toSyncSourceId,
        "toSyncSource",
      ),
    }
  }
  return (
    <>
      {isEmpty ? (
        <EmptyStateRedirect
          route={AppRoute.SERIE_SYNC_TASK_CREATE}
          actionText={"Créer une première modélisation"}
          headerText={"Bienvenue dans le module de synchronisation de séries"}
          helpText={`Ce module vous permet d'analyser le décalage entre des séries similaires.`}
          examplePicturePublicPath={"/examples/serie_sync_example.png"}
        />
      ) : (
        <PageBlock titleInContent={true}>
          <MaterialTable
            title={`Historique des synchronisation de séries`}
            data={getData}
            onRowClick={(row) => {
              if (row) {
                history.push(
                  buildUrl({
                    route: AppRoute.SERIE_SYNC_TASK_VIEW,
                    params: { taskId: row.id + "" },
                  }),
                )
              }
            }}
            columns={columns}
            options={{
              search: false,
              filtering: true,
              exportCsv: createTableExportCsv({
                exportConfig,
                fileName: `serie_sync_tasks.csv`,
                getData,
              }),
            }}
          />
        </PageBlock>
      )}
    </>
  )
})
