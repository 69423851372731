/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  AlertDto,
  AlertDtoFromJSON,
  AlertDtoFromJSONTyped,
  AlertDtoToJSON,
  PrevisionDto,
  PrevisionDtoFromJSON,
  PrevisionDtoFromJSONTyped,
  PrevisionDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface PrevisionUpdateResultDto
 */
export interface PrevisionUpdateResultDto {
  /**
   *
   * @type {PrevisionDto}
   * @memberof PrevisionUpdateResultDto
   */
  prevision: PrevisionDto
  /**
   * List of newly created alerts
   * @type {Array<AlertDto>}
   * @memberof PrevisionUpdateResultDto
   */
  alerts: Array<AlertDto>
}

export function PrevisionUpdateResultDtoFromJSON(json: any): PrevisionUpdateResultDto {
  return PrevisionUpdateResultDtoFromJSONTyped(json, false)
}

export function PrevisionUpdateResultDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PrevisionUpdateResultDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    prevision: PrevisionDtoFromJSON(json["prevision"]),
    alerts: (json["alerts"] as Array<any>).map(AlertDtoFromJSON),
  }
}

export function PrevisionUpdateResultDtoToJSON(value?: PrevisionUpdateResultDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    prevision: PrevisionDtoToJSON(value.prevision),
    alerts: (value.alerts as Array<any>).map(AlertDtoToJSON),
  }
}
