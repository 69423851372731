import Loader from "component/Loader"
import * as lodash from "lodash"
import React from "react"
import PageBlock from "./PageBlock"

export default function LoaderBlock({
  message = "Analyse en cours",
  helpText,
}: {
  message?: string
  helpText?: string
}) {
  const images = ["/undraw/undraw_detailed_analysis_xn7y.svg", "/undraw/undraw_in_progress_ql66.svg"]
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PageBlock
        title={
          <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span style={{ marginRight: "1em" }}>{message}</span>
            <Loader />
          </span>
        }
      >
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <img style={{ maxWidth: "400px" }} alt={message} src={lodash.sample(images)} />

          {helpText && <p style={{ fontSize: "1.3em" }}>{helpText}</p>}
        </div>
      </PageBlock>
    </div>
  )
}
