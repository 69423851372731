// tslint:disable:max-line-length
import { ButtonGroup } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import CalendarIcon from "@material-ui/icons/CalendarToday"
import GridIcon from "@material-ui/icons/GridOn"
import React from "react"
import ListSelect from "../../component/ListSelect"
import { useLocalStorage } from "../../state/localStorage"
import { CalendarContentConfig } from "./CalendarData"
import DayGridYearCalendar from "./DayGridYearCalendar"
import DayListYearCalendar from "./DayListYearCalendar"

import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { createStrIdMap } from "../../lib/array"
import { allDaysInYear } from "../../lib/date"
import { DATA_NOT_PROVIDED_MODEL_ID } from "../ChartDayActivity"
import { AlertYearCalendarProps, CalendarData } from "./CalendarData"

const useStyle = makeStyles({
  disabledButton: {
    boxShadow: "-1px 1px 10px -1px rgba(0, 0, 0, 0.3) inset",
  },
})

export default function DayYearCalendar({
  alerts,
  alertRefs,
  models,
  setSelectedAlert,
  setSelectedAlertRef,
  setSelectedDay,
  days,
  ...props
}: AlertYearCalendarProps) {
  const classes = useStyle()
  const [calStyle, setCalStyle] = useLocalStorage<"grid" | "list">("calendar-style-switch-alert", "list")

  const [calContent, setCalContent] = useLocalStorage<CalendarContentConfig>(
    "calendar-content-switch-alert",
    "activity",
  )

  const options: {
    value: CalendarContentConfig
    label: string
  }[] = [
    { label: "criticité", value: "activity" },
    { label: "écart de profil type", value: "model" },
  ]

  const alertsByDate = createStrIdMap(alerts, (a) => a.date)
  const alertRefsByDate = createStrIdMap(alertRefs, (a) => a.date)
  const daysByDate = createStrIdMap(days, (d) => d.date)
  const calendarDays =
    calContent === "model"
      ? allDaysInYear(props.year)
          .filter((date) => !!daysByDate[date])
          .map((date) =>
            // alert with a better model: highlight
            alertsByDate[date] && alertsByDate[date].better_model_id
              ? {
                  date,
                  anomaly: false,
                  model_id: alertsByDate[date].better_model_id || DATA_NOT_PROVIDED_MODEL_ID,
                  value: alertsByDate[date].criticity,
                }
              : // alert ref with a better model: highlight in transparent
              alertRefsByDate[date] && alertRefsByDate[date].betterModelColor
              ? {
                  date,
                  anomaly: false,
                  transparent: true,
                  model_id: -alertRefsByDate[date].id,
                  value: alertRefsByDate[date].betterModelValue || 0,
                }
              : // alert: just blank
              alertsByDate[date]
              ? {
                  date,
                  anomaly: false,
                  model_id: 0,
                  value: alertsByDate[date].criticity,
                }
              : // alert ref: just blank
              alertRefsByDate[date]
              ? {
                  date,
                  anomaly: false,
                  model_id: 0,
                  value: alertRefsByDate[date].criticity,
                }
              : // no alert, make it empty
                {
                  date,
                  anomaly: false,
                  empty: true,
                  model_id: 0,
                  value: 0,
                },
          )
      : allDaysInYear(props.year)
          .filter((date) => !!daysByDate[date])
          .map((date) =>
            alertsByDate[date]
              ? {
                  date,
                  anomaly: false,
                  model_id: alertsByDate[date].better_model_id || DATA_NOT_PROVIDED_MODEL_ID,
                  value: alertsByDate[date].criticity,
                }
              : alertRefsByDate[date]
              ? {
                  date,
                  anomaly: false,
                  transparent: true,
                  model_id: -alertRefsByDate[date].id,
                  value: alertRefsByDate[date].betterModelValue || 0,
                }
              : {
                  date,
                  anomaly: false,
                  empty: true,
                  model_id: 0,
                  value: 0,
                },
          )

  const calendarModels = [
    ...models,
    ...alertRefs
      .filter((ar) => ar.betterModelColor)
      .map((ar) => ({ color: ar.betterModelColor || "#CCCCCC", id: -ar.id })),
  ]

  function calendarSetSelectedDay(day: CalendarData) {
    if (alertsByDate[day.date]) {
      setSelectedAlert(alertsByDate[day.date] || null)
    } else if (alertRefsByDate[day.date]) {
      setSelectedAlertRef(alertRefsByDate[day.date] || null)
    } else if (daysByDate[day.date]) {
      setSelectedDay(daysByDate[day.date] || null)
    }
  }

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <p style={{ marginRight: 10 }}>Affichage du calendrier par </p>
          <ListSelect<{
            value: CalendarContentConfig
            label: string
          }>
            value={options.find((o) => o.value === calContent)}
            items={options}
            onSelect={(val) => setCalContent(val.value)}
            getValue={(o) => o.value}
            getLabel={(o) => o.label}
          />
        </div>
        <ButtonGroup size="small" aria-label="small outlined button group">
          <Button
            className={clsx(calStyle === "grid" && classes.disabledButton)}
            disabled={calStyle === "grid"}
            onClick={() => setCalStyle("grid")}
          >
            <GridIcon />
          </Button>
          <Button
            className={clsx(calStyle === "list" && classes.disabledButton)}
            disabled={calStyle === "list"}
            onClick={() => setCalStyle("list")}
          >
            <CalendarIcon />
          </Button>
        </ButtonGroup>
      </div>
      {calStyle === "list" && (
        <DayListYearCalendar
          content={calContent}
          days={calendarDays}
          setSelectedDay={calendarSetSelectedDay}
          models={calendarModels}
          {...props}
        />
      )}
      {calStyle === "grid" && (
        <DayGridYearCalendar
          content={calContent}
          days={calendarDays}
          setSelectedDay={calendarSetSelectedDay}
          models={calendarModels}
          {...props}
        />
      )}
    </div>
  )
}
