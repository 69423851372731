/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  ContextTypeDateDomainDto,
  ContextTypeDateDomainDtoFromJSON,
  ContextTypeDateDomainDtoFromJSONTyped,
  ContextTypeDateDomainDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface ContextTypeDateDomainListDto
 */
export interface ContextTypeDateDomainListDto {
  /**
   *
   * @type {Array<ContextTypeDateDomainDto>}
   * @memberof ContextTypeDateDomainListDto
   */
  domains: Array<ContextTypeDateDomainDto>
}

export function ContextTypeDateDomainListDtoFromJSON(json: any): ContextTypeDateDomainListDto {
  return ContextTypeDateDomainListDtoFromJSONTyped(json, false)
}

export function ContextTypeDateDomainListDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContextTypeDateDomainListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    domains: (json["domains"] as Array<any>).map(ContextTypeDateDomainDtoFromJSON),
  }
}

export function ContextTypeDateDomainListDtoToJSON(value?: ContextTypeDateDomainListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    domains: (value.domains as Array<any>).map(ContextTypeDateDomainDtoToJSON),
  }
}
