import { useState } from "react"
import { useLocalStorage } from "./localStorage"

export function useToggle(init: boolean) {
  const [open, setOpen] = useState(init)

  return {
    open,
    doOpen: () => setOpen(true),
    doClose: () => setOpen(false),
    toggle: () => setOpen(!open),
  }
}

export function usePersistedToggle(key: string, initialValue: boolean) {
  const [open, setOpen] = useLocalStorage("persisted-toggle-" + key, initialValue)
  return {
    open,
    doOpen: () => setOpen(true),
    doClose: () => setOpen(false),
    toggle: () => setOpen(!open),
  }
}
