/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { PrevisionDto, PrevisionDtoFromJSON, PrevisionDtoFromJSONTyped, PrevisionDtoToJSON } from "./"

/**
 *
 * @export
 * @interface PrevisionListDto
 */
export interface PrevisionListDto {
  /**
   * List of previsions
   * @type {Array<PrevisionDto>}
   * @memberof PrevisionListDto
   */
  previsions: Array<PrevisionDto>
  /**
   * If true, those previsions are user saved previsions and can be updated.If false, you can not update previsions, you have to save them first
   * @type {boolean}
   * @memberof PrevisionListDto
   */
  hasSavedPrevisions: boolean
}

export function PrevisionListDtoFromJSON(json: any): PrevisionListDto {
  return PrevisionListDtoFromJSONTyped(json, false)
}

export function PrevisionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrevisionListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    previsions: (json["previsions"] as Array<any>).map(PrevisionDtoFromJSON),
    hasSavedPrevisions: json["hasSavedPrevisions"],
  }
}

export function PrevisionListDtoToJSON(value?: PrevisionListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    previsions: (value.previsions as Array<any>).map(PrevisionDtoToJSON),
    hasSavedPrevisions: value.hasSavedPrevisions,
  }
}
