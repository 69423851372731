import React from "react"
import { useApiClient } from "./api"
import { AsyncHookParams, useAsyncAction } from "./async"
import { useAlertOnReadOnly, useLogoutOnError, useAuth } from "./auth"
import { Redirect } from "react-router"
import { AnyComponent, AnyChildren } from "../react-type-helpers"
import { useAppRoute, AppRoute } from "./route"
import Loader from "component/Loader"
import { buildUrl } from "../component/AppLink"

export function useCurrentUser(initialParams: AsyncHookParams<{}>) {
  const api = useApiClient()
  const res = useAsyncAction(() => api.userControllerMe(), initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function withAuthRedirects(Component: AnyComponent<{}>) {
  // create a new component, wrapping the Component
  return () => {
    // make the route context available to this component
    return (
      <AuthRedirect>
        <Component />
      </AuthRedirect>
    )
  }
}

const publicRoutes = [AppRoute.LOGIN, AppRoute.REGISTER, AppRoute.AUTH_ERROR]
function AuthRedirect({ children }: { children: AnyChildren }) {
  const { isAuthenticated } = useAuth()

  // extract the route
  const currentRoute = useAppRoute()

  // if logged in and in login route, redirect to home
  if (isAuthenticated && publicRoutes.includes(currentRoute.route)) {
    return <Redirect to={AppRoute.HOME} />
  }
  // if not logged in and route is not login, redirect to login
  if (!isAuthenticated && !publicRoutes.includes(currentRoute.route)) {
    // force the user to reload the page to avoid caching issues
    setTimeout(() => window.location.assign(buildUrl({ route: AppRoute.LOGIN })))
    return <Loader />
  }

  // aaaaand make it available
  return <>{children}</>
}
