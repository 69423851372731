/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  CreateManyDayTrendDto,
  CreateManyDayTrendDtoFromJSON,
  CreateManyDayTrendDtoToJSON,
  DayTrend,
  DayTrendFromJSON,
  DayTrendToJSON,
  DayTrendInputListDto,
  DayTrendInputListDtoFromJSON,
  DayTrendInputListDtoToJSON,
  DayTrendListDto,
  DayTrendListDtoFromJSON,
  DayTrendListDtoToJSON,
  GetManyDayTrendResponseDto,
  GetManyDayTrendResponseDtoFromJSON,
  GetManyDayTrendResponseDtoToJSON,
} from "../models"

export interface CreateManyBaseDayTrendControllerDayTrendRequest {
  createManyDayTrendDto: CreateManyDayTrendDto
}

export interface CreateOneBaseDayTrendControllerDayTrendRequest {
  dayTrend: DayTrend
}

export interface DayTrendControllerReplaceAllInSourceRequest {
  sourceId: number
  dayTrendInputListDto: DayTrendInputListDto
}

export interface DeleteOneBaseDayTrendControllerDayTrendRequest {
  id: number
}

export interface GetManyBaseDayTrendControllerDayTrendRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseDayTrendControllerDayTrendRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface ReplaceOneBaseDayTrendControllerDayTrendRequest {
  id: number
  dayTrend: DayTrend
}

export interface UpdateOneBaseDayTrendControllerDayTrendRequest {
  id: number
  dayTrend: DayTrend
}

/**
 * no description
 */
export class DayTrendApi extends runtime.BaseAPI {
  /**
   * Create many DayTrend
   */
  async createManyBaseDayTrendControllerDayTrendRaw(
    requestParameters: CreateManyBaseDayTrendControllerDayTrendRequest,
  ): Promise<runtime.ApiResponse<Array<DayTrend>>> {
    if (requestParameters.createManyDayTrendDto === null || requestParameters.createManyDayTrendDto === undefined) {
      throw new runtime.RequiredError(
        "createManyDayTrendDto",
        "Required parameter requestParameters.createManyDayTrendDto was null or undefined when calling createManyBaseDayTrendControllerDayTrend.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-trend/bulk`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CreateManyDayTrendDtoToJSON(requestParameters.createManyDayTrendDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DayTrendFromJSON))
  }

  /**
   * Create many DayTrend
   */
  async createManyBaseDayTrendControllerDayTrend(
    requestParameters: CreateManyBaseDayTrendControllerDayTrendRequest,
  ): Promise<Array<DayTrend>> {
    const response = await this.createManyBaseDayTrendControllerDayTrendRaw(requestParameters)
    return await response.value()
  }

  /**
   * Create one DayTrend
   */
  async createOneBaseDayTrendControllerDayTrendRaw(
    requestParameters: CreateOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<runtime.ApiResponse<DayTrend>> {
    if (requestParameters.dayTrend === null || requestParameters.dayTrend === undefined) {
      throw new runtime.RequiredError(
        "dayTrend",
        "Required parameter requestParameters.dayTrend was null or undefined when calling createOneBaseDayTrendControllerDayTrend.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-trend`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DayTrendToJSON(requestParameters.dayTrend),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayTrendFromJSON(jsonValue))
  }

  /**
   * Create one DayTrend
   */
  async createOneBaseDayTrendControllerDayTrend(
    requestParameters: CreateOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<DayTrend> {
    const response = await this.createOneBaseDayTrendControllerDayTrendRaw(requestParameters)
    return await response.value()
  }

  /**
   * Imports many trends and replace existing. Recomputes alerts
   */
  async dayTrendControllerReplaceAllInSourceRaw(
    requestParameters: DayTrendControllerReplaceAllInSourceRequest,
  ): Promise<runtime.ApiResponse<DayTrendListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayTrendControllerReplaceAllInSource.",
      )
    }

    if (requestParameters.dayTrendInputListDto === null || requestParameters.dayTrendInputListDto === undefined) {
      throw new runtime.RequiredError(
        "dayTrendInputListDto",
        "Required parameter requestParameters.dayTrendInputListDto was null or undefined when calling dayTrendControllerReplaceAllInSource.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-trend/replace-all-in-source/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DayTrendInputListDtoToJSON(requestParameters.dayTrendInputListDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayTrendListDtoFromJSON(jsonValue))
  }

  /**
   * Imports many trends and replace existing. Recomputes alerts
   */
  async dayTrendControllerReplaceAllInSource(
    requestParameters: DayTrendControllerReplaceAllInSourceRequest,
  ): Promise<DayTrendListDto> {
    const response = await this.dayTrendControllerReplaceAllInSourceRaw(requestParameters)
    return await response.value()
  }

  /**
   * Delete one DayTrend
   */
  async deleteOneBaseDayTrendControllerDayTrendRaw(
    requestParameters: DeleteOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteOneBaseDayTrendControllerDayTrend.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-trend/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Delete one DayTrend
   */
  async deleteOneBaseDayTrendControllerDayTrend(
    requestParameters: DeleteOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<void> {
    await this.deleteOneBaseDayTrendControllerDayTrendRaw(requestParameters)
  }

  /**
   * Retrieve many DayTrend
   */
  async getManyBaseDayTrendControllerDayTrendRaw(
    requestParameters: GetManyBaseDayTrendControllerDayTrendRequest,
  ): Promise<runtime.ApiResponse<GetManyDayTrendResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-trend`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyDayTrendResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many DayTrend
   */
  async getManyBaseDayTrendControllerDayTrend(
    requestParameters: GetManyBaseDayTrendControllerDayTrendRequest,
  ): Promise<GetManyDayTrendResponseDto> {
    const response = await this.getManyBaseDayTrendControllerDayTrendRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one DayTrend
   */
  async getOneBaseDayTrendControllerDayTrendRaw(
    requestParameters: GetOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<runtime.ApiResponse<DayTrend>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseDayTrendControllerDayTrend.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-trend/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayTrendFromJSON(jsonValue))
  }

  /**
   * Retrieve one DayTrend
   */
  async getOneBaseDayTrendControllerDayTrend(
    requestParameters: GetOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<DayTrend> {
    const response = await this.getOneBaseDayTrendControllerDayTrendRaw(requestParameters)
    return await response.value()
  }

  /**
   * Replace one DayTrend
   */
  async replaceOneBaseDayTrendControllerDayTrendRaw(
    requestParameters: ReplaceOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<runtime.ApiResponse<DayTrend>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling replaceOneBaseDayTrendControllerDayTrend.",
      )
    }

    if (requestParameters.dayTrend === null || requestParameters.dayTrend === undefined) {
      throw new runtime.RequiredError(
        "dayTrend",
        "Required parameter requestParameters.dayTrend was null or undefined when calling replaceOneBaseDayTrendControllerDayTrend.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-trend/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: DayTrendToJSON(requestParameters.dayTrend),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayTrendFromJSON(jsonValue))
  }

  /**
   * Replace one DayTrend
   */
  async replaceOneBaseDayTrendControllerDayTrend(
    requestParameters: ReplaceOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<DayTrend> {
    const response = await this.replaceOneBaseDayTrendControllerDayTrendRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update one DayTrend
   */
  async updateOneBaseDayTrendControllerDayTrendRaw(
    requestParameters: UpdateOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<runtime.ApiResponse<DayTrend>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateOneBaseDayTrendControllerDayTrend.",
      )
    }

    if (requestParameters.dayTrend === null || requestParameters.dayTrend === undefined) {
      throw new runtime.RequiredError(
        "dayTrend",
        "Required parameter requestParameters.dayTrend was null or undefined when calling updateOneBaseDayTrendControllerDayTrend.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-trend/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: DayTrendToJSON(requestParameters.dayTrend),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayTrendFromJSON(jsonValue))
  }

  /**
   * Update one DayTrend
   */
  async updateOneBaseDayTrendControllerDayTrend(
    requestParameters: UpdateOneBaseDayTrendControllerDayTrendRequest,
  ): Promise<DayTrend> {
    const response = await this.updateOneBaseDayTrendControllerDayTrendRaw(requestParameters)
    return await response.value()
  }
}
