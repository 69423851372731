/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayContextTypes, DayContextTypesFromJSON, DayContextTypesFromJSONTyped, DayContextTypesToJSON } from "./"

/**
 *
 * @export
 * @interface ContextImpactTaskInputParams
 */
export interface ContextImpactTaskInputParams {
  /**
   *
   * @type {number}
   * @memberof ContextImpactTaskInputParams
   */
  sourceId: number
  /**
   *
   * @type {string}
   * @memberof ContextImpactTaskInputParams
   */
  title: string
  /**
   *
   * @type {Array<DayContextTypes>}
   * @memberof ContextImpactTaskInputParams
   */
  contextTypes: Array<DayContextTypes>
  /**
   *
   * @type {Array<number>}
   * @memberof ContextImpactTaskInputParams
   */
  contextSourceIds: Array<number>
}

export function ContextImpactTaskInputParamsFromJSON(json: any): ContextImpactTaskInputParams {
  return ContextImpactTaskInputParamsFromJSONTyped(json, false)
}

export function ContextImpactTaskInputParamsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContextImpactTaskInputParams {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sourceId: json["sourceId"],
    title: json["title"],
    contextTypes: (json["contextTypes"] as Array<any>).map(DayContextTypesFromJSON),
    contextSourceIds: json["contextSourceIds"],
  }
}

export function ContextImpactTaskInputParamsToJSON(value?: ContextImpactTaskInputParams | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    title: value.title,
    contextTypes: (value.contextTypes as Array<any>).map(DayContextTypesToJSON),
    contextSourceIds: value.contextSourceIds,
  }
}
