/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"

export interface ImportCsvControllerCreateSourceRequest {
  dateFormat?: string
  numberLocale?: ImportCsvControllerCreateSourceNumberLocaleEnum
  separator?: string
  quote?: string
  stopOnError?: boolean
  interpolationMethod?: string
  name?: string
  activitySize?: number
  dayModelsEnabled?: boolean
  tags?: Array<string>
}

export interface ImportCsvControllerImportDayDataSourceIdRequest {
  sourceId: number
  dateFormat?: string
  numberLocale?: ImportCsvControllerImportDayDataSourceIdNumberLocaleEnum
  separator?: string
  quote?: string
  stopOnError?: boolean
  interpolationMethod?: string
}

export interface ImportCsvControllerValidateCsvEndpointRequest {
  dateFormat?: string
  numberLocale?: ImportCsvControllerValidateCsvEndpointNumberLocaleEnum
  separator?: string
  quote?: string
  stopOnError?: boolean
  activitySize?: number
}

export interface ImportCsvMultiControllerCreateSourcesRequest {
  dateFormat?: string
  numberLocale?: ImportCsvMultiControllerCreateSourcesNumberLocaleEnum
  separator?: string
  quote?: string
  stopOnError?: boolean
  activitySize?: number
  baseName?: string
  interpolationMethod?: string
  dayModelsEnabled?: boolean
  tags?: Array<string>
}

export interface ImportCsvMultiControllerValidateCsvEndpointRequest {
  dateFormat?: string
  numberLocale?: ImportCsvMultiControllerValidateCsvEndpointNumberLocaleEnum
  separator?: string
  quote?: string
  stopOnError?: boolean
  activitySize?: number
}

/**
 * no description
 */
export class ImportCsvApi extends runtime.BaseAPI {
  /**
   * Creates a source, from a csv file format. This endpoint has the same      goal as /v1/import/create-source but can handle more data as the processing happens as      we receive data which is not possible with a json format.      We use the first line year as the source reference year.     We expect the csv file to be a csv with exactly 2 columns.      A \"date\" column with a customizable format (default is ISO).      An \"activity\" column in numeric format with a customizable locale (default is FR).     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * First source creation using an ordered csv file
   */
  async importCsvControllerCreateSourceRaw(
    requestParameters: ImportCsvControllerCreateSourceRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.dateFormat !== undefined) {
      queryParameters["dateFormat"] = requestParameters.dateFormat
    }

    if (requestParameters.numberLocale !== undefined) {
      queryParameters["numberLocale"] = requestParameters.numberLocale
    }

    if (requestParameters.separator !== undefined) {
      queryParameters["separator"] = requestParameters.separator
    }

    if (requestParameters.quote !== undefined) {
      queryParameters["quote"] = requestParameters.quote
    }

    if (requestParameters.stopOnError !== undefined) {
      queryParameters["stopOnError"] = requestParameters.stopOnError
    }

    if (requestParameters.interpolationMethod !== undefined) {
      queryParameters["interpolationMethod"] = requestParameters.interpolationMethod
    }

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name
    }

    if (requestParameters.activitySize !== undefined) {
      queryParameters["activitySize"] = requestParameters.activitySize
    }

    if (requestParameters.dayModelsEnabled !== undefined) {
      queryParameters["dayModelsEnabled"] = requestParameters.dayModelsEnabled
    }

    if (requestParameters.tags) {
      queryParameters["tags"] = requestParameters.tags
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import-csv/create-source`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Creates a source, from a csv file format. This endpoint has the same      goal as /v1/import/create-source but can handle more data as the processing happens as      we receive data which is not possible with a json format.      We use the first line year as the source reference year.     We expect the csv file to be a csv with exactly 2 columns.      A \"date\" column with a customizable format (default is ISO).      An \"activity\" column in numeric format with a customizable locale (default is FR).     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * First source creation using an ordered csv file
   */
  async importCsvControllerCreateSource(requestParameters: ImportCsvControllerCreateSourceRequest): Promise<void> {
    await this.importCsvControllerCreateSourceRaw(requestParameters)
  }

  /**
   * When new data is added, we compute alerts for this dataThis endpoint has the same      goal as /v1/import/:sourceId/days but can handle more data as the processing happens as      we receive data which is not possible with a json format.     We expect the csv file to be a csv with exactly 2 columns.      A \"date\" column with a customizable format (default is ISO).      An \"activity\" column in numeric format with a customizable locale (default is FR).     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * Add new data to a source
   */
  async importCsvControllerImportDayDataSourceIdRaw(
    requestParameters: ImportCsvControllerImportDayDataSourceIdRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling importCsvControllerImportDayDataSourceId.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.dateFormat !== undefined) {
      queryParameters["dateFormat"] = requestParameters.dateFormat
    }

    if (requestParameters.numberLocale !== undefined) {
      queryParameters["numberLocale"] = requestParameters.numberLocale
    }

    if (requestParameters.separator !== undefined) {
      queryParameters["separator"] = requestParameters.separator
    }

    if (requestParameters.quote !== undefined) {
      queryParameters["quote"] = requestParameters.quote
    }

    if (requestParameters.stopOnError !== undefined) {
      queryParameters["stopOnError"] = requestParameters.stopOnError
    }

    if (requestParameters.interpolationMethod !== undefined) {
      queryParameters["interpolationMethod"] = requestParameters.interpolationMethod
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import-csv/{sourceId}/days`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * When new data is added, we compute alerts for this dataThis endpoint has the same      goal as /v1/import/:sourceId/days but can handle more data as the processing happens as      we receive data which is not possible with a json format.     We expect the csv file to be a csv with exactly 2 columns.      A \"date\" column with a customizable format (default is ISO).      An \"activity\" column in numeric format with a customizable locale (default is FR).     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * Add new data to a source
   */
  async importCsvControllerImportDayDataSourceId(
    requestParameters: ImportCsvControllerImportDayDataSourceIdRequest,
  ): Promise<void> {
    await this.importCsvControllerImportDayDataSourceIdRaw(requestParameters)
  }

  /**
   * Check that our csv content can be used to create a source of      feed it some days.     We expect the csv file to be a csv with exactly 2 columns.      A \"date\" column with a customizable format (default is ISO).      An \"activity\" column in numeric format with a customizable locale (default is FR).     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * Validate a CSV input, fetch all errors
   */
  async importCsvControllerValidateCsvEndpointRaw(
    requestParameters: ImportCsvControllerValidateCsvEndpointRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.dateFormat !== undefined) {
      queryParameters["dateFormat"] = requestParameters.dateFormat
    }

    if (requestParameters.numberLocale !== undefined) {
      queryParameters["numberLocale"] = requestParameters.numberLocale
    }

    if (requestParameters.separator !== undefined) {
      queryParameters["separator"] = requestParameters.separator
    }

    if (requestParameters.quote !== undefined) {
      queryParameters["quote"] = requestParameters.quote
    }

    if (requestParameters.stopOnError !== undefined) {
      queryParameters["stopOnError"] = requestParameters.stopOnError
    }

    if (requestParameters.activitySize !== undefined) {
      queryParameters["activitySize"] = requestParameters.activitySize
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import-csv/validate-csv`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Check that our csv content can be used to create a source of      feed it some days.     We expect the csv file to be a csv with exactly 2 columns.      A \"date\" column with a customizable format (default is ISO).      An \"activity\" column in numeric format with a customizable locale (default is FR).     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * Validate a CSV input, fetch all errors
   */
  async importCsvControllerValidateCsvEndpoint(
    requestParameters: ImportCsvControllerValidateCsvEndpointRequest,
  ): Promise<void> {
    await this.importCsvControllerValidateCsvEndpointRaw(requestParameters)
  }

  /**
   * Creates a source, from a csv file format. This endpoint has the same      goal as /v1/import-csv/create-source but can create multiple sources at once     We use the first line year as the sources reference year.     We expect the csv file to be a csv with at least 2 columns.      A \"date\" column with a customizable format (default is ISO).      Any number of metric columns in numeric format with a customizable locale (default is FR).     The column header is used to create distinct source names.     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * First source creation using an ordered csv file
   */
  async importCsvMultiControllerCreateSourcesRaw(
    requestParameters: ImportCsvMultiControllerCreateSourcesRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.dateFormat !== undefined) {
      queryParameters["dateFormat"] = requestParameters.dateFormat
    }

    if (requestParameters.numberLocale !== undefined) {
      queryParameters["numberLocale"] = requestParameters.numberLocale
    }

    if (requestParameters.separator !== undefined) {
      queryParameters["separator"] = requestParameters.separator
    }

    if (requestParameters.quote !== undefined) {
      queryParameters["quote"] = requestParameters.quote
    }

    if (requestParameters.stopOnError !== undefined) {
      queryParameters["stopOnError"] = requestParameters.stopOnError
    }

    if (requestParameters.activitySize !== undefined) {
      queryParameters["activitySize"] = requestParameters.activitySize
    }

    if (requestParameters.baseName !== undefined) {
      queryParameters["baseName"] = requestParameters.baseName
    }

    if (requestParameters.interpolationMethod !== undefined) {
      queryParameters["interpolationMethod"] = requestParameters.interpolationMethod
    }

    if (requestParameters.dayModelsEnabled !== undefined) {
      queryParameters["dayModelsEnabled"] = requestParameters.dayModelsEnabled
    }

    if (requestParameters.tags) {
      queryParameters["tags"] = requestParameters.tags
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import-csv-multi/create-sources`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Creates a source, from a csv file format. This endpoint has the same      goal as /v1/import-csv/create-source but can create multiple sources at once     We use the first line year as the sources reference year.     We expect the csv file to be a csv with at least 2 columns.      A \"date\" column with a customizable format (default is ISO).      Any number of metric columns in numeric format with a customizable locale (default is FR).     The column header is used to create distinct source names.     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * First source creation using an ordered csv file
   */
  async importCsvMultiControllerCreateSources(
    requestParameters: ImportCsvMultiControllerCreateSourcesRequest,
  ): Promise<void> {
    await this.importCsvMultiControllerCreateSourcesRaw(requestParameters)
  }

  /**
   * Check that our csv content can be used to create a source of      feed it some days.     We expect the csv file to be a csv with exactly 2 columns.      A \"date\" column with a customizable format (default is ISO).      Any number of metric columns in numeric format with a customizable locale (default is FR).     The column header is used to create distinct source names.     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * Validate a CSV input, fetch all errors
   */
  async importCsvMultiControllerValidateCsvEndpointRaw(
    requestParameters: ImportCsvMultiControllerValidateCsvEndpointRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.dateFormat !== undefined) {
      queryParameters["dateFormat"] = requestParameters.dateFormat
    }

    if (requestParameters.numberLocale !== undefined) {
      queryParameters["numberLocale"] = requestParameters.numberLocale
    }

    if (requestParameters.separator !== undefined) {
      queryParameters["separator"] = requestParameters.separator
    }

    if (requestParameters.quote !== undefined) {
      queryParameters["quote"] = requestParameters.quote
    }

    if (requestParameters.stopOnError !== undefined) {
      queryParameters["stopOnError"] = requestParameters.stopOnError
    }

    if (requestParameters.activitySize !== undefined) {
      queryParameters["activitySize"] = requestParameters.activitySize
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import-csv-multi/validate-csv`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Check that our csv content can be used to create a source of      feed it some days.     We expect the csv file to be a csv with exactly 2 columns.      A \"date\" column with a customizable format (default is ISO).      Any number of metric columns in numeric format with a customizable locale (default is FR).     The column header is used to create distinct source names.     Ex: \"2004-01-01T00:30:00\";\"181,63\"
   * Validate a CSV input, fetch all errors
   */
  async importCsvMultiControllerValidateCsvEndpoint(
    requestParameters: ImportCsvMultiControllerValidateCsvEndpointRequest,
  ): Promise<void> {
    await this.importCsvMultiControllerValidateCsvEndpointRaw(requestParameters)
  }
}

/**
 * @export
 * @enum {string}
 */
export enum ImportCsvControllerCreateSourceNumberLocaleEnum {
  En = "en",
  Fr = "fr",
}
/**
 * @export
 * @enum {string}
 */
export enum ImportCsvControllerImportDayDataSourceIdNumberLocaleEnum {
  En = "en",
  Fr = "fr",
}
/**
 * @export
 * @enum {string}
 */
export enum ImportCsvControllerValidateCsvEndpointNumberLocaleEnum {
  En = "en",
  Fr = "fr",
}
/**
 * @export
 * @enum {string}
 */
export enum ImportCsvMultiControllerCreateSourcesNumberLocaleEnum {
  En = "en",
  Fr = "fr",
}
/**
 * @export
 * @enum {string}
 */
export enum ImportCsvMultiControllerValidateCsvEndpointNumberLocaleEnum {
  En = "en",
  Fr = "fr",
}
