import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { Formik, FormikErrors } from "formik"
import React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { AppRoute } from "state/route"
import AppLink from "../../component/AppLink"
import MadeWithLove from "../../component/MadeWithLove"
import { prepareIdentifierString } from "../../lib/string"
import { useAuth } from "../../state/auth"
import { AppButton } from "../../component/AppButton"
import { SocialLoginButtons } from "../../component/SocialLoginButtons"
import { Divider } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login-bg.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function Register() {
  const { register } = useAuth()
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Helmet>
            <title>{t("global.appTitle")} - Inscription</title>
          </Helmet>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h6">
            {t("global.appTitle")} - Inscription
          </Typography>
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
              password: "",
              passwordConfirm: "",
              username: "",
              company: "",
              position: "",
            }}
            onSubmit={async (values, { setFieldError }) => {
              try {
                await register(values)
              } catch (errorResponse) {
                if (errorResponse instanceof Response) {
                  if (errorResponse.status === 400) {
                    const responseBody = await errorResponse.json()
                    for (const error of responseBody.message) {
                      setFieldError(error.property, Object.values(error.constraints).join(". "))
                    }
                  } else {
                    alert("Une erreur s'est produite, veuillez contacter nos équipes techniques")
                  }
                }
              }
            }}
            validate={(values) => {
              const errors: FormikErrors<typeof values> = {}
              if (values.passwordConfirm !== values.password) {
                errors.passwordConfirm = "Les mots de passe ne correspondent pas"
              }
              return errors
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  id="firstname"
                  label="Prénom"
                  name="firstname"
                  autoComplete="firstname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstname}
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  id="lastname"
                  label="Nom de famille"
                  name="lastname"
                  autoComplete="lastname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastname}
                  error={!!errors.lastname}
                  helperText={errors.lastname}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  id="username"
                  label="Identifiant utilisateur"
                  helperText={
                    errors.username ? errors.username : "Cet identifiant unique vous permettra de vous connecter"
                  }
                  name="username"
                  autoComplete="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={prepareIdentifierString(values.firstname + values.lastname)}
                  value={values.username}
                  error={!!errors.username}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  type="email"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={!!errors.email}
                  helperText={errors.email}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  type="password"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={!!errors.password}
                  helperText={errors.password ? errors.password : "Minimum 3 caractères"}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  type="password"
                  required
                  fullWidth
                  id="passwordConfirm"
                  label="Confirmez votre mot de passe"
                  name="passwordConfirm"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirm}
                  error={!!errors.passwordConfirm}
                  helperText={errors.passwordConfirm}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  id="company"
                  label="Société"
                  name="company"
                  autoComplete="company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.company}
                  error={!!errors.company}
                  helperText={errors.company}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  id="position"
                  label="Poste"
                  name="position"
                  autoComplete="position"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.position}
                  error={!!errors.position}
                  helperText={errors.position}
                />

                <AppButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  submitting={isSubmitting}
                >
                  Créer mon compte
                </AppButton>
              </form>
            )}
          </Formik>
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Divider style={{ border: "1px #CCC solid", width: "50%" }} />
          </div>
          <SocialLoginButtons mode="register" />
          <div>
            <span>Déjà inscrit ? </span>
            <AppLink linkStyle={true} route={AppRoute.LOGIN}>
              Connectez vous
            </AppLink>
          </div>
          <Box mt={5}>
            <MadeWithLove />
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}
