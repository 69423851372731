import React from "react"

import { makeStyles, Theme } from "@material-ui/core"
import { isDarkColor } from "../lib/color"
import { AnyChildren } from "../react-type-helpers"

interface StyleProps {
  color: string
}

const useStyle = makeStyles<Theme, StyleProps>((theme) => ({
  container: (props) => ({
    width: "100%",
    wordBreak: "unset",
    wordWrap: "unset",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    border: 0,
    backgroundColor: props.color,
    color: isDarkColor(props.color) ? "white" : "black",
    padding: theme.spacing(1),
  }),
}))

export default function GridHighlight({ color, children }: { color: string; children: AnyChildren }) {
  const classes = useStyle({ color })
  return <div className={classes.container}>{children}</div>
}
