import { OutlinedInput } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import DelIcon from "@material-ui/icons/Delete"
import { DatePicker } from "@material-ui/pickers"
import { Formik } from "formik"
import React from "react"
import { dateObjToDayString, dayCountBetweenDates, getCurrentYear, toDateObj } from "../lib/date"
import ListSelect from "./ListSelect"
import { DayTrend, DayTrendUnitEnum, DayTrendTypeEnum } from "api/timelight-api"
import { DayTrendInput, DayTrendInputUnitEnum, DayTrendInputTypeEnum } from "api/timelight-api"
import { AppButton } from "./AppButton"

function unitToText(unit: DayTrendUnitEnum | DayTrendInputUnitEnum) {
  // @ts-ignore
  return {
    [DayTrendUnitEnum.Unit]: " unités",
    [DayTrendUnitEnum.Percent]: "%",
  }[unit]
}

function typeToText(type: DayTrendTypeEnum | DayTrendInputTypeEnum) {
  // @ts-ignore
  return {
    [DayTrendTypeEnum.OneShot]: "une fois",
    [DayTrendTypeEnum.Weekly]: "chaque semaine",
    [DayTrendTypeEnum.Monthly]: "chaque mois",
  }[type]
}

function applyTrendValue(mod: DayTrendInput | DayTrend, act: { date: string; value: number }) {
  if (mod.unit === DayTrendUnitEnum.Percent) {
    act.value *= 1 + mod.value / 100
  } else {
    act.value += mod.value
  }
}

// @todo: Add a .test.ts file
export function applyTrend(mod: DayTrendInput | DayTrend, act: { date: string; value: number }) {
  if (act.date < mod.startDayDate || (mod.stopDayDate && act.date > mod.stopDayDate)) {
    return
  }
  const dayBetween = dayCountBetweenDates(act.date, mod.startDayDate)
  const weekNumber = Math.floor(dayBetween / 7)
  const monthNumber = Math.floor(dayBetween / 30)

  if (mod.type === DayTrendTypeEnum.OneShot) {
    applyTrendValue(mod, act)
  } else if (mod.type === DayTrendTypeEnum.Weekly) {
    for (let i = 0; i < weekNumber + 1; i++) {
      applyTrendValue(mod, act)
    }
  } else if (mod.type === DayTrendTypeEnum.Monthly) {
    for (let i = 0; i < monthNumber + 1; i++) {
      applyTrendValue(mod, act)
    }
  }
}

export function getValueAfterTrends(trends: (DayTrendInput | DayTrend)[], date: string, value: number) {
  const res = { date, value } // copy
  // apply trends one by one, on the given order
  for (const trend of trends) {
    applyTrend(trend, res)
  }
  return res.value
}

export default function DayTrendForm({
  trends,
  setTrends,
}: {
  trends: DayTrendInput[]
  setTrends: (m: DayTrendInput[]) => void
}) {
  return (
    <div>
      <Formik<DayTrendInput>
        initialValues={{
          value: 10,
          unit: DayTrendInputUnitEnum.Percent,
          type: DayTrendInputTypeEnum.Monthly,
          startDayDate: dateObjToDayString(new Date()),
          stopDayDate: null,
        }}
        onSubmit={async (values) => {
          setTrends([
            ...trends,
            {
              ...values,
              startDayDate: dateObjToDayString(toDateObj(values.startDayDate)),
              stopDayDate: values.stopDayDate ? dateObjToDayString(toDateObj(values.stopDayDate)) : null,
            },
          ])
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>Appliquer une modification de</span>
                  <OutlinedInput
                    labelWidth={0}
                    style={{ width: 150, marginLeft: "1em" }}
                    type="number"
                    name="value"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.value}
                    disabled={isSubmitting}
                  />
                  {errors.value && touched.value && errors.value}
                  <ListSelect
                    items={[DayTrendInputUnitEnum.Unit, DayTrendInputUnitEnum.Percent]}
                    minWidth={80}
                    getLabel={unitToText}
                    disabled={isSubmitting}
                    onSelect={(v) => setFieldValue("unit", v)}
                    value={values.unit}
                  />
                  {errors.unit && touched.unit && errors.unit}
                  <ListSelect
                    items={[DayTrendInputTypeEnum.OneShot, DayTrendInputTypeEnum.Weekly, DayTrendInputTypeEnum.Monthly]}
                    minWidth={150}
                    getLabel={typeToText}
                    onSelect={(v) => setFieldValue("type", v)}
                    value={values.type}
                    disabled={isSubmitting}
                  />
                  {errors.type && touched.type && errors.type}
                </div>

                <div style={{ display: "flex", alignItems: "center", marginTop: "1em" }}>
                  <span style={{ marginRight: "1em" }}>À partir du</span>
                  <DatePicker
                    required
                    disabled={isSubmitting}
                    clearable
                    name="startDayDate"
                    format="dd/MM/yyyy"
                    onChange={(d) => setFieldValue("startDayDate", d)}
                    onBlur={handleBlur}
                    minDate={dateObjToDayString(toDateObj(getCurrentYear() + "-01-01"))}
                    maxDate={values.stopDayDate || undefined}
                    value={values.startDayDate}
                  />
                  {errors.startDayDate && touched.startDayDate && errors.startDayDate}
                  <span style={{ marginLeft: "1em", marginRight: "1em" }}>et jusqu'au</span>
                  <DatePicker
                    disabled={isSubmitting}
                    clearable
                    name="stopDayDate"
                    format="dd/MM/yyyy"
                    minDate={values.startDayDate}
                    onChange={(d) => setFieldValue("stopDayDate", d)}
                    onBlur={handleBlur}
                    value={values.stopDayDate}
                  />
                  {errors.stopDayDate && touched.stopDayDate && errors.stopDayDate}
                </div>
              </div>
              <AppButton
                submitting={isSubmitting}
                type="submit"
                variant="outlined"
                color="primary"
                style={{ marginLeft: "2em" }}
              >
                <AddIcon />
              </AppButton>
            </div>
          </form>
        )}
      </Formik>
      <div>
        <ul style={{ marginTop: "4em" }}>
          {trends.map((m, i) => (
            <li key={i}>
              Modification de {m.value}
              {unitToText(m.unit)} {typeToText(m.type)} à partir du {m.startDayDate}{" "}
              {m.stopDayDate ? <> jusqu'au {m.stopDayDate}</> : <> sans date de fin</>}
              <AppButton
                onClick={() => {
                  setTrends(trends.filter((mm) => mm !== m))
                }}
                variant="outlined"
                color="secondary"
                style={{ marginLeft: "2em" }}
              >
                <DelIcon />
              </AppButton>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
