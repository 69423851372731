// tslint:disable:max-line-length
import { ButtonGroup } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import CalendarIcon from "@material-ui/icons/CalendarToday"
import GridIcon from "@material-ui/icons/GridOn"
import React from "react"
import { useLocalStorage } from "../../state/localStorage"
import { DayYearCalendarProps } from "./CalendarData"
import DayGridYearCalendar from "./DayGridYearCalendar"
import DayListYearCalendar from "./DayListYearCalendar"

import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { DATA_NOT_PROVIDED_MODEL_ID } from "../ChartDayActivity"
import { dateObjToDayString } from "../../lib/date"
import { findMetricDataIdx } from "../../lib/array"
import * as lodash from "lodash"
import { FetchSerieDataCompactResult } from "../../api/timelight-api/models/FetchSerieDataCompactResult"

const useStyle = makeStyles({
  disabledButton: {
    boxShadow: "-1px 1px 10px -1px rgba(0, 0, 0, 0.3) inset",
  },
})

export default function DayYearContextCalendar({
  impactSerieData,
  ...props
}: Omit<DayYearCalendarProps, "days"> & { impactSerieData: FetchSerieDataCompactResult }) {
  const classes = useStyle()
  const [calStyle, setCalStyle] = useLocalStorage<"grid" | "list">("calendar-style-switch-days", "list")

  const activityMetricIdx = findMetricDataIdx(impactSerieData.metrics, "activity")
  const impactByDay = Object.entries(
    lodash.groupBy(impactSerieData.data, (values) => dateObjToDayString(new Date(values[0]))),
  ).map(([day, values]) => ({ day, impact: lodash.sum(values.map((v) => v[activityMetricIdx])) }))

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <ButtonGroup size="small" aria-label="small outlined button group">
          <Button
            className={clsx(calStyle === "grid" && classes.disabledButton)}
            disabled={calStyle === "grid"}
            onClick={() => setCalStyle("grid")}
          >
            <GridIcon />
          </Button>
          <Button
            className={clsx(calStyle === "list" && classes.disabledButton)}
            disabled={calStyle === "list"}
            onClick={() => setCalStyle("list")}
          >
            <CalendarIcon />
          </Button>
        </ButtonGroup>
      </div>
      {calStyle === "list" && (
        <DayListYearCalendar
          content="activity"
          {...props}
          days={impactByDay.map((imp) => ({
            date: imp.day,
            value: imp.impact,
            anomaly: false,
            model_id: DATA_NOT_PROVIDED_MODEL_ID,
          }))}
        />
      )}
      {calStyle === "grid" && (
        <DayGridYearCalendar
          content="activity"
          {...props}
          days={impactByDay.map((imp) => ({
            date: imp.day,
            value: imp.impact,
            anomaly: false,
            model_id: DATA_NOT_PROVIDED_MODEL_ID,
          }))}
        />
      )}
    </div>
  )
}
