import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { useConfig } from "../config"
import PageBlock from "./PageBlock"
import { AppButton } from "./AppButton"

const useStyles = makeStyles({
  text: {
    fontSize: "1.2em",
  },
})

export function ImportErrorBlock({ onBack }: { onBack: () => void }) {
  const classes = useStyles()
  const { CONTACT_FORM_URL } = useConfig()
  return (
    <PageBlock title="Analyse approfondie nécessaire">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img style={{ maxWidth: "300px" }} alt="Analyse approfondie nécessaire" src="/undraw/undraw_warning_cyit.svg" />
      </div>
      <p className={classes.text}>
        Malheureusement, nous n'avons pas pu analyser votre fichier automatiquement. Nos équipes seront ravies de vous
        aider à exploiter la richesse de vos données. N'hésitez pas à{" "}
        <a href={CONTACT_FORM_URL} target="_blank" rel="noopener noreferrer">
          nous contacter
        </a>
        .
      </p>
      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <AppButton style={{ marginRight: "1em" }} variant="outlined" color="primary" onClick={onBack}>
          Essayer avec autre fichier
        </AppButton>
        <AppButton variant="contained" color="primary" href={CONTACT_FORM_URL}>
          Nous contacter
        </AppButton>
      </div>
    </PageBlock>
  )
}
