/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  AlertCommentDto,
  AlertCommentDtoFromJSON,
  AlertCommentDtoToJSON,
  AlertDto,
  AlertDtoFromJSON,
  AlertDtoToJSON,
  AlertFavoriteDto,
  AlertFavoriteDtoFromJSON,
  AlertFavoriteDtoToJSON,
  AlertListDto,
  AlertListDtoFromJSON,
  AlertListDtoToJSON,
  AlertRefDto,
  AlertRefDtoFromJSON,
  AlertRefDtoToJSON,
  AlertRefListDto,
  AlertRefListDtoFromJSON,
  AlertRefListDtoToJSON,
} from "../models"

export interface AlertControllerAlertCommentRequest {
  alertId: number
  alertCommentDto: AlertCommentDto
}

export interface AlertControllerAlertFavoriteRequest {
  alertId: number
  alertFavoriteDto: AlertFavoriteDto
}

export interface AlertControllerListAlertRefsRequest {
  year?: number
  sourceId?: number
}

export interface AlertControllerListAlertsRequest {
  year?: number
  sourceId?: number
}

/**
 * no description
 */
export class AlertApi extends runtime.BaseAPI {
  /**
   * A commented alert is closed and is moved to the alert referential
   * Add a comment to an alert
   */
  async alertControllerAlertCommentRaw(
    requestParameters: AlertControllerAlertCommentRequest,
  ): Promise<runtime.ApiResponse<AlertRefDto>> {
    if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
      throw new runtime.RequiredError(
        "alertId",
        "Required parameter requestParameters.alertId was null or undefined when calling alertControllerAlertComment.",
      )
    }

    if (requestParameters.alertCommentDto === null || requestParameters.alertCommentDto === undefined) {
      throw new runtime.RequiredError(
        "alertCommentDto",
        "Required parameter requestParameters.alertCommentDto was null or undefined when calling alertControllerAlertComment.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/alert/{alertId}/comment`.replace(
        `{${"alertId"}}`,
        encodeURIComponent(String(requestParameters.alertId)),
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: AlertCommentDtoToJSON(requestParameters.alertCommentDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AlertRefDtoFromJSON(jsonValue))
  }

  /**
   * A commented alert is closed and is moved to the alert referential
   * Add a comment to an alert
   */
  async alertControllerAlertComment(requestParameters: AlertControllerAlertCommentRequest): Promise<AlertRefDto> {
    const response = await this.alertControllerAlertCommentRaw(requestParameters)
    return await response.value()
  }

  /**
   * Set alert favorite for the current user
   */
  async alertControllerAlertFavoriteRaw(
    requestParameters: AlertControllerAlertFavoriteRequest,
  ): Promise<runtime.ApiResponse<AlertDto>> {
    if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
      throw new runtime.RequiredError(
        "alertId",
        "Required parameter requestParameters.alertId was null or undefined when calling alertControllerAlertFavorite.",
      )
    }

    if (requestParameters.alertFavoriteDto === null || requestParameters.alertFavoriteDto === undefined) {
      throw new runtime.RequiredError(
        "alertFavoriteDto",
        "Required parameter requestParameters.alertFavoriteDto was null or undefined when calling alertControllerAlertFavorite.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/alert/{alertId}/favorite`.replace(
        `{${"alertId"}}`,
        encodeURIComponent(String(requestParameters.alertId)),
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: AlertFavoriteDtoToJSON(requestParameters.alertFavoriteDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AlertDtoFromJSON(jsonValue))
  }

  /**
   * Set alert favorite for the current user
   */
  async alertControllerAlertFavorite(requestParameters: AlertControllerAlertFavoriteRequest): Promise<AlertDto> {
    const response = await this.alertControllerAlertFavoriteRaw(requestParameters)
    return await response.value()
  }

  /**
   * List alerts data of the selected year, all alerts if no year is provided
   */
  async alertControllerListAlertRefsRaw(
    requestParameters: AlertControllerListAlertRefsRequest,
  ): Promise<runtime.ApiResponse<AlertRefListDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.year !== undefined) {
      queryParameters["year"] = requestParameters.year
    }

    if (requestParameters.sourceId !== undefined) {
      queryParameters["sourceId"] = requestParameters.sourceId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/alert/ref/list`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AlertRefListDtoFromJSON(jsonValue))
  }

  /**
   * List alerts data of the selected year, all alerts if no year is provided
   */
  async alertControllerListAlertRefs(requestParameters: AlertControllerListAlertRefsRequest): Promise<AlertRefListDto> {
    const response = await this.alertControllerListAlertRefsRaw(requestParameters)
    return await response.value()
  }

  /**
   * List alerts data of the selected year, all alerts if no year is provided
   */
  async alertControllerListAlertsRaw(
    requestParameters: AlertControllerListAlertsRequest,
  ): Promise<runtime.ApiResponse<AlertListDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.year !== undefined) {
      queryParameters["year"] = requestParameters.year
    }

    if (requestParameters.sourceId !== undefined) {
      queryParameters["sourceId"] = requestParameters.sourceId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/alert/list`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AlertListDtoFromJSON(jsonValue))
  }

  /**
   * List alerts data of the selected year, all alerts if no year is provided
   */
  async alertControllerListAlerts(requestParameters: AlertControllerListAlertsRequest): Promise<AlertListDto> {
    const response = await this.alertControllerListAlertsRaw(requestParameters)
    return await response.value()
  }
}
