import React from "react"
import { useConfig } from "../config"
import Title from "./Title"

function ErrorMessage() {
  const { CONTACT_FORM_URL } = useConfig()
  return (
    <p>
      Une erreur technique nous empêche d'afficher le contenu de cette page correctement, veuillez nous excuser pour la
      gêne occasionnée. Si l'erreur persiste, n'hésitez pas à
      <a style={{ margin: "0.2em" }} href={CONTACT_FORM_URL} target="_blank" rel="noopener noreferrer">
        contacter nos équipes
      </a>
      .
    </p>
  )
}

export class ErrorBoundary extends React.Component<{}, { error: any; errorInfo: any }> {
  constructor(props: {}) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  // @ts-ignorex
  public componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  public render() {
    if (this.state.errorInfo) {
      return (
        <div
          style={{
            marginTop: "2em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title>Une erreur technique est survenue</Title>
          <div
            style={{
              maxWidth: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ maxWidth: "300px" }}
              alt="Une erreur s'est produite"
              src="/undraw/undraw_bug_fixing_oc7a.svg"
            />
            <ErrorMessage />
          </div>
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}
