import React from "react"
import { SourceTag } from "../api/timelight-api/models/SourceTag"
import { Column } from "material-table"
import { ExportConfig, matchSearchTerm } from "./MaterialTable/material-table-helpers"
import { Chip } from "@material-ui/core"

function SourceTagsView({ tags }: { tags: SourceTag[] }) {
  return (
    <div style={{ display: "flex", maxWidth: "400px", flexWrap: "wrap" }}>
      {tags.map((t, i) => (
        <Chip style={{ margin: "0.15em" }} key={i} size="small" label={t.name} />
      ))}
    </div>
  )
}

export function createSourceTagsColumnConfig<T extends object>({
  title,
  getTags,
}: {
  title: string
  getTags: (row: T) => SourceTag[]
}): Column<T> & ExportConfig<T> {
  return {
    customFilterAndSearch: (term: string | string[], row: T) => {
      const tags = getTags(row)
      return tags.some((t) => matchSearchTerm(term, t.name))
    },
    sorting: false,
    render: (row: T) => (
      <div style={{ minWidth: "50px" }}>
        <SourceTagsView tags={getTags(row)} />
      </div>
    ),
    field: "tags",
    filtering: false,
    type: ("string" as unknown) as undefined,
    title,
    exportHeader: title,
    exportRender: (row: T) =>
      getTags(row)
        .map((tag) => tag.name)
        .join(", "),
  }
}
