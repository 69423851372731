import React from "react"
import { useApiClient } from "../state/api"
import { SourceDto } from "../api/timelight-api/models/SourceDto"
import {
  extractColumnConfigAndExportConfig,
  createTextColumnConfig,
  createTypeORMCrudLimit,
  createTypeORMCrudSort,
} from "./MaterialTable/material-table-helpers"
import { MaterialTable } from "./MaterialTable"
import { FormControlLabel, Checkbox } from "@material-ui/core"
import * as lodash from "lodash"
import { createSourceTagsColumnConfig } from "./SourceTags"
import { AnyChildren } from "../react-type-helpers"
import { useAsyncAction } from "../state/async"
import Loader from "./Loader"
import { createTypeORMCrudPage, createDateColumnConfig } from "./MaterialTable/material-table-helpers"

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
export const SourceSelectTable = React.memo(function SourceSelectTableComponent({
  initialOnlySelection = false,
  initialSourceIds = [],
  onChange,
  filterByDateDomain,
  title,
  pageSize = 5,
  readonly = false,
}: {
  initialOnlySelection?: boolean
  initialSourceIds: number[]
  onChange: (sources: SourceDto[]) => void
  filterByDateDomain?: { begin: Date; end: Date }
  title: AnyChildren
  pageSize?: number
  readonly?: boolean
}) {
  const [onlySelection, setOnlySelection] = React.useState<boolean>(initialOnlySelection)
  const [selection, setSelection] = React.useState<SourceDto[] | null>(null)
  const api = useApiClient()

  // fetch initial selection sources
  const [{ data: initialSelectedSources }] = useAsyncAction(
    async ({ sourceIds }) => {
      const sources = await api.getManyBaseSourceControllerSourceDto({
        filter: [`id||$in||${sourceIds.concat([-1]).join(",")}`],
        limit: sourceIds.length,
      })
      return sources.data
    },
    {
      shouldTrigger: true,
      throwErrors: true,
      sourceIds: initialSourceIds,
    },
  )

  if (selection === null && initialSelectedSources !== null) {
    setSelection(initialSelectedSources)
  }

  function onSelectionChange(sources: SourceDto[]) {
    setSelection(sources)
    onChange(sources)
  }

  const { columns } = extractColumnConfigAndExportConfig<SourceDto>([
    createTextColumnConfig({
      title: "Source de données",
      getTextValue: (row) => row.name,
      field: "name",
    }),
    createDateColumnConfig({
      title: "Première donnée",
      getDate: (row) => row.dateDomainBegin,
      field: "dateDomainBegin",
    }),
    createDateColumnConfig({
      title: "Dernière donnée",
      getDate: (row) => row.dateDomainEnd,
      field: "dateDomainEnd",
    }),
    createSourceTagsColumnConfig({
      title: "Tags",
      getTags: (row) => row.tags,
    }),
  ])

  return (
    <div style={{ position: "relative" }}>
      {!selection && <Loader />}
      {selection && (
        <MaterialTable
          key={JSON.stringify({ onlySelection, filterByDateDomain })}
          title={typeof title === "string" ? title : <>{title}</>}
          onRowClick={(row) => {
            if (row && !readonly) {
              onSelectionChange(
                selection !== null && selection.some((s) => s.id === row.id)
                  ? selection.filter((s) => s.id !== row.id)
                  : lodash.uniq([...(selection || []), row]),
              )
            }
          }}
          data={async (query) => {
            const res = await api.getManyBaseSourceControllerSourceDto({
              // search: query.search && query.search.length > 0 ? query.search : undefined,
              limit: createTypeORMCrudLimit(query),
              page: createTypeORMCrudPage(query),
              sort: createTypeORMCrudSort(query),
              s: JSON.stringify({
                $and: [
                  filterByDateDomain
                    ? { dateDomain: { $between: [filterByDateDomain.begin, filterByDateDomain.end] } }
                    : undefined,
                  onlySelection ? { id: { $in: selection.map((s) => s.id).concat([-1]) } } : undefined,
                  query.search && query.search.length > 0
                    ? {
                        $or: [{ tags: { $cont: query.search } }, { name: { $contL: query.search } }],
                      }
                    : undefined,
                  query.filters && query.filters.length > 0
                    ? {
                        $and: query.filters
                          .filter((filter) => !!filter.column.field)
                          .map((filter) => ({
                            [filter.column.field as string]: { $contL: filter.value },
                          })),
                      }
                    : undefined,
                ].filter((f) => f),
              }),
            })
            return {
              page: query.page,
              totalCount: res.total,
              data: res.data,
            }
          }}
          selectedRows={selection || []}
          onSelectionChange={onSelectionChange}
          columns={columns}
          options={{ pageSize, selection: !readonly, filtering: false, exportButton: false }}
        />
      )}

      {!readonly && (
        <div style={{ position: "absolute", bottom: "7px", left: "11px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlySelection}
                onChange={(e: any) => setOnlySelection(e.target.checked)}
                value="onlySelection"
                color="primary"
              />
            }
            label="Voir uniquement la sélection"
          />
        </div>
      )}
    </div>
  )
})
