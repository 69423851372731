import { Grid, Tab, Tabs } from "@material-ui/core"
import Title from "component/Title"
import ToleranceChart from "component/ToleranceChart"
import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import * as lodash from "lodash"
import React, { useMemo, useState } from "react"
import { DayModelDto, ModelDto } from "api/timelight-api"
import { createIdMap } from "../../lib/array"
import { hexToRGBA } from "../../lib/color"
import {
  dateObjToDayString,
  daysOfWeekList,
  formatDate,
  formatDayOfWeek,
  formatMonth,
  monthList,
  toDateObj,
} from "../../lib/date"
import { AnyChildren } from "../../react-type-helpers"
import { useDaysNearDate, useTemperatureContext } from "../../state/api"
import AppModal from "../AppModal"
import { getValueAfterTrends } from "../DayTrendForm"
import Loader from "../Loader"
import {
  createDateColumnConfig,
  createNumericColumnConfig,
  createTextEnumColumnConfig,
  extractColumnConfigAndExportConfig,
} from "../MaterialTable/material-table-helpers"
import { createTableExportCsv } from "../MaterialTable/material-table-helpers"
import { MaterialTable } from "../MaterialTable"
import ScatterDays from "../ScatterDays"
import { createModelCellConfig } from "../table/modelCell"
import { arraysToToleranceData } from "../ToleranceChart/util"
import { AlertForm } from "./AlertForm"
import { AlertRefView } from "./AlertRefView"
import DaysNearDateChart from "./DaysNearDateChart"
import { createChartDayDataFromApiInfos, createChartActivityLabelFromApiInfos } from "../ChartDayActivity/utils"

function TabPanel({ currentIndex, index, children }: { currentIndex: number; index: number; children: AnyChildren }) {
  return currentIndex === index ? <div style={{ height: "100%", width: "100%" }}>{children}</div> : <></>
}

export default function DaysNearDateModal({
  alertInfos = true,
  open,
  onClose,
  initialDate,
  sourceId,
  children,
}: {
  alertInfos?: boolean
  open: boolean
  onClose: () => void
  initialDate: string
  sourceId: number
  children?: AnyChildren
}) {
  const [date, setDate] = useState<string>(initialDate)
  const [{ loading, data }] = useDaysNearDate({ sourceId, dayDate: date, shouldTrigger: true })
  const allDates = (data ? data.days : []).map((d) => d.date).concat((data ? data.previsions : []).map((p) => p.date))

  const temperatureContext = useTemperatureContext({
    sourceId,
    minDate: lodash.min(allDates) as string,
    maxDate: lodash.max(allDates) as string,
    shouldTrigger: allDates.length > 0,
  })
  const [activeTabIdx, setActiveTabIdx] = React.useState(0)

  if (!data || loading) {
    return (
      <AppModal fullscreen={false} open={open} onClose={onClose} title={<Title>Activité du {formatDate(date)}</Title>}>
        <div style={{ display: "flex", minHeight: "600px", alignItems: "center", justifyContent: "center" }}>
          <Loader />
        </div>
      </AppModal>
    )
  }

  const modelsById = createIdMap(data.models)
  const day = data.days.find((d) => d.date === date)
  const alert = data.alerts.find((d) => d.date === date)
  const alertRef = data.alertRefs.find((d) => d.date === date)
  const prevision = data.previsions.find((p) => p.date === date)
  const activityTitle = day ? "Activité effective" : "Activité prévue"
  const model = prevision ? modelsById[prevision.model_id] : day && day.model_id ? modelsById[day.model_id] : null
  const allNearestDays = day ? data.nearestDistanceDays.concat([day]) : data.nearestDistanceDays

  function setSelectedDay(d: { date: string } | null) {
    if (d) {
      setDate(d.date)
    }
  }

  const unstackedData = arraysToToleranceData(
    (model ? model.top_tolerance : []).map((a) => getValueAfterTrends(data.dayTrends, date, a)),
    day ? day.activity : model ? model.activity.map((a) => getValueAfterTrends(data.dayTrends, date, a)) : [],
    (model ? model.bottom_tolerance : []).map((a) => getValueAfterTrends(data.dayTrends, date, a)),
  ).map((d) => ({ ...d, stackedTop: d.top - d.bottom }))

  const options: Highcharts.Options = {
    time: {
      timezone: "Europe/Paris",
    },
    chart: {
      type: "area",
      margin: [60, 10, 80, 50],
    },

    title: {
      text: undefined,
    },

    boost: {
      useGPUTranslations: false,
    },

    subtitle: {
      align: "left",
      x: 40,
    },

    xAxis: {
      categories: unstackedData.map((d) => d.x),
    },

    yAxis: {
      title: {
        text: null,
      },
    },

    tooltip: {
      crosshairs: true,
      shared: false,
    } as Highcharts.TooltipOptions,

    legend: {},

    plotOptions: {
      area: {
        stacking: "normal",
      },
    },

    series: [
      ...allNearestDays.map(
        (d) =>
          ({
            type: "line" as "line",
            id: d.date,
            name: formatDate(d.date),
            data: d.activity,
            zIndex: 1,
            showInLegend: false,
            color: d.model_id ? hexToRGBA(modelsById[d.model_id].color, d === day ? 1.0 : 0.5) : "#5e4fa2",
            shadow: d === day,
            selected: d === day,
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                marker: { enabled: false },
              },
            },
            events: {
              click: () => setDate(d.date),
            },
          } as Highcharts.SeriesLineOptions),
      ),
    ],
  }

  return (
    <AppModal
      fullscreen={false}
      open={open}
      onClose={onClose}
      title={
        <Tabs
          style={{ height: 50 }}
          value={activeTabIdx}
          onChange={(_, newValue) => setActiveTabIdx(newValue)}
          variant="scrollable"
          scrollButtons="off"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab
            value={0}
            label={
              <span>
                {activityTitle} du {formatDate(date)}
              </span>
            }
          />
          {day && (
            <Tab
              value={1}
              label={
                <span>
                  Les 10 journées aux profils
                  <br />
                  les plus semblables
                </span>
              }
            />
          )}
        </Tabs>
      }
    >
      <>
        <TabPanel currentIndex={activeTabIdx} index={0}>
          {alertInfos && alert && (
            <div style={{ marginTop: "1em", marginBottom: "4em" }}>
              <AlertForm alert={alert} models={data.models} />
            </div>
          )}
          {alertInfos && alertRef && (
            <div style={{ marginTop: "1em", marginBottom: "4em" }}>
              <AlertRefView alert={alertRef} />
            </div>
          )}

          <div>
            <Title>
              {activityTitle} du {formatDate(date)}
            </Title>
            <div style={{ height: "300px" }}>
              <ToleranceChart activityTitle={activityTitle} data={unstackedData} />
            </div>
          </div>

          {children && <div style={{ marginTop: "5em", marginBottom: "2em" }}>{children}</div>}
          <div style={{ marginTop: "3em", marginBottom: "1em" }}>
            <Title>
              Données à
              <span style={{ whiteSpace: "nowrap" }}>
                <sup>+</sup>&#8260;<sub>&#8722;</sub>
              </span>{" "}
              15 jours
            </Title>

            <div style={{ height: "400px", width: "100%" }}>
              <DaysNearDateChart
                activityLabel={createChartActivityLabelFromApiInfos({
                  days: data.days.map((d) => ({ ...d, model_id: d.model_id || null })),
                  previsions: data.previsions,
                })}
                highlightedDate={date}
                alertDates={data.alerts.map((a) => a.date).concat(data.alertRefs.map((a) => a.date))}
                days={createChartDayDataFromApiInfos({
                  days: data.days.map((d) => ({ ...d, model_id: d.model_id || null })),
                  previsions: data.previsions,
                  models: data.models,
                  context: temperatureContext,
                })}
                models={data.models}
                onDayClick={setSelectedDay}
              />
            </div>
          </div>
        </TabPanel>
        {day && (
          <TabPanel currentIndex={activeTabIdx} index={1}>
            <Title>Les 10 journées aux profils les plus semblables</Title>

            <Grid container style={{ height: "400px" }}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <HighchartsReact highcharts={Highcharts} options={options} />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <ScatterDays
                  models={data.models}
                  days={allNearestDays}
                  selectedDay={day}
                  setSelectedDay={setSelectedDay}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: "2em", marginBottom: "2em" }}>
              <ModelDayTable
                models={data.models}
                days={allNearestDays}
                setSelectedDay={setSelectedDay}
                selectedDay={day}
              />
            </div>
          </TabPanel>
        )}
      </>
    </AppModal>
  )
}

const ModelDayTable = React.memo(function AnomalyDayTableComponent({
  days,
  models,
  setSelectedDay,
  selectedDay,
}: {
  days: DayModelDto[]
  models: ModelDto[]
  setSelectedDay: (day: DayModelDto | null) => void
  selectedDay: DayModelDto | null | undefined
}) {
  // avoid re-rendering data table when row data change
  const dateDomain = days.map((row) => toDateObj(row.date))
  const minDateStr = dateObjToDayString(lodash.min(dateDomain) || new Date())
  const maxDateStr = dateObjToDayString(lodash.max(dateDomain) || new Date())

  const { columns, exportConfig } = useMemo(
    () =>
      extractColumnConfigAndExportConfig<DayModelDto>([
        createDateColumnConfig({
          title: "Date",
          getDate: (row) => toDateObj(row.date),
          domain: [toDateObj(minDateStr), toDateObj(maxDateStr)],
        }),
        createTextEnumColumnConfig({
          title: "Jour",
          getTextValue: (row) => formatDayOfWeek(row.date),
          enumValues: daysOfWeekList,
          sortEnum: false,
        }),
        createTextEnumColumnConfig({
          title: "Mois",
          getTextValue: (row) => formatMonth(row.date),
          enumValues: monthList,
        }),
        createModelCellConfig({
          models,
          getModelId: (row) => row.model_id,
        }),
        createNumericColumnConfig({
          title: "Activité",
          getValue: (row) => row.value,
        }),
      ]),
    [minDateStr, maxDateStr, models],
  )

  const hlIdx = selectedDay ? days.indexOf(selectedDay) : -1

  return (
    <MaterialTable
      data={days}
      title={""}
      onRowClick={setSelectedDay}
      highlightedRowsIds={hlIdx !== -1 ? [hlIdx] : undefined}
      selectedRows={selectedDay ? [selectedDay] : undefined}
      columns={columns}
      options={{
        selection: false,
        pageSize: days.length,
        exportCsv: createTableExportCsv({
          exportConfig,
          fileName: `day_data_near.csv`,
        }),
      }}
    />
  )
})
