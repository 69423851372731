import React from "react"
import { Bar, CartesianGrid, Cell, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { ModelDto } from "api/timelight-api"
import { createIdMap } from "../../lib/array"
import { DayActivityCustomAxisTick, DayData } from "../ChartDayActivity"
import DayActivityChartTooltip from "../ChartDayActivity/DayActivityChartTooltip"
import YAxisLargeNumberTick from "../YAxisLargeNumberTick"

export default function DaysNearDateChart({
  highlightedDate,
  alertDates,
  days,
  models,
  onDayClick,
  activityLabel,
}: {
  activityLabel: string | ((day: DayData) => string)
  highlightedDate: string
  alertDates: string[]
  days: DayData[]
  models: ModelDto[]
  onDayClick: (day: DayData) => void
}) {
  // create some quick access structures
  const modelsById = createIdMap(models)

  return (
    <ResponsiveContainer>
      <ComposedChart width={730} height={250} data={days}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" height={80} tick={(props) => <DayActivityCustomAxisTick {...props} />} />
        <YAxis
          yAxisId="values"
          scale="linear"
          dataKey="value"
          width={40}
          tick={(props) => <YAxisLargeNumberTick {...props} />}
        />
        <YAxis
          yAxisId="temperatures"
          scale="linear"
          orientation="right"
          unit="°C"
          tick={(props) => <YAxisLargeNumberTick {...props} />}
        />
        <Tooltip
          content={(props: any) => <DayActivityChartTooltip models={models} {...props} activityLabel={activityLabel} />}
        />
        <Bar yAxisId="values" dataKey="value" onClick={onDayClick}>
          {days.map((day, index) => {
            const model = day.model_id ? modelsById[day.model_id] : null
            const color = model ? model.color : "#CCCCCC"
            return (
              <Cell
                key={`cell-${index}`}
                fill={color}
                fillOpacity={(day.date === highlightedDate ? 1 : 0.8) * (day.transparent ? 0.5 : 1)}
                stroke={day.date === highlightedDate || alertDates.find((ad) => day.date === ad) ? "red" : "#bbb"}
                strokeWidth={2}
                strokeOpacity={day.transparent ? 0.3 : 0.6}
                cursor="pointer"
              />
            )
          })}
        </Bar>
        <Line yAxisId="temperatures" type="monotone" dataKey="tempAvg" dot={false} animationDuration={300} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
