import React from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useApiClient } from "../../state/api"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import {
  createTextColumnConfig,
  RowWithSource,
  fetchAndAddSourcesToRows,
} from "../../component/MaterialTable/material-table-helpers"
import AddIcon from "@material-ui/icons/Add"
import {
  extractColumnConfigAndExportConfig,
  createDateColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import { MaterialTable } from "component/MaterialTable"
import { useHistory } from "react-router"
import { AppRoute } from "../../state/route"
import { buildUrl } from "../../component/AppLink"
import { createTaskStatusColumnConfig } from "../../component/TaskStatusActivity"
import { ContextImpactTask } from "../../api/timelight-api/models/ContextImpactTask"
import AppLink from "../../component/AppLink/index"
import { AppButton } from "../../component/AppButton"
import {
  createTypeORMCrudFilter,
  createSourceIdColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import { EmptyStateRedirect } from "../../component/EmptyStateRedirect"
import { formatDuration } from "../../lib/date"
import { createTableExportCsv, Query } from "../../component/MaterialTable/material-table-helpers"
import {
  createTypeORMCrudLimit,
  createTypeORMCrudPage,
  createTypeORMCrudSort,
} from "../../component/MaterialTable/material-table-helpers"
import { QueryResult } from "material-table"

export function ContextImpactTaskList() {
  return (
    <PageContainer title="Analyse des données contextuelles">
      <AppHeader>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Analyse des données contextuelles
          </Typography>

          <AppLink route={AppRoute.CONTEXT_IMPACT_TASK_CREATE}>
            <AppButton variant="outlined" style={{ color: "white", borderColor: "white" }}>
              <AddIcon /> Nouvelle modélisation
            </AppButton>
          </AppLink>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <ContextImpactTaskTable />
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const ContextImpactTaskTable = React.memo(function ContextImpactTaskTableComponent() {
  const history = useHistory()
  const api = useApiClient()
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false)

  type RowType = RowWithSource<ContextImpactTask>

  const { columns, exportConfig } = extractColumnConfigAndExportConfig<RowType>([
    createTextColumnConfig({
      title: "Nom",
      getTextValue: (row) => row.title,
      field: "title",
    }),
    createSourceIdColumnConfig({
      title: "Source",
      getTextValue: (row) => row.source.name,
      field: "sourceId",
    }),
    createDateColumnConfig({
      title: "Date de création",
      getDate: (row) => row.createdDate,
      field: "createdDate",
    }),
    createTextColumnConfig({
      title: "Durée d'exécution",
      getTextValue: (row) => formatDuration(row.durationMs),
    }),
    createTaskStatusColumnConfig<RowType>(),
  ])

  async function getData(query: Query<RowType>): Promise<QueryResult<RowType>> {
    const res = await api.getManyBaseContextImpactTaskControllerContextImpactTask({
      limit: createTypeORMCrudLimit(query),
      page: createTypeORMCrudPage(query),
      sort: createTypeORMCrudSort(query),
      filter: createTypeORMCrudFilter(query),
    })
    if (query.filters.length === 0 && query.search.length === 0 && res.count === 0) {
      setIsEmpty(true)
    }
    return {
      page: query.page,
      totalCount: res.total,
      data: await fetchAndAddSourcesToRows(api, res.data, (row) => row.sourceId),
    }
  }

  return (
    <>
      {isEmpty ? (
        <EmptyStateRedirect
          route={AppRoute.CONTEXT_IMPACT_TASK_CREATE}
          actionText={"Créer une première modélisation"}
          headerText={"Bienvenue dans le module d'analyse de données contextuelles"}
          helpText={`Ce module vous permet d'analyser l'impact et la corrélation 
          d'une ou plusieurs sources de données.`}
          examplePicturePublicPath={"/examples/context_impact_example.png"}
        />
      ) : (
        <PageBlock titleInContent={true}>
          <MaterialTable
            title={`Historique des analyses de données contextuelles`}
            data={getData}
            onRowClick={(row) => {
              if (row) {
                history.push(
                  buildUrl({
                    route: AppRoute.CONTEXT_IMPACT_TASK_VIEW,
                    params: { taskId: row.id + "" },
                  }),
                )
              }
            }}
            columns={columns}
            options={{
              search: false,
              filtering: true,
              exportCsv: createTableExportCsv({
                exportConfig,
                fileName: `context_impact_tasks.csv`,
                getData,
              }),
            }}
          />
        </PageBlock>
      )}
    </>
  )
})
