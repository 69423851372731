import { CardActionArea } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined"
import TrackChangesOutlinedIcon from "@material-ui/icons/TrackChangesOutlined"
import ForecastIcon from "@material-ui/icons/Timeline"
import CompareArrows from "@material-ui/icons/CompareArrows"
import BubbleChart from "@material-ui/icons/BubbleChart"
import VerifiedUser from "@material-ui/icons/VerifiedUser"
import RoundIcon from "@material-ui/icons/PanoramaFishEye"
import React from "react"
import AppHeader from "../component/AppHeader"
import { GradientBackground } from "../component/GradientBackground"
import PageContainer from "../component/PageContainer"
import PageContent from "../component/PageContent"
import { AnyComponent } from "../react-type-helpers"
import { AppRoute } from "../state/route"
import { stepperTheme, useTheme } from "../theme"
import Logo from "component/Logo"
import { useTranslation } from "react-i18next"
import { useOnboardingState } from "../state/onboarding"
import AppLink from "../component/AppLink/AppLink"

type Step = keyof typeof stepperTheme

const useCardStyles = makeStyles<Theme, { step: Step; disabled: boolean }>((theme) => ({
  card: (props) => ({
    borderLeftStyle: "solid",
    borderLeftWidth: theme.spacing(0.5),
    borderColor: stepperTheme[props.step].main,
    height: "100%",
    filter: props.disabled ? "opacity(0.8) blur(1px)" : undefined,
  }),
  cardContent: (props) => ({
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(4),
    color: props.disabled ? stepperTheme.stepNext.main : stepperTheme[props.step].main,
    height: "100%",
    alignItems: "center",
  }),
}))

function SimpleCard({
  title,
  Icon,
  step,
  route,
  disabled = false,
}: {
  title?: string
  Icon: AnyComponent<{}>
  step: Step
  route?: AppRoute
  disabled?: boolean
}) {
  const classes = useCardStyles({ step, disabled })

  const content = (
    <>
      <Typography style={{ fontSize: "1.5em", color: route && !disabled ? "rgb(76, 76, 76)" : "rgb(175, 175, 175)" }}>
        {title}
      </Typography>
      <Icon />
    </>
  )
  return (
    <Card className={classes.card}>
      {route && !disabled ? (
        <AppLink route={route}>
          <CardActionArea className={classes.cardContent}>{content}</CardActionArea>
        </AppLink>
      ) : (
        <div className={classes.cardContent}>{content}</div>
      )}
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(4),
    minHeight: "80vh",
  },
  outerBox: { maxWidth: "75%", margin: "auto", padding: theme.spacing(4) },
  introText: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(40),
    lineHeight: 1,
  },
}))

export default function Home() {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { hasSource } = useOnboardingState()

  return (
    <PageContainer title="Accueil">
      <AppHeader openable={false} />
      <PageContent padding={false}>
        <GradientBackground
          base={theme.palette.primary.main}
          darker={theme.palette.primary.dark}
          className={classes.background}
        >
          <Paper className={classes.outerBox}>
            <Grid container spacing={3} className={classes.introText}>
              <Grid item xs={12}>
                <Logo size="large" />
                <Typography style={{ marginTop: "2em", marginBottom: "0.5em" }}>
                  Bonjour et bienvenue sur votre portail {t("global.appTitle")}
                </Typography>
                <Typography style={{ marginBottom: "0.5em" }}>
                  Vous trouverez ici les différents modules auxquels vous avez souscrits ainsi que les applications sur
                  mesure que nous avons développées pour vous
                </Typography>
                <Typography style={{ marginBottom: "0.5em" }}>
                  Pour toute question n'hésitez pas à nous contacter.
                </Typography>
                <Typography style={{ marginBottom: "0.5em" }}>Bonne navigation !</Typography>
                <br />
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ alignItems: "stretch" }}>
              <Grid item xs={12} md={4} lg={4}>
                <SimpleCard
                  title={`${t("global.appTitle")} DATA`}
                  Icon={VerifiedUser}
                  step="step1"
                  route={AppRoute.DATA_SOURCE_LIST}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <SimpleCard
                  title="Modélisation par profil type"
                  Icon={GroupWorkOutlinedIcon}
                  step="step2"
                  route={AppRoute.MONITOR_ACTIVITY}
                  disabled={hasSource === false}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <SimpleCard
                  title="Modélisation Statistique"
                  Icon={ForecastIcon}
                  step="step3"
                  route={AppRoute.FORECAST_LIST}
                  disabled={hasSource === false}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <SimpleCard
                  title="[BETA] Analyse de données contextuelles"
                  Icon={TrackChangesOutlinedIcon}
                  step="step4"
                  route={AppRoute.CONTEXT_IMPACT_LIST}
                  disabled={hasSource === false}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <SimpleCard
                  title="Synchronisation de séries"
                  Icon={CompareArrows}
                  step="step5"
                  route={AppRoute.SERIE_SYNC_LIST}
                  disabled={hasSource === false}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <SimpleCard
                  title="Comparaison de séries"
                  Icon={BubbleChart}
                  step="step6"
                  route={AppRoute.SOURCE_CLUSTER_LIST}
                  disabled={hasSource === false}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <SimpleCard
                  title="(A venir) Analyse de multi-séries"
                  Icon={RoundIcon}
                  step="stepNext"
                  disabled={hasSource === false}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <SimpleCard
                  title="(A venir) Analyse Spectrale"
                  Icon={RoundIcon}
                  step="stepNext"
                  disabled={hasSource === false}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <SimpleCard Icon={RoundIcon} step="stepNext" disabled={hasSource === false} />
              </Grid>
            </Grid>
          </Paper>
        </GradientBackground>
      </PageContent>
    </PageContainer>
  )
}
