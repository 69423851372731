/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CreateSourceDayDto,
  CreateSourceDayDtoFromJSON,
  CreateSourceDayDtoFromJSONTyped,
  CreateSourceDayDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface CreateSourceDto
 */
export interface CreateSourceDto {
  /**
   * The source name, between 2 and 100 characters
   * @type {string}
   * @memberof CreateSourceDto
   */
  name: string
  /**
   * The source activity size, this size will be used as the amount of elements in activity arrays.      Activity can be an array of 1, 2, 4, 8, 12, 24, 48, 72, 144 (default) or 1440 values values.        Each value represents a time window of 24h, 12h, 6h, 3h, 2h, 1h, 30m, 20m, 10m or 1m
   * @type {number}
   * @memberof CreateSourceDto
   */
  activitySize?: number
  /**
   * Defines the data interpolation method to fill missing data.      zero: replace missing data with zeros.     linear: classic and simple linear interpolation.
   * @type {string}
   * @memberof CreateSourceDto
   */
  interpolationMethod?: string
  /**
   * The source reference year, used to train our models
   * @type {number}
   * @memberof CreateSourceDto
   */
  referenceYear: number
  /**
   * The source latitude
   * @type {number}
   * @memberof CreateSourceDto
   */
  latitude?: number
  /**
   * The source longitude
   * @type {number}
   * @memberof CreateSourceDto
   */
  longitude?: number
  /**
   * The source physical address
   * @type {string}
   * @memberof CreateSourceDto
   */
  address?: string
  /**
   * List of day data
   * @type {Array<CreateSourceDayDto>}
   * @memberof CreateSourceDto
   */
  days: Array<CreateSourceDayDto>
  /**
   *
   * @type {boolean}
   * @memberof CreateSourceDto
   */
  dayModelsEnabled?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof CreateSourceDto
   */
  tags?: Array<string>
}

export function CreateSourceDtoFromJSON(json: any): CreateSourceDto {
  return CreateSourceDtoFromJSONTyped(json, false)
}

export function CreateSourceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSourceDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json["name"],
    activitySize: !exists(json, "activitySize") ? undefined : json["activitySize"],
    interpolationMethod: !exists(json, "interpolationMethod") ? undefined : json["interpolationMethod"],
    referenceYear: json["referenceYear"],
    latitude: !exists(json, "latitude") ? undefined : json["latitude"],
    longitude: !exists(json, "longitude") ? undefined : json["longitude"],
    address: !exists(json, "address") ? undefined : json["address"],
    days: (json["days"] as Array<any>).map(CreateSourceDayDtoFromJSON),
    dayModelsEnabled: !exists(json, "dayModelsEnabled") ? undefined : json["dayModelsEnabled"],
    tags: !exists(json, "tags") ? undefined : json["tags"],
  }
}

export function CreateSourceDtoToJSON(value?: CreateSourceDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    activitySize: value.activitySize,
    interpolationMethod: value.interpolationMethod,
    referenceYear: value.referenceYear,
    latitude: value.latitude,
    longitude: value.longitude,
    address: value.address,
    days: (value.days as Array<any>).map(CreateSourceDayDtoToJSON),
    dayModelsEnabled: value.dayModelsEnabled,
    tags: value.tags,
  }
}
