import { makeStyles } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { useConfig } from "../../config"
import logoSeWhite from "./secure-energy-white.png"
import logoSe from "./secure-energy.png"
import logoTlWhite from "./timelight-white.png"
import logoTl from "./timelight.png"
import logoSmall from "./logo-small.png"
import { Theme } from "@material-ui/core/styles"

const useStyle = makeStyles<Theme, { size: "medium" | "large" | "small" }>((theme) => ({
  logo: {
    maxHeight: (props) =>
      props.size === "medium" ? theme.spacing(4) : props.size === "small" ? theme.spacing(3) : theme.spacing(8),
  },
  img: {
    maxHeight: (props) =>
      props.size === "medium" ? theme.spacing(4) : props.size === "small" ? theme.spacing(3) : theme.spacing(8),
  },
}))

const logoMap: any = {
  SECURE_ENERGY: {
    black: logoSe,
    white: logoSeWhite,
  },
  TIMELIGHT: {
    black: logoTl,
    white: logoTlWhite,
  },
}

export default function Logo({
  version = "black",
  size = "medium",
}: {
  version?: "black" | "white"
  size?: "medium" | "large" | "small"
}) {
  const classes = useStyle({ size })
  const { t } = useTranslation()
  const { APP_TYPE } = useConfig()
  const logo = size === "small" ? logoSmall : logoMap[APP_TYPE][version]
  return (
    <div className={classes.logo}>
      <img className={classes.img} src={logo} alt={t("global.appTitle")} />
    </div>
  )
}
