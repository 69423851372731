/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  PrevisionApplyGroupDto,
  PrevisionApplyGroupDtoFromJSON,
  PrevisionApplyGroupDtoToJSON,
  PrevisionBulkSaveDto,
  PrevisionBulkSaveDtoFromJSON,
  PrevisionBulkSaveDtoToJSON,
  PrevisionBulkSaveResultDto,
  PrevisionBulkSaveResultDtoFromJSON,
  PrevisionBulkSaveResultDtoToJSON,
  PrevisionListDto,
  PrevisionListDtoFromJSON,
  PrevisionListDtoToJSON,
  PrevisionPatchDto,
  PrevisionPatchDtoFromJSON,
  PrevisionPatchDtoToJSON,
  PrevisionUpdateResultDto,
  PrevisionUpdateResultDtoFromJSON,
  PrevisionUpdateResultDtoToJSON,
} from "../models"

export interface PrevisionControllerApplyPrevisionsOfSourceToGroupRequest {
  previsionApplyGroupDto: PrevisionApplyGroupDto
}

export interface PrevisionControllerListDaysRequest {
  sourceId: number
  year: number
}

export interface PrevisionControllerPatchPrevisionRequest {
  previsionPatchDto: PrevisionPatchDto
}

export interface PrevisionControllerSaveDefaultPrevisionsRequest {
  sourceId: number
  year: number
}

export interface PrevisionControllerSavePrevisionRequest {
  previsionBulkSaveDto: PrevisionBulkSaveDto
}

/**
 * no description
 */
export class PrevisionApi extends runtime.BaseAPI {
  /**
   * Apply a source prevision to the whole group
   */
  async previsionControllerApplyPrevisionsOfSourceToGroupRaw(
    requestParameters: PrevisionControllerApplyPrevisionsOfSourceToGroupRequest,
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.previsionApplyGroupDto === null || requestParameters.previsionApplyGroupDto === undefined) {
      throw new runtime.RequiredError(
        "previsionApplyGroupDto",
        "Required parameter requestParameters.previsionApplyGroupDto was null or undefined when calling previsionControllerApplyPrevisionsOfSourceToGroup.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prevision/group-apply-prevision`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PrevisionApplyGroupDtoToJSON(requestParameters.previsionApplyGroupDto),
    })

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Apply a source prevision to the whole group
   */
  async previsionControllerApplyPrevisionsOfSourceToGroup(
    requestParameters: PrevisionControllerApplyPrevisionsOfSourceToGroupRequest,
  ): Promise<object> {
    const response = await this.previsionControllerApplyPrevisionsOfSourceToGroupRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch data previsions for a given year
   */
  async previsionControllerListDaysRaw(
    requestParameters: PrevisionControllerListDaysRequest,
  ): Promise<runtime.ApiResponse<PrevisionListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling previsionControllerListDays.",
      )
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling previsionControllerListDays.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prevision/list/{sourceId}/{year}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => PrevisionListDtoFromJSON(jsonValue))
  }

  /**
   * Fetch data previsions for a given year
   */
  async previsionControllerListDays(requestParameters: PrevisionControllerListDaysRequest): Promise<PrevisionListDto> {
    const response = await this.previsionControllerListDaysRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update a specific prevision
   */
  async previsionControllerPatchPrevisionRaw(
    requestParameters: PrevisionControllerPatchPrevisionRequest,
  ): Promise<runtime.ApiResponse<PrevisionUpdateResultDto>> {
    if (requestParameters.previsionPatchDto === null || requestParameters.previsionPatchDto === undefined) {
      throw new runtime.RequiredError(
        "previsionPatchDto",
        "Required parameter requestParameters.previsionPatchDto was null or undefined when calling previsionControllerPatchPrevision.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prevision/update`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: PrevisionPatchDtoToJSON(requestParameters.previsionPatchDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => PrevisionUpdateResultDtoFromJSON(jsonValue))
  }

  /**
   * Update a specific prevision
   */
  async previsionControllerPatchPrevision(
    requestParameters: PrevisionControllerPatchPrevisionRequest,
  ): Promise<PrevisionUpdateResultDto> {
    const response = await this.previsionControllerPatchPrevisionRaw(requestParameters)
    return await response.value()
  }

  /**
   * Generate default previsions for the source and save them
   */
  async previsionControllerSaveDefaultPrevisionsRaw(
    requestParameters: PrevisionControllerSaveDefaultPrevisionsRequest,
  ): Promise<runtime.ApiResponse<PrevisionBulkSaveResultDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling previsionControllerSaveDefaultPrevisions.",
      )
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling previsionControllerSaveDefaultPrevisions.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prevision/save-default-previsions/{sourceId}/{year}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => PrevisionBulkSaveResultDtoFromJSON(jsonValue))
  }

  /**
   * Generate default previsions for the source and save them
   */
  async previsionControllerSaveDefaultPrevisions(
    requestParameters: PrevisionControllerSaveDefaultPrevisionsRequest,
  ): Promise<PrevisionBulkSaveResultDto> {
    const response = await this.previsionControllerSaveDefaultPrevisionsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Save many previsions at once
   */
  async previsionControllerSavePrevisionRaw(
    requestParameters: PrevisionControllerSavePrevisionRequest,
  ): Promise<runtime.ApiResponse<PrevisionBulkSaveResultDto>> {
    if (requestParameters.previsionBulkSaveDto === null || requestParameters.previsionBulkSaveDto === undefined) {
      throw new runtime.RequiredError(
        "previsionBulkSaveDto",
        "Required parameter requestParameters.previsionBulkSaveDto was null or undefined when calling previsionControllerSavePrevision.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prevision/save`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PrevisionBulkSaveDtoToJSON(requestParameters.previsionBulkSaveDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => PrevisionBulkSaveResultDtoFromJSON(jsonValue))
  }

  /**
   * Save many previsions at once
   */
  async previsionControllerSavePrevision(
    requestParameters: PrevisionControllerSavePrevisionRequest,
  ): Promise<PrevisionBulkSaveResultDto> {
    const response = await this.previsionControllerSavePrevisionRaw(requestParameters)
    return await response.value()
  }
}
