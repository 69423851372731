/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  GetManySourceClusterModelResponseDto,
  GetManySourceClusterModelResponseDtoFromJSON,
  GetManySourceClusterModelResponseDtoToJSON,
  GetManySourceClusterTaskResponseDto,
  GetManySourceClusterTaskResponseDtoFromJSON,
  GetManySourceClusterTaskResponseDtoToJSON,
  SourceClusterModel,
  SourceClusterModelFromJSON,
  SourceClusterModelToJSON,
  SourceClusterTask,
  SourceClusterTaskFromJSON,
  SourceClusterTaskToJSON,
  SourceClusterTaskInputParams,
  SourceClusterTaskInputParamsFromJSON,
  SourceClusterTaskInputParamsToJSON,
} from "../models"

export interface GetManyBaseSourceClusterModelControllerSourceClusterModelRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetManyBaseSourceClusterTaskControllerSourceClusterTaskRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseSourceClusterModelControllerSourceClusterModelRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface GetOneBaseSourceClusterTaskControllerSourceClusterTaskRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface SourceClusterTaskControllerCreateOneRequest {
  sourceClusterTaskInputParams: SourceClusterTaskInputParams
}

export interface SourceClusterTaskControllerDeleteOneRequest {
  sourceClusterTaskId: number
}

export interface SourceClusterTaskControllerUpdateOneRequest {
  sourceClusterTaskId: number
  sourceClusterTaskInputParams: SourceClusterTaskInputParams
}

/**
 * no description
 */
export class SourceClusterApi extends runtime.BaseAPI {
  /**
   * Retrieve many SourceClusterModel
   */
  async getManyBaseSourceClusterModelControllerSourceClusterModelRaw(
    requestParameters: GetManyBaseSourceClusterModelControllerSourceClusterModelRequest,
  ): Promise<runtime.ApiResponse<GetManySourceClusterModelResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-cluster-model`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManySourceClusterModelResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many SourceClusterModel
   */
  async getManyBaseSourceClusterModelControllerSourceClusterModel(
    requestParameters: GetManyBaseSourceClusterModelControllerSourceClusterModelRequest,
  ): Promise<GetManySourceClusterModelResponseDto> {
    const response = await this.getManyBaseSourceClusterModelControllerSourceClusterModelRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve many SourceClusterTask
   */
  async getManyBaseSourceClusterTaskControllerSourceClusterTaskRaw(
    requestParameters: GetManyBaseSourceClusterTaskControllerSourceClusterTaskRequest,
  ): Promise<runtime.ApiResponse<GetManySourceClusterTaskResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-cluster-task`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManySourceClusterTaskResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many SourceClusterTask
   */
  async getManyBaseSourceClusterTaskControllerSourceClusterTask(
    requestParameters: GetManyBaseSourceClusterTaskControllerSourceClusterTaskRequest,
  ): Promise<GetManySourceClusterTaskResponseDto> {
    const response = await this.getManyBaseSourceClusterTaskControllerSourceClusterTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one SourceClusterModel
   */
  async getOneBaseSourceClusterModelControllerSourceClusterModelRaw(
    requestParameters: GetOneBaseSourceClusterModelControllerSourceClusterModelRequest,
  ): Promise<runtime.ApiResponse<SourceClusterModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseSourceClusterModelControllerSourceClusterModel.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-cluster-model/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceClusterModelFromJSON(jsonValue))
  }

  /**
   * Retrieve one SourceClusterModel
   */
  async getOneBaseSourceClusterModelControllerSourceClusterModel(
    requestParameters: GetOneBaseSourceClusterModelControllerSourceClusterModelRequest,
  ): Promise<SourceClusterModel> {
    const response = await this.getOneBaseSourceClusterModelControllerSourceClusterModelRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one SourceClusterTask
   */
  async getOneBaseSourceClusterTaskControllerSourceClusterTaskRaw(
    requestParameters: GetOneBaseSourceClusterTaskControllerSourceClusterTaskRequest,
  ): Promise<runtime.ApiResponse<SourceClusterTask>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseSourceClusterTaskControllerSourceClusterTask.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-cluster-task/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceClusterTaskFromJSON(jsonValue))
  }

  /**
   * Retrieve one SourceClusterTask
   */
  async getOneBaseSourceClusterTaskControllerSourceClusterTask(
    requestParameters: GetOneBaseSourceClusterTaskControllerSourceClusterTaskRequest,
  ): Promise<SourceClusterTask> {
    const response = await this.getOneBaseSourceClusterTaskControllerSourceClusterTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Create a new synchronisation task
   */
  async sourceClusterTaskControllerCreateOneRaw(
    requestParameters: SourceClusterTaskControllerCreateOneRequest,
  ): Promise<runtime.ApiResponse<SourceClusterTask>> {
    if (
      requestParameters.sourceClusterTaskInputParams === null ||
      requestParameters.sourceClusterTaskInputParams === undefined
    ) {
      throw new runtime.RequiredError(
        "sourceClusterTaskInputParams",
        "Required parameter requestParameters.sourceClusterTaskInputParams was null or undefined when calling sourceClusterTaskControllerCreateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-cluster-task`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SourceClusterTaskInputParamsToJSON(requestParameters.sourceClusterTaskInputParams),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceClusterTaskFromJSON(jsonValue))
  }

  /**
   * Create a new synchronisation task
   */
  async sourceClusterTaskControllerCreateOne(
    requestParameters: SourceClusterTaskControllerCreateOneRequest,
  ): Promise<SourceClusterTask> {
    const response = await this.sourceClusterTaskControllerCreateOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Delete a source custering task
   */
  async sourceClusterTaskControllerDeleteOneRaw(
    requestParameters: SourceClusterTaskControllerDeleteOneRequest,
  ): Promise<runtime.ApiResponse<SourceClusterTask>> {
    if (requestParameters.sourceClusterTaskId === null || requestParameters.sourceClusterTaskId === undefined) {
      throw new runtime.RequiredError(
        "sourceClusterTaskId",
        "Required parameter requestParameters.sourceClusterTaskId was null or undefined when calling sourceClusterTaskControllerDeleteOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-cluster-task/{sourceClusterTaskId}`.replace(
        `{${"sourceClusterTaskId"}}`,
        encodeURIComponent(String(requestParameters.sourceClusterTaskId)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceClusterTaskFromJSON(jsonValue))
  }

  /**
   * Delete a source custering task
   */
  async sourceClusterTaskControllerDeleteOne(
    requestParameters: SourceClusterTaskControllerDeleteOneRequest,
  ): Promise<SourceClusterTask> {
    const response = await this.sourceClusterTaskControllerDeleteOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update a source cluster params and re-run task, wipes out previous data
   */
  async sourceClusterTaskControllerUpdateOneRaw(
    requestParameters: SourceClusterTaskControllerUpdateOneRequest,
  ): Promise<runtime.ApiResponse<SourceClusterTask>> {
    if (requestParameters.sourceClusterTaskId === null || requestParameters.sourceClusterTaskId === undefined) {
      throw new runtime.RequiredError(
        "sourceClusterTaskId",
        "Required parameter requestParameters.sourceClusterTaskId was null or undefined when calling sourceClusterTaskControllerUpdateOne.",
      )
    }

    if (
      requestParameters.sourceClusterTaskInputParams === null ||
      requestParameters.sourceClusterTaskInputParams === undefined
    ) {
      throw new runtime.RequiredError(
        "sourceClusterTaskInputParams",
        "Required parameter requestParameters.sourceClusterTaskInputParams was null or undefined when calling sourceClusterTaskControllerUpdateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-cluster-task/{sourceClusterTaskId}`.replace(
        `{${"sourceClusterTaskId"}}`,
        encodeURIComponent(String(requestParameters.sourceClusterTaskId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SourceClusterTaskInputParamsToJSON(requestParameters.sourceClusterTaskInputParams),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceClusterTaskFromJSON(jsonValue))
  }

  /**
   * Update a source cluster params and re-run task, wipes out previous data
   */
  async sourceClusterTaskControllerUpdateOne(
    requestParameters: SourceClusterTaskControllerUpdateOneRequest,
  ): Promise<SourceClusterTask> {
    const response = await this.sourceClusterTaskControllerUpdateOneRaw(requestParameters)
    return await response.value()
  }
}
