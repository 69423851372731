/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  ContextImpactSummaryDto,
  ContextImpactSummaryDtoFromJSON,
  ContextImpactSummaryDtoToJSON,
  ContextImpactTask,
  ContextImpactTaskFromJSON,
  ContextImpactTaskToJSON,
  ContextImpactTaskInputParams,
  ContextImpactTaskInputParamsFromJSON,
  ContextImpactTaskInputParamsToJSON,
  GetManyContextImpactTaskResponseDto,
  GetManyContextImpactTaskResponseDtoFromJSON,
  GetManyContextImpactTaskResponseDtoToJSON,
} from "../models"

export interface ContextImpactTaskControllerCreateOneRequest {
  contextImpactTaskInputParams: ContextImpactTaskInputParams
}

export interface ContextImpactTaskControllerDeleteOneRequest {
  contextImpactTaskId: number
}

export interface ContextImpactTaskControllerGetDaySummaryRequest {
  contextImpactTaskId: number
  day: string
}

export interface ContextImpactTaskControllerUpdateOneRequest {
  contextImpactTaskId: number
  contextImpactTaskInputParams: ContextImpactTaskInputParams
}

export interface GetManyBaseContextImpactTaskControllerContextImpactTaskRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseContextImpactTaskControllerContextImpactTaskRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

/**
 * no description
 */
export class ContextImpactApi extends runtime.BaseAPI {
  /**
   * Create a new context impact task
   */
  async contextImpactTaskControllerCreateOneRaw(
    requestParameters: ContextImpactTaskControllerCreateOneRequest,
  ): Promise<runtime.ApiResponse<ContextImpactTask>> {
    if (
      requestParameters.contextImpactTaskInputParams === null ||
      requestParameters.contextImpactTaskInputParams === undefined
    ) {
      throw new runtime.RequiredError(
        "contextImpactTaskInputParams",
        "Required parameter requestParameters.contextImpactTaskInputParams was null or undefined when calling contextImpactTaskControllerCreateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/context-impact-task`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ContextImpactTaskInputParamsToJSON(requestParameters.contextImpactTaskInputParams),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ContextImpactTaskFromJSON(jsonValue))
  }

  /**
   * Create a new context impact task
   */
  async contextImpactTaskControllerCreateOne(
    requestParameters: ContextImpactTaskControllerCreateOneRequest,
  ): Promise<ContextImpactTask> {
    const response = await this.contextImpactTaskControllerCreateOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * delete a context impact task
   */
  async contextImpactTaskControllerDeleteOneRaw(
    requestParameters: ContextImpactTaskControllerDeleteOneRequest,
  ): Promise<runtime.ApiResponse<ContextImpactTask>> {
    if (requestParameters.contextImpactTaskId === null || requestParameters.contextImpactTaskId === undefined) {
      throw new runtime.RequiredError(
        "contextImpactTaskId",
        "Required parameter requestParameters.contextImpactTaskId was null or undefined when calling contextImpactTaskControllerDeleteOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/context-impact-task/{contextImpactTaskId}`.replace(
        `{${"contextImpactTaskId"}}`,
        encodeURIComponent(String(requestParameters.contextImpactTaskId)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ContextImpactTaskFromJSON(jsonValue))
  }

  /**
   * delete a context impact task
   */
  async contextImpactTaskControllerDeleteOne(
    requestParameters: ContextImpactTaskControllerDeleteOneRequest,
  ): Promise<ContextImpactTask> {
    const response = await this.contextImpactTaskControllerDeleteOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch a context impact task
   */
  async contextImpactTaskControllerGetDaySummaryRaw(
    requestParameters: ContextImpactTaskControllerGetDaySummaryRequest,
  ): Promise<runtime.ApiResponse<ContextImpactSummaryDto>> {
    if (requestParameters.contextImpactTaskId === null || requestParameters.contextImpactTaskId === undefined) {
      throw new runtime.RequiredError(
        "contextImpactTaskId",
        "Required parameter requestParameters.contextImpactTaskId was null or undefined when calling contextImpactTaskControllerGetDaySummary.",
      )
    }

    if (requestParameters.day === null || requestParameters.day === undefined) {
      throw new runtime.RequiredError(
        "day",
        "Required parameter requestParameters.day was null or undefined when calling contextImpactTaskControllerGetDaySummary.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/context-impact-task/{contextImpactTaskId}/summary/{day}`
        .replace(`{${"contextImpactTaskId"}}`, encodeURIComponent(String(requestParameters.contextImpactTaskId)))
        .replace(`{${"day"}}`, encodeURIComponent(String(requestParameters.day))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ContextImpactSummaryDtoFromJSON(jsonValue))
  }

  /**
   * Fetch a context impact task
   */
  async contextImpactTaskControllerGetDaySummary(
    requestParameters: ContextImpactTaskControllerGetDaySummaryRequest,
  ): Promise<ContextImpactSummaryDto> {
    const response = await this.contextImpactTaskControllerGetDaySummaryRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update a context impact params and re-run task, wipes out previous data
   */
  async contextImpactTaskControllerUpdateOneRaw(
    requestParameters: ContextImpactTaskControllerUpdateOneRequest,
  ): Promise<runtime.ApiResponse<ContextImpactTask>> {
    if (requestParameters.contextImpactTaskId === null || requestParameters.contextImpactTaskId === undefined) {
      throw new runtime.RequiredError(
        "contextImpactTaskId",
        "Required parameter requestParameters.contextImpactTaskId was null or undefined when calling contextImpactTaskControllerUpdateOne.",
      )
    }

    if (
      requestParameters.contextImpactTaskInputParams === null ||
      requestParameters.contextImpactTaskInputParams === undefined
    ) {
      throw new runtime.RequiredError(
        "contextImpactTaskInputParams",
        "Required parameter requestParameters.contextImpactTaskInputParams was null or undefined when calling contextImpactTaskControllerUpdateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/context-impact-task/{contextImpactTaskId}`.replace(
        `{${"contextImpactTaskId"}}`,
        encodeURIComponent(String(requestParameters.contextImpactTaskId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ContextImpactTaskInputParamsToJSON(requestParameters.contextImpactTaskInputParams),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ContextImpactTaskFromJSON(jsonValue))
  }

  /**
   * Update a context impact params and re-run task, wipes out previous data
   */
  async contextImpactTaskControllerUpdateOne(
    requestParameters: ContextImpactTaskControllerUpdateOneRequest,
  ): Promise<ContextImpactTask> {
    const response = await this.contextImpactTaskControllerUpdateOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve many ContextImpactTask
   */
  async getManyBaseContextImpactTaskControllerContextImpactTaskRaw(
    requestParameters: GetManyBaseContextImpactTaskControllerContextImpactTaskRequest,
  ): Promise<runtime.ApiResponse<GetManyContextImpactTaskResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/context-impact-task`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyContextImpactTaskResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many ContextImpactTask
   */
  async getManyBaseContextImpactTaskControllerContextImpactTask(
    requestParameters: GetManyBaseContextImpactTaskControllerContextImpactTaskRequest,
  ): Promise<GetManyContextImpactTaskResponseDto> {
    const response = await this.getManyBaseContextImpactTaskControllerContextImpactTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one ContextImpactTask
   */
  async getOneBaseContextImpactTaskControllerContextImpactTaskRaw(
    requestParameters: GetOneBaseContextImpactTaskControllerContextImpactTaskRequest,
  ): Promise<runtime.ApiResponse<ContextImpactTask>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseContextImpactTaskControllerContextImpactTask.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/context-impact-task/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ContextImpactTaskFromJSON(jsonValue))
  }

  /**
   * Retrieve one ContextImpactTask
   */
  async getOneBaseContextImpactTaskControllerContextImpactTask(
    requestParameters: GetOneBaseContextImpactTaskControllerContextImpactTaskRequest,
  ): Promise<ContextImpactTask> {
    const response = await this.getOneBaseContextImpactTaskControllerContextImpactTaskRaw(requestParameters)
    return await response.value()
  }
}
