/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayContext, DayContextFromJSON, DayContextFromJSONTyped, DayContextToJSON } from "./"

/**
 *
 * @export
 * @interface DayContextListDto
 */
export interface DayContextListDto {
  /**
   * List of day context
   * @type {Array<DayContext>}
   * @memberof DayContextListDto
   */
  dayContexts: Array<DayContext>
}

export function DayContextListDtoFromJSON(json: any): DayContextListDto {
  return DayContextListDtoFromJSONTyped(json, false)
}

export function DayContextListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayContextListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dayContexts: (json["dayContexts"] as Array<any>).map(DayContextFromJSON),
  }
}

export function DayContextListDtoToJSON(value?: DayContextListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    dayContexts: (value.dayContexts as Array<any>).map(DayContextToJSON),
  }
}
