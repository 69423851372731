/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface DayPatchDto
 */
export interface DayPatchDto {
  /**
   * The date with a format YYYY-MM-DD
   * @type {string}
   * @memberof DayPatchDto
   */
  date: string
  /**
   * True if the day is an anomaly and should be ignored by the ML engine
   * @type {boolean}
   * @memberof DayPatchDto
   */
  anomaly: boolean
}

export function DayPatchDtoFromJSON(json: any): DayPatchDto {
  return DayPatchDtoFromJSONTyped(json, false)
}

export function DayPatchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayPatchDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    date: json["date"],
    anomaly: json["anomaly"],
  }
}

export function DayPatchDtoToJSON(value?: DayPatchDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date: value.date,
    anomaly: value.anomaly,
  }
}
