import React from "react"
import { XAxisPlotBandsOptions } from "highcharts"
import * as lodash from "lodash"
import { SourceSelectPreviewChart } from "../SourceSelectPreviewChart"
import { Field } from "react-final-form"
import { dayMs } from "../../lib/date"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"

export interface SourcePreviewFormState {
  sourceId: number
  previewLimits: { begin: Date; end: Date }
  // todo: refactor these
  trainBand: XAxisPlotBandsOptions
  forecastBand: XAxisPlotBandsOptions
}

export function createSourceSelectPreviewField<FormState>(props: { name: keyof FormState & string }) {
  return <Field {...props} render={SourceSelectPreviewFieldRender} />
}

const SourceSelectPreviewFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<SourcePreviewFormState, {}>) => {
    return (
      <SourceSelectPreviewChart
        sourceId={props.input.value.sourceId}
        previewLimits={props.input.value.previewLimits}
        additionalHighchartOptions={{
          xAxis: [
            {
              plotBands: [props.input.value.trainBand, props.input.value.forecastBand],
              min: props.input.value.previewLimits.begin.getTime() - 1 * dayMs,
              max: props.input.value.previewLimits.end.getTime() + 1 * dayMs,
            },
          ],
        }}
      />
    )
  },
  (props: CustomFieldRenderProps<SourcePreviewFormState, {}>) => {
    return (
      [
        props.input.value.sourceId,
        props.input.value.previewLimits.begin,
        props.input.value.previewLimits.end,
        props.input.value.trainBand.from,
        props.input.value.trainBand.to,
        props.input.value.forecastBand.from,
        props.input.value.forecastBand.to,
      ].join("|") +
      "|" +
      getFormFieldPropsMemoizeKey(props)
    )
  },
)
