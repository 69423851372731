import React, { useMemo } from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useApiClient } from "../../state/api"
import { Grid, InputLabel, rgbToHex } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import { ForecastType } from "../../api/timelight-api/models/ForecastType"
import { PremiumFeature } from "../../component/PremiumFeature"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { useHistory, Prompt } from "react-router"
import { useTheme } from "../../theme"
import { hexToRGBA } from "../../lib/color"
import { ConfirmDialogButton } from "../../component/ConfirmDialogButton"
import * as lodash from "lodash"
import { toDateObj, dateObjToDayString, truncateDateToDay, dayMs } from "../../lib/date"
import { Form } from "react-final-form"
import { DateRangeFieldState, createDateRangeField } from "../../component/form/DateRangeField"
import { createCalculator, DecoratorConfig, applyCalculatorParams } from "../../component/form/decorator"
import { buildUrl } from "../../component/AppLink"
import { AppRoute, useAppRoute } from "../../state/route"
import { SourceTableSelectFormState, createSourceSelectTableField } from "../../component/form/SourceSelectTableField"
import { createSourceSelectPreviewField, SourcePreviewFormState } from "../../component/form/SourcePreviewField"
import { SourceSelectFormState, createSourceSelectField } from "../../component/form/SourceSelectField"
import { createListSelectField } from "../../component/form/ListSelectField"
import {
  ContextTypesSelectFormState,
  createContextTypeSelectTableField,
} from "../../component/form/ContextTypesSelectField"
import { createTextField } from "component/form/TextField"
import { createCheckboxField, CheckboxFormState } from "../../component/form/CheckboxField"
import { AsyncHookParams, useAsyncAction } from "../../state/async"
import Loader from "../../component/Loader"
import { TextFormState } from "../../component/form/TextField"
import { forecastMethods } from "./forecastMethods"
import { ForecastTask } from "../../api/timelight-api/models/ForecastTask"

interface FormState {
  source: SourceSelectFormState
  sourcePreview: SourcePreviewFormState
  forecastType: ForecastType
  title: TextFormState
  trainRange: DateRangeFieldState
  forecastRange: DateRangeFieldState
  contextTypes: ContextTypesSelectFormState
  sourceIds: SourceTableSelectFormState
  showOnlyDateCompatibleContext: CheckboxFormState
}

export function ForecastTaskCreate() {
  const theme = useTheme()
  const history = useHistory()
  const api = useApiClient()

  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Tâche créée, cette opération peut prendre jusqu'à 5 minutes",
  })

  const fetchSourceDomain = useMemo(() => {
    const fetcher = (sourceId: number) => api.sourceControllerSourceDateDomain({ sourceId })
    return lodash.memoize(fetcher)
  }, [api])

  const calculatorParams = useMemo(
    () => [
      {
        field: "trainRange", // when train range change
        updates: {
          sourcePreview: (value, allValues) => {
            return value
              ? {
                  ...allValues.sourcePreview,
                  previewLimits: {
                    ...allValues.sourcePreview.previewLimits,
                    begin: value.range.begin || allValues.sourcePreview.previewLimits.begin,
                  },
                  trainBand: {
                    ...allValues.sourcePreview.trainBand,
                    from:
                      value.range.begin && value.range.end
                        ? toDateObj(dateObjToDayString(value.range.begin)).getTime()
                        : new Date(),
                    to:
                      value.range.begin && value.range.end
                        ? toDateObj(dateObjToDayString(value.range.end)).getTime() + dayMs
                        : new Date(),
                  },
                }
              : allValues.sourcePreview
          },
          contextTypes: (value, allValues) => {
            return allValues.showOnlyDateCompatibleContext
              ? {
                  ...allValues.contextTypes,
                  filterByDateDomain:
                    value.range.begin && value.range.end ? { begin: value.range.begin, end: value.range.end } : null,
                }
              : allValues.contextTypes
          },
          sourceIds: (value, allValues) => {
            return allValues.showOnlyDateCompatibleContext
              ? {
                  ...allValues.sourceIds,
                  filterByDateDomain:
                    value.range.begin && value.range.end ? { begin: value.range.begin, end: value.range.end } : null,
                }
              : allValues.sourceIds
          },
        },
      } as DecoratorConfig<FormState, "trainRange">,

      {
        field: "forecastRange", // when train range change
        updates: {
          sourcePreview: (value, allValues) => {
            return value
              ? {
                  ...allValues.sourcePreview,
                  previewLimits: {
                    ...allValues.sourcePreview.previewLimits,
                    end: value.range.end || allValues.sourcePreview.previewLimits.end,
                  },
                  forecastBand: {
                    ...allValues.sourcePreview.forecastBand,
                    from:
                      value.range.begin && value.range.end
                        ? truncateDateToDay(value.range.begin).getTime()
                        : new Date(),
                    to:
                      value.range.begin && value.range.end
                        ? truncateDateToDay(value.range.end).getTime() + dayMs
                        : new Date(),
                  },
                }
              : allValues.sourcePreview
          },
        },
      } as DecoratorConfig<FormState, "forecastRange">,
      {
        field: "source",
        updates: {
          sourcePreview: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.sourcePreview
            }
            // fetch date
            const domain = await fetchSourceDomain(value.sourceId)

            return {
              ...allValues.sourcePreview,
              sourceId: value.sourceId,
              previewLimits: {
                ...allValues.sourcePreview.previewLimits,
                begin: domain.beginDate,
                end: truncateDateToDay(new Date(domain.endDate.getTime() + (1 + 2 * 7) * dayMs)),
              },
              trainBand: {
                ...allValues.sourcePreview.trainBand,
                from: domain.beginDate.getTime(),
                to: domain.endDate.getTime(),
              },
              forecastBand: {
                ...allValues.sourcePreview.forecastBand,
                from: truncateDateToDay(new Date(domain.endDate.getTime() + 1 * dayMs)).getTime(),
                to: truncateDateToDay(new Date(domain.endDate.getTime() + (1 + 2 * 7) * dayMs)).getTime(),
              },
            }
          },
          contextTypes: (value, allValues) => {
            return value.sourceId ? { ...allValues.contextTypes, sourceId: value.sourceId } : allValues.contextTypes
          },
          trainRange: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.trainRange
            }
            // fetch date
            const domain = await fetchSourceDomain(value.sourceId)
            return {
              ...allValues.trainRange,
              // - valeurs par défaut : période d'apprentissage :
              // [ début des données ; fin des données ]
              range: {
                begin: domain.beginDate,
                end: domain.endDate,
              },
              // range maximal de la période d'apprentissage :
              // début des données de la source <-> fin des données de la source
              minDate: domain.beginDate,
              maxDate: domain.endDate,
            }
          },

          forecastRange: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.forecastRange
            }
            // fetch date
            const domain = await fetchSourceDomain(value.sourceId)
            return {
              ...allValues.forecastRange,
              // - valeurs par défaut :  période de modelisation : ] fin des données ; fin des données + 2 semaines ]
              range: {
                begin: truncateDateToDay(new Date(domain.endDate.getTime() + 1 * dayMs)),
                end: truncateDateToDay(new Date(domain.endDate.getTime() + (1 + 2 * 7) * dayMs)),
              },
              // range maximal de la période de modélisation : début des données de la source + 1 semaine <-> infini
              minDate: truncateDateToDay(new Date(domain.beginDate.getTime() + 7 * dayMs)),
              maxDate: new Date(domain.endDate.getTime() + 50000 * dayMs),
            }
          },
        },
      } as DecoratorConfig<FormState, "source">,
      {
        field: "showOnlyDateCompatibleContext",
        updates: {
          contextTypes: (value, allValues) => {
            return {
              ...allValues.contextTypes,
              filterByDateDomain:
                value && allValues.trainRange.range.begin && allValues.trainRange.range.end
                  ? { begin: allValues.trainRange.range.begin, end: allValues.trainRange.range.end }
                  : null,
            }
          },
          sourceIds: (value, allValues) => {
            return {
              ...allValues.sourceIds,
              filterByDateDomain:
                value && allValues.trainRange.range.begin && allValues.trainRange.range.end
                  ? { begin: allValues.trainRange.range.begin, end: allValues.trainRange.range.end }
                  : null,
            }
          },
        },
      } as DecoratorConfig<FormState, "showOnlyDateCompatibleContext">,
    ],
    [fetchSourceDomain],
  )

  const currentRoute = useAppRoute()
  const currentTaskId = currentRoute.params.taskId ? parseInt(currentRoute.params.taskId, 10) : undefined
  const [{ data: initialValues }] = useAsyncAction<FormState, AsyncHookParams<{ taskId?: number }>>(
    async ({ taskId }) => {
      // fetch the default source
      const sources = await api.getManyBaseSourceControllerSourceDto({ limit: 1 })
      const source = sources.data[0]
      let values: FormState = {
        source: {
          sourceId: source.id,
        },
        sourcePreview: {
          sourceId: source.id,
          forecastBand: {
            color: hexToRGBA("#D4AF37", 0.3),
          },
          trainBand: {
            color: hexToRGBA(rgbToHex(theme.palette.primary.light), 0.3),
          },
          previewLimits: {
            begin: new Date(),
            end: new Date(),
          },
        },
        forecastType: ForecastType.XGBOOST,
        title: "Nouveau projet",
        trainRange: {
          range: { begin: new Date(), end: new Date() },
          minDate: new Date(),
          maxDate: new Date(),
        },
        forecastRange: {
          range: { begin: new Date(), end: new Date() },
          minDate: new Date(),
          maxDate: new Date(),
        },
        sourceIds: {
          sourceIds: [],
          filterByDateDomain: null,
        },
        contextTypes: {
          sourceId: source.id,
          contextTypes: [],
          filterByDateDomain: null,
        },
        showOnlyDateCompatibleContext: false,
      }

      if (taskId) {
        const task = await api.getOneBaseForecastTaskControllerForecastTask({ id: taskId })

        // apply calculator to selected sources
        values.title = task.title
        values.forecastType = task.forecastType
        values = await applyCalculatorParams(values, calculatorParams, "source", {
          sourceId: task.sourceId,
        })
        values = await applyCalculatorParams(values, calculatorParams, "trainRange", {
          range: { begin: task.trainBegin, end: task.trainEnd },
        })
        values = await applyCalculatorParams(values, calculatorParams, "forecastRange", {
          range: { begin: task.forecastBegin, end: task.forecastEnd },
        })
        values = await applyCalculatorParams(values, calculatorParams, "contextTypes", {
          contextTypes: task.forecastContextTypes,
        })
        values = await applyCalculatorParams(values, calculatorParams, "sourceIds", {
          sourceIds: task.forecastContextSourceIds,
        })
      } else {
        // apply calculator to selected sources
        values = await applyCalculatorParams(values, calculatorParams, "source", { sourceId: source.id })
      }

      return values
    },
    {
      shouldTrigger: true,
      taskId: currentTaskId,
    },
  )

  return (
    <PageContainer title="Modélisation statistique - Nouveau Projet">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Nouveau projet de modélisation statistique
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SuccessMessage />
        {!initialValues ? (
          <Loader />
        ) : (
          <Form<FormState>
            decorators={[createCalculator(...calculatorParams)]}
            onSubmit={async (values, form) => {
              if (!values.forecastRange.range.begin || !values.forecastRange.range.end) {
                throw new Error("Should not have been able to submit without a proper forecastRange")
              }
              if (!values.trainRange.range.begin || !values.trainRange.range.end) {
                throw new Error("Should not have been able to submit without a proper trainRange")
              }

              let res: ForecastTask | null = null
              const inputParams = {
                forecastBegin: values.forecastRange.range.begin,
                forecastEnd: values.forecastRange.range.end,
                forecastContextSourceIds: values.sourceIds.sourceIds,
                forecastContextTypes: values.contextTypes.contextTypes,
                title: values.title,
                sourceId: values.source.sourceId || -1,
                trainBegin: values.trainRange.range.begin,
                trainEnd: values.trainRange.range.end,
                forecastType: values.forecastType,
              }
              if (currentRoute.route === AppRoute.FORECAST_TASK_UPDATE && currentTaskId) {
                res = await api.forecastTaskControllerUpdateOne({
                  forecastTaskId: currentTaskId,
                  forecastTaskInputParams: inputParams,
                })
              } else {
                res = await api.forecastTaskControllerCreateOne({
                  forecastTaskInputParams: inputParams,
                })
              }
              const taskId = res.id

              showSuccessMessage()
              setTimeout(() => {
                history.push(
                  buildUrl({
                    route: AppRoute.FORECAST_TASK_VIEW,
                    params: { taskId: taskId + "" },
                  }),
                )
              }, 1500)
            }}
            initialValues={initialValues}
            validate={(values) => {
              const errors: { [key in keyof typeof values]?: string[] } = {
                trainRange: [],
                forecastRange: [],
                title: [],
              }
              if (!values.title || values.title.length <= 0) {
                errors.title?.push("Veuillez saisir un nom de projet")
              }

              if (!values.trainRange.range.begin || !values.trainRange.range.end) {
                errors.trainRange?.push("Veuillez définir une période d'apprentissage")
                return errors
              }
              if (!values.forecastRange.range.begin || !values.forecastRange.range.end) {
                errors.forecastRange?.push("Veuillez définir une période de modélisation")
                return errors
              }

              // durée minimale de la période d'apprentissage : 1 semaine
              if (
                Math.round((values.trainRange.range.end.getTime() - values.trainRange.range.begin.getTime()) / dayMs) <
                7
              ) {
                errors.trainRange?.push("La période d'apprentissage doit être d'au moins 7 jours")
              }

              // durée minimale de la période de modélisation : 1 jour
              if (
                Math.round(
                  (values.forecastRange.range.end.getTime() - values.forecastRange.range.begin.getTime()) / dayMs,
                ) < 1
              ) {
                errors.forecastRange?.push("La période de modélisation doit être d'au moins 1 jours")
              }

              // la superposition des périodes d'apprentissage et de modélisation est possible,
              // tant qu'il y a au moins une semaine d'apprentissage sans superposition.
              if (
                Math.round(
                  (values.forecastRange.range.begin.getTime() - values.trainRange.range.begin.getTime()) / dayMs,
                ) < 7
              ) {
                errors.trainRange?.push(
                  "Veuillez sélectionner au moins 7 jours d'apprentissage sans superposition avec la période de modélisation",
                )
              }

              // il n'est pas possible d'avoir la période de modélisation avant la période d'apprentissage.
              if (values.forecastRange.range.begin < values.trainRange.range.begin) {
                errors.forecastRange?.push("La période de modélisation doit se situer après la période d'apprentissage")
              }
              return errors
            }}
            render={({ handleSubmit, dirty, submitting, pristine, values, valid, submitSucceeded }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Prompt
                    when={dirty && !submitting && !submitSucceeded}
                    message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                  />
                  <PageBlock title="Configuration du projet">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ marginRight: "1em", width: "150px" }}>Nom du projet : </InputLabel>
                      {createTextField<FormState>({ name: "title", label: "Nom du projet", required: true })}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ marginRight: "1em", width: "142px" }}>Source cible : </InputLabel>
                      {createSourceSelectField<FormState>({ name: "source" })}
                    </div>
                    {createSourceSelectPreviewField<FormState>({ name: "sourcePreview" })}
                  </PageBlock>

                  <PageBlock title="Configuration de la modélisation">
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "2em" }}>
                      <InputLabel style={{ marginRight: "1em", width: "200px" }}>Méthode de modélisation</InputLabel>

                      {createListSelectField<FormState, typeof forecastMethods[0]>({
                        name: "forecastType",
                        items: forecastMethods,
                        isItemDisabled: (i) => i.premium === true,
                        customOptionRenderer: (i) => (
                          <span>
                            {i.premium && <PremiumFeature />} {i.label} {i.premium}
                          </span>
                        ),
                      })}
                    </div>
                    <div style={{ marginBottom: "2em" }}>
                      <div style={{ display: "flex", alignItems: "center", marginTop: "2em" }}>
                        <InputLabel style={{ marginRight: "1em", width: "200px" }}>
                          Période de d'apprentissage
                        </InputLabel>
                        {createDateRangeField<FormState>({
                          name: "trainRange",
                          color: hexToRGBA(rgbToHex(theme.palette.primary.light), 0.75),
                          minimumNights: 6,
                        })}
                      </div>

                      <div style={{ display: "flex", alignItems: "center", marginTop: "2em" }}>
                        <InputLabel style={{ marginRight: "1em", width: "200px" }}>Période de modélisation</InputLabel>
                        {createDateRangeField<FormState>({
                          name: "forecastRange",
                          color: hexToRGBA("#D4AF37", 0.8),
                          minimumNights: 0,
                        })}
                      </div>
                    </div>
                  </PageBlock>

                  {forecastMethods.find((m) => m.value === values.forecastType)?.supportsContext && (
                    <PageBlock title="Données contextuelles">
                      <Grid container spacing={9}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          {createContextTypeSelectTableField<FormState>({ name: "contextTypes" })}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          {createSourceSelectTableField<FormState>({
                            name: "sourceIds",
                            title: <InputLabel>Ajouter une source de données comme donnée contextuelle</InputLabel>,
                            pageSize: 5,
                          })}
                        </Grid>
                      </Grid>

                      {createCheckboxField<FormState>({
                        name: "showOnlyDateCompatibleContext",
                        label: "Monter uniquement les données compatibles avec la période d'apprentissage sélectionnée",
                      })}
                    </PageBlock>
                  )}
                  <ConfirmDialogButton disabled={!valid} isSubmitting={submitting} onSubmit={handleSubmit} />
                </form>
              )
            }}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}
