import moment from "moment"
import "moment/locale/fr"
import React, { Component } from "react"
import { AnyChildren } from "../../../react-type-helpers"
import { CalendarClasses } from "./CalendarClasses"
import Month from "./Month"
import { range } from "./utils"
import "./YearCalendar.css"

moment.locale("fr")

interface Props {
  year: number
  forceFullWeeks: boolean
  showDaysOfWeek: boolean
  showWeekSeparators: boolean
  firstDayOfWeek: number
  selectRange: boolean
  onPickDate: (date: moment.Moment, classes: string) => void
  onPickRange: (start: moment.Moment, stop: moment.Moment) => void
  selectedDay: moment.Moment
  customClasses?: CalendarClasses
}

interface State {
  selectingRange?: moment.Moment[]
}

export default class YearCalendar extends Component<Props, State> {
  public static defaultProps = {
    forceFullWeeks: false,
    showDaysOfWeek: true,
    showWeekSeparators: true,
    firstDayOfWeek: 0,
    selectRange: false,
    selectedDay: moment(),
    customClasses: {},
    onPickDate: () => {},
    onPickRange: () => {},
  }
  constructor(props: Props) {
    super(props)

    this.state = {
      selectingRange: undefined,
    }
  }

  public render() {
    const { selectingRange } = this.state

    const months = range(0, 12).map((month) => (
      <Month
        month={month}
        key={`month-${month}`}
        dayClicked={this.dayClicked}
        dayHovered={this.dayHovered}
        {...this.props}
        selectingRange={selectingRange}
      />
    ))

    return (
      <div className="calendar-container">
        <table className="calendar">
          <thead className="day-headers">{this.props.showDaysOfWeek ? this.renderDaysOfWeek() : null}</thead>
          <tbody>{months}</tbody>
        </table>
      </div>
    )
  }

  private dayClicked = (date: moment.Moment | null, classes: string) => {
    if (!date) {
      // clicked on prev or next month
      return
    }

    let { selectingRange } = this.state
    const { selectRange, onPickRange, onPickDate } = this.props

    if (!selectRange) {
      if (onPickDate instanceof Function) {
        onPickDate(date, classes)
      }
      return
    }

    if (!selectingRange) {
      selectingRange = [date, date]
    } else {
      if (onPickRange instanceof Function) {
        if (selectingRange[0] > date) {
          onPickRange(date, selectingRange[0])
        } else {
          onPickRange(selectingRange[0], date)
        }
      }
      selectingRange = undefined
    }

    this.setState({
      selectingRange,
    })
  }

  private dayHovered = (hoveredDay: moment.Moment | null) => {
    if (!hoveredDay) {
      // clicked on prev or next month
      return
    }

    const { selectingRange } = this.state

    if (selectingRange) {
      selectingRange[1] = hoveredDay

      this.setState({
        selectingRange,
      })
    }
  }

  private renderDaysOfWeek = () => {
    const { firstDayOfWeek, forceFullWeeks, showWeekSeparators } = this.props
    const totalDays = forceFullWeeks ? 42 : 37

    const days: AnyChildren[] = []
    range(firstDayOfWeek, totalDays + firstDayOfWeek).forEach((i) => {
      const day = moment().weekday(i).format("dd").charAt(0)

      if (showWeekSeparators) {
        if (i % 7 === firstDayOfWeek && days.length) {
          // push week separator
          days.push(<th className="week-separator" key={`seperator-${i}`} />)
        }
      }
      days.push(
        <th key={`weekday-${i}`} className={i % 7 === 6 || i % 7 === 5 ? "bolder" : ""}>
          {day}
        </th>,
      )
    })

    return (
      <tr>
        <th>&nbsp;</th>
        {days}
      </tr>
    )
  }
}
