/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SourcePatchGroupDto
 */
export interface SourcePatchGroupDto {
  /**
   * The new group id
   * @type {number}
   * @memberof SourcePatchGroupDto
   */
  groupId: number | null
}

export function SourcePatchGroupDtoFromJSON(json: any): SourcePatchGroupDto {
  return SourcePatchGroupDtoFromJSONTyped(json, false)
}

export function SourcePatchGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourcePatchGroupDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    groupId: json["groupId"],
  }
}

export function SourcePatchGroupDtoToJSON(value?: SourcePatchGroupDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    groupId: value.groupId,
  }
}
