import React, { useContext } from "react"
import { AnyChildren } from "../react-type-helpers"
import { usePersistedToggle } from "./toggle"

// the current app route
interface SideMenuContextState {
  open: boolean
  doOpen: () => void
  doClose: () => void
}

const SideMenuContext = React.createContext<SideMenuContextState>({
  open: true,
  doOpen: () => {},
  doClose: () => {},
})

export function SideMenuContextProvider({ children }: { children: AnyChildren }) {
  const { open, doOpen, doClose } = usePersistedToggle("menu-drawer", true)

  // aaaaand make it available
  return (
    <SideMenuContext.Provider
      value={{
        open,
        doOpen,
        doClose,
      }}
    >
      {children}
    </SideMenuContext.Provider>
  )
}

export function useSideMenuToggle(): SideMenuContextState {
  return useContext(SideMenuContext)
}
