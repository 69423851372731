import { ConfigContextConsumer, ConfigContextProvider } from "config"
import React from "react"
import ReactDOM from "react-dom"
import { Helmet } from "react-helmet"
import App from "./App"
import { ConfigFile, parseConfigFile } from "./config"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import moment from "moment"
import "moment-timezone"

const publicUrl = (process.env.PUBLIC_URL || window.location.origin).replace(/\/$/, "")

async function init() {
  // https://github.com/highcharts/highcharts-react/issues/118#issuecomment-487060151
  // @ts-ignore
  window.moment = moment

  // fetch the application config
  let config: Partial<ConfigFile> = {}
  try {
    const res = await fetch(publicUrl + "/config.json")
    if (!res.ok) {
      throw new Error("HTTP error " + res.status)
    }
    config = await res.json()
  } catch (e) {
    // tslint-disable-next-line
    console.error("Could net fetch config, using defaults")
  }

  // initialize google analytics
  const dataLayer: any[] = []
  function gtag(..._: any[]) {
    dataLayer.push(arguments)
  }
  // @ts-ignore
  window.dataLayer = window.dataLayer || dataLayer
  // @ts-ignore
  window.gtag = gtag

  // add config as a context and render the app
  ReactDOM.render(
    <ConfigContextProvider value={parseConfigFile(config)}>
      <>
        <App />
        <ConfigContextConsumer>
          {({ GOOGLE_ANALYTICS_UA }) => {
            gtag("js", new Date())
            gtag("config", GOOGLE_ANALYTICS_UA)
            return (
              <Helmet>
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_UA}`}></script>
              </Helmet>
            )
          }}
        </ConfigContextConsumer>
      </>
    </ConfigContextProvider>,
    document.getElementById("root"),
  )
}

// tslint:disable-next-line:no-floating-promises
init()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
