import React, { useMemo } from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useApiClient } from "../../state/api"
import { Grid, InputLabel, rgbToHex } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { useHistory, Prompt } from "react-router"
import { buildUrl } from "../../component/AppLink"
import { AppRoute, useAppRoute } from "../../state/route"
import * as lodash from "lodash"
import { ConfirmDialogButton } from "../../component/ConfirmDialogButton"
import { SourceSelectFormState, createSourceSelectField } from "../../component/form/SourceSelectField"
import { SourcePreviewFormState, createSourceSelectPreviewField } from "../../component/form/SourcePreviewField"
import { DateRangeFieldState, createDateRangeField } from "../../component/form/DateRangeField"
import { Form } from "react-final-form"
import { hexToRGBA } from "../../lib/color"
import { useTheme } from "../../theme"
import { DecoratorConfig, createCalculator, applyCalculatorParams } from "../../component/form/decorator"
import { createTextField, TextFormState } from "../../component/form/TextField"
import Loader from "../../component/Loader"
import { useAsyncAction, AsyncHookParams } from "../../state/async"
import { SerieChartSyncZoomProvider } from "../../component/SerieChart/serieChartContext"
import { SerieSyncTask } from "../../api/timelight-api/models/SerieSyncTask"

interface FormState {
  refSource: SourceSelectFormState
  refSourcePreview: SourcePreviewFormState
  toSyncSource: SourceSelectFormState
  toSyncSourcePreview: SourcePreviewFormState
  title: TextFormState
  trainRange: DateRangeFieldState
}

export function SerieSyncTaskCreate() {
  const theme = useTheme()
  const history = useHistory()
  const api = useApiClient()

  const fetchSourceDomain = useMemo(() => {
    const fetcher = (sourceId: number) => api.sourceControllerSourceDateDomain({ sourceId })
    return lodash.memoize(fetcher)
  }, [api])

  const getMaxCommonDomain = useMemo(() => {
    return async (
      sourceIdA: number | undefined | null,
      sourceIdB: number | undefined | null,
    ): Promise<{ begin: Date; end: Date }> => {
      const aDomain =
        sourceIdA && sourceIdA > 0
          ? await fetchSourceDomain(sourceIdA)
          : { beginDate: new Date(-Infinity), endDate: new Date(Infinity) }
      const bDomain =
        sourceIdB && sourceIdB > 0
          ? await fetchSourceDomain(sourceIdB)
          : { beginDate: new Date(-Infinity), endDate: new Date(Infinity) }
      return {
        begin: lodash.max([aDomain.beginDate, bDomain.beginDate]) as Date,
        end: lodash.min([aDomain.endDate, bDomain.endDate]) as Date,
      }
    }
  }, [fetchSourceDomain])

  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Tâche créée, cette opération peut prendre jusqu'à 5 minutes",
  })

  const calculatorParams = useMemo(() => {
    return [
      {
        field: "refSource",
        updates: {
          toSyncSource: (value, allValues) => {
            if (!value.sourceId) {
              return allValues.toSyncSource
            }
            const newEx = [value.sourceId]

            return lodash.isEqual(allValues.toSyncSource.sourceIdExclusions, newEx)
              ? allValues.toSyncSource
              : {
                  ...allValues.toSyncSource,
                  sourceIdExclusions: [value.sourceId],
                }
          },
          refSourcePreview: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.refSourcePreview
            }
            const domain = await fetchSourceDomain(value.sourceId)
            const commonDomain = await getMaxCommonDomain(value.sourceId, allValues.toSyncSource.sourceId)
            return {
              ...allValues.refSourcePreview,
              sourceId: value.sourceId,
              trainBand: {
                ...allValues.refSourcePreview.trainBand,
                from: commonDomain.begin.getTime(),
                to: commonDomain.end.getTime(),
              },
              previewLimits: {
                begin: domain.beginDate,
                end: domain.endDate,
              },
            }
          },
          trainRange: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.trainRange
            }
            // fetch date
            const commonDomain = await getMaxCommonDomain(value.sourceId, allValues.toSyncSource.sourceId)

            return {
              ...allValues.trainRange,
              range: commonDomain,
              minDate: commonDomain.begin,
              maxDate: commonDomain.end,
            }
          },
        },
      } as DecoratorConfig<FormState, "refSource">,
      {
        field: "toSyncSource",
        updates: {
          refSource: (value, allValues) => {
            if (!value.sourceId) {
              return allValues.refSource
            }
            const newEx = [value.sourceId]

            return lodash.isEqual(allValues.refSource.sourceIdExclusions, newEx)
              ? allValues.refSource
              : {
                  ...allValues.refSource,
                  sourceIdExclusions: [value.sourceId],
                }
          },
          toSyncSourcePreview: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.toSyncSourcePreview
            }
            const domain = await fetchSourceDomain(value.sourceId)
            const commonDomain = await getMaxCommonDomain(value.sourceId, allValues.refSource.sourceId)
            return {
              ...allValues.toSyncSourcePreview,
              trainBand: {
                ...allValues.toSyncSourcePreview.trainBand,
                from: commonDomain.begin.getTime(),
                to: commonDomain.end.getTime(),
              },
              sourceId: value.sourceId,
              previewLimits: { begin: domain.beginDate, end: domain.endDate },
            }
          },
          trainRange: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.trainRange
            }
            // fetch date
            const commonDomain = await getMaxCommonDomain(value.sourceId, allValues.refSource.sourceId)

            return {
              ...allValues.trainRange,
              range: commonDomain,
              minDate: commonDomain.begin,
              maxDate: commonDomain.end,
            }
          },
        },
      } as DecoratorConfig<FormState, "toSyncSource">,
      {
        field: "trainRange",
        updates: {
          refSourcePreview: (value, allValues) => {
            return {
              ...allValues.refSourcePreview,
              trainBand: {
                ...allValues.refSourcePreview.trainBand,
                from: value.range.begin?.getTime(),
                to: value.range.end?.getTime(),
              },
            }
          },
          toSyncSourcePreview: (value, allValues) => {
            return {
              ...allValues.toSyncSourcePreview,
              trainBand: {
                ...allValues.toSyncSourcePreview.trainBand,
                from: value.range.begin?.getTime(),
                to: value.range.end?.getTime(),
              },
            }
          },
        },
      } as DecoratorConfig<FormState, "trainRange">,
    ]
  }, [fetchSourceDomain, getMaxCommonDomain])

  const currentRoute = useAppRoute()
  const currentTaskId = currentRoute.params.taskId ? parseInt(currentRoute.params.taskId, 10) : undefined
  const [{ data: initialValues }] = useAsyncAction<FormState, AsyncHookParams<{ taskId?: number }>>(
    async ({ taskId }) => {
      let values: FormState = {
        refSource: {
          sourceId: null,
          sourceIdExclusions: [],
        },
        refSourcePreview: {
          sourceId: -1,
          forecastBand: {},
          trainBand: {
            color: hexToRGBA(rgbToHex(theme.palette.primary.light), 0.3),
          },
          previewLimits: {
            begin: new Date(),
            end: new Date(),
          },
        },
        toSyncSource: {
          sourceId: null,
          sourceIdExclusions: [],
        },
        toSyncSourcePreview: {
          sourceId: -1,
          forecastBand: {},
          trainBand: {
            color: hexToRGBA(rgbToHex(theme.palette.primary.light), 0.3),
          },
          previewLimits: {
            begin: new Date(),
            end: new Date(),
          },
        },
        title: "Nouveau projet",
        trainRange: {
          range: { begin: new Date(), end: new Date() },
          minDate: new Date(),
          maxDate: new Date(),
        },
      }

      if (taskId) {
        const task = await api.getOneBaseSyncTaskControllerSerieSyncTask({ id: taskId })

        // apply calculator to selected sources
        values.title = task.title
        values = await applyCalculatorParams(values, calculatorParams, "refSource", {
          sourceId: task.refSourceId,
        })
        values = await applyCalculatorParams(values, calculatorParams, "toSyncSource", {
          sourceId: task.toSyncSourceId,
        })
        values = await applyCalculatorParams(values, calculatorParams, "trainRange", {
          range: { begin: task.trainBegin, end: task.trainEnd },
        })
      } else {
        // apply calculator to selected sources
        values = await applyCalculatorParams(values, calculatorParams, "refSource", { sourceId: null })
        values = await applyCalculatorParams(values, calculatorParams, "toSyncSource", { sourceId: null })
      }

      return values
    },
    {
      shouldTrigger: true,
      throwErrors: true,
      taskId: currentTaskId,
    },
  )

  return (
    <PageContainer title="Synchronisation de série - Nouveau Projet">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Nouveau projet de synchronisation de série
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SuccessMessage />

        {!initialValues ? (
          <Loader />
        ) : (
          <Form<FormState>
            decorators={[createCalculator(...calculatorParams)]}
            onSubmit={async (values, form) => {
              if (!values.trainRange.range.begin || !values.trainRange.range.end) {
                throw new Error("Should not have been able to submit without a proper trainRange")
              }

              let res: SerieSyncTask | null = null
              const inputParams = {
                refSourceId: values.refSource.sourceId || -1,
                toSyncSourceId: values.toSyncSource.sourceId || -1,
                title: values.title,
                trainBegin: values.trainRange.range.begin,
                trainEnd: values.trainRange.range.end,
              }
              if (currentRoute.route === AppRoute.SERIE_SYNC_TASK_UPDATE && currentTaskId) {
                res = await api.syncTaskControllerUpdateOne({
                  syncTaskId: currentTaskId,
                  serieSyncTaskInputParams: inputParams,
                })
              } else {
                res = await api.syncTaskControllerCreateOne({
                  serieSyncTaskInputParams: inputParams,
                })
              }
              const taskId = res.id

              showSuccessMessage()
              setTimeout(() => {
                history.push(
                  buildUrl({
                    route: AppRoute.SERIE_SYNC_TASK_VIEW,
                    params: { taskId: taskId + "" },
                  }),
                )
              }, 1000)
            }}
            initialValues={initialValues}
            validate={(values) => {
              const errors: { [key in keyof typeof values]?: string[] } = {
                refSource: [],
                toSyncSource: [],
                trainRange: [],
                title: [],
              }

              if (!values.title || values.title.length <= 0) {
                errors.title?.push("Veuillez saisir un nom de projet")
              }

              if ((values.refSource.sourceId || -1) < 0) {
                errors.refSource?.push("Veuillez sélectionner une source de référence")
              }
              if ((values.toSyncSource.sourceId || -1) < 0) {
                errors.toSyncSource?.push("Veuillez sélectionner une source à synchroniser")
              }

              if (
                values.trainRange.minDate > values.trainRange.maxDate ||
                (values.refSourcePreview.trainBand.from &&
                  values.refSourcePreview.trainBand.to &&
                  values.refSourcePreview.trainBand.from > values.refSourcePreview.trainBand.to) ||
                (values.toSyncSourcePreview.trainBand.from &&
                  values.toSyncSourcePreview.trainBand.to &&
                  values.toSyncSourcePreview.trainBand.from > values.toSyncSourcePreview.trainBand.to)
              ) {
                errors.trainRange?.push("Les sources sélectionnées n'ont pas de données en commun")
                errors.refSource?.push("Les sources sélectionnées n'ont pas de données en commun")
                errors.toSyncSource?.push("Les sources sélectionnées n'ont pas de données en commun")
              }

              return errors
            }}
            render={({ handleSubmit, dirty, submitting, pristine, values, valid, submitSucceeded }) => {
              return (
                <SerieChartSyncZoomProvider>
                  <form onSubmit={handleSubmit}>
                    <Prompt
                      when={dirty && !submitting && !submitSucceeded}
                      message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                    />
                    <PageBlock title="Configuration du projet">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <InputLabel style={{ marginRight: "1em", width: "150px" }}>Nom du projet : </InputLabel>
                        {createTextField<FormState>({ name: "title", label: "Nom du projet", required: true })}
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <InputLabel style={{ marginRight: "1em", width: "142px" }}>Source de référence : </InputLabel>
                        {createSourceSelectField<FormState>({ name: "refSource", required: true })}
                      </div>
                      {values.refSource.sourceId &&
                        values.refSource.sourceId !== -1 &&
                        createSourceSelectPreviewField<FormState>({ name: "refSourcePreview" })}

                      {values.refSource.sourceId && values.refSource.sourceId !== -1 && (
                        <>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <InputLabel style={{ marginRight: "1em", width: "142px" }}>
                              Source à synchroniser :{" "}
                            </InputLabel>
                            {values.refSource.sourceId &&
                              values.refSource.sourceId !== -1 &&
                              (!values.toSyncSource.sourceId ||
                                values.refSource.sourceId !== values.toSyncSource.sourceId) &&
                              createSourceSelectField<FormState>({ name: "toSyncSource", required: true })}
                          </div>
                          {values.toSyncSource.sourceId &&
                            values.toSyncSource.sourceId !== -1 &&
                            createSourceSelectPreviewField<FormState>({ name: "toSyncSourcePreview" })}
                        </>
                      )}
                      <div style={{ display: "flex", alignItems: "center", marginTop: "2em" }}>
                        <InputLabel style={{ marginRight: "1em", width: "200px" }}>
                          Période de d'apprentissage
                        </InputLabel>
                        {createDateRangeField<FormState>({
                          name: "trainRange",
                          color: hexToRGBA(rgbToHex(theme.palette.primary.light), 0.75),
                          minimumNights: 6,
                        })}
                      </div>
                    </PageBlock>

                    <ConfirmDialogButton disabled={!valid} isSubmitting={submitting} onSubmit={handleSubmit} />
                  </form>
                </SerieChartSyncZoomProvider>
              )
            }}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}
