import React from "react"
import * as lodash from "lodash"
import { Field } from "react-final-form"
import { getUniqueObjectId } from "../../lib/uniq"
import { FormHelperText } from "@material-ui/core"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"
import { DateRangePicker } from "react-dates"
import moment from "moment"

import "react-dates/lib/css/_datepicker.css"
import "react-dates/initialize"

moment.locale("fr")

export interface DateRangeFieldState {
  range: { begin: Date | null; end: Date | null }
  minDate: Date
  maxDate: Date
}

export function createDateRangeField<FormState>(props: { name: keyof FormState & string } & DateRangeFieldProps) {
  return <Field {...props} render={DateRangeFieldRender} />
}

interface DateRangeFieldProps {
  color?: string
  minimumNights?: number
}

const DateRangeFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<DateRangeFieldState, DateRangeFieldProps>) => {
    return <DateRangeFieldComponent {...props} />
  },
  (props: CustomFieldRenderProps<DateRangeFieldState, DateRangeFieldProps>) =>
    getUniqueObjectId(props.input.value.minDate) +
    getUniqueObjectId(props.input.value.maxDate) +
    getUniqueObjectId(props.input.value.range.begin) +
    getUniqueObjectId(props.input.value.range.end) +
    "|" +
    getFormFieldPropsMemoizeKey(props),
)

// use a component to set focused input aside from range
// this caused a bug wher onChange was called twice in a row
// the second call erasing the changes from the first call
function DateRangeFieldComponent(props: CustomFieldRenderProps<DateRangeFieldState, DateRangeFieldProps>) {
  const [focusedInput, setFocusedInput] = React.useState<"startDate" | "endDate" | null>(null)
  const startDate = props.input.value.range.begin ? moment(props.input.value.range.begin) : null
  const endDate = props.input.value.range.end ? moment(props.input.value.range.end) : null
  const momentMinDate = moment(props.input.value.minDate).startOf("day")
  const momentMaxDate = moment(props.input.value.maxDate).endOf("day")

  const years: number[] = []
  for (let y = props.input.value.minDate.getFullYear(); y <= props.input.value.maxDate.getFullYear(); y++) {
    years.push(y)
  }
  return (
    <div>
      <DateRangePicker
        startDateId={props.input.name + "_startDate"}
        startDate={startDate}
        endDateId={props.input.name + "_endDate"}
        endDate={endDate}
        focusedInput={focusedInput}
        transitionDuration={0}
        onFocusChange={setFocusedInput}
        onDatesChange={(range) => {
          props.input.onChange({
            ...props.input.value,
            range: {
              begin: range.startDate?.startOf("day").toDate() || null,
              end: range.endDate?.endOf("day").toDate() || null,
            },
          })
        }}
        minDate={momentMinDate}
        maxDate={momentMaxDate}
        initialVisibleMonth={() => {
          if (focusedInput === "startDate") {
            return startDate || momentMinDate
          } else if (focusedInput === "endDate") {
            return endDate || momentMaxDate
          }
          return moment()
        }}
        disableScroll={false}
        keepFocusOnInput={true}
        numberOfMonths={2}
        disabled={props.meta.submitting}
        minimumNights={props.minimumNights}
        enableOutsideDays={false}
        isDayBlocked={() => false}
        isOutsideRange={(day: moment.Moment) => day.isBefore(momentMinDate) || day.isAfter(momentMaxDate)}
        isDayHighlighted={() => false}
        keepOpenOnDateSelect={false}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
          const monthItems = moment.months().map((m, v) => ({ label: m, value: v }))
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
                  {monthItems.map((m) => (
                    <option value={m.value}>{m.label}</option>
                  ))}
                </select>
              </div>
              <div>
                <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                  {years.map((y) => (
                    <option value={y}>{y}</option>
                  ))}
                </select>
              </div>
            </div>
          )
        }}
      />

      {props.meta.error &&
        (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
          <FormHelperText key={i} error={true}>
            {e}
          </FormHelperText>
        ))}
    </div>
  )
}
