/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayContextTypes, DayContextTypesFromJSON, DayContextTypesFromJSONTyped, DayContextTypesToJSON } from "./"

/**
 *
 * @export
 * @interface DayContextSummary
 */
export interface DayContextSummary {
  /**
   *
   * @type {DayContextTypes}
   * @memberof DayContextSummary
   */
  contextType: DayContextTypes
  /**
   * Average context value
   * @type {number}
   * @memberof DayContextSummary
   */
  avgValue: number
  /**
   * Average context correlation impact
   * @type {number}
   * @memberof DayContextSummary
   */
  corrImpact: number
  /**
   * Context correlation impact activity
   * @type {Array<number>}
   * @memberof DayContextSummary
   */
  corrActivity: Array<number>
  /**
   * Context fill ratio
   * @type {number}
   * @memberof DayContextSummary
   */
  fillRatio: number
}

export function DayContextSummaryFromJSON(json: any): DayContextSummary {
  return DayContextSummaryFromJSONTyped(json, false)
}

export function DayContextSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayContextSummary {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contextType: DayContextTypesFromJSON(json["contextType"]),
    avgValue: json["avgValue"],
    corrImpact: json["corrImpact"],
    corrActivity: json["corrActivity"],
    fillRatio: json["fillRatio"],
  }
}

export function DayContextSummaryToJSON(value?: DayContextSummary | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contextType: DayContextTypesToJSON(value.contextType),
    avgValue: value.avgValue,
    corrImpact: value.corrImpact,
    corrActivity: value.corrActivity,
    fillRatio: value.fillRatio,
  }
}
