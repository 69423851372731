import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { ModelDto, SourceDto, SourceGroupDto } from "api/timelight-api"
import { createIdMap } from "../lib/array"
import { AnyChildren } from "../react-type-helpers"
import AppModal from "./AppModal"
import ChartDayActivity from "./ChartDayActivity"
import { DayData } from "./ChartDayActivity"
import Title from "./Title"

const useStyles = makeStyles({
  chart: {
    height: "400px",
    width: "600px",
  },
})

export default function SourceModal({
  open,
  source,
  groups,
  models,
  days,
  onClose,
  children,
}: {
  open: boolean
  source: SourceDto
  groups: SourceGroupDto[]
  models: ModelDto[]
  days: DayData[]
  onClose: () => void
  children?: AnyChildren
}) {
  const classes = useStyles()

  const groupsById = createIdMap(groups)

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={
        <Title>
          {source.groupId ? groupsById[source.groupId].name : "Non assigné"} - {source.name}
        </Title>
      }
    >
      <>
        <div className={classes.chart}>
          <ChartDayActivity activityLabel={"Activité effective"} models={models} days={days} />
        </div>
        {children && (
          <>
            <hr />{" "}
            <div
              style={{
                paddingTop: 20,
              }}
            >
              {children}
            </div>
          </>
        )}
      </>
    </AppModal>
  )
}
