/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface ModelPostDto
 */
export interface ModelPostDto {
  /**
   * The color in hex format. Ex: \'#FFFFFF\'
   * @type {string}
   * @memberof ModelPostDto
   */
  color: string
  /**
   * The model name, between 1 and 100 characters
   * @type {string}
   * @memberof ModelPostDto
   */
  name: string
  /**
   * True if the model is an anomaly and should be ignored by the ML engine
   * @type {boolean}
   * @memberof ModelPostDto
   */
  anomaly: boolean
  /**
   * An array of 1, 2, 4, 8, 12, 24, 48, 72, 144 (default) or 1440 values values.        Each value represents a time window of 24h, 12h, 6h, 3h, 2h, 1h, 30m, 20m, 10m or 1m
   * @type {Array<number>}
   * @memberof ModelPostDto
   */
  topTolerance: Array<number>
  /**
   * An array of 1, 2, 4, 8, 12, 24, 48, 72, 144 (default) or 1440 values values.        Each value represents a time window of 24h, 12h, 6h, 3h, 2h, 1h, 30m, 20m, 10m or 1m
   * @type {Array<number>}
   * @memberof ModelPostDto
   */
  activity: Array<number>
  /**
   * An array of 1, 2, 4, 8, 12, 24, 48, 72, 144 (default) or 1440 values values.        Each value represents a time window of 24h, 12h, 6h, 3h, 2h, 1h, 30m, 20m, 10m or 1m
   * @type {Array<number>}
   * @memberof ModelPostDto
   */
  bottomTolerance: Array<number>
}

export function ModelPostDtoFromJSON(json: any): ModelPostDto {
  return ModelPostDtoFromJSONTyped(json, false)
}

export function ModelPostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPostDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    color: json["color"],
    name: json["name"],
    anomaly: json["anomaly"],
    topTolerance: json["topTolerance"],
    activity: json["activity"],
    bottomTolerance: json["bottomTolerance"],
  }
}

export function ModelPostDtoToJSON(value?: ModelPostDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    color: value.color,
    name: value.name,
    anomaly: value.anomaly,
    topTolerance: value.topTolerance,
    activity: value.activity,
    bottomTolerance: value.bottomTolerance,
  }
}
