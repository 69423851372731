// tslint:disable:max-line-length
import { ButtonGroup } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import CalendarIcon from "@material-ui/icons/CalendarToday"
import GridIcon from "@material-ui/icons/GridOn"
import React from "react"
import ListSelect from "../../component/ListSelect"
import { useLocalStorage } from "../../state/localStorage"
import { CalendarContentConfig, DayYearCalendarProps } from "./CalendarData"
import DayGridYearCalendar from "./DayGridYearCalendar"
import DayListYearCalendar from "./DayListYearCalendar"

import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

const useStyle = makeStyles({
  disabledButton: {
    boxShadow: "-1px 1px 10px -1px rgba(0, 0, 0, 0.3) inset",
  },
})

export default function DayYearCalendar(props: DayYearCalendarProps) {
  const classes = useStyle()
  const [calStyle, setCalStyle] = useLocalStorage<"grid" | "list">("calendar-style-switch-days", "list")

  const [calContent, setCalContent] = useLocalStorage<CalendarContentConfig>("calendar-content-switch-days", "model")

  const options: {
    value: CalendarContentConfig
    label: string
  }[] = [
    { label: "profil type", value: "model" },
    { label: "activité", value: "activity" },
  ]

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <p style={{ marginRight: 10 }}>Affichage du calendrier par </p>
          <ListSelect<{
            value: CalendarContentConfig
            label: string
          }>
            value={options.find((o) => o.value === calContent)}
            items={options}
            onSelect={(val) => setCalContent(val.value)}
            getValue={(o) => o.value}
            getLabel={(o) => o.label}
          />
        </div>
        <ButtonGroup size="small" aria-label="small outlined button group">
          <Button
            className={clsx(calStyle === "grid" && classes.disabledButton)}
            disabled={calStyle === "grid"}
            onClick={() => setCalStyle("grid")}
          >
            <GridIcon />
          </Button>
          <Button
            className={clsx(calStyle === "list" && classes.disabledButton)}
            disabled={calStyle === "list"}
            onClick={() => setCalStyle("list")}
          >
            <CalendarIcon />
          </Button>
        </ButtonGroup>
      </div>
      {calStyle === "list" && <DayListYearCalendar content={calContent} {...props} />}
      {calStyle === "grid" && <DayGridYearCalendar content={calContent} {...props} />}
    </div>
  )
}
