/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  DayListDto,
  DayListDtoFromJSON,
  DayListDtoToJSON,
  DaysPatchDto,
  DaysPatchDtoFromJSON,
  DaysPatchDtoToJSON,
} from "../models"

export interface DayControllerBulkUpdateRequest {
  daysPatchDto: DaysPatchDto
}

export interface DayControllerListDaysRequest {
  sourceId: number
  year: number
}

export interface DayPublicControllerListDaysRequest {
  sourceId: number
}

/**
 * no description
 */
export class DayApi extends runtime.BaseAPI {
  /**
   * Update day entities
   */
  async dayControllerBulkUpdateRaw(
    requestParameters: DayControllerBulkUpdateRequest,
  ): Promise<runtime.ApiResponse<DayListDto>> {
    if (requestParameters.daysPatchDto === null || requestParameters.daysPatchDto === undefined) {
      throw new runtime.RequiredError(
        "daysPatchDto",
        "Required parameter requestParameters.daysPatchDto was null or undefined when calling dayControllerBulkUpdate.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day/bulk`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: DaysPatchDtoToJSON(requestParameters.daysPatchDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayListDtoFromJSON(jsonValue))
  }

  /**
   * Update day entities
   */
  async dayControllerBulkUpdate(requestParameters: DayControllerBulkUpdateRequest): Promise<DayListDto> {
    const response = await this.dayControllerBulkUpdateRaw(requestParameters)
    return await response.value()
  }

  /**
   * List day data of the reference year
   */
  async dayControllerListDaysRaw(
    requestParameters: DayControllerListDaysRequest,
  ): Promise<runtime.ApiResponse<DayListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayControllerListDays.",
      )
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling dayControllerListDays.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day/list/{sourceId}/{year}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayListDtoFromJSON(jsonValue))
  }

  /**
   * List day data of the reference year
   */
  async dayControllerListDays(requestParameters: DayControllerListDaysRequest): Promise<DayListDto> {
    const response = await this.dayControllerListDaysRaw(requestParameters)
    return await response.value()
  }

  /**
   * List all day data
   */
  async dayPublicControllerListDaysRaw(
    requestParameters: DayPublicControllerListDaysRequest,
  ): Promise<runtime.ApiResponse<DayListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayPublicControllerListDays.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-public/list/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayListDtoFromJSON(jsonValue))
  }

  /**
   * List all day data
   */
  async dayPublicControllerListDays(requestParameters: DayPublicControllerListDaysRequest): Promise<DayListDto> {
    const response = await this.dayPublicControllerListDaysRaw(requestParameters)
    return await response.value()
  }
}
