import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import ListSelect from "component/ListSelect"
import Loader from "component/Loader"
import PageBlock from "component/PageBlock"
import { SourcesGmaps } from "component/SourcesGmap"
import { Formik } from "formik"
import React, { useState } from "react"
import { Prompt } from "react-router"
import { SourceDto, SourceGroupPatchDto } from "api/timelight-api"
import AppHeader from "../../component/AppHeader"
import { DATA_NOT_PROVIDED_MODEL_ID } from "../../component/ChartDayActivity"
import ColorPicker from "../../component/ColorPicker"
import GridHighlight from "../../component/GridHighlight"
import GroupAssignAction from "../../component/GroupAssignAction"
import {
  createIntegerColumnConfig,
  createNumericColumnConfig,
  createTableExportCsv,
  createTextEnumColumnConfig,
  extractColumnConfigAndExportConfig,
  MaterialTable,
} from "../../component/MaterialTable"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import ScatterSources from "../../component/ScatterSources"
import SideMenu from "../../component/SideMenu"
import SourceModal from "../../component/SourceModal"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { useApiClient, useDayAndModelList, useSourcesAndGroupsList, useTemperatureContext } from "../../state/api"
import { useApiExceptionHandler } from "../../state/auth"
import { useAppRoute } from "../../state/route"
import { createChartDayDataFromApiInfos } from "../../component/ChartDayActivity/utils"
import { AppButton } from "../../component/AppButton"

export default function PrevisionGroupSources() {
  // fetch the current group ID
  const route = useAppRoute()
  const selectedGroupId = parseInt(route.params.groupId ? route.params.groupId || "0" : "0", 10)

  // fetch some data
  const [
    { data },
    // @ts-ignore
    // tslint:disable:no-unused-declaration
    // eslint-disable-next-line
    _,
    setData,
  ] = useSourcesAndGroupsList({
    shouldTrigger: true,
  })
  const selectedGroup = data ? data.groups.find((g) => g.id === selectedGroupId) || null : null

  // focus on one group
  const sources = data ? data.sources.filter((s) => s.groupId === selectedGroupId) : []

  // the source modal
  const [selectedSource, setSelectedSource] = useState<SourceDto | null>(null)

  // fetch modal data if needed
  const [{ data: modalData }] = useDayAndModelList({
    shouldTrigger: !!selectedSource,
    sourceId: selectedSource ? selectedSource.id : 0,
    year: selectedSource ? selectedSource.referenceYear : 0,
  })

  // fetch temperature data
  const temperatureContext = useTemperatureContext({
    sourceId: selectedSource ? selectedSource.id : 0,
    minDate: `${selectedSource ? selectedSource.referenceYear : 0}-01-01`,
    maxDate: `${selectedSource ? selectedSource.referenceYear : 0}-12-31`,
    shouldTrigger: selectedSource !== null,
  })

  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Goupe mis à jour",
  })
  function onSourceUpdated(source: SourceDto) {
    if (!data) {
      return
    }

    // update the local data
    setData({
      ...data,
      sources: data.sources.map((s) => (s.id === source.id ? source : s)),
    })

    // close our modal
    setSelectedSource(null)

    // we're done
    showSuccessMessage()
  }

  // prepare some api calls
  // needed for the group form
  const api = useApiClient()
  const handleApiException = useApiExceptionHandler()
  const [submitting, setSubmitting] = useState<boolean>(false)

  // apply prevision everywhere
  const [selectedApplySource, setSelectedApplySource] = useState<SourceDto | null>(null)
  const [GroupApplySuccessMessage, showGroupApplySuccessMessage] = useSuccessMessage({
    message: "La planification a été appliquée partout",
  })
  async function applyPrevisionToGroup() {
    if (!data || !selectedApplySource) {
      return
    }
    setSubmitting(true)
    try {
      await api.previsionControllerApplyPrevisionsOfSourceToGroup({
        previsionApplyGroupDto: {
          sourceId: selectedApplySource.id,
        },
      })

      // yep, we're done
      showGroupApplySuccessMessage()

      // reload the page because i'm lazy
      window.location.reload()
    } finally {
      setSubmitting(false)
    }
  }

  const sourcesWithLatLng = sources.filter((s) => s.latitude !== null && s.longitude !== null)

  return (
    <PageContainer title={`Plan > {selectedGroup.name}`}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          {!data && <Loader />}

          {data && selectedGroup && (
            <Typography variant="h6">Planification multi-source > {selectedGroup.name}</Typography>
          )}
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SuccessMessage />
        <GroupApplySuccessMessage />

        {!data && <Loader />}

        {data && selectedSource && modalData && (
          <SourceModal
            open={!!selectedSource}
            source={selectedSource}
            groups={data.groups}
            models={modalData.models}
            days={createChartDayDataFromApiInfos({
              days: modalData.days.map((d) => ({ ...d, model_id: d.model_id || DATA_NOT_PROVIDED_MODEL_ID })),
              context: temperatureContext,
              previsions: [],
              models: modalData.models,
              year: selectedSource.referenceYear,
            })}
            onClose={() => setSelectedSource(null)}
          >
            <GroupAssignAction groups={data.groups} source={selectedSource} onSourceUpdated={onSourceUpdated} />
          </SourceModal>
        )}
        <p>Bienvenue sur la page de détail d'un groupe de capteurs.</p>
        <p>
          Réalisez la planification d'une capteur via le module planifier pour l'appliquer ensuite à tous les capteurs
          de ce groupe.
        </p>
        <PageBlock loading={!data} title="Association des capteurs aux groupes">
          {data && (
            <div
              style={{
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <ScatterSources groups={data.groups} sources={sources} setSelectedSource={setSelectedSource} />
            </div>
          )}

          {data &&
            (() => {
              const { columns, exportConfig } = extractColumnConfigAndExportConfig<SourceDto>([
                createTextEnumColumnConfig({
                  title: "Capteur",
                  getTextValue: (row) => row.name,
                  enumValues: sources.map((s) => s.name).sort(),
                }),
                createIntegerColumnConfig({
                  title: "Année de référence",
                  getValue: (row) => row.referenceYear,
                }),
                createTextEnumColumnConfig({
                  title: "Déjà planifié ?",
                  render: (row) => (
                    <GridHighlight color={row.hasPrevision ? "#61c569" : "#000000"}>
                      {row.hasPrevision ? "Oui" : "Non"}
                    </GridHighlight>
                  ),
                  getTextValue: (row) => (row.hasPrevision ? "Oui" : "Non"),
                  enumValues: ["Oui", "Non"],
                }),
                createNumericColumnConfig({
                  title: "Inertie intra groupe",
                  getValue: (row) => row.groupInertia || 0,
                }),
              ])
              return (
                <div style={{ marginTop: 30 }}>
                  <MaterialTable
                    title="Liste des capteurs"
                    data={sources}
                    onRowClick={setSelectedSource}
                    columns={columns}
                    options={{
                      exportCsv: createTableExportCsv({
                        exportConfig,
                        fileName: `sources_by_group.csv`,
                      }),
                    }}
                  />
                </div>
              )
            })()}
        </PageBlock>

        <PageBlock loading={!data} title="Appliquer une planification à l'ensemble des groupes">
          {data && (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 10 }}>Appliquer la planification de</p>
                <ListSelect<SourceDto>
                  value={selectedApplySource || undefined}
                  placeholder="Capteurs"
                  items={sources}
                  getLabel={(s) => s.name}
                  getValue={(s) => s.id}
                  onSelect={(s) => setSelectedApplySource(s)}
                  disabled={submitting}
                  isItemDisabled={(s) => !s.hasPrevision}
                />
                <div style={{ minWidth: 200, marginLeft: 10 }}>
                  <AppButton
                    color="primary"
                    variant="contained"
                    submitting={submitting}
                    onClick={() => applyPrevisionToGroup()}
                  >
                    Appliquer partout
                  </AppButton>
                </div>
              </div>
              <hr style={{ margin: 30 }} />
              <p>
                Attention ! Appliquer cette planification écrase la configuration des profil types des autres capteurs,
                pour recréer des profil types et une planification au plus proche de la capteur sélectionné
              </p>
              <p>Ces configuration sont toujours éditables ultérieurement par capteur via le module maîtriser</p>
            </>
          )}
        </PageBlock>

        {sourcesWithLatLng.length > 0 && (
          <PageBlock title="Carte des sources">
            <div style={{ height: 400 }}>
              <SourcesGmaps sources={sourcesWithLatLng} onSourceClick={setSelectedSource} onClick={() => {}} />
            </div>
          </PageBlock>
        )}

        <PageBlock loading={!data} title="Configuration du groupe">
          {data && selectedGroup && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <Formik<SourceGroupPatchDto>
                enableReinitialize={true}
                initialValues={{
                  name: selectedGroup.name,
                  color: selectedGroup.color,
                }}
                onSubmit={async (values) => {
                  try {
                    const newGroup = await api.sourceGroupControllerSourceGroupPatch({
                      sourceGroupPatchDto: values,
                      groupId: selectedGroup.id,
                    })
                    // update local data
                    setData({
                      ...data,
                      groups: data.groups.map((g) => (g.id === newGroup.id ? newGroup : g)),
                    })

                    // oh yeah
                    showSuccessMessage()
                  } catch (e) {
                    handleApiException(e)
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  dirty,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,

                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Prompt
                        when={dirty}
                        message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                      />

                      <TextField
                        style={{ minWidth: 200 }}
                        label={`Nom du groupe`}
                        variant="outlined"
                        value={values.name}
                        margin="normal"
                        required
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && errors.name}

                      <div style={{ marginBottom: 8, marginLeft: 8 }}>
                        <ColorPicker color={values.color} onChange={(color) => setFieldValue("color", color)} />
                      </div>
                      {errors.color && touched.color && errors.color}
                    </div>
                    <div style={{ width: "100%", textAlign: "right" }}>
                      <AppButton type="submit" variant="contained" color="primary" submitting={isSubmitting}>
                        Sauvegarder
                      </AppButton>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </PageBlock>
      </PageContent>
    </PageContainer>
  )
}
