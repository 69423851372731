import * as lodash from "lodash"
const uidSymbol = Symbol("Unique ID")

export function getUniqueObjectId(object: any): string {
  if (object === undefined) {
    return "undefined"
  }
  if (object === null) {
    return "null"
  }
  if (typeof object === "string") {
    return object
  }
  if (typeof object === "boolean") {
    return object ? "true" : "false"
  }
  if (object instanceof Date) {
    return object.toISOString()
  }
  if (!object[uidSymbol]) {
    object[uidSymbol] = Math.random() + ""
  }
  return object[uidSymbol]
}

export function getUniqueErrorsId(errors: { [key: string]: string[] | string }): string {
  if (errors === undefined) {
    return "undefined"
  }
  if (errors === null) {
    return "null"
  }
  return lodash.flatten(Object.values(errors).map((errs) => (Array.isArray(errs) ? errs : [errs]))).join(".")
}
