/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  FetchSerieDataCompactResult,
  FetchSerieDataCompactResultFromJSON,
  FetchSerieDataCompactResultToJSON,
  FetchSerieDataExpandedResult,
  FetchSerieDataExpandedResultFromJSON,
  FetchSerieDataExpandedResultToJSON,
  GetManySerieResult,
  GetManySerieResultFromJSON,
  GetManySerieResultToJSON,
  Serie,
  SerieFromJSON,
  SerieToJSON,
  SerieAggregationEnum,
  SerieAggregationEnumFromJSON,
  SerieAggregationEnumToJSON,
  SerieBlockTimeStep,
  SerieBlockTimeStepFromJSON,
  SerieBlockTimeStepToJSON,
  SerieImportResult,
  SerieImportResultFromJSON,
  SerieImportResultToJSON,
  SerieInterpolationEnum,
  SerieInterpolationEnumFromJSON,
  SerieInterpolationEnumToJSON,
} from "../models"

export interface SerieControllerGetManyRequest {
  page?: number
  limit?: number
  tags?: object
}

export interface SerieControllerGetOneRequest {
  serieId: number
}

export interface SerieDataControllerFetchDataCompactRequest {
  serieId: number
  begin: Date
  end: Date
  interpolation?: SerieInterpolationEnum
  aggregation?: SerieAggregationEnum
  resample?: SerieBlockTimeStep
}

export interface SerieDataControllerFetchDataExpandedRequest {
  serieId: number
  begin: Date
  end: Date
  interpolation?: SerieInterpolationEnum
  aggregation?: SerieAggregationEnum
  resample?: SerieBlockTimeStep
}

export interface SerieImportCsvControllerImportCsvRequest {
  serieId: number
  dateFormat?: string
  numberLocale?: SerieImportCsvControllerImportCsvNumberLocaleEnum
  separator?: string
  quote?: string
  stopOnError?: boolean
}

export interface SerieImportCsvControllerValidateCsvRequest {
  serieId: number
  dateFormat?: string
  numberLocale?: SerieImportCsvControllerValidateCsvNumberLocaleEnum
  separator?: string
  quote?: string
  stopOnError?: boolean
}

/**
 * no description
 */
export class SerieApi extends runtime.BaseAPI {
  /**
   * Fetch series
   */
  async serieControllerGetManyRaw(
    requestParameters: SerieControllerGetManyRequest,
  ): Promise<runtime.ApiResponse<GetManySerieResult>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.tags !== undefined) {
      queryParameters["tags"] = requestParameters.tags as any
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/serie`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManySerieResultFromJSON(jsonValue))
  }

  /**
   * Fetch series
   */
  async serieControllerGetMany(requestParameters: SerieControllerGetManyRequest): Promise<GetManySerieResult> {
    const response = await this.serieControllerGetManyRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch one serie
   */
  async serieControllerGetOneRaw(requestParameters: SerieControllerGetOneRequest): Promise<runtime.ApiResponse<Serie>> {
    if (requestParameters.serieId === null || requestParameters.serieId === undefined) {
      throw new runtime.RequiredError(
        "serieId",
        "Required parameter requestParameters.serieId was null or undefined when calling serieControllerGetOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/serie/{serieId}`.replace(`{${"serieId"}}`, encodeURIComponent(String(requestParameters.serieId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SerieFromJSON(jsonValue))
  }

  /**
   * Fetch one serie
   */
  async serieControllerGetOne(requestParameters: SerieControllerGetOneRequest): Promise<Serie> {
    const response = await this.serieControllerGetOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Use this endpoint to fetch data in a compact and optimized format.
   * Fetch serie data in a compact format
   */
  async serieDataControllerFetchDataCompactRaw(
    requestParameters: SerieDataControllerFetchDataCompactRequest,
  ): Promise<runtime.ApiResponse<FetchSerieDataCompactResult>> {
    if (requestParameters.serieId === null || requestParameters.serieId === undefined) {
      throw new runtime.RequiredError(
        "serieId",
        "Required parameter requestParameters.serieId was null or undefined when calling serieDataControllerFetchDataCompact.",
      )
    }

    if (requestParameters.begin === null || requestParameters.begin === undefined) {
      throw new runtime.RequiredError(
        "begin",
        "Required parameter requestParameters.begin was null or undefined when calling serieDataControllerFetchDataCompact.",
      )
    }

    if (requestParameters.end === null || requestParameters.end === undefined) {
      throw new runtime.RequiredError(
        "end",
        "Required parameter requestParameters.end was null or undefined when calling serieDataControllerFetchDataCompact.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.begin !== undefined) {
      queryParameters["begin"] = (requestParameters.begin as any).toISOString()
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString()
    }

    if (requestParameters.interpolation !== undefined) {
      queryParameters["interpolation"] = requestParameters.interpolation
    }

    if (requestParameters.aggregation !== undefined) {
      queryParameters["aggregation"] = requestParameters.aggregation
    }

    if (requestParameters.resample !== undefined) {
      queryParameters["resample"] = requestParameters.resample
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/serie-data/compact/{serieId}`.replace(
        `{${"serieId"}}`,
        encodeURIComponent(String(requestParameters.serieId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => FetchSerieDataCompactResultFromJSON(jsonValue))
  }

  /**
   * Use this endpoint to fetch data in a compact and optimized format.
   * Fetch serie data in a compact format
   */
  async serieDataControllerFetchDataCompact(
    requestParameters: SerieDataControllerFetchDataCompactRequest,
  ): Promise<FetchSerieDataCompactResult> {
    const response = await this.serieDataControllerFetchDataCompactRaw(requestParameters)
    return await response.value()
  }

  /**
   * Use this endpoint to fetch data in an expanded format and quickly prototype.
   * Fetch serie data in a human readable format
   */
  async serieDataControllerFetchDataExpandedRaw(
    requestParameters: SerieDataControllerFetchDataExpandedRequest,
  ): Promise<runtime.ApiResponse<FetchSerieDataExpandedResult>> {
    if (requestParameters.serieId === null || requestParameters.serieId === undefined) {
      throw new runtime.RequiredError(
        "serieId",
        "Required parameter requestParameters.serieId was null or undefined when calling serieDataControllerFetchDataExpanded.",
      )
    }

    if (requestParameters.begin === null || requestParameters.begin === undefined) {
      throw new runtime.RequiredError(
        "begin",
        "Required parameter requestParameters.begin was null or undefined when calling serieDataControllerFetchDataExpanded.",
      )
    }

    if (requestParameters.end === null || requestParameters.end === undefined) {
      throw new runtime.RequiredError(
        "end",
        "Required parameter requestParameters.end was null or undefined when calling serieDataControllerFetchDataExpanded.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.begin !== undefined) {
      queryParameters["begin"] = (requestParameters.begin as any).toISOString()
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString()
    }

    if (requestParameters.interpolation !== undefined) {
      queryParameters["interpolation"] = requestParameters.interpolation
    }

    if (requestParameters.aggregation !== undefined) {
      queryParameters["aggregation"] = requestParameters.aggregation
    }

    if (requestParameters.resample !== undefined) {
      queryParameters["resample"] = requestParameters.resample
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/serie-data/expanded/{serieId}`.replace(
        `{${"serieId"}}`,
        encodeURIComponent(String(requestParameters.serieId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => FetchSerieDataExpandedResultFromJSON(jsonValue))
  }

  /**
   * Use this endpoint to fetch data in an expanded format and quickly prototype.
   * Fetch serie data in a human readable format
   */
  async serieDataControllerFetchDataExpanded(
    requestParameters: SerieDataControllerFetchDataExpandedRequest,
  ): Promise<FetchSerieDataExpandedResult> {
    const response = await this.serieDataControllerFetchDataExpandedRaw(requestParameters)
    return await response.value()
  }

  /**
   * Imports metric data.     The first column must be the metric date with a customizable format (default is ISO).      The next columns must be the metric data in numeric with a customizable locale (default is FR).     The header of metric columns must match the serie metric names.     Ex:        \"date\";\"activity\"       \"2004-01-01T00:30:00\";\"181,63\"
   * Add new data to a serie or override existing data
   */
  async serieImportCsvControllerImportCsvRaw(
    requestParameters: SerieImportCsvControllerImportCsvRequest,
  ): Promise<runtime.ApiResponse<SerieImportResult>> {
    if (requestParameters.serieId === null || requestParameters.serieId === undefined) {
      throw new runtime.RequiredError(
        "serieId",
        "Required parameter requestParameters.serieId was null or undefined when calling serieImportCsvControllerImportCsv.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.dateFormat !== undefined) {
      queryParameters["dateFormat"] = requestParameters.dateFormat
    }

    if (requestParameters.numberLocale !== undefined) {
      queryParameters["numberLocale"] = requestParameters.numberLocale
    }

    if (requestParameters.separator !== undefined) {
      queryParameters["separator"] = requestParameters.separator
    }

    if (requestParameters.quote !== undefined) {
      queryParameters["quote"] = requestParameters.quote
    }

    if (requestParameters.stopOnError !== undefined) {
      queryParameters["stopOnError"] = requestParameters.stopOnError
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/serie-import-csv/{serieId}`.replace(
        `{${"serieId"}}`,
        encodeURIComponent(String(requestParameters.serieId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SerieImportResultFromJSON(jsonValue))
  }

  /**
   * Imports metric data.     The first column must be the metric date with a customizable format (default is ISO).      The next columns must be the metric data in numeric with a customizable locale (default is FR).     The header of metric columns must match the serie metric names.     Ex:        \"date\";\"activity\"       \"2004-01-01T00:30:00\";\"181,63\"
   * Add new data to a serie or override existing data
   */
  async serieImportCsvControllerImportCsv(
    requestParameters: SerieImportCsvControllerImportCsvRequest,
  ): Promise<SerieImportResult> {
    const response = await this.serieImportCsvControllerImportCsvRaw(requestParameters)
    return await response.value()
  }

  /**
   * Check that our csv content can be used as a serie data source.     The first column must be the metric date with a customizable format (default is ISO).      The next columns must be the metric data in numeric with a customizable locale (default is FR).     The header of metric columns must match the serie metric names.     Ex:        \"date\";\"activity\"       \"2004-01-01T00:30:00\";\"181,63\"
   * Validate a CSV input, fetch all errors
   */
  async serieImportCsvControllerValidateCsvRaw(
    requestParameters: SerieImportCsvControllerValidateCsvRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.serieId === null || requestParameters.serieId === undefined) {
      throw new runtime.RequiredError(
        "serieId",
        "Required parameter requestParameters.serieId was null or undefined when calling serieImportCsvControllerValidateCsv.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.dateFormat !== undefined) {
      queryParameters["dateFormat"] = requestParameters.dateFormat
    }

    if (requestParameters.numberLocale !== undefined) {
      queryParameters["numberLocale"] = requestParameters.numberLocale
    }

    if (requestParameters.separator !== undefined) {
      queryParameters["separator"] = requestParameters.separator
    }

    if (requestParameters.quote !== undefined) {
      queryParameters["quote"] = requestParameters.quote
    }

    if (requestParameters.stopOnError !== undefined) {
      queryParameters["stopOnError"] = requestParameters.stopOnError
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/serie-import-csv/{serieId}/validate-csv`.replace(
        `{${"serieId"}}`,
        encodeURIComponent(String(requestParameters.serieId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Check that our csv content can be used as a serie data source.     The first column must be the metric date with a customizable format (default is ISO).      The next columns must be the metric data in numeric with a customizable locale (default is FR).     The header of metric columns must match the serie metric names.     Ex:        \"date\";\"activity\"       \"2004-01-01T00:30:00\";\"181,63\"
   * Validate a CSV input, fetch all errors
   */
  async serieImportCsvControllerValidateCsv(
    requestParameters: SerieImportCsvControllerValidateCsvRequest,
  ): Promise<void> {
    await this.serieImportCsvControllerValidateCsvRaw(requestParameters)
  }
}

/**
 * @export
 * @enum {string}
 */
export enum SerieImportCsvControllerImportCsvNumberLocaleEnum {
  En = "en",
  Fr = "fr",
}
/**
 * @export
 * @enum {string}
 */
export enum SerieImportCsvControllerValidateCsvNumberLocaleEnum {
  En = "en",
  Fr = "fr",
}
