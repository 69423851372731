import * as Cookies from "js-cookie"
import React, { useEffect, useContext } from "react"
import { useConfig } from "../config"
import { AnyChildren } from "../react-type-helpers"
import { useApiClient } from "./api"
import { AsyncHook } from "./async"
import { useGlobalMessage } from "./message"

interface RegisterParams {
  firstname: string
  lastname: string
  email: string
  password: string
  username: string
  company: string
  position: string
}

interface AuthContextState {
  isAuthenticated: boolean
  jwt: string | null
  login: (params: { username: string; password: string }) => void
  register: (params: RegisterParams) => void
  logout: () => void
}

const defaultValue: AuthContextState = {
  isAuthenticated: false,
  jwt: null,
  login: () => {},
  register: () => {},
  logout: () => {},
}
const AuthContext = React.createContext<AuthContextState>(defaultValue)

export function useJwt() {
  const { AUTH_COOKIE_NAME } = useConfig()
  const { jwt } = useContext(AuthContext)
  if (jwt) {
    return jwt
  } else {
    return Cookies.get(AUTH_COOKIE_NAME)
  }
}

export function AuthContextProvider({ children }: { children: AnyChildren }) {
  const { AUTH_COOKIE_NAME, AUTH_COOKIE_DOMAIN } = useConfig()
  // initialize auth state with some cookie, miam
  const [auth, setAuth] = React.useState(() => {
    const jwt = Cookies.get(AUTH_COOKIE_NAME)
    return jwt
      ? {
          ...defaultValue,
          isAuthenticated: true,
          jwt,
          logout,
        }
      : defaultValue
  })
  const TimelightApi = useApiClient()

  function logout() {
    setAuth(defaultValue)
    Cookies.remove(AUTH_COOKIE_NAME, { domain: AUTH_COOKIE_DOMAIN })
  }
  async function register(params: RegisterParams) {
    const res = await TimelightApi.userControllerRegister({ registerDto: params })
    const jwt = res.jwt
    Cookies.set(AUTH_COOKIE_NAME, jwt, { domain: AUTH_COOKIE_DOMAIN })
    setAuth({
      ...auth,
      isAuthenticated: true,
      jwt,
      logout,
    })
  }

  async function login(params: { username: string; password: string }) {
    const res = await TimelightApi.userControllerLogin({ loginDto: params })
    const jwt = res.jwt
    Cookies.set(AUTH_COOKIE_NAME, jwt, { domain: AUTH_COOKIE_DOMAIN })
    setAuth({
      ...auth,
      isAuthenticated: true,
      jwt,
      logout,
    })
  }

  // and the provider provode... provided... provide...
  // and the auth is properly set
  return (
    <AuthContext.Provider
      value={{
        ...auth,
        login,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextState {
  return React.useContext(AuthContext)
}

export function useLogoutOnError(asyncHookRes: AsyncHook<any, any>) {
  const { logout } = useAuth()
  const error = asyncHookRes[0].error
  useEffect(() => {
    if (error && error.status === 401) {
      logout()
    }
  }, [error, logout])
}

export function useAlertOnReadOnly(asyncHookRes: AsyncHook<any, any>) {
  const handleApiException = useApiExceptionHandler()
  const error = asyncHookRes[0].error
  useEffect(() => {
    handleApiException(error)
  }, [error, handleApiException])
}

export function useApiExceptionHandler() {
  const { showMessage } = useGlobalMessage()
  const { CONTACT_FORM_URL } = useConfig()
  return (e: any) => {
    if ((e && e.status === 429) || (e && e.statusCode === 429)) {
      showMessage(
        <>
          Vous avez dépassé le nombre de sources permis par votre plan
          <a style={{ margin: "0.2em" }} href={CONTACT_FORM_URL} target="_blank" rel="noopener noreferrer">
            Contactez nous
          </a>{" "}
          pour augmenter la capacité de votre compte.
        </>,
      )
    }
  }
}
