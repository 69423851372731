/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  AlertDto,
  AlertDtoFromJSON,
  AlertDtoFromJSONTyped,
  AlertDtoToJSON,
  DayModelDto,
  DayModelDtoFromJSON,
  DayModelDtoFromJSONTyped,
  DayModelDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface ImportDaysResultDto
 */
export interface ImportDaysResultDto {
  /**
   * List of days with a matching model
   * @type {Array<DayModelDto>}
   * @memberof ImportDaysResultDto
   */
  days: Array<DayModelDto>
  /**
   * List of alerts
   * @type {Array<AlertDto>}
   * @memberof ImportDaysResultDto
   */
  alerts: Array<AlertDto>
}

export function ImportDaysResultDtoFromJSON(json: any): ImportDaysResultDto {
  return ImportDaysResultDtoFromJSONTyped(json, false)
}

export function ImportDaysResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportDaysResultDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    days: (json["days"] as Array<any>).map(DayModelDtoFromJSON),
    alerts: (json["alerts"] as Array<any>).map(AlertDtoFromJSON),
  }
}

export function ImportDaysResultDtoToJSON(value?: ImportDaysResultDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    days: (value.days as Array<any>).map(DayModelDtoToJSON),
    alerts: (value.alerts as Array<any>).map(AlertDtoToJSON),
  }
}
