import React from "react"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PageBlock from "component/PageBlock"
import { useTranslation } from "react-i18next"
import AppHeader from "../../component/AppHeader"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import SourceSelect from "../../component/SourceSelect"
import { useSourceSelect } from "../../state/persisted"
import { useSourceStats, useSerieData } from "../../state/api"
import { toDateObj, formatDate } from "../../lib/date"
import { formatNumber } from "lib/number"
import { createTableExportCsv, createDateColumnConfig } from "../../component/MaterialTable/material-table-helpers"
import { MaterialTable } from "../../component/MaterialTable"
import { SourceDto } from "../../api/timelight-api/models/SourceDto"
import { SerieData } from "../../component/SerieChart"
import { SeriesDataView } from "../../component/SeriesDataView"
import { FetchSerieDataCompactResult } from "../../api/timelight-api/models/FetchSerieDataCompactResult"
import * as lodash from "lodash"
import ConfigIcon from "@material-ui/icons/Build"
import AppLink from "../../component/AppLink/index"
import { AppButton } from "../../component/AppButton"
import { AppRoute } from "../../state/route"
import { getSerieDataResample } from "../../component/SerieChart/SerieChart"
import {
  createTextColumnConfig,
  extractColumnConfigAndExportConfig,
} from "../../component/MaterialTable/material-table-helpers"

export default function SourceExploration() {
  // the app bar for values
  const [source, setSourceId] = useSourceSelect()
  const { t } = useTranslation()

  const [{ data: sourceStat }] = useSourceStats({
    shouldTrigger: source.id !== -1,
    sourceId: source.id,
  })
  const [{ data: hcData }] = useSerieData({
    shouldTrigger: source.id !== -1 && !!sourceStat,
    serieId: source.serieId,
    begin: sourceStat?.beginDate || new Date(),
    end: sourceStat?.endDate || new Date(),
    resample: getSerieDataResample(sourceStat?.beginDate || new Date(), sourceStat?.endDate || new Date()),
  })

  return (
    <PageContainer title={`Configuration ${t("source.aname")} > ${source.name}`}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 15,
            }}
          >
            <div style={{ marginRight: "1em" }}>Exploration de {t("source.aname")} </div>
            <SourceSelect sourceId={source.id} setSourceId={setSourceId} />
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <PageBlock loading={!sourceStat} title={`${t("source.Name")} : ${source.name}`}>
          {sourceStat && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <dl>
                <dt>Identifiant technique {t("global.appTitle")} :</dt>
                <dd>
                  <strong>{source.id}</strong>
                </dd>

                <dt>Valeur moyenne :</dt>
                <dd>
                  <strong>{sourceStat.mean}</strong>
                </dd>
                <dt>Date de création :</dt>
                <dd>
                  <strong>{formatDate(source.createdDate)}</strong>
                </dd>

                <dt>Date de dernière mise à jour :</dt>
                <dd>
                  <strong>
                    {sourceStat.lastImportExecStartDate
                      ? formatDate(sourceStat.lastImportExecStartDate)
                      : "Pas de données importées"}
                  </strong>
                </dd>

                <dt>Ecart type :</dt>
                <dd>
                  <strong>{sourceStat.std}</strong>
                </dd>

                <dt>Nombre de points :</dt>
                <dd>
                  <strong>{sourceStat.count}</strong>
                </dd>
              </dl>
              <div>
                <AppLink route={AppRoute.DATA_SOURCE_CONFIG} params={{ sourceId: source.id + "" }}>
                  <AppButton style={{ margin: "1em" }} variant="outlined">
                    <ConfigIcon />
                  </AppButton>
                </AppLink>
              </div>
            </div>
          )}
        </PageBlock>
        <PageBlock loading={!sourceStat} title={`Activité de ${source.name}`}>
          {sourceStat && (
            <SeriesDataView
              begin={sourceStat.beginDate}
              end={sourceStat.endDate}
              serieIds={[source.serieId]}
              serieNames={[source.name]}
            />
          )}
        </PageBlock>
        <PageBlock loading={!hcData} titleInContent={true}>
          {hcData && <SourceDataTable source={source} hcData={hcData} />}
        </PageBlock>
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const SourceDataTable = React.memo(function SourceDataTableComponent({
  source,
  hcData,
}: {
  source: SourceDto
  hcData: FetchSerieDataCompactResult
}) {
  type RowType = SerieData[0]
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<RowType>([
    createDateColumnConfig({
      title: "Date",
      getDate: (row) => new Date(row[0]),
      withTime: true,
      domain: [
        toDateObj(lodash.min(hcData.data.map((v) => v[0])) as number),
        toDateObj(lodash.max(hcData.data.map((v) => v[0])) as number),
      ],
    }),
    createTextColumnConfig({
      title: "Activité",
      getTextValue: (row) => (row[1] !== null ? formatNumber(row[1]) : ""),
    }),
  ])

  return (
    <>
      <MaterialTable<RowType>
        title={`Détail des données de ${source.name}`}
        data={hcData.data as RowType[]}
        columns={columns}
        options={{
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `source_data_${source.name}.csv`,
          }),
        }}
      />
    </>
  )
})
