/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { ModelDto, ModelDtoFromJSON, ModelDtoFromJSONTyped, ModelDtoToJSON } from "./"

/**
 *
 * @export
 * @interface ModelListDto
 */
export interface ModelListDto {
  /**
   * List of models
   * @type {Array<ModelDto>}
   * @memberof ModelListDto
   */
  models: Array<ModelDto>
}

export function ModelListDtoFromJSON(json: any): ModelListDto {
  return ModelListDtoFromJSONTyped(json, false)
}

export function ModelListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    models: (json["models"] as Array<any>).map(ModelDtoFromJSON),
  }
}

export function ModelListDtoToJSON(value?: ModelListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    models: (value.models as Array<any>).map(ModelDtoToJSON),
  }
}
