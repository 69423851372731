import React, { useState } from "react"
import { Map, Marker, GoogleApiWrapper, ProvidedProps as AdditionalGoogleProps } from "google-maps-react"
import { useConfig } from "config"

interface GmapsSource {
  name?: string | null | undefined
  latitude?: number | null | undefined
  longitude?: number | null | undefined
}

interface SourceGmapsProps<SourceType extends GmapsSource> {
  sources: SourceType[]
  onSourceClick: (source: SourceType) => void
  onClick: () => void
}

function SourcesGmapsView<SourceType extends GmapsSource>({
  sources: rawSources,
  onSourceClick,
  onClick,
  google,
}: SourceGmapsProps<SourceType> & AdditionalGoogleProps) {
  const sources = rawSources.filter(
    (source) => typeof source.longitude === "number" && typeof source.latitude === "number",
  )
  const [mapObj, setMapObj] = useState<null | google.maps.Map<any>>(null)
  if (mapObj && sources.length > 0) {
    const bounds = new google.maps.LatLngBounds()
    // extends map bounds to show all markers
    for (const source of sources) {
      bounds.extend({ lng: source.longitude || 0, lat: source.latitude || 0 })
    }
    // special case where only one source
    if (sources.length === 1) {
      const singleSourceZoomOut = 0.01
      bounds.extend({
        lng: (sources[0].longitude || 0) - singleSourceZoomOut,
        lat: (sources[0].latitude || 0) - singleSourceZoomOut,
      })
      bounds.extend({
        lng: (sources[0].longitude || 0) + singleSourceZoomOut,
        lat: (sources[0].latitude || 0) + singleSourceZoomOut,
      })
    }
    mapObj.fitBounds(bounds)
    // avoid re-fitting
    setMapObj(null)
  }
  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <Map google={google} zoom={12} onReady={(_, map) => setMapObj(map || null)} onClick={onClick}>
        {sources.map((source, i) => (
          <Marker
            key={i}
            label={source.name || ""}
            position={{ lng: source.longitude || 0, lat: source.latitude || 0 }}
            onClick={() => onSourceClick(source)}
          />
        ))}
      </Map>
    </div>
  )
}

export function SourcesGmaps<SourceType extends GmapsSource>(props: SourceGmapsProps<SourceType>) {
  const { GMAPS_API_KEY } = useConfig()
  const SourcesGmapsApi = GoogleApiWrapper({ apiKey: GMAPS_API_KEY })(SourcesGmapsView) as any
  return (
    <div className="google-map" style={{ width: "100%", height: "100%" }}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .google-map > div {
          position: relative;
          height: 100%;
        }
        
      `,
        }}
      ></style>
      <SourcesGmapsApi {...props} />
    </div>
  )
}
