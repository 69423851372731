/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CreateSourceDayDto
 */
export interface CreateSourceDayDto {
  /**
   * The date with a format YYYY-MM-DD
   * @type {string}
   * @memberof CreateSourceDayDto
   */
  date: string
  /**
   * An array of 1, 2, 4, 8, 12, 24, 48, 72, 144 (default) or 1440 values values.        Each value represents a time window of 24h, 12h, 6h, 3h, 2h, 1h, 30m, 20m, 10m or 1m
   * @type {Array<number>}
   * @memberof CreateSourceDayDto
   */
  activity: Array<number>
}

export function CreateSourceDayDtoFromJSON(json: any): CreateSourceDayDto {
  return CreateSourceDayDtoFromJSONTyped(json, false)
}

export function CreateSourceDayDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSourceDayDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    date: json["date"],
    activity: json["activity"],
  }
}

export function CreateSourceDayDtoToJSON(value?: CreateSourceDayDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date: value.date,
    activity: value.activity,
  }
}
