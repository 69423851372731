/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface AlertFavoriteDto
 */
export interface AlertFavoriteDto {
  /**
   * True to be set as favorite, false otherwise
   * @type {boolean}
   * @memberof AlertFavoriteDto
   */
  favorite: boolean
}

export function AlertFavoriteDtoFromJSON(json: any): AlertFavoriteDto {
  return AlertFavoriteDtoFromJSONTyped(json, false)
}

export function AlertFavoriteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertFavoriteDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    favorite: json["favorite"],
  }
}

export function AlertFavoriteDtoToJSON(value?: AlertFavoriteDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    favorite: value.favorite,
  }
}
