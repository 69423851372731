/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayPatchDto, DayPatchDtoFromJSON, DayPatchDtoFromJSONTyped, DayPatchDtoToJSON } from "./"

/**
 *
 * @export
 * @interface DaysPatchDto
 */
export interface DaysPatchDto {
  /**
   * The source id to update days on
   * @type {number}
   * @memberof DaysPatchDto
   */
  sourceId: number
  /**
   * The list of days to update
   * @type {Array<DayPatchDto>}
   * @memberof DaysPatchDto
   */
  days: Array<DayPatchDto>
}

export function DaysPatchDtoFromJSON(json: any): DaysPatchDto {
  return DaysPatchDtoFromJSONTyped(json, false)
}

export function DaysPatchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaysPatchDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sourceId: json["sourceId"],
    days: (json["days"] as Array<any>).map(DayPatchDtoFromJSON),
  }
}

export function DaysPatchDtoToJSON(value?: DaysPatchDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    days: (value.days as Array<any>).map(DayPatchDtoToJSON),
  }
}
