import React from "react"

import { Tooltip } from "@material-ui/core"
import CompletedIcon from "@material-ui/icons/Done"
import WaitingIcon from "@material-ui/icons/QueryBuilder"
import FailIcon from "@material-ui/icons/Error"
import { TaskStatus } from "../api/timelight-api/models/TaskStatus"
import Loader from "./Loader"
import { createProperTextEnumColumnConfig } from "./MaterialTable/material-table-helpers"
import { useTaskStream, Task } from "../state/tasksStream"

const statusTextMap = {
  [TaskStatus.Active]: "Tâche en cours de traitement",
  [TaskStatus.Completed]: "Tâche terminée",
  [TaskStatus.Failed]: "Echec de la tâche",
  [TaskStatus.Waiting]: "Tâche en attente de traitement",
  [TaskStatus.Killed]: "Tâche annulée pour raison technique",
}

const statusColorMap = {
  primary: {
    [TaskStatus.Active]: "#0071AA",
    [TaskStatus.Completed]: "#1f8b40",
    [TaskStatus.Failed]: "#e05757",
    [TaskStatus.Waiting]: "#dbb951",
    [TaskStatus.Killed]: "#e05757",
  },
  inverted: {
    [TaskStatus.Active]: "#ecf1f4",
    [TaskStatus.Completed]: "#00ff4e",
    [TaskStatus.Failed]: "#e05757",
    [TaskStatus.Waiting]: "#ffd657",
    [TaskStatus.Killed]: "#e05757",
  },
}

export function createTaskStatusColumnConfig<T extends Task>() {
  return createProperTextEnumColumnConfig<T, TaskStatus>({
    title: "Statut de la tâche",
    getEnumValue: (row) => row.status,
    enumLookup: statusTextMap,
    render: (row) => <TaskStatusActivity large={true} task={row} />,
    field: "status",
  })
}
export default function TaskStatusActivity<T extends Task>({
  task: initialTask,
  large = false,
  color = "primary",
}: {
  task: T
  large?: boolean
  color?: "primary" | "inverted"
}) {
  const task = useTaskStream(initialTask)
  // task is still loading
  if (task === null) {
    return <Loader />
  }
  return (
    <div
      style={{
        color: statusColorMap[color][task.status],
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: statusColorMap[color][task.status],
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {(task.status === TaskStatus.Failed || task.status === TaskStatus.Killed) && (
          <Tooltip style={{ display: "flex" }} title={statusTextMap[task.status]}>
            <div>
              <FailIcon color="inherit" fontSize="inherit" />
            </div>
          </Tooltip>
        )}
        {task.status === TaskStatus.Waiting && (
          <Tooltip style={{ display: "flex" }} title={statusTextMap[task.status]}>
            <div>
              <WaitingIcon color="inherit" fontSize="inherit" />
            </div>
          </Tooltip>
        )}
        {task.status === TaskStatus.Active && (
          <Tooltip style={{ display: "flex" }} title={statusTextMap[task.status]}>
            <div>
              <Loader color="inherit" />
            </div>
          </Tooltip>
        )}
        {task.status === TaskStatus.Completed && (
          <Tooltip style={{ display: "flex" }} title={statusTextMap[task.status]}>
            <div>
              <CompletedIcon color="inherit" fontSize="inherit" />
            </div>
          </Tooltip>
        )}
      </div>
      {large && <div style={{ marginLeft: 5 }}>{statusTextMap[task.status]}</div>}
    </div>
  )
}
