/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayContextTypes, DayContextTypesFromJSON, DayContextTypesFromJSONTyped, DayContextTypesToJSON } from "./"

/**
 *
 * @export
 * @interface ContextTypeStatsDto
 */
export interface ContextTypeStatsDto {
  /**
   *
   * @type {Date}
   * @memberof ContextTypeStatsDto
   */
  beginDate: Date
  /**
   *
   * @type {Date}
   * @memberof ContextTypeStatsDto
   */
  endDate: Date
  /**
   *
   * @type {number}
   * @memberof ContextTypeStatsDto
   */
  mean: number
  /**
   *
   * @type {number}
   * @memberof ContextTypeStatsDto
   */
  std: number
  /**
   *
   * @type {number}
   * @memberof ContextTypeStatsDto
   */
  count: number
  /**
   *
   * @type {DayContextTypes}
   * @memberof ContextTypeStatsDto
   */
  ctxType: DayContextTypes
}

export function ContextTypeStatsDtoFromJSON(json: any): ContextTypeStatsDto {
  return ContextTypeStatsDtoFromJSONTyped(json, false)
}

export function ContextTypeStatsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContextTypeStatsDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    beginDate: new Date(json["beginDate"]),
    endDate: new Date(json["endDate"]),
    mean: json["mean"],
    std: json["std"],
    count: json["count"],
    ctxType: DayContextTypesFromJSON(json["ctxType"]),
  }
}

export function ContextTypeStatsDtoToJSON(value?: ContextTypeStatsDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    beginDate: value.beginDate.toISOString(),
    endDate: value.endDate.toISOString(),
    mean: value.mean,
    std: value.std,
    count: value.count,
    ctxType: DayContextTypesToJSON(value.ctxType),
  }
}
