import React from "react"
import { DayContextTypes } from "../api/timelight-api/models/DayContextTypes"
import { useSourceContextDateDomains } from "../state/api"
import { createStrIdMap } from "../lib/array"
import {
  extractColumnConfigAndExportConfig,
  createTextColumnConfig,
  createDateColumnConfig,
} from "./MaterialTable/material-table-helpers"
import { MaterialTable } from "./MaterialTable"
import Loader from "./Loader"
import { InputLabel, FormControlLabel, Checkbox } from "@material-ui/core"
import * as lodash from "lodash"
import { contextTypeToText, dayContextTypeValues } from "./DaysNearDateModal/DayContextAnalysis"

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
type DayContextSelectTableRowType = { ctxType: DayContextTypes }
// use memoized function to handle selection properly
const ctxTypeToRow = lodash.memoize((ctxType: DayContextTypes): DayContextSelectTableRowType => ({ ctxType }))
const contextTypes = dayContextTypeValues

export const ContextTypeSelectTable = React.memo(function ContextTypeSelectTableComponent({
  initialOnlySelection = false,
  sourceId,
  selection,
  onSelectionChange,
  filterByDateDomain,
  readonly = false,
}: {
  initialOnlySelection?: boolean
  sourceId: number | null
  selection: DayContextTypes[]
  onSelectionChange: (contextTypes: DayContextTypes[]) => void
  filterByDateDomain?: { begin: Date; end: Date }
  readonly?: boolean
}) {
  const [onlySelection, setOnlySelection] = React.useState<boolean>(initialOnlySelection)
  const [{ data: contextDateDomains }] = useSourceContextDateDomains({
    shouldTrigger: sourceId !== null && sourceId !== -1,
    sourceId: sourceId || -1,
  })

  if (!contextDateDomains) {
    return (
      <>
        <Loader />
      </>
    )
  }

  const contextDateDomainsByCtxType = createStrIdMap(contextDateDomains.domains, (s) => s.ctxType)
  const { columns } = extractColumnConfigAndExportConfig<DayContextSelectTableRowType>([
    createTextColumnConfig({
      title: "Donnée contextuelle",
      getTextValue: (row) => contextTypeToText(row.ctxType),
    }),
    createDateColumnConfig({
      title: "Première donnée",
      withTime: false,
      getDate: (row) => contextDateDomainsByCtxType[row.ctxType].beginDate,
    }),
    createDateColumnConfig({
      title: "Dernière donnée",
      withTime: false,
      getDate: (row) => contextDateDomainsByCtxType[row.ctxType].endDate,
    }),
  ])

  return (
    <div style={{ position: "relative" }}>
      <MaterialTable<DayContextSelectTableRowType>
        title={<InputLabel>Données contextuelles utilisées pour la modélisation</InputLabel>}
        onRowClick={(row) => {
          if (row) {
            onSelectionChange(
              selection.some((s) => s === row.ctxType)
                ? selection.filter((s) => s !== row.ctxType)
                : lodash.uniq([...selection, row.ctxType]),
            )
          }
        }}
        data={
          onlySelection
            ? contextTypes.filter((ctxType) => selection.some((ctt) => ctt === ctxType)).map(ctxTypeToRow)
            : contextTypes
                // keep context types with data only
                .filter((ctxType) => contextDateDomainsByCtxType[ctxType] !== undefined)
                // apply filter by date domain if applicable
                .filter((ctxType) =>
                  !filterByDateDomain
                    ? true
                    : contextDateDomainsByCtxType[ctxType].beginDate <= filterByDateDomain.end &&
                      contextDateDomainsByCtxType[ctxType].endDate >= filterByDateDomain.begin,
                )
                .map(ctxTypeToRow)
        }
        selectedRows={selection.map(ctxTypeToRow)}
        onSelectionChange={(selectedRows) => onSelectionChange(selectedRows.map((row) => row.ctxType))}
        columns={columns}
        options={{ pageSize: 5, selection: !readonly, filtering: false, exportButton: false }}
      />

      {!readonly && (
        <div style={{ position: "absolute", bottom: "7px", left: "11px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlySelection}
                onChange={(e: any) => setOnlySelection(e.target.checked)}
                value="onlySelection"
                color="primary"
              />
            }
            label="Voir uniquement la sélection"
          />
        </div>
      )}
    </div>
  )
})
