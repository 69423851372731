import AddBox from "@material-ui/icons/AddBox"
import ArrowUpward from "@material-ui/icons/ArrowUpward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import * as lodash from "lodash"
import {
  Column as MColumn,
  Icons,
  Localization as MTLocalization,
  Query as MQuery,
  Filter as MFilter,
} from "material-table"
import numeral from "numeral"
import React from "react"
import { forwardRef } from "react"
import { arrayToCsvString } from "../../lib/csv"
import { dateObjToDayString, formatDate, formatDateTime } from "../../lib/date"
import { downloadStringAsFile } from "../../lib/file"
import { formatNumber } from "../../lib/number"
import { AnyChildren } from "../../react-type-helpers"
import { TimelightApi } from "../../state/api"
import { SourceDto } from "../../api/timelight-api/models/SourceDto"
import { createIdMap } from "../../lib/array"
import { QueryResult } from "material-table"

export type Column<RowData extends object> = Omit<MColumn<RowData>, "type"> & {
  type?: MColumn<RowData>["type"] | "string"
  customType?: "daterange" | "number" | "sourceId"
}
export type Filter<RowData extends object> = Omit<MFilter<RowData>, "column"> & { column: Column<RowData> }
export type Query<RowData extends object> = Omit<MQuery<RowData>, "filters" | "orderBy"> & {
  filters: Filter<RowData>[]
  orderBy: Column<RowData>
}

export const materialTableLocalization: MTLocalization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} sur {count}",
    labelRowsSelect: "lignes",
    // firstAriaLabel: "1ère page",
    firstTooltip: "Afficher la première page",
    // previousAriaLabel: "Précédent",
    previousTooltip: "Afficher la page précédente",
    // nextAriaLabel: "Suivant",
    nextTooltip: "Afficher la page suivante",
    // lastAriaLabel: "Dernière page",
    lastTooltip: "Afficher la dernière page",
  },
  toolbar: {
    addRemoveColumns: "Ajouter ou supprimer des colonnes",
    nRowsSelected: "{0} lignes sélectionnées",
    showColumnsTitle: "Montrer les colonnes",
    showColumnsAriaLabel: "Montrer les colonnes",
    exportTitle: "Exporter au format CSV",
    exportAriaLabel: "Exporter au format CSV",
    exportName: "Exporter au format CSV",
    searchTooltip: "Rechercher dans la table",
    searchPlaceholder: "Rechercher dans la table",
  },
  body: {
    emptyDataSourceMessage: "Aucune donnée à afficher",
    addTooltip: "Ajouter",
    deleteTooltip: "Supprimer",
    editTooltip: "Éditer",
    filterRow: {
      filterTooltip: "Filtrer",
    },
    editRow: {
      deleteText: "Supprimer",
      cancelTooltip: "Annuler",
      saveTooltip: "Sauvegarder",
    },
  },
  grouping: {
    placeholder: "Cliquer-glisser les en-têtes",
  },
  header: {
    actions: "Actions",
  },
}

export const materialTableIcons: Icons = {
  // @ts-ignore
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  // @ts-ignore
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  // @ts-ignore
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // @ts-ignore
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  // @ts-ignore
  DetailPanel: forwardRef((props, ref) => (
    // @ts-ignore
    <ChevronRight {...props} ref={ref} />
  )),
  // @ts-ignore
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  // @ts-ignore
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // @ts-ignore
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <span ref={ref}></span>),
  // @ts-ignore
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  // @ts-ignore
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  // @ts-ignore
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  // @ts-ignore
  PreviousPage: forwardRef((props, ref) => (
    // @ts-ignore
    <ChevronLeft {...props} ref={ref} />
  )),
  // @ts-ignore
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // @ts-ignore
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  // @ts-ignore
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),

  // @ts-ignore
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  // @ts-ignore
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

export function createTextColumnConfig<T extends object>({
  title,
  render,
  getTextValue,
  field = null,
}: {
  title: string
  getTextValue: (row: T) => string | undefined
  render?: (row: T) => AnyChildren
  field?: keyof T | null
}): Column<T> & ExportConfig<T> {
  return {
    customFilterAndSearch: (term: string | string[], row: T) => {
      const value = getTextValue(row) || ""
      return matchSearchTerm(term, value)
    },
    customSort: (row1: T, row2: T) => {
      const a = (getTextValue(row1) || "").toLocaleLowerCase().trim()
      const b = (getTextValue(row2) || "").toLocaleLowerCase().trim()
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    },
    sorting: field !== null,
    field: field ? field : undefined,
    filtering: field !== null,
    render: (row: T) => (render ? render(row) : getTextValue(row) || ""),
    type: ("string" as unknown) as undefined,
    title,
    exportHeader: title,
    exportRender: (row: T) => getTextValue(row) || "",
  }
}

export function createSourceIdColumnConfig<T extends object>({
  title,
  render,
  getTextValue,
  field = null,
}: {
  title: string
  getTextValue: (row: T) => string | undefined
  render?: (row: T) => AnyChildren
  field?: keyof T | null
}): Column<T> & ExportConfig<T> {
  // same as text config with a custom type for custom filter
  const textConfig = createTextColumnConfig({ title, render, getTextValue, field })
  return { ...textConfig, customType: "sourceId", type: "string", sorting: false }
}

export function createDateColumnConfig<T extends object>({
  title,
  getDate,
  domain,
  render,
  withTime = false,
  field = null,
}: {
  title: string
  getDate: (row: T) => Date | null
  domain?: Date[]
  render?: (row: T) => AnyChildren
  withTime?: boolean
  field?: keyof T | null
}): Column<T> & ExportConfig<T> {
  const formatter = withTime ? formatDateTime : formatDate
  const config: Column<T> & ExportConfig<T> = {
    customFilterAndSearch: (term: string | string[], row: T) => {
      const d = getDate(row)
      return d ? matchSearchTerm(term, dateObjToDayString(d)) : false
    },
    customSort: (row1: T, row2: T) => {
      const d1 = getDate(row1)
      const d2 = getDate(row2)
      if (!d1 && !d2) {
        return 0
      } else if (!d1) {
        return -1
      } else if (!d2) {
        return 1
      }
      return d1.getTime() - d2.getTime()
    },
    render: (row: T) => {
      if (render) {
        return render(row)
      }
      const d = getDate(row)
      if (!d) {
        return ""
      }
      return formatter(d)
    },
    // the lib doesn't know we have a custom type
    type: "date",
    customType: "daterange",
    title,
    sorting: field !== null,
    field: field ? field : undefined,
    filtering: field !== null,
    exportHeader: title,
    exportRender: (row: T) => {
      const d = getDate(row)
      if (!d) {
        return ""
      }
      return formatter(d)
    },
  }
  /* add this column separately because it's unknown from
     the lib and the typescript types. This allow us to
     keep the compiler checks for the other fields */
  return ({
    ...config,
    dateRangeDomain: domain,
  } as unknown) as Column<T> & ExportConfig<T>
}

export function createNumericColumnConfig<T extends object>({
  title,
  getValue,
  render,
  isInt = false,
  field = null,
}: {
  title: string
  getValue: (row: T) => number | undefined | null
  render?: (row: T) => AnyChildren
  isInt?: boolean
  field?: keyof T | null
}): Column<T> & ExportConfig<T> {
  return {
    customFilterAndSearch: (term: string | string[], row: T) => {
      /* concat formatted value and raw value 
         to be able to filter on both.
         Ex: value: 100000.03, formatted: 100 000,03, raw: 100000.03.
         Allow to search for "100000"
      */
      const value = getValue(row)
      if (value === undefined || value === null) {
        return false
      }
      return matchSearchTerm(
        term,
        formatNumber(value) + "              " + numeral(value).format(isInt ? "0,0" : "0.00"),
      )
    },
    customSort: (row1: T, row2: T) => {
      const v1 = getValue(row1)
      const v2 = getValue(row2)
      if (v1 === v2) {
        return 0
      }
      if (v1 === undefined || v1 === null) {
        return -1
      }
      if (v2 === undefined || v2 === null) {
        return 1
      }
      return v1 - v2
    },
    render: (row: T) => {
      const value = getValue(row)
      return render ? render(row) : value === undefined || value === null ? "" : formatNumber(value, isInt)
    },
    /* we set the type as text to get a text filter
       filtering numbers with a number filter does
       not make sense. TODO: Implement a custom type 
       and update FilterRow.js to render a slider. 
     */
    // type: "numeric",
    type: "string",
    customType: "number",
    title,
    sorting: field !== null,
    field: field ? field : undefined,
    filtering: field !== null,
    exportHeader: title,
    exportRender: (row: T) => {
      const value = getValue(row)
      return value === undefined || value === null ? "" : formatNumber(value)
    },
  }
}

export function createIntegerColumnConfig<T extends object>({
  title,
  getValue,
  render,
}: {
  title: string
  getValue: (row: T) => number
  render?: (row: T) => AnyChildren
}): Column<T> & ExportConfig<T> {
  return {
    customFilterAndSearch: (term: string | string[], row: T) => {
      /* concat formatted value and raw value 
         to be able to filter on both.
         Ex: value: 100000.03, formatted: 100 000,03, raw: 100000.03.
         Allow to search for "100000"
      */
      return matchSearchTerm(term, numeral(getValue(row)).format("0"))
    },
    customSort: (row1: T, row2: T) => {
      return getValue(row1) - getValue(row2)
    },
    render: (row: T) => (render ? render(row) : numeral(getValue(row)).format("0")),
    /* we set the type as text to get a text filter
       filtering numbers with a number filter does
       not make sense. TODO: Implement a custom type 
       and update FilterRow.js to render a slider. 
     */
    type: ("number" as unknown) as undefined,
    title,
    exportHeader: title,
    exportRender: (row: T) => numeral(getValue(row)).format("0"),
  }
}

export function createTextEnumColumnConfig<T extends object>({
  title,
  getTextValue,
  enumValues,
  render,
  sortEnum = false,
  field = null,
}: {
  title: string
  getTextValue: (row: T) => string
  enumValues: string[]
  render?: (row: T) => AnyChildren
  sortEnum?: boolean
  field?: keyof T | null
}): Column<T> & ExportConfig<T> {
  return {
    lookup: createLookup(sortEnum ? lodash.orderBy(enumValues, [(v) => v.toLocaleLowerCase()]) : enumValues),
    customFilterAndSearch: (term: string | string[], row: T) => {
      return matchSearchTerm(term, getTextValue(row))
    },
    customSort: (row1: T, row2: T) => {
      const a = getTextValue(row1).toLocaleLowerCase().trim()
      const b = getTextValue(row2).toLocaleLowerCase().trim()
      if (a < b) {
        return 1
      }
      if (a > b) {
        return -1
      }
      return 0
    },
    render: (row: T) => (render ? render(row) : getTextValue(row)),
    type: ("string" as unknown) as undefined,
    title,
    sorting: field !== null,
    field: field ? field : undefined,
    filtering: field !== null,
    exportHeader: title,
    exportRender: (row: T) => getTextValue(row),
  }
}

export function createProperTextEnumColumnConfig<T extends object, E extends string>({
  title,
  getEnumValue,
  enumLookup,
  render,
  field = null,
}: {
  title: string
  getEnumValue: (row: T) => E
  enumLookup: { [key: string]: string }
  render?: (row: T) => AnyChildren
  field?: keyof T | null
}): Column<T> & ExportConfig<T> {
  function getEnumText(row: T): string {
    return enumLookup[getEnumValue(row)]
  }
  return {
    lookup: enumLookup,
    render: (row: T) => (render ? render(row) : getEnumText(row)),
    title,
    sorting: field !== null,
    field: field ? field : undefined,
    filtering: field !== null,
    exportHeader: title,
    exportRender: (row: T) => getEnumText(row),
  }
}

export function extractColumnConfigAndExportConfig<T extends object>(
  columns: (Column<T> & Partial<ExportConfig<T>>)[],
): { columns: Column<T>[]; exportConfig: ExportConfig<T>[] } {
  return {
    // extract our column config
    columns: columns.map((column) => {
      const { exportRender: _, exportHeader: __, ...columnConfig } = column
      return columnConfig
    }),
    // extract the proper export config
    exportConfig: columns.map((column) => {
      const renderFn = column.render
      const field = column.field
      return {
        exportHeader: column.exportHeader
          ? column.exportHeader
          : column.title && typeof column.title === "string"
          ? column.title
          : column.field
          ? String(column.field)
          : "",
        exportRender: column.exportRender
          ? column.exportRender
          : renderFn
          ? (row: T) => renderFn(row, "row")
          : field
          ? (row: T) => row[(field as unknown) as keyof T]
          : () => "",
      }
    }),
  }
}

export interface ExportConfig<T> {
  exportHeader: string
  exportRender: (row: T) => string
}

export function createTableExportCsv<T extends object>({
  exportConfig,
  fileName,
  getData,
}: {
  exportConfig: ExportConfig<T>[]
  fileName: string
  getData?: (query: Query<T>) => Promise<QueryResult<T>>
}) {
  return async (_: Column<T>[], inputData: T[]) => {
    const header = exportConfig.map((config) => config.exportHeader)
    const query = ({
      filters: [],
      orderBy: undefined,
      orderDirection: "",
      page: 0,
      pageSize: 1000000000,
      search: "",
    } as unknown) as Query<T>
    const data = getData ? (await getData(query)).data : inputData
    const rows = data.map((row) => exportConfig.map((config) => config.exportRender(row)))
    downloadStringAsFile(arrayToCsvString(rows, { header }), { filename: fileName, mimeType: "text/csv" })
  }
}

export function matchSearchTerm(term: string | string[] | Date, value: string) {
  // if we have a lookup table
  if (Array.isArray(term)) {
    return term.length === 0 || term.some((t) => value === t)
  } else if (term instanceof Date) {
    return true
  } else {
    // if we have a text filter
    return value.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !== -1
  }
}

function createLookup(values: string[]): { [key: string]: string } {
  return values.reduce((agg, v) => Object.assign(agg, { [v]: v }), {})
}

export function createTypeORMCrudLimit<RowType extends object>(query: Query<RowType>) {
  return query.pageSize
}
export function createTypeORMCrudPage<RowType extends object>(query: Query<RowType>) {
  return query.page + 1
}

export function createTypeORMCrudSort<RowType extends object>(query: Query<RowType>) {
  return query.orderBy && query.orderBy.field
    ? [`${query.orderBy.field},${query.orderDirection.toUpperCase()}`]
    : undefined
}
export function createTypeORMCrudFilter<RowType extends object>(query: Query<RowType>) {
  function cleanValue(v: any) {
    return (v + "").replace(",", ".")
  }
  return query.filters.map((filter) => {
    if (filter.column.type === "date" && filter.column.customType === "daterange") {
      return `${filter.column.field}||$between||${cleanValue(lodash.min(filter.value))},${cleanValue(
        lodash.max(filter.value),
      )}`
    }
    if (Array.isArray(filter.value)) {
      if (filter.value.length > 0) {
        return `${filter.column.field}||$in||` + filter.value.map(cleanValue).join(",")
      } else {
        return ""
      }
    }
    if (filter.column.type === "string" && filter.column.customType === "sourceId") {
      return `${filter.column.field}||$in||` + cleanValue(filter.value)
    }
    if (filter.column.type === "string") {
      return `${filter.column.field}||$contL||` + cleanValue(filter.value)
    }
    return `${filter.column.field}||$in||` + cleanValue(filter.value)
  })
}

export type RowWithSource<RowType extends object, Key extends string = "source"> = RowType & { [key in Key]: SourceDto }

export async function fetchAndAddSourcesToRows<RowType extends object, Key extends string = "source">(
  api: TimelightApi,
  rows: RowType[],
  getSourceId: (row: RowType) => number,
  key: Key = "source" as Key,
): Promise<RowWithSource<RowType, Key>[]> {
  const sourceIds = rows.map(getSourceId)
  let sources: SourceDto[] = []
  if (sourceIds.length > 0) {
    sources = (
      await api.getManyBaseSourceControllerSourceDto({
        limit: sourceIds.length,
        filter: [`id||$in||${sourceIds.concat([-1]).join(",")}`],
      })
    ).data
  }
  const sourcesById = createIdMap(sources)

  // @ts-ignore
  return rows.map((si) => ({ ...si, [key as Key]: sourcesById[getSourceId(si)] }))
}
