/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  AlertActivityEnum,
  AlertActivityEnumFromJSON,
  AlertActivityEnumFromJSONTyped,
  AlertActivityEnumToJSON,
} from "./"

/**
 *
 * @export
 * @interface AlertRefDto
 */
export interface AlertRefDto {
  /**
   *
   * @type {number}
   * @memberof AlertRefDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof AlertRefDto
   */
  source_id: number
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  date: string
  /**
   *
   * @type {number}
   * @memberof AlertRefDto
   */
  criticity: number
  /**
   *
   * @type {boolean}
   * @memberof AlertRefDto
   */
  shape: boolean
  /**
   *
   * @type {boolean}
   * @memberof AlertRefDto
   */
  anomaly: boolean
  /**
   *
   * @type {AlertActivityEnum}
   * @memberof AlertRefDto
   */
  activity: AlertActivityEnum
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  comment: string | null
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  betterModelName: string | null
  /**
   *
   * @type {number}
   * @memberof AlertRefDto
   */
  betterModelValue: number | null
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  betterModelColor: string | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  betterModelActivity: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  betterModelTopTolerance: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  betterModelBottomTolerance: Array<number> | null
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  closestModelName: string | null
  /**
   *
   * @type {number}
   * @memberof AlertRefDto
   */
  closestModelValue: number | null
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  closestModelColor: string | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  closestModelActivity: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  closestModelTopTolerance: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  closestModelBottomTolerance: Array<number> | null
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  dayModelName: string | null
  /**
   *
   * @type {number}
   * @memberof AlertRefDto
   */
  dayModelValue: number | null
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  dayModelColor: string | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  dayModelActivity: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  dayModelTopTolerance: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  dayModelBottomTolerance: Array<number> | null
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  dayPrevisionModelName: string | null
  /**
   *
   * @type {number}
   * @memberof AlertRefDto
   */
  dayPrevisionModelValue: number | null
  /**
   *
   * @type {string}
   * @memberof AlertRefDto
   */
  dayPrevisionModelColor: string | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  dayPrevisionModelActivity: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  dayPrevisionModelTopTolerance: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof AlertRefDto
   */
  dayPrevisionModelBottomTolerance: Array<number> | null
}

export function AlertRefDtoFromJSON(json: any): AlertRefDto {
  return AlertRefDtoFromJSONTyped(json, false)
}

export function AlertRefDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRefDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    source_id: json["source_id"],
    date: json["date"],
    criticity: json["criticity"],
    shape: json["shape"],
    anomaly: json["anomaly"],
    activity: AlertActivityEnumFromJSON(json["activity"]),
    comment: json["comment"],
    betterModelName: json["betterModelName"],
    betterModelValue: json["betterModelValue"],
    betterModelColor: json["betterModelColor"],
    betterModelActivity: json["betterModelActivity"],
    betterModelTopTolerance: json["betterModelTopTolerance"],
    betterModelBottomTolerance: json["betterModelBottomTolerance"],
    closestModelName: json["closestModelName"],
    closestModelValue: json["closestModelValue"],
    closestModelColor: json["closestModelColor"],
    closestModelActivity: json["closestModelActivity"],
    closestModelTopTolerance: json["closestModelTopTolerance"],
    closestModelBottomTolerance: json["closestModelBottomTolerance"],
    dayModelName: json["dayModelName"],
    dayModelValue: json["dayModelValue"],
    dayModelColor: json["dayModelColor"],
    dayModelActivity: json["dayModelActivity"],
    dayModelTopTolerance: json["dayModelTopTolerance"],
    dayModelBottomTolerance: json["dayModelBottomTolerance"],
    dayPrevisionModelName: json["dayPrevisionModelName"],
    dayPrevisionModelValue: json["dayPrevisionModelValue"],
    dayPrevisionModelColor: json["dayPrevisionModelColor"],
    dayPrevisionModelActivity: json["dayPrevisionModelActivity"],
    dayPrevisionModelTopTolerance: json["dayPrevisionModelTopTolerance"],
    dayPrevisionModelBottomTolerance: json["dayPrevisionModelBottomTolerance"],
  }
}

export function AlertRefDtoToJSON(value?: AlertRefDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    source_id: value.source_id,
    date: value.date,
    criticity: value.criticity,
    shape: value.shape,
    anomaly: value.anomaly,
    activity: AlertActivityEnumToJSON(value.activity),
    comment: value.comment,
    betterModelName: value.betterModelName,
    betterModelValue: value.betterModelValue,
    betterModelColor: value.betterModelColor,
    betterModelActivity: value.betterModelActivity,
    betterModelTopTolerance: value.betterModelTopTolerance,
    betterModelBottomTolerance: value.betterModelBottomTolerance,
    closestModelName: value.closestModelName,
    closestModelValue: value.closestModelValue,
    closestModelColor: value.closestModelColor,
    closestModelActivity: value.closestModelActivity,
    closestModelTopTolerance: value.closestModelTopTolerance,
    closestModelBottomTolerance: value.closestModelBottomTolerance,
    dayModelName: value.dayModelName,
    dayModelValue: value.dayModelValue,
    dayModelColor: value.dayModelColor,
    dayModelActivity: value.dayModelActivity,
    dayModelTopTolerance: value.dayModelTopTolerance,
    dayModelBottomTolerance: value.dayModelBottomTolerance,
    dayPrevisionModelName: value.dayPrevisionModelName,
    dayPrevisionModelValue: value.dayPrevisionModelValue,
    dayPrevisionModelColor: value.dayPrevisionModelColor,
    dayPrevisionModelActivity: value.dayPrevisionModelActivity,
    dayPrevisionModelTopTolerance: value.dayPrevisionModelTopTolerance,
    dayPrevisionModelBottomTolerance: value.dayPrevisionModelBottomTolerance,
  }
}
