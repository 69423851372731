import { ModelDto } from "api/timelight-api"
import GridHighlight from "component/GridHighlight"
import * as lodash from "lodash"
import React from "react"
import { createIdMap } from "../../lib/array"
import { ExportConfig } from "../MaterialTable"
import { Column, createTextEnumColumnConfig } from "../MaterialTable/material-table-helpers"

export function createModelCellConfig<T extends object>({
  models,
  getModelId,
}: {
  models: ModelDto[]
  getModelId: (row: T) => number | null | undefined
}): Column<T> & Partial<ExportConfig<T>> {
  const modelsById = createIdMap(models)
  return createTextEnumColumnConfig({
    title: "Profil type",
    render: (row) => {
      const modelId = getModelId(row)
      return modelId ? (
        <GridHighlight color={modelsById[modelId].color}>{modelsById[modelId].name}</GridHighlight>
      ) : (
        <></>
      )
    },
    getTextValue: (row) => {
      const modelId = getModelId(row)
      return modelId ? modelsById[modelId].name : ""
    },
    enumValues: lodash.orderBy(
      models.map((m) => m.name),
      [(n) => n.toLocaleLowerCase()],
    ),
  })
}
