import { useReducer, useEffect, Reducer } from "react"
import isEqual from "react-fast-compare"
// https://levelup.gitconnected.com/performing-async-actions-using-hooks-e4da47293d8e

export type AsyncHook<T, P = {}> = [
  // data
  { loading: boolean; error: Response | null; data: T | null },
  // params setter
  (params: P) => void,
  // data setter
  (data: T | null) => void,
]

export type AsyncHookParams<T> = T & { shouldTrigger: boolean; throwErrors?: boolean }

interface AsyncHookState<T, P> {
  isLoading: boolean
  error: Response | null
  data: T | null
  params: AsyncHookParams<P>
}
type AsyncHookAction<T, P> =
  | { type: "FETCH_INIT"; payload: P }
  | { type: "FETCH_SUCCESS"; payload: T | null }
  | { type: "FETCH_FAILURE"; payload: Response }

function dataFetchReducer<T, P>(state: AsyncHookState<T, P>, action: AsyncHookAction<T, P>) {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        error: null,
        params: action.payload,
      }
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload,
      }
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    default:
      throw new Error()
  }
}

export function useAsyncAction<T, P extends AsyncHookParams<{}>>(
  action: (params: P) => Promise<T>,
  initialParams: P,
): AsyncHook<T, P> {
  const [state, dispatch] = useReducer<Reducer<AsyncHookState<T, P>, AsyncHookAction<T, P>>>(dataFetchReducer, {
    isLoading: false,
    error: null,
    data: null,
    params: initialParams,
  })

  // effect for params change
  useEffect(() => {
    let didCancel = false

    async function fetchData() {
      try {
        const result = await action(state.params)

        if (!didCancel) {
          dispatch({ type: "FETCH_SUCCESS", payload: result })
        }
      } catch (error) {
        if (!didCancel) {
          console.error(error)
          dispatch({ type: "FETCH_FAILURE", payload: error })
          if (state.params.throwErrors === true) {
            throw error
          }
        }
      }
    }
    if (state.params.shouldTrigger) {
      // tslint:disable-next-line:no-floating-promises
      fetchData()
    }
    return () => {
      didCancel = true
    }

    // eslint-disable-next-line
  }, Object.values(state.params))

  if (!isEqual(state.params, initialParams)) {
    dispatch({ type: "FETCH_INIT", payload: initialParams })
  }

  return [
    { loading: state.isLoading, data: state.data, error: state.error },
    (p: P) => dispatch({ type: "FETCH_INIT", payload: p }),
    (d: T | null) => dispatch({ type: "FETCH_SUCCESS", payload: d }),
  ]
}
