import { Typography, Divider, Checkbox, FormHelperText } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import PageBlock from "component/PageBlock"
import { SourcesGmaps } from "component/SourcesGmap"
import { Formik } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Prompt } from "react-router"
import { SourcePatchDto } from "api/timelight-api"
import AppHeader from "../../component/AppHeader"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import SourceSelect from "../../component/SourceSelect"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { useApiClient, useSourceTagsList } from "../../state/api"
import { useApiExceptionHandler } from "../../state/auth"
import { useSourceSelect, clearSourceIdSelectCache } from "../../state/persisted"
import { PlacesAutocompleteInput } from "../../component/PlacesAutocompleteInput"
import ListSelect from "component/ListSelect"
import { getCurrentYear } from "../../lib/date"
import { SourceDto } from "../../api/timelight-api/models/SourceDto"
import { FormControlLabel } from "@material-ui/core"
import { PremiumFeature } from "../../component/PremiumFeature"
import { AppRoute } from "../../state/route"
import { ButtonWithConfirmDialog } from "../../component/ButtonWithConfirmDialog"
import { AppButton } from "../../component/AppButton"
import SelectCreatable from "react-select/creatable"
import { useOnboardingState } from "../../state/onboarding"

type FormType<T> = {
  [P in keyof T]: T[P] | undefined
}

function MeteoLoaderForm({ source }: { source: SourceDto }) {
  const sourceYears = []
  for (let y = source.referenceYear; y <= getCurrentYear() && y < 2020; y++) {
    sourceYears.push(y)
  }

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  interface MeteoItem {
    label: string
    value: number
  }
  const ALL_YEARS_CONST = 0
  const meteoImportOptions: MeteoItem[] = sourceYears
    .map((y) => ({ label: `Année ${y}`, value: y }))
    .concat([
      { label: `Toutes les années de ${t("source.aname")} (${sourceYears.join(", ")})`, value: ALL_YEARS_CONST },
    ])
  const [meteoYear, setMeteoYear] = useState<number>(0)

  const api = useApiClient()
  const handleApiException = useApiExceptionHandler()
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: `Données météo de ${t("source.thename")} mis à jour`,
  })

  async function loadMeteoData() {
    setLoading(true)
    try {
      await api.dayContextControllerLoadMeteo({
        sourceId: source.id,
        year: meteoYear === ALL_YEARS_CONST ? undefined : meteoYear,
      })
      showSuccessMessage()
    } catch (e) {
      handleApiException(e)
    } finally {
      setLoading(false)
    }
  }

  const value = meteoImportOptions.find((i) => i.value === meteoYear)
  return (
    <Grid container direction="column">
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <SuccessMessage />
        <ListSelect<MeteoItem>
          value={value}
          items={meteoImportOptions}
          getLabel={(i) => i.label}
          getValue={(i) => i.value}
          onSelect={(i) => setMeteoYear(i.value)}
          disabled={loading}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} style={{ marginTop: "1em" }}>
        <AppButton onClick={loadMeteoData} color="primary" variant="contained" submitting={loading}>
          Importer les données météo
        </AppButton>
      </Grid>
    </Grid>
  )
}

export default function SourceEdit() {
  // the app bar for values
  const [source, setSourceId] = useSourceSelect()
  const { t } = useTranslation()
  const { sourceRemoved } = useOnboardingState()

  const [{ data: sourceTags }] = useSourceTagsList({ shouldTrigger: true, limit: 1000 })

  // prepare some api calls
  const api = useApiClient()
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: `${t("source.Name")} mis à jour`,
  })

  async function submitSourceData(values: FormType<SourcePatchDto>) {
    try {
      const newSource = await api.sourceControllerSourceUpdate({
        sourcePatchDto: (values as unknown) as SourcePatchDto,
        sourceId: source.id,
      })
      setSourceId(newSource.id)

      // oh yeah
      showSuccessMessage()
    } catch (e) {
      // ts-lint:disable-next-line:no-console
      console.error(e)
    }
  }

  return (
    <PageContainer title={`Configuration ${t("source.aname")} > ${source.name}`}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Configuration {t("source.aname")}
            <SourceSelect sourceId={source.id} setSourceId={setSourceId} />
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <Grid container direction="column">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <PageBlock title={`Configuration ${t("source.aname")}`}>
              <SuccessMessage />
              <Formik<FormType<SourcePatchDto>>
                key={source.id + "-data"}
                enableReinitialize={true}
                initialValues={{
                  name: source.name,
                  previsionEnabled: undefined,
                  alertMinCriticity: source.alertMinCriticity || undefined,
                  alertMaxCriticity: source.alertMaxCriticity || undefined,
                  address: undefined,
                  latitude: undefined,
                  longitude: undefined,
                  syncMeteo: undefined,
                  tags: (source.tags || []).map((tt) => tt.name),
                }}
                onSubmit={submitSourceData}
              >
                {({
                  values,
                  errors,
                  touched,
                  dirty,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Prompt
                        when={dirty}
                        message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                      />

                      <Grid container direction="column">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            style={{ width: "100%" }}
                            label={`Nom ${t("source.aname")}`}
                            variant="outlined"
                            value={values.name}
                            margin="normal"
                            required
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name && errors.name}
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            type="number"
                            style={{ width: "100%" }}
                            label={`Criticité minimum`}
                            variant="outlined"
                            value={values.alertMinCriticity}
                            margin="normal"
                            required={false}
                            name="alertMinCriticity"
                            onChange={(e) =>
                              setFieldValue(
                                "alertMinCriticity",
                                (e.target.value + "").length > 0 ? parseFloat(e.target.value) : null,
                              )
                            }
                            onBlur={handleBlur}
                          />
                          {errors.alertMinCriticity && touched.alertMinCriticity && errors.alertMinCriticity}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            type="number"
                            style={{ width: "100%" }}
                            label={`Criticité maximum`}
                            variant="outlined"
                            value={values.alertMaxCriticity}
                            margin="normal"
                            required={false}
                            name="alertMaxCriticity"
                            onChange={(e) =>
                              setFieldValue(
                                "alertMaxCriticity",
                                (e.target.value + "").length > 0 ? parseFloat(e.target.value) : null,
                              )
                            }
                            onBlur={handleBlur}
                          />
                          {errors.alertMaxCriticity && touched.alertMaxCriticity && errors.alertMaxCriticity}
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <div style={{ margin: "1em 0" }}>
                            <SelectCreatable
                              placeholder="Tags"
                              value={(values.tags || []).map((tt) => ({ value: tt, label: tt }))}
                              isMulti
                              isClearable
                              options={
                                sourceTags ? sourceTags.data.map((tt) => ({ value: tt.name, label: tt.name })) : []
                              }
                              onChange={(val) => {
                                if (!val) {
                                  val = []
                                }
                                if (!Array.isArray(val)) {
                                  throw new Error("Multi-value expected")
                                }
                                setFieldValue(
                                  "tags",
                                  val.map((v) => v.value),
                                )
                              }}
                              name="tags"
                            />
                            {errors.tags && touched.tags && errors.tags}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}>
                      <AppButton type="submit" variant="contained" color="primary" submitting={isSubmitting}>
                        Sauvegarder
                      </AppButton>
                    </div>
                  </form>
                )}
              </Formik>
            </PageBlock>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <PageBlock title="Synchronisation automatique des données météo">
              <Formik<FormType<SourcePatchDto>>
                key={source.id + "-meteo"}
                enableReinitialize={true}
                initialValues={{
                  name: undefined,
                  alertMaxCriticity: undefined,
                  alertMinCriticity: undefined,
                  previsionEnabled: undefined,
                  syncMeteo: source.syncMeteo,
                  latitude: source.latitude || undefined,
                  longitude: source.longitude || undefined,
                  address: source.address || undefined,
                  tags: undefined,
                }}
                onSubmit={submitSourceData}
              >
                {({ values, errors, touched, dirty, setFieldValue, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Prompt
                        when={dirty}
                        message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                      />

                      <Grid container direction="column">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <PlacesAutocompleteInput
                            initialValue={values.address || ""}
                            label={`Adresse ${t("source.aname")}`}
                            required={false}
                            onSelect={(place) => {
                              if (place && place.geometry) {
                                setFieldValue("address", place.formatted_address)
                                setFieldValue("latitude", place.geometry.location.lat())
                                setFieldValue("longitude", place.geometry.location.lng())
                              }
                            }}
                          />
                          {errors.address && touched.address && errors.address}
                        </Grid>
                        {values.latitude && values.longitude && (
                          <Grid item lg={12} md={12} sm={12} xs={12} style={{ height: "300px" }}>
                            <SourcesGmaps sources={[values]} onSourceClick={() => {}} onClick={() => {}} />
                          </Grid>
                        )}

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Divider />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.syncMeteo}
                                onChange={(e) => setFieldValue("syncMeteo", e.target.checked)}
                                value="syncMeteo"
                                color="primary"
                                disabled={!values.longitude || !values.latitude}
                              />
                            }
                            label="Synchronisation des données historiques"
                          />
                          <FormHelperText>
                            Cocher cette case pour activer la récupération des données météo pour les données
                            historiques
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Divider />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={false}
                                onChange={() => {}}
                                value="syncMeteoForecast"
                                color="primary"
                                disabled={true}
                              />
                            }
                            label={
                              <span>
                                <PremiumFeature /> Synchronisation des prévisions météo
                              </span>
                            }
                          />
                          <FormHelperText>
                            Cocher cette case pour activer la récupération des prévisions météo
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}>
                      <AppButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        submitting={isSubmitting}
                        disabled={!values.longitude || !values.latitude}
                      >
                        Sauvegarder
                      </AppButton>
                      {(!values.longitude || !values.latitude) && (
                        <FormHelperText error={true}>
                          Pour synchroniser les données météo, nous devons connaître la localisation de la source
                        </FormHelperText>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </PageBlock>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <PageBlock title="Import de données météo">
              <MeteoLoaderForm source={source} />
            </PageBlock>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            {source && (
              <PageBlock alert={true} title="Zone dangereuse">
                <ButtonWithConfirmDialog
                  buttonText={<>Supprimer {t("source.thename")}</>}
                  popinButtonText={
                    <>
                      Supprimer définitivement {t("source.thename")} {source.name}
                    </>
                  }
                  popinTitle={<>Supprimer {source.name} ?</>}
                  popinWarning={
                    <>
                      Cette action est irréversible, vous perdrez toutes les données liées à {t("source.thisname")}, y
                      compris les alertes traitées.
                    </>
                  }
                  successMessage={`${t("source.Name")} supprimée définitivement`}
                  redirectToAfterAction={{ route: AppRoute.DATA_SOURCE_LIST }}
                  doAction={async () => {
                    await api.sourceControllerDeleteSource({ sourceId: source.id })
                    clearSourceIdSelectCache()
                    sourceRemoved()
                  }}
                />
              </PageBlock>
            )}
          </Grid>
        </Grid>
      </PageContent>
    </PageContainer>
  )
}
