/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayContextTypes, DayContextTypesFromJSON, DayContextTypesFromJSONTyped, DayContextTypesToJSON } from "./"

/**
 *
 * @export
 * @interface ContextTypeDateDomainDto
 */
export interface ContextTypeDateDomainDto {
  /**
   *
   * @type {Date}
   * @memberof ContextTypeDateDomainDto
   */
  beginDate: Date
  /**
   *
   * @type {Date}
   * @memberof ContextTypeDateDomainDto
   */
  endDate: Date
  /**
   *
   * @type {DayContextTypes}
   * @memberof ContextTypeDateDomainDto
   */
  ctxType: DayContextTypes
}

export function ContextTypeDateDomainDtoFromJSON(json: any): ContextTypeDateDomainDto {
  return ContextTypeDateDomainDtoFromJSONTyped(json, false)
}

export function ContextTypeDateDomainDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContextTypeDateDomainDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    beginDate: new Date(json["beginDate"]),
    endDate: new Date(json["endDate"]),
    ctxType: DayContextTypesFromJSON(json["ctxType"]),
  }
}

export function ContextTypeDateDomainDtoToJSON(value?: ContextTypeDateDomainDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    beginDate: value.beginDate.toISOString(),
    endDate: value.endDate.toISOString(),
    ctxType: DayContextTypesToJSON(value.ctxType),
  }
}
