import React from "react"
import { dayNumberInYear, toDateObj } from "../lib/date"
import PieStats from "./PieStat"

export default function AlertRatePieStats({
  alertCount,
  handledAlertCount,
  maxDate,
}: {
  alertCount: number
  handledAlertCount: number
  maxDate: string
}) {
  const elapsed = dayNumberInYear(toDateObj(maxDate))

  return (
    <PieStats
      total={elapsed}
      totalTitle="Jours sans alerte"
      count={alertCount + handledAlertCount}
      countTitle="Jours en alerte"
      noDataTitle="Pas de données"
      color="#00C49F"
    />
  )
}
