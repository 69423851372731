// tslint:disable:max-line-length
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import * as lodash from "lodash"
import moment from "moment"
import React from "react"
import { createStrIdMap } from "../../lib/array"
import { hexToRGBA, isDarkColor } from "../../lib/color"
import { allDaysInYear } from "../../lib/date"
import { CalendarData, InternalCalendarProps } from "./CalendarData"
import GridYearCalendar from "./GridYearCalendar"
import { getActivityColor } from "./util"

const useStyles = makeStyles({
  yearCalendar: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1em",
  },
})

export default function DayGridYearCalendar({
  days,
  models,
  year,
  setSelectedDay,
  content,
  activityColor,
}: InternalCalendarProps) {
  const classes = useStyles()

  const onPickDate = (d: moment.Moment) => {
    const dayStr = d.format("YYYY-MM-DD")
    const selected = days.find((day) => day.date === dayStr)
    if (selected) {
      setSelectedDay(selected)
    }
  }

  const dayIndex = createStrIdMap<CalendarData>(days, (d) => d.date)
  const minValue = lodash.min(days.filter((d) => !d.empty).map((d) => d.value)) || 0
  const maxValue = lodash.max(days.filter((d) => !d.empty).map((d) => d.value)) || 200

  return (
    <div className={clsx(classes.yearCalendar, "grid-year-calendar")}>
      <style>
        {`

          .grid-year-calendar .calendar td.selected {
            background-color: unset;
          }
          .grid-year-calendar .calendar .day-anomaly .day-number {
            border: 1px red solid;
            color: black;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=);
          }
          .grid-year-calendar .calendar .day-hashed .day-number {
            color: #CCC;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=);
            cursor: default;
          }
          .grid-year-calendar .calendar td.day-hashed:hover .day-number {
              background-color: inherit;
          }
          .grid-year-calendar .calendar .day-transparent .day-number {
            opacity: 0.4;
          }

          .grid-year-calendar .calendar .day-empty .day-number {
            color: #CCC;
          }
        `}
      </style>

      {content === "model" && (
        <>
          <style>
            {models.map(
              (m) =>
                `
                .grid-year-calendar .calendar .day-model-${m.id} .day-number {
                  background-color: ${hexToRGBA(m.color, 0.75)};
                  color: ${isDarkColor(m.color) ? "white" : "black"};
                }
                .grid-year-calendar .calendar .day-alert-no-model .day-number {
                  background-color: white;
                  color: red;
                  font-size: bold;
                }
                `,
            )}
          </style>
          <GridYearCalendar
            year={year}
            onPickDate={onPickDate}
            customClasses={models.reduce(
              (agg, m) =>
                Object.assign(agg, {
                  [`day-model-${m.id}`]: days.filter((d) => d.model_id === m.id && !d.anomaly).map((d) => d.date),
                }),
              {
                "day-alert-no-model": days
                  .filter((d) => (d.model_id === undefined || d.model_id === 0) && !d.anomaly && !d.empty)
                  .map((d) => d.date),
                "day-anomaly": days.filter((d) => d.anomaly).map((d) => d.date),
                "day-hashed": allDaysInYear(year).filter((date) => !days.some((d) => d.date === date)),
                "day-transparent": days.filter((d) => d.transparent).map((d) => d.date),
                "day-empty": days.filter((d) => d.empty).map((d) => d.date),
              },
            )}
          />
        </>
      )}

      {content === "activity" && (
        <>
          <style>
            {lodash.uniq(days.map((d) => getActivityColor(minValue, d.value, maxValue, activityColor))).map((color) => {
              const className = `day-activity-${color.replace("#", "")}`
              return `
                  .calendar .${className} .day-number {
                    background-color: ${hexToRGBA(color, 0.75)};
                    color: ${isDarkColor(color) ? "white" : "black"};
                  }
              `
            })}
          </style>
          <GridYearCalendar
            year={year}
            onPickDate={onPickDate}
            customClasses={(d) => {
              const calData = dayIndex[d.format("YYYY-MM-DD")]
              const classNames: string[] = []
              if (calData) {
                const color = getActivityColor(minValue, calData.value, maxValue, activityColor)
                const className = `day-activity-${color.replace("#", "")}`
                classNames.push(calData.anomaly ? `day-anomaly` : calData.empty ? `day-empty` : className)
                if (calData.transparent) {
                  classNames.push("day-transparent")
                }
              } else {
                // no data to show -> hash
                classNames.push("day-hashed")
              }
              return classNames.join(" ")
            }}
          />
        </>
      )}
    </div>
  )
}
