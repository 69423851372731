/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SourceTag
 */
export interface SourceTag {
  /**
   *
   * @type {string}
   * @memberof SourceTag
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof SourceTag
   */
  companyId: number
}

export function SourceTagFromJSON(json: any): SourceTag {
  return SourceTagFromJSONTyped(json, false)
}

export function SourceTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceTag {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json["name"],
    companyId: json["companyId"],
  }
}

export function SourceTagToJSON(value?: SourceTag | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    companyId: value.companyId,
  }
}
