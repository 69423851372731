/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  DayContextDaySummary,
  DayContextDaySummaryFromJSON,
  DayContextDaySummaryFromJSONTyped,
  DayContextDaySummaryToJSON,
  DayContextSummary,
  DayContextSummaryFromJSON,
  DayContextSummaryFromJSONTyped,
  DayContextSummaryToJSON,
} from "./"

/**
 *
 * @export
 * @interface DayContextSummaryYearDto
 */
export interface DayContextSummaryYearDto {
  /**
   * List of day context summary
   * @type {Array<DayContextDaySummary>}
   * @memberof DayContextSummaryYearDto
   */
  daySummaries: Array<DayContextDaySummary>
  /**
   * List of day context summary
   * @type {Array<DayContextSummary>}
   * @memberof DayContextSummaryYearDto
   */
  yearSummaries: Array<DayContextSummary>
}

export function DayContextSummaryYearDtoFromJSON(json: any): DayContextSummaryYearDto {
  return DayContextSummaryYearDtoFromJSONTyped(json, false)
}

export function DayContextSummaryYearDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DayContextSummaryYearDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    daySummaries: (json["daySummaries"] as Array<any>).map(DayContextDaySummaryFromJSON),
    yearSummaries: (json["yearSummaries"] as Array<any>).map(DayContextSummaryFromJSON),
  }
}

export function DayContextSummaryYearDtoToJSON(value?: DayContextSummaryYearDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    daySummaries: (value.daySummaries as Array<any>).map(DayContextDaySummaryToJSON),
    yearSummaries: (value.yearSummaries as Array<any>).map(DayContextSummaryToJSON),
  }
}
