import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import ActivityHeatmap from "component/ActivityHeatmap"
import AppLink from "component/AppLink"
import { MaterialTable } from "component/MaterialTable"
import PageBlock from "component/PageBlock"
import { createIdMap } from "lib/array"
import * as lodash from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { AppRoute } from "state/route"
import { AlertListDto, ModelListDto, SourceDto } from "api/timelight-api"
import { AlertDto } from "api/timelight-api"
import AlertActivity from "../../component/AlertActivity"
import AlertAnomaly from "../../component/AlertAnomaly"
import AlertBetterModel from "../../component/AlertBetterModel"
import AlertRatePieStats from "../../component/AlertRatePieStats"
import AlertShape from "../../component/AlertShape"
import AppHeader from "../../component/AppHeader"
import { LinkCss } from "../../component/AppLink"
import ChartDayActivity from "../../component/ChartDayActivity"
import DaysNearDateModal from "../../component/DaysNearDateModal"
import { getValueAfterTrends } from "../../component/DayTrendForm"
import AlertYearCalendar from "../../component/DayYearCalendar/AlertYearCalendar"
import HandledAlertPieStats from "../../component/HandledAlertPieStats"
import {
  createDateColumnConfig,
  createNumericColumnConfig,
  createTextEnumColumnConfig,
  extractColumnConfigAndExportConfig,
} from "../../component/MaterialTable/material-table-helpers"
import { createTableExportCsv } from "../../component/MaterialTable/material-table-helpers"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import SourceSelect from "../../component/SourceSelect"
import YearPieStats from "../../component/YearPieStats"
import YearSelect from "../../component/YearSelect"
import { useConfig } from "../../config"
import { createStrIdMap } from "../../lib/array"
import { allDaysInYear, daysOfWeekList, formatDayOfWeek, getCurrentYear, toDateObj } from "../../lib/date"
import { useDayAndModelAndAlertAndAlertRefListAndDayTrends, useTemperatureContext } from "../../state/api"
import { useSourceSelect } from "../../state/persisted"
import { AlertActivityEnum } from "../../api/timelight-api/models/AlertActivityEnum"
import { AppButton } from "../../component/AppButton"
import {
  createChartDayDataFromApiInfos,
  createChartActivityLabelFromApiInfos,
} from "../../component/ChartDayActivity/utils"

export default function MonitorActivity() {
  const { t } = useTranslation()
  // the app bar for values
  const [source, setSourceId] = useSourceSelect()

  const [year, setYear] = useState<number>(getCurrentYear())

  // fetch some data
  const [{ data, loading }] = useDayAndModelAndAlertAndAlertRefListAndDayTrends({
    shouldTrigger: source.id !== -1,
    sourceId: source.id,
    year,
  })

  // fetch temperature data
  const temperatureContext = useTemperatureContext({
    sourceId: source.id,
    minDate: `${year}-01-01`,
    maxDate: `${year}-12-31`,
    shouldTrigger: source.id !== -1,
  })

  // state for our day modal
  const [selectedDate, setSelectedDate] = useState<string | null>(null)

  // have a quick kv store of models
  const modelsById = createIdMap(data ? data.models : [])
  const daysByDate = createStrIdMap(data ? data.days : [], (d) => d.date)
  const previsionsByDate = createStrIdMap(data ? data.previsions : [], (p) => p.date)
  const alertsByDate = createStrIdMap(data ? data.alerts : [], (a) => a.date)

  function openProperModal(day?: { date: string } | null) {
    setSelectedDate(day ? day.date : null)
  }

  // needed for warning messages
  const { API_URL } = useConfig()
  const hasData = data ? data.days.length > 0 : false

  const trends = lodash.sortBy(data?.dayTrends || [], "id")
  const historyDays = allDaysInYear(year)
    .filter((date) => daysByDate[date] || previsionsByDate[date])
    .map((date) => {
      const day = daysByDate[date]
      const prevision = previsionsByDate[date]
      const alert = alertsByDate[date]

      let value = 0
      let activity: number[] = []
      if (day) {
        value = day.value
        activity = day.activity
      } else if (prevision) {
        const previsionModel = modelsById[prevision.model_id]
        value = getValueAfterTrends(trends, date, previsionModel.value)
        activity = previsionModel.activity.map((v) => getValueAfterTrends(trends, date, v))
      }
      return {
        date,
        value,
        activity,
        model_id:
          alert && alert.better_model_id
            ? alert.better_model_id
            : day && day.model_id
            ? day.model_id
            : prevision
            ? prevision.model_id
            : 0,
        transparent: !day,
      }
    })

  return (
    <PageContainer title="Activité actuelle">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Activité récente du capteur
            <SourceSelect sourceId={source.id} setSourceId={setSourceId} />
            pour l'année
            <YearSelect
              year={year}
              setYear={(y) => setYear(y)}
              years={{ from: source.referenceYear + 1, to: getCurrentYear() }}
            />
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        {data && selectedDate && (
          <DaysNearDateModal
            open={!!selectedDate}
            onClose={openProperModal}
            sourceId={source.id}
            initialDate={selectedDate}
          />
        )}

        {data && !hasData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginBottom: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                maxWidth: 500,
              }}
            >
              <PageBlock alert={true} title="Aucune données récentes">
                <Typography style={{ marginBottom: 20 }}>
                  Aucune donnée récente n'a été chargée pour {t("source.thename")} {source.name}. Envoyez des données
                  récentes par api ou en csv pour y remédier
                </Typography>
                <a
                  className={LinkCss.link}
                  style={{
                    marginRight: "1em",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={API_URL}
                >
                  <AppButton color="primary" variant="outlined">
                    Documentation de l'API
                  </AppButton>
                </a>
                <AppLink route={AppRoute.SOURCE_ADD_DAYS_HUB}>
                  <AppButton color="primary" variant="contained">
                    Importer des données
                  </AppButton>
                </AppLink>
              </PageBlock>
            </div>
          </div>
        )}

        {data && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginBottom: "30px",
            }}
          >
            <YearPieStats
              daysWithDataCount={data.days.length}
              maxDate={lodash.max(data.days.map((d) => d.date)) || ""}
            />
            <HandledAlertPieStats alertCount={data.alerts.length} handledAlertCount={data.alertRefs.length} />
            <AlertRatePieStats
              alertCount={data.alerts.length}
              handledAlertCount={data.alertRefs.length}
              maxDate={lodash.max(data.days.map((d) => d.date)) || ""}
            />
          </div>
        )}

        <PageBlock loading={loading} title={`Activité sur l'année en cours`} height={400}>
          {data && (
            <ChartDayActivity
              activityLabel={createChartActivityLabelFromApiInfos({ days: data.days, previsions: data.previsions })}
              models={data.models}
              days={createChartDayDataFromApiInfos({
                days: historyDays,
                previsions: data.previsions,
                models: data.models,
                context: temperatureContext,
                year,
              })}
              onBarClick={(selected) => openProperModal(selected)}
            />
          )}
        </PageBlock>

        <PageBlock loading={loading}>
          {data && <ActivityHeatmap year={year} days={historyDays} label="Activité" />}
        </PageBlock>

        <PageBlock loading={loading} title={`Calendrier des alertes ${year}`}>
          {data && (
            <AlertYearCalendar
              year={year}
              models={data.models}
              alerts={data.alerts}
              alertRefs={data.alertRefs}
              days={data.days}
              setSelectedDay={openProperModal}
              setSelectedAlert={openProperModal}
              setSelectedAlertRef={openProperModal}
            />
          )}
        </PageBlock>

        <PageBlock loading={loading}>
          {data && source && <SourceAlertTable data={data} source={source} setSelectedAlert={openProperModal} />}
        </PageBlock>

        {!source.hasPrevision && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginBottom: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                maxWidth: 500,
              }}
            >
              <PageBlock warning={true} title="Capteur non planifiée">
                <Typography style={{ marginBottom: 20 }}>
                  Aucune planification n'a été validée pour {t("source.thename")} {source.name}. Rendez-vous dans
                  l'écran de Planification par capteur pour y remédier
                </Typography>
                <AppLink route={AppRoute.PREVISION_SOURCE}>
                  <AppButton color="primary" variant="outlined">
                    Planifier ce capteur
                  </AppButton>
                </AppLink>
              </PageBlock>
            </div>
          </div>
        )}
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on alert select
   this will empty the filters
 */
const SourceAlertTable = React.memo(function SourceAlertTableComponent({
  data,
  source,
  setSelectedAlert,
}: {
  source: SourceDto
  data: ModelListDto & AlertListDto
  setSelectedAlert: (alert: AlertDto | null) => void
}) {
  // have a quick kv store of models and sources
  const modelsById = createIdMap(data.models)
  const { t } = useTranslation()

  const { columns, exportConfig } = extractColumnConfigAndExportConfig<AlertDto>([
    createTextEnumColumnConfig({
      title: "Jour",
      getTextValue: (row) => formatDayOfWeek(row.date),
      enumValues: daysOfWeekList,
      sortEnum: false,
    }),
    createDateColumnConfig({
      title: "Date",
      getDate: (row) => toDateObj(row.date),
      domain: data.alerts.map((row) => toDateObj(row.date)),
    }),
    createNumericColumnConfig({
      title: "Criticité",
      getValue: (row) => row.criticity,
    }),
    createTextEnumColumnConfig({
      title: "Anomalie",
      getTextValue: (row) => (row.anomaly ? "Anomalie" : "Pas d'anomalie"),
      enumValues: ["Anomalie", "Pas d'anomalie"],
      render: (row) => <AlertAnomaly alert={row} />,
    }),
    createTextEnumColumnConfig({
      title: "Forme",
      getTextValue: (row) => (row.shape ? "Écart de forme" : "Pas d'écart de forme"),
      enumValues: ["Écart de forme", "Pas d'écart de forme"],
      render: (row) => <AlertShape alert={row} />,
    }),
    createTextEnumColumnConfig({
      title: "Activité",
      getTextValue: (row) =>
        row.activity === AlertActivityEnum.Over
          ? "Sur-activité"
          : row.activity === AlertActivityEnum.Under
          ? "Sous-activité"
          : "Pas de sur-activité, pas de sous-activité",
      enumValues: ["Sur-activité", "Sous-activité", "Pas de sur-activité, pas de sous-activité"],
      render: (row) => <AlertActivity alert={row} />,
    }),
    createTextEnumColumnConfig({
      title: "Meilleur profil type détecté",
      getTextValue: (row) =>
        row.better_model_id ? modelsById[row.better_model_id].name : "Pas de meilleur profil type",
      enumValues: data.models
        .map((m) => m.name)
        .sort()
        .concat("Pas de meilleur profil type"),
      render: (row) => (
        <AlertBetterModel
          alert={row}
          getModelColor={(a) => (a.better_model_id ? modelsById[a.better_model_id].color : null)}
          getModelName={(a) => (a.better_model_id ? modelsById[a.better_model_id].name : null)}
        />
      ),
    }),
  ])

  return (
    <>
      <MaterialTable
        title={`Alertes à traiter sur ${t("source.thename")} ${source.name}`}
        data={data.alerts}
        onRowClick={setSelectedAlert}
        columns={columns}
        options={{
          pageSize: 10,
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `alerts.csv`,
          }),
        }}
      />
    </>
  )
})
