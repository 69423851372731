/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SourceImportHistory
 */
export interface SourceImportHistory {
  /**
   *
   * @type {number}
   * @memberof SourceImportHistory
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof SourceImportHistory
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof SourceImportHistory
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof SourceImportHistory
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof SourceImportHistory
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof SourceImportHistory
   */
  readonly companyId: number
  /**
   *
   * @type {number}
   * @memberof SourceImportHistory
   */
  sourceId: number
  /**
   *
   * @type {Date}
   * @memberof SourceImportHistory
   */
  execStartDate: Date
  /**
   *
   * @type {Date}
   * @memberof SourceImportHistory
   */
  execEndDate: Date
  /**
   *
   * @type {Date}
   * @memberof SourceImportHistory
   */
  dataStartDate: Date
  /**
   *
   * @type {Date}
   * @memberof SourceImportHistory
   */
  dataEndDate: Date
  /**
   *
   * @type {number}
   * @memberof SourceImportHistory
   */
  dataPointCount: number
}

export function SourceImportHistoryFromJSON(json: any): SourceImportHistory {
  return SourceImportHistoryFromJSONTyped(json, false)
}

export function SourceImportHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceImportHistory {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    sourceId: json["sourceId"],
    execStartDate: new Date(json["execStartDate"]),
    execEndDate: new Date(json["execEndDate"]),
    dataStartDate: new Date(json["dataStartDate"]),
    dataEndDate: new Date(json["dataEndDate"]),
    dataPointCount: json["dataPointCount"],
  }
}

export function SourceImportHistoryToJSON(value?: SourceImportHistory | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    execStartDate: value.execStartDate.toISOString(),
    execEndDate: value.execEndDate.toISOString(),
    dataStartDate: value.dataStartDate.toISOString(),
    dataEndDate: value.dataEndDate.toISOString(),
    dataPointCount: value.dataPointCount,
  }
}
