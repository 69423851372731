import moment from "moment"
export function toDateObj(date: string | Date | number): Date {
  if (date instanceof Date) {
    return date
  }
  if (typeof date === "number") {
    return new Date(date)
  }
  if (/\d{4}-\d{2}-\d{2}/.test(date)) {
    return new Date(date + "T00:00:00")
  }
  return new Date(NaN)
}

export function formatDate(date: string | Date) {
  const d = toDateObj(date)
  return (
    d.getDate().toString().padStart(2, "0") +
    "/" +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    d.getFullYear()
  )
}

export function formatTime(date: string | Date) {
  const d = toDateObj(date)
  return (
    d.getHours().toString().padStart(2, "0") +
    ":" +
    d.getMinutes().toString().padStart(2, "0") +
    ":" +
    d.getSeconds().toString().padStart(2, "0")
  )
}

export function formatDateTime(date: string | Date) {
  return formatDate(date) + " " + formatTime(date)
}

export function dateObjToDayString(d: Date) {
  return (
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    d.getDate().toString().padStart(2, "0")
  )
}

export function dateObjToDateTimeString(d: Date) {
  return (
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    d.getDate().toString().padStart(2, "0") +
    "T" +
    d.getHours().toString().padStart(2, "0") +
    ":" +
    d.getMinutes().toString().padStart(2, "0") +
    ":" +
    d.getSeconds().toString().padStart(2, "0")
  )
}

export const daysOfWeekList = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

export function formatDayOfWeek(date: string) {
  // https://www.tutorialspoint.com/javascript/date_getday.htm
  // 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.
  const num = toDateObj(date).getDay()
  return ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][num]
}

export const monthList = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
]
export function formatMonth(date: string) {
  const num = toDateObj(date).getMonth()
  return monthList[num]
}

export function getCurrentYear(now: Date = new Date()) {
  return now.getFullYear()
}

export function daysCountInYear(year: number = getCurrentYear()): number {
  const isLeapYear = year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)
  return isLeapYear ? 366 : 365
}

export function dayNumberInYear(nowDate: Date = new Date()): number {
  const now = moment(nowDate) // todays date
  const end = moment(new Date(nowDate.getUTCFullYear(), 0, 1)) // another date
  const duration = moment.duration(now.diff(end))
  const days = duration.asDays()
  // round to avoid 0.95 issues
  // add 1 as "now" is expressed as a date starting at 00:00:00
  return Math.round(days) + 1
}

export function allDaysInYear(year: number = getCurrentYear()): string[] {
  const yearStr = year + ""
  return Array.from({ length: 370 }, (_, i) => i)
    .map((i) => dateObjToDayString(new Date(year, 0, i)))
    .filter((d) => d.substr(0, 4) === yearStr)
}

export function dayCountBetweenDates(d1: Date | string, d2: Date | string) {
  return Math.abs(Math.floor((toDateObj(d1).getTime() - toDateObj(d2).getTime()) / (1000 * 60 * 60 * 24)))
}

export function isFuturDate(d: Date | string | number, now: Date = new Date()) {
  return toDateObj(d).getTime() > now.getTime()
}

export function formatDayWithMoment(d: string, format: string = "YYYY-MM-DDTHH:mm:ss"): string {
  return moment(d, "YYYY-MM-DDTHH:mm:ss", true).format(format)
}

export function truncateDateToDay(d: Date | string | number): Date {
  return toDateObj(dateObjToDayString(toDateObj(d)))
}

export const dayMs = 24 * 60 * 60 * 1000

type Durations = "ms" | "s" | "m" | "h" | "d" | "w"
const durationMap: { [key in Durations]: number } = {
  ms: 1,
  s: 1000,
  m: 60 * 1000,
  h: 60 * 60 * 1000,
  d: 24 * 60 * 60 * 1000,
  w: 7 * 24 * 60 * 60 * 1000,
}

export function formatDuration(ms: number | null | undefined) {
  if (ms === null || ms === undefined) {
    return ""
  }
  const parts = []
  let rest = Math.abs(ms)
  if (rest === 0) {
    return "0ms"
  }
  for (const p of ["w", "d", "h", "m", "s", "ms"] as Durations[]) {
    const v = Math.floor(rest / durationMap[p])
    if (v > 0) {
      parts.push(v + p)
      rest = rest - v * durationMap[p]
    }
  }
  return parts.join(" ")
}
