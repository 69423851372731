/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  AlertRefResultDto,
  AlertRefResultDtoFromJSON,
  AlertRefResultDtoToJSON,
  DaysNearDateResultDto,
  DaysNearDateResultDtoFromJSON,
  DaysNearDateResultDtoToJSON,
} from "../models"

export interface ViewHelperControllerDaysNearDateRequest {
  sourceId: number
  dayDate: string
}

/**
 * no description
 */
export class ViewHelperApi extends runtime.BaseAPI {
  /**
   * Get the alert referential view data
   */
  async viewHelperControllerAlertRefRaw(): Promise<runtime.ApiResponse<AlertRefResultDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/view-helper/alerts-ref`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AlertRefResultDtoFromJSON(jsonValue))
  }

  /**
   * Get the alert referential view data
   */
  async viewHelperControllerAlertRef(): Promise<AlertRefResultDto> {
    const response = await this.viewHelperControllerAlertRefRaw()
    return await response.value()
  }

  /**
   * Get the alert view data
   */
  async viewHelperControllerAlertsRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/view-helper/alerts`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Get the alert view data
   */
  async viewHelperControllerAlerts(): Promise<void> {
    await this.viewHelperControllerAlertsRaw()
  }

  /**
   * Get the alert modal view data
   */
  async viewHelperControllerDaysNearDateRaw(
    requestParameters: ViewHelperControllerDaysNearDateRequest,
  ): Promise<runtime.ApiResponse<DaysNearDateResultDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling viewHelperControllerDaysNearDate.",
      )
    }

    if (requestParameters.dayDate === null || requestParameters.dayDate === undefined) {
      throw new runtime.RequiredError(
        "dayDate",
        "Required parameter requestParameters.dayDate was null or undefined when calling viewHelperControllerDaysNearDate.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/view-helper/days-near-date/{sourceId}/{dayDate}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"dayDate"}}`, encodeURIComponent(String(requestParameters.dayDate))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DaysNearDateResultDtoFromJSON(jsonValue))
  }

  /**
   * Get the alert modal view data
   */
  async viewHelperControllerDaysNearDate(
    requestParameters: ViewHelperControllerDaysNearDateRequest,
  ): Promise<DaysNearDateResultDto> {
    const response = await this.viewHelperControllerDaysNearDateRaw(requestParameters)
    return await response.value()
  }
}
