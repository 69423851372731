import React from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useSource, useSerieSyncTask, useApiClient } from "../../state/api"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import { useAppRoute, AppRoute } from "../../state/route"
import { formatDate, formatDuration } from "../../lib/date"
import * as Highcharts from "highcharts"
import highchartsMore from "highcharts/highcharts-more"
import { SeriesDataView } from "../../component/SeriesDataView"
import { formatNumber } from "../../lib/number"
import TaskStatusActivity from "../../component/TaskStatusActivity"
import { useTaskStream, isTaskEnded, isTaskSuccessfullyEnded } from "../../state/tasksStream"
import { TaskActionsBlock } from "../../component/TaskActionsBlock"
import { SerieChartSyncZoomProvider } from "../../component/SerieChart/serieChartContext"

highchartsMore(Highcharts)

export function SerieSyncTaskView() {
  const currentRoute = useAppRoute()
  const taskId = parseInt(currentRoute.params.taskId || "-1", 10)

  // fetch page data
  const [{ data: initSyncTask }] = useSerieSyncTask({ shouldTrigger: taskId > 0, syncTaskId: taskId })
  const task = useTaskStream(initSyncTask)
  const [{ data: refSource }] = useSource({
    shouldTrigger: !!task,
    sourceId: task ? task.refSourceId : -1,
  })
  const [{ data: toSyncSource }] = useSource({
    shouldTrigger: !!task,
    sourceId: task ? task.toSyncSourceId : -1,
  })
  const api = useApiClient()

  return (
    <PageContainer title={`Synchronisation de série ${"- " + (task?.title || "")}`}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Synchronisation de série{task && <>: {task.title}</>}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <PageBlock loading={!task} title="Configuration de la modélisation">
          {task && (
            <div>
              <dl>
                {refSource && (
                  <>
                    <dt>Source étudiée</dt>
                    <dd>
                      <strong>{refSource.name}</strong>
                    </dd>
                  </>
                )}
                {toSyncSource && (
                  <>
                    <dt>Source synchronisée</dt>
                    <dd>
                      <strong>{toSyncSource.name}</strong>
                    </dd>
                  </>
                )}

                <dt>Période de modélisation</dt>
                <dd>
                  <strong>
                    Du {formatDate(task.trainBegin)} au {formatDate(task.trainEnd)}
                  </strong>
                </dd>

                {isTaskSuccessfullyEnded(task) && (
                  <>
                    <dt>Indice de corrélation Pearson global</dt>
                    <dd>
                      <strong>{formatNumber(task.globalPearsonValue)}</strong>
                    </dd>

                    <dt>Décalage global</dt>
                    <dd>
                      <strong>{formatDuration(task.globalOffsetMs)}</strong>
                    </dd>
                  </>
                )}

                <dt>Status de la tâche</dt>
                <dd>
                  <TaskStatusActivity large={true} task={task} />
                </dd>
              </dl>
            </div>
          )}
        </PageBlock>

        {task && isTaskEnded(task) && task.error && (
          <PageBlock title="Erreur technique">
            <pre>{task.error}</pre>
          </PageBlock>
        )}

        <SerieChartSyncZoomProvider>
          {task && refSource && toSyncSource && (
            <PageBlock title="Sources de données">
              <SeriesDataView
                serieIds={[refSource.serieId, toSyncSource.serieId]}
                serieNames={["Source de référence : " + refSource.name, "Source à synchroniser : " + toSyncSource.name]}
                begin={task.trainBegin}
                end={task.trainEnd}
              />
            </PageBlock>
          )}

          {task && isTaskSuccessfullyEnded(task) && (
            <PageBlock title="Evolution de l'indice de Pearson">
              <SeriesDataView
                serieIds={[task.rollingPearsonSerieId]}
                serieNames={["Evolution de l'indice de Pearson"]}
                valueFormatter={(v) => formatNumber(v, false, undefined, 4)}
                begin={task.trainBegin}
                end={task.trainEnd}
              />
            </PageBlock>
          )}

          {task && isTaskSuccessfullyEnded(task) && refSource && toSyncSource && (
            <PageBlock title="Simulation après alignement">
              <SeriesDataView
                serieIds={[refSource.serieId, task.toSyncAlignedSerieId]}
                serieNames={[
                  "Source de référence : " + refSource.name,
                  "Simulation de synchronisation : " + toSyncSource.name,
                ]}
                begin={task.trainBegin}
                end={task.trainEnd}
              />
            </PageBlock>
          )}

          {task && isTaskSuccessfullyEnded(task) && (
            <PageBlock title="Evolution de l'indice de Pearson après alignement">
              <SeriesDataView
                serieIds={[task.rollingPearsonAlignedSerieId]}
                serieNames={["Evolution de l'indice de Pearson après alignement"]}
                valueFormatter={(v) => formatNumber(v, false, undefined, 4)}
                begin={task.trainBegin}
                end={task.trainEnd}
              />
            </PageBlock>
          )}

          {task && isTaskEnded(task) && (
            <TaskActionsBlock
              updateProps={{
                buttonText: <>Mettre à jour</>,
                popinButtonText: <>Supprimer définitivement les données du projet {task.title}</>,
                popinTitle: <>La mise à jour supprimera les données du projet {task.title} ?</>,
                popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce projet</>,
                redirectToAfterAction: { route: AppRoute.SERIE_SYNC_TASK_UPDATE, params: { taskId: task.id + "" } },
                doAction: async () => {},
              }}
              deleteProps={{
                buttonText: <>Supprimer le projet</>,
                popinButtonText: <>Supprimer définitivement le projet {task.title}</>,
                popinTitle: <>Supprimer le projet {task.title} ?</>,
                popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce projet</>,
                redirectToAfterAction: { route: AppRoute.SERIE_SYNC_LIST },
                successMessage: `Projet ${task.title} supprimé définitivement`,
                doAction: async () => {
                  await api.syncTaskControllerDeleteOne({ syncTaskId: task.id })
                },
              }}
              redirectToOnCopy={{ route: AppRoute.SERIE_SYNC_TASK_COPY, params: { taskId: task.id + "" } }}
            />
          )}
        </SerieChartSyncZoomProvider>
      </PageContent>
    </PageContainer>
  )
}
