import React from "react"
import { useApiClient } from "../state/api"
import * as Highcharts from "highcharts"
import highchartsMore from "highcharts/highcharts-more"
import { findMetricDataIdx } from "../lib/array"
import { formatNumber } from "lib/number"
import { SerieChart, SerieData } from "./SerieChart"
import { SerieBlockTimeStep } from "../api/timelight-api/models/SerieBlockTimeStep"
import { getMaxSerieResample } from "./SerieChart/SerieChart"
highchartsMore(Highcharts)

export function SeriesDataView({
  valueFormatter,
  serieNames,
  serieIds,
  begin,
  end,
}: {
  valueFormatter?: (v: number) => string
  serieNames: string[]
  serieIds: number[]
  begin: Date
  end: Date
}) {
  const formatValue: (v: number) => string = valueFormatter || ((v: number) => formatNumber(v))

  const api = useApiClient()
  const chartDomain = { begin, end }

  const getSeriesData = React.useMemo(() => {
    return async (minDate: Date, maxDate: Date, resample?: SerieBlockTimeStep): Promise<SerieData[]> => {
      const res: SerieData[] = []
      for (const serieId of serieIds) {
        const serie = await api.serieControllerGetOne({ serieId })
        const serieData = await api.serieDataControllerFetchDataCompact({
          begin: minDate,
          end: maxDate,
          serieId,
          resample: getMaxSerieResample([serie.blockTimeStep, resample]),
        })
        const activityMetricIdx = findMetricDataIdx(serieData.metrics, "activity")
        res.push(serieData.data.map((d) => [d[0], d[activityMetricIdx]]))
      }

      return res
    }
  }, [api, serieIds])

  return (
    <SerieChart
      key={serieIds.join("-")}
      chartDomain={chartDomain}
      getSeriesData={getSeriesData}
      seriesConfig={serieIds.map((_, i) => ({
        type: "line",
        name: serieNames[i],
        yAxis: i,
      }))}
      additionalHighchartOptions={{
        chart: { height: "400px" },
        legend: {
          enabled: true,
        },
        yAxis: [{} as any].concat(
          serieNames.map((serieName, i) => ({
            title: {
              text: serieName,
            },
            opposite: i % 2 === 0,
            labels: {
              formatter(): string {
                // @ts-ignore
                return formatValue(this.value)
              },
            },
          })),
        ),
      }}
    />
  )
}
