import { Configuration } from "api/timelight-api"
import * as lodash from "lodash"
import { useState } from "react"
import {
  AIApi,
  AlertApi,
  AlertListDto,
  AlertRefListDto,
  AlertRefResultDto,
  DayApi,
  DayContextApi,
  DayListDto,
  DaysNearDateResultDto,
  DayTrendListDto,
  ImportApi,
  ModelApi,
  ModelListDto,
  PrevisionApi,
  PrevisionListDto,
  SourceApi,
  SourceGroupApi,
  SourceGroupListDto,
  UserApi,
  ViewHelperApi,
  DayTrendApi,
  DayTrendInput,
  SourceTagsApi,
} from "api/timelight-api"
import { useConfig } from "../config"
import { getInstanceMethodNames, createStrIdMap, movingAverage } from "../lib/array"
import { deepFreeze } from "../lib/deep-freeze"
import { AsyncHookParams, useAsyncAction } from "./async"
import { useAlertOnReadOnly, useAuth, useLogoutOnError } from "./auth"
import { BaseAPI } from "api/timelight-api"
import { SourceControllerSourceDateDomainRequest } from "../api/timelight-api/apis/SourceApi"
import { SourceDto } from "../api/timelight-api/models/SourceDto"
import { SourceStatsDto } from "../api/timelight-api/models/SourceStatsDto"
import { ForecastApi } from "../api/timelight-api/apis/ForecastApi"
import { DayContextTypes } from "../api/timelight-api/models/DayContextTypes"
import { ForecastTask } from "../api/timelight-api/models/ForecastTask"
import { DayContextControllerSourceContextDateDomainsRequest } from "../api/timelight-api/apis/DayContextApi"
import { SerieSynchronisationApi } from "../api/timelight-api/apis/SerieSynchronisationApi"
import { SourceControllerSourceStatsRequest } from "../api/timelight-api/apis/SourceApi"
import { SerieSyncTask } from "../api/timelight-api/models/SerieSyncTask"
import { FetchSerieDataCompactResult } from "../api/timelight-api/models/FetchSerieDataCompactResult"
import {
  SourceClusterApi,
  GetManyBaseSourceClusterModelControllerSourceClusterModelRequest,
} from "../api/timelight-api/apis/SourceClusterApi"
import { SourceClusterTask } from "../api/timelight-api/models/SourceClusterTask"
import { SourceDateDomainDto } from "../api/timelight-api/models/SourceDateDomainDto"
import { SourceUserTagsControllerGetManyRequest } from "../api/timelight-api/apis/SourceTagsApi"
import { GetManySourceTagsResult } from "../api/timelight-api/models/GetManySourceTagsResult"
import { SourceDateDomainListDto } from "../api/timelight-api/models/SourceDateDomainListDto"
import { ContextTypeDateDomainListDto } from "../api/timelight-api/models/ContextTypeDateDomainListDto"
import { ContextImpactApi } from "../api/timelight-api/apis/ContextImpactApi"
import { ContextImpactTask } from "../api/timelight-api/models/ContextImpactTask"
import { ContextImpactSummaryDto } from "../api/timelight-api/models/ContextImpactSummaryDto"
import { toDateObj, dateObjToDayString } from "../lib/date"
import { GetManySourceClusterModelResponseDto } from "../api/timelight-api/models/GetManySourceClusterModelResponseDto"
import { GetManySourceDtoResponseDto } from "../api/timelight-api/models/GetManySourceDtoResponseDto"
import { GetManyForecastAlertResponseDto } from "../api/timelight-api/models/GetManyForecastAlertResponseDto"
import { SerieApi, SerieDataControllerFetchDataCompactRequest } from "../api/timelight-api/apis/SerieApi"

export type TimelightApi = Omit<
  AIApi &
    AlertApi &
    DayApi &
    DayContextApi &
    ImportApi &
    ModelApi &
    PrevisionApi &
    SourceApi &
    SourceGroupApi &
    UserApi &
    ViewHelperApi &
    DayTrendApi &
    ForecastApi &
    SerieSynchronisationApi &
    SerieApi &
    SourceClusterApi &
    ContextImpactApi &
    SourceTagsApi,
  "configuration" | "basePath" | "fetch"
>

export function useApiClient(): TimelightApi {
  const { jwt } = useAuth()
  const { API_URL } = useConfig()

  const params: Configuration = new Configuration({
    basePath: API_URL,
    accessToken: jwt || undefined,
  })

  const [api] = useState<any>(() =>
    [
      new AIApi(params),
      new AlertApi(params),
      new DayApi(params),
      new DayContextApi(params),
      new ImportApi(params),
      new ModelApi(params),
      new PrevisionApi(params),
      new SourceApi(params),
      new SourceGroupApi(params),
      new UserApi(params),
      new ViewHelperApi(params),
      new DayTrendApi(params),
      new ForecastApi(params),
      new SerieSynchronisationApi(params),
      new SerieApi(params),
      new SourceClusterApi(params),
      new SourceTagsApi(params),
      new ContextImpactApi(params),
    ]
      .map((apiPart) =>
        getInstanceMethodNames(apiPart, BaseAPI.prototype).reduce(
          (agg, methodName) => Object.assign(agg, { [methodName]: (apiPart as any)[methodName].bind(apiPart) }),
          {},
        ),
      )
      .reduce((agg, apiPart) => Object.assign(agg, apiPart), {}),
  )

  return api
}

export function useInstanceHasAtLeastNSources(initialParams: AsyncHookParams<{ n: number }>) {
  const api = useApiClient()
  const res = useAsyncAction<boolean, AsyncHookParams<{ n: number }>>(
    async ({ n }) => (await api.getManyBaseSourceControllerSourceDto({ limit: n })).data.length >= n,
    initialParams,
  )

  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourceList(initialParams: AsyncHookParams<{ limit: number }>) {
  const api = useApiClient()
  const res = useAsyncAction<{ sources: GetManySourceDtoResponseDto["data"] }, AsyncHookParams<{ limit?: number }>>(
    async ({ limit }) => ({ sources: (await api.getManyBaseSourceControllerSourceDto({ limit })).data }),
    initialParams,
  )

  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSource(initialParams: AsyncHookParams<{ sourceId: number }>) {
  const api = useApiClient()
  const res = useAsyncAction<SourceDto, AsyncHookParams<{ sourceId: number }>>(
    async ({ sourceId }) => deepFreeze(await api.getOneBaseSourceControllerSourceDto({ id: sourceId })),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourcesById(initialParams: AsyncHookParams<{ sourceIds: number[] }>) {
  const api = useApiClient()
  const res = useAsyncAction<SourceDto[], AsyncHookParams<{ sourceIds: number[] }>>(
    async ({ sourceIds }) =>
      deepFreeze(
        (
          await api.getManyBaseSourceControllerSourceDto({
            limit: sourceIds.length,
            filter: [`id||$in||${sourceIds.concat([-1]).join(",")}`],
          })
        ).data,
      ),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourceImportHistoryList(initialParams: AsyncHookParams<{}>) {
  const api = useApiClient()
  const res = useAsyncAction(
    async () =>
      deepFreeze({ sourceImports: (await api.getManyBaseSourceImportHistoryControllerSourceImportHistory({})).data }),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourceStats(initialParams: AsyncHookParams<SourceControllerSourceStatsRequest>) {
  const api = useApiClient()
  const res = useAsyncAction<SourceStatsDto, AsyncHookParams<SourceControllerSourceStatsRequest>>(
    async ({ sourceId }) => deepFreeze(await api.sourceControllerSourceStats({ sourceId })),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourceDateDomain(initialParams: AsyncHookParams<SourceControllerSourceDateDomainRequest>) {
  const api = useApiClient()
  const res = useAsyncAction<SourceDateDomainDto, AsyncHookParams<SourceControllerSourceDateDomainRequest>>(
    async ({ sourceId }) => deepFreeze(await api.sourceControllerSourceDateDomain({ sourceId })),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourceListDateDomain(initialParams: AsyncHookParams<{}>) {
  const api = useApiClient()
  const res = useAsyncAction<SourceDateDomainListDto, AsyncHookParams<{}>>(
    async () => deepFreeze(await api.sourceControllerSourceListDomains()),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourceContextDateDomains(
  initialParams: AsyncHookParams<DayContextControllerSourceContextDateDomainsRequest>,
) {
  const api = useApiClient()
  const res = useAsyncAction<
    ContextTypeDateDomainListDto,
    AsyncHookParams<DayContextControllerSourceContextDateDomainsRequest>
  >(async (params) => deepFreeze(await api.dayContextControllerSourceContextDateDomains(params)), initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useTimelightSourceList(initialParams: AsyncHookParams<{}>) {
  const api = useApiClient()
  const res = useAsyncAction(
    async () => deepFreeze({ sources: (await api.getManyBaseSourcePublicControllerSourceDto({ limit: 1000 })).data }),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useAllTimelightSourceDays(initialParams: AsyncHookParams<{ sourceId: number }>) {
  const api = useApiClient()
  const res = useAsyncAction<DayListDto, AsyncHookParams<{ sourceId: number }>>(async (params) => {
    return deepFreeze(await api.dayPublicControllerListDays({ sourceId: params.sourceId }))
  }, initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

interface DayListParams {
  sourceId: number
  year: number
}

interface ModelListParams {
  sourceId: number
}

export function useDayAndModelList(initialParams: AsyncHookParams<DayListParams & ModelListParams>) {
  const api = useApiClient()
  const res = useAsyncAction<ModelListDto & DayListDto, AsyncHookParams<DayListParams & ModelListParams>>(
    async (params) => {
      return deepFreeze({
        ...(await api.modelControllerListSourceModels({ sourceId: params.sourceId })),
        ...(await api.dayControllerListDays({ year: params.year, sourceId: params.sourceId })),
      })
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

interface AlertRefListParams {
  sourceId?: number
  year?: number
}

export function useDayAndModelAndAlertAndAlertRefListAndDayTrends(
  initialParams: AsyncHookParams<
    DayListParams & ModelListParams & AlertListParams & AlertRefListParams & PrevisionListParams
  >,
) {
  const api = useApiClient()
  const res = useAsyncAction<
    ModelListDto & DayListDto & AlertListDto & AlertRefListDto & PrevisionListDto & DayTrendListDto,
    AsyncHookParams<DayListParams & ModelListParams & AlertListParams & AlertRefListParams & PrevisionListParams>
  >(async (params) => {
    return deepFreeze({
      ...(await api.modelControllerListSourceModels({ sourceId: params.sourceId })),
      ...(await api.dayControllerListDays({ year: params.year, sourceId: params.sourceId })),
      ...(await api.alertControllerListAlerts({ sourceId: params.sourceId, year: params.year })),
      ...(await api.alertControllerListAlertRefs({ sourceId: params.sourceId, year: params.year })),
      ...(await api.previsionControllerListDays({ year: params.year, sourceId: params.sourceId })),
      ...{
        dayTrends: (
          await api.getManyBaseDayTrendControllerDayTrend({
            limit: 1000,
            filter: ["sourceId||eq||" + params.sourceId],
          })
        ).data,
      },
    })
  }, initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

interface PrevisionListParams {
  sourceId: number
  year: number
}
export function usePrevisionAndModelList(initialParams: AsyncHookParams<PrevisionListParams & ModelListParams>) {
  const api = useApiClient()
  const res = useAsyncAction<ModelListDto & PrevisionListDto, AsyncHookParams<ModelListParams & PrevisionListParams>>(
    async (params) => {
      return deepFreeze({
        ...(await api.modelControllerListSourceModels({ sourceId: params.sourceId })),
        ...(await api.previsionControllerListDays({ year: params.year, sourceId: params.sourceId })),
      })
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

interface AlertListParams {
  sourceId?: number
  year?: number
}

export function useAlertsAndModelsAndSources(initialParams: AsyncHookParams<AlertListParams>) {
  const api = useApiClient()
  const res = useAsyncAction<
    ModelListDto & { sources: GetManySourceDtoResponseDto["data"] } & AlertListDto,
    AsyncHookParams<AlertListParams>
  >(async (params) => {
    // fetch alerts
    const alertRes = await api.alertControllerListAlerts({ sourceId: params.sourceId, year: params.year })
    const models = await api.modelControllerListModels()

    return deepFreeze({
      sources: (await api.getManyBaseSourceControllerSourceDto({ limit: 10000000 })).data,
      alerts: alertRes.alerts,
      models: models.models,
    })
  }, initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

interface DaysNearDateParams {
  sourceId: number
  dayDate: string
}

export function useDaysNearDate(initialParams: AsyncHookParams<DaysNearDateParams>) {
  const api = useApiClient()
  const res = useAsyncAction<
    DaysNearDateResultDto & { sources: GetManySourceDtoResponseDto["data"] } & DayTrendListDto,
    AsyncHookParams<DaysNearDateParams>
  >(
    async (params) =>
      deepFreeze({
        sources: (await api.getManyBaseSourceControllerSourceDto({ limit: 10000000 })).data,
        ...(await api.viewHelperControllerDaysNearDate({ dayDate: params.dayDate, sourceId: params.sourceId })),
        ...{
          dayTrends: (
            await api.getManyBaseDayTrendControllerDayTrend({
              limit: 1000,
              filter: ["sourceId||eq||" + params.sourceId],
            })
          ).data,
        },
      }),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useAlertRefList(initialParams: AsyncHookParams<AlertRefListParams>) {
  const api = useApiClient()
  const res = useAsyncAction<AlertRefResultDto, AsyncHookParams<{}>>(
    async () => deepFreeze(await api.viewHelperControllerAlertRef()),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourcesAndGroupsList(initialParams: AsyncHookParams<{}>) {
  const api = useApiClient()
  const res = useAsyncAction<
    { sources: GetManySourceDtoResponseDto["data"] } & SourceGroupListDto,
    AsyncHookParams<{}>
  >(
    async () =>
      deepFreeze({
        sources: (await api.getManyBaseSourceControllerSourceDto({ limit: 10000000 })).data,
        ...(await api.sourceGroupControllerSourceList()),
      }),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

interface TemperatureDataItem {
  dayDate: string
  tempMin: number | null
  tempMax: number | null
  tempAvg: number | null
  tempMovAvgAvg: number | null
}

function useTemperatureData(initialParams: AsyncHookParams<{ sourceId: number; minDate: string; maxDate: string }>) {
  const api = useApiClient()

  const res = useAsyncAction<
    TemperatureDataItem[],
    AsyncHookParams<{ sourceId: number; minDate: string; maxDate: string }>
  >(async ({ sourceId, minDate, maxDate }) => {
    const beginDate = toDateObj(minDate)
    const endDate = new Date(toDateObj(maxDate).getTime() + 24 * 60 * 60 * 1000)
    const dayContexts = deepFreeze([
      ...(
        await api.dayContextControllerGetMany({
          sourceId,
          ctxType: DayContextTypes.TempMaxDeg,
          begin: beginDate,
          end: endDate,
        })
      ).data,
      ...(
        await api.dayContextControllerGetMany({
          sourceId,
          ctxType: DayContextTypes.TempMinDeg,
          begin: beginDate,
          end: endDate,
        })
      ).data,
    ])

    // join the 2 data sources by date
    const tempMinByDate = createStrIdMap(
      (dayContexts || []).filter((c) => c.ctxType === DayContextTypes.TempMinDeg),
      (c) => c.dayDate,
    )
    const tempMaxByDate = createStrIdMap(
      (dayContexts || []).filter((c) => c.ctxType === DayContextTypes.TempMaxDeg),
      (c) => c.dayDate,
    )
    const days = lodash.uniqBy(dayContexts, "dayDate").map((c) => c.dayDate)
    days.sort()
    const dataByDay = createStrIdMap(
      days.map((date) => {
        const tempMin = tempMinByDate[date] ? tempMinByDate[date].value : null
        const tempMax = tempMaxByDate[date] ? tempMaxByDate[date].value : null
        const tempAvg = tempMin !== null && tempMax !== null ? (tempMin + tempMax) / 2 : null
        return {
          dayDate: date,
          tempMin,
          tempMax,
          tempAvg,
          tempMovAvgAvg: null,
        }
      }),
      (i) => i.dayDate,
    )

    // fill date voids between the 2 dates
    const filledVoidData: TemperatureDataItem[] = []
    for (
      let date = minDate;
      date < maxDate;
      // add 1 day... thank you JS
      // add 26h to account for seasonal time changes
      date = dateObjToDayString(new Date(toDateObj(date).getTime() + 60 * 60 * 26 * 1000))
    ) {
      filledVoidData.push(
        dataByDay[date]
          ? dataByDay[date]
          : { dayDate: date, tempMin: null, tempMax: null, tempAvg: null, tempMovAvgAvg: null },
      )
    }

    // compute temperature moving average of temperature average
    const movingAvgs = movingAverage(
      filledVoidData.map((t) => t.tempAvg),
      10,
      0,
    )
    return filledVoidData.map((d, i) => ({
      ...d,
      tempMovAvgAvg: movingAvgs[i],
    }))
  }, initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useTemperatureContext(...args: Parameters<typeof useTemperatureData>) {
  const [{ data: temperatureData }] = useTemperatureData(...args)
  return lodash.flatten(
    (temperatureData || [])
      .filter((td) => td.tempAvg !== null)
      .map((td) => [
        {
          dayDate: td.dayDate,
          ctxType: ("temp_avg_deg" as unknown) as DayContextTypes,
          value: td.tempAvg || 0,
        },
        {
          dayDate: td.dayDate,
          ctxType: DayContextTypes.TempMinDeg,
          value: td.tempMin || 0,
        },
        {
          dayDate: td.dayDate,
          ctxType: DayContextTypes.TempMaxDeg,
          value: td.tempMax || 0,
        },
      ]),
  )
}

export function useDayTrendInputs(initialParams: AsyncHookParams<{ sourceId: number }>) {
  const api = useApiClient()

  const res = useAsyncAction<DayTrendInput[], AsyncHookParams<{ sourceId: number }>>(async ({ sourceId }) => {
    return deepFreeze(
      ((
        await api.getManyBaseDayTrendControllerDayTrend({
          limit: 1000,
          filter: ["sourceId||eq||" + sourceId],
        })
      ).data as unknown) as DayTrendInput[],
    )
  }, initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useForecastTask(initialParams: AsyncHookParams<{ forecastTaskId: number }>) {
  const api = useApiClient()

  const res = useAsyncAction<ForecastTask, AsyncHookParams<{ forecastTaskId: number }>>(async ({ forecastTaskId }) => {
    return deepFreeze(
      await api.getOneBaseForecastTaskControllerForecastTask({
        id: forecastTaskId,
      }),
    )
  }, initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useForecastAlerts(initialParams: AsyncHookParams<{ forecastTaskId: number }>) {
  const api = useApiClient()

  const res = useAsyncAction<GetManyForecastAlertResponseDto, AsyncHookParams<{ forecastTaskId: number }>>(
    async ({ forecastTaskId }) => {
      return deepFreeze(
        await api.getManyBaseForecastAlertControllerForecastAlert({
          filter: [`forecastTaskId||$eq||` + forecastTaskId],
          limit: 100000,
        }),
      )
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSerieSyncTask(initialParams: AsyncHookParams<{ syncTaskId: number }>) {
  const api = useApiClient()

  const res = useAsyncAction<SerieSyncTask, AsyncHookParams<{ syncTaskId: number }>>(async ({ syncTaskId }) => {
    return deepFreeze(
      await api.getOneBaseSyncTaskControllerSerieSyncTask({
        id: syncTaskId,
      }),
    )
  }, initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourceClusterTask(initialParams: AsyncHookParams<{ sourceClusterTaskId: number }>) {
  const api = useApiClient()

  const res = useAsyncAction<SourceClusterTask, AsyncHookParams<{ sourceClusterTaskId: number }>>(
    async ({ sourceClusterTaskId }) => {
      return deepFreeze(
        await api.getOneBaseSourceClusterTaskControllerSourceClusterTask({
          id: sourceClusterTaskId,
        }),
      )
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourceClusterModels(
  initialParams: AsyncHookParams<GetManyBaseSourceClusterModelControllerSourceClusterModelRequest>,
) {
  const api = useApiClient()

  const res = useAsyncAction<
    GetManySourceClusterModelResponseDto,
    AsyncHookParams<GetManyBaseSourceClusterModelControllerSourceClusterModelRequest>
  >(async (params) => {
    return deepFreeze(await api.getManyBaseSourceClusterModelControllerSourceClusterModel(params))
  }, initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useContextImpactTask(initialParams: AsyncHookParams<{ contextImpactTaskId: number }>) {
  const api = useApiClient()

  const res = useAsyncAction<ContextImpactTask, AsyncHookParams<{ contextImpactTaskId: number }>>(
    async ({ contextImpactTaskId }) => {
      return deepFreeze(
        await api.getOneBaseContextImpactTaskControllerContextImpactTask({
          id: contextImpactTaskId,
        }),
      )
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useContextImpactDaySummary(
  initialParams: AsyncHookParams<{ contextImpactTaskId: number; day: string }>,
) {
  const api = useApiClient()

  const res = useAsyncAction<ContextImpactSummaryDto, AsyncHookParams<{ contextImpactTaskId: number; day: string }>>(
    async ({ contextImpactTaskId, day }) => {
      return deepFreeze(
        await api.contextImpactTaskControllerGetDaySummary({
          contextImpactTaskId,
          day,
        }),
      )
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSerieData(initialParams: AsyncHookParams<SerieDataControllerFetchDataCompactRequest>) {
  const api = useApiClient()

  const res = useAsyncAction<FetchSerieDataCompactResult, AsyncHookParams<SerieDataControllerFetchDataCompactRequest>>(
    async (params) => {
      return deepFreeze(await api.serieDataControllerFetchDataCompact(params))
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useSourceTagsList(initialParams: AsyncHookParams<SourceUserTagsControllerGetManyRequest>) {
  const api = useApiClient()

  const res = useAsyncAction<GetManySourceTagsResult, AsyncHookParams<SourceUserTagsControllerGetManyRequest>>(
    async (params) => {
      return deepFreeze(await api.sourceUserTagsControllerGetMany(params))
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function uploadFile(file: File, options: { jwt: string | null; url: string }) {
  const headers: { [header: string]: string } = {
    "Content-Type": file.type,
  }
  if (options.jwt) {
    headers.Authorization = `Bearer ${options.jwt}`
  }

  return fetch(options.url, {
    method: "POST",
    headers,
    body: file,
  }).then((res) => res.json())
}

export function extractConstraints(res: any): { [key: string]: string } | null {
  if (res.constraints) {
    return res.constraints
  }
  let constraints = {}
  if (res.children && Array.isArray(res.children)) {
    for (const ch of res.children) {
      const constr = extractConstraints(ch)
      if (constr) {
        constraints = { ...constraints, ...constr }
      }
    }
  }
  if (res.message && Array.isArray(res.message)) {
    for (const ch of res.message) {
      const constr = extractConstraints(ch)
      if (constr) {
        constraints = { ...constraints, ...constr }
      }
    }
  }
  if (Object.keys(constraints).length > 0) {
    return constraints
  }
  return null
}
