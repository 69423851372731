import { CircularProgress } from "@material-ui/core"
import React from "react"

export default function Loader({ color = "primary" }: { color?: "primary" | "inherit" }) {
  return (
    <div style={{ display: "flex" }}>
      <CircularProgress color={color} size={24} />
    </div>
  )
}
