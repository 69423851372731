import React from "react"
import { Field } from "react-final-form"
import * as lodash from "lodash"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"
import TextField from "@material-ui/core/TextField"
import { FormHelperText } from "@material-ui/core"

export type TextFormState = string

export function createTextField<FormState>(props: { name: keyof FormState & string } & TextFieldProps) {
  // @ts-ignore
  return <Field {...props} render={TextFieldRender} />
}

interface TextFieldProps {
  label: string
  required: boolean
}

const TextFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<TextFormState, TextFieldProps>) => {
    return (
      <div>
        <TextField
          label={props.label}
          variant="outlined"
          value={props.input.value}
          margin="normal"
          required={props.required}
          name={props.input.name}
          onChange={(v) => {
            props.input.onChange(v.target.value)
          }}
        />
        {props.meta.error &&
          (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
            <FormHelperText key={i} error={true}>
              {e}
            </FormHelperText>
          ))}
      </div>
    )
  },
  (props: CustomFieldRenderProps<TextFormState, TextFieldProps>) => {
    return props.input.value + getFormFieldPropsMemoizeKey(props)
  },
)
