/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  SourceGroupCreateDto,
  SourceGroupCreateDtoFromJSON,
  SourceGroupCreateDtoToJSON,
  SourceGroupDto,
  SourceGroupDtoFromJSON,
  SourceGroupDtoToJSON,
  SourceGroupListDto,
  SourceGroupListDtoFromJSON,
  SourceGroupListDtoToJSON,
  SourceGroupPatchDto,
  SourceGroupPatchDtoFromJSON,
  SourceGroupPatchDtoToJSON,
} from "../models"

export interface SourceGroupControllerSourceGroupCreateRequest {
  sourceGroupCreateDto: SourceGroupCreateDto
}

export interface SourceGroupControllerSourceGroupPatchRequest {
  groupId: number
  sourceGroupPatchDto: SourceGroupPatchDto
}

/**
 * no description
 */
export class SourceGroupApi extends runtime.BaseAPI {
  /**
   * Create a new source groups
   */
  async sourceGroupControllerSourceGroupCreateRaw(
    requestParameters: SourceGroupControllerSourceGroupCreateRequest,
  ): Promise<runtime.ApiResponse<SourceGroupDto>> {
    if (requestParameters.sourceGroupCreateDto === null || requestParameters.sourceGroupCreateDto === undefined) {
      throw new runtime.RequiredError(
        "sourceGroupCreateDto",
        "Required parameter requestParameters.sourceGroupCreateDto was null or undefined when calling sourceGroupControllerSourceGroupCreate.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-group/create`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SourceGroupCreateDtoToJSON(requestParameters.sourceGroupCreateDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceGroupDtoFromJSON(jsonValue))
  }

  /**
   * Create a new source groups
   */
  async sourceGroupControllerSourceGroupCreate(
    requestParameters: SourceGroupControllerSourceGroupCreateRequest,
  ): Promise<SourceGroupDto> {
    const response = await this.sourceGroupControllerSourceGroupCreateRaw(requestParameters)
    return await response.value()
  }

  /**
   * Updates a group configuration
   */
  async sourceGroupControllerSourceGroupPatchRaw(
    requestParameters: SourceGroupControllerSourceGroupPatchRequest,
  ): Promise<runtime.ApiResponse<SourceGroupDto>> {
    if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
      throw new runtime.RequiredError(
        "groupId",
        "Required parameter requestParameters.groupId was null or undefined when calling sourceGroupControllerSourceGroupPatch.",
      )
    }

    if (requestParameters.sourceGroupPatchDto === null || requestParameters.sourceGroupPatchDto === undefined) {
      throw new runtime.RequiredError(
        "sourceGroupPatchDto",
        "Required parameter requestParameters.sourceGroupPatchDto was null or undefined when calling sourceGroupControllerSourceGroupPatch.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-group/{groupId}`.replace(
        `{${"groupId"}}`,
        encodeURIComponent(String(requestParameters.groupId)),
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: SourceGroupPatchDtoToJSON(requestParameters.sourceGroupPatchDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceGroupDtoFromJSON(jsonValue))
  }

  /**
   * Updates a group configuration
   */
  async sourceGroupControllerSourceGroupPatch(
    requestParameters: SourceGroupControllerSourceGroupPatchRequest,
  ): Promise<SourceGroupDto> {
    const response = await this.sourceGroupControllerSourceGroupPatchRaw(requestParameters)
    return await response.value()
  }

  /**
   * All source groups
   */
  async sourceGroupControllerSourceListRaw(): Promise<runtime.ApiResponse<SourceGroupListDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-group/list`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceGroupListDtoFromJSON(jsonValue))
  }

  /**
   * All source groups
   */
  async sourceGroupControllerSourceList(): Promise<SourceGroupListDto> {
    const response = await this.sourceGroupControllerSourceListRaw()
    return await response.value()
  }
}
