/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  ContextTypeStatsDto,
  ContextTypeStatsDtoFromJSON,
  ContextTypeStatsDtoFromJSONTyped,
  ContextTypeStatsDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface ContextTypeStatsListDto
 */
export interface ContextTypeStatsListDto {
  /**
   *
   * @type {Array<ContextTypeStatsDto>}
   * @memberof ContextTypeStatsListDto
   */
  stats: Array<ContextTypeStatsDto>
}

export function ContextTypeStatsListDtoFromJSON(json: any): ContextTypeStatsListDto {
  return ContextTypeStatsListDtoFromJSONTyped(json, false)
}

export function ContextTypeStatsListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContextTypeStatsListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    stats: (json["stats"] as Array<any>).map(ContextTypeStatsDtoFromJSON),
  }
}

export function ContextTypeStatsListDtoToJSON(value?: ContextTypeStatsListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    stats: (value.stats as Array<any>).map(ContextTypeStatsDtoToJSON),
  }
}
