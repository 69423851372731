/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayTrend, DayTrendFromJSON, DayTrendFromJSONTyped, DayTrendToJSON } from "./"

/**
 *
 * @export
 * @interface CreateManyDayTrendDto
 */
export interface CreateManyDayTrendDto {
  /**
   *
   * @type {Array<DayTrend>}
   * @memberof CreateManyDayTrendDto
   */
  bulk: Array<DayTrend>
}

export function CreateManyDayTrendDtoFromJSON(json: any): CreateManyDayTrendDto {
  return CreateManyDayTrendDtoFromJSONTyped(json, false)
}

export function CreateManyDayTrendDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateManyDayTrendDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    bulk: (json["bulk"] as Array<any>).map(DayTrendFromJSON),
  }
}

export function CreateManyDayTrendDtoToJSON(value?: CreateManyDayTrendDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    bulk: (value.bulk as Array<any>).map(DayTrendToJSON),
  }
}
