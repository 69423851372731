import { FormControl, FormControlLabel, FormGroup, Switch, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import SaveIcon from "@material-ui/icons/Save"
import ListSelect from "component/ListSelect"
import Loader from "component/Loader"
import PageBlock from "component/PageBlock"
import YearSelect from "component/YearSelect"
import { createIdMap } from "lib/array"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import posed from "react-pose"
import { Prompt } from "react-router"
import { DayTrendInput, ModelDto, PrevisionDto, SourcePatchDto } from "api/timelight-api"
import AppHeader from "../../component/AppHeader"
import ChartDayActivity from "../../component/ChartDayActivity"
import DayTrendForm from "../../component/DayTrendForm"
import { applyTrend } from "../../component/DayTrendForm"
import DayYearCalendar from "../../component/DayYearCalendar"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import SourceSelect from "../../component/SourceSelect"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { getCurrentYear } from "../../lib/date"
import { useApiClient, useDayTrendInputs, usePrevisionAndModelList, useTemperatureContext } from "../../state/api"
import { useApiExceptionHandler } from "../../state/auth"
import { useSourceSelect } from "../../state/persisted"
import * as lodash from "lodash"
import { createChartDayDataFromApiInfos } from "../../component/ChartDayActivity/utils"
import DaysNearDateModal from "component/DaysNearDateModal"
import { AppButton } from "../../component/AppButton"

const useStyle = makeStyles((theme) => ({
  saveContainer: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },
  savePaper: {
    maxWidth: "350px",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

const SaveAnimationContainer = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
})

export default function PrevisionSource() {
  const classes = useStyle()
  const { t } = useTranslation()

  // the app bar for values
  const [source, setSourceId] = useSourceSelect()

  const [year, setYear] = useState(getCurrentYear())

  // fetch some data
  const [{ data, loading }, setParams, setData] = usePrevisionAndModelList({
    shouldTrigger: source.id !== -1,
    sourceId: source.id,
    year,
  })
  // fetch temperature data
  const temperatureContext = useTemperatureContext({
    sourceId: source.id,
    minDate: `${year}-01-01`,
    maxDate: `${year}-12-31`,
    shouldTrigger: source.id !== -1,
  })

  // state for our prevision modal
  const [selectedPrevision, setSelectedPrevision] = useState<PrevisionDto | null>(null)

  // we want to prevent data loss
  const [dirty, setDirty] = useState<boolean>(false)

  // have a quick kv store of models
  const modelsById = createIdMap(data ? data.models : [])

  function handleSourceIdChange(id: number) {
    setSourceId(id)
    setParams({ shouldTrigger: true, sourceId: id, year })
  }
  function handleYearChange(y: number) {
    setYear(y)
    setParams({ shouldTrigger: true, sourceId: source.id, year: y })
  }

  const [LocalModelChangeSuccessMessage, showLocalModelChangeSuccessMessage] = useSuccessMessage({
    message: "Profil type modifié localement, veuillez sauvegarder pour appliquer les changements",
  })
  function updateSelectedPrevisionModel(model: ModelDto) {
    if (!selectedPrevision || !data) {
      return
    }
    // update local data only
    setData({
      ...data,
      previsions: data.previsions.map((p) => ({
        ...p,
        model_id: p.date === selectedPrevision.date ? model.id : p.model_id,
      })),
    })

    if (!dirty) {
      // show a noice message
      showLocalModelChangeSuccessMessage()
    }

    // we now have a dirty page, we want to prevent page changes
    setDirty(true)

    // close the modal
    setSelectedPrevision(null)
  }

  // prevision update api call
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Prévisions mises à jour avec succès",
  })
  const api = useApiClient()
  const handleApiException = useApiExceptionHandler()
  const [submitting, setSubmitting] = useState<boolean>(false)
  async function saveAllPrevisions() {
    if (!data) {
      return
    }
    setSubmitting(true)
    try {
      await api.previsionControllerSavePrevision({
        previsionBulkSaveDto: {
          year,
          sourceId: source.id,
          previsions: data.previsions.map((p) => ({
            modelId: p.model_id,
            date: p.date,
          })),
        },
      })
      // update local data
      setData({
        ...data,
        hasSavedPrevisions: true,
      })
      // close modal if any
      setSelectedPrevision(null)

      // success is at the corner
      showSuccessMessage()

      setDirty(false)
    } finally {
      setSubmitting(false)
    }
  }

  // source update api call
  const [SourceSuccessMessage, showSourceSuccessMessage] = useSuccessMessage({
    message: `La planification est maintenant ${!source.previsionEnabled ? "activée" : "désactivée"}`,
  })
  async function updateSource(values: { previsionEnabled: boolean }) {
    if (!data) {
      return
    }
    setSubmitting(true)
    try {
      const newSource = await api.sourceControllerSourceUpdate({
        sourcePatchDto: ({
          name: source.name,
          address: source.address,
          latitude: source.latitude,
          longitude: source.longitude,
          previsionEnabled: values.previsionEnabled,
          alertMinCriticity: source.alertMinCriticity,
          alertMaxCriticity: source.alertMaxCriticity,
        } as unknown) as SourcePatchDto,
        sourceId: source.id,
      })
      // update local data
      setSourceId(newSource.id)

      // success is at the corner
      showSourceSuccessMessage()

      // reload the page because i'm lazy
      window.location.reload()
    } catch (e) {
      handleApiException(e)
    } finally {
      setSubmitting(false)
    }
  }

  // schéma directeur
  const [trendsDirty, setTrendsDirty] = useState<boolean>(false)
  const [
    { data: trendsData },
    // @ts-ignore
    // tslint:disable:no-unused-declaration
    // eslint-disable-next-line
    ___,
    setTrendsState,
  ] = useDayTrendInputs({ sourceId: source.id, shouldTrigger: true })
  const trends = lodash.sortBy(trendsData || [], "id")
  function setTrends(tr: DayTrendInput[]) {
    setTrendsDirty(true)
    setTrendsState(tr)
  }

  const [TrendSuccessMessage, showTrendSuccessMessage] = useSuccessMessage({
    message: t("trend.updateSuccess"),
  })
  async function saveTrends() {
    if (!data) {
      return
    }
    setSubmitting(true)
    try {
      await api.dayTrendControllerReplaceAllInSource({
        sourceId: source.id,
        dayTrendInputListDto: { dayTrends: trends },
      })
      // update local data
      setTrends(trends)
      // success is at the corner
      showTrendSuccessMessage()

      setTrendsDirty(false)
    } finally {
      setSubmitting(false)
    }
  }

  // make a new copy of activity data
  const activityData = data
    ? data.previsions.map((p) => {
        return {
          ...p,
          value: modelsById[p.model_id].value,
          anomaly: false,
        }
      })
    : []

  // apply modifiers
  for (const act of activityData) {
    for (const mod of trends) {
      applyTrend(mod, act)
    }
  }

  return (
    <PageContainer title="Prévoir">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            <SourceSelect sourceId={source.id} setSourceId={handleSourceIdChange} />
            <YearSelect
              year={year}
              setYear={handleYearChange}
              years={{ from: getCurrentYear(), to: getCurrentYear() + 5 }}
            />
            Prévoir l'activité
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SuccessMessage />
        <SourceSuccessMessage />
        <LocalModelChangeSuccessMessage />
        <TrendSuccessMessage />
        <Prompt
          when={dirty}
          message={() => `Vous perdrez vos modifications de prévisions si vous changez de page maintenant`}
        />
        <Prompt when={trendsDirty} message={t("trend.leaveWarning")} />

        <PageBlock title="Configuration de la planification">
          <p>
            {source.previsionEnabled ? (
              <>
                La planification est <strong>activée</strong>: le calendrier de l'activité à venir est automatiquement
                constitué en fonction de l'historique - n'hésitez pas à le modifier ci après pour représenter au mieux
                l'activité à venir. Il sert de référence pour les données de l'année en cours.
              </>
            ) : (
              <>
                La planification n'est <strong>pas activée</strong>: les données de l'année en cours sont
                automatiquement associés à leur profil type le plus proche - seules les alertes de type écart de forme
                et écart d'activité sont détectées.
              </>
            )}
          </p>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                key={source.id}
                disabled={submitting}
                control={
                  <Switch
                    color="primary"
                    checked={source.previsionEnabled}
                    onChange={() => updateSource({ previsionEnabled: !source.previsionEnabled })}
                  />
                }
                label={source.previsionEnabled ? "Planification activée" : "Planification désactivée"}
              />
            </FormGroup>
          </FormControl>
        </PageBlock>

        {source.previsionEnabled && (
          <>
            {data && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: dirty ? 20 : 0,
                }}
              >
                {dirty && (
                  <AppButton
                    submitting={submitting}
                    color="primary"
                    variant="contained"
                    onClick={() => saveAllPrevisions()}
                  >
                    Sauvegarder la planification
                  </AppButton>
                )}
              </div>
            )}
            {data && selectedPrevision && (
              <DaysNearDateModal
                open={true}
                alertInfos={false}
                sourceId={source.id}
                initialDate={selectedPrevision.date}
                onClose={() => setSelectedPrevision(null)}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingLeft: 20,
                    paddingRight: 20,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ marginRight: 10 }}>Modifier le profil type calculé :</div>
                    <div style={{ minWidth: 200 }}>
                      <ListSelect
                        menuPlacement="top"
                        value={modelsById[selectedPrevision.model_id]}
                        items={data.models}
                        getLabel={(m) => m.name}
                        getValue={(m) => m.id}
                        disabled={submitting}
                        onSelect={(m) => updateSelectedPrevisionModel(m)}
                      />
                    </div>
                  </div>
                </div>
              </DaysNearDateModal>
            )}
            <PageBlock
              loading={loading}
              title={`Activité prévue sur l'année: ${year}`}
              help={
                <div>
                  <p>
                    Vous trouverez ici le calendrier projeté, qui est la référence utilisée pour la détection d'alertes
                  </p>
                  <p>Pour changer la configuration des profil types, rendez-vous dans le module "Maîtriser"</p>
                </div>
              }
              height={400}
            >
              {data && (
                <ChartDayActivity
                  activityLabel="Activité prévue"
                  models={data.models}
                  days={createChartDayDataFromApiInfos({
                    days: activityData,
                    context: temperatureContext,
                    previsions: data.previsions,
                    models: data.models,
                    year,
                  })}
                  onBarClick={(selected) =>
                    setSelectedPrevision(data.previsions.find((p) => p.date === selected.date) || null)
                  }
                />
              )}
            </PageBlock>
            <PageBlock
              title={t("trend.title")}
              help={
                <div>
                  <p>{t("trend.introText")}</p>
                </div>
              }
            >
              <DayTrendForm trends={trends} setTrends={setTrends} />

              {data && trendsDirty && (
                <AppButton submitting={submitting} color="primary" variant="contained" onClick={() => saveTrends()}>
                  {t("trend.saveButton")}
                </AppButton>
              )}
            </PageBlock>
            <PageBlock
              title={`Calendrier prévisionnel de l'année: ${year}`}
              help={
                <div>
                  <p>Ce calendrier est généré par défaut à partir de l'année de référence du module "Maîtriser"</p>
                  <p>Vous pouvez l'éditer en changeant pour chaque journée le profil type attendu</p>
                </div>
              }
            >
              {data && (
                <DayYearCalendar
                  year={year}
                  models={data.models}
                  days={activityData}
                  setSelectedDay={setSelectedPrevision}
                />
              )}

              <SaveAnimationContainer
                className={classes.saveContainer}
                pose={
                  (data && !data.hasSavedPrevisions) || (data && data.hasSavedPrevisions && dirty)
                    ? "visible"
                    : "hidden"
                }
              >
                <Paper className={classes.savePaper}>
                  <Typography style={{ marginBottom: 10 }}>
                    {data && !data.hasSavedPrevisions && (
                      <>
                        Vous n'avez pas encore sauvegardé vos prévisions pour cette année. Vous devez valider la
                        planification d'un capteur pour recevoir des alertes.
                      </>
                    )}
                    {data && data.hasSavedPrevisions && (
                      <>
                        Attention ! La modification de cette configuration peut impacter la qualification des alertes
                        passées Pour autant les alertes mises sous silence ne seront pas réveillées
                      </>
                    )}
                  </Typography>
                  <AppButton
                    variant="contained"
                    color="primary"
                    size="medium"
                    submitting={submitting}
                    onClick={() => saveAllPrevisions()}
                  >
                    <SaveIcon className={classes.extendedIcon} />
                    Sauvegarder la planification
                  </AppButton>
                </Paper>
              </SaveAnimationContainer>
              {loading && <Loader />}
            </PageBlock>
          </>
        )}
      </PageContent>
    </PageContainer>
  )
}
