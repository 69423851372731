/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  DayContextSummary,
  DayContextSummaryFromJSON,
  DayContextSummaryFromJSONTyped,
  DayContextSummaryToJSON,
} from "./"

/**
 *
 * @export
 * @interface DayContextSummaryListDto
 */
export interface DayContextSummaryListDto {
  /**
   * List of day context summary
   * @type {Array<DayContextSummary>}
   * @memberof DayContextSummaryListDto
   */
  summaries: Array<DayContextSummary>
}

export function DayContextSummaryListDtoFromJSON(json: any): DayContextSummaryListDto {
  return DayContextSummaryListDtoFromJSONTyped(json, false)
}

export function DayContextSummaryListDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DayContextSummaryListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    summaries: (json["summaries"] as Array<any>).map(DayContextSummaryFromJSON),
  }
}

export function DayContextSummaryListDtoToJSON(value?: DayContextSummaryListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    summaries: (value.summaries as Array<any>).map(DayContextSummaryToJSON),
  }
}
