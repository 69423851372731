import { Input } from "@material-ui/core"
import FormHelperText from "@material-ui/core/FormHelperText"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import OkIcon from "@material-ui/icons/Check"
import DlIcon from "@material-ui/icons/CloudDownload"
import { ImportErrorBlock } from "component/ImportErrorBlock"
import PageBlock from "component/PageBlock"
import Title from "component/Title"
import * as lodash from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { AppRoute } from "state/route"
import { CreateSourceDto } from "api/timelight-api"
import AppHeader from "../../component/AppHeader"
import GridHighlight from "../../component/GridHighlight"
import ListSelect from "../../component/ListSelect"
import Loader from "../../component/Loader"
import LoaderBlock from "../../component/LoaderBlock"
import { MaterialTable } from "../../component/MaterialTable"
import {
  createTextColumnConfig,
  extractColumnConfigAndExportConfig,
} from "../../component/MaterialTable/material-table-helpers"
import {
  createNumericColumnConfig,
  createTextEnumColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { useConfig } from "../../config"
import { arrayToCsvString } from "../../lib/csv"
import { formatDayWithMoment } from "../../lib/date"
import { downloadStringAsFile } from "../../lib/file"
import { formatNumber } from "../../lib/number"
import { extractConstraints, uploadFile } from "../../state/api"
import { useApiExceptionHandler, useAuth } from "../../state/auth"
import SideMenu from "component/SideMenu"
import { useHistory } from "react-router"
import { buildUrl } from "../../component/AppLink"
import { AppButton } from "../../component/AppButton"
import { useLocalStorage } from "../../state/localStorage"
import { useOnboardingState } from "../../state/onboarding"
import { objectToQueryParams } from "../../lib/url"
import SelectCreatable from "react-select/creatable"

interface ImportResponseRow {
  ok: boolean
  lineNum: number
  row: { date: string | null; data: (number | null)[] }
  errors: string[]
}

const useStyles = makeStyles((theme) => ({
  outerBox: { maxWidth: "70%", margin: "auto", padding: theme.spacing(4) },
  introText: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(40),
    lineHeight: 1,
  },
  table: {
    maxWidth: 500,
  },
  modelChart: {
    height: "300px",
    width: "100%",
    maxWidth: "400px",
  },
  modelTag: {
    paddingRight: "1em",
  },
  modelTagContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    marginBottom: "4em",
  },

  text: {
    fontSize: "1.2em",
  },
  okIcon: {
    color: theme.palette.primary.main,
    "&> svg": {
      fontSize: "10em",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    fontSize: 15,
    width: "100%",
    flexWrap: "wrap",
    marginTop: "1em",
  },
}))

const activitySizeOptions = [
  { value: 144, label: "10 minutes" },
  { value: 72, label: "20 minutes" },
  { value: 48, label: "30 minutes" },
  { value: 24, label: "1 heure" },
  { value: 12, label: "2 heures" },
  { value: 8, label: "3 heures" },
  { value: 4, label: "6 heures" },
]

const interpolationMethodOptions = [
  { value: "zero", label: "Remplir avec des 0" },
  { value: "linear", label: "Interpolation linéaire (moyenne pondérée)" },
  { value: "forecast", label: "Interpolation par modélisation statistique" },
]

const numberLocaleOptions = [
  { value: "fr", label: "Format Français. Ex: 1 200,4 ou 1200,4" },
  { value: "en", label: "Format Anglais. Ex: 1,200.4 ou 1200.4" },
]
const dateFormatOptions = [
  { value: "YYYY-MM-DDTHH:mm:ss", label: "2014-12-31T23:59:00" },
  { value: "YYYY-MM-DDTHH:mm", label: "2014-12-31T23:59" },
  { value: "YYYY-MM-DD HH:mm:ss", label: "2014-12-31 23:59:00" },
  { value: "YYYY-MM-DD HH:mm", label: "2014-12-31 23:59" },
  { value: "DD/MM/YYYY HH:mm:ss", label: "31/12/2014 23:59:00" },
  { value: "DD/MM/YYYY HH:mm", label: "31/12/2014 23:59" },
  { value: "MM/DD/YYYY HH:mm:ss", label: "12/31/2014 23:59:00" },
  { value: "MM/DD/YYYY HH:mm", label: "12/31/2014 23:59" },
]
const separatorOptions = [
  { value: ";", label: ";" },
  { value: ",", label: "," },
  { value: "|", label: "|" },
  { value: " ", label: "espace vide" },
]

const quoteOptions = [
  { value: "'", label: "'" },
  { value: '"', label: '"' },
]

export default function SourceCreate() {
  const classes = useStyles()
  const { API_URL } = useConfig()
  const { sourceAdded } = useOnboardingState()
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [generatingTemplate, setGeneratingTemplate] = useState<boolean>(false)
  const [response, setResponse] = useState<ImportResponseRow[] | null>(null)
  const [userError, setUserError] = useState<string | null>(null)
  const [serverError, setServerError] = useState<boolean>(false)
  const [sourceName, setSourceName] = useState<string>("Ma Source")
  const [sourceTags, setSourceTags] = useState<string[]>([])
  const [activitySize, setActivitySize] = useLocalStorage<number>("source-import-form-activity-size", 144)
  const [interpolationMethod, setInterpolationMethod] = useLocalStorage<string>(
    "source-import-form--interpolation-method",
    "zero",
  )
  const [dateFormat, setDateFormat] = useLocalStorage<string>("source-import-form-date-format", "YYYY-MM-DDTHH:mm:ss")
  const [numberLocale, setNumberLocale] = useLocalStorage<string>("source-import-form-number-locale", "fr")
  const [separator, setSeparator] = useLocalStorage<string>("source-import-form-separator", ";")
  const [quote, setQuote] = useLocalStorage<string>("source-import-form-quote", '"')
  const apiExceptionHandler = useApiExceptionHandler()
  const history = useHistory()
  const { t } = useTranslation()
  const { jwt } = useAuth()
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Fichier importé avec succès",
  })
  const responseKo = response && Array.isArray(response) ? response.filter((r) => !r.ok).length : 0

  return (
    <PageContainer title="Ma première analyse">
      <AppHeader color={true} openable={true} />
      <SideMenu />
      <PageContent padding={true}>
        <div style={{ maxWidth: 900, margin: "auto" }}>
          <SuccessMessage />
          {loading ? (
            <LoaderBlock
              message={
                file && !response
                  ? "Validation du format du fichier en cours"
                  : file && response
                  ? "Import de données et analyse en cours"
                  : undefined
              }
            />
          ) : (
            <>
              {!response && (
                <PageBlock title={`Créer ${t("source.onename")} en important un fichier de données`}>
                  <div style={{ maxWidth: "600px", margin: "auto", marginBottom: "2em" }}>
                    <p className={classes.text}>
                      <strong>1 Import:</strong> La boîte de téléchargement ci-dessous vous permet de transmettre un
                      premier fichier de données. Pour commencer, utilisez un fichier en récupérant le fichier template
                      ci-dessous.
                    </p>
                    <p className={classes.text}>
                      <strong>2 Validation:</strong> Une fois votre fichier de données uploadé, nous ferons une première
                      vérification sur son contenu. Cette validation technique des données nous permet d'identifier les
                      problèmes de format avant de lancer l'analyse.
                    </p>
                    <p className={classes.text}>
                      <strong>3 Analyse:</strong> Une fois votre fichier validé, nous lancerons l'analyse{" "}
                      {t("global.appTitle")}. Cette première analyse sera effectuée sur la première année de données
                      présente dans le fichier afin de vous donner un apperçu de notre analyse intelligente des données
                    </p>
                    <strong className={classes.inputContainer}>Configuration du fichier d'import</strong>
                    <div className={classes.inputContainer}>
                      <span style={{ marginRight: "1em" }}>Format de date</span>
                      <ListSelect
                        placeholder="Format de date"
                        items={dateFormatOptions}
                        getLabel={(o) => o.label}
                        getValue={(o) => o.value}
                        onSelect={(o) => setDateFormat(o.value)}
                        value={dateFormatOptions.filter((o) => o.value === dateFormat)[0]}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      <span style={{ marginRight: "1em" }}>Format de valeur</span>
                      <ListSelect
                        placeholder="Format de valeur"
                        items={numberLocaleOptions}
                        getLabel={(o) => o.label}
                        getValue={(o) => o.value}
                        onSelect={(o) => setNumberLocale(o.value)}
                        value={numberLocaleOptions.filter((o) => o.value === numberLocale)[0]}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      <span style={{ marginRight: "1em" }}>Séparateur de champ</span>
                      <ListSelect
                        placeholder="Séparateur de champ"
                        items={separatorOptions}
                        getLabel={(o) => o.label}
                        getValue={(o) => o.value}
                        onSelect={(o) => setSeparator(o.value)}
                        value={separatorOptions.filter((o) => o.value === separator)[0]}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      <span style={{ marginRight: "1em" }}>Échappement de valeur</span>
                      <ListSelect
                        placeholder="Échappement de valeur"
                        items={quoteOptions}
                        getLabel={(o) => o.label}
                        getValue={(o) => o.value}
                        onSelect={(o) => setQuote(o.value)}
                        value={quoteOptions.filter((o) => o.value === quote)[0]}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end" }}>
                    <AppButton
                      color="primary"
                      variant="outlined"
                      submitting={generatingTemplate}
                      onClick={async () => {
                        setGeneratingTemplate(true)
                        const dataCreate: CreateSourceDto = await (
                          await fetch("/data/source_interieur.create-source.json")
                        ).json()
                        const csvString = arrayToCsvString(
                          lodash.flatten(
                            dataCreate.days.slice(0, 300).map((d) =>
                              d.activity.map((n, i) => [
                                formatDayWithMoment(
                                  d.date +
                                    "T" +
                                    Math.floor(i / 6)
                                      .toString()
                                      .padStart(2, "0") +
                                    ":" +
                                    ((i % 6) * 10).toString().padStart(2, "0") +
                                    ":00",
                                  dateFormat,
                                ),
                                formatNumber(n, false, numberLocale as "fr" | "en", 2, null),
                              ]),
                            ),
                          ),
                          {
                            separator,
                            quote,
                            header: ["date", "activity"],
                          },
                        )
                        downloadStringAsFile(csvString, {
                          filename: "template_create_source.csv",
                          mimeType: "text/csv",
                        })
                        setGeneratingTemplate(false)
                      }}
                    >
                      <DlIcon style={{ marginRight: 10 }} />
                      Télécharger le template
                    </AppButton>
                    <Input
                      disabled={loading}
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                      inputProps={{
                        multiple: false,
                        accept: "text/csv",
                      }}
                      onChange={async (evt) => {
                        // @ts-ignore
                        const inputFile = evt.target.files.length > 0 ? evt.target.files[0] : null
                        setFile(inputFile)
                        setLoading(true)
                        try {
                          const res = await uploadFile(inputFile, {
                            jwt,
                            url:
                              API_URL +
                              `/v1/import-csv/validate-csv?` +
                              objectToQueryParams({
                                dateFormat,
                                numberLocale,
                                quote,
                                separator,
                              }),
                          })
                          setResponse(res)
                          if (inputFile.name) {
                            setSourceName(inputFile.name)
                          }
                        } catch (e) {
                          apiExceptionHandler(e)
                          throw e
                        } finally {
                          setLoading(false)
                        }
                      }}
                    />
                    <label htmlFor="raised-button-file">
                      <AppButton
                        style={{ marginLeft: "1em" }}
                        submitting={loading}
                        color="primary"
                        variant="contained"
                        component="span"
                        startIcon={loading ? <Loader /> : undefined}
                      >
                        Charger un fichier de données au format CSV
                      </AppButton>
                    </label>
                  </div>
                </PageBlock>
              )}

              {response &&
                (!serverError ? (
                  <PageBlock titleInContent={true}>
                    {responseKo > 0 ? (
                      <div>
                        <ResponseErrorsTable rows={response.filter((row) => !row.ok)} />
                        <p className={classes.text}>
                          Nous avons détecté {responseKo} erreurs parmi les {response.length} lignes de votre fichier.
                          Vous pouvez poursuivre l'analyse en ignorant les lignes en erreur.
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{
                          maxWidth: "600px",
                          margin: "auto",
                          marginBottom: "2em",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.okIcon}>
                          <OkIcon />
                        </div>
                        <p className={classes.text}>
                          Le fichier est entièrement valide ! Vous pouvez lancer l'analyse {t("global.appTitle")}. Cette
                          analyse peut prendre jusqu'à 1m30.
                        </p>
                        <strong className={classes.inputContainer}>Configuration {t("source.aname")}</strong>

                        <div className={classes.inputContainer}>
                          <TextField
                            style={{ flexGrow: 1 }}
                            label={`Nom ${t("source.aname")}`}
                            variant="outlined"
                            value={sourceName}
                            margin="normal"
                            required
                            name="name"
                            onChange={(v) => setSourceName(v.target.value)}
                          />
                        </div>
                        <div className={classes.inputContainer}>
                          <span style={{ marginRight: "1em" }}>
                            Taille de l'échantillonnage de données à utiliser pour l'analyse{" "}
                          </span>
                          <ListSelect
                            placeholder="Taille de l'échantillonnage de données à utiliser pour l'analyse"
                            helpText="Un échantillonnage plus fin sera meilleur pour détecter les petits écarts, mais plus lent à calculer et donc moins réactif"
                            items={activitySizeOptions}
                            getLabel={(o) => o.label}
                            getValue={(o) => o.value}
                            onSelect={(o) => setActivitySize(o.value)}
                            value={activitySizeOptions.filter((o) => o.value === activitySize)[0]}
                          />
                        </div>
                        <div className={classes.inputContainer}>
                          <span style={{ marginRight: "1em" }}>Interpolation des données manquantes</span>
                          <ListSelect
                            placeholder="Méthode d'interpolation"
                            helpText="En cas de données manquantes, nous utiliserons cette méthode pour combler les trou de données"
                            items={interpolationMethodOptions}
                            getLabel={(o) => o.label}
                            getValue={(o) => o.value}
                            onSelect={(o) => setInterpolationMethod(o.value)}
                            value={interpolationMethodOptions.filter((o) => o.value === interpolationMethod)[0]}
                          />
                        </div>

                        <div className={classes.inputContainer}>
                          <span style={{ marginRight: "1em" }}>Tags</span>
                          <SelectCreatable
                            value={(sourceTags || []).map((tt) => ({ value: tt, label: tt }))}
                            placeholder="Créez et associez un ou plusieurs tags aux sources créées"
                            isMulti
                            isClearable
                            options={[]}
                            onChange={(val) => {
                              if (!val) {
                                val = []
                              }
                              if (!Array.isArray(val)) {
                                throw new Error("Multi-value expected")
                              }
                              setSourceTags(val.map((v) => v.value))
                            }}
                            name="tags"
                          />
                          <FormHelperText>
                            Tagguez les sources créées pour les regrouper et les retrouver facilement dans les autres
                            écrans {t("global.appTitle")}
                          </FormHelperText>
                        </div>
                      </div>
                    )}
                    {userError && (
                      <FormHelperText error={true} style={{ fontSize: "1em", width: "100%", textAlign: "center" }}>
                        {userError}
                      </FormHelperText>
                    )}

                    <div
                      style={{
                        marginTop: "1em",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <AppButton
                        onClick={async () => {
                          setResponse(null)
                          setUserError(null)
                        }}
                        style={{ marginRight: "1em" }}
                        variant="outlined"
                        color="primary"
                      >
                        Annuler et modifier le fichier
                      </AppButton>
                      <AppButton
                        submitting={loading}
                        onClick={async () => {
                          if (!file) {
                            alert("File is not defined")
                            return
                          }
                          setLoading(true)
                          try {
                            const res = await uploadFile(file, {
                              jwt,
                              url:
                                API_URL +
                                `/v1/import-csv/create-source?` +
                                objectToQueryParams({
                                  sourceName,
                                  activitySize,
                                  interpolationMethod,
                                  dateFormat,
                                  numberLocale,
                                  quote,
                                  separator,
                                  onErrorStop: false,
                                  tags: sourceTags,
                                }),
                            })
                            if (res.statusCode && res.statusCode === 429) {
                              apiExceptionHandler(res)
                              setLoading(false)
                            } else if (res.statusCode && res.statusCode === 400) {
                              const userErr = extractConstraints(res)
                              if (userErr !== null) {
                                setUserError(Object.values(userErr).join(". "))
                              } else {
                                setServerError(true)
                              }

                              setLoading(false)
                            } else if (res.statusCode && res.statusCode === 500) {
                              setServerError(true)
                              setLoading(false)
                            } else {
                              showSuccessMessage()
                              sourceAdded()
                              setTimeout(() => {
                                history.push(
                                  buildUrl({
                                    route: AppRoute.DATA_SOURCE_EXPLORE,
                                    params: { sourceId: res.source.id + "" },
                                  }),
                                )
                              }, 1000)
                            }
                          } catch (e) {
                            setLoading(false)
                          }
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Lancer l'analyse {t("global.appTitle")}
                        {responseKo > 0 && <> en ignorant les {responseKo} erreurs</>}
                      </AppButton>
                    </div>
                  </PageBlock>
                ) : (
                  <ImportErrorBlock
                    onBack={() => {
                      setResponse(null)
                      setServerError(false)
                    }}
                  />
                ))}
            </>
          )}
        </div>
      </PageContent>
    </PageContainer>
  )
}

const ResponseErrorsTable = React.memo(function ResponseErrorsTableComponent({ rows }: { rows: ImportResponseRow[] }) {
  const { columns } = extractColumnConfigAndExportConfig<ImportResponseRow>([
    createNumericColumnConfig({
      title: "Ligne",
      getValue: (row) => row.lineNum,
      isInt: true,
    }),
    createTextColumnConfig({
      title: "Date",
      getTextValue: (row) => (row.row.date ? row.row.date : ""),
    }),
    createTextColumnConfig({
      title: "Activité",
      getTextValue: (row) =>
        (row.row.data && Array.isArray(row.row.data) && row.row.data.length > 0 && typeof row.row.data[0] === "number"
          ? row.row.data[0].toFixed(4)
          : "") + "",
    }),
    createTextEnumColumnConfig({
      title: "Validation",
      render: (row) => {
        return row.ok ? (
          <GridHighlight color={"#369763"}>Ok</GridHighlight>
        ) : (
          <GridHighlight color={"#c44e47"}>KO</GridHighlight>
        )
      },
      getTextValue: (row) => {
        return row.ok ? "Ok" : "KO"
      },
      enumValues: ["Ok", "KO"],
    }),
    createTextColumnConfig({
      title: "Message",
      getTextValue: (row) => row.errors.join(". "),
    }),
  ])

  return (
    <MaterialTable<ImportResponseRow>
      data={rows}
      title={<Title>Nous avons détecté des erreurs dans le fichier</Title>}
      columns={columns}
      options={{
        pageSize: 20,
        selection: false,
        search: false,
        filtering: false,
        exportButton: false,
      }}
    />
  )
})
