/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  DayTrendTypeEnum,
  DayTrendTypeEnumFromJSON,
  DayTrendTypeEnumFromJSONTyped,
  DayTrendTypeEnumToJSON,
  DayTrendUnitEnum,
  DayTrendUnitEnumFromJSON,
  DayTrendUnitEnumFromJSONTyped,
  DayTrendUnitEnumToJSON,
} from "./"

/**
 *
 * @export
 * @interface DayTrend
 */
export interface DayTrend {
  /**
   *
   * @type {number}
   * @memberof DayTrend
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof DayTrend
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof DayTrend
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof DayTrend
   */
  readonly companyId: number
  /**
   *
   * @type {number}
   * @memberof DayTrend
   */
  sourceId: number
  /**
   *
   * @type {string}
   * @memberof DayTrend
   */
  startDayDate: string
  /**
   *
   * @type {string}
   * @memberof DayTrend
   */
  stopDayDate: string | null
  /**
   *
   * @type {DayTrendUnitEnum}
   * @memberof DayTrend
   */
  unit: DayTrendUnitEnum
  /**
   *
   * @type {DayTrendTypeEnum}
   * @memberof DayTrend
   */
  type: DayTrendTypeEnum
  /**
   *
   * @type {number}
   * @memberof DayTrend
   */
  value: number
}

export function DayTrendFromJSON(json: any): DayTrend {
  return DayTrendFromJSONTyped(json, false)
}

export function DayTrendFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayTrend {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    companyId: json["companyId"],
    sourceId: json["sourceId"],
    startDayDate: json["startDayDate"],
    stopDayDate: json["stopDayDate"],
    unit: DayTrendUnitEnumFromJSON(json["unit"]),
    type: DayTrendTypeEnumFromJSON(json["type"]),
    value: json["value"],
  }
}

export function DayTrendToJSON(value?: DayTrend | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    startDayDate: value.startDayDate,
    stopDayDate: value.stopDayDate,
    unit: DayTrendUnitEnumToJSON(value.unit),
    type: DayTrendTypeEnumToJSON(value.type),
    value: value.value,
  }
}
