/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface FetchSerieDataCompactResult
 */
export interface FetchSerieDataCompactResult {
  /**
   * The serie identifier.
   * @type {number}
   * @memberof FetchSerieDataCompactResult
   */
  serieId: number
  /**
   * The serie metrics. As: [ metric 1 name, metric 2 name, ..., metric 3 name ]
   * @type {Array<string>}
   * @memberof FetchSerieDataCompactResult
   */
  metrics: Array<string>
  /**
   * The data. Each row is in format:        [ timestamp, metric 1 data, metric 2 data, ..., metric N data ]
   * @type {Array<Array<number>>}
   * @memberof FetchSerieDataCompactResult
   */
  data: Array<Array<number>>
  /**
   * The serie metadata
   * @type {object}
   * @memberof FetchSerieDataCompactResult
   */
  serieTags: object
}

export function FetchSerieDataCompactResultFromJSON(json: any): FetchSerieDataCompactResult {
  return FetchSerieDataCompactResultFromJSONTyped(json, false)
}

export function FetchSerieDataCompactResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FetchSerieDataCompactResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    serieId: json["serieId"],
    metrics: json["metrics"],
    data: json["data"],
    serieTags: json["serieTags"],
  }
}

export function FetchSerieDataCompactResultToJSON(value?: FetchSerieDataCompactResult | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    serieId: value.serieId,
    metrics: value.metrics,
    data: value.data,
    serieTags: value.serieTags,
  }
}
