/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum AlertActivityEnum {
  Over = "over",
  Under = "under",
}

export function AlertActivityEnumFromJSON(json: any): AlertActivityEnum {
  return AlertActivityEnumFromJSONTyped(json, false)
}

export function AlertActivityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertActivityEnum {
  return json as AlertActivityEnum
}

export function AlertActivityEnumToJSON(value?: AlertActivityEnum | null): any {
  return value as any
}
