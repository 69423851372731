import React from "react"
import PageBlock from "./PageBlock"
import { AppRoute, AppRouteParams } from "../state/route"
import { ButtonWithConfirmDialogProps, ButtonWithConfirmDialog } from "./ButtonWithConfirmDialog"
import { AppButton } from "./AppButton"
import { useHistory } from "react-router"
import { buildUrl } from "./AppLink"

export function TaskActionsBlock({
  redirectToOnCopy,
  redirectToOnUpdate,
  deleteProps,
  updateProps,
}: {
  deleteProps: ButtonWithConfirmDialogProps
  updateProps: ButtonWithConfirmDialogProps
  redirectToOnUpdate?: { route: AppRoute; params?: AppRouteParams }
  redirectToOnCopy: { route: AppRoute; params?: AppRouteParams }
}) {
  const history = useHistory()
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <div style={{ maxWidth: updateProps ? "700px" : "500px" }}>
        <PageBlock alert={false} title="Actions">
          <div style={{ display: "flex" }}>
            <ButtonWithConfirmDialog {...deleteProps} />
            <div style={{ marginLeft: "2em" }}>
              <ButtonWithConfirmDialog {...updateProps} />
            </div>
            <AppButton
              onClick={() => {
                setTimeout(() => {
                  history.push(buildUrl(redirectToOnCopy))
                })
              }}
              style={{ marginLeft: "2em" }}
              variant="contained"
              color="primary"
            >
              Copier dans un nouveau projet
            </AppButton>
          </div>
        </PageBlock>
      </div>
    </div>
  )
}
