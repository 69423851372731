/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  DayContextTypes,
  DayContextTypesFromJSON,
  DayContextTypesFromJSONTyped,
  DayContextTypesToJSON,
  ForecastType,
  ForecastTypeFromJSON,
  ForecastTypeFromJSONTyped,
  ForecastTypeToJSON,
} from "./"

/**
 *
 * @export
 * @interface ForecastTaskInputParams
 */
export interface ForecastTaskInputParams {
  /**
   *
   * @type {number}
   * @memberof ForecastTaskInputParams
   */
  sourceId: number
  /**
   *
   * @type {ForecastType}
   * @memberof ForecastTaskInputParams
   */
  forecastType: ForecastType
  /**
   *
   * @type {string}
   * @memberof ForecastTaskInputParams
   */
  title: string
  /**
   *
   * @type {Date}
   * @memberof ForecastTaskInputParams
   */
  trainBegin: Date
  /**
   *
   * @type {Date}
   * @memberof ForecastTaskInputParams
   */
  trainEnd: Date
  /**
   *
   * @type {Date}
   * @memberof ForecastTaskInputParams
   */
  forecastBegin: Date
  /**
   *
   * @type {Date}
   * @memberof ForecastTaskInputParams
   */
  forecastEnd: Date
  /**
   *
   * @type {Array<DayContextTypes>}
   * @memberof ForecastTaskInputParams
   */
  forecastContextTypes: Array<DayContextTypes>
  /**
   *
   * @type {Array<number>}
   * @memberof ForecastTaskInputParams
   */
  forecastContextSourceIds: Array<number>
}

export function ForecastTaskInputParamsFromJSON(json: any): ForecastTaskInputParams {
  return ForecastTaskInputParamsFromJSONTyped(json, false)
}

export function ForecastTaskInputParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForecastTaskInputParams {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sourceId: json["sourceId"],
    forecastType: ForecastTypeFromJSON(json["forecastType"]),
    title: json["title"],
    trainBegin: new Date(json["trainBegin"]),
    trainEnd: new Date(json["trainEnd"]),
    forecastBegin: new Date(json["forecastBegin"]),
    forecastEnd: new Date(json["forecastEnd"]),
    forecastContextTypes: (json["forecastContextTypes"] as Array<any>).map(DayContextTypesFromJSON),
    forecastContextSourceIds: json["forecastContextSourceIds"],
  }
}

export function ForecastTaskInputParamsToJSON(value?: ForecastTaskInputParams | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    forecastType: ForecastTypeToJSON(value.forecastType),
    title: value.title,
    trainBegin: value.trainBegin.toISOString(),
    trainEnd: value.trainEnd.toISOString(),
    forecastBegin: value.forecastBegin.toISOString(),
    forecastEnd: value.forecastEnd.toISOString(),
    forecastContextTypes: (value.forecastContextTypes as Array<any>).map(DayContextTypesToJSON),
    forecastContextSourceIds: value.forecastContextSourceIds,
  }
}
