/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  SourceClusterDistance,
  SourceClusterDistanceFromJSON,
  SourceClusterDistanceFromJSONTyped,
  SourceClusterDistanceToJSON,
  TaskStatus,
  TaskStatusFromJSON,
  TaskStatusFromJSONTyped,
  TaskStatusToJSON,
} from "./"

/**
 *
 * @export
 * @interface SourceClusterTask
 */
export interface SourceClusterTask {
  /**
   *
   * @type {number}
   * @memberof SourceClusterTask
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof SourceClusterTask
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof SourceClusterTask
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof SourceClusterTask
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof SourceClusterTask
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof SourceClusterTask
   */
  readonly companyId: number
  /**
   *
   * @type {string}
   * @memberof SourceClusterTask
   */
  taskType: string
  /**
   *
   * @type {TaskStatus}
   * @memberof SourceClusterTask
   */
  status: TaskStatus
  /**
   *
   * @type {Date}
   * @memberof SourceClusterTask
   */
  readonly startDate: Date | null
  /**
   *
   * @type {Date}
   * @memberof SourceClusterTask
   */
  readonly endDate: Date | null
  /**
   *
   * @type {number}
   * @memberof SourceClusterTask
   */
  readonly durationMs: number | null
  /**
   *
   * @type {string}
   * @memberof SourceClusterTask
   */
  error: string | null
  /**
   *
   * @type {string}
   * @memberof SourceClusterTask
   */
  title: string
  /**
   *
   * @type {Array<number>}
   * @memberof SourceClusterTask
   */
  sourceIds: Array<number>
  /**
   *
   * @type {SourceClusterDistance}
   * @memberof SourceClusterTask
   */
  distance: SourceClusterDistance
  /**
   * Number of Clusters
   * @type {number}
   * @memberof SourceClusterTask
   */
  modelCount: number | null
  /**
   *
   * @type {number}
   * @memberof SourceClusterTask
   */
  anomalyCount: number | null
  /**
   *
   * @type {Array<number>}
   * @memberof SourceClusterTask
   */
  serieIds: Array<number>
}

export function SourceClusterTaskFromJSON(json: any): SourceClusterTask {
  return SourceClusterTaskFromJSONTyped(json, false)
}

export function SourceClusterTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceClusterTask {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    taskType: json["taskType"],
    status: TaskStatusFromJSON(json["status"]),
    startDate: json["startDate"] === null ? null : new Date(json["startDate"]),
    endDate: json["endDate"] === null ? null : new Date(json["endDate"]),
    durationMs: json["durationMs"],
    error: json["error"],
    title: json["title"],
    sourceIds: json["sourceIds"],
    distance: SourceClusterDistanceFromJSON(json["distance"]),
    modelCount: json["modelCount"],
    anomalyCount: json["anomalyCount"],
    serieIds: json["serieIds"],
  }
}

export function SourceClusterTaskToJSON(value?: SourceClusterTask | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    taskType: value.taskType,
    status: TaskStatusToJSON(value.status),
    error: value.error,
    title: value.title,
    sourceIds: value.sourceIds,
    distance: SourceClusterDistanceToJSON(value.distance),
    modelCount: value.modelCount,
    anomalyCount: value.anomalyCount,
    serieIds: value.serieIds,
  }
}
