/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface PrevisionPatchDto
 */
export interface PrevisionPatchDto {
  /**
   * The source id of the prevision to update
   * @type {number}
   * @memberof PrevisionPatchDto
   */
  sourceId: number
  /**
   * The prevision date with a format YYYY-MM-DD
   * @type {string}
   * @memberof PrevisionPatchDto
   */
  date: string
  /**
   * New model id to set on the prevision
   * @type {number}
   * @memberof PrevisionPatchDto
   */
  modelId: number
}

export function PrevisionPatchDtoFromJSON(json: any): PrevisionPatchDto {
  return PrevisionPatchDtoFromJSONTyped(json, false)
}

export function PrevisionPatchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrevisionPatchDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sourceId: json["sourceId"],
    date: json["date"],
    modelId: json["modelId"],
  }
}

export function PrevisionPatchDtoToJSON(value?: PrevisionPatchDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    date: value.date,
    modelId: value.modelId,
  }
}
