import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { AnyChildren } from "../react-type-helpers"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}))

export default function PageContainer({ title, children }: { title: string; children: AnyChildren }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t("global.appTitleShort") + " - " + title}</title>
      </Helmet>
      <div className={classes.root}>{children}</div>
    </>
  )
}
