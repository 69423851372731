import { DayContextTypes } from "../../api/timelight-api/models/DayContextTypes"

export const dayContextTypeValues: DayContextTypes[] = Object.values(DayContextTypes)

const typeTextMap: { [key: string]: string } = {
  dju: "Degré Jour Unifié (DJU)",
  temp_max_deg: "Température maximale",
  temp_avg_deg: "Température moyenne",
  temp_min_deg: "Température minimale",
  wind_speed_kmh: "Vitesse du vent",
  wind_temp_deg: "Température du vent",
  rain_mm: "Précipitations",
  humidity_perc: "Humidité",
  visibility_km: "Visibilité",
  cloud_cover_perc: "Couverture nuageuse",
  heat_idx_unit: "Indice de chaleur",
  dew_point_deg: "Point de rosée",
  pressure_hpa: "Pression",
  sunrise_sec: "Heure du lever du soleil",
  sunset_sec: "Heure du coucher du soleil",
  day_length_sec: "Durée du jour",
  historique_meteo_enum: "L'avis de historique-meteo.net",
}
export function contextTypeToText(type: DayContextTypes): string {
  return typeTextMap[type] || type + ""
}
function createFormatter({ precision, unit }: { precision: number; unit: string }) {
  return (value: number | null) => (value === null ? "N/A" : value.toFixed(precision) + unit)
}
function durationFormatter(value: number | null) {
  if (value === null) {
    return "N/A"
  }
  const h = Math.floor(value / (60 * 60))
  const m = Math.floor((value - h * 60 * 60) / 60)
  const s = Math.floor(value - h * 60 * 60 - m * 60)
  return h.toString().padStart(2, "0") + ":" + m.toString().padStart(2, "0") + ":" + s.toString().padStart(2, "0")
}
function historiqueMeteoEnumFormatter(value: number | null) {
  if (value === null) {
    return "N/A"
  }
  const map: any = { "-2": "très défavorable", "-1": "défavorable", 0: "correct", 1: "favorable", 2: "idéal" }
  return map[value]
}
const typeValueFormatters: { [key: string]: (value: number | null) => string } = {
  dju: createFormatter({ precision: 0, unit: "" }),
  temp_max_deg: createFormatter({ precision: 0, unit: "°" }),
  temp_avg_deg: createFormatter({ precision: 0, unit: "°" }),
  temp_min_deg: createFormatter({ precision: 0, unit: "°" }),
  wind_speed_kmh: createFormatter({ precision: 0, unit: " km/h" }),
  wind_temp_deg: createFormatter({ precision: 0, unit: "°" }),
  rain_mm: createFormatter({ precision: 0, unit: "mm" }),
  humidity_perc: createFormatter({ precision: 0, unit: "%" }),
  visibility_km: createFormatter({ precision: 0, unit: " km" }),
  cloud_cover_perc: createFormatter({ precision: 0, unit: "%" }),
  heat_idx_unit: createFormatter({ precision: 0, unit: "" }),
  dew_point_deg: createFormatter({ precision: 0, unit: "°" }),
  pressure_hpa: createFormatter({ precision: 0, unit: " hPa" }),
  sunrise_sec: durationFormatter,
  sunset_sec: durationFormatter,
  day_length_sec: durationFormatter,
  historique_meteo_enum: historiqueMeteoEnumFormatter,
}

export function contextValueToText({ ctxType, value }: { ctxType: DayContextTypes; value: number | null }) {
  const formatter = typeValueFormatters[ctxType] || ((v: number | null) => v)
  return formatter(value)
}
