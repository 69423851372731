import { Tooltip, CardActionArea } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import { makeStyles, Theme } from "@material-ui/core/styles"
import TaskListCheckIcon from "@material-ui/icons/AssignmentTurnedIn"
import BuildIcon from "@material-ui/icons/Build"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import DateRangeIcon from "@material-ui/icons/DateRange"
import FilterList from "@material-ui/icons/FilterList"
import HistoryIcon from "@material-ui/icons/History"
import ArrowIcon from "@material-ui/icons/KeyboardArrowRight"
import NotificationIcon from "@material-ui/icons/NotificationImportant"
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye"
import CompareArrows from "@material-ui/icons/CompareArrows"
import GroupPrevisionIcon from "@material-ui/icons/Widgets"
import SourceCreateIcon from "@material-ui/icons/PlaylistAdd"
import clsx from "clsx"
import React from "react"
import SourceListIcon from "@material-ui/icons/DataUsage"
import { useSideMenuToggle } from "state/side-menu"
import { AnyChildren } from "../react-type-helpers"
import { AppRoute, useAppRoute } from "../state/route"
import AppLink from "./AppLink"
import Logo from "./Logo"
import SourceAddIcon from "@material-ui/icons/Add"
import { useTranslation } from "react-i18next"
import ListSelect from "./ListSelect"
import { useHistory } from "react-router"
import { buildUrl } from "./AppLink"
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined"
import TrackChangesOutlinedIcon from "@material-ui/icons/TrackChangesOutlined"
import ForecastIcon from "@material-ui/icons/Timeline"
import VerifiedUser from "@material-ui/icons/VerifiedUser"
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined"
import AccountTreeRoundedIcon from "@material-ui/icons/AccountTreeRounded"
import { stepperTheme } from "../theme"
import BubbleChart from "@material-ui/icons/BubbleChart"
import { useOnboardingState } from "../state/onboarding"

type Step = keyof typeof stepperTheme
export const drawerWidth = 240

function TooltipIfClosed({ open, title, children }: { open: boolean; title: string; children: AnyChildren }) {
  return open ? (
    <>{children}</>
  ) : (
    <Tooltip title={title} placement="right">
      <span>{children}</span>
    </Tooltip>
  )
}

const useStyles = makeStyles<Theme, { open: boolean }>((theme) => {
  return {
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      overflowY: "visible",
    },
    drawerPaperClose: {
      overflowX: "hidden",
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    inactiveMenu: {
      color: "#6f6f6f",
    },
    activeMenu: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    activeMenuIcon: {
      color: theme.palette.primary.main,
    },
    inactiveMenuIcon: {
      opacity: 0.7,
    },
    menuIcon: (props) => ({
      marginLeft: !props.open ? theme.spacing(1) : undefined,
      minWidth: props.open ? "30px" : undefined,
    }),
    menuText: {
      fontWeight: "inherit",
      whiteSpace: "normal",
      display: (props) => (props.open ? "block" : "none"),
    },
    subHeader: (props) => ({
      padding: !props.open ? 0 : undefined,
      textAlign: !props.open ? "center" : undefined,
    }),
    activeSubHeader: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    inactiveSubHeader: {
      color: "#5d5d5d",
    },
    titleIcon: {
      display: "flex",
      alignItems: "center",
    },
    menuIconContainer: {
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: "solid",
    },
    menuItemContainers: {
      maxHeight: "calc(100vh - 80px)", // full height minus top header height
      overflowX: "auto",
    },
    cardContent: {
      display: "flex",
      // justifyContent: "center",
      // padding: theme.spacing(4),
      color: theme.palette.primary.main,
      height: "100%",
      alignItems: "center",
      paddingLeft: "1em",
    },
    toolbarIconLogo: {
      height: "100%",
      width: "100%",
    },
  }
})

function SideMenuItem({
  title,
  open,
  itemRoutes,
  currentRoute,
  icon,
  disabled = false,
}: {
  title: string
  open: boolean
  itemRoutes: AppRoute[]
  currentRoute: AppRoute
  icon: AnyChildren
  disabled?: boolean
}) {
  const classes = useStyles({ open })
  const isCurrentItem = itemRoutes.indexOf(currentRoute) !== -1
  const content = (
    <ListItem disabled={disabled} button className={clsx(classes.inactiveMenu, isCurrentItem && classes.activeMenu)}>
      <TooltipIfClosed open={open} title={title}>
        <ListItemIcon
          className={clsx(classes.menuIcon, isCurrentItem ? classes.activeMenuIcon : classes.inactiveMenuIcon)}
        >
          <span className={classes.menuIconContainer} style={{ borderBottomWidth: isCurrentItem ? "1px" : "0px" }}>
            {icon}
          </span>
        </ListItemIcon>
      </TooltipIfClosed>
      <ListItemText primary={title} primaryTypographyProps={{ className: classes.menuText }} />
    </ListItem>
  )
  return disabled ? <>{content}</> : <AppLink route={itemRoutes[0]}>{content}</AppLink>
}

function SideMenuHeader({
  title,
  shortTitle,
  open,
  itemRoutes,
  currentRoute,
}: {
  title: string
  shortTitle: string
  open: boolean
  itemRoutes: AppRoute[]
  currentRoute: AppRoute
}) {
  const classes = useStyles({ open })
  const isCurrentItem = itemRoutes.indexOf(currentRoute) !== -1

  return (
    <ListSubheader
      className={clsx(classes.subHeader, isCurrentItem ? classes.activeSubHeader : classes.inactiveSubHeader)}
    >
      {open ? (
        isCurrentItem ? (
          <span className={classes.titleIcon}>
            <ArrowIcon />
            <span>{title}</span>
          </span>
        ) : (
          title
        )
      ) : (
        shortTitle
      )}
    </ListSubheader>
  )
}

export default function SideMenu() {
  const { open, doOpen, doClose } = useSideMenuToggle()
  const classes = useStyles({ open })
  const { route } = useAppRoute()
  const history = useHistory()
  const { t } = useTranslation()
  const { hasSource } = useOnboardingState()

  // we are on the onboarding state, force no side menu
  if (!hasSource) {
    return <></>
  }
  const routesByModule = {
    data: [
      AppRoute.DATA_SOURCE_TIMELIGHT_LIST,
      AppRoute.DATA_SOURCE_TIMELIGHT_EXPLORE,
      AppRoute.DATA_SOURCE_CONFIG,
      AppRoute.SOURCE_ADD_DAYS_HUB,
      AppRoute.SOURCE_ADD_DAYS,
      AppRoute.SOURCE_CREATE,
      AppRoute.SOURCE_CREATE_EXAMPLE,
      AppRoute.SOURCE_CREATE_HUB,
      AppRoute.DATA_SOURCE_LIST,
      AppRoute.DATA_SOURCE_EXPLORE,
      AppRoute.SOURCE_IMPORT_HISTORY,
    ],
    profiles: [
      AppRoute.MONITOR_ACTIVITY,
      AppRoute.HOME,
      AppRoute.MONITOR_ALERTS,
      AppRoute.INVENTORY_ALERTS,
      AppRoute.DATA_SOURCE_HISTORY,
      AppRoute.DATA_SOURCE_ANOMALY,
      AppRoute.DATA_SOURCE_MODELS,
      AppRoute.PREVISION_SOURCE,
      AppRoute.PREVISION_GROUPS,
    ],
    forecast: [
      AppRoute.FORECAST_LIST,
      AppRoute.FORECAST_TASK_CREATE,
      AppRoute.FORECAST_TASK_COPY,
      AppRoute.FORECAST_TASK_UPDATE,
      AppRoute.FORECAST_TASK_VIEW,
    ],
    contextImpact: [
      AppRoute.CONTEXT_IMPACT_LIST,
      AppRoute.CONTEXT_IMPACT_TASK_CREATE,
      AppRoute.CONTEXT_IMPACT_TASK_COPY,
      AppRoute.CONTEXT_IMPACT_TASK_UPDATE,
      AppRoute.CONTEXT_IMPACT_TASK_VIEW,
    ],
    serieSync: [
      AppRoute.SERIE_SYNC_LIST,
      AppRoute.SERIE_SYNC_TASK_CREATE,
      AppRoute.SERIE_SYNC_TASK_COPY,
      AppRoute.SERIE_SYNC_TASK_UPDATE,
      AppRoute.SERIE_SYNC_TASK_VIEW,
    ],
    sourceCluster: [
      AppRoute.SOURCE_CLUSTER_LIST,
      AppRoute.SOURCE_CLUSTER_TASK_CREATE,
      AppRoute.SOURCE_CLUSTER_TASK_COPY,
      AppRoute.SOURCE_CLUSTER_TASK_UPDATE,
      AppRoute.SOURCE_CLUSTER_TASK_VIEW,
    ],
  }

  let currentModule: "data" | "profiles" | "forecast" | "contextImpact" | "serieSync" | "sourceCluster" | "none" =
    "none"
  for (const [moduleKey, moduleRoutes] of Object.entries(routesByModule)) {
    if (moduleRoutes.includes(route)) {
      currentModule = moduleKey as any
      break
    }
  }

  const moduleSelectOptions: {
    value: "data" | "profiles" | "forecast" | "contextImpact" | "serieSync" | "sourceCluster"
    label: string
    route: AppRoute
    icon: AnyChildren
    step: Step
  }[] = [
    { value: "data", label: "Timelight DATA", route: AppRoute.DATA_SOURCE_LIST, icon: <VerifiedUser />, step: "step1" },
    {
      value: "profiles",
      label: "Modélisation par profil type",
      route: AppRoute.MONITOR_ACTIVITY,
      icon: <GroupWorkOutlinedIcon />,
      step: "step2",
    },
    {
      value: "forecast",
      label: "Modélisation Statistique",
      route: AppRoute.FORECAST_LIST,
      icon: <ForecastIcon />,
      step: "step3",
    },
    {
      value: "contextImpact",
      label: "[BETA] Analyse de données contextuelles",
      route: AppRoute.CONTEXT_IMPACT_LIST,
      icon: <TrackChangesOutlinedIcon />,
      step: "step4",
    },
    {
      value: "serieSync",
      label: "Synchronisation de séries",
      route: AppRoute.SERIE_SYNC_LIST,
      icon: <CompareArrows />,
      step: "step5",
    },
    {
      value: "sourceCluster",
      label: "Comparaison de séries",
      route: AppRoute.SOURCE_CLUSTER_LIST,
      icon: <BubbleChart />,
      step: "step6",
    },
  ]

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <div className={classes.toolbarIconLogo}>
          <AppLink route={AppRoute.HOME}>
            <CardActionArea className={classes.cardContent}>
              <Logo />
            </CardActionArea>
          </AppLink>
        </div>
        <IconButton onClick={() => (open ? doClose() : doOpen())}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {open && (
        <>
          <div style={{ padding: "0 1em" }}>
            <ListSelect
              value={moduleSelectOptions.find((o) => o.value === currentModule)}
              items={moduleSelectOptions}
              onSelect={(val) => history.push(buildUrl({ route: val.route }))}
              getValue={(o) => o.value}
              getLabel={(o) => o.label}
              customOptionRenderer={(o) => (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ color: stepperTheme[o.step].main }}>{o.icon}</span>{" "}
                  <span style={{ marginLeft: "0.5em" }}>{o.label}</span>
                </span>
              )}
            />
          </div>
          <Divider />
        </>
      )}

      <div className={classes.menuItemContainers}>
        {currentModule === "data" && (
          <>
            <List>
              <div>
                <SideMenuItem
                  title={`Explorer vos données`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.DATA_SOURCE_LIST, AppRoute.DATA_SOURCE_CONFIG, AppRoute.DATA_SOURCE_EXPLORE]}
                  open={open}
                  icon={<SourceListIcon />}
                />
                <SideMenuItem
                  disabled={true}
                  title={`(A venir) Explorer les données ${t("global.appTitle")}`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.DATA_SOURCE_TIMELIGHT_LIST]}
                  open={open}
                  icon={<Logo size="small" />}
                />
                <SideMenuItem
                  title={`Ajouter une source`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.SOURCE_CREATE_HUB, AppRoute.SOURCE_CREATE, AppRoute.SOURCE_CREATE_EXAMPLE]}
                  open={open}
                  icon={<SourceAddIcon />}
                />
                <SideMenuItem
                  title={`Ajouter des données`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.SOURCE_ADD_DAYS_HUB, AppRoute.SOURCE_ADD_DAYS]}
                  open={open}
                  icon={<SourceCreateIcon />}
                />
                <SideMenuItem
                  title={`Contrôler l'historique des imports`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.SOURCE_IMPORT_HISTORY]}
                  open={open}
                  icon={<VerifiedUserOutlinedIcon />}
                />
                <SideMenuItem
                  title={`(A venir) Configurer les connecteurs de données`}
                  currentRoute={route}
                  itemRoutes={[]}
                  open={open}
                  icon={<AccountTreeRoundedIcon />}
                  disabled={true}
                />
              </div>
            </List>
          </>
        )}
        {currentModule === "profiles" && (
          <>
            <List>
              <div>
                <SideMenuHeader
                  title="Activité actuelle"
                  shortTitle="Act."
                  open={open}
                  itemRoutes={[AppRoute.MONITOR_ACTIVITY, AppRoute.MONITOR_ALERTS, AppRoute.INVENTORY_ALERTS]}
                  currentRoute={route}
                />

                <SideMenuItem
                  title="Dashboard"
                  currentRoute={route}
                  itemRoutes={[AppRoute.MONITOR_ACTIVITY]}
                  open={open}
                  icon={<TaskListCheckIcon />}
                />
                <SideMenuItem
                  title="Dashboard Alertes"
                  currentRoute={route}
                  itemRoutes={[AppRoute.MONITOR_ALERTS]}
                  open={open}
                  icon={<NotificationIcon />}
                />
                <SideMenuItem
                  title="Référentiel d'alertes traitées"
                  currentRoute={route}
                  itemRoutes={[AppRoute.INVENTORY_ALERTS]}
                  open={open}
                  icon={<HistoryIcon />}
                />
              </div>
            </List>

            <Divider />

            <List>
              <div>
                <SideMenuHeader
                  title="Activité historique"
                  shortTitle="Hist."
                  open={open}
                  itemRoutes={[AppRoute.DATA_SOURCE_HISTORY, AppRoute.DATA_SOURCE_ANOMALY, AppRoute.DATA_SOURCE_MODELS]}
                  currentRoute={route}
                />

                <SideMenuItem
                  title="Données historiques"
                  currentRoute={route}
                  itemRoutes={[AppRoute.DATA_SOURCE_HISTORY]}
                  open={open}
                  icon={<RemoveRedEyeIcon color="inherit" />}
                />
                <SideMenuItem
                  title="Filtrage des anomalies"
                  currentRoute={route}
                  itemRoutes={[AppRoute.DATA_SOURCE_ANOMALY]}
                  open={open}
                  icon={<FilterList />}
                />
                <SideMenuItem
                  title="Configuration des profil types"
                  currentRoute={route}
                  itemRoutes={[AppRoute.DATA_SOURCE_MODELS]}
                  open={open}
                  icon={<BuildIcon />}
                />
              </div>
            </List>
            <Divider />

            <List>
              <div>
                <SideMenuHeader
                  title="Planification"
                  shortTitle="Plan."
                  open={open}
                  itemRoutes={[AppRoute.PREVISION_SOURCE, AppRoute.PREVISION_GROUPS]}
                  currentRoute={route}
                />

                <SideMenuItem
                  title="Planification par capteur"
                  currentRoute={route}
                  itemRoutes={[AppRoute.PREVISION_SOURCE]}
                  open={open}
                  icon={<DateRangeIcon />}
                />
                <SideMenuItem
                  title="Planification groupée"
                  currentRoute={route}
                  itemRoutes={[AppRoute.PREVISION_GROUPS]}
                  open={open}
                  icon={<GroupPrevisionIcon />}
                />
              </div>
            </List>
          </>
        )}

        {currentModule === "forecast" && (
          <>
            <List>
              <div>
                <SideMenuItem
                  title="Tableau de bord"
                  currentRoute={route}
                  itemRoutes={[AppRoute.FORECAST_LIST, AppRoute.FORECAST_TASK_VIEW]}
                  open={open}
                  icon={<ForecastIcon />}
                />
                <SideMenuItem
                  title={`Nouvelle modélisation`}
                  currentRoute={route}
                  itemRoutes={[
                    AppRoute.FORECAST_TASK_CREATE,
                    AppRoute.FORECAST_TASK_COPY,
                    AppRoute.FORECAST_TASK_UPDATE,
                  ]}
                  open={open}
                  icon={<SourceCreateIcon />}
                />
              </div>
            </List>
          </>
        )}

        {currentModule === "serieSync" && (
          <>
            <List>
              <div>
                <SideMenuItem
                  title="Tableau de bord"
                  currentRoute={route}
                  itemRoutes={[AppRoute.SERIE_SYNC_LIST, AppRoute.SERIE_SYNC_TASK_VIEW]}
                  open={open}
                  icon={<CompareArrows />}
                />
                <SideMenuItem
                  title={`Nouvelle synchronisation`}
                  currentRoute={route}
                  itemRoutes={[
                    AppRoute.SERIE_SYNC_TASK_CREATE,
                    AppRoute.SERIE_SYNC_TASK_COPY,
                    AppRoute.SERIE_SYNC_TASK_UPDATE,
                  ]}
                  open={open}
                  icon={<SourceCreateIcon />}
                />
              </div>
            </List>
          </>
        )}
        {currentModule === "sourceCluster" && (
          <>
            <List>
              <div>
                <SideMenuItem
                  title="Tableau de bord"
                  currentRoute={route}
                  itemRoutes={[AppRoute.SOURCE_CLUSTER_LIST, AppRoute.SOURCE_CLUSTER_TASK_VIEW]}
                  open={open}
                  icon={<BubbleChart />}
                />
                <SideMenuItem
                  title={`Nouvelle comparaison`}
                  currentRoute={route}
                  itemRoutes={[
                    AppRoute.SOURCE_CLUSTER_TASK_CREATE,
                    AppRoute.SOURCE_CLUSTER_TASK_COPY,
                    AppRoute.SOURCE_CLUSTER_TASK_UPDATE,
                  ]}
                  open={open}
                  icon={<SourceCreateIcon />}
                />
              </div>
            </List>
          </>
        )}
        {currentModule === "contextImpact" && (
          <>
            <List>
              <div>
                <SideMenuItem
                  title="Tableau de bord"
                  currentRoute={route}
                  itemRoutes={[AppRoute.CONTEXT_IMPACT_LIST, AppRoute.CONTEXT_IMPACT_TASK_VIEW]}
                  open={open}
                  icon={<TrackChangesOutlinedIcon />}
                />
                <SideMenuItem
                  title={`Nouvelle modélisation`}
                  currentRoute={route}
                  itemRoutes={[
                    AppRoute.CONTEXT_IMPACT_TASK_CREATE,
                    AppRoute.CONTEXT_IMPACT_TASK_COPY,
                    AppRoute.CONTEXT_IMPACT_TASK_UPDATE,
                  ]}
                  open={open}
                  icon={<SourceCreateIcon />}
                />
              </div>
            </List>
          </>
        )}
      </div>
    </Drawer>
  )
}
