import DateFnsUtils from "@date-io/date-fns"
import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ThemeProvider } from "@material-ui/styles"
import frLocale from "date-fns/locale/fr"
import React from "react"
import { I18nextProvider } from "react-i18next"
import { useConfig } from "../config"
import { AnyChildren } from "../react-type-helpers"
import { AuthContextProvider } from "../state/auth"
import initI18n from "../state/i18n"
import { MessageContextProvider } from "../state/message"
import { SideMenuContextProvider } from "../state/side-menu"
import { getTheme, ThemeContextProvider } from "../theme"
import { TasksStreamContextContextProvider } from "../state/tasksStream"
import { OnboardingContextProvider } from "../state/onboarding"

export default function AppProviders({ children }: { children: AnyChildren }) {
  const { APP_TYPE } = useConfig()
  const theme = getTheme(APP_TYPE)
  const i18n = initI18n(APP_TYPE)
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeContextProvider value={theme}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <MessageContextProvider>
              <AuthContextProvider>
                <OnboardingContextProvider>
                  <TasksStreamContextContextProvider>
                    <SideMenuContextProvider>{children}</SideMenuContextProvider>
                  </TasksStreamContextContextProvider>
                </OnboardingContextProvider>
              </AuthContextProvider>
            </MessageContextProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ThemeContextProvider>
    </I18nextProvider>
  )
}
