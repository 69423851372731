import { ToleranceChartItem } from "./ChartTooltip"

export const arraysToToleranceData = (
  top_tolerance: number[],
  activity: number[],
  bottom_tolerance: number[],
): ToleranceChartItem[] => {
  const interval = (1000 * 60 * 60 * 24) / activity.length
  return activity.map((a, i) => {
    const curr = i * interval
    const h = Math.floor(curr / (1000 * 60 * 60))
    const m = (curr - h * (1000 * 60 * 60)) / 1000 / 60
    return {
      x: `${h.toFixed(0).padStart(2, "0")}:${m.toFixed(0).padStart(2, "0")}`,
      activity: a,
      top: top_tolerance[i],
      bottom: bottom_tolerance[i],
    }
  })
}
