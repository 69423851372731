/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { SourceTag, SourceTagFromJSON, SourceTagFromJSONTyped, SourceTagToJSON } from "./"

/**
 *
 * @export
 * @interface SourceDto
 */
export interface SourceDto {
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof SourceDto
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  serieId: number
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  companyId: number
  /**
   *
   * @type {Date}
   * @memberof SourceDto
   */
  createdDate: Date
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  groupId: number | null
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  groupInertia: number | null
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  projectionX: number | null
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  projectionY: number | null
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  referenceYear: number
  /**
   *
   * @type {boolean}
   * @memberof SourceDto
   */
  hasPrevision: boolean
  /**
   *
   * @type {boolean}
   * @memberof SourceDto
   */
  hasCustomModels: boolean
  /**
   *
   * @type {boolean}
   * @memberof SourceDto
   */
  previsionEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SourceDto
   */
  syncMeteo: boolean
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  latitude: number | null
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  longitude: number | null
  /**
   *
   * @type {string}
   * @memberof SourceDto
   */
  address: string | null
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  alertMinCriticity: number | null
  /**
   *
   * @type {boolean}
   * @memberof SourceDto
   */
  dayModelsEnabled: boolean
  /**
   *
   * @type {Array<SourceTag>}
   * @memberof SourceDto
   */
  tags: Array<SourceTag>
  /**
   *
   * @type {number}
   * @memberof SourceDto
   */
  alertMaxCriticity: number | null
  /**
   *
   * @type {Date}
   * @memberof SourceDto
   */
  dateDomainBegin: Date | null
  /**
   *
   * @type {Date}
   * @memberof SourceDto
   */
  dateDomainEnd: Date | null
}

export function SourceDtoFromJSON(json: any): SourceDto {
  return SourceDtoFromJSONTyped(json, false)
}

export function SourceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    name: json["name"],
    serieId: json["serieId"],
    companyId: json["companyId"],
    createdDate: new Date(json["createdDate"]),
    groupId: json["groupId"],
    groupInertia: json["groupInertia"],
    projectionX: json["projectionX"],
    projectionY: json["projectionY"],
    referenceYear: json["referenceYear"],
    hasPrevision: json["hasPrevision"],
    hasCustomModels: json["hasCustomModels"],
    previsionEnabled: json["previsionEnabled"],
    syncMeteo: json["syncMeteo"],
    latitude: json["latitude"],
    longitude: json["longitude"],
    address: json["address"],
    alertMinCriticity: json["alertMinCriticity"],
    dayModelsEnabled: json["dayModelsEnabled"],
    tags: (json["tags"] as Array<any>).map(SourceTagFromJSON),
    alertMaxCriticity: json["alertMaxCriticity"],
    dateDomainBegin: json["dateDomainBegin"] === null ? null : new Date(json["dateDomainBegin"]),
    dateDomainEnd: json["dateDomainEnd"] === null ? null : new Date(json["dateDomainEnd"]),
  }
}

export function SourceDtoToJSON(value?: SourceDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    serieId: value.serieId,
    companyId: value.companyId,
    createdDate: value.createdDate.toISOString(),
    groupId: value.groupId,
    groupInertia: value.groupInertia,
    projectionX: value.projectionX,
    projectionY: value.projectionY,
    referenceYear: value.referenceYear,
    hasPrevision: value.hasPrevision,
    hasCustomModels: value.hasCustomModels,
    previsionEnabled: value.previsionEnabled,
    syncMeteo: value.syncMeteo,
    latitude: value.latitude,
    longitude: value.longitude,
    address: value.address,
    alertMinCriticity: value.alertMinCriticity,
    dayModelsEnabled: value.dayModelsEnabled,
    tags: (value.tags as Array<any>).map(SourceTagToJSON),
    alertMaxCriticity: value.alertMaxCriticity,
    dateDomainBegin: value.dateDomainBegin === null ? null : value.dateDomainBegin.toISOString(),
    dateDomainEnd: value.dateDomainEnd === null ? null : value.dateDomainEnd.toISOString(),
  }
}
