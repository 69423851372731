import * as lodash from "lodash"
import { isNumber } from "util"

export function createEntityMap<T>(items: T[], getKey: (item: T) => number): { [key: number]: T } {
  return items.reduce((agg, item) => Object.assign(agg, { [getKey(item)]: item }), {})
}

export function createIdMap<T extends { id: number }>(
  items: T[],
  getKey: (item: T) => number = (i: T) => i.id,
): { [id: number]: T } {
  return createEntityMap(items, getKey)
}

export function createStrIdMap<T>(items: T[], getKey: (item: T) => string): { [id: string]: T } {
  return items.reduce((agg, item) => Object.assign(agg, { [getKey(item)]: item }), {})
}

export function createStrIdMapMultiValue<T>(items: T[], getKey: (item: T) => string): { [id: string]: T[] } {
  return items.reduce((agg: { [k: string]: T[] }, item: T) => {
    const key = getKey(item)
    if (agg[key]) {
      agg[key].push(item)
      return agg
    } else {
      return Object.assign(agg, { [key]: [item] })
    }
  }, {})
}

export function downsampleArray(
  arr: number[],
  targetSize: number,
  {
    reducer = (a) => lodash.mean(a),
    upsampleMethod = "zero",
  }: {
    reducer?: (arr: number[]) => number
    upsampleMethod?: "zero" | "linear"
  } = {},
) {
  const sliceSize = Math.ceil(arr.length / targetSize)
  const res = []
  for (let i = 0; i < targetSize; i++) {
    const sliceElements = arr.slice(i * sliceSize, (i + 1) * sliceSize)
    res.push(reducer(sliceElements))
  }
  return interpolateArray(res, upsampleMethod)
}

export function interpolateArray(arr: (number | null | undefined)[], method: "zero" | "linear"): number[] {
  if (method === "zero") {
    return zeroInterpolateArray(arr)
  } else if (method === "linear") {
    return linearInterpolateArray(arr)
  } else {
    throw new Error(`Invalid interpolation method: ${method}. Use "zero" or "linear"`)
  }
}

export function movingAverage(numbers: (number | null)[], before = 10, after = 0): (number | null)[] {
  return lodash
    .chain(numbers)
    .map((_, index) => {
      const start = Math.max(0, index - before)
      const end = Math.min(numbers.length, index + after + 1)
      return lodash.slice(numbers, start, end).filter((a) => a)
    })
    .map(lodash.mean)
    .value()
    .map((a) => (isNaN(a) ? null : a))
}

// http://code.fitness/post/2016/01/javascript-enumerate-methods.html
function hasMethod(obj: any, name: string) {
  const desc = Object.getOwnPropertyDescriptor(obj, name)
  return !!desc && typeof desc.value === "function"
}

export function getInstanceMethodNames(obj: any, stop: any) {
  const array: string[] = []
  let proto = Object.getPrototypeOf(obj)
  while (proto && proto !== stop) {
    for (const name of Object.getOwnPropertyNames(proto)) {
      if (name !== "constructor") {
        if (hasMethod(proto, name)) {
          array.push(name)
        }
      }
    }
    proto = Object.getPrototypeOf(proto)
  }
  return array
}

export function findClosestBy<T>(arr: T[] | null, getter: (i: T) => number, target: number): T | null {
  return arr && arr.length > 0
    ? arr.reduce((best, current) => {
        const bestDst = Math.abs(getter(best) - target)
        const currDst = Math.abs(getter(current) - target)
        return bestDst > currDst ? current : best
      }, arr[0])
    : null
}

export function zeroInterpolateArray(arr: (number | null | undefined)[]): number[] {
  return arr.map((n) => (isNumber(n) ? n : 0))
}

export function linearInterpolateArray(arr: (number | null | undefined)[]): number[] {
  let previousValueIdx = null
  let nextValueIdx = null
  const res: number[] = Array.from({ length: arr.length })

  for (let i = 0; i < arr.length; i++) {
    // do not interpolate values that are not missing
    if (isNumber(arr[i])) {
      nextValueIdx = null
      res[i] = (arr[i] as unknown) as number
      previousValueIdx = i
      continue
    }
    // here, we have a missing value to interpolate

    // find next non null value if we do not know it already
    for (let j = i + 1; j < arr.length && nextValueIdx === null; j++) {
      if (isNumber(arr[j])) {
        nextValueIdx = j
      }
    }

    // value before and after
    if (previousValueIdx !== null && nextValueIdx !== null) {
      // linear interpolation
      // we know for sure that both index are a number at this point
      const x0: number = previousValueIdx
      const y0: number = (arr[previousValueIdx] as unknown) as number
      const x1: number = nextValueIdx
      const y1: number = (arr[nextValueIdx] as unknown) as number
      const x: number = i

      // https://en.wikipedia.org/wiki/Linear_interpolation
      res[i] = (y0 * (x1 - x) + y1 * (x - x0)) / (x1 - x0)

      // missing value at the end
    } else if (previousValueIdx !== null) {
      // just repeate the previous value
      // we know for sure that this index is a number at this point
      res[i] = (arr[previousValueIdx] as unknown) as number
      // missing value at the start
    } else if (nextValueIdx !== null) {
      // just repeate the next value
      // we know for sure that this index is a number at this point
      res[i] = (arr[nextValueIdx] as unknown) as number
    }
  }
  return res
}

export function findMetricDataIdx(metrics: string[], metric: string): number {
  const idx = metrics.indexOf(metric)
  if (idx === -1) {
    throw new Error(`Could not find metric "${metric}" in metrics [${metrics.join(", ")}]`)
  }
  return idx + 1
}
