import { red } from "@material-ui/core/colors"
import { createMuiTheme, Theme } from "@material-ui/core/styles"
import React from "react"
import { useContext } from "react"
import { AppTypesType } from "./config"

// @ts-ignore No default value
const ThemeContext = React.createContext<Theme>()

export const ThemeContextProvider = ThemeContext.Provider

export function useTheme(): Theme {
  return useContext(ThemeContext)
}

export function getTheme(appType: AppTypesType): Theme {
  // Create a theme instance.
  const theme = createMuiTheme({
    spacing: 8,
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 12,
      fontFamily: "Nunito",
    },

    palette:
      appType === "SECURE_ENERGY"
        ? {
            primary: {
              main: "#589c6f",
            },
            secondary: {
              main: "#858796",
            },
            error: {
              main: red.A400,
            },
            background: {
              default: "#fff",
            },
          }
        : {
            primary: {
              main: "#0071AA",
            },
            secondary: {
              main: "#858796", // #222E50
            },
            error: {
              main: red.A400,
            },
            background: {
              default: "#fff",
            },
          },

    overrides: {
      // Style sheet name ⚛️
      MuiMenu: {
        // Name of the rule
        paper: {
          // we ise !important to override inline style
          // make select list as large as needed
          // these are use as filters atop grid columns
          width: "unset !important",
          // but not too large
          maxWidth: "400px !important",
          // and not too small
          minWidth: "150px !important",
        },
      },
      MuiDrawer: {
        paper: {
          borderRight: 0,
        },
        paperAnchorDockedLeft: {
          borderRight: 0,
        },
      },
      MuiDivider: {
        root: {
          height: 0,
          marginTop: "1.5em",
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: "none",
          borderBottom: 0,
        },
      },
      MuiListSubheader: {
        root: {
          fontSize: "1.3em",
          color: "inherit",
        },
        sticky: {
          position: "initial",
        },
      },
      MuiTabs: {
        scrollButtons: {
          boxShadow: "-1em 0 10px -10px #ccc",
        },
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
  })
  return theme
}

export const stepperTheme = {
  step1: {
    main: "#4e73df",
  },
  step2: {
    main: "#1cc88a",
  },
  step3: {
    main: "#36b9cc",
  },
  step4: {
    main: "#59114D",
  },
  step5: {
    main: "#FB2626",
  },
  step6: {
    main: "#B06BDB",
  },
  stepNext: {
    main: "#f6c23e",
  },
}
