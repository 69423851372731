/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ForecastType {
  AUTOARIMA = "AUTOARIMA",
  FBPROPHET = "FBPROPHET",
  ARIMASIMPLE = "ARIMASIMPLE",
  ARIMASIMPLEAR = "ARIMASIMPLEAR",
  ARIMASIMPLEMA = "ARIMASIMPLEMA",
  HOLTWINTERS = "HOLTWINTERS",
  XGBOOST = "XGBOOST",
  LINEARREG = "LINEARREG",
  NEURALNET = "NEURALNET",
  XGBOOSTDETREND = "XGBOOST_DETREND",
  CONSTLINEARREGDETREND = "CONST_LINEARREG_DETREND",
  CONSTNEURALNETDETREND = "CONST_NEURALNET_DETREND",
}

export function ForecastTypeFromJSON(json: any): ForecastType {
  return ForecastTypeFromJSONTyped(json, false)
}

export function ForecastTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForecastType {
  return json as ForecastType
}

export function ForecastTypeToJSON(value?: ForecastType | null): any {
  return value as any
}
