import { Tooltip } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import HelpIcon from "@material-ui/icons/Help"
import AlertIcon from "@material-ui/icons/Warning"
import React from "react"
import { AnyChildren } from "../react-type-helpers"
import Title from "./Title"
import Loader from "./Loader"

export default function PageBlock({
  title,
  titleInContent = false,
  height,
  alert = false,
  warning = false,
  loading = false,
  help,
  children,
}: {
  title?: string | AnyChildren
  titleInContent?: boolean
  height?: number
  alert?: boolean
  warning?: boolean
  loading?: boolean
  help?: AnyChildren
  children: AnyChildren
}) {
  return (
    <Paper style={{ width: "100%", marginBottom: 37 }}>
      <>
        {title && (
          <div
            style={{
              padding: "15px 20px 15px 20px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title icon={alert || warning ? <AlertIcon /> : undefined} alert={alert} warning={warning}>
              {title}
            </Title>
            {help && (
              <Tooltip
                title={
                  <div
                    style={{
                      color: "white",
                      fontSize: 16,
                      maxWidth: 350,
                      lineHeight: 1,
                      padding: "0 10px",
                    }}
                  >
                    {help}
                  </div>
                }
              >
                <HelpIcon style={{ cursor: "pointer", color: "#4e73df" }} />
              </Tooltip>
            )}
          </div>
        )}
        {loading ? (
          <div style={{ padding: "5em", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Loader />
          </div>
        ) : (
          <div style={{ height: height || undefined, padding: title || titleInContent ? "0 20px 20px 20px" : 20 }}>
            {children}
          </div>
        )}
      </>
    </Paper>
  )
}
