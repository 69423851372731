/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayContextTypes, DayContextTypesFromJSON, DayContextTypesFromJSONTyped, DayContextTypesToJSON } from "./"

/**
 *
 * @export
 * @interface ContextTypeImpactSummary
 */
export interface ContextTypeImpactSummary {
  /**
   *
   * @type {DayContextTypes}
   * @memberof ContextTypeImpactSummary
   */
  contextType: DayContextTypes
  /**
   *
   * @type {number}
   * @memberof ContextTypeImpactSummary
   */
  impactValue: number
  /**
   *
   * @type {number}
   * @memberof ContextTypeImpactSummary
   */
  impactFrequency: number
}

export function ContextTypeImpactSummaryFromJSON(json: any): ContextTypeImpactSummary {
  return ContextTypeImpactSummaryFromJSONTyped(json, false)
}

export function ContextTypeImpactSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContextTypeImpactSummary {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contextType: DayContextTypesFromJSON(json["contextType"]),
    impactValue: json["impactValue"],
    impactFrequency: json["impactFrequency"],
  }
}

export function ContextTypeImpactSummaryToJSON(value?: ContextTypeImpactSummary | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contextType: DayContextTypesToJSON(value.contextType),
    impactValue: value.impactValue,
    impactFrequency: value.impactFrequency,
  }
}
