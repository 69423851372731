import { ClickAwayListener } from "@material-ui/core"
import React from "react"
import { SketchPicker } from "react-color"
import { useToggle } from "../state/toggle"

export default function ColorPicker({ color, onChange }: { color: string; onChange: (color: string) => void }) {
  const { open, doOpen, doClose } = useToggle(false)

  return (
    <ClickAwayListener onClickAway={doClose}>
      <div style={{ position: "relative" }}>
        <div
          style={{
            backgroundColor: color,
            padding: 18,
            borderRadius: 4,
            cursor: "pointer",
            border: "1px solid #ccc",
          }}
          onClick={doOpen}
        ></div>
        {open && (
          <div style={{ position: "absolute", zIndex: 1000, left: -89, top: -310 }}>
            <SketchPicker
              color={color}
              onChangeComplete={(res) => {
                onChange(res.hex)
              }}
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}
