import React from "react"
import * as Cookies from "js-cookie"
import { SourceDto } from "api/timelight-api"
import { useSource, useSourceList } from "./api"
import { useAppRoute } from "./route"
import { useHistory } from "react-router"
import { buildUrl } from "../component/AppLink"

const STORAGE_KEY = "source-id-select"

/**
 * Mainly used when you delete a source
 * and don't want the select to fuck things up
 */
export function clearSourceIdSelectCache() {
  Cookies.remove(STORAGE_KEY)
}

export function useSourceSelect({
  sourceId: initialSourceId,
  sourceIdExclusions = [],
}: { sourceId?: number | null; sourceIdExclusions?: number[] } = {}): [SourceDto, (sourceId: number) => void] {
  const history = useHistory()
  const currentRoute = useAppRoute()

  const defaultSource = {
    id: -1,
    name: "Loading",
    referenceYear: -1,
  } as SourceDto

  /**
   * Source Id order:
   * - route
   * - parameter sourceId (initialSourceId)
   * - local storage
   * - first in the list of sources
   * - null
   */
  let sourceId = null
  if (sourceId === null && currentRoute.params.sourceId) {
    const routeSourceId = parseInt(currentRoute.params.sourceId, 10)
    if (!sourceIdExclusions.includes(routeSourceId)) {
      sourceId = routeSourceId
    }
  }
  if (sourceId === null && initialSourceId && initialSourceId !== -1) {
    sourceId = initialSourceId
  }
  if (sourceId === null) {
    const cookieSourceIdStr = Cookies.get(STORAGE_KEY)
    if (cookieSourceIdStr) {
      const cookieSourceId = parseInt(cookieSourceIdStr, 10)
      if (cookieSourceId !== -1) {
        sourceId = cookieSourceId
      }
    }
  }
  const initialSourceIdIsNull = sourceId === null

  const [selectedSourceId, setSelectedSourceId] = React.useState<number | null>(sourceId)

  const [{ data: source }] = useSource({
    shouldTrigger: (selectedSourceId && selectedSourceId !== -1) || false,
    sourceId: selectedSourceId || -1,
  })

  const [{ data: firstSource }] = useSourceList({
    shouldTrigger: initialSourceIdIsNull && (!selectedSourceId || selectedSourceId === -1),
    limit: 1,
  })
  if (
    initialSourceIdIsNull &&
    (!selectedSourceId || selectedSourceId === -1) &&
    firstSource &&
    firstSource.sources.length > 0
  ) {
    setSelectedSourceId(firstSource.sources[0].id)
  }

  function setSourceId(newSourceId: number) {
    // update local cache
    Cookies.set(STORAGE_KEY, newSourceId + "")

    // update url
    const currentUrl = buildUrl(currentRoute)
    const newUrl = buildUrl({
      route: currentRoute.route,
      params: { ...currentRoute.params, sourceId: newSourceId + "" },
    })
    if (currentUrl !== newUrl) {
      history.push(newUrl)
    } else {
      setSelectedSourceId(newSourceId)
    }
  }

  return [source || defaultSource, setSourceId]
}
