/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  DayModelDto,
  DayModelDtoFromJSON,
  DayModelDtoFromJSONTyped,
  DayModelDtoToJSON,
  ModelDto,
  ModelDtoFromJSON,
  ModelDtoFromJSONTyped,
  ModelDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface RecomputeModelsResponseDto
 */
export interface RecomputeModelsResponseDto {
  /**
   * Our newly created models
   * @type {Array<ModelDto>}
   * @memberof RecomputeModelsResponseDto
   */
  models: Array<ModelDto>
  /**
   * List of days with a matching model
   * @type {Array<DayModelDto>}
   * @memberof RecomputeModelsResponseDto
   */
  days: Array<DayModelDto>
}

export function RecomputeModelsResponseDtoFromJSON(json: any): RecomputeModelsResponseDto {
  return RecomputeModelsResponseDtoFromJSONTyped(json, false)
}

export function RecomputeModelsResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RecomputeModelsResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    models: (json["models"] as Array<any>).map(ModelDtoFromJSON),
    days: (json["days"] as Array<any>).map(DayModelDtoFromJSON),
  }
}

export function RecomputeModelsResponseDtoToJSON(value?: RecomputeModelsResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    models: (value.models as Array<any>).map(ModelDtoToJSON),
    days: (value.days as Array<any>).map(DayModelDtoToJSON),
  }
}
