/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayContextTypes, DayContextTypesFromJSON, DayContextTypesFromJSONTyped, DayContextTypesToJSON } from "./"

/**
 *
 * @export
 * @interface DayContextCorr
 */
export interface DayContextCorr {
  /**
   *
   * @type {number}
   * @memberof DayContextCorr
   */
  readonly companyId: number
  /**
   * The context date in format \"yyyy-mm-dd\"
   * @type {string}
   * @memberof DayContextCorr
   */
  dayDate: string
  /**
   *
   * @type {number}
   * @memberof DayContextCorr
   */
  sourceId: number
  /**
   *
   * @type {DayContextTypes}
   * @memberof DayContextCorr
   */
  ctxType: DayContextTypes
  /**
   *
   * @type {Array<number>}
   * @memberof DayContextCorr
   */
  activity: Array<number>
  /**
   *
   * @type {number}
   * @memberof DayContextCorr
   */
  value: number
}

export function DayContextCorrFromJSON(json: any): DayContextCorr {
  return DayContextCorrFromJSONTyped(json, false)
}

export function DayContextCorrFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayContextCorr {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyId: json["companyId"],
    dayDate: json["dayDate"],
    sourceId: json["sourceId"],
    ctxType: DayContextTypesFromJSON(json["ctxType"]),
    activity: json["activity"],
    value: json["value"],
  }
}

export function DayContextCorrToJSON(value?: DayContextCorr | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    dayDate: value.dayDate,
    sourceId: value.sourceId,
    ctxType: DayContextTypesToJSON(value.ctxType),
    activity: value.activity,
    value: value.value,
  }
}
