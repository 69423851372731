/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  AlertActivityEnum,
  AlertActivityEnumFromJSON,
  AlertActivityEnumFromJSONTyped,
  AlertActivityEnumToJSON,
} from "./"

/**
 *
 * @export
 * @interface AlertDto
 */
export interface AlertDto {
  /**
   *
   * @type {number}
   * @memberof AlertDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof AlertDto
   */
  source_id: number
  /**
   *
   * @type {string}
   * @memberof AlertDto
   */
  date: string
  /**
   *
   * @type {number}
   * @memberof AlertDto
   */
  criticity: number
  /**
   *
   * @type {boolean}
   * @memberof AlertDto
   */
  shape: boolean
  /**
   *
   * @type {AlertActivityEnum}
   * @memberof AlertDto
   */
  activity: AlertActivityEnum
  /**
   *
   * @type {number}
   * @memberof AlertDto
   */
  better_model_id: number | null
  /**
   *
   * @type {number}
   * @memberof AlertDto
   */
  closest_model_id: number | null
  /**
   *
   * @type {boolean}
   * @memberof AlertDto
   */
  favorite: boolean
  /**
   *
   * @type {boolean}
   * @memberof AlertDto
   */
  anomaly: boolean
}

export function AlertDtoFromJSON(json: any): AlertDto {
  return AlertDtoFromJSONTyped(json, false)
}

export function AlertDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    source_id: json["source_id"],
    date: json["date"],
    criticity: json["criticity"],
    shape: json["shape"],
    activity: AlertActivityEnumFromJSON(json["activity"]),
    better_model_id: json["better_model_id"],
    closest_model_id: json["closest_model_id"],
    favorite: json["favorite"],
    anomaly: json["anomaly"],
  }
}

export function AlertDtoToJSON(value?: AlertDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    source_id: value.source_id,
    date: value.date,
    criticity: value.criticity,
    shape: value.shape,
    activity: AlertActivityEnumToJSON(value.activity),
    better_model_id: value.better_model_id,
    closest_model_id: value.closest_model_id,
    favorite: value.favorite,
    anomaly: value.anomaly,
  }
}
