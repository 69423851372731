import React from "react"
import { Field } from "react-final-form"
import * as lodash from "lodash"
import ListSelect from "../ListSelect"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"
import { ListSelectProps } from "../ListSelect"
import { FormHelperText } from "@material-ui/core"

export function createListSelectField<FormState, ItemType>(
  props: { name: keyof FormState & string } & SourceSelectFieldProps<ItemType>,
) {
  // @ts-ignore
  return <Field {...props} render={ListSelectFieldRender} />
}

interface SourceSelectFieldProps<ItemType> {
  items: ListSelectProps<ItemType>["items"]
  isItemDisabled?: ListSelectProps<ItemType>["isItemDisabled"]
  customOptionRenderer?: ListSelectProps<ItemType>["customOptionRenderer"]
}

const ListSelectFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<any, SourceSelectFieldProps<any>>) => {
    return (
      <div>
        <ListSelect<any>
          value={props.items.find((m) => m.value === props.input.value)}
          onSelect={(i) => props.input.onChange(i.value)}
          disabled={props.meta.submitting}
          items={props.items}
          getValue={(i) => i.value}
          getLabel={(i) => i.label}
          isItemDisabled={props.isItemDisabled}
          customOptionRenderer={props.customOptionRenderer}
        />
        {props.meta.error &&
          (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
            <FormHelperText key={i} error={true}>
              {e}
            </FormHelperText>
          ))}
      </div>
    )
  },
  (props: CustomFieldRenderProps<any, SourceSelectFieldProps<any>>) =>
    props.input.value + "|" + getFormFieldPropsMemoizeKey(props),
)
