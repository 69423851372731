import React, { useMemo } from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useForecastTask, useSource, useForecastAlerts, useApiClient, useSourceDateDomain } from "../../state/api"
import { Grid, rgbToHex, InputLabel } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import { useAppRoute, AppRoute } from "../../state/route"
import { formatDate, formatDateTime, formatDuration, truncateDateToDay, dayMs } from "../../lib/date"
import { MaterialTable } from "component/MaterialTable"
import { SourceDto } from "../../api/timelight-api/models/SourceDto"
import { ForecastAlert } from "../../api/timelight-api/models/ForecastAlert"
import { createTableExportCsv } from "../../component/MaterialTable/material-table-helpers"
import {
  extractColumnConfigAndExportConfig,
  createDateColumnConfig,
  createTextColumnConfig,
  createNumericColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import { findMetricDataIdx } from "../../lib/array"
import { SerieBlockTimeStep, SerieAggregationEnum } from "api/timelight-api"
import TaskStatusActivity from "../../component/TaskStatusActivity"
import { downloadStringAsFile } from "../../lib/file"
import { arrayToCsvString } from "../../lib/csv"
import { useTheme } from "../../theme"
import { hexToRGBA } from "../../lib/color"
import { useTaskStream, isTaskEnded, isTaskSuccessfullyEnded } from "../../state/tasksStream"
import { AppButton } from "../../component/AppButton"
import * as lodash from "lodash"
import { TaskActionsBlock } from "../../component/TaskActionsBlock"
import { SourceSelectTable } from "../../component/SourceSelectTable"
import { ContextTypeSelectTable } from "../../component/ContextTypeSelectTable"
import { ForecastTask } from "../../api/timelight-api/models/ForecastTask"
import Loader from "../../component/Loader"
import { SerieChart, SerieData } from "../../component/SerieChart"
import { SerieChartSyncZoomProvider } from "component/SerieChart/serieChartContext"
import { SerieChartContext } from "../../component/SerieChart/serieChartContext"
import { SerieChartProps, getMaxSerieResample } from "../../component/SerieChart/SerieChart"
import { getForecastMethodName } from "./forecastMethods"

export function ForecastTaskView() {
  const currentRoute = useAppRoute()
  const forecastTaskId = parseInt(currentRoute.params.taskId || "-1", 10)

  // fetch page data
  const [{ data: initForecastTask }] = useForecastTask({
    shouldTrigger: forecastTaskId > 0,
    forecastTaskId,
  })
  const task = useTaskStream(initForecastTask)
  const [{ data: forecastAlerts }] = useForecastAlerts({
    shouldTrigger: !!task && isTaskSuccessfullyEnded(task),
    forecastTaskId,
  })
  const [{ data: source }] = useSource({
    shouldTrigger: !!task,
    sourceId: task ? task.sourceId : -1,
  })
  const api = useApiClient()

  return (
    <PageContainer title={`Modélisation statistique ${"- " + (task?.title || "")}`}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Modélisation statistique
            {task && <>: {task.title}</>}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <SerieChartSyncZoomProvider>
        <PageContent>
          <PageBlock loading={!task} title="Configuration de la modélisation">
            {task && (
              <div>
                <dl>
                  {source && (
                    <>
                      <dt>Source étudiée</dt>
                      <dd>
                        <strong>{source.name}</strong>
                      </dd>
                    </>
                  )}
                  <dt>Période d'apprentissage</dt>
                  <dd>
                    <strong>
                      Du {formatDate(task.trainBegin)} au {formatDate(task.trainEnd)}
                    </strong>
                  </dd>

                  <dt>Période de modélisation</dt>
                  <dd>
                    <strong>
                      Du {formatDate(task.forecastBegin)} au {formatDate(task.forecastEnd)}
                    </strong>
                  </dd>

                  <dt>Méthode de modélisation</dt>
                  <dd>
                    <strong>{getForecastMethodName(task.forecastType)}</strong>
                  </dd>

                  <dt>Status de la tâche</dt>
                  <dd>
                    <TaskStatusActivity large={true} task={task} />
                  </dd>
                </dl>
                <Grid container spacing={9}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <ContextTypeSelectTable
                      initialOnlySelection={true}
                      readonly={true}
                      onSelectionChange={() => {}}
                      selection={task.forecastContextTypes}
                      sourceId={task.sourceId}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SourceSelectTable
                      initialOnlySelection={true}
                      readonly={true}
                      title={<InputLabel>Sources contextuelles</InputLabel>}
                      initialSourceIds={task.forecastContextSourceIds}
                      onChange={() => {}}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </PageBlock>

          {task && isTaskSuccessfullyEnded(task) && (
            /*
          <PageBlock title="Informations sur le résultat du modèle">
            <div>
              {Object.entries(task.forecastProperties).map(([outKey, outVal]) => (
                <dl>
                  <dt>{outKey}</dt>
                  <dd>
                    <strong>{outVal}</strong>
                  </dd>
                </dl>
              ))}
            </div>
          </PageBlock>*/ <>

            </>
          )}

          {task && task.error && (
            <PageBlock title="Erreur technique">
              <pre>{task.error}</pre>
            </PageBlock>
          )}

          <PageBlock loading={!task} title="Modélisation">
            <div style={{ width: "100%", height: "400px" }}>
              {task ? <ForecastChart task={task} forecastAlerts={forecastAlerts?.data} /> : <Loader />}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <AppButton
                variant="contained"
                color="primary"
                onClick={async () => {
                  if (!task || !isTaskSuccessfullyEnded(task) || !source) {
                    return
                  }
                  const forecastData = await api.serieDataControllerFetchDataCompact({
                    begin: task.forecastBegin,
                    end: task.forecastEnd,
                    serieId: task.forecastSerieId || -1,
                  })
                  const activityMetricIdx = findMetricDataIdx(forecastData.metrics, "activity")
                  const bottomToleranceMetricIdx = findMetricDataIdx(forecastData.metrics, "bottomTolerance")
                  const topToleranceMetricIdx = findMetricDataIdx(forecastData.metrics, "topTolerance")

                  downloadStringAsFile(
                    arrayToCsvString(
                      forecastData.data.map((d) => [
                        formatDateTime(new Date(d[0])),
                        d[activityMetricIdx],
                        d[bottomToleranceMetricIdx],
                        d[topToleranceMetricIdx],
                      ]),
                      {
                        header: ["Date", "Modèle", "Tolérance Basse", "Tolérance Haute"],
                      },
                    ),
                    {
                      filename: `predictions_${source.name}.csv`,
                      mimeType: "text/csv",
                    },
                  )
                }}
              >
                Exporter les prédictions
              </AppButton>
            </div>
          </PageBlock>

          {source && forecastAlerts && task && isTaskEnded(task) && (
            <PageBlock titleInContent={true}>
              <ForecastAlertTable
                source={source}
                forecastAlerts={forecastAlerts.data}
                minDate={task.forecastBegin}
                maxDate={task.forecastEnd}
              />
            </PageBlock>
          )}

          {task && isTaskEnded(task) && (
            <TaskActionsBlock
              deleteProps={{
                buttonText: <>Supprimer le projet</>,
                popinButtonText: <>Supprimer définitivement le projet {task.title}</>,
                popinTitle: <>Supprimer le projet {task.title} ?</>,
                popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce projet</>,
                successMessage: `Projet ${task.title} supprimé définitivement`,
                redirectToAfterAction: { route: AppRoute.FORECAST_LIST },
                doAction: async () => {
                  await api.forecastTaskControllerDeleteOne({
                    forecastTaskId: task.id,
                  })
                },
              }}
              updateProps={{
                buttonText: <>Mettre à jour</>,
                popinButtonText: <>Supprimer définitivement les données du projet {task.title}</>,
                popinTitle: <>La mise à jour supprimera les données du projet {task.title} ?</>,
                popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce projet</>,
                redirectToAfterAction: { route: AppRoute.FORECAST_TASK_UPDATE, params: { taskId: task.id + "" } },
                doAction: async () => {},
              }}
              redirectToOnCopy={{ route: AppRoute.FORECAST_TASK_COPY, params: { taskId: task.id + "" } }}
            />
          )}
        </PageContent>
      </SerieChartSyncZoomProvider>
    </PageContainer>
  )
}

const ForecastChart = React.memo(function ForecastChartComponent({
  task,
  forecastAlerts,
}: {
  task: ForecastTask
  forecastAlerts: ForecastAlert[] | undefined
}) {
  const theme = useTheme()
  const api = useApiClient()
  const [{ data: sourceDomain }] = useSourceDateDomain({
    shouldTrigger: true,
    sourceId: task ? task.sourceId : -1,
  })
  const chartDomain = {
    begin: lodash.min([sourceDomain?.beginDate, task.trainBegin, task.forecastBegin]) as Date,
    end: lodash.max([sourceDomain?.endDate, task.trainEnd, task.forecastEnd]) as Date,
  }

  const getSeriesData = useMemo(() => {
    return async (minDate: Date, maxDate: Date, resample?: SerieBlockTimeStep): Promise<SerieData[]> => {
      // source Data
      const source = await api.getOneBaseSourceControllerSourceDto({ id: task.sourceId })
      const serie = await api.serieControllerGetOne({ serieId: source.serieId })
      const sourceData = await api.serieDataControllerFetchDataCompact({
        begin: minDate,
        end: maxDate,
        serieId: source.serieId,
        aggregation: SerieAggregationEnum.Mean,
        resample: getMaxSerieResample([serie.blockTimeStep, resample]),
      })
      const sourceActivityMetricIdx = findMetricDataIdx(sourceData.metrics, "activity")

      if (!task.forecastSerieId) {
        return [[], [], []]
      }
      // forecast data
      const forecastData = await api.serieDataControllerFetchDataCompact({
        begin: minDate,
        end: maxDate,
        serieId: task.forecastSerieId,
        aggregation: SerieAggregationEnum.Mean,
        resample: getMaxSerieResample([serie.blockTimeStep, resample]),
      })
      const forecastActivityMetricIdx = findMetricDataIdx(forecastData.metrics, "activity")
      const bottomToleranceMetricIdx = findMetricDataIdx(forecastData.metrics, "bottomTolerance")
      const topToleranceMetricIdx = findMetricDataIdx(forecastData.metrics, "topTolerance")
      return [
        sourceData.data.map((d) => [d[0], d[sourceActivityMetricIdx]]),
        forecastData.data.map((d) => [d[0], d[forecastActivityMetricIdx]]),
        forecastData.data.map((d) => [d[0], d[bottomToleranceMetricIdx], d[topToleranceMetricIdx]]),
      ]
    }
  }, [api, task])

  // create highcharts options
  let plotBands: Highcharts.AxisPlotBandsOptions[] = []

  if (task) {
    plotBands = plotBands.concat([
      {
        color: hexToRGBA(rgbToHex(theme.palette.primary.light), 0.1),
        from: truncateDateToDay(task.trainBegin).getTime(),
        to: truncateDateToDay(task.trainEnd.getTime() + 1 * dayMs).getTime() - 1,
      },
    ])
  }

  const dataToPointsConfig = useMemo((): SerieChartProps["dataToPointsConfig"] => {
    if (!forecastAlerts) {
      return undefined
    }
    // sort alerts by start date to implement early stopping and quickstart indexes
    const alerts = lodash.sortBy(forecastAlerts, (a) => a.start)
    return (serieIdx, data) => {
      // only handle source data serie
      if (serieIdx !== 0) {
        return data
      }
      // mark some points as alerts
      return data.map((point) => {
        // try to find if this point is inside an alert
        const ts = point[0]
        for (const forecastAlert of alerts) {
          // early stopping because alerts are sorted
          if (forecastAlert.start.getTime() > ts) {
            return point
          }

          if (forecastAlert.start.getTime() <= ts && forecastAlert.end.getTime() >= ts) {
            // test if our point is in an alert
            return { x: ts, y: point[1], color: "red", marker: { enabled: true } }
          }
        }
        return point
      })
    }
  }, [forecastAlerts])

  if (!sourceDomain) {
    return <Loader />
  }

  return (
    <SerieChart
      key={task.status}
      chartDomain={chartDomain}
      getSeriesData={getSeriesData}
      additionalHighchartOptions={{ xAxis: [{ plotBands }] }}
      dataToPointsConfig={dataToPointsConfig}
      seriesConfig={[
        {
          name: "Activité",
          type: "line",
          zIndex: 1,
          marker: {
            lineWidth: 2,
            enabled: false,
          },
        },
        {
          name: "Modélisation",
          type: "line",
          zIndex: 1,
          opacity: 0.5,
          color: "#D4AF37",
          marker: {
            lineWidth: 2,
            enabled: false,
          },
        },
        {
          name: "Interval de confiance",
          type: "arearange",
          linkedTo: ":previous",
          color: "#D4AF37",
          fillOpacity: 0.3,
          zIndex: 0,
          marker: {
            enabled: false,
          },
        },
      ]}
    />
  )
})

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const ForecastAlertTable = React.memo(function ForecastAlertTableComponent({
  source,
  forecastAlerts,
  minDate,
  maxDate,
}: {
  source: SourceDto
  forecastAlerts: ForecastAlert[]
  minDate: Date
  maxDate: Date
}) {
  const { setChartsZoom } = React.useContext(SerieChartContext)
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<ForecastAlert>([
    createDateColumnConfig({
      title: "Date de début",
      getDate: (row) => new Date(row.start),
      domain: [minDate, maxDate],
      withTime: true,
    }),
    createDateColumnConfig({
      title: "Date de fin",
      getDate: (row) => new Date(row.end),
      domain: [minDate, maxDate],
      withTime: true,
    }),
    createTextColumnConfig({
      title: "Durée",
      getTextValue: (row) => formatDuration(new Date(row.end).getTime() - new Date(row.start).getTime()),
    }),
    createNumericColumnConfig({
      title: "Criticité",
      getValue: (row) => row.criticity,
    }),
  ])

  return (
    <>
      <MaterialTable
        title={`Anomalies internes`}
        data={forecastAlerts}
        onRowClick={(a) => {
          if (!a) {
            return
          }
          const alertSpan = Math.abs(a.end.getTime() - a.start.getTime()) || 1
          const zoomMarginPrc = alertSpan < 1 * 24 * 60 * 60 * 1000 ? 3.0 : 1.0
          setChartsZoom({
            begin: new Date(a.start.getTime() - alertSpan * zoomMarginPrc),
            end: new Date(a.end.getTime() + alertSpan * zoomMarginPrc),
          })
        }}
        columns={columns}
        options={{
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `forecast_alerts_${source.name}.csv`,
          }),
        }}
      />
    </>
  )
})
