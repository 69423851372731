/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
  /**
   * User first name
   * @type {string}
   * @memberof RegisterDto
   */
  firstname: string
  /**
   * User last name
   * @type {string}
   * @memberof RegisterDto
   */
  lastname: string
  /**
   * User email
   * @type {string}
   * @memberof RegisterDto
   */
  email: string
  /**
   * User login
   * @type {string}
   * @memberof RegisterDto
   */
  username: string
  /**
   * User password
   * @type {string}
   * @memberof RegisterDto
   */
  password: string
  /**
   * Company name
   * @type {string}
   * @memberof RegisterDto
   */
  company: string
  /**
   * Company position
   * @type {string}
   * @memberof RegisterDto
   */
  position: string
}

export function RegisterDtoFromJSON(json: any): RegisterDto {
  return RegisterDtoFromJSONTyped(json, false)
}

export function RegisterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    firstname: json["firstname"],
    lastname: json["lastname"],
    email: json["email"],
    username: json["username"],
    password: json["password"],
    company: json["company"],
    position: json["position"],
  }
}

export function RegisterDtoToJSON(value?: RegisterDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    firstname: value.firstname,
    lastname: value.lastname,
    email: value.email,
    username: value.username,
    password: value.password,
    company: value.company,
    position: value.position,
  }
}
