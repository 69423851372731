import { createBrowserHistory, UnregisterCallback } from "history"
import MonitorActivity from "pages/profile-type/MonitorActivity"
import React from "react"
import { Route, Router, Switch } from "react-router-dom"
import { useConfig } from "../config"
import { createTrackPageView } from "../lib/tracking"
import DataAnomaly from "../pages/profile-type/DataAnomaly"
import DataHistory from "../pages/profile-type/DataHistory"
import DataModels from "../pages/profile-type/DataModels"
import SourceEdit from "../pages/timelight-data/SourceEdit"
import InventoryAlerts from "../pages/profile-type/InventoryAlerts"
import Login from "../pages/auth/Login"
import MonitorAlerts from "../pages/profile-type/MonitorAlerts"
import Page404 from "../pages/Page404"
import PrevisionGroups from "../pages/profile-type/PrevisionGroups"
import PrevisionGroupSources from "../pages/profile-type/PrevisionGroupSources"
import PrevisionSource from "../pages/profile-type/PrevisionSource"
import Register from "../pages/auth/Register"
import SourceAddDays from "../pages/timelight-data/SourceAddDays"
import SourceCreate from "../pages/timelight-data/SourceCreate"
import SourceCreateMultiple from "../pages/timelight-data/SourceCreateMultiple"
import SourceCreateExample from "../pages/timelight-data/SourceCreateExample"
import SourceCreateHub from "../pages/timelight-data/SourceCreateHub"
import { AppRoute } from "../state/route"
import { ErrorBoundary } from "./ErrorBoundary"
import Home from "../pages/Home"
import SourceAddDaysHub from "../pages/timelight-data/SourceAddDaysHub"
import SourceList from "../pages/timelight-data/SourceList"
import SourceExploration from "../pages/timelight-data/SourceExploration"
import { withAuthRedirects } from "../state/currentUser"
import SourceListTimelight from "../pages/timelight-data/SourceListTimelight"
import SourceExplorationTimelight from "../pages/timelight-data/SourceExplorationTimelight"
import SourceImportHistoryPage from "../pages/timelight-data/SourceImportHistoryPage"
import { ForecastTaskList } from "../pages/forecast/ForecastTaskList"
import { ForecastTaskCreate } from "../pages/forecast/ForecastTaskCreate"
import { ForecastTaskView } from "../pages/forecast/ForecastTaskView"
import { SerieSyncTaskList } from "../pages/serie-sync/SerieSyncTaskList"
import { SerieSyncTaskCreate } from "../pages/serie-sync/SerieSyncTaskCreate"
import { SerieSyncTaskView } from "../pages/serie-sync/SerieSyncTaskView"
import { SourceClusterTaskList } from "../pages/source-cluster/SourceClusterTaskList"
import { SourceClusterTaskCreate } from "../pages/source-cluster/SourceClusterTaskCreate"
import { SourceClusterTaskView } from "../pages/source-cluster/SourceClusterTaskView"
import { ContextImpactTaskList } from "../pages/context-impact/ContextImpactTaskList"
import { ContextImpactTaskCreate } from "../pages/context-impact/ContextImpactTaskCreate"
import { ContextImpactTaskView } from "../pages/context-impact/ContextImpactTaskView"
import { AuthError } from "../pages/auth/AuthError"

const customHistory = createBrowserHistory()
let unListen: UnregisterCallback | null = null

export default function AppRouter() {
  const { SOCIAL_AUTH_ENABLED, USER_SIGN_UP_ENABLED, GOOGLE_ANALYTICS_UA } = useConfig()

  if (unListen) {
    unListen()
  }
  unListen = customHistory.listen(createTrackPageView(GOOGLE_ANALYTICS_UA))
  return (
    <ErrorBoundary>
      <Router history={customHistory}>
        <Switch>
          <Route path={AppRoute.DATA_SOURCE_HISTORY} exact component={withAuthRedirects(DataHistory)} />
          <Route path={AppRoute.DATA_SOURCE_ANOMALY} exact component={withAuthRedirects(DataAnomaly)} />
          <Route path={AppRoute.DATA_SOURCE_MODELS} exact component={withAuthRedirects(DataModels)} />
          <Route path={AppRoute.PREVISION_SOURCE} exact component={withAuthRedirects(PrevisionSource)} />
          <Route path={AppRoute.PREVISION_GROUPS} exact component={withAuthRedirects(PrevisionGroups)} />
          <Route path={AppRoute.PREVISION_GROUP_SOURCES} exact component={withAuthRedirects(PrevisionGroupSources)} />
          <Route path={AppRoute.MONITOR_ALERTS} exact component={withAuthRedirects(MonitorAlerts)} />
          <Route path={AppRoute.MONITOR_ACTIVITY} exact component={withAuthRedirects(MonitorActivity)} />
          <Route path={AppRoute.INVENTORY_ALERTS} exact component={withAuthRedirects(InventoryAlerts)} />
          <Route path={AppRoute.FORECAST_LIST} exact component={withAuthRedirects(ForecastTaskList)} />
          <Route path={AppRoute.FORECAST_TASK_CREATE} exact component={withAuthRedirects(ForecastTaskCreate)} />
          <Route path={AppRoute.FORECAST_TASK_COPY} exact component={withAuthRedirects(ForecastTaskCreate)} />
          <Route path={AppRoute.FORECAST_TASK_UPDATE} exact component={withAuthRedirects(ForecastTaskCreate)} />
          <Route path={AppRoute.FORECAST_TASK_VIEW} exact component={withAuthRedirects(ForecastTaskView)} />
          <Route path={AppRoute.SERIE_SYNC_LIST} exact component={withAuthRedirects(SerieSyncTaskList)} />
          <Route path={AppRoute.SERIE_SYNC_TASK_CREATE} exact component={withAuthRedirects(SerieSyncTaskCreate)} />
          <Route path={AppRoute.SERIE_SYNC_TASK_COPY} exact component={withAuthRedirects(SerieSyncTaskCreate)} />
          <Route path={AppRoute.SERIE_SYNC_TASK_UPDATE} exact component={withAuthRedirects(SerieSyncTaskCreate)} />
          <Route path={AppRoute.SERIE_SYNC_TASK_VIEW} exact component={withAuthRedirects(SerieSyncTaskView)} />
          <Route path={AppRoute.SOURCE_CLUSTER_LIST} exact component={withAuthRedirects(SourceClusterTaskList)} />{" "}
          <Route
            path={AppRoute.SOURCE_CLUSTER_TASK_CREATE}
            exact
            component={withAuthRedirects(SourceClusterTaskCreate)}
          />
          <Route
            path={AppRoute.SOURCE_CLUSTER_TASK_COPY}
            exact
            component={withAuthRedirects(SourceClusterTaskCreate)}
          />
          <Route
            path={AppRoute.SOURCE_CLUSTER_TASK_UPDATE}
            exact
            component={withAuthRedirects(SourceClusterTaskCreate)}
          />
          <Route path={AppRoute.SOURCE_CLUSTER_TASK_VIEW} exact component={withAuthRedirects(SourceClusterTaskView)} />
          <Route path={AppRoute.CONTEXT_IMPACT_LIST} exact component={withAuthRedirects(ContextImpactTaskList)} />
          <Route
            path={AppRoute.CONTEXT_IMPACT_TASK_CREATE}
            exact
            component={withAuthRedirects(ContextImpactTaskCreate)}
          />
          <Route
            path={AppRoute.CONTEXT_IMPACT_TASK_COPY}
            exact
            component={withAuthRedirects(ContextImpactTaskCreate)}
          />
          <Route
            path={AppRoute.CONTEXT_IMPACT_TASK_UPDATE}
            exact
            component={withAuthRedirects(ContextImpactTaskCreate)}
          />
          <Route path={AppRoute.CONTEXT_IMPACT_TASK_VIEW} exact component={withAuthRedirects(ContextImpactTaskView)} />
          <Route path={AppRoute.DATA_SOURCE_CONFIG} exact component={withAuthRedirects(SourceEdit)} />
          <Route path={AppRoute.SOURCE_CREATE} exact component={withAuthRedirects(SourceCreate)} />
          <Route path={AppRoute.SOURCE_CREATE_MULTIPLE} exact component={withAuthRedirects(SourceCreateMultiple)} />
          <Route path={AppRoute.SOURCE_ADD_DAYS} exact component={withAuthRedirects(SourceAddDays)} />
          <Route path={AppRoute.SOURCE_CREATE_HUB} exact component={withAuthRedirects(SourceCreateHub)} />
          <Route path={AppRoute.SOURCE_ADD_DAYS_HUB} exact component={withAuthRedirects(SourceAddDaysHub)} />
          <Route path={AppRoute.DATA_SOURCE_LIST} exact component={withAuthRedirects(SourceList)} />
          <Route path={AppRoute.DATA_SOURCE_TIMELIGHT_LIST} exact component={withAuthRedirects(SourceListTimelight)} />
          <Route path={AppRoute.DATA_SOURCE_EXPLORE} exact component={withAuthRedirects(SourceExploration)} />
          <Route path={AppRoute.SOURCE_IMPORT_HISTORY} exact component={withAuthRedirects(SourceImportHistoryPage)} />
          <Route
            path={AppRoute.DATA_SOURCE_TIMELIGHT_EXPLORE}
            exact
            component={withAuthRedirects(SourceExplorationTimelight)}
          />
          <Route path={AppRoute.SOURCE_CREATE_EXAMPLE} exact component={withAuthRedirects(SourceCreateExample)} />
          <Route path={AppRoute.EMPTY_INSTANCE} exact component={withAuthRedirects(SourceCreateHub)} />
          <Route path={AppRoute.LOGIN} exact component={withAuthRedirects(Login)} />
          {USER_SIGN_UP_ENABLED && <Route path={AppRoute.REGISTER} exact component={withAuthRedirects(Register)} />}
          {SOCIAL_AUTH_ENABLED && <Route path={AppRoute.AUTH_ERROR} exact component={withAuthRedirects(AuthError)} />}
          <Route path={AppRoute.HOME} exact component={withAuthRedirects(Home)} />
          <Route component={withAuthRedirects(Page404)} />
        </Switch>
      </Router>
    </ErrorBoundary>
  )
}
