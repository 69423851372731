import React from "react"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Loader from "component/Loader"
import { SourceDto } from "api/timelight-api"
import AppHeader from "../../component/AppHeader"
import { createTableExportCsv, extractColumnConfigAndExportConfig, MaterialTable } from "../../component/MaterialTable"
import PageBlock from "../../component/PageBlock"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { useTimelightSourceList } from "../../state/api"
import { createTextColumnConfig } from "../../component/MaterialTable/material-table-helpers"
import { AppRoute } from "state/route"
import { buildUrl } from "../../component/AppLink"
import { useHistory } from "react-router"

export default function SourceListTimelight() {
  // fetch some data
  const [{ data: sourceData, loading }] = useTimelightSourceList({ shouldTrigger: true })
  const history = useHistory()
  function setSource(newSource: SourceDto) {
    history.push(buildUrl({ route: AppRoute.DATA_SOURCE_TIMELIGHT_EXPLORE, params: { sourceId: newSource.id + "" } }))
  }
  return (
    <PageContainer title="Sources Timelight">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          {!sourceData && <Loader />}

          {sourceData && (
            <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
              Sources Timelight
            </Typography>
          )}
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <PageBlock loading={loading} titleInContent={true}>
          {sourceData && <SourceTable sources={sourceData.sources} onSourceClick={setSource} />}
        </PageBlock>
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const SourceTable = React.memo(function SourceTableComponent({
  onSourceClick,
  sources,
}: {
  sources: SourceDto[]
  onSourceClick: (source: SourceDto) => void
}) {
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<SourceDto>([
    createTextColumnConfig({
      title: "Source",
      getTextValue: (row) => row.name,
    }),
    createTextColumnConfig({
      title: "Adresse",
      getTextValue: (row) => row.address || "",
    }),
  ])

  return (
    <>
      <MaterialTable
        title={"Les sources Timelight"}
        data={sources}
        onRowClick={(row) => row && onSourceClick(row)}
        columns={columns}
        options={{
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `sources.csv`,
          }),
        }}
      />
    </>
  )
})
