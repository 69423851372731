import React from "react"
import { ModelDto } from "api/timelight-api"
import { formatDate } from "../../lib/date"
import { formatNumber } from "../../lib/number"
import ModelTag from "../ModelTag"
import { DATA_NOT_PROVIDED_MODEL_ID, DayData } from "./ChartDayActivity"
import css from "./DayActivityChartTooltip.module.css"
import { contextTypeToText, contextValueToText } from "../DaysNearDateModal/DayContextAnalysis"

export default function DayActivityChartTooltip(props: {
  activityLabel: string | ((day: DayData) => string)
  active: boolean
  payload?: { payload: DayData }[]
  models: ModelDto[]
  label: string
}) {
  const { activityLabel, active, payload, label, models } = props
  if (active && payload && payload.length) {
    const dayData = payload[0].payload

    // don't display a tooltip where there is no data
    if (dayData.model_id === DATA_NOT_PROVIDED_MODEL_ID) {
      return null
    }
    const model = models.find((m) => m.id === dayData.model_id)
    return (
      <div className={css.container}>
        <p className={css.date}>{formatDate(label)}</p>
        <p className={css.value}>
          {typeof activityLabel === "function" ? activityLabel(dayData) : activityLabel}:{" "}
          {dayData.value ? formatNumber(dayData.value) : "N/A"}
        </p>
        {dayData.context.map((ctx) => (
          <p key={ctx.ctxType} className={css.value}>
            {contextTypeToText(ctx.ctxType)}: {contextValueToText(ctx)}
          </p>
        ))}
        {model && <ModelTag model={model} />}
      </div>
    )
  }

  return null
}
