import { FormControlLabel, Switch, Tooltip, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import HelpIcon from "@material-ui/icons/Help"
import SaveIcon from "@material-ui/icons/Save"
import PageBlock from "component/PageBlock"
import { Formik } from "formik"
import React, { useState } from "react"
import posed from "react-pose"
import { Prompt } from "react-router"
import { DayModelDto, ModelDto } from "api/timelight-api"
import AppHeader from "../../component/AppHeader"
import { DATA_NOT_PROVIDED_MODEL_ID } from "../../component/ChartDayActivity"
import ColorPicker from "../../component/ColorPicker"
import DaysNearDateModal from "../../component/DaysNearDateModal/DaysNearDateModal"
import DayYearCalendar from "../../component/DayYearCalendar"
import ListSelect from "../../component/ListSelect"
import ModelModal from "../../component/ModelModal"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import ScatterDays from "../../component/ScatterDays"
import SideMenu from "../../component/SideMenu"
import SourceSelect from "../../component/SourceSelect"
import { useSuccessMessage } from "../../component/SuccessMessage"
import ToleranceChart from "../../component/ToleranceChart/ToleranceChart"
import { arraysToToleranceData } from "../../component/ToleranceChart/util"
import { useApiClient, useDayAndModelList } from "../../state/api"
import { useApiExceptionHandler } from "../../state/auth"
import { useSourceSelect } from "../../state/persisted"
import { AppButton } from "../../component/AppButton"

const useStyles = makeStyles((theme) => ({
  modelChart: {
    height: "300px",
    width: "100%",
    maxWidth: "400px",
  },
  nameColorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    maxWidth: "310px",
    paddingLeft: 20,
    paddingRight: 20,
  },
  switchBase: {
    color: "#CCC",
  },
  switchChecked: {
    color: "#e05757",
  },
  switchTrack: {
    backgroundColor: "#e05757",
  },
  saveContainer: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },
  savePaper: {
    maxWidth: "300px",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

const SaveAnimationContainer = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
})

export default function DataModels() {
  // our styles
  const classes = useStyles()

  // the app bar for values
  const [source, setSourceId] = useSourceSelect()

  // fetch some data
  const [{ data, loading }, , setData] = useDayAndModelList({
    shouldTrigger: source.id !== -1,
    sourceId: source.id,
    year: source.referenceYear,
  })

  // prepare our api call
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Les profil types ont été mis à jour",
  })
  const api = useApiClient()
  const handleApiException = useApiExceptionHandler()

  // state for our day modal
  const [selectedDay, setSelectedDay] = useState<DayModelDto | null>(null)
  // state for our model modal
  const [selectedModel, setSelectedModel] = useState<ModelDto | null>(null)

  // model count change
  const [ModelCountSuccessMessage, showModelCountSuccessMessage] = useSuccessMessage({
    message: "Nombre de profil types mis à jour",
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  async function recomputeModelCount(modelCount: number) {
    if (!source) {
      return
    }
    setSubmitting(true)
    try {
      const res = await api.aiControllerRecomputeModels({ year: source.referenceYear, sourceId: source.id, modelCount })
      // update local data
      setData({
        ...data,
        models: res.models,
        days: res.days,
      })

      // close modal if any
      setSelectedDay(null)

      // yep, we're done
      showModelCountSuccessMessage()
    } catch (e) {
      handleApiException(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <PageContainer title="Configuration des profil types">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Configuration des profil types de
            <SourceSelect sourceId={source.id} setSourceId={setSourceId} />
            sur l'année de référence {source.referenceYear}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SuccessMessage />
        <ModelCountSuccessMessage />

        {data && selectedDay && (
          <DaysNearDateModal
            open={!!selectedDay}
            sourceId={source.id}
            initialDate={selectedDay.date}
            onClose={() => setSelectedDay(null)}
          />
        )}

        {data && selectedModel && (
          <ModelModal
            open={!!selectedModel}
            model={selectedModel}
            days={data.days}
            source={source}
            data={arraysToToleranceData(
              selectedModel.top_tolerance,
              selectedModel.activity,
              selectedModel.bottom_tolerance,
            )}
            onClose={() => setSelectedModel(null)}
          />
        )}

        <PageBlock
          loading={loading}
          title={`Cartographie de l'année de référence: ${source.referenceYear}`}
          help={
            <div>
              <p>
                Vous pouvez ici choisir le nombre de profil types utilisés par la plateforme, puis nomer et qualifier
                ces profil types
              </p>
              <p>
                N'hésitez pas à affiner ces paramètres pour obtenir la situation la plus proche de votre réalité métier
              </p>
            </div>
          }
        >
          {data && (
            <div style={{ height: 500 }}>
              <ScatterDays
                models={data.models}
                days={data.days}
                setSelectedDay={setSelectedDay}
                disableAnomalies={true}
              />
            </div>
          )}
        </PageBlock>

        <PageBlock
          loading={!data}
          title={`Configuration des profil types de l'année de réference: ${source.referenceYear}`}
          help={
            <div>
              <p>Les noms des profil types sont repris dans les autres modules</p>
              <p>
                Les profil types identifiés comme "Alerte" ne sont pas disponibles dans le module Prévoir et
                n'impacterons pas la planification
              </p>
            </div>
          }
        >
          {data && !source.hasCustomModels && (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}>
              <ListSelect<number>
                disabled={submitting}
                placeholder="Nombre de profil types"
                items={Array.from({ length: 16 }, (_, i) => i)}
                value={data.models.length}
                getValue={(i) => i}
                getLabel={(i) => (i === 0 ? "Auto" : i)}
                onSelect={(i) => recomputeModelCount(i)}
              />
              <p style={{ marginLeft: 10 }}>
                Vous pouvez configurer ici les profils type issus de l'activité historique - utilisez l'api de timelight
                pour charger vos propres profils. Attention ! Changer le nombre de profil type écrase les désignations
                et qualifications des profil types précédents, cette opération n'est pas reversible.
              </p>
            </div>
          )}
          {data && source.hasCustomModels && (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}>
              <p style={{ marginLeft: 10 }}>
                Vos profils types ont été correctement chargés - pour retrouver les profils type timelight utilisez
                l'api
              </p>
            </div>
          )}
          {data && (
            <Formik<{ [nameOrColor: string]: string | boolean }>
              enableReinitialize={true}
              initialValues={data.models.reduce(
                (agg, m) =>
                  Object.assign(agg, {
                    [`model-name-${m.id}`]: m.name,
                    [`model-color-${m.id}`]: m.color,
                    [`model-anomaly-${m.id}`]: m.anomaly,
                  }),
                {},
              )}
              onSubmit={async (values) => {
                try {
                  const modelData = await api.modelControllerBulkUpdateModels({
                    modelsPatchDto: {
                      models: data.models.map((m) => ({
                        id: m.id,
                        name: (values[`model-name-${m.id}`] as unknown) as string,
                        color: (values[`model-color-${m.id}`] as unknown) as string,
                        anomaly: (values[`model-anomaly-${m.id}`] as unknown) as boolean,
                      })),
                    },
                  })
                  // update local data
                  setData({
                    ...data,
                    models: modelData.models,
                  })
                  // oh yeah
                  showSuccessMessage()
                } catch (e) {
                  handleApiException(e)
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                setFieldValue,

                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

                /* and other goodies */
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Prompt
                      when={dirty}
                      message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                    />

                    <Grid container>
                      {data.models.map((m, idx) => {
                        return (
                          <Grid key={m.id} item xs={12} md={6} lg={4} style={{ paddingBottom: 50, paddingRight: 30 }}>
                            <Grid container direction="column" justify="center" alignItems="center">
                              <div className={classes.modelChart}>
                                <ToleranceChart
                                  activityTitle={`Activité moyenne ${m.name}`}
                                  data={arraysToToleranceData(m.top_tolerance, m.activity, m.bottom_tolerance)}
                                />
                              </div>
                              <div className={classes.nameColorContainer}>
                                <TextField
                                  key={m.id}
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  label={`Nom du profil type n°${idx + 1}`}
                                  id={`model-name-${m.id}`}
                                  name={`model-name-${m.id}`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values[`model-name-${m.id}`] || ""}
                                  style={{ width: "calc(100% - 60px)" }}
                                />
                                {errors[`model-name-${m.id}`] &&
                                  touched[`model-name-${m.id}`] &&
                                  errors[`model-name-${m.id}`]}
                                <div style={{ marginBottom: 8 }}>
                                  <ColorPicker
                                    color={(values[`model-color-${m.id}`] as unknown) as string}
                                    onChange={(color) => setFieldValue(`model-color-${m.id}`, color)}
                                  />
                                </div>
                                {errors[`model-color-${m.id}`] &&
                                  touched[`model-color-${m.id}`] &&
                                  errors[`model-color-${m.id}`]}

                                <Tooltip title="Détail des journées du profil type">
                                  <AppButton
                                    variant="text"
                                    color="inherit"
                                    size="small"
                                    style={{ marginLeft: 5, marginBottom: 13 }}
                                    onClick={() => setSelectedModel(m)}
                                  >
                                    <HelpIcon style={{ cursor: "pointer", color: "#4e73df" }} />
                                  </AppButton>
                                </Tooltip>
                              </div>
                              <div>
                                <FormControlLabel
                                  style={{
                                    color: values[`model-anomaly-${m.id}`] ? "#e05757" : undefined,
                                  }}
                                  control={
                                    <Switch
                                      color="default"
                                      classes={{
                                        switchBase: classes.switchBase,
                                        track: classes.switchTrack,
                                        checked: classes.switchChecked,
                                      }}
                                      onChange={() =>
                                        setFieldValue(`model-anomaly-${m.id}`, !values[`model-anomaly-${m.id}`])
                                      }
                                      checked={(values[`model-anomaly-${m.id}`] as unknown) as boolean}
                                      value={`model-anomaly-${m.id}` || false}
                                    />
                                  }
                                  label="Ce profil type représente une dérive"
                                />
                                {errors[`model-anomaly-${m.id}`] &&
                                  touched[`model-anomaly-${m.id}`] &&
                                  errors[`model-anomaly-${m.id}`]}
                              </div>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                    <SaveAnimationContainer className={classes.saveContainer} pose={dirty ? "visible" : "hidden"}>
                      <Paper className={classes.savePaper}>
                        <Typography style={{ marginBottom: 10 }}>
                          Les profil types seront modifiés uniquement si vous sauvegardez. Attention, le fait de
                          déclarer un profil type en anomalie aura pour effet de supprimer les alertes et la
                          planification du capteur
                        </Typography>
                        <AppButton variant="contained" color="primary" submitting={isSubmitting} type="submit">
                          <SaveIcon className={classes.extendedIcon} />
                          Sauvegarder
                        </AppButton>
                      </Paper>
                    </SaveAnimationContainer>
                  </form>
                )
              }}
            </Formik>
          )}
        </PageBlock>

        <PageBlock loading={!data} title={`Calendrier de l'année de réference: ${source.referenceYear}`}>
          {data && (
            <DayYearCalendar
              year={source.referenceYear}
              models={data.models}
              days={data.days.map((d) => ({ ...d, model_id: d.model_id || DATA_NOT_PROVIDED_MODEL_ID }))}
              setSelectedDay={(selected) => setSelectedDay(data.days.find((d) => d.date === selected.date) || null)}
            />
          )}
        </PageBlock>
      </PageContent>
    </PageContainer>
  )
}
