/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { ModelPostDto, ModelPostDtoFromJSON, ModelPostDtoFromJSONTyped, ModelPostDtoToJSON } from "./"

/**
 *
 * @export
 * @interface ModelsPostDto
 */
export interface ModelsPostDto {
  /**
   * Defines the data interpolation method to fill missing data.      zero: replace missing data with zeros.     linear: classic and simple linear interpolation.
   * @type {string}
   * @memberof ModelsPostDto
   */
  interpolationMethod: string
  /**
   * The list of models to be created
   * @type {Array<ModelPostDto>}
   * @memberof ModelsPostDto
   */
  models: Array<ModelPostDto>
}

export function ModelsPostDtoFromJSON(json: any): ModelsPostDto {
  return ModelsPostDtoFromJSONTyped(json, false)
}

export function ModelsPostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelsPostDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    interpolationMethod: json["interpolationMethod"],
    models: (json["models"] as Array<any>).map(ModelPostDtoFromJSON),
  }
}

export function ModelsPostDtoToJSON(value?: ModelsPostDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    interpolationMethod: value.interpolationMethod,
    models: (value.models as Array<any>).map(ModelPostDtoToJSON),
  }
}
