/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  ContextSourceImpactSummary,
  ContextSourceImpactSummaryFromJSON,
  ContextSourceImpactSummaryFromJSONTyped,
  ContextSourceImpactSummaryToJSON,
  ContextTypeImpactSummary,
  ContextTypeImpactSummaryFromJSON,
  ContextTypeImpactSummaryFromJSONTyped,
  ContextTypeImpactSummaryToJSON,
  DayContextTypes,
  DayContextTypesFromJSON,
  DayContextTypesFromJSONTyped,
  DayContextTypesToJSON,
  TaskStatus,
  TaskStatusFromJSON,
  TaskStatusFromJSONTyped,
  TaskStatusToJSON,
} from "./"

/**
 *
 * @export
 * @interface ContextImpactTask
 */
export interface ContextImpactTask {
  /**
   *
   * @type {number}
   * @memberof ContextImpactTask
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof ContextImpactTask
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof ContextImpactTask
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof ContextImpactTask
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof ContextImpactTask
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof ContextImpactTask
   */
  readonly companyId: number
  /**
   *
   * @type {string}
   * @memberof ContextImpactTask
   */
  taskType: string
  /**
   *
   * @type {TaskStatus}
   * @memberof ContextImpactTask
   */
  status: TaskStatus
  /**
   *
   * @type {Date}
   * @memberof ContextImpactTask
   */
  readonly startDate: Date | null
  /**
   *
   * @type {Date}
   * @memberof ContextImpactTask
   */
  readonly endDate: Date | null
  /**
   *
   * @type {number}
   * @memberof ContextImpactTask
   */
  readonly durationMs: number | null
  /**
   *
   * @type {string}
   * @memberof ContextImpactTask
   */
  error: string | null
  /**
   *
   * @type {number}
   * @memberof ContextImpactTask
   */
  sourceId: number
  /**
   *
   * @type {string}
   * @memberof ContextImpactTask
   */
  title: string
  /**
   *
   * @type {Array<DayContextTypes>}
   * @memberof ContextImpactTask
   */
  contextTypes: Array<DayContextTypes>
  /**
   *
   * @type {Array<number>}
   * @memberof ContextImpactTask
   */
  contextSourceIds: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof ContextImpactTask
   */
  contextTypeSerieIds: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof ContextImpactTask
   */
  contextSourceSerieIds: Array<number>
  /**
   *
   * @type {Array<ContextTypeImpactSummary>}
   * @memberof ContextImpactTask
   */
  contextTypeImpactSummaries: Array<ContextTypeImpactSummary>
  /**
   *
   * @type {Array<number>}
   * @memberof ContextImpactTask
   */
  contextTypeImpactSerieIds: Array<number>
  /**
   *
   * @type {Array<ContextSourceImpactSummary>}
   * @memberof ContextImpactTask
   */
  contextSourceImpactSummaries: Array<ContextSourceImpactSummary>
  /**
   *
   * @type {Array<number>}
   * @memberof ContextImpactTask
   */
  contextSourceImpactSerieIds: Array<number>
}

export function ContextImpactTaskFromJSON(json: any): ContextImpactTask {
  return ContextImpactTaskFromJSONTyped(json, false)
}

export function ContextImpactTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContextImpactTask {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    taskType: json["taskType"],
    status: TaskStatusFromJSON(json["status"]),
    startDate: json["startDate"] === null ? null : new Date(json["startDate"]),
    endDate: json["endDate"] === null ? null : new Date(json["endDate"]),
    durationMs: json["durationMs"],
    error: json["error"],
    sourceId: json["sourceId"],
    title: json["title"],
    contextTypes: (json["contextTypes"] as Array<any>).map(DayContextTypesFromJSON),
    contextSourceIds: json["contextSourceIds"],
    contextTypeSerieIds: json["contextTypeSerieIds"],
    contextSourceSerieIds: json["contextSourceSerieIds"],
    contextTypeImpactSummaries: (json["contextTypeImpactSummaries"] as Array<any>).map(
      ContextTypeImpactSummaryFromJSON,
    ),
    contextTypeImpactSerieIds: json["contextTypeImpactSerieIds"],
    contextSourceImpactSummaries: (json["contextSourceImpactSummaries"] as Array<any>).map(
      ContextSourceImpactSummaryFromJSON,
    ),
    contextSourceImpactSerieIds: json["contextSourceImpactSerieIds"],
  }
}

export function ContextImpactTaskToJSON(value?: ContextImpactTask | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    taskType: value.taskType,
    status: TaskStatusToJSON(value.status),
    error: value.error,
    sourceId: value.sourceId,
    title: value.title,
    contextTypes: (value.contextTypes as Array<any>).map(DayContextTypesToJSON),
    contextSourceIds: value.contextSourceIds,
    contextTypeSerieIds: value.contextTypeSerieIds,
    contextSourceSerieIds: value.contextSourceSerieIds,
    contextTypeImpactSummaries: (value.contextTypeImpactSummaries as Array<any>).map(ContextTypeImpactSummaryToJSON),
    contextTypeImpactSerieIds: value.contextTypeImpactSerieIds,
    contextSourceImpactSummaries: (value.contextSourceImpactSummaries as Array<any>).map(
      ContextSourceImpactSummaryToJSON,
    ),
    contextSourceImpactSerieIds: value.contextSourceImpactSerieIds,
  }
}
