import { Hidden, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import AddIcon from "@material-ui/icons/Add"
import GridHighlight from "component/GridHighlight"
import GroupAssignAction from "component/GroupAssignAction"
import ListSelect from "component/ListSelect"
import Loader from "component/Loader"
import PageBlock from "component/PageBlock"
import { useSuccessMessage } from "component/SuccessMessage"
import * as lodash from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { SourceDto, SourceGroupDto } from "api/timelight-api"
import AppHeader from "../../component/AppHeader"
import { AppRedirect } from "../../component/AppLink"
import { DATA_NOT_PROVIDED_MODEL_ID } from "../../component/ChartDayActivity"
import {
  createIntegerColumnConfig,
  createNumericColumnConfig,
  createTableExportCsv,
  createTextEnumColumnConfig,
  extractColumnConfigAndExportConfig,
  MaterialTable,
} from "../../component/MaterialTable"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import ScatterSources from "../../component/ScatterSources"
import SideMenu from "../../component/SideMenu"
import SourceModal from "../../component/SourceModal"
import { SourcesGmaps } from "../../component/SourcesGmap"
import { useConfig } from "../../config"
import { createIdMap } from "../../lib/array"
import { useApiClient, useDayAndModelList, useSourcesAndGroupsList, useTemperatureContext } from "../../state/api"
import { useApiExceptionHandler } from "../../state/auth"
import { AppRoute } from "../../state/route"
import { createChartDayDataFromApiInfos } from "../../component/ChartDayActivity/utils"
import { AppButton } from "../../component/AppButton"

export default function PrevisionGroups() {
  const { t } = useTranslation()
  // fetch some data
  const [
    { data },
    // @ts-ignore
    // tslint:disable:no-unused-declaration
    // eslint-disable-next-line
    _,
    setData,
  ] = useSourcesAndGroupsList({
    shouldTrigger: true,
  })
  const { MAX_CROSS_SOURCE_AMOUNT } = useConfig()

  const sources = data ? lodash.sortBy(data.sources, "id").slice(0, MAX_CROSS_SOURCE_AMOUNT) : []
  const hasMoreHiddenSources = data ? data.sources.length > sources.length : false

  // the source modal
  const [selectedSource, setSelectedSource] = useState<SourceDto | null>(null)

  // the selected group (redirect)
  const [selectedGroup, setSelectedGroup] = useState<SourceGroupDto | null>(null)

  // create a faster group index
  const groupsById = createIdMap(data ? data.groups : [])

  // fetch modal data if needed
  const [{ data: modalData }] = useDayAndModelList({
    shouldTrigger: !!selectedSource,
    sourceId: selectedSource ? selectedSource.id : 0,
    year: selectedSource ? selectedSource.referenceYear : 0,
  })

  // fetch temperature data
  const temperatureContext = useTemperatureContext({
    sourceId: selectedSource ? selectedSource.id : 0,
    minDate: `${selectedSource ? selectedSource.referenceYear : 0}-01-01`,
    maxDate: `${selectedSource ? selectedSource.referenceYear : 0}-12-31`,
    shouldTrigger: selectedSource !== null,
  })

  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Goupe mis à jour",
  })
  function onSourceUpdated(source: SourceDto) {
    if (!data) {
      return
    }

    // update the local data
    setData({
      ...data,
      sources: sources.map((s) => (s.id === source.id ? source : s)),
    })

    // close our modal
    setSelectedSource(null)

    // we're done
    showSuccessMessage()
  }

  // prepare some api calls
  const api = useApiClient()
  const handleApiException = useApiExceptionHandler()
  const [submitting, setSubmitting] = useState<boolean>(false)

  // group count change
  const [GroupCountSuccessMessage, showGroupCountSuccessMessage] = useSuccessMessage({
    message: "Nombre de groupes mis à jour",
  })
  async function recomputeGroupCount(modelCount: number) {
    if (!data) {
      return
    }
    setSubmitting(true)
    try {
      const res = await api.aiControllerRecomputeSourceModels({ modelCount })
      // update local data
      setData({
        ...data,
        groups: res.groups,
        sources: res.sources,
      })
      setSelectedSource(null)

      // yep, we're done
      showGroupCountSuccessMessage()
    } catch (e) {
      handleApiException(e)
    } finally {
      setSubmitting(false)
    }
  }

  const [NewGroupSuccessMessage, showNewGroupSuccessMessage] = useSuccessMessage({
    message: "Nouveau groupe créé",
  })
  async function createEmptyGroup() {
    if (!data) {
      return
    }
    setSubmitting(true)
    try {
      const res = await api.sourceGroupControllerSourceGroupCreate({ sourceGroupCreateDto: { name: "Nouveau groupe" } })
      // update local data
      setData({
        ...data,
        groups: data.groups.concat([res]),
      })

      // yep, we're done
      showNewGroupSuccessMessage()
    } catch (e) {
      handleApiException(e)
    } finally {
      setSubmitting(false)
    }
  }

  const sourcesWithLatLng = data ? sources.filter((s) => s.latitude !== null && s.longitude !== null) : []

  return (
    <PageContainer title="Planification multi-source">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Hidden only={["xs"]}>
            <Typography variant="h6">Planification multi-source</Typography>
          </Hidden>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SuccessMessage />
        <GroupCountSuccessMessage />
        <NewGroupSuccessMessage />

        {selectedGroup && (
          <AppRedirect route={AppRoute.PREVISION_GROUP_SOURCES} params={{ groupId: selectedGroup.id + "" }} />
        )}

        {!data && <Loader />}

        {data && selectedSource && modalData && (
          <SourceModal
            open={!!selectedSource}
            source={selectedSource}
            groups={data.groups}
            models={modalData.models}
            days={createChartDayDataFromApiInfos({
              days: modalData.days.map((d) => ({ ...d, model_id: d.model_id || DATA_NOT_PROVIDED_MODEL_ID })),
              context: temperatureContext,
              previsions: [],
              models: modalData.models,
              year: selectedSource.referenceYear,
            })}
            onClose={() => setSelectedSource(null)}
          >
            <GroupAssignAction groups={data.groups} source={selectedSource} onSourceUpdated={onSourceUpdated} />
          </SourceModal>
        )}
        <PageBlock title="Association des capteurs aux groupes">
          <p>
            Bienvenue sur votre écran de planification multi-capteur, vous pouvez utiliser ce module pour établir des
            groupes de capteurs et appliquer la planification d'un capteur à tous les capteurs d'un groupe.
          </p>
          <p>
            Nous prenons en compte l'activité de l'année de référence de chaque capteur pour établir la cartographie des
            capteurs.
          </p>
          {hasMoreHiddenSources && (
            <p>
              <strong>Attention</strong> sur cette instance, uniquement les {MAX_CROSS_SOURCE_AMOUNT} premières{" "}
              {t("source.name")}
              sont prises en compte.
            </p>
          )}
          {data && (
            <div
              style={{
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <ScatterSources groups={data.groups} sources={sources} setSelectedSource={setSelectedSource} />
            </div>
          )}
          <p>
            Vous pouvez utiliser la carte ou le tableau ci-après pour créer et affecter vos capteurs à des groupes -
            mais vous pouvez également utiliser nos services pour les grouper automatiquement.
          </p>
          {data && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginRight: 10 }}>Nombres de groupes</p>
              <ListSelect<number>
                placeholder="Nombre de groupes"
                value={data.groups.length}
                items={Array.from({ length: Math.min(24, sources.length) }, (__, i) => i + 1)}
                getValue={(i) => i}
                getLabel={(i) => i}
                disabled={submitting}
                onSelect={(i) => {
                  // tslint:disable-next-line:no-floating-promises
                  recomputeGroupCount(i)
                }}
              />
              <p style={{ marginLeft: 10 }}>
                Attention, utiliser cette fonctionnalité construit de nouveaux groupes et remplace les groupes
                précédemment constitués
              </p>
            </div>
          )}

          {data &&
            (() => {
              const { columns, exportConfig } = extractColumnConfigAndExportConfig<SourceDto>([
                createTextEnumColumnConfig({
                  title: "Capteur",
                  getTextValue: (row) => row.name,
                  enumValues: sources.map((s) => s.name).sort(),
                }),
                createTextEnumColumnConfig({
                  title: "Groupe",
                  getTextValue: (row) => (row.groupId ? groupsById[row.groupId].name : "Non assigné"),
                  render: (row) => (
                    <GridHighlight color={row.groupId ? groupsById[row.groupId].color : "#CCCCCC"}>
                      {row.groupId ? groupsById[row.groupId].name : "Non assigné"}
                    </GridHighlight>
                  ),
                  enumValues: data.groups
                    .map((g) => g.name)
                    .sort()
                    .concat(["Non assigné"]),
                }),
                createIntegerColumnConfig({
                  title: "Année de référence",
                  getValue: (row) => row.referenceYear,
                }),
                createTextEnumColumnConfig({
                  title: "Déjà planifié ?",
                  render: (row) => (
                    <GridHighlight color={row.hasPrevision ? "#61c569" : "#000000"}>
                      {row.hasPrevision ? "Oui" : "Non"}
                    </GridHighlight>
                  ),
                  getTextValue: (row) => (row.hasPrevision ? "Oui" : "Non"),
                  enumValues: ["Oui", "Non"],
                }),
                createNumericColumnConfig({
                  title: "Inertie intra groupe",
                  getValue: (row) => row.groupInertia || 0,
                }),
              ])
              return (
                <div style={{ marginTop: 30 }}>
                  <MaterialTable
                    title="Liste des capteurs"
                    data={sources}
                    onRowClick={setSelectedSource}
                    columns={columns}
                    options={{
                      exportCsv: createTableExportCsv({
                        exportConfig,
                        fileName: `sources_by_group.csv`,
                      }),
                    }}
                  />
                </div>
              )
            })()}
        </PageBlock>

        {sourcesWithLatLng.length > 0 && (
          <PageBlock title={`Carte des ${t("source.name")}`}>
            <div style={{ height: 400 }}>
              <SourcesGmaps sources={sourcesWithLatLng} onSourceClick={setSelectedSource} onClick={() => {}} />
            </div>
          </PageBlock>
        )}

        <PageBlock title="Gestion des groupes">
          <div style={{ display: "flex", alignItems: "center" }}>
            <AppButton
              style={{ minWidth: 200, marginRight: 10 }}
              color="primary"
              variant="contained"
              submitting={submitting}
              onClick={() => createEmptyGroup()}
            >
              <AddIcon style={{ marginRight: 5 }} />
              Créer un groupe
            </AppButton>
            <p>
              Vous trouverez ci-après les groupes constitués, éditez le contenu d'un groupe existant pour éditer la
              planification de toutes ses capteurs
            </p>
          </div>
          {data &&
            (() => {
              const { columns, exportConfig } = extractColumnConfigAndExportConfig<SourceGroupDto>([
                createTextEnumColumnConfig({
                  title: "Groupe",
                  getTextValue: (row) => row.name,
                  enumValues: data.groups.map((g) => g.name).sort(),
                }),
                createIntegerColumnConfig({
                  title: "Nombre de capteurs",
                  getValue: (row) => sources.filter((s) => s.groupId === row.id).length,
                }),
                createIntegerColumnConfig({
                  title: "Nombre de capteurs planifiées",
                  getValue: (row) => sources.filter((s) => s.hasPrevision && s.groupId === row.id).length,
                  render: (row) => {
                    const groupCount = sources.filter((s) => s.groupId === row.id).length
                    const count = sources.filter((s) => s.hasPrevision && s.groupId === row.id).length
                    return (
                      <GridHighlight color={count === 0 ? "#e05757" : count < groupCount ? "#eab24a" : "#61c569"}>
                        {count}
                      </GridHighlight>
                    )
                  },
                }),
              ])
              return (
                <div style={{ marginTop: 30 }}>
                  <MaterialTable
                    title="Liste des groupes"
                    data={data.groups}
                    columns={columns}
                    onRowClick={setSelectedGroup}
                    options={{
                      exportCsv: createTableExportCsv({
                        exportConfig,
                        fileName: `groups.csv`,
                      }),
                    }}
                  />
                </div>
              )
            })()}
        </PageBlock>
      </PageContent>
    </PageContainer>
  )
}
