import React from "react"
import { red } from "@material-ui/core/colors"
import { Tooltip } from "@material-ui/core"

export function PremiumFeature() {
  return (
    <Tooltip title="Cette fonctionnalité est réservée aux comptes premium. Contactez-nos équipes pour activer cette fonctionnalité">
      <span
        style={{
          cursor: "pointer",
          color: red[300],
          fontSize: "10px",
          border: "2px solid",
          borderColor: red[300],
          padding: "0 3px",
          borderRadius: "3px",
        }}
      >
        PREMIUM
      </span>
    </Tooltip>
  )
}
