import React from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useApiClient } from "../../state/api"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import {
  createTextColumnConfig,
  createNumericColumnConfig,
  createTypeORMCrudPage,
  createTypeORMCrudLimit,
  createTypeORMCrudSort,
  createTypeORMCrudFilter,
} from "../../component/MaterialTable/material-table-helpers"
import {
  extractColumnConfigAndExportConfig,
  createDateColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import { MaterialTable } from "component/MaterialTable"
import { useHistory } from "react-router"
import { AppRoute } from "../../state/route"
import { buildUrl } from "../../component/AppLink"
import { createTaskStatusColumnConfig } from "../../component/TaskStatusActivity"
import { SourceClusterTask } from "../../api/timelight-api/models/SourceClusterTask"
import AppLink from "../../component/AppLink/index"
import AddIcon from "@material-ui/icons/Add"
import { AppButton } from "../../component/AppButton"
import { clusterDistances } from "./clusterDistances"
import {
  createProperTextEnumColumnConfig,
  Query,
  createTableExportCsv,
} from "../../component/MaterialTable/material-table-helpers"
import { EmptyStateRedirect } from "../../component/EmptyStateRedirect"
import { formatDuration } from "../../lib/date"
import { QueryResult } from "material-table"

export function SourceClusterTaskList() {
  return (
    <PageContainer title="Comparaison de séries">
      <AppHeader>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Comparaison de séries
          </Typography>

          <AppLink route={AppRoute.SOURCE_CLUSTER_TASK_CREATE}>
            <AppButton variant="outlined" style={{ color: "white", borderColor: "white" }}>
              <AddIcon /> Nouvelle modélisation
            </AppButton>
          </AppLink>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SourceClusterTaskTable />
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const SourceClusterTaskTable = React.memo(function SourceClusterTaskTableComponent() {
  const history = useHistory()
  const api = useApiClient()
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false)

  type RowType = SourceClusterTask

  const { columns, exportConfig } = extractColumnConfigAndExportConfig<RowType>([
    createTextColumnConfig({
      title: "Nom",
      getTextValue: (row) => row.title,
      field: "title",
    }),
    createNumericColumnConfig({
      title: "Nombre de sources",
      getValue: (row) => row.sourceIds.length,
      isInt: true,
    }),
    createDateColumnConfig({
      title: "Date de création",
      getDate: (row) => row.createdDate,
      field: "createdDate",
    }),
    createTextColumnConfig({
      title: "Durée d'exécution",
      getTextValue: (row) => formatDuration(row.durationMs),
    }),
    createProperTextEnumColumnConfig({
      title: "Distance",
      getEnumValue: (row) => row.distance,
      enumLookup: clusterDistances.reduce((agg, method) => Object.assign(agg, { [method.value]: method.label }), {}),
      field: "distance",
    }),
    createNumericColumnConfig({
      title: "Nombre de clusters",
      getValue: (row) => row?.modelCount,
      isInt: true,
      field: "modelCount",
    }),
    createNumericColumnConfig({
      title: "Nombre d'anomalies",
      getValue: (row) => row?.anomalyCount,
      isInt: true,
      field: "anomalyCount",
    }),
    createTaskStatusColumnConfig<RowType>(),
  ])

  async function getData(query: Query<RowType>): Promise<QueryResult<RowType>> {
    const res = await api.getManyBaseSourceClusterTaskControllerSourceClusterTask({
      page: createTypeORMCrudPage(query),
      limit: createTypeORMCrudLimit(query),
      sort: createTypeORMCrudSort(query),
      filter: createTypeORMCrudFilter(query),
    })
    if (query.filters.length === 0 && query.search.length === 0 && res.count === 0) {
      setIsEmpty(true)
    }
    return {
      page: query.page,
      totalCount: res.total,
      data: res.data,
    }
  }
  return (
    <>
      {isEmpty ? (
        <EmptyStateRedirect
          route={AppRoute.SOURCE_CLUSTER_TASK_CREATE}
          actionText={"Créer une première modélisation"}
          headerText={"Bienvenue dans le module de comparaison de séries"}
          helpText={`Ce module vous permet de créer des groupements de sources 
          en comparant leur données respectives`}
          examplePicturePublicPath={"/examples/source_cluster_example.png"}
        />
      ) : (
        <PageBlock titleInContent={true}>
          <MaterialTable
            title={`Historique des comparaison de séries`}
            data={getData}
            onRowClick={(row) => {
              if (row) {
                history.push(
                  buildUrl({
                    route: AppRoute.SOURCE_CLUSTER_TASK_VIEW,
                    params: {
                      taskId: row.id + "",
                    },
                  }),
                )
              }
            }}
            columns={columns}
            options={{
              search: false,
              filtering: true,
              exportCsv: createTableExportCsv({
                exportConfig,
                fileName: `source_cluster_tasks.csv`,
                getData,
              }),
            }}
          />
        </PageBlock>
      )}
    </>
  )
})
