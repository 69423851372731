/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SourceClusterDistance {
  EUCLIDE = "EUCLIDE",
  MANHATTAN = "MANHATTAN",
  CHEBYSHEV = "CHEBYSHEV",
  MINKOWSKI = "MINKOWSKI",
  CANBERRA = "CANBERRA",
  CHISQUARE = "CHI_SQUARE",
  DISSIM = "DISSIM",
  DTW = "DTW",
  LBKEOGH = "LB_KEOGH",
  EDR = "EDR",
  LCSS = "LCSS",
  ERP = "ERP",
  MAHALANOBIS = "MAHALANOBIS",
}

export function SourceClusterDistanceFromJSON(json: any): SourceClusterDistance {
  return SourceClusterDistanceFromJSONTyped(json, false)
}

export function SourceClusterDistanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceClusterDistance {
  return json as SourceClusterDistance
}

export function SourceClusterDistanceToJSON(value?: SourceClusterDistance | null): any {
  return value as any
}
