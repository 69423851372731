import React from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import {
  useSource,
  useApiClient,
  useContextImpactTask,
  useSourceDateDomain,
  useContextImpactDaySummary,
} from "../../state/api"
import { Grid, rgbToHex } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import { useAppRoute, AppRoute } from "../../state/route"
import * as Highcharts from "highcharts"
import highchartsMore from "highcharts/highcharts-more"
import TaskStatusActivity from "../../component/TaskStatusActivity"
import { useTaskStream, isTaskEnded, isTaskSuccessfullyEnded } from "../../state/tasksStream"
import { ContextImpactTask } from "../../api/timelight-api/models/ContextImpactTask"
import {
  extractColumnConfigAndExportConfig,
  createTextColumnConfig,
  createNumericColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import { MaterialTable } from "../../component/MaterialTable"
import { contextTypeToText, contextValueToText } from "../../component/DaysNearDateModal/DayContextAnalysis"
import { SourceDto } from "../../api/timelight-api/models/SourceDto"
import { createIdMap, findMetricDataIdx } from "../../lib/array"
import { ContextSourceImpactSummary } from "../../api/timelight-api/models/ContextSourceImpactSummary"
import { ContextTypeImpactSummary } from "../../api/timelight-api/models/ContextTypeImpactSummary"
import { createTableExportCsv } from "../../component/MaterialTable/material-table-helpers"
import ListSelect from "../../component/ListSelect"
import { DayContextTypes } from "../../api/timelight-api/models/DayContextTypes"
import DayYearContextCalendar from "../../component/DayYearCalendar/DayYearContextCalendar"
import { SerieBlockTimeStep } from "../../api/timelight-api/models/SerieBlockTimeStep"
import { useTheme } from "../../theme"
import { hexToRGBA } from "../../lib/color"
import { useSerieData, useSourcesById } from "../../state/api"
import YearSelect from "component/YearSelect"
import { getCurrentYear, dayMs, toDateObj, formatDate } from "../../lib/date"
import * as lodash from "lodash"
import { getActivityColor } from "../../component/DayYearCalendar/util"
import AppModal from "../../component/AppModal"
import Title from "../../component/Title"
import { ContextImpactSummaryDto } from "../../api/timelight-api/models/ContextImpactSummaryDto"
import { SerieAggregationEnum } from "../../api/timelight-api/models/SerieAggregationEnum"
import { TaskActionsBlock } from "../../component/TaskActionsBlock"
import Loader from "../../component/Loader"
import { SerieChart, SerieData } from "../../component/SerieChart"
import { SerieInterpolationEnum } from "api/timelight-api"
import { getMaxSerieResample } from "../../component/SerieChart/SerieChart"

highchartsMore(Highcharts)

type RowType = ContextSourceImpactSummary | ContextTypeImpactSummary

function isContextImpactSummary(o: any): o is ContextTypeImpactSummary {
  return !!o.contextType
}
function isSourceImpactSummary(o: any): o is ContextSourceImpactSummary {
  return !!o.contextSourceId
}

interface ContextTypeSourceSelectItem {
  value: string
  sourceId: number
  name: string
  impactSerieId: number
  dataSerieId: number
  impact: number
}
interface ContextTypeContextSelectItem {
  value: string
  contextType: DayContextTypes
  name: string
  impactSerieId: number
  dataSerieId: number
  impact: number
}
type ContextTypeSelectItem = ContextTypeSourceSelectItem | ContextTypeContextSelectItem

function isContextSourceItem(o: any): o is ContextTypeSourceSelectItem {
  return !!o.sourceId
}

export function ContextImpactTaskView() {
  const currentRoute = useAppRoute()
  const taskId = parseInt(currentRoute.params.taskId || "-1", 10)
  const [selectedContextItem, setSelectedContextItem] = React.useState<ContextTypeSelectItem | undefined>(undefined)
  const [selectedCalendarYear, setSelectedCalendarYear] = React.useState<number>(getCurrentYear())
  const [selectedDate, setSelectedDate] = React.useState<string | null>(null)

  // fetch page data
  const [{ data: initTask }] = useContextImpactTask({
    shouldTrigger: taskId > 0,
    contextImpactTaskId: taskId,
  })
  const task = useTaskStream(initTask)
  const [{ data: source }] = useSource({
    shouldTrigger: !!task,
    sourceId: task ? task.sourceId : -1,
  })

  const [{ data: sourceList }] = useSourcesById({
    shouldTrigger: !!task && isTaskEnded(task),
    sourceIds: lodash.uniq(
      [task?.sourceId || -1, ...(task?.contextSourceIds || [-1])].concat(
        task?.contextSourceImpactSummaries?.map((s) => s.contextSourceId) || [],
      ),
    ),
  })

  const [{ data: domain }] = useSourceDateDomain({
    shouldTrigger: !!task,
    sourceId: task ? task.sourceId : -1,
  })

  const [{ data: contextImpactDayData }] = useSerieData({
    shouldTrigger:
      !!task && isTaskEnded(task) && !!domain && !!selectedContextItem && selectedContextItem?.impactSerieId !== -1,
    begin: domain?.beginDate || new Date(),
    end: domain?.endDate || new Date(),
    serieId: selectedContextItem?.impactSerieId || -1,
    resample: SerieBlockTimeStep._1d,
    aggregation: SerieAggregationEnum.Sum,
  })

  if (
    domain &&
    (selectedCalendarYear > getCurrentYear(domain.endDate) || selectedCalendarYear < getCurrentYear(domain.beginDate))
  ) {
    setSelectedCalendarYear(getCurrentYear(domain.beginDate))
  }

  const api = useApiClient()
  const sourceById = createIdMap(sourceList || [])
  const contextItems = createContextSelectItems({ sources: sourceList || [], task })
  if (contextItems.length > 0) {
    if (selectedContextItem === undefined) {
      setSelectedContextItem(contextItems[0])
    } else if (selectedContextItem.dataSerieId === -1 && contextItems[0].dataSerieId > 0) {
      setSelectedContextItem(contextItems[0])
    } else if (selectedContextItem.impactSerieId === -1 && contextItems[0].impactSerieId > 0) {
      setSelectedContextItem(contextItems[0])
    }
  }

  return (
    <PageContainer title={`Analyse de données contextuelles ${"- " + (task?.title || "")}`}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Analyse de données contextuelles
            {task && <>: {task.title}</>}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <PageBlock title="Configuration de la modélisation">
          {task && (
            <div>
              <dl>
                {source && (
                  <>
                    <dt>Source étudiée</dt>
                    <dd>
                      <strong>{source.name}</strong>
                    </dd>
                  </>
                )}

                <dt>Status de la tâche</dt>
                <dd>
                  <TaskStatusActivity large={true} task={task} />
                </dd>
              </dl>
            </div>
          )}
        </PageBlock>

        {task && isTaskSuccessfullyEnded(task) && sourceList && (
          <PageBlock titleInContent={true}>
            <ContextImpactSummaryTable
              sources={sourceList}
              summary={task}
              onContextImpactSummaryClick={(item) =>
                setSelectedContextItem(contextItems.find((it) => it.value === item.contextType))
              }
              onSourceImpactSummaryClick={(item) =>
                setSelectedContextItem(contextItems.find((it) => it.value === item.contextSourceId + ""))
              }
            />
          </PageBlock>
        )}

        {task && task.error && (
          <PageBlock title="Erreur technique">
            <pre>{task.error}</pre>
          </PageBlock>
        )}

        {domain && task && isTaskEnded(task) && (
          <PageBlock
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "1em" }}>Impact de </span>
                <ListSelect<ContextTypeSelectItem>
                  minWidth={300}
                  items={contextItems}
                  value={selectedContextItem}
                  getLabel={(item) => item.name}
                  getValue={(item) => item.value}
                  onSelect={setSelectedContextItem}
                />
                <span style={{ marginLeft: "1em" }}> sur les données de {sourceById[task.sourceId]?.name} </span>
              </div>
            }
          >
            <ContextImpactPreview
              task={task}
              begin={domain.beginDate}
              end={domain.endDate}
              selectedContextItem={selectedContextItem}
            />
          </PageBlock>
        )}

        {contextImpactDayData && domain && task && isTaskEnded(task) && (
          <PageBlock
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "1em" }}>Impact de </span>
                <ListSelect<ContextTypeSelectItem>
                  minWidth={300}
                  items={contextItems}
                  value={selectedContextItem}
                  getLabel={(item) => item.name}
                  getValue={(item) => item.value}
                  onSelect={setSelectedContextItem}
                />
                <span style={{ marginLeft: "1em", display: "flex", alignItems: "center" }}>
                  {" "}
                  sur les données de l'année{" "}
                  <div style={{ fontWeight: "normal", fontSize: "14px" }}>
                    <YearSelect
                      year={selectedCalendarYear}
                      years={{ from: getCurrentYear(domain.beginDate), to: getCurrentYear(domain.endDate) }}
                      setYear={setSelectedCalendarYear}
                    />
                  </div>
                </span>
              </div>
            }
          >
            <DayYearContextCalendar
              year={selectedCalendarYear}
              models={[]}
              activityColor="g"
              impactSerieData={contextImpactDayData}
              setSelectedDay={(day) => setSelectedDate(day.date)}
            />

            {selectedDate && (
              <AppModal
                fullscreen={false}
                open={!!selectedDate}
                onClose={() => setSelectedDate(null)}
                title={<Title>Impact des données contextuelles du {formatDate(selectedDate)}</Title>}
              >
                <ContextImpactDayModalContent
                  sources={sourceList || []}
                  initialContextItem={selectedContextItem}
                  day={selectedDate}
                  task={task}
                />
              </AppModal>
            )}
          </PageBlock>
        )}

        {task && isTaskEnded(task) && (
          <TaskActionsBlock
            deleteProps={{
              buttonText: <>Supprimer le projet</>,
              popinButtonText: <>Supprimer définitivement le projet {task.title}</>,
              popinTitle: <>Supprimer le projet {task.title} ?</>,
              popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce projet</>,
              successMessage: `Projet ${task.title} supprimé définitivement`,
              redirectToAfterAction: { route: AppRoute.CONTEXT_IMPACT_LIST },
              doAction: async () => {
                await api.contextImpactTaskControllerDeleteOne({
                  contextImpactTaskId: task.id,
                })
              },
            }}
            updateProps={{
              buttonText: <>Mettre à jour</>,
              popinButtonText: <>Supprimer définitivement les données du projet {task.title}</>,
              popinTitle: <>La mise à jour supprimera les données du projet {task.title} ?</>,
              popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce projet</>,
              redirectToAfterAction: { route: AppRoute.CONTEXT_IMPACT_TASK_UPDATE, params: { taskId: task.id + "" } },
              doAction: async () => {},
            }}
            redirectToOnCopy={{ route: AppRoute.CONTEXT_IMPACT_TASK_COPY, params: { taskId: task.id + "" } }}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}

function createContextSelectItems({ sources, task }: { sources: SourceDto[]; task: ContextImpactTask | null }) {
  const sourceById = createIdMap(sources)
  return lodash.orderBy(
    (task?.contextTypes || [])
      .map(
        (ctxType, i) =>
          ({
            value: ctxType,
            contextType: ctxType,
            impact: task?.contextTypeImpactSummaries[i]?.impactValue || 0,
            name:
              contextTypeToText(ctxType) +
              (task && isTaskSuccessfullyEnded(task) ? ` (${task.contextTypeImpactSummaries[i].impactValue})` : ""),
            impactSerieId: task?.contextTypeImpactSerieIds[i] || -1,
            dataSerieId: task?.contextTypeSerieIds[i] || -1,
          } as ContextTypeSelectItem),
      )
      .concat(
        (task?.contextSourceIds || []).map(
          (sourceId, i) =>
            ({
              value: sourceId + "",
              sourceId,
              impact: task?.contextSourceImpactSummaries[i]?.impactValue || 0,
              name:
                sourceById[sourceId]?.name +
                (task && isTaskSuccessfullyEnded(task)
                  ? ` (${task.contextSourceImpactSummaries[i]?.impactValue})`
                  : ""),
              impactSerieId: task?.contextSourceImpactSerieIds[i] || -1,
              dataSerieId: task?.contextSourceSerieIds[i] || -1,
            } as ContextTypeSelectItem),
        ),
      ),
    (item) => -item.impact,
  )
}

const ContextImpactPreview = React.memo(function ContextImpactPreviewComponent({
  task,
  begin,
  end,
  selectedContextItem,
  resampleImpact = SerieBlockTimeStep._1d,
}: {
  task: ContextImpactTask
  begin: Date
  end: Date
  selectedContextItem: ContextTypeSelectItem | undefined
  resampleImpact?: SerieBlockTimeStep
}) {
  const theme = useTheme()
  const api = useApiClient()
  const [{ data: sourceDomain }] = useSourceDateDomain({
    shouldTrigger: task.sourceId !== -1,
    sourceId: task.sourceId,
  })
  const chartDomain = {
    begin: lodash.max([sourceDomain?.beginDate, begin]) as Date,
    end: lodash.min([sourceDomain?.endDate, end]) as Date,
  }

  const selectedContextItemImpactSerieId = selectedContextItem?.impactSerieId || -1
  const selectedContextItemDataSerieId = selectedContextItem?.dataSerieId || -1
  const getSeriesData = React.useMemo(() => {
    return async (minDate: Date, maxDate: Date, resample?: SerieBlockTimeStep): Promise<SerieData[]> => {
      const source = await api.getOneBaseSourceControllerSourceDto({ id: task.sourceId })
      const serie = await api.serieControllerGetOne({ serieId: source.serieId })
      const sourceData = await api.serieDataControllerFetchDataCompact({
        begin: minDate,
        end: maxDate,
        serieId: source.serieId,
        aggregation: SerieAggregationEnum.Mean,
        resample: getMaxSerieResample([serie.blockTimeStep, resample]),
      })

      let contextData = { metrics: ["activity"], data: [] as number[][] }
      if (selectedContextItemDataSerieId !== -1) {
        const contextSerie = await api.serieControllerGetOne({ serieId: source.serieId })
        contextData = await api.serieDataControllerFetchDataCompact({
          begin: minDate,
          end: maxDate,
          serieId: selectedContextItemDataSerieId,
          aggregation: SerieAggregationEnum.Sum,
          interpolation: SerieInterpolationEnum.Linear,
          resample: getMaxSerieResample([contextSerie.blockTimeStep, resample]),
        })
      }

      let contextImpactData = { metrics: ["activity"], data: [] as number[][] }
      if (selectedContextItemImpactSerieId !== -1) {
        const impactSerie = await api.serieControllerGetOne({ serieId: source.serieId })
        contextImpactData = await api.serieDataControllerFetchDataCompact({
          begin: minDate,
          end: maxDate,
          serieId: selectedContextItemImpactSerieId,
          aggregation: SerieAggregationEnum.Sum,
          interpolation: SerieInterpolationEnum.Zero,
          resample: getMaxSerieResample([impactSerie.blockTimeStep, resample]),
        })
      }

      const sourceActivityMetricIdx = findMetricDataIdx(sourceData.metrics, "activity")
      const contextActivityMetricIdx = findMetricDataIdx(contextData.metrics, "activity")
      const contextImpactActivityMetricIdx = findMetricDataIdx(contextImpactData.metrics, "activity")

      return [
        sourceData.data.map((d) => [d[0], d[sourceActivityMetricIdx]]),
        contextData.data.map((d) => [d[0], d[contextActivityMetricIdx]]),
        contextImpactData.data.map((d) => [d[0], d[contextImpactActivityMetricIdx]]),
      ]
    }
  }, [api, task, selectedContextItemDataSerieId, selectedContextItemImpactSerieId])

  const getPlotBands = React.useMemo(() => {
    return (seriesData: SerieData[]) => {
      const contextImpactData = seriesData[2]
      const valueIdx = 1
      const minImpact = lodash.min(contextImpactData.map((values) => values[valueIdx])) as number
      const maxImpact = lodash.max(contextImpactData.map((values) => values[valueIdx])) as number

      return contextImpactData
        .filter((values) => (values[valueIdx] || 0) > 0)
        .map((values) => ({
          id: values[0] + "",
          from: values[0],
          to: values[0] + dayMs,
          color: hexToRGBA(getActivityColor(minImpact, values[valueIdx] || 0, maxImpact, "g"), 0.4),
        }))
    }
  }, [])

  if (!sourceDomain || !selectedContextItem) {
    return <Loader />
  }

  return (
    <SerieChart
      key={selectedContextItem.dataSerieId + "-" + selectedContextItem.impactSerieId}
      chartDomain={chartDomain}
      getSeriesData={getSeriesData}
      getPlotBands={getPlotBands}
      seriesConfig={[
        {
          type: "line",
          name: "Activité",
          color: hexToRGBA(rgbToHex(theme.palette.primary.light), 0.3),
          yAxis: 0,
        },
        {
          type: "line",
          name: selectedContextItem?.name || "",
          yAxis: 1,
          color: "#FF0000",
        },
      ]}
      additionalHighchartOptions={{
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        yAxis: [
          {} as any,
          {
            labels: {
              formatter() {
                if (!selectedContextItem) {
                  return this.value
                }
                return isContextSourceItem(selectedContextItem)
                  ? this.value
                  : contextValueToText({ ctxType: selectedContextItem.contextType, value: this.value })
              },
            },
            title: {
              text: selectedContextItem?.name || "",
            },
            opposite: true,
          },
        ],
      }}
    />
  )
})

const ContextImpactDayModalContent = React.memo(function ContextImpactDayModalContentComponent({
  sources,
  task,
  day,
  initialContextItem,
}: {
  sources: SourceDto[]
  task: ContextImpactTask
  day: string
  initialContextItem: ContextTypeSelectItem | undefined
}) {
  const begin = toDateObj(day)
  const end = new Date(begin.getTime() + dayMs)

  const [selectedContextItem, setSelectedContextItem] = React.useState<ContextTypeSelectItem | undefined>(
    initialContextItem,
  )
  const contextItems = createContextSelectItems({ sources, task })

  const [{ data: daySummaryData }] = useContextImpactDaySummary({
    shouldTrigger: true,
    contextImpactTaskId: task.id,
    day,
  })

  if (contextItems.length > 0 && selectedContextItem === undefined) {
    setSelectedContextItem(contextItems[0])
  }

  return (
    <div>
      {daySummaryData && (
        <ContextImpactSummaryTable
          sources={sources}
          summary={daySummaryData}
          onContextImpactSummaryClick={(item) =>
            setSelectedContextItem(contextItems.find((it) => it.value === item.contextType))
          }
          onSourceImpactSummaryClick={(item) =>
            setSelectedContextItem(contextItems.find((it) => it.value === item.contextSourceId + ""))
          }
          pageSize={5}
        />
      )}
      <div style={{ marginTop: "3em" }}></div>
      <Title>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "1em" }}>Impact de </span>
          <ListSelect<ContextTypeSelectItem>
            minWidth={300}
            items={contextItems}
            value={selectedContextItem}
            getLabel={(item) => item.name}
            getValue={(item) => item.value}
            onSelect={setSelectedContextItem}
          />
          <span style={{ marginLeft: "1em" }}> au {formatDate(day)} </span>
        </div>
      </Title>
      <ContextImpactPreview
        begin={begin}
        end={end}
        selectedContextItem={selectedContextItem}
        task={task}
        resampleImpact={SerieBlockTimeStep._10m}
      />
    </div>
  )
})

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const ContextImpactSummaryTable = React.memo(function ContextImpactSummaryTableComponent({
  sources,
  summary,
  onContextImpactSummaryClick,
  onSourceImpactSummaryClick,
  pageSize = 10,
}: {
  sources: SourceDto[]
  onContextImpactSummaryClick: (item: ContextTypeImpactSummary) => void
  onSourceImpactSummaryClick: (item: ContextSourceImpactSummary) => void
  summary: ContextImpactSummaryDto
  pageSize?: number
}) {
  const sourcesById = createIdMap(sources)
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<RowType>([
    createTextColumnConfig({
      title: "Paramètre",
      getTextValue: (row) =>
        isContextImpactSummary(row)
          ? contextTypeToText(row.contextType)
          : isSourceImpactSummary(row)
          ? sourcesById[row.contextSourceId].name
          : JSON.stringify(row),
    }),
    createNumericColumnConfig({
      title: "Mesure de l'impact",
      getValue: (row) => row.impactValue,
      isInt: true,
    }),
    createNumericColumnConfig({
      title: "Fréquence de l'impact",
      getValue: (row) => row.impactFrequency,
      isInt: true,
    }),
  ])

  return (
    <>
      <MaterialTable
        title={`Impact des paramètres`}
        data={(summary.contextSourceImpactSummaries as RowType[]).concat(
          summary.contextTypeImpactSummaries as RowType[],
        )}
        onRowClick={(row) =>
          isContextImpactSummary(row)
            ? onContextImpactSummaryClick(row)
            : isSourceImpactSummary(row)
            ? onSourceImpactSummaryClick(row)
            : null
        }
        columns={columns}
        options={{
          pageSize,
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `context_impact_summaries.csv`,
          }),
        }}
      />
    </>
  )
})
