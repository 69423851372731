/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  DayModelDto,
  DayModelDtoFromJSON,
  DayModelDtoFromJSONTyped,
  DayModelDtoToJSON,
  SourceDto,
  SourceDtoFromJSON,
  SourceDtoFromJSONTyped,
  SourceDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface CreateSourceResultDto
 */
export interface CreateSourceResultDto {
  /**
   *
   * @type {SourceDto}
   * @memberof CreateSourceResultDto
   */
  source: SourceDto
  /**
   * List of days with a matching model
   * @type {Array<DayModelDto>}
   * @memberof CreateSourceResultDto
   */
  days: Array<DayModelDto>
}

export function CreateSourceResultDtoFromJSON(json: any): CreateSourceResultDto {
  return CreateSourceResultDtoFromJSONTyped(json, false)
}

export function CreateSourceResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSourceResultDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    source: SourceDtoFromJSON(json["source"]),
    days: (json["days"] as Array<any>).map(DayModelDtoFromJSON),
  }
}

export function CreateSourceResultDtoToJSON(value?: CreateSourceResultDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    source: SourceDtoToJSON(value.source),
    days: (value.days as Array<any>).map(DayModelDtoToJSON),
  }
}
