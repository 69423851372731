import React from "react"
import { useApiExceptionHandler } from "../state/auth"
import { useSuccessMessage } from "./SuccessMessage"
import { Formik } from "formik"
import { DialogTitle, Dialog, DialogContentText, DialogContent, DialogActions } from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import { AnyChildren } from "../react-type-helpers"
import { AppRoute, AppRouteParams } from "../state/route"
import { useHistory } from "react-router"
import { buildUrl } from "./AppLink"
import { AppButton } from "./AppButton"

export interface ButtonWithConfirmDialogProps {
  buttonText: AnyChildren
  popinTitle: AnyChildren
  popinWarning: AnyChildren
  popinButtonText: AnyChildren
  successMessage?: string
  doAction: () => Promise<any>
  redirectToAfterAction: { route: AppRoute; params?: AppRouteParams }
}

export function ButtonWithConfirmDialog({
  buttonText,
  popinTitle,
  popinWarning,
  popinButtonText,
  successMessage,
  doAction,
  redirectToAfterAction,
}: ButtonWithConfirmDialogProps) {
  const history = useHistory()
  const handleApiException = useApiExceptionHandler()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: successMessage || "",
  })

  return (
    <div>
      <SuccessMessage />
      <Formik<{}>
        initialValues={{}}
        onSubmit={async (__) => {
          try {
            await doAction()
            setDialogOpen(false)
            if (successMessage) {
              showSuccessMessage()
            }

            setTimeout(() => {
              history.push(buildUrl(redirectToAfterAction))
            }, 1000)
          } catch (e) {
            handleApiException(e)
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <AppButton
              variant="contained"
              color="inherit"
              style={{ backgroundColor: red[500], color: "white" }}
              onClick={() => setDialogOpen(true)}
              disabled={isSubmitting}
            >
              {buttonText}
            </AppButton>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>{popinTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText>{popinWarning}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <AppButton onClick={() => setDialogOpen(false)} color="primary" disabled={isSubmitting}>
                  Annuler
                </AppButton>
                <AppButton
                  type="submit"
                  onClick={() => handleSubmit()}
                  color="inherit"
                  variant="contained"
                  style={{ backgroundColor: red[500], color: "white" }}
                  submitting={isSubmitting}
                >
                  {popinButtonText}
                </AppButton>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </div>
  )
}
