/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface AlertCommentDto
 */
export interface AlertCommentDto {
  /**
   * The text comment to set on the alert, max of 5000 characters
   * @type {string}
   * @memberof AlertCommentDto
   */
  comment: string
}

export function AlertCommentDtoFromJSON(json: any): AlertCommentDto {
  return AlertCommentDtoFromJSONTyped(json, false)
}

export function AlertCommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertCommentDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    comment: json["comment"],
  }
}

export function AlertCommentDtoToJSON(value?: AlertCommentDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    comment: value.comment,
  }
}
