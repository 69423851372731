export function getActivityColor(minValue: number, value: number, maxValue: number, baseColor: "r" | "g" | "b" = "r") {
  if (minValue === maxValue && minValue !== 0) {
    minValue = 0
  }
  const easing = (x: number) => x /* linear */
  const percentInterval = (value - minValue) / (maxValue - minValue)
  const shiftFromWhite = 20 /* we don't want to go full white */
  const shiftFromRed = 70 /* not full red either */
  const colorHex = (255 - Math.round((255 - shiftFromWhite - shiftFromRed) * easing(percentInterval)) - shiftFromWhite)
    .toString(16)
    .padStart(2, "0")

  if (baseColor === "r") {
    return `#FF${colorHex}${colorHex}`
  } else if (baseColor === "g") {
    return `#${colorHex}FF${colorHex}`
  } else {
    return `#${colorHex}${colorHex}FF`
  }
}
