import { useRouteMatch } from "react-router-dom"

export type AppRouteParams = {
  groupId?: string
  sourceId?: string
  taskId?: string
}

export enum AppRoute {
  EMPTY_INSTANCE = "/no-data",
  HOME = "/",

  SOURCE_CREATE_HUB = "/source/data-hub",
  SOURCE_CREATE = "/source/import-csv-single",
  SOURCE_CREATE_MULTIPLE = "/source/import-csv-multiple",
  SOURCE_CREATE_EXAMPLE = "/source/import-example",
  SOURCE_ADD_DAYS = "/source/import-days-csv",
  SOURCE_ADD_DAYS_HUB = "/source/data-add-hub",
  SOURCE_IMPORT_HISTORY = "/source/import-history",

  FORECAST_LIST = "/source/forecasts",
  FORECAST_TASK_CREATE = "/source/forecast/create",
  FORECAST_TASK_COPY = "/source/forecast/copy/:taskId",
  FORECAST_TASK_UPDATE = "/source/forecast/update/:taskId",
  FORECAST_TASK_VIEW = "/source/forecast/:taskId",

  SERIE_SYNC_LIST = "/source/serie-sync",
  SERIE_SYNC_TASK_CREATE = "/source/serie-sync/create",
  SERIE_SYNC_TASK_COPY = "/source/serie-sync/copy/:taskId",
  SERIE_SYNC_TASK_UPDATE = "/source/serie-sync/update/:taskId",
  SERIE_SYNC_TASK_VIEW = "/source/serie-sync/:taskId",

  SOURCE_CLUSTER_LIST = "/source/comparaison-source",
  SOURCE_CLUSTER_TASK_CREATE = "/source/comparaison-source/create",
  SOURCE_CLUSTER_TASK_COPY = "/source/comparaison-source/copy/:taskId",
  SOURCE_CLUSTER_TASK_UPDATE = "/source/comparaison-source/update/:taskId",
  SOURCE_CLUSTER_TASK_VIEW = "/source/comparaison-source/:taskId",

  CONTEXT_IMPACT_LIST = "/source/context-impact",
  CONTEXT_IMPACT_TASK_CREATE = "/source/context-impact/create",
  CONTEXT_IMPACT_TASK_COPY = "/source/context-impact/copy/:taskId",
  CONTEXT_IMPACT_TASK_UPDATE = "/source/context-impact/update/:taskId",
  CONTEXT_IMPACT_TASK_VIEW = "/source/context-impact/:taskId",

  DATA_SOURCE_LIST = "/source/mes-sources",
  DATA_SOURCE_TIMELIGHT_LIST = "/source/timelight",
  DATA_SOURCE_CONFIG = "/parametres/source/:sourceId",
  DATA_SOURCE_EXPLORE = "/source/exploration/:sourceId",
  DATA_SOURCE_TIMELIGHT_EXPLORE = "/source/timelight/exploration/:sourceId",

  DATA_SOURCE_HISTORY = "/maitriser/visualiser",
  DATA_SOURCE_ANOMALY = "/maitriser/qualifier",
  DATA_SOURCE_MODELS = "/maitriser/identifier",

  PREVISION_SOURCE = "/prevoir/planification/source",
  PREVISION_GROUPS = "/prevoir/planification/groups",
  PREVISION_GROUP_SOURCES = "/prevoir/planification/group/:groupId",

  MONITOR_ALERTS = "/controler/alertes",
  MONITOR_ACTIVITY = "/controler/activite",

  INVENTORY_ALERTS = "/controler/referentiel-alertes",

  LOGIN = "/login",
  REGISTER = "/inscription",
  AUTH_ERROR = "/auth/error",
}

export function useAppRoute(): { route: AppRoute; params: AppRouteParams } {
  const currentMatch = useRouteMatch<AppRouteParams>()

  const match = Object.entries(AppRoute)
    .map(
      // @ts-ignore
      ([key, value]) => [key, value, AppRoute[key]] as [string, any, AppRoute],
    )
    .find(([_, value]) => value === currentMatch.path)

  if (!match) {
    throw new Error(`Could not find route ${currentMatch.path} in route enum`)
  }
  const matchingRoute = match[2]
  return { route: matchingRoute, params: currentMatch.params }
}
