import React, { useMemo } from "react"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PageBlock from "component/PageBlock"
import { useTranslation } from "react-i18next"
import AppHeader from "../../component/AppHeader"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import SourceSelect from "../../component/SourceSelect"
import { useSourceSelect } from "../../state/persisted"
import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useAllTimelightSourceDays } from "../../state/api"
import { toDateObj, formatDateTime, dateObjToDayString } from "../../lib/date"
import * as lodash from "lodash"
import { formatNumber } from "lib/number"
import { createStrIdMap } from "../../lib/array"
import { createTableExportCsv, createDateColumnConfig } from "../../component/MaterialTable/material-table-helpers"
import { MaterialTable } from "../../component/MaterialTable"
import { SourceDto } from "../../api/timelight-api/models/SourceDto"
import {
  createTextColumnConfig,
  extractColumnConfigAndExportConfig,
} from "../../component/MaterialTable/material-table-helpers"

type HcData = [number, number | null]

export default function SourceExplorationTimelight() {
  // the app bar for values
  const [source, setSourceId] = useSourceSelect({
    /*scope: "public"*/
  })
  const { t } = useTranslation()

  const [{ data: daysData }] = useAllTimelightSourceDays({
    shouldTrigger: source.id !== -1,
    sourceId: source.id,
  })

  const { hcData, minDate: minDateMemo, maxDate: maxDateMemo } = useMemo<{
    hcData: HcData[]
    minDate: string
    maxDate: string
  }>(() => {
    const minDate = daysData ? (lodash.minBy(daysData.days, (d) => d.date)?.date as string) : ""
    const maxDate = daysData ? (lodash.maxBy(daysData.days, (d) => d.date)?.date as string) : ""
    const res: HcData[] = []

    if (daysData && daysData.days.length > 0) {
      const activitySize = daysData.days[0].activity.length
      const stepSize = (24 * 60 * 60 * 1000) / activitySize
      const daysByDate = createStrIdMap(daysData.days, (d) => d.date)
      for (
        let currDate = toDateObj(minDate);
        dateObjToDayString(currDate) <= maxDate;
        currDate = new Date(currDate.getTime() + 24 * 60 * 60 * 1000)
      ) {
        const currDateStr = dateObjToDayString(currDate)
        if (daysByDate[currDateStr]) {
          for (let i = 0; i < activitySize; i++) {
            res.push([currDate.getTime() + i * stepSize, daysByDate[currDateStr].activity[i]])
          }
        } else {
          for (let i = 0; i < activitySize; i++) {
            res.push([currDate.getTime() + i * stepSize, null])
          }
        }
      }
    }
    return { hcData: res, minDate, maxDate }
  }, [daysData])

  const options: Highcharts.Options = {
    time: {
      timezone: "Europe/Paris",
    },
    chart: {
      zoomType: "x",
    },
    title: {
      text: "",
    },

    boost: {
      useGPUTranslations: false,
    },

    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        text: "Activité",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter() {
        return (
          `${formatDateTime(new Date(this.x))}<br/>` +
          this.points?.map((p) => `${p.series.name}: <b>${formatNumber(p.y || 0)}</b><br/>`).join("")
        )
      },
    } as Highcharts.TooltipOptions,

    series: [
      {
        type: "line",
        name: "Activité",
        data: hcData,
      },
    ],
  }
  return (
    <PageContainer title={`Configuration ${t("source.aname")} > ${source.name}`}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 15,
            }}
          >
            Exploration de {t("source.aname")}
            <SourceSelect sourceId={source.id} setSourceId={setSourceId} />
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <PageBlock loading={!daysData} title={`Données de ${t("source.aname")} ${source.name}`}>
          {daysData && <HighchartsReact highcharts={Highcharts} options={options} />}
        </PageBlock>
        <PageBlock loading={!daysData} titleInContent={true}>
          {daysData && <SourceDataTable source={source} hcData={hcData} minDate={minDateMemo} maxDate={maxDateMemo} />}
        </PageBlock>
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const SourceDataTable = React.memo(function SourceDataTableComponent({
  source,
  hcData,
  minDate,
  maxDate,
}: {
  source: SourceDto
  hcData: HcData[]
  minDate: string
  maxDate: string
}) {
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<HcData>([
    createDateColumnConfig({
      title: "Date",
      getDate: (row) => new Date(row[0]),
      domain: [toDateObj(minDate), toDateObj(maxDate)],
    }),
    createTextColumnConfig({
      title: "Activité",
      getTextValue: (row) => (row[1] !== null ? formatNumber(row[1]) : ""),
    }),
  ])

  return (
    <>
      <MaterialTable
        title={`Données de ${source.name}`}
        data={hcData}
        columns={columns}
        options={{
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `source_data_${source.name}.csv`,
          }),
        }}
      />
    </>
  )
})
