/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  GetManySerieSyncTaskResponseDto,
  GetManySerieSyncTaskResponseDtoFromJSON,
  GetManySerieSyncTaskResponseDtoToJSON,
  SerieSyncTask,
  SerieSyncTaskFromJSON,
  SerieSyncTaskToJSON,
  SerieSyncTaskInputParams,
  SerieSyncTaskInputParamsFromJSON,
  SerieSyncTaskInputParamsToJSON,
} from "../models"

export interface GetManyBaseSyncTaskControllerSerieSyncTaskRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseSyncTaskControllerSerieSyncTaskRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface SyncTaskControllerCreateOneRequest {
  serieSyncTaskInputParams: SerieSyncTaskInputParams
}

export interface SyncTaskControllerDeleteOneRequest {
  syncTaskId: number
}

export interface SyncTaskControllerUpdateOneRequest {
  syncTaskId: number
  serieSyncTaskInputParams: SerieSyncTaskInputParams
}

/**
 * no description
 */
export class SerieSynchronisationApi extends runtime.BaseAPI {
  /**
   * Retrieve many SerieSyncTask
   */
  async getManyBaseSyncTaskControllerSerieSyncTaskRaw(
    requestParameters: GetManyBaseSyncTaskControllerSerieSyncTaskRequest,
  ): Promise<runtime.ApiResponse<GetManySerieSyncTaskResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/sync-task`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManySerieSyncTaskResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many SerieSyncTask
   */
  async getManyBaseSyncTaskControllerSerieSyncTask(
    requestParameters: GetManyBaseSyncTaskControllerSerieSyncTaskRequest,
  ): Promise<GetManySerieSyncTaskResponseDto> {
    const response = await this.getManyBaseSyncTaskControllerSerieSyncTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one SerieSyncTask
   */
  async getOneBaseSyncTaskControllerSerieSyncTaskRaw(
    requestParameters: GetOneBaseSyncTaskControllerSerieSyncTaskRequest,
  ): Promise<runtime.ApiResponse<SerieSyncTask>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseSyncTaskControllerSerieSyncTask.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/sync-task/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SerieSyncTaskFromJSON(jsonValue))
  }

  /**
   * Retrieve one SerieSyncTask
   */
  async getOneBaseSyncTaskControllerSerieSyncTask(
    requestParameters: GetOneBaseSyncTaskControllerSerieSyncTaskRequest,
  ): Promise<SerieSyncTask> {
    const response = await this.getOneBaseSyncTaskControllerSerieSyncTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Create a new synchronisation task
   */
  async syncTaskControllerCreateOneRaw(
    requestParameters: SyncTaskControllerCreateOneRequest,
  ): Promise<runtime.ApiResponse<SerieSyncTask>> {
    if (
      requestParameters.serieSyncTaskInputParams === null ||
      requestParameters.serieSyncTaskInputParams === undefined
    ) {
      throw new runtime.RequiredError(
        "serieSyncTaskInputParams",
        "Required parameter requestParameters.serieSyncTaskInputParams was null or undefined when calling syncTaskControllerCreateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/sync-task`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SerieSyncTaskInputParamsToJSON(requestParameters.serieSyncTaskInputParams),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SerieSyncTaskFromJSON(jsonValue))
  }

  /**
   * Create a new synchronisation task
   */
  async syncTaskControllerCreateOne(requestParameters: SyncTaskControllerCreateOneRequest): Promise<SerieSyncTask> {
    const response = await this.syncTaskControllerCreateOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Delete a serie synchronisation task
   */
  async syncTaskControllerDeleteOneRaw(
    requestParameters: SyncTaskControllerDeleteOneRequest,
  ): Promise<runtime.ApiResponse<SerieSyncTask>> {
    if (requestParameters.syncTaskId === null || requestParameters.syncTaskId === undefined) {
      throw new runtime.RequiredError(
        "syncTaskId",
        "Required parameter requestParameters.syncTaskId was null or undefined when calling syncTaskControllerDeleteOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/sync-task/{syncTaskId}`.replace(
        `{${"syncTaskId"}}`,
        encodeURIComponent(String(requestParameters.syncTaskId)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SerieSyncTaskFromJSON(jsonValue))
  }

  /**
   * Delete a serie synchronisation task
   */
  async syncTaskControllerDeleteOne(requestParameters: SyncTaskControllerDeleteOneRequest): Promise<SerieSyncTask> {
    const response = await this.syncTaskControllerDeleteOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update a serie sync params and re-run sync, wipes out previous data
   */
  async syncTaskControllerUpdateOneRaw(
    requestParameters: SyncTaskControllerUpdateOneRequest,
  ): Promise<runtime.ApiResponse<SerieSyncTask>> {
    if (requestParameters.syncTaskId === null || requestParameters.syncTaskId === undefined) {
      throw new runtime.RequiredError(
        "syncTaskId",
        "Required parameter requestParameters.syncTaskId was null or undefined when calling syncTaskControllerUpdateOne.",
      )
    }

    if (
      requestParameters.serieSyncTaskInputParams === null ||
      requestParameters.serieSyncTaskInputParams === undefined
    ) {
      throw new runtime.RequiredError(
        "serieSyncTaskInputParams",
        "Required parameter requestParameters.serieSyncTaskInputParams was null or undefined when calling syncTaskControllerUpdateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/sync-task/{syncTaskId}`.replace(
        `{${"syncTaskId"}}`,
        encodeURIComponent(String(requestParameters.syncTaskId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SerieSyncTaskInputParamsToJSON(requestParameters.serieSyncTaskInputParams),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SerieSyncTaskFromJSON(jsonValue))
  }

  /**
   * Update a serie sync params and re-run sync, wipes out previous data
   */
  async syncTaskControllerUpdateOne(requestParameters: SyncTaskControllerUpdateOneRequest): Promise<SerieSyncTask> {
    const response = await this.syncTaskControllerUpdateOneRaw(requestParameters)
    return await response.value()
  }
}
