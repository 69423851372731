/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface DayModelDto
 */
export interface DayModelDto {
  /**
   *
   * @type {string}
   * @memberof DayModelDto
   */
  date: string
  /**
   *
   * @type {number}
   * @memberof DayModelDto
   */
  value: number
  /**
   *
   * @type {number}
   * @memberof DayModelDto
   */
  inertia: number | null
  /**
   *
   * @type {number}
   * @memberof DayModelDto
   */
  model_id: number | null
  /**
   *
   * @type {boolean}
   * @memberof DayModelDto
   */
  anomaly: boolean
  /**
   *
   * @type {number}
   * @memberof DayModelDto
   */
  x: number | null
  /**
   *
   * @type {number}
   * @memberof DayModelDto
   */
  y: number | null
  /**
   *
   * @type {Array<number>}
   * @memberof DayModelDto
   */
  activity: Array<number>
}

export function DayModelDtoFromJSON(json: any): DayModelDto {
  return DayModelDtoFromJSONTyped(json, false)
}

export function DayModelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayModelDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    date: json["date"],
    value: json["value"],
    inertia: json["inertia"],
    model_id: json["model_id"],
    anomaly: json["anomaly"],
    x: json["x"],
    y: json["y"],
    activity: json["activity"],
  }
}

export function DayModelDtoToJSON(value?: DayModelDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date: value.date,
    value: value.value,
    inertia: value.inertia,
    model_id: value.model_id,
    anomaly: value.anomaly,
    x: value.x,
    y: value.y,
    activity: value.activity,
  }
}
