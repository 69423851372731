import isWindows from "is-windows"

export function arrayToCsvString<T extends object>(
  data: T[],
  {
    getValues = (r) =>
      Array.isArray(r)
        ? r.map((value) => (value === undefined || value === null ? "" : String(value)))
        : Object.values(r),
    header = null,
    separator = ";",
    quote = '"',
    newLine = isWindows() ? "\r\n" : "\n",
  }: {
    getValues?: (row: T) => string[]
    header?: string[] | null
    separator?: string
    quote?: string
    newLine?: string
  },
) {
  const formatValues = (values: string[]) =>
    values
      .map((value) => (value === undefined || value === null ? "" : String(value)))
      .map((value) => value.replace(quote, `${quote}${quote}`))
      .map((value) => `${quote}${value}${quote}`)
      .join(separator)

  return (
    (header ? formatValues(header) + newLine : "") + data.map((row, i) => formatValues(getValues(row))).join(newLine)
  )
}
