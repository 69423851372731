import { ForecastType } from "../../api/timelight-api/models/ForecastType"

export const forecastMethods: { label: string; value: ForecastType; premium: boolean; supportsContext: boolean }[] = [
  { label: "XGBoost", value: ForecastType.XGBOOST, premium: false, supportsContext: true },
  { label: "Sarima", value: ForecastType.AUTOARIMA, premium: false, supportsContext: false },
  { label: "Facebook Prophet", value: ForecastType.FBPROPHET, premium: false, supportsContext: true },
  { label: "Arima AR", value: ForecastType.ARIMASIMPLEAR, premium: false, supportsContext: true },
  { label: "Arima MA", value: ForecastType.ARIMASIMPLEMA, premium: false, supportsContext: true },
  { label: "Arima", value: ForecastType.ARIMASIMPLE, premium: false, supportsContext: true },
  { label: "Holt-Winters", value: ForecastType.HOLTWINTERS, premium: false, supportsContext: true },
  { label: "Linear Regression", value: ForecastType.LINEARREG, premium: false, supportsContext: true },
  { label: "Neural Network", value: ForecastType.NEURALNET, premium: false, supportsContext: true },
  { label: "XGBoost auto trend", value: ForecastType.XGBOOSTDETREND, premium: false, supportsContext: true },
  {
    label: "Neural Network auto trend",
    value: ForecastType.CONSTNEURALNETDETREND,
    premium: false,
    supportsContext: true,
  },
  {
    label: "Linear Regression auto trend",
    value: ForecastType.CONSTLINEARREGDETREND,
    premium: false,
    supportsContext: true,
  },
  { label: "Sarimax", value: ("sarimax" as unknown) as ForecastType, premium: true, supportsContext: true },
  { label: "LSTM", value: ("lstm" as unknown) as ForecastType, premium: true, supportsContext: true },
  { label: "Sur-mesure", value: ("custom" as unknown) as ForecastType, premium: true, supportsContext: true },
]

export const getForecastMethodName = (forecastType: ForecastType) => {
  return forecastMethods.find((method) => method.value === forecastType)?.label
}
