/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface PrevisionApplyGroupDto
 */
export interface PrevisionApplyGroupDto {
  /**
   * The source id apply prevision from
   * @type {number}
   * @memberof PrevisionApplyGroupDto
   */
  sourceId: number
}

export function PrevisionApplyGroupDtoFromJSON(json: any): PrevisionApplyGroupDto {
  return PrevisionApplyGroupDtoFromJSONTyped(json, false)
}

export function PrevisionApplyGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrevisionApplyGroupDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sourceId: json["sourceId"],
  }
}

export function PrevisionApplyGroupDtoToJSON(value?: PrevisionApplyGroupDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
  }
}
