/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SourceYearWithDataListDto
 */
export interface SourceYearWithDataListDto {
  /**
   *
   * @type {Array<number>}
   * @memberof SourceYearWithDataListDto
   */
  dayYears: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SourceYearWithDataListDto
   */
  contextYears: Array<number>
}

export function SourceYearWithDataListDtoFromJSON(json: any): SourceYearWithDataListDto {
  return SourceYearWithDataListDtoFromJSONTyped(json, false)
}

export function SourceYearWithDataListDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SourceYearWithDataListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dayYears: json["dayYears"],
    contextYears: json["contextYears"],
  }
}

export function SourceYearWithDataListDtoToJSON(value?: SourceYearWithDataListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    dayYears: value.dayYears,
    contextYears: value.contextYears,
  }
}
