import React from "react"
import { Field } from "react-final-form"
import * as lodash from "lodash"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"
import { FormControlLabel, Checkbox, FormHelperText } from "@material-ui/core"

export type CheckboxFormState = boolean

export function createCheckboxField<FormState>(props: { name: keyof FormState & string } & CheckboxFieldProps) {
  // @ts-ignore
  return <Field {...props} render={CheckboxFieldRender} />
}

interface CheckboxFieldProps {
  label: string
}

const CheckboxFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<CheckboxFormState, CheckboxFieldProps>) => {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.input.value}
              onChange={(e: any) => props.input.onChange(e.target.checked)}
              value={props.input.name}
              color="primary"
              disabled={props.meta.submitting}
            />
          }
          label={props.label}
        />
        {props.meta.error &&
          (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
            <FormHelperText key={i} error={true}>
              {e}
            </FormHelperText>
          ))}
      </div>
    )
  },
  (props: CustomFieldRenderProps<CheckboxFormState, CheckboxFieldProps>) =>
    props.input.value + "|" + getFormFieldPropsMemoizeKey(props),
)
