/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import { GetManySourceTagsResult, GetManySourceTagsResultFromJSON, GetManySourceTagsResultToJSON } from "../models"

export interface SourceUserTagsControllerGetManyRequest {
  page?: number
  limit?: number
  refSourceId?: number
}

/**
 * no description
 */
export class SourceTagsApi extends runtime.BaseAPI {
  /**
   * Fetch available source tags
   */
  async sourceUserTagsControllerGetManyRaw(
    requestParameters: SourceUserTagsControllerGetManyRequest,
  ): Promise<runtime.ApiResponse<GetManySourceTagsResult>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.refSourceId !== undefined) {
      queryParameters["refSourceId"] = requestParameters.refSourceId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-user-tags`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManySourceTagsResultFromJSON(jsonValue))
  }

  /**
   * Fetch available source tags
   */
  async sourceUserTagsControllerGetMany(
    requestParameters: SourceUserTagsControllerGetManyRequest,
  ): Promise<GetManySourceTagsResult> {
    const response = await this.sourceUserTagsControllerGetManyRaw(requestParameters)
    return await response.value()
  }
}
