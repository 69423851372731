/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  AlertDto,
  AlertDtoFromJSON,
  AlertDtoFromJSONTyped,
  AlertDtoToJSON,
  AlertRefDto,
  AlertRefDtoFromJSON,
  AlertRefDtoFromJSONTyped,
  AlertRefDtoToJSON,
  DayModelDto,
  DayModelDtoFromJSON,
  DayModelDtoFromJSONTyped,
  DayModelDtoToJSON,
  ModelDto,
  ModelDtoFromJSON,
  ModelDtoFromJSONTyped,
  ModelDtoToJSON,
  PrevisionDto,
  PrevisionDtoFromJSON,
  PrevisionDtoFromJSONTyped,
  PrevisionDtoToJSON,
  SourceDto,
  SourceDtoFromJSON,
  SourceDtoFromJSONTyped,
  SourceDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface DaysNearDateResultDto
 */
export interface DaysNearDateResultDto {
  /**
   * List of models
   * @type {Array<ModelDto>}
   * @memberof DaysNearDateResultDto
   */
  models: Array<ModelDto>
  /**
   * List of days around the target date
   * @type {Array<DayModelDto>}
   * @memberof DaysNearDateResultDto
   */
  days: Array<DayModelDto>
  /**
   * List of days near the target date in distance
   * @type {Array<DayModelDto>}
   * @memberof DaysNearDateResultDto
   */
  nearestDistanceDays: Array<DayModelDto>
  /**
   *
   * @type {SourceDto}
   * @memberof DaysNearDateResultDto
   */
  source: SourceDto
  /**
   * List of previsions
   * @type {Array<PrevisionDto>}
   * @memberof DaysNearDateResultDto
   */
  previsions: Array<PrevisionDto>
  /**
   * List of alerts
   * @type {Array<AlertDto>}
   * @memberof DaysNearDateResultDto
   */
  alerts: Array<AlertDto>
  /**
   * List of alerts refs
   * @type {Array<AlertRefDto>}
   * @memberof DaysNearDateResultDto
   */
  alertRefs: Array<AlertRefDto>
}

export function DaysNearDateResultDtoFromJSON(json: any): DaysNearDateResultDto {
  return DaysNearDateResultDtoFromJSONTyped(json, false)
}

export function DaysNearDateResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaysNearDateResultDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    models: (json["models"] as Array<any>).map(ModelDtoFromJSON),
    days: (json["days"] as Array<any>).map(DayModelDtoFromJSON),
    nearestDistanceDays: (json["nearestDistanceDays"] as Array<any>).map(DayModelDtoFromJSON),
    source: SourceDtoFromJSON(json["source"]),
    previsions: (json["previsions"] as Array<any>).map(PrevisionDtoFromJSON),
    alerts: (json["alerts"] as Array<any>).map(AlertDtoFromJSON),
    alertRefs: (json["alertRefs"] as Array<any>).map(AlertRefDtoFromJSON),
  }
}

export function DaysNearDateResultDtoToJSON(value?: DaysNearDateResultDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    models: (value.models as Array<any>).map(ModelDtoToJSON),
    days: (value.days as Array<any>).map(DayModelDtoToJSON),
    nearestDistanceDays: (value.nearestDistanceDays as Array<any>).map(DayModelDtoToJSON),
    source: SourceDtoToJSON(value.source),
    previsions: (value.previsions as Array<any>).map(PrevisionDtoToJSON),
    alerts: (value.alerts as Array<any>).map(AlertDtoToJSON),
    alertRefs: (value.alertRefs as Array<any>).map(AlertRefDtoToJSON),
  }
}
