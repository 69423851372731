import React from "react"
import { formatNumber } from "../../lib/number"
import css from "./ChartTooltip.module.css"
import { contextTypeToText, contextValueToText } from "../DaysNearDateModal/DayContextAnalysis"
import { DayContextTypes } from "../../api/timelight-api/models/DayContextTypes"

export interface ToleranceChartItem {
  x: string
  activity: number
  contextValue?: number
  contextImpact?: number
  top: number
  bottom: number
}

export default function ChartTooltip({
  active,
  payload,
  activityTitle,
  contextType,
}: {
  active: boolean
  payload?: { payload: ToleranceChartItem }[]
  activityTitle: string
  contextType?: DayContextTypes
}) {
  if (active && payload && payload.length && payload[0].payload) {
    return (
      <div className={css.container}>
        <p className={css.date}>{payload[0].payload.x}</p>
        <p className={css.value}>Tolérance haute: {formatNumber(payload[0].payload.top)}</p>
        <p className={css.value}>
          {activityTitle}: {formatNumber(payload[0].payload.activity)}
        </p>
        <p className={css.value}>Tolérance basse: {formatNumber(payload[0].payload.bottom)}</p>
        {contextType && payload[0].payload.contextValue !== undefined && (
          <p className={css.value}>
            {contextTypeToText(contextType)}:{" "}
            {contextValueToText({ ctxType: contextType, value: payload[0].payload.contextValue })}
          </p>
        )}
        {contextType && payload[0].payload.contextImpact !== undefined && (
          <p className={css.value}>Impact du context: {formatNumber(payload[0].payload.contextImpact, true)}</p>
        )}
      </div>
    )
  }

  return null
}
