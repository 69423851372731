/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SourceClusterModel
 */
export interface SourceClusterModel {
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof SourceClusterModel
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof SourceClusterModel
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  readonly companyId: number
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  sourceId: number
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  taskId: number
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  clusterId: number
  /**
   *
   * @type {boolean}
   * @memberof SourceClusterModel
   */
  isAnomaly: boolean
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  projectionX: number
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  projectionY: number
  /**
   *
   * @type {number}
   * @memberof SourceClusterModel
   */
  inertia: number
}

export function SourceClusterModelFromJSON(json: any): SourceClusterModel {
  return SourceClusterModelFromJSONTyped(json, false)
}

export function SourceClusterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceClusterModel {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    sourceId: json["sourceId"],
    taskId: json["taskId"],
    clusterId: json["clusterId"],
    isAnomaly: json["isAnomaly"],
    projectionX: json["projectionX"],
    projectionY: json["projectionY"],
    inertia: json["inertia"],
  }
}

export function SourceClusterModelToJSON(value?: SourceClusterModel | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    taskId: value.taskId,
    clusterId: value.clusterId,
    isAnomaly: value.isAnomaly,
    projectionX: value.projectionX,
    projectionY: value.projectionY,
    inertia: value.inertia,
  }
}
