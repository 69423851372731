import React from "react"
import * as lodash from "lodash"
import { SourceSelectTable } from "../SourceSelectTable"
import { Field } from "react-final-form"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"
import { AnyChildren } from "../../react-type-helpers"
import { FormHelperText } from "@material-ui/core"

export interface SourceTableSelectFormState {
  sourceIds: number[]
  filterByDateDomain: null | { begin: Date; end: Date }
}
interface SourceTableSelectFieldProps {
  title: AnyChildren
  pageSize?: number
}

export function createSourceSelectTableField<FormState>(
  props: { name: keyof FormState & string } & SourceTableSelectFieldProps,
) {
  // @ts-ignore
  return <Field {...props} render={SourceSelectTableFieldRender} />
}

/**
 * Use a memoized wrapper to avoid frequent re-render
 */
const SourceSelectTableFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<SourceTableSelectFormState, SourceTableSelectFieldProps>) => {
    return (
      <div>
        <SourceSelectTable
          initialSourceIds={props.input.value.sourceIds}
          title={props.title}
          pageSize={props.pageSize}
          filterByDateDomain={props.input.value.filterByDateDomain || undefined}
          onChange={(selectedSources) =>
            props.input.onChange({ ...props.input.value, sourceIds: selectedSources.map((s) => s.id) })
          }
        />
        {props.meta.error &&
          (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
            <FormHelperText key={i} error={true}>
              {e}
            </FormHelperText>
          ))}
      </div>
    )
  },
  (props: CustomFieldRenderProps<SourceTableSelectFormState, SourceTableSelectFieldProps>) =>
    props.input.value.sourceIds.sort().join("-") +
    props.input.value.filterByDateDomain?.begin.toISOString() +
    props.input.value.filterByDateDomain?.end.toISOString() +
    getFormFieldPropsMemoizeKey(props),
)
