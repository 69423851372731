export function deepFreeze<T>(o: T) {
  Object.freeze(o)
  if (Array.isArray(o)) {
    o.map(deepFreeze)
  } else if (typeof o === "number" || o === null || o === undefined) {
    return o
  } else {
    Object.getOwnPropertyNames(o).forEach((prop) => {
      if (
        // @ts-ignore
        o.hasOwnProperty(prop) &&
        // @ts-ignore
        o[prop] !== null &&
        // @ts-ignore
        (typeof o[prop] === "object" || typeof o[prop] === "function") &&
        // @ts-ignore
        !Object.isFrozen(o[prop])
      ) {
        // @ts-ignore
        deepFreeze(o[prop])
      }
    })
  }
  return o
}
