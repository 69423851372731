import { SourceClusterDistance } from "../../api/timelight-api/models/SourceClusterDistance"

export const clusterDistances: { label: string; value: SourceClusterDistance }[] = [
  { label: "Euclidienne", value: SourceClusterDistance.EUCLIDE },
  { label: "Manhattan", value: SourceClusterDistance.MANHATTAN },
  { label: "Canberra", value: SourceClusterDistance.CANBERRA },
  { label: "Chebyshev", value: SourceClusterDistance.CHEBYSHEV },
  { label: "χ2", value: SourceClusterDistance.CHISQUARE },
  { label: "Minkowski", value: SourceClusterDistance.MINKOWSKI },
  { label: "Dissim", value: SourceClusterDistance.DISSIM },
  { label: "Mahalanobis", value: SourceClusterDistance.MAHALANOBIS },
  { label: "Dynamic Time Warping", value: SourceClusterDistance.DTW },
  { label: "Lower-Bounding Keogh", value: SourceClusterDistance.LBKEOGH },
  { label: "Edit Distance on Real Signals", value: SourceClusterDistance.EDR },
  { label: "Longest Common Subsequence", value: SourceClusterDistance.LCSS },
  { label: "Edit distance with Real Penalty", value: SourceClusterDistance.ERP },
]

export const getClusterDistanceName = (clusterDistance: SourceClusterDistance) => {
  return clusterDistances.find((distance) => distance.value === clusterDistance)?.label
}
