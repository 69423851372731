/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DayTrendUnitEnum {
  Percent = "percent",
  Unit = "unit",
}

export function DayTrendUnitEnumFromJSON(json: any): DayTrendUnitEnum {
  return DayTrendUnitEnumFromJSONTyped(json, false)
}

export function DayTrendUnitEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayTrendUnitEnum {
  return json as DayTrendUnitEnum
}

export function DayTrendUnitEnumToJSON(value?: DayTrendUnitEnum | null): any {
  return value as any
}
