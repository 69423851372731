import { Typography } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import React from "react"
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Text, Tooltip } from "recharts"

const PieStats = React.memo(function PieStatsComponent({
  total: tmpTotal,
  totalTitle,
  count,
  countTitle,
  color,
  noDataTitle,
}: {
  total: number
  totalTitle: string
  count: number
  countTitle: string
  color: string
  noDataTitle: string
}) {
  // fix the total
  const total = tmpTotal < count ? count : tmpTotal
  const data = [
    { name: countTitle, value: count },
    {
      name: totalTitle,
      value: total - count,
    },
  ]
  const COLORS = [color, "#CCCCCC80"]

  if (!total && !count) {
    return (
      <Paper
        style={{
          width: "250px",
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>{noDataTitle}</Typography>
      </Paper>
    )
  }

  const percent = total !== 0 ? (count / total) * 100 : 0
  const aboveZero = Math.floor(percent).toFixed(0)
  const belowZero = (percent - Math.floor(percent)).toFixed(2).substr(2, 2)

  return (
    <Paper style={{ width: "250px", height: "150px" }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            cy={110}
            data={data}
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            animationDuration={300}
            label={(item) => (
              <text
                fill="#000"
                x={item.x}
                y={item.y}
                stroke="none"
                alignmentBaseline="middle"
                className="recharts-text recharts-pie-label-text"
                textAnchor="end"
              >
                <tspan x={item.x} textAnchor={item.textAnchor} dy="0em">
                  {item.value}
                </tspan>
              </text>
            )}
          >
            <Label
              content={(props) => {
                const {
                  viewBox: { cx, cy },
                } = props
                const textY =
                  cy - 15 /* align the text baseline with the bottom of the chart*/ - 10 /* this is the top row */
                const textProps = {
                  x:
                    cx +
                    10 +
                    (percent >= 100 ? 10 : 0) +
                    (percent < 100 && percent > 10 ? 2 : 0) +
                    (percent <= 10 ? -6 : 0),
                  y: textY,
                  textAnchor: "end" as "end",
                  verticalAnchor: "middle" as "middle",
                  fill: "#000000",
                  fontSize: 25,
                }
                return (
                  <>
                    <Text {...textProps} x={textProps.x - 15}>
                      {aboveZero}
                    </Text>
                    <Text {...textProps} fontSize={10} y={textProps.y + 5}>
                      {"." + belowZero}
                    </Text>
                    <Text {...textProps} x={textProps.x + 22}>
                      {"%"}
                    </Text>
                    <Text
                      x={cx}
                      y={cy - 5 /* align the text baseline with the bottom of the chart*/}
                      textAnchor="middle"
                      verticalAnchor="middle"
                      fill="#000000"
                      fontSize={10}
                    >
                      {countTitle}
                    </Text>
                  </>
                )
              }}
            />
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  )
})

export default PieStats
