import React, { useContext } from "react"
import { AnyChildren } from "../react-type-helpers"
import { useAsyncAction, AsyncHookParams } from "./async"
import { useApiClient } from "./api"
import { useAuth } from "./auth"

// the current app route
interface OnboardingContextState {
  hasSource: boolean | null // null == we don't know yet
  sourceAdded: () => void
  sourceRemoved: () => void
}

const OnboardingContext = React.createContext<OnboardingContextState>({
  hasSource: null,
  sourceAdded: () => {},
  sourceRemoved: () => {},
})

function useHasSource() {
  const [rndNum, setRndNum] = React.useState<number>(Math.random())
  const api = useApiClient()
  const [{ data: hasSource }] = useAsyncAction<boolean, AsyncHookParams<{ invalidate: number }>>(
    async () => {
      const sources = await api.getManyBaseSourceControllerSourceDto({ limit: 1 })
      return sources.count > 0
    },
    { shouldTrigger: true, invalidate: rndNum },
  )

  return {
    hasSource,
    invalidate: () => {
      setRndNum(Math.random())
    },
  }
}

function AuthenticatedOnboardingContextProvider({ children }: { children: AnyChildren }) {
  const { hasSource, invalidate } = useHasSource()
  // aaaaand make it available
  return (
    <OnboardingContext.Provider
      value={{
        hasSource,
        sourceAdded: () => {
          if (!hasSource) {
            invalidate()
          }
        },
        sourceRemoved: () => {
          if (hasSource) {
            invalidate()
          }
        },
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}

export function OnboardingContextProvider({ children }: { children: AnyChildren }) {
  const { isAuthenticated } = useAuth()
  if (isAuthenticated) {
    return <AuthenticatedOnboardingContextProvider>{children}</AuthenticatedOnboardingContextProvider>
  } else {
    return <>{children}</>
  }
}

export function useOnboardingState(): OnboardingContextState {
  return useContext(OnboardingContext)
}
