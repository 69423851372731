import React from "react"
import { ButtonProps, Button } from "@material-ui/core"
import Loader from "./Loader"

export function AppButton({
  inverted = false,
  submitting = false,
  ...props
}: ButtonProps & { submitting?: boolean; inverted?: boolean; component?: string /* i don't know why it's needed */ }) {
  return (
    <Button {...props} disabled={submitting ? true : props.disabled}>
      {submitting ? <Loader /> : props.children}
    </Button>
  )
}
