import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { AppTypesType } from "../config"

export default function initI18n(appType: AppTypesType) {
  const translation =
    appType === "SECURE_ENERGY"
      ? {
          global: {
            appTitle: "Secure Energy",
            appTitleShort: "SE",
          },
          dayContext: {
            notAvailable: "Aucune données météo disponible pour cette date",
            title: "Données météo",
          },
          anomaly: {
            autoDetectButton: "Détecter automatiquement les erreurs de télé-relève",
          },
          trend: {
            title: "Tendance",
            updateSuccess: "Tendances mises à jour",
            leaveWarning: "Vous perdrez vos modifications de tendances si vous changez de page maintenant",
            introText: "Intégrez des tendances pour refléter les variations d'activités attendues",
            saveButton: "Sauvegarder les tendances",
          },
          source: {
            name: "capteur",
            names: "capteurs",
            Name: "Capteur",
            onename: "un capteur",
            aname: "du capteur",
            thename: "le capteur",
            thisname: "ce capteur",
          },
        }
      : {
          global: {
            appTitle: "Timelight",
            appTitleShort: "TL",
          },
          dayContext: {
            notAvailable: "Aucune données contextuelles disponible pour cette date",
            title: "Données contextuelles",
          },
          anomaly: {
            autoDetectButton: "Détecter automatiquement les anomalies",
          },
          trend: {
            title: "Tendance",
            updateSuccess: "Tendances mises à jour",
            leaveWarning: "Vous perdrez vos modifications de tendances si vous changez de page maintenant",
            introText: "Intégrez des tendances pour refléter les variations d'activités attendues",
            saveButton: "Sauvegarder les tendances",
          },
          source: {
            name: "source",
            names: "sources",
            Name: "Source",
            onename: "une source",
            aname: "de la source",
            thename: "la source",
            thisname: "cette source",
          },
        }

  // the translations
  // (tip move them in a JSON file and import them)
  const resources = {
    fr: { translation },
  }

  // @ts-ignore
  // tslint:disable-next-line:no-floating-promises
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "fr",

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })

  return i18n
}
