import clsx from "clsx"
import React from "react"
import { Link, Redirect } from "react-router-dom"
import { AnyChildren } from "../../react-type-helpers"
import { AppRoute, AppRouteParams } from "../../state/route"
import css from "./AppLink.module.css"

export const LinkCss = css

export default function AppLink({
  route,
  params,
  linkStyle = false,
  children,
  className,
}: {
  route: AppRoute
  linkStyle?: boolean
  params?: AppRouteParams
  children: AnyChildren
  className?: string
}) {
  return (
    <Link
      className={clsx(linkStyle ? "" : css.link, className)}
      to={buildUrl({ route, params })}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Link>
  )
}

export function AppRedirect({ route, params }: { route: AppRoute; params?: AppRouteParams }) {
  return <Redirect push to={buildUrl({ route, params })} />
}

export function buildUrl({ route, params = {} }: { route: AppRoute; params?: AppRouteParams }) {
  let url = route as string
  for (const [key, value] of Object.entries(params)) {
    url = url.replace(`:${key}`, value + "")
  }
  return url
}
