/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface DayTrendInput
 */
export interface DayTrendInput {
  /**
   *
   * @type {string}
   * @memberof DayTrendInput
   */
  startDayDate: string
  /**
   *
   * @type {string}
   * @memberof DayTrendInput
   */
  stopDayDate: string | null
  /**
   *
   * @type {string}
   * @memberof DayTrendInput
   */
  unit: DayTrendInputUnitEnum
  /**
   *
   * @type {string}
   * @memberof DayTrendInput
   */
  type: DayTrendInputTypeEnum
  /**
   *
   * @type {number}
   * @memberof DayTrendInput
   */
  value: number
}

export function DayTrendInputFromJSON(json: any): DayTrendInput {
  return DayTrendInputFromJSONTyped(json, false)
}

export function DayTrendInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayTrendInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    startDayDate: json["startDayDate"],
    stopDayDate: json["stopDayDate"],
    unit: json["unit"],
    type: json["type"],
    value: json["value"],
  }
}

export function DayTrendInputToJSON(value?: DayTrendInput | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    startDayDate: value.startDayDate,
    stopDayDate: value.stopDayDate,
    unit: value.unit,
    type: value.type,
    value: value.value,
  }
}

/**
 * @export
 * @enum {string}
 */
export enum DayTrendInputUnitEnum {
  Percent = "percent",
  Unit = "unit",
}
/**
 * @export
 * @enum {string}
 */
export enum DayTrendInputTypeEnum {
  OneShot = "one-shot",
  Weekly = "weekly",
  Monthly = "monthly",
}
