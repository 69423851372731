// tslint:disable:no-bitwise
// https://css-tricks.com/converting-color-spaces-in-javascript/
export function hexToRGB(h: string) {
  let r = "0"
  let g = "0"
  let b = "0"

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1]
    g = "0x" + h[2] + h[2]
    b = "0x" + h[3] + h[3]

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2]
    g = "0x" + h[3] + h[4]
    b = "0x" + h[5] + h[6]
  }

  return { r: parseInt(r, 16), g: parseInt(g, 16), b: parseInt(b, 16) }
}

// YIQ equation from http://24ways.org/2010/calculating-color-contrast
export function isDarkColor(hexString: string) {
  const rgb = hexToRGB(hexString)
  const yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
  return yiq < 128
}

/* (#FFFFFF, 0.5) -> "rgba(255, 255, 255, 0.5)" */
export function hexToRGBA(hexString: string, alpha: number) {
  const cc = hexToRGB(hexString)
  return `rgba(${cc.r}, ${cc.g}, ${cc.b}, ${alpha.toFixed(2)})`
}

// https://css-tricks.com/snippets/javascript/lighten-darken-color/
export function lightenDarkenColor(col: string, amt: number): string {
  let usePound = false

  if (col[0] === "#") {
    col = col.slice(1)
    usePound = true
  }

  const num = parseInt(col, 16)

  let r = (num >> 16) + amt

  if (r > 255) {
    r = 255
  } else if (r < 0) {
    r = 0
  }

  let b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) {
    b = 255
  } else if (b < 0) {
    b = 0
  }

  let g = (num & 0x0000ff) + amt

  if (g > 255) {
    g = 255
  } else if (g < 0) {
    g = 0
  }

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16)
}
