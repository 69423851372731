import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AlertIcon from "@material-ui/icons/Warning"
import React from "react"
import { AlertRefDto } from "api/timelight-api"
import { formatNumber } from "../../lib/number"
import AlertActivity from "../AlertActivity"
import AlertAnomaly from "../AlertAnomaly"
import AlertBetterModel from "../AlertBetterModel"
import AlertShape from "../AlertShape"
import Title from "../Title"

const useStyles = makeStyles({
  diagnosticContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
})

export function AlertRefView({ alert }: { alert: AlertRefDto }) {
  const classes = useStyles()

  return (
    <div className={classes.diagnosticContainer}>
      <Grid container>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Title icon={<AlertIcon />} warning={true}>
            Cette alerte a été traitée
          </Title>
          <Grid container direction="column" style={{ marginLeft: "4em" }}>
            <p style={{ marginRight: 5 }}>Criticité: {formatNumber(alert.criticity)}</p>
            <AlertAnomaly alert={alert} large={true} />
            <AlertShape alert={alert} large={true} />
            <AlertActivity alert={alert} large={true} />
            <AlertBetterModel
              alert={alert}
              getModelName={(a) => a.betterModelName || ""}
              getModelColor={(a) => a.betterModelColor || ""}
            />
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Title>Commentaire utilisateur</Title>
          <p>{alert.comment}</p>
        </Grid>
      </Grid>
    </div>
  )
}
