import { makeStyles } from "@material-ui/core"
import React from "react"
import { hexToRGBA, isDarkColor } from "../lib/color"

const useStyle = makeStyles({
  modelName: {
    textAlign: "center",
    maxWidth: "200px",
    borderRadius: "5px",
    padding: "0.5em 3em",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
  },
})

export default function ModelTag({ model }: { model: { color: string; name: string } }) {
  const classes = useStyle()
  return (
    <div
      className={classes.modelName}
      style={{
        color: isDarkColor(model.color) ? "white" : "black",
        backgroundColor: hexToRGBA(model.color, 0.75),
      }}
    >
      {model.name}
    </div>
  )
}
