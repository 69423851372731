import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { AppRoute } from "state/route"
import AppLink from "../../component/AppLink"
import MadeWithLove from "../../component/MadeWithLove"
import { useConfig } from "../../config"
import { getAllUrlParams } from "../../lib/url"
import { SocialLoginButtons } from "component/SocialLoginButtons"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login-bg.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  errorMessageContainer: {
    fontSize: "1.5em",
  },
  errorMessage: {
    color: "red",
  },
}))
export function AuthError() {
  const classes = useStyles()
  const { t } = useTranslation()
  const params = getAllUrlParams()
  const { USER_SIGN_UP_ENABLED } = useConfig()

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Helmet>
            <title>{t("global.appTitle")} - Identification</title>
          </Helmet>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h6">
            {t("global.appTitle")} - Identification
          </Typography>
          <div className={classes.container}>
            <p className={classes.errorMessageContainer}>
              Une erreur s'est produite: <span className={classes.errorMessage}>{params.error}</span>
            </p>
            <SocialLoginButtons mode="generic" />
          </div>

          <div>
            <span>Déjà inscrit ? </span>
            <AppLink linkStyle={true} route={AppRoute.LOGIN}>
              Connectez vous
            </AppLink>
          </div>
          {USER_SIGN_UP_ENABLED && (
            <div>
              <span>Pas encore de compte ? </span>
              <AppLink linkStyle={true} route={AppRoute.REGISTER}>
                Créer mon compte
              </AppLink>
            </div>
          )}
          <Box mt={5}>
            <MadeWithLove />
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}
