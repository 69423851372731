import { makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import clsx from "clsx"
import * as lodash from "lodash"
import React, { ComponentType } from "react"
import AsyncSelect from "react-select/async"
import { ValueType } from "react-select/src/types"
import Loader from "./Loader"
import { useApiClient, useSource } from "../state/api"
import { useAsyncAction, AsyncHookParams } from "../state/async"
import { components, SingleValueProps } from "react-select"
import { GetManySourceDtoResponseDto } from "../api/timelight-api/models/GetManySourceDtoResponseDto"

const SingleValue: ComponentType<SingleValueProps<{ value: number; label: string }>> = ({ children, ...props }) => {
  const isOpen = props.selectProps.menuIsOpen
  return (
    <components.SingleValue {...props}>
      {isOpen ? (
        <span style={{ display: "flex", alignItems: "center", color: "#797979" }}>
          <SearchIcon style={{ marginRight: "0.2em" }} />
          Recherchez une source
        </span>
      ) : (
        children
      )}
    </components.SingleValue>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 400,
    color: "black",
    cursor: "pointer",
  },
}))

export default function SourceSelect({
  sourceIdExclusions = [],
  className = "",
  sourceId,
  setSourceId,
  isClearable = false,
}: {
  className?: string
  sourceId: number | null
  setSourceId: (sourceId: number) => void
  sourceIdExclusions?: number[]
  isClearable?: boolean
}) {
  const api = useApiClient()
  const classes = useStyles()

  const [{ data: initialSources }] = useAsyncAction<GetManySourceDtoResponseDto, AsyncHookParams<{}>>(
    async () => {
      return api.getManyBaseSourceControllerSourceDto({ limit: 1000 })
    },
    {
      shouldTrigger: true,
    },
  )

  const [{ data: source }] = useSource({
    shouldTrigger: (sourceId && sourceId !== -1) || false,
    sourceId: sourceId || -1,
  })
  return (
    <div className={clsx(classes.container, className)}>
      {initialSources && (sourceId && sourceId !== -1 ? !!source : true) ? (
        <AsyncSelect
          isClearable={isClearable}
          styles={{ menu: (provided) => ({ ...provided, width: "initial" }) }}
          components={{ SingleValue }}
          cacheOptions={true}
          value={sourceId && sourceId !== -1 && source ? { value: source.id, label: source.name } : undefined}
          defaultOptions={initialSources.data.map((s) => ({ value: s.id, label: s.name }))}
          onChange={(val: ValueType<{ value: number; label: string }>) => {
            if (!val && !isClearable) {
              throw new Error("No source selected")
            }
            if (!val && isClearable) {
              // @ts-ignore
              setSourceId(null)
            } else {
              if (Array.isArray(val)) {
                throw new Error("Multi-value unexpected")
              }
              const selectedId = ((val as unknown) as {
                value: number
                label: string
              }).value
              setSourceId(selectedId)
            }
          }}
          isOptionDisabled={(o) => sourceIdExclusions.includes(o.value)}
          loadOptions={async (inputValue) => {
            const res = await api.getManyBaseSourceControllerSourceDto({
              limit: 20,
              s: JSON.stringify({ $or: [{ tags: { $cont: inputValue } }, { name: { $contL: inputValue } }] }),
            })
            return lodash
              .orderBy(res.data, [(s) => s.name.toLocaleLowerCase()])
              .filter((s) => !sourceIdExclusions.includes(s.id))
              .map((s) => ({ label: s.name, value: s.id }))
          }}
        />
      ) : (
        <Loader />
      )}
    </div>
  )
}
