import React from "react"
import { Field } from "react-final-form"
import SourceSelect from "../SourceSelect"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"
import { FormHelperText } from "@material-ui/core"
import * as lodash from "lodash"

export interface SourceSelectFormState {
  sourceId: number | null
  sourceIdExclusions?: number[]
}

export function createSourceSelectField<FormState>(props: { name: keyof FormState & string; required?: boolean }) {
  return <Field {...props} render={SourceSelectFieldRender} />
}

// we cannot memoize this renderer as the underlying component sets the source
// this should not have happened but hey we're here already
const SourceSelectFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<SourceSelectFormState, {}>) => {
    return (
      <div>
        <SourceSelect
          sourceId={props.input.value.sourceId}
          setSourceId={(id) => props.input.onChange({ ...props.input.value, sourceId: id })}
          sourceIdExclusions={props.input.value.sourceIdExclusions}
          isClearable={true}
        />
        {props.meta.error &&
          (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
            <FormHelperText key={i} error={true}>
              {e}
            </FormHelperText>
          ))}
      </div>
    )
  },
  (props: CustomFieldRenderProps<SourceSelectFormState, {}>) => {
    return (
      "" +
      props.input.value.sourceId +
      "" +
      props.input.value.sourceIdExclusions?.sort().join("-") +
      getFormFieldPropsMemoizeKey(props)
    )
  },
)
