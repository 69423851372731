/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SerieBlockTimeSpan {
  _50w = "50w",
  _4w = "4w",
  _1w = "1w",
  _1d = "1d",
  _1h = "1h",
}

export function SerieBlockTimeSpanFromJSON(json: any): SerieBlockTimeSpan {
  return SerieBlockTimeSpanFromJSONTyped(json, false)
}

export function SerieBlockTimeSpanFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerieBlockTimeSpan {
  return json as SerieBlockTimeSpan
}

export function SerieBlockTimeSpanToJSON(value?: SerieBlockTimeSpan | null): any {
  return value as any
}
