import { Divider, Menu, MenuItem, Typography } from "@material-ui/core"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import AccountCircle from "@material-ui/icons/AccountCircle"
import LogoutIcon from "@material-ui/icons/PowerSettingsNew"
import ApiIcon from "@material-ui/icons/Code"
import React, { useState } from "react"
import { useAuth } from "../state/auth"
import { useCurrentUser } from "../state/currentUser"
import { useToggle } from "../state/toggle"
import { LinkCss } from "./AppLink"
import { useConfig } from "../config"
import { AppButton } from "./AppButton"

export default function HeaderUser() {
  const { logout } = useAuth()
  const { API_URL } = useConfig()
  const { open, doOpen, doClose } = useToggle(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [{ data }] = useCurrentUser({ shouldTrigger: true })
  return (
    <div ref={setAnchorEl}>
      <div>
        <AppButton onClick={doOpen} color="inherit">
          {data && <Typography>{data.displayName}</Typography>}
          <AccountCircle style={{ marginLeft: "10px" }} />
        </AppButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={doClose}
        >
          <MenuItem component="a" className={LinkCss.link} target="_blank" rel="noopener noreferrer" href={API_URL}>
            <ListItemIcon>
              <ApiIcon />
            </ListItemIcon>
            Documentation de l'API
          </MenuItem>
          <Divider />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Déconnexion
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}
