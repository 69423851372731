/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface FetchSerieDataExpandedResult
 */
export interface FetchSerieDataExpandedResult {
  /**
   * The serie identifier.
   * @type {number}
   * @memberof FetchSerieDataExpandedResult
   */
  serieId: number
  /**
   * The serie metadata
   * @type {object}
   * @memberof FetchSerieDataExpandedResult
   */
  serieTags: object
  /**
   * The data. Each row is in format:        [ timestamp, metric 1 data, metric 2 data, ..., metric N data ]
   * @type {Array<object>}
   * @memberof FetchSerieDataExpandedResult
   */
  data: Array<object>
}

export function FetchSerieDataExpandedResultFromJSON(json: any): FetchSerieDataExpandedResult {
  return FetchSerieDataExpandedResultFromJSONTyped(json, false)
}

export function FetchSerieDataExpandedResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FetchSerieDataExpandedResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    serieId: json["serieId"],
    serieTags: json["serieTags"],
    data: json["data"],
  }
}

export function FetchSerieDataExpandedResultToJSON(value?: FetchSerieDataExpandedResult | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    serieId: value.serieId,
    serieTags: value.serieTags,
    data: value.data,
  }
}
