/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SourcePatchDto
 */
export interface SourcePatchDto {
  /**
   * The source name
   * @type {string}
   * @memberof SourcePatchDto
   */
  name?: string
  /**
   * The source ability to generate alerts from previsions
   * @type {boolean}
   * @memberof SourcePatchDto
   */
  previsionEnabled?: boolean
  /**
   * If true, enable meteo data synchronization
   * @type {boolean}
   * @memberof SourcePatchDto
   */
  syncMeteo?: boolean
  /**
   * The source latitude
   * @type {number}
   * @memberof SourcePatchDto
   */
  latitude?: number
  /**
   * The source longitude
   * @type {number}
   * @memberof SourcePatchDto
   */
  longitude?: number
  /**
   * The source physical address
   * @type {string}
   * @memberof SourcePatchDto
   */
  address?: string
  /**
   * The source minimum criticity to trigger an alert
   * @type {number}
   * @memberof SourcePatchDto
   */
  alertMinCriticity?: number
  /**
   * The source maximum criticity to trigger an alert
   * @type {number}
   * @memberof SourcePatchDto
   */
  alertMaxCriticity?: number
  /**
   *
   * @type {boolean}
   * @memberof SourcePatchDto
   */
  isPublic?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof SourcePatchDto
   */
  tags?: Array<string>
}

export function SourcePatchDtoFromJSON(json: any): SourcePatchDto {
  return SourcePatchDtoFromJSONTyped(json, false)
}

export function SourcePatchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourcePatchDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    previsionEnabled: !exists(json, "previsionEnabled") ? undefined : json["previsionEnabled"],
    syncMeteo: !exists(json, "syncMeteo") ? undefined : json["syncMeteo"],
    latitude: !exists(json, "latitude") ? undefined : json["latitude"],
    longitude: !exists(json, "longitude") ? undefined : json["longitude"],
    address: !exists(json, "address") ? undefined : json["address"],
    alertMinCriticity: !exists(json, "alertMinCriticity") ? undefined : json["alertMinCriticity"],
    alertMaxCriticity: !exists(json, "alertMaxCriticity") ? undefined : json["alertMaxCriticity"],
    isPublic: !exists(json, "isPublic") ? undefined : json["isPublic"],
    tags: !exists(json, "tags") ? undefined : json["tags"],
  }
}

export function SourcePatchDtoToJSON(value?: SourcePatchDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    previsionEnabled: value.previsionEnabled,
    syncMeteo: value.syncMeteo,
    latitude: value.latitude,
    longitude: value.longitude,
    address: value.address,
    alertMinCriticity: value.alertMinCriticity,
    alertMaxCriticity: value.alertMaxCriticity,
    isPublic: value.isPublic,
    tags: value.tags,
  }
}
