import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import UploadIcon from "@material-ui/icons/CloudUpload"
import ApiIcon from "@material-ui/icons/Code"
import clsx from "clsx"
import PageBlock from "component/PageBlock"
import React from "react"
import AppHeader from "../../component/AppHeader"
import AppLink from "../../component/AppLink"
import { LinkCss } from "../../component/AppLink"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import { useConfig } from "../../config"
import { useInstanceHasAtLeastNSources } from "../../state/api"
import { AppRoute } from "../../state/route"
import SideMenu from "component/SideMenu"

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(4),
    minHeight: "80vh",
  },
  introText: {
    textAlign: "center",
    lineHeight: 1,
  },
  optionBlock: {
    width: "250px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "4em",
    marginBottom: "2em",
    minWidth: "200px",
    boxSizing: "border-box",
    backgroundColor: "rgba(247, 248, 250, 0.19)",
    boxShadow: "1px 3px 12px -5px " + theme.palette.primary.light,
    borderRadius: "5px",
    transition: "color 100ms ease, background-color 100ms ease, box-shadow 200ms ease",
    "&:hover": {
      boxShadow: "2px 5px 28px 1px " + theme.palette.primary.main,
    },
  },
  optionBlockIcon: {
    color: theme.palette.primary.main,
    width: "2em",
    height: "2em",
  },
  optionBlockImage: {
    marginTop: "1em",
    width: "100px",
    minWidth: "100px",
    height: "30px",
    minHeight: "30px",
  },
}))

export default function SourceAddDaysHub() {
  const classes = useStyles()
  const { API_URL } = useConfig()
  const [{ data: hasData }] = useInstanceHasAtLeastNSources({ shouldTrigger: true, n: 1 })

  return (
    <PageContainer title="Ajouter des données">
      <AppHeader color={true} openable={true} />
      <SideMenu />
      <PageContent noMenu={true}>
        {hasData && (
          <PageBlock title={`Ajout de données récentes.`}>
            <p>Ajoutez des données récentes en utilisant une des méthodes ci-dessous:</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                alignItems: "stretch",
                paddingTop: "4em",
              }}
            >
              <a
                className={clsx(classes.optionBlock, LinkCss.link)}
                target="_blank"
                rel="noopener noreferrer"
                href={API_URL + "/docs/import-donnees"}
              >
                <Typography>Ajouter des données par API</Typography>
                <ApiIcon className={classes.optionBlockIcon} />
              </a>

              <AppLink className={classes.optionBlock} route={AppRoute.SOURCE_ADD_DAYS}>
                <Typography>Ajouter des données récentes avec un fichier CSV.</Typography>
                <UploadIcon className={classes.optionBlockIcon} />
              </AppLink>
            </div>
          </PageBlock>
        )}
      </PageContent>
    </PageContainer>
  )
}
