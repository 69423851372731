import React from "react"

export type AppTypesType = "TIMELIGHT" | "SECURE_ENERGY"

// the current app route
interface ConfigContextState {
  API_URL: string
  AUTH_COOKIE_NAME: string
  AUTH_COOKIE_DOMAIN: string
  APP_TYPE: AppTypesType
  CONTACT_FORM_URL: string
  MAX_CROSS_SOURCE_AMOUNT: number
  USER_SIGN_UP_ENABLED: boolean
  GOOGLE_ANALYTICS_UA: string
  GMAPS_API_KEY: string
  SOCIAL_AUTH_ENABLED: boolean
}

// parse config from env
const defaultConfig: ConfigContextState = {
  // CRA env need the REACT_APP_ prefix
  // https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
  API_URL: process.env.REACT_APP_API_URL || "http://localhost:3000",
  AUTH_COOKIE_NAME: process.env.REACT_APP_AUTH_COOKIE_NAME || "jwt",
  AUTH_COOKIE_DOMAIN: process.env.REACT_APP_AUTH_COOKIE_DOMAIN || "localhost",
  APP_TYPE: ((process.env.REACT_APP_APP_TYPE || "TIMELIGHT") as unknown) as AppTypesType,
  USER_SIGN_UP_ENABLED: process.env.REACT_APP_USER_SIGN_UP_ENABLED === "true" || false,
  CONTACT_FORM_URL: process.env.REACT_APP_CONTACT_FORM_URL || "https://secure-energy.tech/#contact",
  MAX_CROSS_SOURCE_AMOUNT: parseInt(process.env.REACT_APP_MAX_CROSS_SOURCE_AMOUNT || "100", 10),
  GOOGLE_ANALYTICS_UA: process.env.REACT_APP_GOOGLE_ANALYTICS_UA || "UA-142391010-1",
  GMAPS_API_KEY: process.env.REACT_APP_GMAPS_API_KEY || "AIzaSyB7R9X2ovECDNmRrUfG8fW0dMaG_nSn278",
  SOCIAL_AUTH_ENABLED: process.env.REACT_APP_SOCIAL_AUTH_ENABLED === "true" || false,
}

export interface ConfigFile {
  API_URL: string
  AUTH_COOKIE_NAME: string
  AUTH_COOKIE_DOMAIN: string
  APP_TYPE: AppTypesType
  MAX_CROSS_SOURCE_AMOUNT: string
  USER_SIGN_UP_ENABLED: string
  CONTACT_FORM_URL: string
  GOOGLE_ANALYTICS_UA: string
  GMAPS_API_KEY: string
  SOCIAL_AUTH_ENABLED: string
}
export function parseConfigFile(content: Partial<ConfigFile>): ConfigContextState {
  return {
    API_URL: content.API_URL || defaultConfig.API_URL,
    AUTH_COOKIE_NAME: content.AUTH_COOKIE_NAME || defaultConfig.AUTH_COOKIE_NAME,
    AUTH_COOKIE_DOMAIN: content.AUTH_COOKIE_DOMAIN || defaultConfig.AUTH_COOKIE_DOMAIN,
    APP_TYPE: content.APP_TYPE || defaultConfig.APP_TYPE,
    USER_SIGN_UP_ENABLED: content.USER_SIGN_UP_ENABLED === "true" || defaultConfig.USER_SIGN_UP_ENABLED,
    MAX_CROSS_SOURCE_AMOUNT: parseInt(
      content.MAX_CROSS_SOURCE_AMOUNT || defaultConfig.MAX_CROSS_SOURCE_AMOUNT + "",
      10,
    ),
    CONTACT_FORM_URL: content.CONTACT_FORM_URL || defaultConfig.CONTACT_FORM_URL,
    GOOGLE_ANALYTICS_UA: content.GOOGLE_ANALYTICS_UA || defaultConfig.GOOGLE_ANALYTICS_UA,
    GMAPS_API_KEY: content.GMAPS_API_KEY || defaultConfig.GMAPS_API_KEY,
    SOCIAL_AUTH_ENABLED: content.SOCIAL_AUTH_ENABLED === "true" || defaultConfig.SOCIAL_AUTH_ENABLED,
  }
}

const ConfigContext = React.createContext<ConfigContextState>(defaultConfig)

export const ConfigContextProvider = ConfigContext.Provider
export const ConfigContextConsumer = ConfigContext.Consumer

export function useConfig(): ConfigContextState {
  return React.useContext(ConfigContext)
}
