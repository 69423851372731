import { Tooltip } from "@material-ui/core"
import KoIcon from "@material-ui/icons/LocationDisabled"
import OkIcon from "@material-ui/icons/MyLocation"
import React from "react"

export default function AlertBetterModel<T>({
  alert,
  getModelName,
  getModelColor,
}: {
  alert: T
  getModelName: (alert: T) => string | null
  getModelColor?: (alert: T) => string | null
}) {
  const modelName = getModelName(alert)
  const modelColor = getModelColor ? getModelColor(alert) : null
  const Icon = modelName ? KoIcon : OkIcon

  return (
    <>
      <Tooltip title={modelName ? "Meilleur profil type détecté : " + modelName : "Profil type prévu conforme"}>
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              fontSize: "30px",
              marginRight: "5px",
              color: modelName ? "#e05757" : "#ccc",
            }}
          >
            <Icon color="inherit" fontSize="inherit" />
          </span>
          <span
            style={{
              whiteSpace: "nowrap",
              color: modelColor || "#CCC",
              fontWeight: 800,
            }}
          >
            {modelName ? modelName : "Profil type conforme"}
          </span>
        </span>
      </Tooltip>
    </>
  )
}
