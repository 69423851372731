import React from "react"
import { useToggle } from "../state/toggle"

import { AnyComponent } from "../react-type-helpers"
import AppSnackbar from "./AppSnackbar"

export function useSuccessMessage({ message }: { message: string }): [AnyComponent<{}>, () => void] {
  const { open, doOpen, doClose } = useToggle(false)

  return [() => <AppSnackbar message={message} open={open} onClose={doClose} variant="success" />, doOpen]
}
