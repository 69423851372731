import React from "react"
import Loader from "./Loader"
import * as Highcharts from "highcharts"
import highchartsMore from "highcharts/highcharts-more"
import { findMetricDataIdx } from "../lib/array"
import { SerieBlockTimeStep } from "api/timelight-api"
import { useApiClient, useSourceDateDomain } from "../state/api"
import * as lodash from "lodash"
import { SerieChart, SerieData } from "./SerieChart"
import { SerieAggregationEnum } from "../api/timelight-api/models/SerieAggregationEnum"
import { getMaxSerieResample } from "./SerieChart/SerieChart"
highchartsMore(Highcharts)

export const SourceSelectPreviewChart = React.memo(function SourceSelectPreviewChartComponent({
  sourceId,
  additionalHighchartOptions,
  previewLimits,
  serieColor,
  height = 300,
}: {
  sourceId: number
  additionalHighchartOptions?: Highcharts.Options
  previewLimits?: {
    begin: Date
    end: Date
  }
  serieColor?: string
  height?: number
}) {
  const api = useApiClient()
  const [{ data: sourceDomain }] = useSourceDateDomain({
    shouldTrigger: sourceId !== -1 && !previewLimits,
    sourceId,
  })
  const chartDomain = {
    begin: lodash.min([sourceDomain?.beginDate, previewLimits?.begin]) as Date,
    end: lodash.max([sourceDomain?.endDate, previewLimits?.end]) as Date,
  }

  const getSeriesData = React.useMemo(() => {
    return async (minDate: Date, maxDate: Date, resample?: SerieBlockTimeStep): Promise<SerieData[]> => {
      const source = await api.getOneBaseSourceControllerSourceDto({ id: sourceId })
      const serie = await api.serieControllerGetOne({ serieId: source.serieId })
      const sourceData = await api.serieDataControllerFetchDataCompact({
        begin: minDate,
        end: maxDate,
        serieId: source.serieId,
        aggregation: SerieAggregationEnum.Mean,
        resample: getMaxSerieResample([serie.blockTimeStep, resample]),
      })
      const sourceActivityMetricIdx = findMetricDataIdx(sourceData.metrics, "activity")

      return [sourceData.data.map((d) => [d[0], d[sourceActivityMetricIdx]])]
    }
  }, [api, sourceId])

  if ((!sourceDomain && !previewLimits) || sourceId === -1) {
    return <Loader />
  }

  return (
    <SerieChart
      key={sourceId}
      chartDomain={chartDomain}
      getSeriesData={getSeriesData}
      seriesConfig={[
        {
          type: "line",
          name: "Activité",
          color: serieColor,
          yAxis: 0,
        },
      ]}
      additionalHighchartOptions={lodash.merge(
        {
          chart: {
            height: height + "px",
          },
          navigator: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
        },
        additionalHighchartOptions,
      )}
    />
  )
})
