import { Grid } from "@material-ui/core"
import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import * as lodash from "lodash"
import React, { useMemo, useState } from "react"
import { DayModelDto, ModelDto, SourceDto } from "api/timelight-api"
import {
  dateObjToDayString,
  daysOfWeekList,
  formatDate,
  formatDayOfWeek,
  formatMonth,
  monthList,
  toDateObj,
} from "../lib/date"
import { formatNumber } from "../lib/number"
import AppModal from "./AppModal"
import { DATA_NOT_PROVIDED_MODEL_ID } from "./ChartDayActivity"
import DayYearCalendar from "./DayYearCalendar/index"
import {
  createDateColumnConfig,
  createNumericColumnConfig,
  createTextEnumColumnConfig,
  extractColumnConfigAndExportConfig,
} from "./MaterialTable/material-table-helpers"
import { createTableExportCsv } from "./MaterialTable/material-table-helpers"
import { MaterialTable } from "./MaterialTable"
import ScatterDays from "./ScatterDays"
import { ToleranceChartItem } from "./ToleranceChart/ChartTooltip"

export default function ModelModal({
  open,
  onClose,
  model,
  days,
  source,
  data,
}: {
  open: boolean
  onClose: () => void
  data: ToleranceChartItem[]
  source: SourceDto
  model: ModelDto
  days: DayModelDto[]
}) {
  const [selectedDay, setSelectedDay] = useState<DayModelDto | null>(null)
  const modelDays = days
    .map((d) => ({ ...d, model_id: d.model_id || DATA_NOT_PROVIDED_MODEL_ID }))
    .filter((d) => d.model_id === model.id && d.anomaly === false)

  /* We define top and bottom as stacked charts to display a beautiful sleeve
     around the value data. But the stacked charts get added, we don't want 
     top data to be displayed at (bottom+top), so we remove "bottom" from 
     the "top" data to correct this behavior */
  const unstackedData = data.map((d) => ({ ...d, stackedTop: d.top - d.bottom }))

  const options: Highcharts.Options = {
    time: {
      timezone: "Europe/Paris",
    },
    chart: {
      type: "area",
      margin: [60, 10, 80, 50],
    },

    title: {
      text: "",
    },

    boost: {
      useGPUTranslations: false,
    },

    subtitle: {
      text: `Détail des journées du profil type`,
      align: "left",
      x: 40,
    },

    xAxis: {
      categories: data.map((d) => d.x),
    },

    yAxis: {
      title: {
        text: null,
      },
    },

    tooltip: {
      crosshairs: true,
      shared: false,
    } as Highcharts.TooltipOptions,

    legend: {},

    plotOptions: {
      area: {
        stacking: "normal",
      },
    },

    series: [
      ...modelDays
        .filter((day) => (selectedDay ? day.date === selectedDay.date : true))
        .map(
          (day) =>
            ({
              type: "line" as "line",
              id: day.date,
              name: formatDate(day.date),
              data: day.activity,
              zIndex: 1,
              showInLegend: false,
              color: "#5e4fa2",
              marker: {
                enabled: false,
              },
              states: {
                hover: {
                  marker: { enabled: false },
                },
              },
              events: {
                click: () => setSelectedDayOrUnselect(day),
              },
            } as Highcharts.SeriesLineOptions),
        ),
      {
        name: "Tolérance haute",
        id: "tolerance-haute",
        data: unstackedData.map((d) => d.stackedTop),
        type: "area" as "area",
        linkedTo: ":previous",
        zIndex: 0,
        fillColor: "#7cb5ec33" /* alpha: 0.2 */,
        lineColor: "#7cb5ec",
        lineWidth: 1,
        showInLegend: false,
        marker: {
          enabled: false,
        },
        tooltip: {
          pointFormatter(this: any): string {
            return formatNumber(unstackedData[this.index].top)
          },
        } as Highcharts.SeriesTooltipOptionsObject,
        states: {
          hover: {
            marker: { enabled: false },
          },
        },
      } as Highcharts.SeriesAreaOptions,
      {
        name: "Tolérance basse",
        id: "tolerance-basse",
        data: unstackedData.map((d) => d.bottom),
        type: "area" as "area",
        linkedTo: ":previous",
        zIndex: 0,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            marker: { enabled: false },
          },
        },
        fillColor: "#7cb5ec00",
        lineColor: "#7cb5ec",
        lineWidth: 1,
      } as Highcharts.SeriesAreaOptions,
    ],
  }

  function setSelectedDayOrUnselect(day: DayModelDto | null) {
    return selectedDay && day && day.date === selectedDay.date ? setSelectedDay(null) : setSelectedDay(day)
  }
  return (
    <AppModal fullscreen={true} open={open} onClose={onClose} title={<h2>{model.name}</h2>}>
      <>
        <div style={{ marginBottom: "2em", marginTop: "1em" }}>
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <HighchartsReact highcharts={Highcharts} options={options} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <ScatterDays
                days={modelDays}
                models={[model]}
                setSelectedDay={setSelectedDayOrUnselect}
                selectedDay={selectedDay}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginBottom: "2em" }}>
          <ModelDayTable
            days={modelDays}
            model={model}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDayOrUnselect}
          />
        </div>

        <div style={{ marginBottom: "2em" }}>
          <DayYearCalendar
            year={source.referenceYear}
            models={[model]}
            days={
              selectedDay
                ? modelDays.map((d) => ({ ...d, anomaly: !(selectedDay && d.date === selectedDay.date) }))
                : modelDays
            }
            setSelectedDay={(day) => setSelectedDayOrUnselect(modelDays.find((d) => d.date === day.date) || null)}
          />
        </div>
      </>
    </AppModal>
  )
}

const ModelDayTable = React.memo(function AnomalyDayTableComponent({
  days,
  model,
  selectedDay,
  setSelectedDay,
}: {
  days: DayModelDto[]
  model: ModelDto
  selectedDay: DayModelDto | null
  setSelectedDay: (day: DayModelDto | null) => void
}) {
  // avoid re-rendering data table when row data change
  const dateDomain = days.map((row) => toDateObj(row.date))
  const minDateStr = dateObjToDayString(lodash.min(dateDomain) || new Date())
  const maxDateStr = dateObjToDayString(lodash.max(dateDomain) || new Date())

  const { columns, exportConfig } = useMemo(
    () =>
      extractColumnConfigAndExportConfig<DayModelDto>([
        createDateColumnConfig({
          title: "Date",
          getDate: (row) => toDateObj(row.date),
          domain: [toDateObj(minDateStr), toDateObj(maxDateStr)],
        }),
        createTextEnumColumnConfig({
          title: "Jour",
          getTextValue: (row) => formatDayOfWeek(row.date),
          enumValues: daysOfWeekList,
          sortEnum: false,
        }),
        createTextEnumColumnConfig({
          title: "Mois",
          getTextValue: (row) => formatMonth(row.date),
          enumValues: monthList,
        }),
        createNumericColumnConfig({
          title: "Inertie",
          getValue: (row) => row.inertia || 0,
        }),
      ]),
    [minDateStr, maxDateStr],
  )

  return (
    <MaterialTable
      title={`Journées du profil type ${model.name}`}
      data={days}
      highlightedRowsIds={selectedDay ? [days.indexOf(selectedDay)] : undefined}
      onRowClick={setSelectedDay}
      columns={columns}
      options={{
        selection: false,
        pageSize: 10,
        exportCsv: createTableExportCsv({
          exportConfig,
          fileName: `day_data_${model.name}.csv`,
        }),
      }}
    />
  )
})
