import React from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useSourceClusterTask, useApiClient, useSourcesById } from "../../state/api"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import { useAppRoute, AppRoute } from "../../state/route"
import { MaterialTable } from "component/MaterialTable"
import { SourceDto } from "../../api/timelight-api/models/SourceDto"
import {
  createTableExportCsv,
  createTextEnumColumnConfig,
  createNumericColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import {
  extractColumnConfigAndExportConfig,
  createTextColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import * as Highcharts from "highcharts"
import highchartsMore from "highcharts/highcharts-more"
import { useSourceClusterModels } from "../../state/api"
import { SourceClusterModel } from "../../api/timelight-api/models/SourceClusterModel"
import { createIdMap } from "../../lib/array"
import GridHighlight from "../../component/GridHighlight"
import ScatterSources from "../../component/ScatterSources"
import * as lodash from "lodash"
import TaskStatusActivity from "../../component/TaskStatusActivity"
import * as chroma from "chroma-js"
import { useTaskStream, isTaskEnded, isTaskSuccessfullyEnded } from "../../state/tasksStream"
import { TaskActionsBlock } from "../../component/TaskActionsBlock"
import { getClusterDistanceName } from "./clusterDistances"
import { buildUrl } from "../../component/AppLink/AppLink"

highchartsMore(Highcharts)

export function SourceClusterTaskView() {
  const currentRoute = useAppRoute()
  const taskId = parseInt(currentRoute.params.taskId || "-1", 10)

  // fetch page data
  const [{ data: initTask }] = useSourceClusterTask({
    shouldTrigger: taskId > 0,
    sourceClusterTaskId: taskId,
  })
  const task = useTaskStream(initTask)
  const [{ data: taskModels }] = useSourceClusterModels({
    shouldTrigger: !!task && isTaskSuccessfullyEnded(task),
    filter: [`taskId||$eq||${taskId}`],
    limit: 10000,
  })
  const [{ data: sourcesData }] = useSourcesById({
    shouldTrigger: !!task && isTaskSuccessfullyEnded(task),
    sourceIds: task?.sourceIds || [-1],
  })
  const sourceById = createIdMap(sourcesData ? sourcesData : [])
  const clusterIds = lodash.uniq(taskModels ? taskModels.data.map((m) => m.clusterId) : [])
  const clustersById = createIdMap(taskModels ? taskModels.data : [])
  const colorScale = chroma.scale("Spectral").domain([clusterIds.length, 0]).classes(clusterIds.length)

  // prepare some api calls
  const api = useApiClient()

  return (
    <PageContainer title={`Comparaison de séries ${"- " + (task?.title || "")}`}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Comparaison de séries{task && <>: {task.title}</>}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <PageBlock loading={!task} title="Configuration de la modélisation">
          {task && (
            <div>
              <dl>
                <dt>Distance utilisée</dt>
                <dd>
                  <strong>{getClusterDistanceName(task.distance)}</strong>
                </dd>

                <dt>Status de la tâche</dt>
                <dd>
                  <TaskStatusActivity large={true} task={task} />
                </dd>
              </dl>
            </div>
          )}
        </PageBlock>

        {task && isTaskSuccessfullyEnded(task) && (
          <PageBlock loading={!taskModels || !sourcesData} title="Goupes de sources">
            {taskModels && sourcesData && (
              <>
                <ScatterSources
                  setSelectedSource={() => {}}
                  anomalyTitle="Anomalie"
                  sources={taskModels.data.map((m) => ({
                    ...sourceById[m.sourceId],
                    groupId: m.isAnomaly ? null : m.clusterId,
                    projectionX: clustersById[m.id].projectionX,
                    projectionY: clustersById[m.id].projectionY,
                  }))}
                  groups={clusterIds.map((clusterId, i) => ({
                    id: clusterId,
                    color: colorScale(i).hex("rgb"),
                    name: `Cluster ${clusterId}`,
                  }))}
                />
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}>
                  <p style={{ marginRight: 10 }}>Nombres de groupes: {clusterIds.length}</p>
                </div>
                <SourceClusterModelTable sources={sourcesData} sourceClusterModels={taskModels.data} />
              </>
            )}
          </PageBlock>
        )}

        {task && isTaskEnded(task) && task.error && (
          <PageBlock title="Erreur technique">
            <pre>{task.error}</pre>
          </PageBlock>
        )}

        {task && isTaskEnded(task) && (
          <TaskActionsBlock
            updateProps={{
              buttonText: <>Mettre à jour</>,
              popinButtonText: <>Supprimer définitivement les données du projet {task.title}</>,
              popinTitle: <>La mise à jour supprimera les données du projet {task.title} ?</>,
              popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce projet</>,
              redirectToAfterAction: { route: AppRoute.SOURCE_CLUSTER_TASK_UPDATE, params: { taskId: task.id + "" } },
              doAction: async () => {},
            }}
            deleteProps={{
              buttonText: <>Supprimer le projet</>,
              popinButtonText: <>Supprimer définitivement le projet {task.title}</>,
              popinTitle: <>Supprimer le projet {task.title} ?</>,
              popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce projet</>,
              successMessage: `Projet ${task.title} supprimé définitivement`,
              redirectToAfterAction: { route: AppRoute.SOURCE_CLUSTER_LIST },
              doAction: async () => {
                await api.sourceClusterTaskControllerDeleteOne({ sourceClusterTaskId: task.id })
              },
            }}
            redirectToOnCopy={{ route: AppRoute.SOURCE_CLUSTER_TASK_COPY, params: { taskId: task.id + "" } }}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const SourceClusterModelTable = React.memo(function ForecastAlertTableComponent({
  sourceClusterModels,
  sources,
}: {
  sourceClusterModels: SourceClusterModel[]
  sources: SourceDto[]
}) {
  const sourceById = createIdMap(sources)
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<SourceClusterModel>([
    createTextColumnConfig({
      title: "Nom de la source",
      getTextValue: (row) => sourceById[row.sourceId].name,
    }),
    createTextColumnConfig({
      title: "Cluster",
      getTextValue: (row) => `Cluster ${row.clusterId}`,
    }),
    createNumericColumnConfig({
      title: "Inertie",
      getValue: (row) => row.inertia,
    }),
    createTextEnumColumnConfig({
      title: "Anomalie",
      render: (row) => (
        <GridHighlight color={row.isAnomaly ? "#e05757" : "#61c569"}>{row.isAnomaly ? "Oui" : "Non"}</GridHighlight>
      ),
      getTextValue: (row) => (row.isAnomaly ? "Oui" : "Non"),
      enumValues: ["Oui", "Non"],
    }),
  ])

  return (
    <>
      <MaterialTable
        title={`Détail des sources`}
        data={sourceClusterModels}
        columns={columns}
        onRowClick={(row) =>
          setTimeout(() => {
            if (row) {
              window.open(
                buildUrl({ route: AppRoute.DATA_SOURCE_EXPLORE, params: { sourceId: row.sourceId + "" } }),
                "_blank",
              )
            }
          })
        }
        options={{
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `source_cluster_models.csv`,
          }),
        }}
      />
    </>
  )
})
