/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { SourceStatsDto, SourceStatsDtoFromJSON, SourceStatsDtoFromJSONTyped, SourceStatsDtoToJSON } from "./"

/**
 *
 * @export
 * @interface SourceStatsListDto
 */
export interface SourceStatsListDto {
  /**
   *
   * @type {Array<SourceStatsDto>}
   * @memberof SourceStatsListDto
   */
  stats: Array<SourceStatsDto>
}

export function SourceStatsListDtoFromJSON(json: any): SourceStatsListDto {
  return SourceStatsListDtoFromJSONTyped(json, false)
}

export function SourceStatsListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceStatsListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    stats: (json["stats"] as Array<any>).map(SourceStatsDtoFromJSON),
  }
}

export function SourceStatsListDtoToJSON(value?: SourceStatsListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    stats: (value.stats as Array<any>).map(SourceStatsDtoToJSON),
  }
}
