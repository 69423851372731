/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { ImportDayDto, ImportDayDtoFromJSON, ImportDayDtoFromJSONTyped, ImportDayDtoToJSON } from "./"

/**
 *
 * @export
 * @interface ImportDaysDto
 */
export interface ImportDaysDto {
  /**
   * The source id to add data to
   * @type {number}
   * @memberof ImportDaysDto
   */
  sourceId: number
  /**
   * Defines the data interpolation method to fill missing data.      zero: replace missing data with zeros.     linear: classic and simple linear interpolation.
   * @type {string}
   * @memberof ImportDaysDto
   */
  interpolationMethod?: string
  /**
   * The list of days to add
   * @type {Array<ImportDayDto>}
   * @memberof ImportDaysDto
   */
  days: Array<ImportDayDto>
}

export function ImportDaysDtoFromJSON(json: any): ImportDaysDto {
  return ImportDaysDtoFromJSONTyped(json, false)
}

export function ImportDaysDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportDaysDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sourceId: json["sourceId"],
    interpolationMethod: !exists(json, "interpolationMethod") ? undefined : json["interpolationMethod"],
    days: (json["days"] as Array<any>).map(ImportDayDtoFromJSON),
  }
}

export function ImportDaysDtoToJSON(value?: ImportDaysDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    interpolationMethod: value.interpolationMethod,
    days: (value.days as Array<any>).map(ImportDayDtoToJSON),
  }
}
