import numeral from "numeral"

// load a locale
if (!numeral.locales.fr) {
  numeral.register("locale", "fr", {
    delimiters: {
      thousands: " ",
      decimal: ",",
    },
    abbreviations: {
      thousand: "k",
      million: "m",
      billion: "b",
      trillion: "t",
    },
    ordinal(n) {
      return n === 1 ? "er" : "ème"
    },
    currency: {
      symbol: "€",
    },
  })
}

numeral.locale("fr")

export function parseNumber(num: string, locale: "en" | "fr"): number {
  if (num.match(/[^0-9,. ]/)) {
    throw new Error("Invalid number")
  }
  if (locale === "en" && num.includes(" ")) {
    throw new Error("Invalid number")
  }
  if (locale === "fr" && num.includes(".")) {
    throw new Error("Invalid number")
  }
  const tmp = numeral.locale()
  numeral.locale(locale)
  const res = numeral(num).value()
  numeral.locale(tmp)
  return res
}

export function formatNumber(
  num: number | null | undefined,
  isInt: boolean = false,
  locale: "en" | "fr" = "fr",
  floatPrecision = 2,
  nullValue: any = "",
) {
  if (num === null || num === undefined) {
    return nullValue
  }

  const tmp = numeral.locale()
  numeral.locale(locale)
  const res = numeral(num).format(isInt ? "0,0" : "0,0." + "0".repeat(floatPrecision))
  numeral.locale(tmp)

  return res === "NaN" ? nullValue : res
}
