import YAxisLargeNumberTick from "component/YAxisLargeNumberTick"
import React, { PureComponent } from "react"
import {
  Bar,
  CartesianGrid,
  Cell,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
} from "recharts"
import { ComposedChart, Line } from "recharts"
import { useTheme } from "theme"
import { ModelDto } from "api/timelight-api"
import { createIdMap } from "../../lib/array"
import { isDarkColor } from "../../lib/color"
import { dateObjToDayString, isFuturDate } from "../../lib/date"
import DayActivityChartTooltip from "./DayActivityChartTooltip"
import * as lodash from "lodash"
import YAxisContextTypeTick from "../YAxisContextTypeTick"
import { DayContextTypes } from "../../api/timelight-api/models/DayContextTypes"

export interface DayData {
  date: string
  value: number | null
  model_id: number | null
  transparent?: boolean
  context: { ctxType: DayContextTypes; value: number }[]
}

export class DayActivityCustomAxisTick extends PureComponent<{
  x: number
  y: number
  payload?: DayData
}> {
  public render() {
    const { x, y, payload } = this.props
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload && payload.value}
        </text>
      </g>
    )
  }
}

class CustomizedLegend extends PureComponent<{ models: ModelDto[] }> {
  public render() {
    const { models } = this.props

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "1em",
        }}
      >
        {models.map((m) => (
          <div
            key={m.id}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 10,
              flexWrap: "nowrap",
            }}
          >
            <div
              style={{
                backgroundColor: m.color,
                width: 20,
                height: 10,
                borderRadius: 1,
                marginRight: 5,
                border: "1px #ccc solid",
              }}
            ></div>
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {m.name}
            </span>
          </div>
        ))}
      </div>
    )
  }
}

export const DATA_NOT_PROVIDED_MODEL_ID = -1

export default function ChartDayActivity({
  activityLabel,
  models,
  days,
  onBarClick = () => {},
  heavyContext = false,
  highlightedDays = [],
}: {
  activityLabel: string | ((day: DayData) => string)
  models: ModelDto[]
  days: DayData[]
  onBarClick?: (selected: DayData) => void
  heavyContext?: boolean
  highlightedDays?: string[]
}) {
  const theme = useTheme()
  const modelsById = createIdMap(models)
  const chartData = days.map((d) => {
    const contextValue = d.context.length > 0 ? d.context[0].value : null
    const isFutur = isFuturDate(d.date)
    return {
      ...d,
      contextValue: isFutur ? null : contextValue,
      futurContextValue: isFutur ? contextValue : null,
      highlighted: highlightedDays.includes(d.date) ? 1 : 0,
    }
  })
  const dayWithContext = chartData.find((d) => d.context.length > 0)
  const contextType = dayWithContext ? dayWithContext.context[0].ctxType : undefined
  let contextDomain: [number, number] =
    chartData.length > 0
      ? [
          lodash.min(chartData.map((d) => Math.min(d.contextValue || Infinity, d.futurContextValue || Infinity))) ||
            Infinity,
          lodash.max(chartData.map((d) => Math.max(d.contextValue || -Infinity, d.futurContextValue || -Infinity))) ||
            -Infinity,
        ]
      : [0, 0]
  const domainMargin = (contextDomain[1] - contextDomain[0]) * 0.1
  contextDomain = [contextDomain[0] - domainMargin, contextDomain[1] + domainMargin]

  return (
    <>
      {days.length > 0 && models.length > 0 ? (
        <ResponsiveContainer>
          <ComposedChart width={730} height={250} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" height={80} tick={(props) => <DayActivityCustomAxisTick {...props} />} />
            <YAxis
              yAxisId="values"
              scale="linear"
              dataKey="value"
              width={40}
              tick={(props) => <YAxisLargeNumberTick {...props} />}
            />
            <YAxis
              yAxisId="context"
              scale="linear"
              orientation="right"
              tick={(props) => <YAxisContextTypeTick {...props} contextType={contextType} />}
              domain={contextDomain}
            />
            <YAxis yAxisId="highlight" scale="linear" hide domain={[0, 1]} />
            <Tooltip
              content={(props: any) => (
                <DayActivityChartTooltip models={models} {...props} activityLabel={activityLabel} />
              )}
            />
            <Legend content={() => <CustomizedLegend models={models} />} />
            <Area
              yAxisId="highlight"
              type="step"
              dataKey="highlighted"
              strokeWidth={0}
              dot={false}
              fill="#00FF00"
              fillOpacity={0.3}
              animationDuration={300}
            />
            <Bar yAxisId="values" dataKey="value" onClick={onBarClick}>
              {days.map((day, index) => {
                const model = day.model_id ? modelsById[day.model_id] : null
                const color = model ? model.color : "#CCCCCC"
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={color}
                    fillOpacity={(day.transparent ? 0.5 : 1) * (heavyContext ? 0.3 : 1)}
                    stroke={isDarkColor(color) ? color : "#222"}
                    strokeWidth={isDarkColor(color) ? 1 : 0.7}
                    strokeOpacity={(day.transparent ? 0.3 : 0.6) * (heavyContext ? 0.3 : 1)}
                    cursor="pointer"
                  />
                )
              })}
            </Bar>

            <ReferenceLine
              yAxisId="values"
              x={dateObjToDayString(new Date())}
              stroke={theme.palette.primary.main}
              strokeDasharray="10 2 3 2"
            />
            <Line
              yAxisId="context"
              type="monotone"
              dataKey="contextValue"
              strokeWidth={heavyContext ? 2 : 1}
              dot={false}
              animationDuration={300}
              activeDot={{ cursor: "pointer", onClick: (selected: any) => onBarClick(selected.payload) }}
            />
            <Line
              yAxisId="context"
              type="natural"
              dataKey="futurContextValue"
              strokeWidth={heavyContext ? 2 : 1}
              dot={false}
              activeDot={{ cursor: "pointer", onClick: (selected: any) => onBarClick(selected.payload) }}
              strokeDasharray="4 2 3 2"
              animationDuration={300}
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <>Pas d'activité sur la période donnée</>
      )}
    </>
  )
}
