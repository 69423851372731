const UNICODE_BOM = "\uFEFF"

export function downloadStringAsFile(
  text: string,
  {
    filename = "file.txt",
    mimeType = "text/plain",
    encoding = "utf-8",
  }: { filename?: string; mimeType?: string; encoding?: string } = {},
) {
  const element = document.createElement("a")
  element.setAttribute(
    "href",
    "data:" +
      mimeType +
      ";charset=" +
      encoding +
      "," +
      encodeURIComponent(encoding === "utf-8" && !text.startsWith(UNICODE_BOM) ? UNICODE_BOM + text : text),
  )
  element.setAttribute("download", filename)

  element.style.display = "none"
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
