import OkIcon from "@material-ui/icons/Check"
import MuiMaterialTable, { MaterialTableProps, Query, QueryResult } from "material-table"
import React from "react"
import { MaterialTableFilterRow } from "./index"
import { materialTableIcons, materialTableLocalization } from "./material-table-helpers"
import "./material-table.css"
import Title from "../Title"

/* remove prop types as we added a custom "daterange" column type
   this prevent the react prop type validator to be annoying about it */
// @ts-ignore
MuiMaterialTable.propTypes = undefined

export default function MaterialTable<T extends object>({
  onRowClick,
  data,
  options,
  highlightedRowsIds = [],
  selectedRows = [],
  onSelectionChange,
  title,
  getId = (t) => {
    // @ts-ignore
    if (typeof t.id === "number") {
      // @ts-ignore
      return t.id
      // @ts-ignore
    } else if (t.tableData && typeof t.tableData.id === "number") {
      // @ts-ignore
      return t.tableData.id
      // @ts-ignore
    } else if (t.ctxType) {
      // @ts-ignore
      return t.ctxType
    }
    return JSON.stringify(t)
  },
  ...props
}: Omit<MaterialTableProps<T>, "onRowClick" | "data" | "onSelectionChange"> & {
  onRowClick?: (item: T | null) => void
  data: T[] | ((query: Query<T>) => Promise<QueryResult<T>>)
  highlightedRowsIds?: number[]
  selectedRows?: T[]
  getId?: (t: T) => string | number
  onSelectionChange?: (items: T[]) => void
}) {
  if (Array.isArray(data)) {
    // create a copy of all data because Material-table
    // modifies the row data to add a tableData property
    data = data.map((i) => ({ ...i }))
  }
  const selectedIds = selectedRows.map(getId)
  return (
    <div className="app-material-table-container">
      <style>{`
        .app-material-table-container .MuiToolbar-gutters {
          padding-left: 0;
        }
        .app-material-table-container .MuiTableCell-head {
          position: initial; /* remove position sticky */
        }
      `}</style>
      <MuiMaterialTable<T>
        data={data}
        localization={materialTableLocalization}
        components={{
          FilterRow: MaterialTableFilterRow,
          Action: ({
            data: {
              tableData: { id: rowId },
            },
          }: {
            data: T & { tableData: { id: number } }
          }) => {
            const isHl = highlightedRowsIds.reduce((agg, id) => agg || id === rowId, false)
            return isHl ? <OkIcon color="primary" /> : <></>
          },
        }}
        actions={
          highlightedRowsIds.length > 0
            ? [
                {
                  icon: "save",
                  tooltip: "Ligne sélectionnée",
                  onClick: () => {},
                },
              ]
            : undefined
        }
        onRowClick={
          onRowClick
            ? (_, row) => {
                if (row) {
                  onRowClick(row)
                } else {
                  onRowClick(null)
                }
              }
            : undefined
        }
        options={{
          // disable buggy selection options when using async data source
          showSelectAllCheckbox: Array.isArray(data),
          showTextRowsSelected: Array.isArray(data),
          pageSize: 20,
          filtering: true,
          exportButton: true,
          selection: false,
          draggable: false,
          ...(options || {}),
          selectionProps: (currRow: T) => {
            const currentRowId = getId(currRow)
            return { checked: selectedIds.indexOf(currentRowId) !== -1 }
          },
          exportAllData: true,
        }}
        onSelectionChange={(items: T[], item?: T) => {
          if (onSelectionChange && item) {
            const clickedId = getId(item)
            if (selectedIds.indexOf(clickedId) === -1) {
              onSelectionChange([...selectedRows, item])
            } else {
              onSelectionChange(selectedRows.filter((r) => getId(r) !== clickedId))
            }
          } else if (onSelectionChange && !item) {
            if (items.length === selectedRows.length) {
              onSelectionChange([])
            } else {
              onSelectionChange(items)
            }
          }
        }}
        icons={materialTableIcons}
        title={typeof title === "string" ? <Title margins={false}>{title}</Title> : title}
        {...props}
      />
    </div>
  )
}
