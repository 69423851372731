/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  AlertRefDto,
  AlertRefDtoFromJSON,
  AlertRefDtoFromJSONTyped,
  AlertRefDtoToJSON,
  SourceDto,
  SourceDtoFromJSON,
  SourceDtoFromJSONTyped,
  SourceDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface AlertRefResultDto
 */
export interface AlertRefResultDto {
  /**
   * List of alert ref
   * @type {Array<AlertRefDto>}
   * @memberof AlertRefResultDto
   */
  alerts: Array<AlertRefDto>
  /**
   * List of sources
   * @type {Array<SourceDto>}
   * @memberof AlertRefResultDto
   */
  sources: Array<SourceDto>
}

export function AlertRefResultDtoFromJSON(json: any): AlertRefResultDto {
  return AlertRefResultDtoFromJSONTyped(json, false)
}

export function AlertRefResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRefResultDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    alerts: (json["alerts"] as Array<any>).map(AlertRefDtoFromJSON),
    sources: (json["sources"] as Array<any>).map(SourceDtoFromJSON),
  }
}

export function AlertRefResultDtoToJSON(value?: AlertRefResultDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    alerts: (value.alerts as Array<any>).map(AlertRefDtoToJSON),
    sources: (value.sources as Array<any>).map(SourceDtoToJSON),
  }
}
