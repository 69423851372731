/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SourceGroupDto
 */
export interface SourceGroupDto {
  /**
   *
   * @type {number}
   * @memberof SourceGroupDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof SourceGroupDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof SourceGroupDto
   */
  color: string
}

export function SourceGroupDtoFromJSON(json: any): SourceGroupDto {
  return SourceGroupDtoFromJSONTyped(json, false)
}

export function SourceGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceGroupDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    name: json["name"],
    color: json["color"],
  }
}

export function SourceGroupDtoToJSON(value?: SourceGroupDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    color: value.color,
  }
}
