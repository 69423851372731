import React from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useApiClient } from "../../state/api"
import { FormHelperText, Grid, InputLabel } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { useHistory, Prompt } from "react-router"
import { ConfirmDialogButton } from "../../component/ConfirmDialogButton"
import { Form } from "react-final-form"
import { buildUrl } from "../../component/AppLink"
import { AppRoute, useAppRoute } from "../../state/route"
import { SourceTableSelectFormState, createSourceSelectTableField } from "../../component/form/SourceSelectTableField"
import { createListSelectField } from "../../component/form/ListSelectField"
import { createTextField } from "component/form/TextField"
import { SourceClusterDistance } from "../../api/timelight-api/models/SourceClusterDistance"
import { useAsyncAction, AsyncHookParams } from "../../state/async"
import Loader from "../../component/Loader"
import { TextFormState } from "../../component/form/TextField"
import { clusterDistances } from "./clusterDistances"
import { SourceClusterTask } from "../../api/timelight-api/models/SourceClusterTask"

interface FormState {
  title: TextFormState
  clusterDistance: SourceClusterDistance
  sourceIds: SourceTableSelectFormState
  modelCount: number
}

export function SourceClusterTaskCreate() {
  const history = useHistory()
  const api = useApiClient()

  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Tâche créée, cette opération peut prendre jusqu'à 5 minutes",
  })

  const currentRoute = useAppRoute()
  const currentTaskId = currentRoute.params.taskId ? parseInt(currentRoute.params.taskId, 10) : undefined
  const [{ data: initialValues }] = useAsyncAction<FormState, AsyncHookParams<{ taskId?: number }>>(
    async ({ taskId }) => {
      const values: FormState = {
        clusterDistance: SourceClusterDistance.DTW,
        title: "Nouveau projet",
        sourceIds: {
          sourceIds: [],
          filterByDateDomain: null,
        },
        modelCount: -1,
      }

      if (taskId) {
        const task = await api.getOneBaseSourceClusterTaskControllerSourceClusterTask({ id: taskId })

        values.title = task.title
        values.clusterDistance = task.distance
        values.sourceIds = {
          ...values.sourceIds,
          sourceIds: task.sourceIds,
        }
        values.modelCount = task.modelCount === null ? -1 : task.modelCount
      }
      return values
    },
    {
      shouldTrigger: true,
      taskId: currentTaskId,
    },
  )

  return (
    <PageContainer title="Comparaison de source - Nouveau Projet">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Nouveau projet de comparaison de source
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SuccessMessage />
        {!initialValues ? (
          <Loader />
        ) : (
          <Form<FormState>
            onSubmit={async (values, form) => {
              let res: SourceClusterTask | null = null
              const inputParams = {
                distance: values.clusterDistance,
                sourceIds: values.sourceIds.sourceIds,
                title: values.title,
                modelCount: values.modelCount === -1 ? null : values.modelCount,
              }
              if (currentRoute.route === AppRoute.SOURCE_CLUSTER_TASK_UPDATE && currentTaskId) {
                res = await api.sourceClusterTaskControllerUpdateOne({
                  sourceClusterTaskId: currentTaskId,
                  sourceClusterTaskInputParams: inputParams,
                })
              } else {
                res = await api.sourceClusterTaskControllerCreateOne({
                  sourceClusterTaskInputParams: inputParams,
                })
              }
              const taskId = res.id

              showSuccessMessage()
              setTimeout(() => {
                history.push(
                  buildUrl({
                    route: AppRoute.SOURCE_CLUSTER_TASK_VIEW,
                    params: { taskId: taskId + "" },
                  }),
                )
              }, 1500)
            }}
            initialValues={initialValues}
            validate={(values) => {
              const errors: { [key in keyof typeof values]?: string[] } = {
                sourceIds: [],
                title: [],
                modelCount: [],
              }

              if (!values.title || values.title.length <= 0) {
                errors.title?.push("Veuillez saisir un nom de projet")
              }
              if (values.sourceIds.sourceIds.length < 2) {
                errors.sourceIds?.push("Veuillez sélectionner au moins 2 sources")
              }
              if (values.sourceIds.sourceIds.length < values.modelCount) {
                errors.modelCount?.push("Pas assez de sources sélectionnées pour le nombre de cluster voulus")
              }

              return errors
            }}
            render={({ handleSubmit, dirty, submitting, pristine, values, valid, submitSucceeded }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Prompt
                    when={dirty && !submitting && !submitSucceeded}
                    message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                  />
                  <PageBlock title="Configuration du projet">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ marginRight: "1em", width: "150px" }}>Nom du projet : </InputLabel>
                      {createTextField<FormState>({ name: "title", label: "Nom du projet", required: true })}
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}>
                      <InputLabel style={{ marginRight: "1em", width: "150px" }}>Distance : </InputLabel>

                      {createListSelectField<FormState, typeof clusterDistances[0]>({
                        name: "clusterDistance",
                        items: clusterDistances,
                      })}
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ marginRight: "1em", width: "150px" }}>Nombre de modèles : </InputLabel>

                      {createListSelectField<FormState, { label: string; value: number }>({
                        name: "modelCount",
                        items: [{ label: "Auto", value: -1 }].concat(
                          Array.from({ length: 20 }).map((_, i) => ({ label: i + 1 + "", value: i + 1 })),
                        ),
                      })}
                    </div>
                    <FormHelperText style={{}}>
                      Laissez vide pour détecter automatiquement le nombre de modèles
                    </FormHelperText>
                  </PageBlock>

                  <PageBlock titleInContent={true}>
                    {createSourceSelectTableField<FormState>({
                      name: "sourceIds",
                      title: "Sources utilisées pour l'analyse",
                      pageSize: 20,
                    })}
                  </PageBlock>

                  <ConfirmDialogButton disabled={!valid} isSubmitting={submitting} onSubmit={handleSubmit} />
                </form>
              )
            }}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}
