import numeral from "numeral"
import React, { PureComponent } from "react"
import { Text } from "recharts"

/**
 * Y axis may contain very large numbers, like 800 000
 * We want to format them like so: 800k
 */
export default class YAxisLargeNumberTick extends PureComponent<{
  x: number
  y: number
  payload?: {
    value: number
  }
}> {
  public render() {
    const { payload } = this.props
    return <Text {...this.props}>{payload && numeral(payload.value).format("0a")}</Text>
  }
}
