/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  SerieBlockTimeSpan,
  SerieBlockTimeSpanFromJSON,
  SerieBlockTimeSpanFromJSONTyped,
  SerieBlockTimeSpanToJSON,
  SerieBlockTimeStep,
  SerieBlockTimeStepFromJSON,
  SerieBlockTimeStepFromJSONTyped,
  SerieBlockTimeStepToJSON,
} from "./"

/**
 *
 * @export
 * @interface Serie
 */
export interface Serie {
  /**
   *
   * @type {number}
   * @memberof Serie
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof Serie
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof Serie
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof Serie
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof Serie
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof Serie
   */
  readonly companyId: number
  /**
   *
   * @type {object}
   * @memberof Serie
   */
  tags: object
  /**
   *
   * @type {Array<string>}
   * @memberof Serie
   */
  blockMetrics: Array<string>
  /**
   *
   * @type {SerieBlockTimeStep}
   * @memberof Serie
   */
  blockTimeStep?: SerieBlockTimeStep
  /**
   *
   * @type {SerieBlockTimeSpan}
   * @memberof Serie
   */
  blockTimeSpan?: SerieBlockTimeSpan
}

export function SerieFromJSON(json: any): Serie {
  return SerieFromJSONTyped(json, false)
}

export function SerieFromJSONTyped(json: any, ignoreDiscriminator: boolean): Serie {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    tags: json["tags"],
    blockMetrics: json["blockMetrics"],
    blockTimeStep: !exists(json, "blockTimeStep") ? undefined : SerieBlockTimeStepFromJSON(json["blockTimeStep"]),
    blockTimeSpan: !exists(json, "blockTimeSpan") ? undefined : SerieBlockTimeSpanFromJSON(json["blockTimeSpan"]),
  }
}

export function SerieToJSON(value?: Serie | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    tags: value.tags,
    blockMetrics: value.blockMetrics,
    blockTimeStep: SerieBlockTimeStepToJSON(value.blockTimeStep),
    blockTimeSpan: SerieBlockTimeSpanToJSON(value.blockTimeSpan),
  }
}
