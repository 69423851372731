import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import React from "react"
import { SourceDto, SourceGroupDto } from "api/timelight-api"
import { hexToRGBA } from "../../lib/color"

function scatterClickHandler(evt: any) {
  if (evt.point) {
    const opt = evt.point.series.userOptions
    opt.setSelectedSource(opt.groupSources[evt.point.index])
  }
}

export default function ScatterSources({
  sources,
  groups,
  setSelectedSource,
  anomalyTitle = "Non assigné",
}: {
  sources: SourceDto[]
  groups: SourceGroupDto[]
  setSelectedSource: (day: SourceDto) => void
  anomalyTitle?: string
}) {
  const noGroup = sources.filter((s) => s.groupId === null)
  const withGroup = sources.filter((s) => s.groupId !== null)

  const series: (Highcharts.SeriesScatterOptions & {
    groupSources: SourceDto[]
    setSelectedSource: (day: SourceDto) => void
  })[] = []
  for (const group of groups) {
    const groupSources = withGroup.filter((s) => s.groupId === group.id)
    if (groupSources.length > 0) {
      series.push({
        type: "scatter",
        name: group.name,
        color: hexToRGBA(group.color, 0.6),
        data: groupSources.map((d) => [d.projectionX || 0, d.projectionY || 0]),
        groupSources,
        setSelectedSource,
        events: {
          click: scatterClickHandler,
        },
        tooltip: {
          pointFormatter(this: any) {
            return groupSources[this.index].name
          },
        } as Highcharts.SeriesTooltipOptionsObject,
      })
    }
  }

  // anomalies
  if (noGroup.length > 0) {
    series.push({
      type: "scatter",
      name: anomalyTitle,
      color: "black",
      data: noGroup.map((d) => [d.projectionX || 0, d.projectionY || 0]),
      groupSources: noGroup,
      setSelectedSource,
      events: {
        click: scatterClickHandler,
      },
      tooltip: {
        pointFormatter(this: any) {
          return noGroup[this.index].name
        },
      } as Highcharts.SeriesTooltipOptionsObject,
    })
  }

  const options: Highcharts.Options = {
    time: {
      timezone: "Europe/Paris",
    },
    chart: {
      type: "scatter",
      zoomType: "xy",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    plotOptions: {
      scatter: {
        cursor: "pointer",
        states: {
          inactive: {
            opacity: 0.7,
          },
        } as Highcharts.SeriesStatesOptionsObject,
        marker: {
          symbol: "circle",
          lineWidth: 0.7,
          lineColor: "rgba(100,100,100, 0.5)",
          radius: 5,
          states: {
            hover: {
              lineColor: "rgba(100,100,100,0.7)",
            },
          },
        },
      },
    },
    xAxis: {
      gridLineWidth: 1,
      tickWidth: 0,
      startOnTick: true,
      endOnTick: true,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      gridLineWidth: 1,
      tickWidth: 0,
      startOnTick: true,
      endOnTick: true,
      labels: {
        enabled: false,
      },
      title: "",
    } as Highcharts.YAxisOptions,
    series,
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
