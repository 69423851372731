import { Hidden, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import AlertActivity from "component/AlertActivity"
import * as lodash from "lodash"
import React, { useState } from "react"
import { AlertRefDto, AlertRefResultDto } from "api/timelight-api"
import AlertAnomaly from "../../component/AlertAnomaly"
import AlertBetterModel from "../../component/AlertBetterModel"
import AlertShape from "../../component/AlertShape"
import AppHeader from "../../component/AppHeader"
import DaysNearDateModal from "../../component/DaysNearDateModal/DaysNearDateModal"
import {
  createDateColumnConfig,
  createTableExportCsv,
  createTextColumnConfig,
  createTextEnumColumnConfig,
  extractColumnConfigAndExportConfig,
  MaterialTable,
} from "../../component/MaterialTable"
import PageBlock from "../../component/PageBlock"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { createIdMap } from "../../lib/array"
import { daysOfWeekList, formatDayOfWeek, toDateObj } from "../../lib/date"
import { useAlertRefList } from "../../state/api"
import { AlertActivityEnum } from "../../api/timelight-api/models/AlertActivityEnum"

export default function InventoryAlerts() {
  // fetch some data
  const [{ data, loading }] = useAlertRefList({ shouldTrigger: true })

  // state for our day modal
  const [selectedAlert, setSelectedAlert] = useState<AlertRefDto | null>(null)

  return (
    <PageContainer title="Référentiel des alertes traitées">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Hidden only={["xs"]}>
            <Typography variant="h6">Référentiel des alertes traitées</Typography>
          </Hidden>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        {data && selectedAlert && (
          <DaysNearDateModal
            open={!!selectedAlert}
            sourceId={selectedAlert.source_id}
            initialDate={selectedAlert.date}
            onClose={() => setSelectedAlert(null)}
          />
        )}

        <PageBlock loading={loading} titleInContent={true}>
          {data && <AlertInventoryTable data={data} setSelectedAlert={setSelectedAlert} />}
        </PageBlock>
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on alert select
   this will empty the filters
 */
const AlertInventoryTable = React.memo(function AlertInventoryTableComponent({
  data,
  setSelectedAlert,
}: {
  data: AlertRefResultDto
  setSelectedAlert: (alert: AlertRefDto | null) => void
}) {
  // have a quick kv store of models and sources
  const sourcesById = createIdMap(data.sources)

  const { columns, exportConfig } = extractColumnConfigAndExportConfig<AlertRefDto>([
    createTextEnumColumnConfig({
      title: "Capteur",
      getTextValue: (row) => sourcesById[row.source_id].name,
      enumValues: data.sources.map((s) => s.name).sort(),
    }),
    createTextEnumColumnConfig({
      title: "Jour",
      getTextValue: (row) => formatDayOfWeek(row.date),
      enumValues: daysOfWeekList,
      sortEnum: false,
    }),
    createDateColumnConfig({
      title: "Date",
      getDate: (row) => toDateObj(row.date),
      domain: data.alerts.map((row) => toDateObj(row.date)),
    }),
    createTextColumnConfig({
      title: "Commentaire",
      getTextValue: (row) => row.comment || "",
    }),
    createTextEnumColumnConfig({
      title: "Anomalie",
      getTextValue: (row) => (row.anomaly ? "Anomalie" : "Pas d'anomalie"),
      enumValues: ["Anomalie", "Pas d'anomalie"],
      render: (row) => <AlertAnomaly alert={row} />,
    }),
    createTextEnumColumnConfig({
      title: "Forme",
      getTextValue: (row) => (row.shape ? "Écart de forme" : "Pas d'écart de forme"),
      enumValues: ["Écart de forme", "Pas d'écart de forme"],
      render: (row) => <AlertShape alert={row} />,
    }),
    createTextEnumColumnConfig({
      title: "Activité",
      getTextValue: (row) =>
        row.activity === AlertActivityEnum.Over
          ? "Sur-activité"
          : row.activity === AlertActivityEnum.Under
          ? "Sous-activité"
          : "Pas de sur-activité, pas de sous-activité",
      enumValues: ["Sur-activité", "Sous-activité", "Pas de sur-activité, pas de sous-activité"],
      render: (row) => <AlertActivity alert={row} />,
    }),
    createTextEnumColumnConfig({
      title: "Meilleur profil type détecté",
      getTextValue: (row) => row.betterModelName || "Pas de meilleur profil type",
      enumValues: lodash
        .uniq(data.alerts.filter((a) => a.betterModelName).map((a) => a.betterModelName || ""))
        .sort()
        .concat("Pas de meilleur profil type"),
      render: (row) => (
        <AlertBetterModel
          alert={row}
          getModelColor={(a) => a.betterModelColor || "#CCC"}
          getModelName={(a) => a.betterModelName || ""}
        />
      ),
    }),
  ])
  return (
    <>
      <MaterialTable
        title="Historique des alertes traitées"
        data={data.alerts}
        onRowClick={setSelectedAlert}
        columns={columns}
        options={{
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `alert_referential.csv`,
          }),
        }}
      />
    </>
  )
})
