import React from "react"
import { dayNumberInYear, toDateObj } from "../lib/date"
import PieStats from "./PieStat"

export default function YearPieStats({ daysWithDataCount, maxDate }: { daysWithDataCount: number; maxDate: string }) {
  const elapsed = dayNumberInYear(toDateObj(maxDate))

  return (
    <PieStats
      total={elapsed}
      totalTitle="Jours non alimentés"
      count={daysWithDataCount}
      countTitle="Jours alimentés"
      color="#0088FE"
      noDataTitle="Pas de données"
    />
  )
}
