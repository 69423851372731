import React from "react"
import { AnyChildren } from "../react-type-helpers"

export function GradientBackground({
  base,
  darker,
  className,
  children,
}: {
  base: string
  darker: string
  className?: string
  children: AnyChildren
}) {
  return (
    <div
      className={className}
      style={{
        backgroundColor: base,
        backgroundImage: `linear-gradient(180deg,${base} 10%, ${darker} 100%)`,
      }}
    >
      {children}
    </div>
  )
}
