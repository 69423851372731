import { createStrIdMapMultiValue, createStrIdMap, createIdMap } from "../../lib/array"
import { dateObjToDayString, toDateObj } from "../../lib/date"
import { DayData, DATA_NOT_PROVIDED_MODEL_ID } from "./ChartDayActivity"
import * as lodash from "lodash"
import { DayContextTypes } from "../../api/timelight-api/models/DayContextTypes"

export function createChartActivityLabelFromApiInfos({
  days,
  previsions,
}: {
  days: {
    date: string
    value: number | null
    model_id: number | null
    transparent?: boolean
  }[]
  previsions: { date: string; model_id: number }[]
}) {
  const daysByDateIdx = createStrIdMap(days, (d) => d.date)
  const previsionByDateIdx = createStrIdMap(previsions, (p) => p.date)
  return (day: { date: string }) => {
    return daysByDateIdx[day.date]
      ? "Activité effective"
      : previsionByDateIdx[day.date]
      ? "Activité prévue"
      : "Activité"
  }
}

export function createChartDayDataFromApiInfos({
  days,
  context,
  previsions,
  models,
  year,
}: {
  days: {
    date: string
    value: number | null
    model_id: number | null
    transparent?: boolean
  }[]
  year?: number
  models: { id: number; value: number }[]
  context: { dayDate: string; ctxType: DayContextTypes; value: number }[]
  previsions: { date: string; model_id: number }[]
}): DayData[] {
  const modelsById = createIdMap(models)
  const daysByDateIdx = createStrIdMap(days, (d) => d.date)
  const previsionByDateIdx = createStrIdMap(previsions, (p) => p.date)
  const contextByDateIdx = createStrIdMapMultiValue(context, (c) => c.dayDate)

  const daysToFill: string[] = []
  // a year have been provided, fill it until it's full
  if (year) {
    for (let i = 1; i < 370; i++) {
      const day = new Date(year, 0, i) as Date
      // we stay in reference year
      if (day.getFullYear() === year) {
        const currDayStr = dateObjToDayString(day)
        daysToFill.push(currDayStr)
      }
    }
  } else {
    // a year have not been provided, fill between min and max dates
    const oneDay = 24 * 60 * 60 * 1000

    // consider previsions and regular days
    const allDates = days.map((d) => d.date).concat(previsions.map((p) => p.date))
    if (allDates.length > 0) {
      const minDate = toDateObj(lodash.min(allDates) as string)
      const maxDate = toDateObj(lodash.max(allDates) as string)
      for (let day = minDate; day <= maxDate; day = new Date(day.getTime() + oneDay)) {
        const currDayStr = dateObjToDayString(day)
        daysToFill.push(currDayStr)
      }
    }
  }

  // for each date to fill
  const chartData: DayData[] = []
  for (const currDayStr of daysToFill) {
    const dayData = daysByDateIdx[currDayStr]
    const contextData = contextByDateIdx[currDayStr] || []
    const prevision = previsionByDateIdx[currDayStr]
    const dayModel =
      dayData && dayData.model_id && modelsById[dayData.model_id]
        ? modelsById[dayData.model_id]
        : prevision && prevision.model_id && modelsById[prevision.model_id]
        ? modelsById[prevision.model_id]
        : null

    if (dayData && currDayStr === dayData.date) {
      chartData.push({ ...dayData, context: contextData })
      // fill data voids
    } else {
      chartData.push({
        date: currDayStr,
        value: dayModel ? dayModel.value : 0,
        model_id: dayModel ? dayModel.id : DATA_NOT_PROVIDED_MODEL_ID,
        transparent: dayData ? false : prevision ? true : false,
        context: contextData,
      })
    }
  }
  return chartData
}
