import { Location } from "history"

export function createTrackPageView(GOOGLE_ANALYTICS_UA: string) {
  return function trackPageView(location: Location<any>) {
    /* we use a timeout because the page title is set with react helmet
     we want to allow react helmet to execute and get the document
     title afterward */
    setTimeout(() => {
      const infos = {
        // @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
        page_path: location.pathname + location.search,
        page_title: document.title,
        page_location: window.location.origin + location.pathname + location.search,
      }
      // console.log("tracking page", infos)
      // @ts-ignore window.gtag does not exists
      window.gtag("config", GOOGLE_ANALYTICS_UA, infos)
    }, 500)
  }
}
