/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  AnomaliesResponseDto,
  AnomaliesResponseDtoFromJSON,
  AnomaliesResponseDtoToJSON,
  RecomputeDayModelsResponseDto,
  RecomputeDayModelsResponseDtoFromJSON,
  RecomputeDayModelsResponseDtoToJSON,
  RecomputeDaysProjectionResponseDto,
  RecomputeDaysProjectionResponseDtoFromJSON,
  RecomputeDaysProjectionResponseDtoToJSON,
  RecomputeModelsResponseDto,
  RecomputeModelsResponseDtoFromJSON,
  RecomputeModelsResponseDtoToJSON,
  RecomputeSourceModelsResponseDto,
  RecomputeSourceModelsResponseDtoFromJSON,
  RecomputeSourceModelsResponseDtoToJSON,
} from "../models"

export interface AiControllerGetSourceAnomaliesRequest {
  sourceId: number
}

export interface AiControllerRecomputeDayModelsRequest {
  sourceId: number
  year: number
}

export interface AiControllerRecomputeDaysProjectionRequest {
  sourceId: number
  year: number
}

export interface AiControllerRecomputeModelsRequest {
  sourceId: number
  year: number
  modelCount?: number
}

export interface AiControllerRecomputeSourceModelsRequest {
  modelCount: number
}

/**
 * no description
 */
export class AIApi extends runtime.BaseAPI {
  /**
   * Auto detect-anomalies
   */
  async aiControllerGetSourceAnomaliesRaw(
    requestParameters: AiControllerGetSourceAnomaliesRequest,
  ): Promise<runtime.ApiResponse<AnomaliesResponseDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling aiControllerGetSourceAnomalies.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/ai/anomalies/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AnomaliesResponseDtoFromJSON(jsonValue))
  }

  /**
   * Auto detect-anomalies
   */
  async aiControllerGetSourceAnomalies(
    requestParameters: AiControllerGetSourceAnomaliesRequest,
  ): Promise<AnomaliesResponseDto> {
    const response = await this.aiControllerGetSourceAnomaliesRaw(requestParameters)
    return await response.value()
  }

  /**
   * Erases and re-computes all day models for a source and year
   * Recomputes all day models
   */
  async aiControllerRecomputeDayModelsRaw(
    requestParameters: AiControllerRecomputeDayModelsRequest,
  ): Promise<runtime.ApiResponse<RecomputeDayModelsResponseDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling aiControllerRecomputeDayModels.",
      )
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling aiControllerRecomputeDayModels.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/ai/recompute-day-models/{sourceId}/{year}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => RecomputeDayModelsResponseDtoFromJSON(jsonValue))
  }

  /**
   * Erases and re-computes all day models for a source and year
   * Recomputes all day models
   */
  async aiControllerRecomputeDayModels(
    requestParameters: AiControllerRecomputeDayModelsRequest,
  ): Promise<RecomputeDayModelsResponseDto> {
    const response = await this.aiControllerRecomputeDayModelsRaw(requestParameters)
    return await response.value()
  }

  /**
   * This computes the X/Y projection of all days in the source for the given year
   * Computes all days projection for a source and save them
   */
  async aiControllerRecomputeDaysProjectionRaw(
    requestParameters: AiControllerRecomputeDaysProjectionRequest,
  ): Promise<runtime.ApiResponse<RecomputeDaysProjectionResponseDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling aiControllerRecomputeDaysProjection.",
      )
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling aiControllerRecomputeDaysProjection.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/ai/recompute-days-projection/{sourceId}/{year}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => RecomputeDaysProjectionResponseDtoFromJSON(jsonValue))
  }

  /**
   * This computes the X/Y projection of all days in the source for the given year
   * Computes all days projection for a source and save them
   */
  async aiControllerRecomputeDaysProjection(
    requestParameters: AiControllerRecomputeDaysProjectionRequest,
  ): Promise<RecomputeDaysProjectionResponseDto> {
    const response = await this.aiControllerRecomputeDaysProjectionRaw(requestParameters)
    return await response.value()
  }

  /**
   * This operations erases both non-handled alerts and user previsions
   * Triggers a model recompute
   */
  async aiControllerRecomputeModelsRaw(
    requestParameters: AiControllerRecomputeModelsRequest,
  ): Promise<runtime.ApiResponse<RecomputeModelsResponseDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling aiControllerRecomputeModels.",
      )
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling aiControllerRecomputeModels.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.modelCount !== undefined) {
      queryParameters["modelCount"] = requestParameters.modelCount
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/ai/recompute-models/{sourceId}/{year}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => RecomputeModelsResponseDtoFromJSON(jsonValue))
  }

  /**
   * This operations erases both non-handled alerts and user previsions
   * Triggers a model recompute
   */
  async aiControllerRecomputeModels(
    requestParameters: AiControllerRecomputeModelsRequest,
  ): Promise<RecomputeModelsResponseDto> {
    const response = await this.aiControllerRecomputeModelsRaw(requestParameters)
    return await response.value()
  }

  /**
   * This operations erases the group configuration
   * Triggers a model recompute for source groups
   */
  async aiControllerRecomputeSourceModelsRaw(
    requestParameters: AiControllerRecomputeSourceModelsRequest,
  ): Promise<runtime.ApiResponse<RecomputeSourceModelsResponseDto>> {
    if (requestParameters.modelCount === null || requestParameters.modelCount === undefined) {
      throw new runtime.RequiredError(
        "modelCount",
        "Required parameter requestParameters.modelCount was null or undefined when calling aiControllerRecomputeSourceModels.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/ai/recompute-source-models/{modelCount}`.replace(
        `{${"modelCount"}}`,
        encodeURIComponent(String(requestParameters.modelCount)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => RecomputeSourceModelsResponseDtoFromJSON(jsonValue))
  }

  /**
   * This operations erases the group configuration
   * Triggers a model recompute for source groups
   */
  async aiControllerRecomputeSourceModels(
    requestParameters: AiControllerRecomputeSourceModelsRequest,
  ): Promise<RecomputeSourceModelsResponseDto> {
    const response = await this.aiControllerRecomputeSourceModelsRaw(requestParameters)
    return await response.value()
  }
}
