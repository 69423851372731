import React from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useApiClient } from "../../state/api"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import { ForecastTask } from "../../api/timelight-api/models/ForecastTask"
import {
  createTextColumnConfig,
  RowWithSource,
  fetchAndAddSourcesToRows,
} from "../../component/MaterialTable/material-table-helpers"
import {
  extractColumnConfigAndExportConfig,
  createDateColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import { MaterialTable } from "component/MaterialTable"
import { useHistory } from "react-router"
import { AppRoute } from "../../state/route"
import { buildUrl } from "../../component/AppLink"
import { createTaskStatusColumnConfig } from "../../component/TaskStatusActivity"
import AddIcon from "@material-ui/icons/Add"
import { AppButton } from "component/AppButton"
import AppLink from "../../component/AppLink/index"
import {
  createTypeORMCrudFilter,
  createProperTextEnumColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import { forecastMethods } from "./forecastMethods"
import { EmptyStateRedirect } from "../../component/EmptyStateRedirect"
import {
  createSourceIdColumnConfig,
  createTableExportCsv,
  Query,
} from "../../component/MaterialTable/material-table-helpers"
import { formatDuration } from "../../lib/date"
import { QueryResult } from "material-table"
import {
  createTypeORMCrudPage,
  createTypeORMCrudLimit,
  createTypeORMCrudSort,
} from "../../component/MaterialTable/material-table-helpers"

export function ForecastTaskList() {
  return (
    <PageContainer title="Modélisation statistique">
      <AppHeader>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Modélisation statistique
          </Typography>
          <AppLink route={AppRoute.FORECAST_TASK_CREATE}>
            <AppButton variant="outlined" style={{ color: "white", borderColor: "white" }}>
              <AddIcon /> Nouvelle modélisation
            </AppButton>
          </AppLink>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <ForecastTaskTable />
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const ForecastTaskTable = React.memo(function ForecastTaskTableComponent() {
  const history = useHistory()
  const api = useApiClient()
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false)

  type RowType = RowWithSource<ForecastTask>

  const { columns, exportConfig } = extractColumnConfigAndExportConfig<RowType>([
    createTextColumnConfig({
      title: "Nom",
      getTextValue: (row) => row.title,
      field: "title",
    }),
    createSourceIdColumnConfig({
      title: "Source",
      getTextValue: (row) => row.source.name,
      field: "sourceId",
    }),
    createDateColumnConfig({
      title: "Date de création",
      getDate: (row) => row.createdDate,
      field: "createdDate",
    }),
    createTextColumnConfig({
      title: "Durée d'exécution",
      getTextValue: (row) => formatDuration(row.durationMs),
    }),
    createProperTextEnumColumnConfig({
      title: "Algorithme",
      getEnumValue: (row) => row.forecastType,
      enumLookup: forecastMethods
        .filter((method) => !method.premium)
        .reduce((agg, method) => Object.assign(agg, { [method.value]: method.label }), {}),
      field: "forecastType",
    }),
    createDateColumnConfig({
      title: "Début de modélisation",
      getDate: (row) => row.forecastBegin,
      field: "forecastBegin",
    }),
    createDateColumnConfig({
      title: "Fin de modélisation",
      getDate: (row) => row.forecastEnd,
      field: "forecastEnd",
    }),
    createTaskStatusColumnConfig<RowType>(),
  ])

  async function getData(query: Query<RowType>): Promise<QueryResult<RowType>> {
    const res = await api.getManyBaseForecastTaskControllerForecastTask({
      page: createTypeORMCrudPage(query),
      limit: createTypeORMCrudLimit(query),
      sort: createTypeORMCrudSort(query),
      filter: createTypeORMCrudFilter(query),
    })
    if (query.filters.length === 0 && query.search.length === 0 && res.count === 0) {
      setIsEmpty(true)
    }
    return {
      page: query.page,
      totalCount: res.total,
      data: await fetchAndAddSourcesToRows(api, res.data, (row) => row.sourceId),
    }
  }

  return (
    <>
      {isEmpty ? (
        <EmptyStateRedirect
          route={AppRoute.FORECAST_TASK_CREATE}
          actionText={"Créer une première modélisation"}
          headerText={"Bienvenue dans le module de modélisation statistiques"}
          helpText={`Ce module vous permet de prédir une partie des données en définissant 
        les données d'entraînement ainsi que la méthode de prédiction.`}
          examplePicturePublicPath={"/examples/forecast_example.png"}
        />
      ) : (
        <PageBlock titleInContent={true}>
          <MaterialTable
            title={`Historique des modélisations statistiques`}
            data={getData}
            onRowClick={(row) => {
              if (row) {
                history.push(buildUrl({ route: AppRoute.FORECAST_TASK_VIEW, params: { taskId: row.id + "" } }))
              }
            }}
            columns={columns}
            options={{
              search: false,
              filtering: true,
              exportCsv: createTableExportCsv({
                exportConfig,
                fileName: `forecasts.csv`,
                getData,
              }),
            }}
          />
        </PageBlock>
      )}
    </>
  )
})
