import { Hidden, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import React, { useState } from "react"
import { AlertDto, AlertListDto, ModelListDto } from "api/timelight-api"
import AlertActivity from "../../component/AlertActivity"
import AlertAnomaly from "../../component/AlertAnomaly"
import AlertBetterModel from "../../component/AlertBetterModel"
import AlertShape from "../../component/AlertShape"
import AppHeader from "../../component/AppHeader"
import DaysNearDateModal from "../../component/DaysNearDateModal/index"
import LoaderBlock from "../../component/LoaderBlock"
import {
  createDateColumnConfig,
  createNumericColumnConfig,
  createTableExportCsv,
  createTextEnumColumnConfig,
  extractColumnConfigAndExportConfig,
  MaterialTable,
} from "../../component/MaterialTable"
import PageBlock from "../../component/PageBlock"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { createIdMap } from "../../lib/array"
import { daysOfWeekList, formatDayOfWeek } from "../../lib/date"
import { toDateObj } from "../../lib/date"
import { useAlertsAndModelsAndSources } from "../../state/api"
import { AlertActivityEnum } from "../../api/timelight-api/models/AlertActivityEnum"
import { GetManySourceDtoResponseDto } from "../../api/timelight-api/models/GetManySourceDtoResponseDto"

export default function MonitorAlerts() {
  // fetch some data
  const [{ data, loading }] = useAlertsAndModelsAndSources({ shouldTrigger: true })
  // const data: any = null
  // const loading = true
  // state for our day modal
  const [selectedAlert, setSelectedAlert] = useState<AlertDto | null>(null)
  return (
    <PageContainer title="Dashboard Alertes">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Hidden only={["xs"]}>
            <Typography variant="h6">Dashboard Alertes</Typography>
          </Hidden>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        {data && selectedAlert && (
          <DaysNearDateModal
            open={!!selectedAlert}
            sourceId={selectedAlert.source_id}
            initialDate={selectedAlert.date}
            onClose={() => setSelectedAlert(null)}
          />
        )}

        {loading ? (
          <LoaderBlock
            message="Nous réunissons l'ensemble des alertes dans une seule page pour vous"
            helpText={`En fonction du nombre d'alertes à traiter, 
          cette page peut mettre jusqu'à 1 minute à se charger, veuillez patienter.`}
          />
        ) : (
          <PageBlock loading={!data} titleInContent={true}>
            {data && <AlertTable data={data} setSelectedAlert={setSelectedAlert} />}
          </PageBlock>
        )}
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on alert select
   this will empty the filters
 */
const AlertTable = React.memo(function AlertTableComponent({
  data,
  setSelectedAlert,
}: {
  data: ModelListDto & { sources: GetManySourceDtoResponseDto["data"] } & AlertListDto
  setSelectedAlert: (alert: AlertDto | null) => void
}) {
  // have a quick kv store of models and sources
  const modelsById = createIdMap(data.models)
  const sourcesById = createIdMap(data.sources)

  const { columns, exportConfig } = extractColumnConfigAndExportConfig<AlertDto>([
    createTextEnumColumnConfig({
      title: "Capteur",
      getTextValue: (row) => sourcesById[row.source_id].name,
      enumValues: data.sources.map((s) => s.name).sort(),
    }),
    createTextEnumColumnConfig({
      title: "Jour",
      getTextValue: (row) => formatDayOfWeek(row.date),
      enumValues: daysOfWeekList,
      sortEnum: false,
    }),
    createDateColumnConfig({
      title: "Date",
      getDate: (row) => toDateObj(row.date),
      domain: data.alerts.map((row) => toDateObj(row.date)),
    }),
    createNumericColumnConfig({
      title: "Criticité",
      getValue: (row) => row.criticity,
    }),
    createTextEnumColumnConfig({
      title: "Anomalie",
      getTextValue: (row) => (row.anomaly ? "Anomalie" : "Pas d'anomalie"),
      enumValues: ["Anomalie", "Pas d'anomalie"],
      render: (row) => <AlertAnomaly alert={row} />,
    }),
    createTextEnumColumnConfig({
      title: "Forme",
      getTextValue: (row) => (row.shape ? "Écart de forme" : "Pas d'écart de forme"),
      enumValues: ["Écart de forme", "Pas d'écart de forme"],
      render: (row) => <AlertShape alert={row} />,
    }),
    createTextEnumColumnConfig({
      title: "Activité",
      getTextValue: (row) =>
        row.activity === AlertActivityEnum.Over
          ? "Sur-activité"
          : row.activity === AlertActivityEnum.Under
          ? "Sous-activité"
          : "Pas de sur-activité, pas de sous-activité",
      enumValues: ["Sur-activité", "Sous-activité", "Pas de sur-activité, pas de sous-activité"],
      render: (row) => <AlertActivity alert={row} />,
    }),
    createTextEnumColumnConfig({
      title: "Meilleur profil type détecté",
      getTextValue: (row) =>
        row.better_model_id ? modelsById[row.better_model_id].name : "Pas de meilleur profil type",
      enumValues: data.models
        .map((m) => m.name)
        .sort()
        .concat("Pas de meilleur profil type"),
      render: (row) => (
        <AlertBetterModel
          alert={row}
          getModelColor={(a) => (a.better_model_id ? modelsById[a.better_model_id].color : null)}
          getModelName={(a) => (a.better_model_id ? modelsById[a.better_model_id].name : null)}
        />
      ),
    }),
  ])

  return (
    <>
      <MaterialTable
        title="Alertes à traiter"
        data={data.alerts}
        onRowClick={setSelectedAlert}
        columns={columns}
        options={{
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `alerts.csv`,
          }),
        }}
      />
    </>
  )
})
