/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
  /**
   * Our super secret json web token
   * @type {string}
   * @memberof LoginResponseDto
   */
  jwt: string
}

export function LoginResponseDtoFromJSON(json: any): LoginResponseDto {
  return LoginResponseDtoFromJSONTyped(json, false)
}

export function LoginResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    jwt: json["jwt"],
  }
}

export function LoginResponseDtoToJSON(value?: LoginResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    jwt: value.jwt,
  }
}
