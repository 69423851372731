import React, { PureComponent } from "react"
import { Text } from "recharts"
import { contextValueToText } from "./DaysNearDateModal/DayContextAnalysis"
import { DayContextTypes } from "../api/timelight-api/models/DayContextTypes"

/**
 * Y axis may contain very large numbers, like 800 000
 * We want to format them like so: 800k
 */
export default class YAxisContextTypeTick extends PureComponent<{
  x: number
  y: number
  contextType: DayContextTypes
  payload?: {
    value: number
  }
}> {
  public render() {
    const { payload, contextType } = this.props
    return <Text {...this.props}>{payload && contextValueToText({ ctxType: contextType, value: payload.value })}</Text>
  }
}
