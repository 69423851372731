/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { PrevisionSaveDto, PrevisionSaveDtoFromJSON, PrevisionSaveDtoFromJSONTyped, PrevisionSaveDtoToJSON } from "./"

/**
 *
 * @export
 * @interface PrevisionBulkSaveDto
 */
export interface PrevisionBulkSaveDto {
  /**
   * The year to update the prevision, must be in the future
   * @type {number}
   * @memberof PrevisionBulkSaveDto
   */
  year: number
  /**
   * The source id to update previsions on
   * @type {number}
   * @memberof PrevisionBulkSaveDto
   */
  sourceId: number
  /**
   * List of previsions to update
   * @type {Array<PrevisionSaveDto>}
   * @memberof PrevisionBulkSaveDto
   */
  previsions: Array<PrevisionSaveDto>
}

export function PrevisionBulkSaveDtoFromJSON(json: any): PrevisionBulkSaveDto {
  return PrevisionBulkSaveDtoFromJSONTyped(json, false)
}

export function PrevisionBulkSaveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrevisionBulkSaveDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    year: json["year"],
    sourceId: json["sourceId"],
    previsions: (json["previsions"] as Array<any>).map(PrevisionSaveDtoFromJSON),
  }
}

export function PrevisionBulkSaveDtoToJSON(value?: PrevisionBulkSaveDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    year: value.year,
    sourceId: value.sourceId,
    previsions: (value.previsions as Array<any>).map(PrevisionSaveDtoToJSON),
  }
}
