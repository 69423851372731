/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DayContextTypes {
  Dju = "dju",
  TempMaxDeg = "temp_max_deg",
  TempMinDeg = "temp_min_deg",
  WindSpeedKmh = "wind_speed_kmh",
  WindTempDeg = "wind_temp_deg",
  RainMm = "rain_mm",
  HumidityPerc = "humidity_perc",
  VisibilityKm = "visibility_km",
  CloudCoverPerc = "cloud_cover_perc",
  HeatIdxUnit = "heat_idx_unit",
  DewPointDeg = "dew_point_deg",
  PressureHpa = "pressure_hpa",
  SunriseSec = "sunrise_sec",
  SunsetSec = "sunset_sec",
  DayLengthSec = "day_length_sec",
  HistoriqueMeteoEnum = "historique_meteo_enum",
}

export function DayContextTypesFromJSON(json: any): DayContextTypes {
  return DayContextTypesFromJSONTyped(json, false)
}

export function DayContextTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayContextTypes {
  return json as DayContextTypes
}

export function DayContextTypesToJSON(value?: DayContextTypes | null): any {
  return value as any
}
