import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  Typography,
} from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import ValidIcon from "@material-ui/icons/Check"
import FindAnomaliesIcon from "@material-ui/icons/FindInPage"
import ViewDayIcon from "@material-ui/icons/RemoveRedEye"
import AlertIcon from "@material-ui/icons/Warning"
import AppLink from "component/AppLink"
import GridHighlight from "component/GridHighlight"
import lodash from "lodash"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import posed from "react-pose"
import { AppRoute } from "state/route"
import { DayListDto, DayModelDto, ModelListDto, SourceDto } from "api/timelight-api"
import AppHeader from "../../component/AppHeader"
import { DATA_NOT_PROVIDED_MODEL_ID } from "../../component/ChartDayActivity"
import DaysNearDateModal from "../../component/DaysNearDateModal/DaysNearDateModal"
import DayYearCalendar from "../../component/DayYearCalendar"
import {
  createDateColumnConfig,
  createNumericColumnConfig,
  createTableExportCsv,
  createTextEnumColumnConfig,
  extractColumnConfigAndExportConfig,
  MaterialTable,
} from "../../component/MaterialTable"
import PageBlock from "../../component/PageBlock"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import ScatterDays from "../../component/ScatterDays"
import SideMenu from "../../component/SideMenu"
import SourceSelect from "../../component/SourceSelect"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { createModelCellConfig } from "../../component/table/modelCell"
import { dateObjToDayString, daysOfWeekList, formatDate, formatDayOfWeek, formatMonth, monthList } from "../../lib/date"
import { toDateObj } from "../../lib/date"
import { useApiClient, useDayAndModelList } from "../../state/api"
import { useApiExceptionHandler } from "../../state/auth"
import { useSourceSelect } from "../../state/persisted"
import { AppButton } from "../../component/AppButton"

const useStyles = makeStyles((theme) => ({
  anomalyButton: {
    color: theme.palette.getContrastText(red[500]),
    fontWeight: 800,
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  unsetAnomalyButton: {
    color: "#FFF",
    fontWeight: 800,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  leftIcon: {
    marginRight: 4,
  },
  modalAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  saveContainer: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },
  savePaper: {
    maxWidth: "400px",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  saveActionsContainer: {
    display: "flex",
  },
}))

export default function DataAnomaly() {
  const { t } = useTranslation()
  // our styles
  const classes = useStyles()

  // the app bar for values
  const [source, setSourceId] = useSourceSelect()

  // fetch some data
  const [{ data, loading }, , setData] = useDayAndModelList({
    shouldTrigger: source.id !== -1,
    sourceId: source.id,
    year: source.referenceYear,
  })

  // state for our day modal
  const [selectedDay, setSelectedDay] = useState<DayModelDto | null>(null)

  // anomaly api call
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Données mises à jour",
  })
  const api = useApiClient()
  const handleApiException = useApiExceptionHandler()
  const [submitting, setSubmitting] = useState<boolean>(false)
  async function toggleSelectedAnomaly() {
    if (!selectedDay || !data) {
      return
    }
    setSubmitting(true)
    try {
      const { days } = await api.dayControllerBulkUpdate({
        daysPatchDto: {
          sourceId: source.id,
          days: [{ date: selectedDay.date, anomaly: !selectedDay.anomaly }],
        },
      })
      // update local data
      setData({
        ...data,
        days: data.days.map((d) => days.find((nd) => nd.date === d.date) || d),
      })
      // close modal
      setSelectedDay(null)

      // yep, we're done
      showSuccessMessage()
    } catch (e) {
      handleApiException(e)
    } finally {
      setSubmitting(false)
    }
  }

  async function bulkUpdate(inputDays: DayModelDto[], anomaly: boolean) {
    if (!data) {
      return
    }
    setSubmitting(true)
    try {
      const { days } = await api.dayControllerBulkUpdate({
        daysPatchDto: { sourceId: source.id, days: inputDays.map((d) => ({ date: d.date, anomaly })) },
      })
      // update local data
      setData({
        ...data,
        days: data.days.map((d) => days.find((nd) => nd.date === d.date) || d),
      })
      // close modals
      setSelectedDay(null)
      setAnomalyDetectDates(null)

      // yep, we're done
      showSuccessMessage()
    } catch (e) {
      handleApiException(e)
    } finally {
      setSubmitting(false)
    }
  }

  const [anomalyDetectDates, setAnomalyDetectDates] = useState<string[] | null>(null)
  async function anomaliesAutoDetect() {
    if (!data) {
      return
    }
    setSubmitting(true)
    try {
      const { dates } = await api.aiControllerGetSourceAnomalies({ sourceId: source.id })

      // filter dates already set as anomaly
      setAnomalyDetectDates(dates.filter((d) => !data.days.find((day) => day.date === d && day.anomaly)))
    } catch (e) {
      handleApiException(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <PageContainer title="Filtrage des anomalies">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Filtrage des anomalies de
            <SourceSelect sourceId={source.id} setSourceId={setSourceId} />
            sur l'année de référence {source.referenceYear}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SuccessMessage />

        {data && selectedDay && (
          <DaysNearDateModal
            open={!!selectedDay}
            sourceId={source.id}
            initialDate={selectedDay.date}
            onClose={() => setSelectedDay(null)}
          >
            <div className={classes.modalAction}>
              {selectedDay.anomaly ? (
                <AppButton
                  className={classes.unsetAnomalyButton}
                  color="primary"
                  onClick={toggleSelectedAnomaly}
                  submitting={submitting}
                >
                  <ValidIcon className={classes.leftIcon} />
                  Marquer comme valide
                </AppButton>
              ) : (
                <AppButton
                  className={classes.anomalyButton}
                  color="primary"
                  onClick={toggleSelectedAnomaly}
                  submitting={submitting}
                >
                  <AlertIcon className={classes.leftIcon} />
                  Marquer comme anomalie
                </AppButton>
              )}
            </div>
          </DaysNearDateModal>
        )}

        <PageBlock loading={loading} title={`Cartographie de l'année de référence: ${source.referenceYear}`}>
          {data && <ScatterDays models={data.models} days={data.days} setSelectedDay={setSelectedDay} />}
          {data && (
            <>
              <Divider />
              <div style={{ marginTop: 20, display: "flex", width: "100%", justifyContent: "center" }}>
                <AppButton variant="contained" color="primary" onClick={anomaliesAutoDetect} submitting={submitting}>
                  <FindAnomaliesIcon className={classes.leftIcon} />
                  {t("anomaly.autoDetectButton")}
                </AppButton>

                <Dialog open={anomalyDetectDates !== null} onClose={() => setAnomalyDetectDates(null)}>
                  {anomalyDetectDates && data && (
                    <>
                      {anomalyDetectDates.length > 0 ? (
                        <>
                          <DialogTitle>{anomalyDetectDates.length} anomalies détectées</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Nous avons détecté des anomalies potentielles:
                              <ul>
                                {anomalyDetectDates.map((d) => (
                                  <li key={d}>
                                    {formatDate(d)}{" "}
                                    <Fab
                                      style={{ marginLeft: 10 }}
                                      size="small"
                                      color="primary"
                                      onClick={() => {
                                        const day = data.days.find((dd) => dd.date === d)
                                        if (day) {
                                          setSelectedDay(day)
                                        }
                                      }}
                                    >
                                      <ViewDayIcon />
                                    </Fab>
                                  </li>
                                ))}
                              </ul>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <AppButton
                              onClick={() => setAnomalyDetectDates(null)}
                              color="primary"
                              disabled={submitting}
                            >
                              Annuler
                            </AppButton>
                            <AppButton
                              type="submit"
                              onClick={() =>
                                bulkUpdate(
                                  data.days.filter((d) => anomalyDetectDates.some((ad) => ad === d.date)),
                                  true,
                                )
                              }
                              color="inherit"
                              style={{ color: red[500] }}
                              submitting={submitting}
                            >
                              Marquer les {anomalyDetectDates.length} dates comme anomalie
                            </AppButton>
                          </DialogActions>
                        </>
                      ) : (
                        <>
                          <DialogTitle>Aucune nouvelle anomalie détectée</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Notre moteur n'a pas détecté d'autres anomalies, vous pouvez passer à la{" "}
                              <AppLink route={AppRoute.DATA_SOURCE_MODELS}>configuration des profil types</AppLink>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <AppButton
                              onClick={() => setAnomalyDetectDates(null)}
                              color="primary"
                              submitting={submitting}
                            >
                              OK
                            </AppButton>
                          </DialogActions>
                        </>
                      )}
                    </>
                  )}
                </Dialog>
              </div>
            </>
          )}
        </PageBlock>

        <PageBlock loading={loading} titleInContent={true}>
          {data && (
            <AnomalyDayTable
              data={data}
              source={source}
              setSelectedDay={setSelectedDay}
              submitting={submitting}
              bulkUpdate={bulkUpdate}
            />
          )}
        </PageBlock>

        {data && (
          <PageBlock loading={loading} title={`Calendrier de l'année de réference: ${source.referenceYear}`}>
            {data && (
              <DayYearCalendar
                year={source.referenceYear}
                models={data.models}
                days={data.days.map((d) => ({ ...d, model_id: d.model_id || DATA_NOT_PROVIDED_MODEL_ID }))}
                setSelectedDay={(selected) => setSelectedDay(data.days.find((d) => d.date === selected.date) || null)}
              />
            )}
          </PageBlock>
        )}
      </PageContent>
    </PageContainer>
  )
}

const SaveAnimationContainer = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
})

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const AnomalyDayTable = React.memo(function AnomalyDayTableComponent({
  data,
  source,
  setSelectedDay,
  submitting,
  bulkUpdate,
}: {
  data: ModelListDto & DayListDto
  source: SourceDto
  setSelectedDay: (day: DayModelDto | null) => void
  submitting: boolean
  bulkUpdate: (days: DayModelDto[], anomaly: boolean) => void
}) {
  const classes = useStyles()

  // the save modal container
  const [selectedRows, setSelectedRows] = useState<DayModelDto[]>([])

  // avoid re-rendering data table when row data change
  const models = data.models
  const dateDomain = data.days.map((row) => toDateObj(row.date))
  const minDateStr = dateObjToDayString(lodash.min(dateDomain) || new Date())
  const maxDateStr = dateObjToDayString(lodash.max(dateDomain) || new Date())

  const { columns, exportConfig } = useMemo(
    () =>
      extractColumnConfigAndExportConfig<DayModelDto>([
        createDateColumnConfig({
          title: "Date",
          getDate: (row) => toDateObj(row.date),
          domain: [toDateObj(minDateStr), toDateObj(maxDateStr)],
        }),
        createTextEnumColumnConfig({
          title: "Jour",
          getTextValue: (row) => formatDayOfWeek(row.date),
          enumValues: daysOfWeekList,
          sortEnum: false,
        }),
        createTextEnumColumnConfig({
          title: "Mois",
          getTextValue: (row) => formatMonth(row.date),
          enumValues: monthList,
        }),
        createModelCellConfig({
          models,
          getModelId: (row) => row.model_id,
        }),
        createNumericColumnConfig({
          title: "Inertie",
          getValue: (row) => row.inertia || 0,
        }),
        createTextEnumColumnConfig({
          title: "Anomalie",
          render: (row) => (
            <GridHighlight color={row.anomaly ? "#e05757" : "#61c569"}>{row.anomaly ? "Oui" : "Non"}</GridHighlight>
          ),
          getTextValue: (row) => (row.anomaly ? "Oui" : "Non"),
          enumValues: ["Oui", "Non"],
        }),
      ]),
    [models, minDateStr, maxDateStr],
  )

  return (
    <>
      <SaveAnimationContainer className={classes.saveContainer} pose={selectedRows.length > 0 ? "visible" : "hidden"}>
        <Paper className={classes.savePaper}>
          <Typography style={{ marginBottom: 10 }}>
            Que souhaitez-vous faire des {selectedRows.length} lignes sélectionnées ?
          </Typography>
          <div className={classes.saveActionsContainer}>
            <AppButton
              className={classes.unsetAnomalyButton}
              style={{ marginRight: "5px" }}
              color="primary"
              submitting={submitting}
              onClick={async () => {
                bulkUpdate(selectedRows, false)
                setSelectedRows([])
              }}
            >
              <ValidIcon className={classes.leftIcon} />
              Marquer comme valides
            </AppButton>
            <AppButton
              className={classes.anomalyButton}
              color="primary"
              submitting={submitting}
              onClick={async () => {
                bulkUpdate(selectedRows, true)
                setSelectedRows([])
              }}
            >
              <AlertIcon className={classes.leftIcon} />
              Marquer comme anomalies
            </AppButton>
          </div>
        </Paper>
      </SaveAnimationContainer>
      <MaterialTable
        title={`Journées de l'année de référence: ${source.referenceYear}`}
        data={data.days}
        onRowClick={setSelectedDay}
        columns={columns}
        onSelectionChange={(newSelection) => setSelectedRows(newSelection)}
        selectedRows={selectedRows}
        options={{
          selection: true,
          exportCsv: createTableExportCsv({
            exportConfig,
            fileName: `day_data_${source.referenceYear}.csv`,
          }),
        }}
      />
    </>
  )
})
