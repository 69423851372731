/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SerieBlockTimeStep {
  _1d = "1d",
  _12h = "12h",
  _6h = "6h",
  _3h = "3h",
  _1h = "1h",
  _30m = "30m",
  _10m = "10m",
  _1m = "1m",
  _1s = "1s",
}

export function SerieBlockTimeStepFromJSON(json: any): SerieBlockTimeStep {
  return SerieBlockTimeStepFromJSONTyped(json, false)
}

export function SerieBlockTimeStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerieBlockTimeStep {
  return json as SerieBlockTimeStep
}

export function SerieBlockTimeStepToJSON(value?: SerieBlockTimeStep | null): any {
  return value as any
}
