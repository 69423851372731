import React from "react"
import { SerieBlockTimeStep } from "../../api/timelight-api/models/SerieBlockTimeStep"
import HighchartsReact from "highcharts-react-official"
import * as Highcharts from "highcharts/highstock"
import highchartsMore from "highcharts/highcharts-more"
import * as lodash from "lodash"
import Loader from "../Loader"
import { dayMs, formatDateTime } from "../../lib/date"
import { useAsyncAction } from "../../state/async"
import { formatNumber } from "lib/number"
import { findClosestBy } from "../../lib/array"
import { SerieChartContext, SerieChartContextState } from "./serieChartContext"

highchartsMore(Highcharts)

const blockTimeStepOrder = {
  [SerieBlockTimeStep._1s]: 1,
  [SerieBlockTimeStep._1m]: 2,
  [SerieBlockTimeStep._10m]: 3,
  [SerieBlockTimeStep._30m]: 4,
  [SerieBlockTimeStep._1h]: 5,
  [SerieBlockTimeStep._3h]: 6,
  [SerieBlockTimeStep._6h]: 7,
  [SerieBlockTimeStep._12h]: 8,
  [SerieBlockTimeStep._1d]: 9,
}
export function getMaxSerieResample(resamples: (SerieBlockTimeStep | undefined)[]): SerieBlockTimeStep | undefined {
  if (resamples.length <= 0) {
    return undefined
  }
  let maxValue: SerieBlockTimeStep | null = null
  for (const r of resamples) {
    if (!r) {
      continue
    }
    const rv = blockTimeStepOrder[r]
    if (!rv) {
      throw new Error("Unknown SerieBlockTimeStep " + r)
    }
    if (maxValue === null || (rv && rv > blockTimeStepOrder[maxValue])) {
      maxValue = r
    }
  }
  if (maxValue === null) {
    return undefined
  }
  return maxValue
}

export function getSerieDataResample(begin: Date, end: Date) {
  const dateSpan = end.getTime() - begin.getTime()
  const resample =
    dateSpan < 10 * dayMs
      ? undefined
      : dateSpan < 100 * dayMs
      ? SerieBlockTimeStep._1h
      : dateSpan < 300 * dayMs
      ? SerieBlockTimeStep._3h
      : SerieBlockTimeStep._1d
  return resample
}

export type SerieData = [number, ...(number | null)[]][]

export interface SerieChartProps {
  chartDomain: { begin: Date; end: Date }
  getSeriesData: (begin: Date, end: Date, resample?: SerieBlockTimeStep) => Promise<SerieData[]>
  getPlotBands?: (seriesData: SerieData[]) => Highcharts.XAxisPlotBandsOptions[]
  seriesConfig: Highcharts.SeriesOptionsType[]
  additionalHighchartOptions?: Highcharts.Options
  dataToPointsConfig?: (serieIdx: number, data: SerieData) => Highcharts.PointOptionsType[]
}

export const SerieChart = (props: SerieChartProps) => {
  const ctx = React.useContext(SerieChartContext)
  return <SerieChartView {...props} {...ctx} />
}

export const SerieChartView = React.memo(function SerieChartViewComponent({
  chartDomain,
  getSeriesData,
  onZoomChanged,
  registerChartObj,
  getPlotBands,
  seriesConfig,
  additionalHighchartOptions,
  dataToPointsConfig,
}: SerieChartProps & SerieChartContextState) {
  const chartRef = React.useRef<{ chart: Highcharts.Chart }>()

  const chartAfterSetExtremes = React.useMemo(() => {
    const cb = async function (e: any) {
      if (e.type !== "setExtremes") {
        return
      }
      // @ts-ignore
      const chart: Highcharts.Chart = this.chart
      const minDate = e.min ? new Date(e.min) : chartDomain.begin
      const maxDate = e.max ? new Date(e.max) : chartDomain.end

      // register a zoom event
      onZoomChanged({ begin: minDate, end: maxDate }, chart)

      // @ts-ignore
      chart.showLoading("Loading data from server...")
      const resample = getSerieDataResample(minDate, maxDate)

      // set series data
      // add some margin to display border data
      const dateMargin = (maxDate.getTime() - minDate.getTime()) * 0.05
      const queryMinDate = new Date(minDate.getTime() - dateMargin)
      const queryMaxDate = new Date(maxDate.getTime() + dateMargin)

      const seriesData = await getSeriesData(queryMinDate, queryMaxDate, resample)

      for (let serieIdx = 0; serieIdx < seriesData.length; serieIdx++) {
        if (serieIdx < chart.series.length && chart.series[serieIdx]) {
          // add a null value at the start and the end to be able to pan
          const firstMetric = seriesData[serieIdx].length > 0 ? seriesData[serieIdx][0] : [new Date().getTime(), null]
          const start: SerieData[0] = (firstMetric.map((v) => null) as unknown) as SerieData[0]
          start[0] = chartDomain.begin.getTime()
          const end: SerieData[0] = (firstMetric.map((v) => null) as unknown) as SerieData[0]
          end[0] = chartDomain.end.getTime()
          // https://www.highcharts.com/errors/15/
          const newSerieData: SerieData = lodash.sortBy([start, ...seriesData[serieIdx], end], (i) => i[0])
          // set series data
          if (dataToPointsConfig) {
            chart.series[serieIdx].setData(dataToPointsConfig(serieIdx, newSerieData), false /* redraw */)
          } else {
            chart.series[serieIdx].setData(newSerieData, false /* redraw */)
          }
        }
      }

      // redraw only once all series are updated
      chart.redraw()

      // set plot bands
      if (getPlotBands) {
        const plotBands = getPlotBands(seriesData)
        // remove all plot existing bands
        // @ts-ignore
        chart.xAxis[0].plotLinesAndBands
          .filter((pb: any) => pb.id && pb.options.from !== undefined)
          .map((pb: any) => chart.xAxis[0].removePlotBand(pb.id))
        // add new plot bands
        plotBands.map((pb) => chart.xAxis[0].addPlotBand(pb))
      }

      chart.hideLoading()
    }
    return lodash.throttle(cb, 100, { leading: false, trailing: true })
  }, [getSeriesData, getPlotBands, chartDomain, onZoomChanged, dataToPointsConfig])

  const [{ data: chartData }] = useAsyncAction(
    ({ begin, end }) => getSeriesData(begin, end, getSerieDataResample(begin, end)),
    {
      shouldTrigger: true,
      begin: chartDomain.begin,
      end: chartDomain.end,
    },
  )

  // create highcharts options
  const highchartsOptions: Highcharts.Options = {
    time: {
      timezone: "Europe/Paris",
    },
    chart: {
      type: "area",
      zoomType: "x",
      panning: true as any,
      panKey: "shift",
    },

    navigator: {
      enabled: true,
      adaptToUpdatedData: true,
      maskInside: false,

      xAxis: [
        {
          type: "datetime",
          min: chartDomain.begin.getTime(),
          max: chartDomain.end.getTime(),
          visible: false,
        },
      ],
      yAxis: [
        {
          title: {
            text: null,
          },
          visible: false,
        },
      ],
      series: [
        {
          type: "line",
          data: [
            [chartDomain.begin.getTime(), null],
            [chartDomain.end.getTime(), null],
          ],

          showInNavigator: true,
          visible: false,
        },
      ],
    },

    scrollbar: {
      liveRedraw: false,
    },

    rangeSelector: {
      enabled: true,
      buttons: [
        {
          type: "hour",
          count: 1,
          text: "1h",
        },
        {
          type: "day",
          count: 1,
          text: "1d",
        },
        {
          type: "week",
          count: 1,
          text: "1w",
        },
        {
          type: "month",
          count: 1,
          text: "1m",
        },
        {
          type: "year",
          count: 1,
          text: "1y",
        },
        {
          type: "second",
          text: "-",
          events: {
            click(e) {
              if (chartRef.current) {
                const xAxis = chartRef.current.chart.xAxis[0]
                const begin = xAxis.min || chartDomain.begin.getTime()
                const end = xAxis.max || chartDomain.end.getTime()
                const delta = end - begin
                const newBegin = lodash.max([begin - delta, chartDomain.begin.getTime()]) as number
                const newEnd = lodash.min([end + delta, chartDomain.end.getTime()]) as number
                // zoom out 100%
                xAxis.setExtremes(newBegin, newEnd)
              }
              return false
            },
          },
        },
        {
          type: "all",
          text: "All",
        },
      ],
      inputEnabled: true, // it supports only days
      selected: 6, // all
    },

    title: {
      text: "",
      style: {
        display: "none",
      },
    },

    subtitle: {
      text: "",
      style: {
        display: "none",
      },
    },

    boost: {
      enabled: true,
      useGPUTranslations: true,
    },

    xAxis: [
      {
        type: "datetime",
        plotBands: getPlotBands && chartData ? getPlotBands(chartData) : [],
        events: {
          afterSetExtremes: chartAfterSetExtremes,
        },
        min: chartDomain.begin.getTime(),
        max: chartDomain.end.getTime(),
      },
    ],

    yAxis: [
      {
        title: {
          text: null,
        },
      },
    ],

    tooltip: {
      crosshairs: true,
      shared: true,

      formatter() {
        const series = ((this.points && this.points[0]?.series?.chart?.series) || []).slice(0, seriesConfig.length)
        return (
          `${formatDateTime(new Date(this.x))}<br/>` +
          series
            .map((serie, i) => {
              // find closest serie point
              const yData = ((serie as unknown) as { yData: number[] }).yData
              const closest = findClosestBy(serie.points, (p) => p.x, this.x) as Highcharts.Point & {
                i?: number
              }
              // intervals
              if (closest && closest.high !== undefined && closest.low !== undefined) {
                return `${serie.name}: <b>[${formatNumber(closest.low || 0, true)} ; ${formatNumber(
                  closest.high || 0,
                  true,
                )}]</b><br/>`
              }

              const y = !closest
                ? 0
                : closest.y !== undefined
                ? closest.y
                : closest.index !== undefined
                ? yData[closest.index]
                : closest.i !== undefined
                ? yData[closest.i]
                : 0
              const value = formatNumber(y || 0)
              return `${serie.name}: <b>${value}</b><br/>`
            })
            .join("")
        )
      },
    } as Highcharts.TooltipOptions,

    legend: {},

    plotOptions: {
      series: {
        dataGrouping: { enabled: false },
        gapUnit: "value",
        gapSize: 0,
        getExtremesFromAll: true,
      },
      area: {
        stacking: "normal",
        dataGrouping: { enabled: false },
        gapUnit: "value",
        gapSize: 0,
        getExtremesFromAll: true,
      },
      line: {
        dataGrouping: { enabled: false },
        gapUnit: "value",
        gapSize: 0,
        getExtremesFromAll: true,
      },
    },

    series: seriesConfig.map((s, i) => ({
      ...s,
      // @ts-ignore
      showInNavigator: s.showInNavigator !== undefined ? s.showInNavigator : true,
      // @ts-ignore
      data: chartData ? chartData[i] : s.data ? s.data : [],
    })),
  }

  if (!chartData) {
    return <Loader />
  }

  return (
    <HighchartsReact
      callback={registerChartObj}
      highcharts={Highcharts}
      options={lodash.merge(highchartsOptions, additionalHighchartOptions)}
      // @ts-ignore
      ref={chartRef}
    />
  )
})
