import React from "react"
import { useConfig } from "../config"
import { GoogleLoginButton, LinkedInLoginButton } from "react-social-login-buttons"

export function SocialLoginButtons({ mode }: { mode: "login" | "register" | "generic" }) {
  const { SOCIAL_AUTH_ENABLED, API_URL } = useConfig()

  function createProps({ path, name }: { path: string; name: string }) {
    return {
      style: {
        marginTop: "0.5em",
        height: "35px",
        fontSize: "0.75rem",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: 0,
      },
      iconSize: "20px",
      onClick: () => {
        // @ts-ignore
        window.location = API_URL + path
      },
      text:
        mode === "login"
          ? `Connexion ${name}`
          : mode === "register"
          ? `Inscription ${name}`
          : `Authentification ${name}`,
    }
  }
  return (
    <>
      {SOCIAL_AUTH_ENABLED && (
        <div style={{ marginTop: "2em", width: "100%", marginBottom: "2em" }}>
          <GoogleLoginButton {...createProps({ path: "/v1/user/google/auth", name: "Google" })} />
          <LinkedInLoginButton {...createProps({ path: "/v1/user/linkedin/auth", name: "LinkedIn" })} />
        </div>
      )}
    </>
  )
}
