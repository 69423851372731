/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SerieSyncTaskInputParams
 */
export interface SerieSyncTaskInputParams {
  /**
   *
   * @type {string}
   * @memberof SerieSyncTaskInputParams
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof SerieSyncTaskInputParams
   */
  refSourceId: number
  /**
   *
   * @type {number}
   * @memberof SerieSyncTaskInputParams
   */
  toSyncSourceId: number
  /**
   *
   * @type {Date}
   * @memberof SerieSyncTaskInputParams
   */
  trainBegin: Date
  /**
   *
   * @type {Date}
   * @memberof SerieSyncTaskInputParams
   */
  trainEnd: Date
}

export function SerieSyncTaskInputParamsFromJSON(json: any): SerieSyncTaskInputParams {
  return SerieSyncTaskInputParamsFromJSONTyped(json, false)
}

export function SerieSyncTaskInputParamsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SerieSyncTaskInputParams {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json["title"],
    refSourceId: json["refSourceId"],
    toSyncSourceId: json["toSyncSourceId"],
    trainBegin: new Date(json["trainBegin"]),
    trainEnd: new Date(json["trainEnd"]),
  }
}

export function SerieSyncTaskInputParamsToJSON(value?: SerieSyncTaskInputParams | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    refSourceId: value.refSourceId,
    toSyncSourceId: value.toSyncSourceId,
    trainBegin: value.trainBegin.toISOString(),
    trainEnd: value.trainEnd.toISOString(),
  }
}
