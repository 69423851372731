import React from "react"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import AppHeader from "../../component/AppHeader"
import { createTableExportCsv, extractColumnConfigAndExportConfig, MaterialTable } from "../../component/MaterialTable"
import PageBlock from "../../component/PageBlock"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { useApiClient } from "../../state/api"
import {
  createTextColumnConfig,
  createTypeORMCrudPage,
  createTypeORMCrudLimit,
} from "../../component/MaterialTable/material-table-helpers"
import AppLink from "../../component/AppLink/index"
import { AppRoute } from "state/route"
import ConfigIcon from "@material-ui/icons/Build"
import { buildUrl } from "../../component/AppLink"
import { useHistory } from "react-router"
import { SourcesGmaps } from "component/SourcesGmap"
import { AppButton } from "../../component/AppButton"
import { createSourceTagsColumnConfig } from "../../component/SourceTags"
import { SourceDto } from "../../api/timelight-api/models/SourceDto"
import {
  createTypeORMCrudSort,
  createDateColumnConfig,
  Query,
} from "../../component/MaterialTable/material-table-helpers"
import { EmptyStateRedirect } from "../../component/EmptyStateRedirect"
import { QueryResult } from "material-table"

export default function SourceList() {
  // fetch some data
  const history = useHistory()
  function setSource(newSource: SourceDto) {
    history.push(buildUrl({ route: AppRoute.DATA_SOURCE_EXPLORE, params: { sourceId: newSource.id + "" } }))
  }
  const sourcesWithLatLng: SourceDto[] = [] /*sourceData
    ? sourceData.sources.filter((s) => s.latitude !== null && s.longitude !== null)
    : []*/
  return (
    <PageContainer title="Vos sources">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Gestion de vos sources
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SourceTable onSourceClick={setSource} />
        {sourcesWithLatLng.length > 0 && (
          <PageBlock>
            <div style={{ height: "400px" }}>
              <SourcesGmaps sources={sourcesWithLatLng} onClick={() => {}} onSourceClick={setSource} />
            </div>
          </PageBlock>
        )}
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const SourceTable = React.memo(function SourceTableComponent({
  onSourceClick,
}: {
  onSourceClick: (source: SourceDto) => void
}) {
  const api = useApiClient()
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false)
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<SourceDto>([
    createTextColumnConfig({
      title: "Source",
      getTextValue: (row) => row.name,
      field: "name",
    }),
    createDateColumnConfig({
      title: "Date de création",
      getDate: (row) => row.createdDate,
      field: "createdDate",
    }),
    createDateColumnConfig({
      title: "Première donnée",
      getDate: (row) => row.dateDomainBegin,
      field: "dateDomainBegin",
    }),
    createDateColumnConfig({
      title: "Dernière donnée",
      getDate: (row) => row.dateDomainEnd,
      field: "dateDomainEnd",
    }),
    createSourceTagsColumnConfig({
      title: "Tags",
      getTags: (row) => row.tags,
    }),
    {
      filtering: false,
      sorting: false,
      render: (row) => (
        <AppLink route={AppRoute.DATA_SOURCE_CONFIG} params={{ sourceId: row.id + "" }}>
          <AppButton style={{ margin: "1em" }} variant="outlined">
            <ConfigIcon />
          </AppButton>
        </AppLink>
      ),
      type: ("string" as unknown) as undefined,
      title: "Configuration",
      exportRender: (_) => "",
      export: false,
    },
  ])

  async function getData(query: Query<SourceDto>): Promise<QueryResult<SourceDto>> {
    const res = await api.getManyBaseSourceControllerSourceDto({
      page: createTypeORMCrudPage(query),
      limit: createTypeORMCrudLimit(query),
      sort: createTypeORMCrudSort(query),
      s: JSON.stringify({
        $and: [
          query.search && query.search.length > 0
            ? {
                $or: [{ tags: { $cont: query.search } }, { name: { $contL: query.search } }],
              }
            : undefined,
          query.filters && query.filters.length > 0
            ? {
                $and: query.filters
                  .filter((filter) => !!filter.column.field)
                  .map((filter) => ({
                    [filter.column.field as string]: { $contL: filter.value },
                  })),
              }
            : undefined,
        ].filter((f) => f),
      }),
    })

    if (query.filters.length === 0 && query.search.length === 0 && res.count === 0) {
      setIsEmpty(true)
    }

    return {
      page: query.page,
      totalCount: res.total,
      data: res.data,
    }
  }

  return (
    <>
      {isEmpty ? (
        <EmptyStateRedirect
          route={AppRoute.SOURCE_CREATE_HUB}
          actionText={"Ajouter des premières données"}
          headerText={"Bienvenue dans votre espace Timelight Data"}
          helpText={`Pour utiliser Timelight à 100% de ses capacités, vous devez tout d'abord ajouter une première source de données.`}
        />
      ) : (
        <PageBlock titleInContent={true}>
          <MaterialTable
            title={"Vos sources"}
            data={getData}
            onRowClick={(row) => row && onSourceClick(row)}
            columns={columns}
            options={{
              exportCsv: createTableExportCsv({
                exportConfig,
                fileName: `sources.csv`,
                getData,
              }),
            }}
          />
        </PageBlock>
      )}
    </>
  )
})
