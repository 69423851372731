/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface ForecastAlert
 */
export interface ForecastAlert {
  /**
   *
   * @type {number}
   * @memberof ForecastAlert
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof ForecastAlert
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof ForecastAlert
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof ForecastAlert
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof ForecastAlert
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof ForecastAlert
   */
  readonly companyId: number
  /**
   *
   * @type {number}
   * @memberof ForecastAlert
   */
  sourceId: number
  /**
   *
   * @type {number}
   * @memberof ForecastAlert
   */
  readonly forecastTaskId: number
  /**
   *
   * @type {number}
   * @memberof ForecastAlert
   */
  criticity: number
  /**
   *
   * @type {Date}
   * @memberof ForecastAlert
   */
  start: Date
  /**
   *
   * @type {Date}
   * @memberof ForecastAlert
   */
  end: Date
}

export function ForecastAlertFromJSON(json: any): ForecastAlert {
  return ForecastAlertFromJSONTyped(json, false)
}

export function ForecastAlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForecastAlert {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    sourceId: json["sourceId"],
    forecastTaskId: json["forecastTaskId"],
    criticity: json["criticity"],
    start: new Date(json["start"]),
    end: new Date(json["end"]),
  }
}

export function ForecastAlertToJSON(value?: ForecastAlert | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    criticity: value.criticity,
    start: value.start.toISOString(),
    end: value.end.toISOString(),
  }
}
