/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
  /**
   * The user name
   * @type {string}
   * @memberof LoginDto
   */
  username: string
  /**
   * Our super secret password
   * @type {string}
   * @memberof LoginDto
   */
  password: string
}

export function LoginDtoFromJSON(json: any): LoginDto {
  return LoginDtoFromJSONTyped(json, false)
}

export function LoginDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    username: json["username"],
    password: json["password"],
  }
}

export function LoginDtoToJSON(value?: LoginDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    username: value.username,
    password: value.password,
  }
}
