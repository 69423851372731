import { Typography } from "@material-ui/core"
import MuiLink from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import logo from "./timelight-logo-transparent.png"

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  timelight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  timelightLink: {
    marginTop: "5px", // align logo text with surrounding text
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  sqreen: {
    marginTop: theme.spacing(1),
  },
  love: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "red",
  },
}))

export default function MadeWithLove() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography className={classes.timelight} variant="body1" color="textSecondary" align="center">
        Built with <span className={classes.love}>❤</span> by the
        <MuiLink color="inherit" href="https://timelight.tech" className={classes.timelightLink}>
          <img
            style={{ width: "97px" }}
            src={logo}
            alt="Timelight | Exploitez la richesse de vos données temporelles"
          />
        </MuiLink>{" "}
        team.
      </Typography>
      <a
        className={classes.sqreen}
        target="_blank"
        rel="noopener noreferrer"
        title="Realtime application protection"
        href="https://www.sqreen.com/?utm_source=badge"
      >
        <img
          style={{ width: "109px", height: "36px" }}
          src="https://s3-eu-west-1.amazonaws.com/sqreen-assets/badges/20171107/sqreen-light-badge.svg"
          alt="Sqreen | Runtime Application Protection"
        />
      </a>
    </div>
  )
}
