import React from "react"
import PieStats from "./PieStat"

export default function HandledAlertPieStats({
  alertCount,
  handledAlertCount,
}: {
  alertCount: number
  handledAlertCount: number
}) {
  return (
    <PieStats
      total={alertCount + handledAlertCount}
      totalTitle="Alertes restantes"
      count={handledAlertCount}
      countTitle="Alertes traitées"
      noDataTitle="Aucune alerte à traiter"
      color="#FF8042"
    />
  )
}
