import * as lodash from "lodash"
import React from "react"
import PageBlock from "./PageBlock"
import AppLink from "./AppLink/AppLink"
import { AppRoute } from "../state/route"
import { AppButton } from "./AppButton"

export function EmptyStateRedirect({
  headerText,
  helpText,
  actionText,
  route,
  examplePicturePublicPath,
}: {
  route: AppRoute
  headerText: string
  helpText: string
  actionText: string
  examplePicturePublicPath?: string
}) {
  const images = [
    "/undraw/undraw_add_document.svg",
    "/undraw/undraw_add_file.svg",
    "/undraw/undraw_add_post.svg",
    "/undraw/undraw_design_team.svg",
    "/undraw/undraw_forming_ideas.svg",
    "/undraw/undraw_new_entries.svg",
  ]

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PageBlock
        title={
          <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span style={{ marginRight: "1em" }}>{headerText}</span>
          </span>
        }
      >
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: "1em",
              textAlign: "center",
            }}
          >
            {examplePicturePublicPath && (
              <img style={{ maxWidth: "500px" }} alt="Exemple" src={examplePicturePublicPath} />
            )}
            {helpText && <p style={{ fontSize: "1.3em" }}>{helpText}</p>}

            <AppLink route={route}>
              <AppButton color="primary" variant="contained">
                {actionText}
              </AppButton>
            </AppLink>
          </div>
          <img style={{ maxWidth: "400px" }} alt={headerText} src={lodash.sample(images)} />
        </div>
      </PageBlock>
    </div>
  )
}
