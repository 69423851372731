/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface PrevisionSaveDto
 */
export interface PrevisionSaveDto {
  /**
   * The date with a format YYYY-MM-DD, part of the prevision PK
   * @type {string}
   * @memberof PrevisionSaveDto
   */
  date: string
  /**
   * Model id of the prevision, part of the prevision PK
   * @type {number}
   * @memberof PrevisionSaveDto
   */
  modelId: number
}

export function PrevisionSaveDtoFromJSON(json: any): PrevisionSaveDto {
  return PrevisionSaveDtoFromJSONTyped(json, false)
}

export function PrevisionSaveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrevisionSaveDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    date: json["date"],
    modelId: json["modelId"],
  }
}

export function PrevisionSaveDtoToJSON(value?: PrevisionSaveDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date: value.date,
    modelId: value.modelId,
  }
}
