/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  SourceDto,
  SourceDtoFromJSON,
  SourceDtoFromJSONTyped,
  SourceDtoToJSON,
  SourceGroupDto,
  SourceGroupDtoFromJSON,
  SourceGroupDtoFromJSONTyped,
  SourceGroupDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface RecomputeSourceModelsResponseDto
 */
export interface RecomputeSourceModelsResponseDto {
  /**
   * The updated sources
   * @type {Array<SourceDto>}
   * @memberof RecomputeSourceModelsResponseDto
   */
  sources: Array<SourceDto>
  /**
   * The new source groups
   * @type {Array<SourceGroupDto>}
   * @memberof RecomputeSourceModelsResponseDto
   */
  groups: Array<SourceGroupDto>
}

export function RecomputeSourceModelsResponseDtoFromJSON(json: any): RecomputeSourceModelsResponseDto {
  return RecomputeSourceModelsResponseDtoFromJSONTyped(json, false)
}

export function RecomputeSourceModelsResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RecomputeSourceModelsResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sources: (json["sources"] as Array<any>).map(SourceDtoFromJSON),
    groups: (json["groups"] as Array<any>).map(SourceGroupDtoFromJSON),
  }
}

export function RecomputeSourceModelsResponseDtoToJSON(value?: RecomputeSourceModelsResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sources: (value.sources as Array<any>).map(SourceDtoToJSON),
    groups: (value.groups as Array<any>).map(SourceGroupDtoToJSON),
  }
}
