import React from "react"
import { DayContextTypes } from "../../api/timelight-api/models/DayContextTypes"
import * as lodash from "lodash"
import { Field } from "react-final-form"
import { ContextTypeSelectTable } from "../ContextTypeSelectTable"
import { getUniqueObjectId } from "../../lib/uniq"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"
import { FormHelperText } from "@material-ui/core"

export interface ContextTypesSelectFormState {
  sourceId: number
  contextTypes: DayContextTypes[]
  filterByDateDomain: null | { begin: Date; end: Date }
}

export function createContextTypeSelectTableField<FormState>(props: { name: keyof FormState & string }) {
  return <Field {...props} render={ContextTypeSelectTableFieldRender} />
}

const ContextTypeSelectTableFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<ContextTypesSelectFormState, {}>) => {
    return (
      <div>
        <ContextTypeSelectTable
          sourceId={props.input.value.sourceId}
          filterByDateDomain={props.input.value.filterByDateDomain || undefined}
          selection={props.input.value.contextTypes}
          onSelectionChange={(ctxTypes) => props.input.onChange({ ...props.input.value, contextTypes: ctxTypes })}
        />
        {props.meta.error &&
          (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
            <FormHelperText key={i} error={true}>
              {e}
            </FormHelperText>
          ))}
      </div>
    )
  },
  (props: CustomFieldRenderProps<ContextTypesSelectFormState, {}>) => {
    return (
      props.input.value.sourceId +
      getUniqueObjectId(props.input.value.contextTypes) +
      getUniqueObjectId(props.input.value.filterByDateDomain?.begin) +
      getUniqueObjectId(props.input.value.filterByDateDomain?.end) +
      "|" +
      getFormFieldPropsMemoizeKey(props)
    )
  },
)
