import * as lodash from "lodash"

export const getToleranceDataDomain = lodash.memoize(
  (
    items: {
      activity: number[]
      bottom_tolerance: number[]
      top_tolerance: number[]
    }[],
  ): [number, number] => {
    if (!items.length) {
      return [0, 400] as [number, number]
    }
    const min = items.reduce(
      (m, item) =>
        lodash.min([
          m,
          lodash.min(item.activity),
          lodash.min(item.top_tolerance),
          lodash.min(item.bottom_tolerance),
        ]) as number,
      Infinity,
    )
    const max = items.reduce(
      (m, item) =>
        lodash.max([
          m,
          lodash.max(item.activity),
          lodash.max(item.top_tolerance),
          lodash.max(item.bottom_tolerance),
        ]) as number,
      -Infinity,
    )
    return [min, max]
  },
)

export const getActivityDomain = lodash.memoize((items: { activity: number[] }[]): [number, number] => {
  if (!items.length) {
    return [0, 400] as [number, number]
  }
  const min = items.reduce((m, item) => lodash.min([m, lodash.min(item.activity)]) as number, Infinity)
  const max = items.reduce((m, item) => lodash.max([m, lodash.max(item.activity)]) as number, -Infinity)
  return [min, max]
})

export function smoothDomain(
  domain: [number, number],
  marginPercent: number,
  roundToNearest: number,
): [number, number] {
  return [
    Math.floor((domain[0] - marginPercent) / roundToNearest) * roundToNearest,
    Math.ceil((domain[1] + marginPercent) / roundToNearest) * roundToNearest,
  ] as [number, number]
}

export function avgPerHour(activity: number[]): [number, number][] {
  const interval = (1000 * 60 * 60 * 24) / activity.length
  const res: [number, number][] = []

  let currentHour = 0
  let currentSum = 0
  let currentCount = 0
  for (let i = 0; i < activity.length; i++) {
    const curr = i * interval
    const h = Math.floor(curr / (1000 * 60 * 60))
    // same hour, push values
    if (currentHour === h) {
      currentSum += activity[i]
      currentCount++
    } else {
      res.push([currentHour, currentSum / currentCount])
      currentHour = h
      currentCount = 1
      currentSum = activity[i]
    }
  }
  // push final value
  res.push([currentHour, currentSum / currentCount])

  return res
}
