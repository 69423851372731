/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  GetManySourceDtoResponseDto,
  GetManySourceDtoResponseDtoFromJSON,
  GetManySourceDtoResponseDtoToJSON,
  GetManySourceImportHistoryResponseDto,
  GetManySourceImportHistoryResponseDtoFromJSON,
  GetManySourceImportHistoryResponseDtoToJSON,
  SourceDateDomainDto,
  SourceDateDomainDtoFromJSON,
  SourceDateDomainDtoToJSON,
  SourceDateDomainListDto,
  SourceDateDomainListDtoFromJSON,
  SourceDateDomainListDtoToJSON,
  SourceDto,
  SourceDtoFromJSON,
  SourceDtoToJSON,
  SourceImportHistory,
  SourceImportHistoryFromJSON,
  SourceImportHistoryToJSON,
  SourcePatchDto,
  SourcePatchDtoFromJSON,
  SourcePatchDtoToJSON,
  SourcePatchGroupDto,
  SourcePatchGroupDtoFromJSON,
  SourcePatchGroupDtoToJSON,
  SourceStatsDto,
  SourceStatsDtoFromJSON,
  SourceStatsDtoToJSON,
  SourceStatsListDto,
  SourceStatsListDtoFromJSON,
  SourceStatsListDtoToJSON,
  SourceYearWithDataListDto,
  SourceYearWithDataListDtoFromJSON,
  SourceYearWithDataListDtoToJSON,
} from "../models"

export interface GetManyBaseSourceControllerSourceDtoRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetManyBaseSourceImportHistoryControllerSourceImportHistoryRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetManyBaseSourcePublicControllerSourceDtoRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseSourceControllerSourceDtoRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface GetOneBaseSourceImportHistoryControllerSourceImportHistoryRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface GetOneBaseSourcePublicControllerSourceDtoRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface SourceControllerDeleteSourceRequest {
  sourceId: number
}

export interface SourceControllerSourceDateDomainRequest {
  sourceId: number
}

export interface SourceControllerSourceGroupUpdateRequest {
  sourceId: number
  sourcePatchGroupDto: SourcePatchGroupDto
}

export interface SourceControllerSourceStatsRequest {
  sourceId: number
}

export interface SourceControllerSourceUpdateRequest {
  sourceId: number
  sourcePatchDto: SourcePatchDto
}

export interface SourceControllerSourceYearsWithDataRequest {
  sourceId: number
}

/**
 * no description
 */
export class SourceApi extends runtime.BaseAPI {
  /**
   * Retrieve many SourceDto
   */
  async getManyBaseSourceControllerSourceDtoRaw(
    requestParameters: GetManyBaseSourceControllerSourceDtoRequest,
  ): Promise<runtime.ApiResponse<GetManySourceDtoResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManySourceDtoResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many SourceDto
   */
  async getManyBaseSourceControllerSourceDto(
    requestParameters: GetManyBaseSourceControllerSourceDtoRequest,
  ): Promise<GetManySourceDtoResponseDto> {
    const response = await this.getManyBaseSourceControllerSourceDtoRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve many SourceImportHistory
   */
  async getManyBaseSourceImportHistoryControllerSourceImportHistoryRaw(
    requestParameters: GetManyBaseSourceImportHistoryControllerSourceImportHistoryRequest,
  ): Promise<runtime.ApiResponse<GetManySourceImportHistoryResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-import-history`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetManySourceImportHistoryResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Retrieve many SourceImportHistory
   */
  async getManyBaseSourceImportHistoryControllerSourceImportHistory(
    requestParameters: GetManyBaseSourceImportHistoryControllerSourceImportHistoryRequest,
  ): Promise<GetManySourceImportHistoryResponseDto> {
    const response = await this.getManyBaseSourceImportHistoryControllerSourceImportHistoryRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve many SourceDto
   */
  async getManyBaseSourcePublicControllerSourceDtoRaw(
    requestParameters: GetManyBaseSourcePublicControllerSourceDtoRequest,
  ): Promise<runtime.ApiResponse<GetManySourceDtoResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-public`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManySourceDtoResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many SourceDto
   */
  async getManyBaseSourcePublicControllerSourceDto(
    requestParameters: GetManyBaseSourcePublicControllerSourceDtoRequest,
  ): Promise<GetManySourceDtoResponseDto> {
    const response = await this.getManyBaseSourcePublicControllerSourceDtoRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one SourceDto
   */
  async getOneBaseSourceControllerSourceDtoRaw(
    requestParameters: GetOneBaseSourceControllerSourceDtoRequest,
  ): Promise<runtime.ApiResponse<SourceDto>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseSourceControllerSourceDto.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve one SourceDto
   */
  async getOneBaseSourceControllerSourceDto(
    requestParameters: GetOneBaseSourceControllerSourceDtoRequest,
  ): Promise<SourceDto> {
    const response = await this.getOneBaseSourceControllerSourceDtoRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one SourceImportHistory
   */
  async getOneBaseSourceImportHistoryControllerSourceImportHistoryRaw(
    requestParameters: GetOneBaseSourceImportHistoryControllerSourceImportHistoryRequest,
  ): Promise<runtime.ApiResponse<SourceImportHistory>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseSourceImportHistoryControllerSourceImportHistory.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-import-history/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceImportHistoryFromJSON(jsonValue))
  }

  /**
   * Retrieve one SourceImportHistory
   */
  async getOneBaseSourceImportHistoryControllerSourceImportHistory(
    requestParameters: GetOneBaseSourceImportHistoryControllerSourceImportHistoryRequest,
  ): Promise<SourceImportHistory> {
    const response = await this.getOneBaseSourceImportHistoryControllerSourceImportHistoryRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one SourceDto
   */
  async getOneBaseSourcePublicControllerSourceDtoRaw(
    requestParameters: GetOneBaseSourcePublicControllerSourceDtoRequest,
  ): Promise<runtime.ApiResponse<SourceDto>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseSourcePublicControllerSourceDto.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source-public/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve one SourceDto
   */
  async getOneBaseSourcePublicControllerSourceDto(
    requestParameters: GetOneBaseSourcePublicControllerSourceDtoRequest,
  ): Promise<SourceDto> {
    const response = await this.getOneBaseSourcePublicControllerSourceDtoRaw(requestParameters)
    return await response.value()
  }

  /**
   * Delete a source and all linked data
   */
  async sourceControllerDeleteSourceRaw(
    requestParameters: SourceControllerDeleteSourceRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling sourceControllerDeleteSource.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source/{sourceId}`.replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Delete a source and all linked data
   */
  async sourceControllerDeleteSource(requestParameters: SourceControllerDeleteSourceRequest): Promise<void> {
    await this.sourceControllerDeleteSourceRaw(requestParameters)
  }

  /**
   * Get source stats
   */
  async sourceControllerSourceAllStatsRaw(): Promise<runtime.ApiResponse<SourceStatsListDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source/stats`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceStatsListDtoFromJSON(jsonValue))
  }

  /**
   * Get source stats
   */
  async sourceControllerSourceAllStats(): Promise<SourceStatsListDto> {
    const response = await this.sourceControllerSourceAllStatsRaw()
    return await response.value()
  }

  /**
   * Get one source date domain
   */
  async sourceControllerSourceDateDomainRaw(
    requestParameters: SourceControllerSourceDateDomainRequest,
  ): Promise<runtime.ApiResponse<SourceDateDomainDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling sourceControllerSourceDateDomain.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source/date-domain/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceDateDomainDtoFromJSON(jsonValue))
  }

  /**
   * Get one source date domain
   */
  async sourceControllerSourceDateDomain(
    requestParameters: SourceControllerSourceDateDomainRequest,
  ): Promise<SourceDateDomainDto> {
    const response = await this.sourceControllerSourceDateDomainRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update a source group
   */
  async sourceControllerSourceGroupUpdateRaw(
    requestParameters: SourceControllerSourceGroupUpdateRequest,
  ): Promise<runtime.ApiResponse<SourceDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling sourceControllerSourceGroupUpdate.",
      )
    }

    if (requestParameters.sourcePatchGroupDto === null || requestParameters.sourcePatchGroupDto === undefined) {
      throw new runtime.RequiredError(
        "sourcePatchGroupDto",
        "Required parameter requestParameters.sourcePatchGroupDto was null or undefined when calling sourceControllerSourceGroupUpdate.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source/{sourceId}/group`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: SourcePatchGroupDtoToJSON(requestParameters.sourcePatchGroupDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceDtoFromJSON(jsonValue))
  }

  /**
   * Update a source group
   */
  async sourceControllerSourceGroupUpdate(
    requestParameters: SourceControllerSourceGroupUpdateRequest,
  ): Promise<SourceDto> {
    const response = await this.sourceControllerSourceGroupUpdateRaw(requestParameters)
    return await response.value()
  }

  /**
   * Get all sources date domains
   */
  async sourceControllerSourceListDomainsRaw(): Promise<runtime.ApiResponse<SourceDateDomainListDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source/date-domain`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceDateDomainListDtoFromJSON(jsonValue))
  }

  /**
   * Get all sources date domains
   */
  async sourceControllerSourceListDomains(): Promise<SourceDateDomainListDto> {
    const response = await this.sourceControllerSourceListDomainsRaw()
    return await response.value()
  }

  /**
   * Get one source stats
   */
  async sourceControllerSourceStatsRaw(
    requestParameters: SourceControllerSourceStatsRequest,
  ): Promise<runtime.ApiResponse<SourceStatsDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling sourceControllerSourceStats.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source/stats/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceStatsDtoFromJSON(jsonValue))
  }

  /**
   * Get one source stats
   */
  async sourceControllerSourceStats(requestParameters: SourceControllerSourceStatsRequest): Promise<SourceStatsDto> {
    const response = await this.sourceControllerSourceStatsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update a source
   */
  async sourceControllerSourceUpdateRaw(
    requestParameters: SourceControllerSourceUpdateRequest,
  ): Promise<runtime.ApiResponse<SourceDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling sourceControllerSourceUpdate.",
      )
    }

    if (requestParameters.sourcePatchDto === null || requestParameters.sourcePatchDto === undefined) {
      throw new runtime.RequiredError(
        "sourcePatchDto",
        "Required parameter requestParameters.sourcePatchDto was null or undefined when calling sourceControllerSourceUpdate.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source/{sourceId}`.replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: SourcePatchDtoToJSON(requestParameters.sourcePatchDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceDtoFromJSON(jsonValue))
  }

  /**
   * Update a source
   */
  async sourceControllerSourceUpdate(requestParameters: SourceControllerSourceUpdateRequest): Promise<SourceDto> {
    const response = await this.sourceControllerSourceUpdateRaw(requestParameters)
    return await response.value()
  }

  /**
   * Find which for years this source have data
   */
  async sourceControllerSourceYearsWithDataRaw(
    requestParameters: SourceControllerSourceYearsWithDataRequest,
  ): Promise<runtime.ApiResponse<SourceYearWithDataListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling sourceControllerSourceYearsWithData.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/source/{sourceId}/years-with-data`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SourceYearWithDataListDtoFromJSON(jsonValue))
  }

  /**
   * Find which for years this source have data
   */
  async sourceControllerSourceYearsWithData(
    requestParameters: SourceControllerSourceYearsWithDataRequest,
  ): Promise<SourceYearWithDataListDto> {
    const response = await this.sourceControllerSourceYearsWithDataRaw(requestParameters)
    return await response.value()
  }
}
