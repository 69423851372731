import React from "react"

import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsBoost from "highcharts/modules/boost"
import highchartsBoostCanvas from "highcharts/modules/boost-canvas"
import highchartsHeatmap from "highcharts/modules/heatmap"
import { toDateObj, formatDate } from "../lib/date"
import { avgPerHour, getActivityDomain, smoothDomain } from "../lib/graph"
import { formatNumber } from "../lib/number"
highchartsHeatmap(Highcharts)
highchartsBoost(Highcharts)
highchartsBoostCanvas(Highcharts)

export default React.memo(function ActivityHeatmap({
  days,
  label,
  year,
}: {
  days: { date: string; activity: number[] }[]
  label: string
  year: number
}) {
  const valueDomain = smoothDomain(getActivityDomain(days), 0, 10)

  const options: Highcharts.Options = {
    time: {
      timezone: "Europe/Paris",
    },
    chart: {
      type: "heatmap",
      margin: [60, 10, 80, 50],
    },

    boost: {
      useGPUTranslations: true,
    },

    title: {
      text: "Activité complète",
      align: "left",
      x: 40,
    },

    subtitle: {
      text: `Détail de l'activité par heure pour l'année ${year}`,
      align: "left",
      x: 40,
    },

    xAxis: {
      type: "datetime",
      min: Date.UTC(year, 0, 1),
      max: Date.UTC(year, 11, 31, 23, 59, 59),
      labels: {
        align: "left",
        x: 5,
        y: 14,
        format: "{value:%B}", // long month
      },
      showLastLabel: false,
      tickLength: 16,
    },

    yAxis: {
      title: {
        text: null,
      },
      labels: {
        format: "{value}:00",
      },
      minPadding: 0,
      maxPadding: 0,
      startOnTick: false,
      endOnTick: false,
      tickPositions: [0, 6, 12, 18, 24],
      tickWidth: 1,
      min: 0,
      max: 23,
      reversed: true,
    },

    colorAxis: {
      stops: [
        [0, "#3060cf"],
        [0.4, "#fffbbc"],
        [0.8, "#c4463a"],
        [1, "#000000"],
      ],
      min: valueDomain[0],
      max: valueDomain[1],
      startOnTick: false,
      endOnTick: false,
      labels: {
        format: "{value}",
      },
    },

    series: [
      {
        data: days.map((d) => avgPerHour(d.activity).map((v) => [toDateObj(d.date).valueOf(), ...v])).flat(1),
        type: "heatmap",
        boostThreshold: 100,
        borderWidth: 0,
        nullColor: "#EFEFEF",
        colsize: 24 * 36e5, // one day
        tooltip: {
          headerFormat: label + "<br/>",
          pointFormatter() {
            return formatDate(new Date(this.x)) + " : " + formatNumber(this.value || 0)
          },
        },
        turboThreshold: Number.MAX_VALUE, // #3404, remove after 4.0.5 release
      } as Highcharts.SeriesHeatmapOptions,
    ],
  }
  return <HighchartsReact highcharts={Highcharts} options={options} />
})
