import React from "react"

import { Tooltip } from "@material-ui/core"
import UnderIcon from "@material-ui/icons/VerticalAlignBottom"
import EqualIcon from "@material-ui/icons/VerticalAlignCenter"
import OverIcon from "@material-ui/icons/VerticalAlignTop"
import { AlertActivityEnum } from "../api/timelight-api/models/AlertActivityEnum"

export default function AlertActivity({
  alert,
  large = false,
}: {
  alert: { activity?: AlertActivityEnum | null }
  large?: boolean
}) {
  return (
    <div
      style={{
        color: alert.activity ? "#e05757" : "#ccc",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: alert.activity ? "#e05757" : "#ccc",
          fontSize: "30px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {alert.activity === AlertActivityEnum.Over && (
          <Tooltip title="Sur-activité détectée">
            <OverIcon color="inherit" fontSize="inherit" />
          </Tooltip>
        )}
        {alert.activity === AlertActivityEnum.Under && (
          <Tooltip title="Sous-activité détectée">
            <UnderIcon color="inherit" fontSize="inherit" />
          </Tooltip>
        )}
        {!alert.activity && (
          <Tooltip title="Pas de sous-activité, pas de sur-activité">
            <EqualIcon color="inherit" fontSize="inherit" />
          </Tooltip>
        )}
      </div>
      {large && (
        <div style={{ marginLeft: 5 }}>
          {alert.activity === AlertActivityEnum.Over && "Sur-activité détectée"}
          {alert.activity === AlertActivityEnum.Under && "Sous-activité détectée"}
          {!alert.activity && "Pas de sous-activité, pas de sur-activité"}
        </div>
      )}
    </div>
  )
}
