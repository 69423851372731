import React, { useState } from "react"
import { AnyChildren } from "../../react-type-helpers"
import * as lodash from "lodash"

export interface SerieChartContextState {
  registerChartObj: (chartObj: Highcharts.Chart) => void
  onZoomChanged: (zoom: { begin: Date; end: Date } | null, chart: Highcharts.Chart) => void
  setChartsZoom: (zoom: { begin: Date; end: Date } | null) => void
}

const defaultValue: SerieChartContextState = {
  registerChartObj: () => {},
  onZoomChanged: () => {},
  setChartsZoom: () => {},
}
export const SerieChartContext = React.createContext<SerieChartContextState>(defaultValue)

export function SerieChartSyncZoomProvider({ children }: { children: AnyChildren }) {
  const [chartObjs, setChartObjs] = useState<Highcharts.Chart[]>([])
  const registerChartObj = React.useCallback(
    (chartObj: Highcharts.Chart) => {
      setChartObjs(lodash.uniq([...chartObjs, chartObj]))
    },
    [chartObjs, setChartObjs],
  )
  const onZoomChanged = React.useCallback(
    (zoom: { begin: Date; end: Date } | null, currentChart: Highcharts.Chart) => {
      if (!zoom) return
      for (const chartObj of chartObjs) {
        // same chart, do nothing
        if (chartObj === currentChart) {
          continue
        }
        // axis not defined, do nothing
        if (!chartObj.xAxis) {
          continue
        }
        // same extremes with 5% tolerance, do nothing
        const chartExtr = chartObj.xAxis[0].getExtremes()
        const currentChartExtr = currentChart.xAxis[0].getExtremes()
        const minDiff = Math.abs(chartExtr.min - currentChartExtr.min)
        const maxDiff = Math.abs(chartExtr.max - currentChartExtr.max)
        const tolerance = (currentChartExtr.max - currentChartExtr.min) * 0.05
        if (minDiff < tolerance && maxDiff < tolerance) {
          continue
        }

        // chart do not have matching data range, do nothing
        if (chartExtr.dataMax < currentChartExtr.dataMin || currentChartExtr.dataMax < chartExtr.dataMin) {
          continue
        }

        // selected zoom is outside chart data, do nothing
        if (chartExtr.dataMax < zoom.begin.getTime() || zoom.end.getTime() < chartExtr.dataMin) {
          continue
        }

        // Otherwise, set chart extremes
        chartObj.xAxis[0].setExtremes(zoom.begin.getTime(), zoom.end.getTime())
      }
    },
    [chartObjs],
  )

  const setChartsZoom = React.useCallback(
    (zoom: { begin: Date; end: Date } | null) => {
      if (!zoom) return
      for (const chartObj of chartObjs) {
        // axis not defined, do nothing
        if (!chartObj.xAxis) {
          continue
        }

        // selected zoom is outside chart data, do nothing
        const chartExtr = chartObj.xAxis[0].getExtremes()
        if (chartExtr.dataMax < zoom.begin.getTime() || zoom.end.getTime() < chartExtr.dataMin) {
          continue
        }

        // Otherwise, set chart extremes
        chartObj.xAxis[0].setExtremes(zoom.begin.getTime(), zoom.end.getTime())
      }
    },
    [chartObjs],
  )

  // and the provider provode... provided... provide... providera...
  // and the message is properly set
  return (
    <SerieChartContext.Provider
      value={{
        registerChartObj,
        onZoomChanged,
        setChartsZoom,
      }}
    >
      {children}
    </SerieChartContext.Provider>
  )
}
