/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DayTrendTypeEnum {
  OneShot = "one-shot",
  Weekly = "weekly",
  Monthly = "monthly",
}

export function DayTrendTypeEnumFromJSON(json: any): DayTrendTypeEnum {
  return DayTrendTypeEnumFromJSONTyped(json, false)
}

export function DayTrendTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayTrendTypeEnum {
  return json as DayTrendTypeEnum
}

export function DayTrendTypeEnumToJSON(value?: DayTrendTypeEnum | null): any {
  return value as any
}
