/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SerieImportResult
 */
export interface SerieImportResult {
  /**
   * Minumum imported input date
   * @type {Date}
   * @memberof SerieImportResult
   */
  minInputDate: Date
  /**
   * Maximum imported input date
   * @type {Date}
   * @memberof SerieImportResult
   */
  maxInputDate: Date
  /**
   * Total count of inserted or updated blocks
   * @type {number}
   * @memberof SerieImportResult
   */
  totalBlocks: number
  /**
   * Total count of new blocks
   * @type {number}
   * @memberof SerieImportResult
   */
  insertedBlocks: number
  /**
   * Total count of updated blocks
   * @type {number}
   * @memberof SerieImportResult
   */
  updatedBlocks: number
}

export function SerieImportResultFromJSON(json: any): SerieImportResult {
  return SerieImportResultFromJSONTyped(json, false)
}

export function SerieImportResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerieImportResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    minInputDate: new Date(json["minInputDate"]),
    maxInputDate: new Date(json["maxInputDate"]),
    totalBlocks: json["totalBlocks"],
    insertedBlocks: json["insertedBlocks"],
    updatedBlocks: json["updatedBlocks"],
  }
}

export function SerieImportResultToJSON(value?: SerieImportResult | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    minInputDate: value.minInputDate.toISOString(),
    maxInputDate: value.maxInputDate.toISOString(),
    totalBlocks: value.totalBlocks,
    insertedBlocks: value.insertedBlocks,
    updatedBlocks: value.updatedBlocks,
  }
}
