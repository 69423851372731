import React from "react"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AppButton } from "./AppButton"

export const ConfirmDialogButton = React.memo(function ConfirmDialogButtonComponent({
  disabled = false,
  isSubmitting,
  onSubmit,
}: {
  disabled?: boolean
  isSubmitting: boolean
  onSubmit: () => void
}) {
  const { t } = useTranslation()
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false)

  return (
    <>
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Lancer l'analyse {t("global.appTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Cette opération peut prendre jusqu'à 5 minutes. Contactez-nous pour programmer son exécution
            quotidiennement.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton onClick={() => setConfirmDialogOpen(false)} color="primary" disabled={isSubmitting || disabled}>
            Annuler
          </AppButton>
          <AppButton
            onClick={() => {
              onSubmit()
              setConfirmDialogOpen(false)
            }}
            disabled={isSubmitting || disabled}
            submitting={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
          >
            Lancer l'analyse {t("global.appTitle")}
          </AppButton>
        </DialogActions>
      </Dialog>
      <AppButton
        onClick={() => setConfirmDialogOpen(true)}
        disabled={isSubmitting || disabled}
        submitting={isSubmitting}
        variant="contained"
        color="primary"
      >
        Lancer l'analyse {t("global.appTitle")}
      </AppButton>
    </>
  )
})
