import TickIcon from "@material-ui/icons/Done"
import React from "react"
import { useState } from "react"
import { SourceDto, SourceGroupDto } from "api/timelight-api"
import { createIdMap } from "../lib/array"
import { useApiClient } from "../state/api"
import { useApiExceptionHandler } from "../state/auth"
import ListSelect from "./ListSelect"
import { AppButton } from "./AppButton"

export default function GroupAssignAction({
  groups,
  source,
  onSourceUpdated,
}: {
  groups: SourceGroupDto[]
  source: SourceDto
  onSourceUpdated: (source: SourceDto) => void
}) {
  // create a faster group index
  const groupsById = createIdMap(groups)

  // the selected group
  const [selectedGroup, setSelectedGroup] = useState<SourceGroupDto | null>(
    source.groupId ? groupsById[source.groupId] : null,
  )

  // anomaly api call
  const api = useApiClient()
  const handleApiException = useApiExceptionHandler()
  const [submitting, setSubmitting] = useState<boolean>(false)
  async function updateSourceGroup() {
    setSubmitting(true)
    try {
      const newSource = await api.sourceControllerSourceGroupUpdate({
        sourceId: source.id,
        sourcePatchGroupDto: { groupId: selectedGroup ? selectedGroup.id : null },
      })
      // update local data
      onSourceUpdated(newSource)
    } catch (e) {
      handleApiException(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <ListSelect
        disabled={submitting}
        placeholder="Groupe"
        menuPlacement="top"
        value={{
          label: selectedGroup ? groupsById[selectedGroup.id].name : "Aucun groupe",
          value: selectedGroup ? selectedGroup.id : 0,
        }}
        items={groups
          .map((g) => ({
            label: g.name,
            value: g.id,
          }))
          .concat([
            {
              label: "Aucun groupe",
              value: 0,
            },
          ])}
        getLabel={(o) => o.label}
        getValue={(o) => o.value}
        onSelect={(o) => setSelectedGroup(o.value === 0 ? null : groupsById[o.value])}
      />

      <AppButton
        onClick={() => updateSourceGroup()}
        style={{
          minWidth: 200,
          marginLeft: 10,
          marginRight: 10,
        }}
        color="primary"
        variant="contained"
        submitting={submitting}
      >
        <TickIcon style={{ marginRight: 5 }} />
        Affecter ce capteur
      </AppButton>
    </div>
  )
}
