/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  SourceClusterDistance,
  SourceClusterDistanceFromJSON,
  SourceClusterDistanceFromJSONTyped,
  SourceClusterDistanceToJSON,
} from "./"

/**
 *
 * @export
 * @interface SourceClusterTaskInputParams
 */
export interface SourceClusterTaskInputParams {
  /**
   *
   * @type {string}
   * @memberof SourceClusterTaskInputParams
   */
  title: string
  /**
   *
   * @type {Array<number>}
   * @memberof SourceClusterTaskInputParams
   */
  sourceIds: Array<number>
  /**
   *
   * @type {SourceClusterDistance}
   * @memberof SourceClusterTaskInputParams
   */
  distance: SourceClusterDistance
  /**
   * Number of Clusters
   * @type {number}
   * @memberof SourceClusterTaskInputParams
   */
  modelCount: number | null
}

export function SourceClusterTaskInputParamsFromJSON(json: any): SourceClusterTaskInputParams {
  return SourceClusterTaskInputParamsFromJSONTyped(json, false)
}

export function SourceClusterTaskInputParamsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SourceClusterTaskInputParams {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json["title"],
    sourceIds: json["sourceIds"],
    distance: SourceClusterDistanceFromJSON(json["distance"]),
    modelCount: json["modelCount"],
  }
}

export function SourceClusterTaskInputParamsToJSON(value?: SourceClusterTaskInputParams | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    sourceIds: value.sourceIds,
    distance: SourceClusterDistanceToJSON(value.distance),
    modelCount: value.modelCount,
  }
}
