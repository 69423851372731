import AppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import MenuIcon from "@material-ui/icons/Menu"
import clsx from "clsx"
import React from "react"
import { AnyChildren } from "../react-type-helpers"
import { useAuth } from "../state/auth"
import { AppRoute } from "../state/route"
import { useSideMenuToggle } from "../state/side-menu"
import AppLink from "./AppLink"
import HeaderUser from "./HeaderUser"
import Logo from "./Logo"
import { drawerWidth } from "./SideMenu"
import { useLastStartedTask, buildTaskUrl } from "../state/tasksStream"
import { useHistory } from "react-router"
import TaskStatusActivity from "./TaskStatusActivity"
import { AppButton } from "./AppButton"
import { useOnboardingState } from "../state/onboarding"

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  headContent: {
    flexGrow: 1,
  },
}))

export default function AppHeader({
  color = true,
  children = <></>,
  openable: baseOpenable = true,
  logo: baseLogo = false,
}: {
  color?: boolean
  children?: AnyChildren
  openable?: boolean
  logo?: boolean
}) {
  const classes = useStyles()
  const history = useHistory()
  const { isAuthenticated } = useAuth()
  const { open, doOpen, doClose } = useSideMenuToggle()
  const lastStartedTask = useLastStartedTask()

  // force some configuration when on the onboarding state
  const { hasSource } = useOnboardingState()
  const openable = hasSource === null || hasSource === false ? false : baseOpenable
  const logo = hasSource === null || hasSource === false ? true : baseLogo

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, openable && open && classes.appBarShift)}
      color={color ? "primary" : "default"}
    >
      <Toolbar className={classes.toolbar}>
        {openable && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => (open ? doClose() : doOpen())}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
        )}
        {logo && (
          <AppLink route={AppRoute.HOME}>
            <Logo version={color && !openable ? "white" : "black"} />
          </AppLink>
        )}
        <div className={classes.headContent}>{children}</div>
        {isAuthenticated && (
          <>
            {lastStartedTask && (
              <AppButton
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                variant="text"
                color="inherit"
                onClick={() => history.push(buildTaskUrl(lastStartedTask))}
              >
                <TaskStatusActivity color="inverted" task={lastStartedTask} />
                <div style={{ marginLeft: "1em" }}>{lastStartedTask.title}</div>
              </AppButton>
            )}
            <HeaderUser />
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}
