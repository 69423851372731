/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  ContextSourceImpactSummary,
  ContextSourceImpactSummaryFromJSON,
  ContextSourceImpactSummaryFromJSONTyped,
  ContextSourceImpactSummaryToJSON,
  ContextTypeImpactSummary,
  ContextTypeImpactSummaryFromJSON,
  ContextTypeImpactSummaryFromJSONTyped,
  ContextTypeImpactSummaryToJSON,
} from "./"

/**
 *
 * @export
 * @interface ContextImpactSummaryDto
 */
export interface ContextImpactSummaryDto {
  /**
   *
   * @type {Array<ContextTypeImpactSummary>}
   * @memberof ContextImpactSummaryDto
   */
  contextTypeImpactSummaries: Array<ContextTypeImpactSummary>
  /**
   *
   * @type {Array<ContextSourceImpactSummary>}
   * @memberof ContextImpactSummaryDto
   */
  contextSourceImpactSummaries: Array<ContextSourceImpactSummary>
  /**
   *
   * @type {Array<number>}
   * @memberof ContextImpactSummaryDto
   */
  contextTypeImpactSerieIds: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof ContextImpactSummaryDto
   */
  contextSourceImpactSerieIds: Array<number>
}

export function ContextImpactSummaryDtoFromJSON(json: any): ContextImpactSummaryDto {
  return ContextImpactSummaryDtoFromJSONTyped(json, false)
}

export function ContextImpactSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContextImpactSummaryDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contextTypeImpactSummaries: (json["contextTypeImpactSummaries"] as Array<any>).map(
      ContextTypeImpactSummaryFromJSON,
    ),
    contextSourceImpactSummaries: (json["contextSourceImpactSummaries"] as Array<any>).map(
      ContextSourceImpactSummaryFromJSON,
    ),
    contextTypeImpactSerieIds: json["contextTypeImpactSerieIds"],
    contextSourceImpactSerieIds: json["contextSourceImpactSerieIds"],
  }
}

export function ContextImpactSummaryDtoToJSON(value?: ContextImpactSummaryDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contextTypeImpactSummaries: (value.contextTypeImpactSummaries as Array<any>).map(ContextTypeImpactSummaryToJSON),
    contextSourceImpactSummaries: (value.contextSourceImpactSummaries as Array<any>).map(
      ContextSourceImpactSummaryToJSON,
    ),
    contextTypeImpactSerieIds: value.contextTypeImpactSerieIds,
    contextSourceImpactSerieIds: value.contextSourceImpactSerieIds,
  }
}
