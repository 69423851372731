import { orange, red } from "@material-ui/core/colors"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { AnyChildren } from "../react-type-helpers"

export default function Title({
  children,
  margins = true,
  alert,
  warning,
  icon,
}: {
  icon?: AnyChildren
  alert?: boolean
  warning?: boolean
  margins?: boolean
  children: AnyChildren
}) {
  return (
    <div style={{ color: alert ? red[500] : warning ? orange[500] : undefined }}>
      <Typography
        style={{ fontWeight: "bold", marginBottom: margins ? undefined : 0 }}
        component="h2"
        variant="h6"
        color={alert || warning ? "inherit" : "primary"}
        gutterBottom
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          {icon}
          <span>{children}</span>
        </span>
      </Typography>
    </div>
  )
}
