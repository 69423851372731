/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { AlertDto, AlertDtoFromJSON, AlertDtoFromJSONTyped, AlertDtoToJSON } from "./"

/**
 *
 * @export
 * @interface AlertListDto
 */
export interface AlertListDto {
  /**
   * List of alerts
   * @type {Array<AlertDto>}
   * @memberof AlertListDto
   */
  alerts: Array<AlertDto>
}

export function AlertListDtoFromJSON(json: any): AlertListDto {
  return AlertListDtoFromJSONTyped(json, false)
}

export function AlertListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    alerts: (json["alerts"] as Array<any>).map(AlertDtoFromJSON),
  }
}

export function AlertListDtoToJSON(value?: AlertListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    alerts: (value.alerts as Array<any>).map(AlertDtoToJSON),
  }
}
