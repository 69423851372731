import React from "react"
import AppProviders from "./component/AppProviders"
import AppRouter from "./component/AppRouter"

export default function App() {
  return (
    <AppProviders>
      <AppRouter />
    </AppProviders>
  )
}
