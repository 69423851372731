/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  SourceDateDomainDto,
  SourceDateDomainDtoFromJSON,
  SourceDateDomainDtoFromJSONTyped,
  SourceDateDomainDtoToJSON,
} from "./"

/**
 *
 * @export
 * @interface SourceDateDomainListDto
 */
export interface SourceDateDomainListDto {
  /**
   *
   * @type {Array<SourceDateDomainDto>}
   * @memberof SourceDateDomainListDto
   */
  domains: Array<SourceDateDomainDto>
}

export function SourceDateDomainListDtoFromJSON(json: any): SourceDateDomainListDto {
  return SourceDateDomainListDtoFromJSONTyped(json, false)
}

export function SourceDateDomainListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceDateDomainListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    domains: (json["domains"] as Array<any>).map(SourceDateDomainDtoFromJSON),
  }
}

export function SourceDateDomainListDtoToJSON(value?: SourceDateDomainListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    domains: (value.domains as Array<any>).map(SourceDateDomainDtoToJSON),
  }
}
