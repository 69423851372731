/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayModelDto, DayModelDtoFromJSON, DayModelDtoFromJSONTyped, DayModelDtoToJSON } from "./"

/**
 *
 * @export
 * @interface RecomputeDayModelsResponseDto
 */
export interface RecomputeDayModelsResponseDto {
  /**
   * The updated day models
   * @type {Array<DayModelDto>}
   * @memberof RecomputeDayModelsResponseDto
   */
  days: Array<DayModelDto>
}

export function RecomputeDayModelsResponseDtoFromJSON(json: any): RecomputeDayModelsResponseDto {
  return RecomputeDayModelsResponseDtoFromJSONTyped(json, false)
}

export function RecomputeDayModelsResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RecomputeDayModelsResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    days: (json["days"] as Array<any>).map(DayModelDtoFromJSON),
  }
}

export function RecomputeDayModelsResponseDtoToJSON(value?: RecomputeDayModelsResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    days: (value.days as Array<any>).map(DayModelDtoToJSON),
  }
}
