import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import AlertIcon from "@material-ui/icons/Warning"
import { Formik } from "formik"
import React from "react"
import { AlertDto, ModelDto } from "api/timelight-api"
import { createIdMap } from "../../lib/array"
import { formatNumber } from "../../lib/number"
import { useApiClient } from "../../state/api"
import { useApiExceptionHandler } from "../../state/auth"
import AlertActivity from "../AlertActivity"
import AlertAnomaly from "../AlertAnomaly"
import AlertBetterModel from "../AlertBetterModel"
import AlertShape from "../AlertShape"
import { useSuccessMessage } from "../SuccessMessage"
import Title from "../Title"
import { AppButton } from "../AppButton"

const useStyles = makeStyles({
  diagnosticContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
})

export function AlertForm({ alert, models }: { alert: AlertDto; models: ModelDto[] }) {
  const classes = useStyles()
  const modelsById = createIdMap(models)

  // alert update api call
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Alerte traitée",
  })
  const api = useApiClient()
  const handleApiException = useApiExceptionHandler()
  async function updateAlert({ description }: { description: string }) {
    try {
      await api.alertControllerAlertComment({
        alertId: alert.id,
        alertCommentDto: {
          comment: description,
        },
      })
      // success is at the corner
      showSuccessMessage()

      // reload the page because i'm lazy
      setTimeout(() => window.location.reload(), 1000)
    } catch (e) {
      handleApiException(e)
    }
  }

  return (
    <div className={classes.diagnosticContainer}>
      <SuccessMessage />
      <Grid container>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Title icon={<AlertIcon />} alert={true}>
            Une alerte a été détectée
          </Title>
          <Grid container direction="column" style={{ marginLeft: "4em" }}>
            <p style={{ marginRight: 5 }}>Criticité: {formatNumber(alert.criticity)}</p>
            <AlertAnomaly alert={alert} large={true} />
            <AlertShape alert={alert} large={true} />
            <AlertActivity alert={alert} large={true} />
            <AlertBetterModel
              alert={alert}
              getModelName={(a) => (a.better_model_id ? modelsById[a.better_model_id].name : null)}
              getModelColor={(a) => (a.better_model_id ? modelsById[a.better_model_id].color : null)}
            />
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Title>Action utilisateur</Title>

          <Formik
            initialValues={{ description: "" }}
            onSubmit={async (values) => {
              try {
                await updateAlert(values)
              } catch (e) {
                // ts-lint:disable-next-line:no-console
                console.error(e)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  multiline
                  rows={5}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Description"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
                {errors.description && touched.description && errors.description}
                <AppButton type="submit" fullWidth variant="contained" color="primary" submitting={isSubmitting}>
                  Enregistrer et déclarer cette alerte comme traitée
                </AppButton>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  )
}
