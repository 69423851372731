import React from "react"
import { Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import YAxisLargeNumberTick from "../YAxisLargeNumberTick"
import { ToleranceChartItem } from "./ChartTooltip"
import ChartTooltip from "./ChartTooltip"
import YAxisContextTypeTick from "../YAxisContextTypeTick"
import * as lodash from "lodash"
import { DayContextTypes } from "../../api/timelight-api/models/DayContextTypes"

export default React.memo(function ToleranceChart({
  activityTitle,
  data,
  yDomain,
  contextType,
  heavyContext,
}: {
  contextType?: DayContextTypes
  activityTitle: string
  data: ToleranceChartItem[]
  yDomain?: [number, number]
  heavyContext?: boolean
}) {
  /* We define top and bottom as stacked charts to display a beautiful sleeve
     around the value data. But the stacked charts get added, we don't want 
     top data to be displayed at (bottom+top), so we remove "bottom" from 
     the "top" data to correct this behavior */
  const unstackedData = data.map((d) => ({ ...d, stackedTop: d.top - d.bottom }))

  const contextData = data ? data.map((d) => d.contextValue).filter((v) => v !== undefined) : []
  let contextDomain: [number, number] = contextType
    ? [lodash.min(contextData) || Infinity, lodash.max(contextData) || -Infinity]
    : [0, 0]
  const domainMargin = (contextDomain[1] - contextDomain[0]) * 0.1
  contextDomain = [contextDomain[0] - domainMargin, contextDomain[1] + domainMargin]

  return (
    <ResponsiveContainer>
      <ComposedChart width={730} height={250} data={unstackedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis dataKey="x" />
        <YAxis yAxisId="highlight" scale="linear" hide domain={[0, 1]} />
        <YAxis scale="linear" width={40} domain={yDomain} tick={(props) => <YAxisLargeNumberTick {...props} />} />
        {contextType && (
          <YAxis
            yAxisId="context"
            scale="linear"
            orientation="right"
            tick={(props) => <YAxisContextTypeTick {...props} contextType={contextType} />}
            domain={contextDomain}
          />
        )}
        <Tooltip
          content={(props: any) => <ChartTooltip {...props} activityTitle={activityTitle} contextType={contextType} />}
        />
        <Legend height={36} />
        <Area
          stackId="1"
          animationDuration={300}
          name="Tolérance basse"
          type="monotone"
          dataKey="bottom"
          dot={false}
          stroke="#7cb5ec"
          strokeOpacity={0.5 * (heavyContext ? 0.5 : 1)}
          strokeWidth={2 * (heavyContext ? 0.5 : 1)}
          fill="#7cb5ec"
          fillOpacity={0}
        />
        <Area
          stackId="1"
          animationDuration={300}
          name="Tolérance haute"
          type="monotone"
          dataKey="stackedTop"
          dot={false}
          stroke="#7cb5ec"
          strokeOpacity={0.5 * (heavyContext ? 0.5 : 1)}
          strokeWidth={2 * (heavyContext ? 0.5 : 1)}
          fill="#7cb5ec"
          fillOpacity={0.2 * (heavyContext ? 0.5 : 1)}
        />
        {contextType && (
          <Area
            yAxisId="highlight"
            type="step"
            dataKey="contextImpact"
            strokeWidth={0}
            dot={false}
            fill="#00FF00"
            fillOpacity={0.3}
            animationDuration={300}
          />
        )}
        <Line
          animationDuration={300}
          name={activityTitle}
          type="monotone"
          dataKey="activity"
          dot={false}
          stroke="#5e4fa2"
          strokeWidth={2 * (heavyContext ? 0.5 : 1)}
          strokeOpacity={1 * (heavyContext ? 0.5 : 1)}
        />
        {contextType && (
          <Line
            yAxisId="context"
            type="monotone"
            dataKey="contextValue"
            strokeWidth={heavyContext ? 2 : 1}
            dot={false}
            animationDuration={300}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
})
