/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  DayContextTypes,
  DayContextTypesFromJSON,
  DayContextTypesFromJSONTyped,
  DayContextTypesToJSON,
  ForecastType,
  ForecastTypeFromJSON,
  ForecastTypeFromJSONTyped,
  ForecastTypeToJSON,
  TaskStatus,
  TaskStatusFromJSON,
  TaskStatusFromJSONTyped,
  TaskStatusToJSON,
} from "./"

/**
 *
 * @export
 * @interface ForecastTask
 */
export interface ForecastTask {
  /**
   *
   * @type {number}
   * @memberof ForecastTask
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof ForecastTask
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof ForecastTask
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof ForecastTask
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof ForecastTask
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof ForecastTask
   */
  readonly companyId: number
  /**
   *
   * @type {string}
   * @memberof ForecastTask
   */
  taskType: string
  /**
   *
   * @type {TaskStatus}
   * @memberof ForecastTask
   */
  status: TaskStatus
  /**
   *
   * @type {Date}
   * @memberof ForecastTask
   */
  readonly startDate: Date | null
  /**
   *
   * @type {Date}
   * @memberof ForecastTask
   */
  readonly endDate: Date | null
  /**
   *
   * @type {number}
   * @memberof ForecastTask
   */
  readonly durationMs: number | null
  /**
   *
   * @type {string}
   * @memberof ForecastTask
   */
  error: string | null
  /**
   *
   * @type {number}
   * @memberof ForecastTask
   */
  sourceId: number
  /**
   *
   * @type {ForecastType}
   * @memberof ForecastTask
   */
  forecastType: ForecastType
  /**
   *
   * @type {string}
   * @memberof ForecastTask
   */
  title: string
  /**
   *
   * @type {Date}
   * @memberof ForecastTask
   */
  trainBegin: Date
  /**
   *
   * @type {Date}
   * @memberof ForecastTask
   */
  trainEnd: Date
  /**
   *
   * @type {Date}
   * @memberof ForecastTask
   */
  forecastBegin: Date
  /**
   *
   * @type {Date}
   * @memberof ForecastTask
   */
  forecastEnd: Date
  /**
   *
   * @type {Array<DayContextTypes>}
   * @memberof ForecastTask
   */
  forecastContextTypes: Array<DayContextTypes>
  /**
   *
   * @type {Array<number>}
   * @memberof ForecastTask
   */
  forecastContextSourceIds: Array<number>
  /**
   *
   * @type {object}
   * @memberof ForecastTask
   */
  forecastProperties: object | null
  /**
   *
   * @type {number}
   * @memberof ForecastTask
   */
  forecastSerieId: number | null
}

export function ForecastTaskFromJSON(json: any): ForecastTask {
  return ForecastTaskFromJSONTyped(json, false)
}

export function ForecastTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForecastTask {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    taskType: json["taskType"],
    status: TaskStatusFromJSON(json["status"]),
    startDate: json["startDate"] === null ? null : new Date(json["startDate"]),
    endDate: json["endDate"] === null ? null : new Date(json["endDate"]),
    durationMs: json["durationMs"],
    error: json["error"],
    sourceId: json["sourceId"],
    forecastType: ForecastTypeFromJSON(json["forecastType"]),
    title: json["title"],
    trainBegin: new Date(json["trainBegin"]),
    trainEnd: new Date(json["trainEnd"]),
    forecastBegin: new Date(json["forecastBegin"]),
    forecastEnd: new Date(json["forecastEnd"]),
    forecastContextTypes: (json["forecastContextTypes"] as Array<any>).map(DayContextTypesFromJSON),
    forecastContextSourceIds: json["forecastContextSourceIds"],
    forecastProperties: json["forecastProperties"],
    forecastSerieId: json["forecastSerieId"],
  }
}

export function ForecastTaskToJSON(value?: ForecastTask | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    taskType: value.taskType,
    status: TaskStatusToJSON(value.status),
    error: value.error,
    sourceId: value.sourceId,
    forecastType: ForecastTypeToJSON(value.forecastType),
    title: value.title,
    trainBegin: value.trainBegin.toISOString(),
    trainEnd: value.trainEnd.toISOString(),
    forecastBegin: value.forecastBegin.toISOString(),
    forecastEnd: value.forecastEnd.toISOString(),
    forecastContextTypes: (value.forecastContextTypes as Array<any>).map(DayContextTypesToJSON),
    forecastContextSourceIds: value.forecastContextSourceIds,
    forecastProperties: value.forecastProperties,
    forecastSerieId: value.forecastSerieId,
  }
}
