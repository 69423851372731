/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { SourceGroupDto, SourceGroupDtoFromJSON, SourceGroupDtoFromJSONTyped, SourceGroupDtoToJSON } from "./"

/**
 *
 * @export
 * @interface SourceGroupListDto
 */
export interface SourceGroupListDto {
  /**
   *
   * @type {Array<SourceGroupDto>}
   * @memberof SourceGroupListDto
   */
  groups: Array<SourceGroupDto>
}

export function SourceGroupListDtoFromJSON(json: any): SourceGroupListDto {
  return SourceGroupListDtoFromJSONTyped(json, false)
}

export function SourceGroupListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceGroupListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    groups: (json["groups"] as Array<any>).map(SourceGroupDtoFromJSON),
  }
}

export function SourceGroupListDtoToJSON(value?: SourceGroupListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    groups: (value.groups as Array<any>).map(SourceGroupDtoToJSON),
  }
}
