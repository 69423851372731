/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { DayContextCorr, DayContextCorrFromJSON, DayContextCorrFromJSONTyped, DayContextCorrToJSON } from "./"

/**
 *
 * @export
 * @interface GetManyDayContextCorrResult
 */
export interface GetManyDayContextCorrResult {
  /**
   *
   * @type {Array<DayContextCorr>}
   * @memberof GetManyDayContextCorrResult
   */
  data: Array<DayContextCorr>
}

export function GetManyDayContextCorrResultFromJSON(json: any): GetManyDayContextCorrResult {
  return GetManyDayContextCorrResultFromJSONTyped(json, false)
}

export function GetManyDayContextCorrResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetManyDayContextCorrResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json["data"] as Array<any>).map(DayContextCorrFromJSON),
  }
}

export function GetManyDayContextCorrResultToJSON(value?: GetManyDayContextCorrResult | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(DayContextCorrToJSON),
  }
}
