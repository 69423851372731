import Container from "@material-ui/core/Container"
import { makeStyles, Theme } from "@material-ui/core/styles"
import React from "react"
import { AnyChildren } from "../react-type-helpers"
import { ErrorBoundary } from "./ErrorBoundary"
import MadeWithLove from "./MadeWithLove"

const useStyles = makeStyles<Theme, { padding: boolean; noMenu: boolean }>((theme) => ({
  appBarSpacer: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#f8f9fc",
  },
  container: (props) => ({
    ...(props.padding
      ? {
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
          margin: 0,
          maxWidth: "initial",
        }
      : { padding: 0, margin: 0, maxWidth: "initial" }),
    ...(props.noMenu ? { maxWidth: "1000px", margin: "auto" } : {}),
  }),
}))

export default function PageContent({
  padding = true,
  noMenu = false,
  children,
}: {
  padding?: boolean
  noMenu?: boolean
  children: AnyChildren
}) {
  const classes = useStyles({ padding, noMenu })

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Container>
      <MadeWithLove />
    </main>
  )
}
