import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import React from "react"
import { DayModelDto } from "api/timelight-api"
import { hexToRGBA } from "../../lib/color"

export default function ScatterDays({
  days,
  models,
  setSelectedDay,
  selectedDay = null,
  disableAnomalies = false,
}: {
  days: DayModelDto[]
  models: { id: number; color: string; name: string }[]
  setSelectedDay: (day: DayModelDto) => void
  selectedDay?: DayModelDto | null
  disableAnomalies?: boolean
}) {
  const anomalies = days.filter((d) => d.anomaly)
  const notAnomalies = days.filter((d) => !d.anomaly)

  const series: Highcharts.SeriesScatterOptions[] = []
  for (const model of models) {
    const modelDays = notAnomalies.filter((d) => d.model_id === model.id)
    if (modelDays.length > 0) {
      series.push({
        id: "model-" + model.id,
        type: "scatter",
        name: model.name,
        color: hexToRGBA(model.color, 0.6),
        data: modelDays.map((d) => ({
          x: d.x || 0,
          y: d.y || 0,
          day: d,
          selected: d === selectedDay,
          events: {
            click() {
              setSelectedDay(d)
            },
          },
        })),
        tooltip: {
          pointFormatter(this: any) {
            return modelDays[this.index].date
          },
        } as Highcharts.SeriesTooltipOptionsObject,
      })
    }
  }

  // anomalies
  if (anomalies.length > 0) {
    series.push({
      id: anomalies.map((d) => d.date).join("-"),
      type: "scatter",
      name: "Anomalies",
      color: "black",
      data: anomalies.map((d) => ({
        x: d.x || 0,
        y: d.y || 0,
        day: d,
        selected: d === selectedDay,
        events: {
          click() {
            setSelectedDay(d)
          },
        },
      })),
      visible: !disableAnomalies,
      marker: {
        radius: 7,
      },
      tooltip: {
        pointFormatter(this: any) {
          return anomalies[this.index].date
        },
      } as Highcharts.SeriesTooltipOptionsObject,
    })
  }

  const options: Highcharts.Options = {
    time: {
      timezone: "Europe/Paris",
    },
    chart: {
      type: "scatter",
      zoomType: "xy",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    plotOptions: {
      scatter: {
        cursor: "pointer",
        states: {
          inactive: {
            opacity: 0.7,
          },
          select: {
            halo: {
              opacity: 0,
            },
            marker: {
              enabled: true,
              fillColor: undefined,
              lineColor: undefined,
              radius: 10,
              lineWidth: 4,
            },
          },
        } as Highcharts.SeriesStatesOptionsObject,
        marker: {
          symbol: "circle",
          lineWidth: 0.7,
          lineColor: "rgba(100,100,100, 0.5)",
          radius: 5,
          states: {
            hover: {
              lineColor: "rgba(100,100,100,0.7)",
            },
          },
        },
      },
    },
    xAxis: {
      gridLineWidth: 1,
      tickWidth: 0,
      startOnTick: true,
      endOnTick: true,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      gridLineWidth: 1,
      tickWidth: 0,
      startOnTick: true,
      endOnTick: true,
      labels: {
        enabled: false,
      },
      title: "",
    } as Highcharts.YAxisOptions,
    series,
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
