import { Tooltip, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import HelpIcon from "@material-ui/icons/Help"
import DaysNearDateModal from "component/DaysNearDateModal"
import ModelModal from "component/ModelModal"
import PageBlock from "component/PageBlock"
import ToleranceChart from "component/ToleranceChart"
import { arraysToToleranceData } from "component/ToleranceChart/util"
import React, { useState } from "react"
import { DayModelDto, ModelDto } from "api/timelight-api"
import ActivityHeatmap from "../../component/ActivityHeatmap"
import AppHeader from "../../component/AppHeader"
import ChartDayActivity from "../../component/ChartDayActivity"
import { DATA_NOT_PROVIDED_MODEL_ID } from "../../component/ChartDayActivity"
import DayYearCalendar from "../../component/DayYearCalendar"
import ModelTag from "../../component/ModelTag"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import SourceSelect from "../../component/SourceSelect"
import { getToleranceDataDomain, smoothDomain } from "../../lib/graph"
import { useDayAndModelList, useTemperatureContext } from "../../state/api"
import { useSourceSelect } from "../../state/persisted"
import { createChartDayDataFromApiInfos } from "../../component/ChartDayActivity/utils"
import { AppButton } from "../../component/AppButton"

const useStyles = makeStyles({
  modelChart: {
    height: "300px",
    width: "100%",
    maxWidth: "400px",
  },
  modelTag: {
    paddingRight: "1em",
  },
  modelTagContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    marginBottom: "4em",
  },
})

export default function DataHistory() {
  // our styles
  const classes = useStyles()

  // the app bar for values
  const [source, setSourceId] = useSourceSelect()

  // fetch some data
  const [{ data, loading }] = useDayAndModelList({
    shouldTrigger: source.id !== -1,
    sourceId: source.id,
    year: source.referenceYear,
  })
  // fetch temperature data
  const temperatureContext = useTemperatureContext({
    sourceId: source.id,
    minDate: `${source.referenceYear}-01-01`,
    maxDate: `${source.referenceYear}-12-31`,
    shouldTrigger: source.id !== -1,
  })

  // state for our day modal
  const [selectedDay, setSelectedDay] = useState<DayModelDto | null>(null)
  // state for our model modal
  const [selectedModel, setSelectedModel] = useState<ModelDto | null>(null)

  const modelYDomain = smoothDomain(getToleranceDataDomain(data ? data.models : []), 5, 100)

  return (
    <PageContainer title="Données historiques">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Données historiques de
            <SourceSelect sourceId={source.id} setSourceId={setSourceId} />
            sur l'année de référence {source.referenceYear}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        {data && selectedDay && (
          <DaysNearDateModal
            open={!!selectedDay}
            sourceId={source.id}
            initialDate={selectedDay.date}
            onClose={() => setSelectedDay(null)}
          />
        )}

        {data && selectedModel && (
          <ModelModal
            open={!!selectedModel}
            model={selectedModel}
            days={data.days}
            source={source}
            data={arraysToToleranceData(
              selectedModel.top_tolerance,
              selectedModel.activity,
              selectedModel.bottom_tolerance,
            )}
            onClose={() => setSelectedModel(null)}
          />
        )}

        <PageBlock loading={loading} title={`Activité sur l'année de réference: ${source.referenceYear}`} height={400}>
          {data && (
            <ChartDayActivity
              activityLabel="Activité effective"
              models={data.models}
              days={createChartDayDataFromApiInfos({
                days: data.days.map((d) => ({ ...d, model_id: d.model_id || DATA_NOT_PROVIDED_MODEL_ID })),
                context: temperatureContext,
                previsions: [],
                models: data.models,
                year: source.referenceYear,
              })}
              onBarClick={(selected) => setSelectedDay(data.days.find((d) => d.date === selected.date) || null)}
            />
          )}
        </PageBlock>

        <PageBlock loading={!data}>
          {data && <ActivityHeatmap year={source.referenceYear} days={data.days} label="Activité effective" />}
        </PageBlock>

        <PageBlock loading={loading} title={`Calendrier de l'année de réference: ${source.referenceYear}`}>
          {data && (
            <DayYearCalendar
              year={source.referenceYear}
              models={data.models}
              days={data.days.map((d) => ({ ...d, model_id: d.model_id || DATA_NOT_PROVIDED_MODEL_ID }))}
              setSelectedDay={(selected) => setSelectedDay(data.days.find((d) => d.date === selected.date) || null)}
            />
          )}
        </PageBlock>

        <PageBlock
          loading={!data}
          title={`Profil types de l'année de réference: ${source.referenceYear}`}
          help={
            <div>
              <p>
                Vous trouverez ici les profil types de journée type générés par notre intelligence artificielle Vous
                pouvez affiner ces profil types dans la section "Identifier"
              </p>
              <p>N'hésitez pas à nous contacter si vous souhaitez charger vos propres profil types</p>
            </div>
          }
        >
          {data && (
            <Grid container>
              {data.models.map((m) => (
                <Grid key={m.id} item xs={12} md={6} lg={4}>
                  <Grid container direction="column" justify="center" alignItems="center">
                    <div className={classes.modelChart}>
                      <ToleranceChart
                        activityTitle="Activité moyenne"
                        data={arraysToToleranceData(m.top_tolerance, m.activity, m.bottom_tolerance)}
                        yDomain={modelYDomain}
                      />
                    </div>
                    <div className={classes.modelTagContainer}>
                      <div className={classes.modelTag}>
                        <ModelTag model={m} />
                      </div>
                      <Tooltip title="Détail des journées du profil type">
                        <AppButton variant="text" color="inherit" size="small" onClick={() => setSelectedModel(m)}>
                          <HelpIcon style={{ cursor: "pointer", color: "#4e73df" }} />
                        </AppButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </PageBlock>
      </PageContent>
    </PageContainer>
  )
}
