// tslint:disable:max-line-length
import React from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import parse from "autosuggest-highlight/parse"
import throttle from "lodash/throttle"
import { useConfig } from "../config"
import { GoogleApiWrapper, ProvidedProps as AdditionalGoogleProps } from "google-maps-react"

const autocompleteService: { current: null | google.maps.places.AutocompleteService } = { current: null }
const placesService: { current: null | google.maps.places.PlacesService } = { current: null }
const autocompleteSessionToken: { current: null | google.maps.places.AutocompleteSessionToken } = { current: null }

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

interface PlaceType {
  description: string
  place_id: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: {
      offset: number
      length: number
    }[]
  }
}

interface PlacesAutocompleteInputProps {
  initialValue: string
  label: string
  required: boolean
  helperText?: string
  onSelect: (selected: google.maps.places.PlaceResult | undefined) => void
}

export function PlacesAutocompleteInput(props: PlacesAutocompleteInputProps) {
  const { GMAPS_API_KEY } = useConfig()
  const GmapsApiWrapper = GoogleApiWrapper({ apiKey: GMAPS_API_KEY })(PlacesAutocompleteInputComponent)
  return <GmapsApiWrapper {...props} />
}

// https://material-ui.com/components/autocomplete/#GoogleMaps.tsx
function PlacesAutocompleteInputComponent({
  initialValue,
  label,
  required,
  helperText,
  onSelect,
  google,
}: PlacesAutocompleteInputProps & AdditionalGoogleProps) {
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState(initialValue)
  const [options, setOptions] = React.useState<PlaceType[]>([])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const fetch = React.useMemo(
    // @ts-ignore
    () => throttle((input, callback) => (autocompleteService.current as any).getPlacePredictions(input, callback), 200),
    [],
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && google) {
      autocompleteService.current = new google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }
    if (!placesService.current && google) {
      placesService.current = new google.maps.places.PlacesService(document.createElement("div"))
    }
    if (!placesService.current) {
      return undefined
    }

    if (!autocompleteSessionToken.current && google) {
      autocompleteSessionToken.current = new google.maps.places.AutocompleteSessionToken()
    }
    if (!autocompleteSessionToken.current) {
      return undefined
    }

    if (inputValue === "") {
      setOptions([])
      return undefined
    }

    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest.location
    // https://gist.github.com/graydon/11198540
    // (-54.5247541978, 2.05338918702, 9.56001631027, 51.1485061713)
    //   lat_min	 lat_max	 lon_min	lon_max
    // 	41.2632185	51.268318	-5.4534286	9.8678344
    const franceBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng({ lat: 41.2632185, lng: -5.4534286 }),
      new google.maps.LatLng({ lat: 51.268318, lng: 9.8678344 }),
    )
    // paris: { lat: 48.8534, lng: 2.3488 }
    fetch(
      { input: inputValue, bounds: franceBounds, sessionToken: autocompleteSessionToken.current },
      (results?: PlaceType[]) => {
        if (active) {
          setOptions(results || [])
        }
      },
    )

    return () => {
      active = false
    }
  }, [inputValue, fetch, google])

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      onChange={(_: any, place: any) => {
        if (placesService.current && autocompleteSessionToken.current) {
          placesService.current.getDetails(
            { placeId: place.place_id, sessionToken: autocompleteSessionToken.current },
            (details: any) => {
              if (details && details.formatted_address) {
                setInputValue(details.formatted_address)
              }
              onSelect(details)
            },
          )
        }
      }}
      inputValue={inputValue}
      options={options}
      autoComplete
      includeInputInList
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, style: { padding: 0, paddingLeft: 5 } }}
          variant="outlined"
          margin="normal"
          label={label}
          helperText={helperText}
          fullWidth
          required={required}
          onChange={handleChange}
        />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        )

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
