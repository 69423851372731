/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface AnomaliesResponseDto
 */
export interface AnomaliesResponseDto {
  /**
   * Dates with anomalies
   * @type {Array<string>}
   * @memberof AnomaliesResponseDto
   */
  dates: Array<string>
}

export function AnomaliesResponseDtoFromJSON(json: any): AnomaliesResponseDto {
  return AnomaliesResponseDtoFromJSONTyped(json, false)
}

export function AnomaliesResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnomaliesResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dates: json["dates"],
  }
}

export function AnomaliesResponseDtoToJSON(value?: AnomaliesResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    dates: value.dates,
  }
}
