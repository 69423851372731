import moment from "moment"
import React, { PureComponent } from "react"

interface Props {
  classes: string
  dayClicked: (date: moment.Moment | null) => void
  dayHovered: (date: moment.Moment | null) => void
  day: moment.Moment | null
}

export default class Day extends PureComponent<Props> {
  public static defaultProps = {
    classes: "",
    day: null,
  }

  public render() {
    const { classes, day } = this.props
    return (
      <td onClick={this.onClick} onMouseEnter={this.onHover} className={classes}>
        <span className="day-number">{day === null ? "" : day.date()}</span>
      </td>
    )
  }

  private onClick = () => {
    this.props.dayClicked(this.props.day)
  }

  private onHover = () => {
    this.props.dayHovered(this.props.day)
  }
}
