/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  ContextTypeDateDomainListDto,
  ContextTypeDateDomainListDtoFromJSON,
  ContextTypeDateDomainListDtoToJSON,
  ContextTypeStatsListDto,
  ContextTypeStatsListDtoFromJSON,
  ContextTypeStatsListDtoToJSON,
  DayContextListDto,
  DayContextListDtoFromJSON,
  DayContextListDtoToJSON,
  DayContextSummaryListDto,
  DayContextSummaryListDtoFromJSON,
  DayContextSummaryListDtoToJSON,
  DayContextSummaryYearDto,
  DayContextSummaryYearDtoFromJSON,
  DayContextSummaryYearDtoToJSON,
  DayContextTypes,
  DayContextTypesFromJSON,
  DayContextTypesToJSON,
  GetManyDayContextCorrResult,
  GetManyDayContextCorrResultFromJSON,
  GetManyDayContextCorrResultToJSON,
  GetManyDayContextResult,
  GetManyDayContextResultFromJSON,
  GetManyDayContextResultToJSON,
} from "../models"

export interface DayContextControllerGetManyRequest {
  sourceId: number
  ctxType: DayContextTypes
  begin: Date
  end: Date
}

export interface DayContextControllerImportMeteoCsvRequest {
  sourceId: number
}

export interface DayContextControllerLoadMeteoRequest {
  sourceId: number
  year?: number
}

export interface DayContextControllerSourceContextDateDomainsRequest {
  sourceId: number
}

export interface DayContextControllerSourceContextStatsRequest {
  sourceId: number
}

export interface DayContextCorrControllerContextSummaryDayRequest {
  sourceId: number
  dayDate: string
}

export interface DayContextCorrControllerContextSummaryYearRequest {
  sourceId: number
  year: number
}

export interface DayContextCorrControllerGetManyRequest {
  sourceId: number
  ctxType: DayContextTypes
  begin: Date
  end: Date
}

/**
 * no description
 */
export class DayContextApi extends runtime.BaseAPI {
  /**
   * Fetch day contexts
   */
  async dayContextControllerGetManyRaw(
    requestParameters: DayContextControllerGetManyRequest,
  ): Promise<runtime.ApiResponse<GetManyDayContextResult>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayContextControllerGetMany.",
      )
    }

    if (requestParameters.ctxType === null || requestParameters.ctxType === undefined) {
      throw new runtime.RequiredError(
        "ctxType",
        "Required parameter requestParameters.ctxType was null or undefined when calling dayContextControllerGetMany.",
      )
    }

    if (requestParameters.begin === null || requestParameters.begin === undefined) {
      throw new runtime.RequiredError(
        "begin",
        "Required parameter requestParameters.begin was null or undefined when calling dayContextControllerGetMany.",
      )
    }

    if (requestParameters.end === null || requestParameters.end === undefined) {
      throw new runtime.RequiredError(
        "end",
        "Required parameter requestParameters.end was null or undefined when calling dayContextControllerGetMany.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.sourceId !== undefined) {
      queryParameters["sourceId"] = requestParameters.sourceId
    }

    if (requestParameters.ctxType !== undefined) {
      queryParameters["ctxType"] = requestParameters.ctxType
    }

    if (requestParameters.begin !== undefined) {
      queryParameters["begin"] = (requestParameters.begin as any).toISOString()
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString()
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-context`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyDayContextResultFromJSON(jsonValue))
  }

  /**
   * Fetch day contexts
   */
  async dayContextControllerGetMany(
    requestParameters: DayContextControllerGetManyRequest,
  ): Promise<GetManyDayContextResult> {
    const response = await this.dayContextControllerGetManyRaw(requestParameters)
    return await response.value()
  }

  /**
   * Imports a meteo csv file for the source id
   */
  async dayContextControllerImportMeteoCsvRaw(
    requestParameters: DayContextControllerImportMeteoCsvRequest,
  ): Promise<runtime.ApiResponse<DayContextListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayContextControllerImportMeteoCsv.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-context/import-meteo-csv/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayContextListDtoFromJSON(jsonValue))
  }

  /**
   * Imports a meteo csv file for the source id
   */
  async dayContextControllerImportMeteoCsv(
    requestParameters: DayContextControllerImportMeteoCsvRequest,
  ): Promise<DayContextListDto> {
    const response = await this.dayContextControllerImportMeteoCsvRaw(requestParameters)
    return await response.value()
  }

  /**
   * Loads meteo data from our own internal meteo source
   */
  async dayContextControllerLoadMeteoRaw(
    requestParameters: DayContextControllerLoadMeteoRequest,
  ): Promise<runtime.ApiResponse<DayContextListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayContextControllerLoadMeteo.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.year !== undefined) {
      queryParameters["year"] = requestParameters.year
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-context/load-meteo/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayContextListDtoFromJSON(jsonValue))
  }

  /**
   * Loads meteo data from our own internal meteo source
   */
  async dayContextControllerLoadMeteo(
    requestParameters: DayContextControllerLoadMeteoRequest,
  ): Promise<DayContextListDto> {
    const response = await this.dayContextControllerLoadMeteoRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch day contexts date domain
   */
  async dayContextControllerSourceContextDateDomainsRaw(
    requestParameters: DayContextControllerSourceContextDateDomainsRequest,
  ): Promise<runtime.ApiResponse<ContextTypeDateDomainListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayContextControllerSourceContextDateDomains.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-context/date-domain/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ContextTypeDateDomainListDtoFromJSON(jsonValue))
  }

  /**
   * Fetch day contexts date domain
   */
  async dayContextControllerSourceContextDateDomains(
    requestParameters: DayContextControllerSourceContextDateDomainsRequest,
  ): Promise<ContextTypeDateDomainListDto> {
    const response = await this.dayContextControllerSourceContextDateDomainsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch day contexts stats
   */
  async dayContextControllerSourceContextStatsRaw(
    requestParameters: DayContextControllerSourceContextStatsRequest,
  ): Promise<runtime.ApiResponse<ContextTypeStatsListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayContextControllerSourceContextStats.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-context/stats/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ContextTypeStatsListDtoFromJSON(jsonValue))
  }

  /**
   * Fetch day contexts stats
   */
  async dayContextControllerSourceContextStats(
    requestParameters: DayContextControllerSourceContextStatsRequest,
  ): Promise<ContextTypeStatsListDto> {
    const response = await this.dayContextControllerSourceContextStatsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Computes the day context summary for a day
   */
  async dayContextCorrControllerContextSummaryDayRaw(
    requestParameters: DayContextCorrControllerContextSummaryDayRequest,
  ): Promise<runtime.ApiResponse<DayContextSummaryListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayContextCorrControllerContextSummaryDay.",
      )
    }

    if (requestParameters.dayDate === null || requestParameters.dayDate === undefined) {
      throw new runtime.RequiredError(
        "dayDate",
        "Required parameter requestParameters.dayDate was null or undefined when calling dayContextCorrControllerContextSummaryDay.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-context-corr/summary-day/{sourceId}/{dayDate}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"dayDate"}}`, encodeURIComponent(String(requestParameters.dayDate))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayContextSummaryListDtoFromJSON(jsonValue))
  }

  /**
   * Computes the day context summary for a day
   */
  async dayContextCorrControllerContextSummaryDay(
    requestParameters: DayContextCorrControllerContextSummaryDayRequest,
  ): Promise<DayContextSummaryListDto> {
    const response = await this.dayContextCorrControllerContextSummaryDayRaw(requestParameters)
    return await response.value()
  }

  /**
   * Computes the day context summary for a year for all days
   */
  async dayContextCorrControllerContextSummaryYearRaw(
    requestParameters: DayContextCorrControllerContextSummaryYearRequest,
  ): Promise<runtime.ApiResponse<DayContextSummaryYearDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayContextCorrControllerContextSummaryYear.",
      )
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling dayContextCorrControllerContextSummaryYear.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-context-corr/summary-year/{sourceId}/{year}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DayContextSummaryYearDtoFromJSON(jsonValue))
  }

  /**
   * Computes the day context summary for a year for all days
   */
  async dayContextCorrControllerContextSummaryYear(
    requestParameters: DayContextCorrControllerContextSummaryYearRequest,
  ): Promise<DayContextSummaryYearDto> {
    const response = await this.dayContextCorrControllerContextSummaryYearRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch day contexts
   */
  async dayContextCorrControllerGetManyRaw(
    requestParameters: DayContextCorrControllerGetManyRequest,
  ): Promise<runtime.ApiResponse<GetManyDayContextCorrResult>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling dayContextCorrControllerGetMany.",
      )
    }

    if (requestParameters.ctxType === null || requestParameters.ctxType === undefined) {
      throw new runtime.RequiredError(
        "ctxType",
        "Required parameter requestParameters.ctxType was null or undefined when calling dayContextCorrControllerGetMany.",
      )
    }

    if (requestParameters.begin === null || requestParameters.begin === undefined) {
      throw new runtime.RequiredError(
        "begin",
        "Required parameter requestParameters.begin was null or undefined when calling dayContextCorrControllerGetMany.",
      )
    }

    if (requestParameters.end === null || requestParameters.end === undefined) {
      throw new runtime.RequiredError(
        "end",
        "Required parameter requestParameters.end was null or undefined when calling dayContextCorrControllerGetMany.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.sourceId !== undefined) {
      queryParameters["sourceId"] = requestParameters.sourceId
    }

    if (requestParameters.ctxType !== undefined) {
      queryParameters["ctxType"] = requestParameters.ctxType
    }

    if (requestParameters.begin !== undefined) {
      queryParameters["begin"] = (requestParameters.begin as any).toISOString()
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString()
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/day-context-corr`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyDayContextCorrResultFromJSON(jsonValue))
  }

  /**
   * Fetch day contexts
   */
  async dayContextCorrControllerGetMany(
    requestParameters: DayContextCorrControllerGetManyRequest,
  ): Promise<GetManyDayContextCorrResult> {
    const response = await this.dayContextCorrControllerGetManyRaw(requestParameters)
    return await response.value()
  }
}
