/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { TaskStatus, TaskStatusFromJSON, TaskStatusFromJSONTyped, TaskStatusToJSON } from "./"

/**
 *
 * @export
 * @interface SerieSyncTask
 */
export interface SerieSyncTask {
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof SerieSyncTask
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof SerieSyncTask
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  readonly companyId: number
  /**
   *
   * @type {string}
   * @memberof SerieSyncTask
   */
  taskType: string
  /**
   *
   * @type {TaskStatus}
   * @memberof SerieSyncTask
   */
  status: TaskStatus
  /**
   *
   * @type {Date}
   * @memberof SerieSyncTask
   */
  readonly startDate: Date | null
  /**
   *
   * @type {Date}
   * @memberof SerieSyncTask
   */
  readonly endDate: Date | null
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  readonly durationMs: number | null
  /**
   *
   * @type {string}
   * @memberof SerieSyncTask
   */
  error: string | null
  /**
   *
   * @type {string}
   * @memberof SerieSyncTask
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  refSourceId: number
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  toSyncSourceId: number
  /**
   *
   * @type {Date}
   * @memberof SerieSyncTask
   */
  trainBegin: Date
  /**
   *
   * @type {Date}
   * @memberof SerieSyncTask
   */
  trainEnd: Date
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  globalPearsonValue: number | null
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  globalOffsetMs: number | null
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  rollingPearsonSerieId: number | null
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  toSyncAlignedSerieId: number | null
  /**
   *
   * @type {number}
   * @memberof SerieSyncTask
   */
  rollingPearsonAlignedSerieId: number | null
}

export function SerieSyncTaskFromJSON(json: any): SerieSyncTask {
  return SerieSyncTaskFromJSONTyped(json, false)
}

export function SerieSyncTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerieSyncTask {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    taskType: json["taskType"],
    status: TaskStatusFromJSON(json["status"]),
    startDate: json["startDate"] === null ? null : new Date(json["startDate"]),
    endDate: json["endDate"] === null ? null : new Date(json["endDate"]),
    durationMs: json["durationMs"],
    error: json["error"],
    title: json["title"],
    refSourceId: json["refSourceId"],
    toSyncSourceId: json["toSyncSourceId"],
    trainBegin: new Date(json["trainBegin"]),
    trainEnd: new Date(json["trainEnd"]),
    globalPearsonValue: json["globalPearsonValue"],
    globalOffsetMs: json["globalOffsetMs"],
    rollingPearsonSerieId: json["rollingPearsonSerieId"],
    toSyncAlignedSerieId: json["toSyncAlignedSerieId"],
    rollingPearsonAlignedSerieId: json["rollingPearsonAlignedSerieId"],
  }
}

export function SerieSyncTaskToJSON(value?: SerieSyncTask | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    taskType: value.taskType,
    status: TaskStatusToJSON(value.status),
    error: value.error,
    title: value.title,
    refSourceId: value.refSourceId,
    toSyncSourceId: value.toSyncSourceId,
    trainBegin: value.trainBegin.toISOString(),
    trainEnd: value.trainEnd.toISOString(),
    globalPearsonValue: value.globalPearsonValue,
    globalOffsetMs: value.globalOffsetMs,
    rollingPearsonSerieId: value.rollingPearsonSerieId,
    toSyncAlignedSerieId: value.toSyncAlignedSerieId,
    rollingPearsonAlignedSerieId: value.rollingPearsonAlignedSerieId,
  }
}
