import React, { useMemo } from "react"
import PageContainer from "../../component/PageContainer"
import AppHeader from "../../component/AppHeader"
import { useApiClient } from "../../state/api"
import { Grid, InputLabel } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import SideMenu from "../../component/SideMenu"
import PageBlock from "../../component/PageBlock"
import PageContent from "../../component/PageContent"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { useHistory, Prompt } from "react-router"
import { ConfirmDialogButton } from "../../component/ConfirmDialogButton"
import * as lodash from "lodash"
import { Form } from "react-final-form"
import { createCalculator, DecoratorConfig, applyCalculatorParams } from "../../component/form/decorator"
import { buildUrl } from "../../component/AppLink"
import { AppRoute, useAppRoute } from "../../state/route"
import { SourceTableSelectFormState, createSourceSelectTableField } from "../../component/form/SourceSelectTableField"
import { createSourceSelectPreviewField, SourcePreviewFormState } from "../../component/form/SourcePreviewField"
import { SourceSelectFormState, createSourceSelectField } from "../../component/form/SourceSelectField"
import { createCheckboxField, CheckboxFormState } from "../../component/form/CheckboxField"
import {
  ContextTypesSelectFormState,
  createContextTypeSelectTableField,
} from "../../component/form/ContextTypesSelectField"
import { createTextField } from "component/form/TextField"
import { useAsyncAction, AsyncHookParams } from "../../state/async"
import Loader from "../../component/Loader"
import { TextFormState } from "../../component/form/TextField"
import { ContextImpactTask } from "../../api/timelight-api/models/ContextImpactTask"

interface FormState {
  source: SourceSelectFormState
  sourcePreview: SourcePreviewFormState
  title: TextFormState
  contextTypes: ContextTypesSelectFormState
  sourceIds: SourceTableSelectFormState
  showOnlyDateCompatibleContext: CheckboxFormState
}

export function ContextImpactTaskCreate() {
  const history = useHistory()
  const api = useApiClient()

  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Tâche créée, cette opération peut prendre jusqu'à 5 minutes",
  })

  const fetchSourceDomain = useMemo(() => {
    const fetcher = (sourceId: number) => api.sourceControllerSourceDateDomain({ sourceId })
    return lodash.memoize(fetcher)
  }, [api])

  const calculatorParams = useMemo(
    () => [
      {
        field: "source",
        updates: {
          sourcePreview: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.sourcePreview
            }
            const domain = await fetchSourceDomain(value.sourceId)
            return {
              ...allValues.sourcePreview,
              sourceId: value.sourceId,
              previewLimits: { begin: domain.beginDate, end: domain.endDate },
            }
          },
          contextTypes: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.contextTypes
            }
            const domain = await fetchSourceDomain(value.sourceId)
            return {
              ...allValues.contextTypes,
              sourceId: value.sourceId,
              filterByDateDomain: allValues.showOnlyDateCompatibleContext
                ? {
                    begin: domain.beginDate,
                    end: domain.endDate,
                  }
                : null,
            }
          },
          sourceIds: async (value, allValues) => {
            if (!value.sourceId) {
              return allValues.sourceIds
            }
            const domain = await fetchSourceDomain(value.sourceId)
            return {
              ...allValues.sourceIds,
              filterByDateDomain: allValues.showOnlyDateCompatibleContext
                ? {
                    begin: domain.beginDate,
                    end: domain.endDate,
                  }
                : null,
            }
          },
        },
      } as DecoratorConfig<FormState, "source">,
      {
        field: "showOnlyDateCompatibleContext",
        updates: {
          contextTypes: (value, allValues) => {
            return {
              ...allValues.contextTypes,
              filterByDateDomain:
                value && allValues.sourcePreview.previewLimits.begin && allValues.sourcePreview.previewLimits.end
                  ? {
                      begin: allValues.sourcePreview.previewLimits.begin,
                      end: allValues.sourcePreview.previewLimits.end,
                    }
                  : null,
            }
          },
          sourceIds: (value, allValues) => {
            return {
              ...allValues.sourceIds,
              filterByDateDomain:
                value && allValues.sourcePreview.previewLimits.begin && allValues.sourcePreview.previewLimits.end
                  ? {
                      begin: allValues.sourcePreview.previewLimits.begin,
                      end: allValues.sourcePreview.previewLimits.end,
                    }
                  : null,
            }
          },
        },
      } as DecoratorConfig<FormState, "showOnlyDateCompatibleContext">,
    ],
    [fetchSourceDomain],
  )

  const currentRoute = useAppRoute()
  const currentTaskId = currentRoute.params.taskId ? parseInt(currentRoute.params.taskId, 10) : undefined
  const [{ data: initialValues }] = useAsyncAction<FormState, AsyncHookParams<{ taskId?: number }>>(
    async ({ taskId }) => {
      // fetch the default source
      const sources = await api.getManyBaseSourceControllerSourceDto({ limit: 1 })
      const source = sources.data[0]
      let values: FormState = {
        source: {
          sourceId: source.id,
          sourceIdExclusions: [],
        },
        sourcePreview: {
          sourceId: source.id,
          forecastBand: {},
          trainBand: {},
          previewLimits: {
            begin: new Date(),
            end: new Date(),
          },
        },
        title: "Nouveau projet",
        sourceIds: {
          sourceIds: [],
          filterByDateDomain: null,
        },
        contextTypes: {
          sourceId: source.id,
          contextTypes: [],
          filterByDateDomain: null,
        },
        showOnlyDateCompatibleContext: false,
      }

      if (taskId) {
        const task = await api.getOneBaseContextImpactTaskControllerContextImpactTask({ id: taskId })

        // apply calculator to selected sources
        values.title = task.title
        values = await applyCalculatorParams(values, calculatorParams, "source", {
          sourceId: task.sourceId,
        })
        values = await applyCalculatorParams(values, calculatorParams, "contextTypes", {
          contextTypes: task.contextTypes,
        })
        values = await applyCalculatorParams(values, calculatorParams, "sourceIds", {
          sourceIds: task.contextSourceIds,
        })
      } else {
        // apply calculator to selected sources
        values = await applyCalculatorParams(values, calculatorParams, "source", {
          sourceId: source.id,
        })
      }

      return values
    },
    {
      shouldTrigger: true,
      taskId: currentTaskId,
    },
  )

  return (
    <PageContainer title="Analyse de données contextuelles - Nouveau Projet">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Nouveau projet d'analyse de données contextuelles
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SuccessMessage />

        {!initialValues ? (
          <Loader />
        ) : (
          <Form<FormState>
            decorators={[createCalculator(...calculatorParams)]}
            validate={(values) => {
              const errors: { [key in keyof typeof values]?: string[] } = {
                contextTypes: [],
                sourceIds: [],
                title: [],
              }

              if (!values.title || values.title.length <= 0) {
                errors.title?.push("Veuillez saisir un nom de projet")
              }

              if (values.contextTypes.contextTypes.length === 0 && values.sourceIds.sourceIds.length === 0) {
                errors.contextTypes?.push("Veuillez sélectionner au moins une donnée contextuelle")
                errors.sourceIds?.push("Veuillez sélectionner au moins une donnée contextuelle")
              }

              return errors
            }}
            onSubmit={async (values, form) => {
              let res: ContextImpactTask | null = null
              const inputParams = {
                contextSourceIds: values.sourceIds.sourceIds,
                contextTypes: values.contextTypes.contextTypes,
                title: values.title,
                sourceId: values.source.sourceId || -1,
              }
              if (currentRoute.route === AppRoute.CONTEXT_IMPACT_TASK_UPDATE && currentTaskId) {
                res = await api.contextImpactTaskControllerUpdateOne({
                  contextImpactTaskId: currentTaskId,
                  contextImpactTaskInputParams: inputParams,
                })
              } else {
                res = await api.contextImpactTaskControllerCreateOne({
                  contextImpactTaskInputParams: inputParams,
                })
              }
              const taskId = res.id

              showSuccessMessage()
              setTimeout(() => {
                history.push(
                  buildUrl({
                    route: AppRoute.CONTEXT_IMPACT_TASK_VIEW,
                    params: { taskId: taskId + "" },
                  }),
                )
              }, 1500)
            }}
            initialValues={initialValues}
            render={({ handleSubmit, dirty, submitting, pristine, values, valid, submitSucceeded }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Prompt
                    when={dirty && !submitting && !submitSucceeded}
                    message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                  />
                  <PageBlock title="Configuration du projet">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ marginRight: "1em", width: "150px" }}>Nom du projet : </InputLabel>
                      {createTextField<FormState>({ name: "title", label: "Nom du projet", required: true })}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ marginRight: "1em", width: "142px" }}>Source cible : </InputLabel>
                      {createSourceSelectField<FormState>({ name: "source" })}
                    </div>
                    {createSourceSelectPreviewField<FormState>({ name: "sourcePreview" })}
                  </PageBlock>

                  <PageBlock title="Données contextuelles">
                    <Grid container spacing={9}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {createContextTypeSelectTableField<FormState>({ name: "contextTypes" })}
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {createSourceSelectTableField<FormState>({
                          name: "sourceIds",
                          title: <InputLabel>Ajouter une source de données comme donnée contextuelle</InputLabel>,
                          pageSize: 5,
                        })}
                      </Grid>
                    </Grid>

                    {createCheckboxField<FormState>({
                      name: "showOnlyDateCompatibleContext",
                      label: "Monter uniquement les données compatibles avec la période d'apprentissage sélectionnée",
                    })}
                  </PageBlock>

                  <ConfirmDialogButton disabled={!valid} isSubmitting={submitting} onSubmit={handleSubmit} />
                </form>
              )
            }}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}
