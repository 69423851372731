/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  ModelListDto,
  ModelListDtoFromJSON,
  ModelListDtoToJSON,
  ModelsPatchDto,
  ModelsPatchDtoFromJSON,
  ModelsPatchDtoToJSON,
  ModelsPostDto,
  ModelsPostDtoFromJSON,
  ModelsPostDtoToJSON,
} from "../models"

export interface ModelControllerBulkUpdateModelsRequest {
  modelsPatchDto: ModelsPatchDto
}

export interface ModelControllerListSourceModelsRequest {
  sourceId: number
}

export interface ModelControllerResetToDefaultModelsRequest {
  sourceId: number
  modelCount: number
}

export interface ModelControllerUseCustomModelsRequest {
  sourceId: number
  modelsPostDto: ModelsPostDto
}

/**
 * no description
 */
export class ModelApi extends runtime.BaseAPI {
  /**
   * Update many models at once, mainly used to set color and name of the model
   * Model bulk update
   */
  async modelControllerBulkUpdateModelsRaw(
    requestParameters: ModelControllerBulkUpdateModelsRequest,
  ): Promise<runtime.ApiResponse<ModelListDto>> {
    if (requestParameters.modelsPatchDto === null || requestParameters.modelsPatchDto === undefined) {
      throw new runtime.RequiredError(
        "modelsPatchDto",
        "Required parameter requestParameters.modelsPatchDto was null or undefined when calling modelControllerBulkUpdateModels.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model/bulk`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsPatchDtoToJSON(requestParameters.modelsPatchDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelListDtoFromJSON(jsonValue))
  }

  /**
   * Update many models at once, mainly used to set color and name of the model
   * Model bulk update
   */
  async modelControllerBulkUpdateModels(
    requestParameters: ModelControllerBulkUpdateModelsRequest,
  ): Promise<ModelListDto> {
    const response = await this.modelControllerBulkUpdateModelsRaw(requestParameters)
    return await response.value()
  }

  /**
   * List models data of this source
   */
  async modelControllerListModelsRaw(): Promise<runtime.ApiResponse<ModelListDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model/list`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelListDtoFromJSON(jsonValue))
  }

  /**
   * List models data of this source
   */
  async modelControllerListModels(): Promise<ModelListDto> {
    const response = await this.modelControllerListModelsRaw()
    return await response.value()
  }

  /**
   * List models data of this source
   */
  async modelControllerListSourceModelsRaw(
    requestParameters: ModelControllerListSourceModelsRequest,
  ): Promise<runtime.ApiResponse<ModelListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling modelControllerListSourceModels.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model/list/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelListDtoFromJSON(jsonValue))
  }

  /**
   * List models data of this source
   */
  async modelControllerListSourceModels(
    requestParameters: ModelControllerListSourceModelsRequest,
  ): Promise<ModelListDto> {
    const response = await this.modelControllerListSourceModelsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Drop all models and re-create them from data
   * Reset to default timelight models configuration
   */
  async modelControllerResetToDefaultModelsRaw(
    requestParameters: ModelControllerResetToDefaultModelsRequest,
  ): Promise<runtime.ApiResponse<ModelListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling modelControllerResetToDefaultModels.",
      )
    }

    if (requestParameters.modelCount === null || requestParameters.modelCount === undefined) {
      throw new runtime.RequiredError(
        "modelCount",
        "Required parameter requestParameters.modelCount was null or undefined when calling modelControllerResetToDefaultModels.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model/reset/{sourceId}/{modelCount}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"modelCount"}}`, encodeURIComponent(String(requestParameters.modelCount))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelListDtoFromJSON(jsonValue))
  }

  /**
   * Drop all models and re-create them from data
   * Reset to default timelight models configuration
   */
  async modelControllerResetToDefaultModels(
    requestParameters: ModelControllerResetToDefaultModelsRequest,
  ): Promise<ModelListDto> {
    const response = await this.modelControllerResetToDefaultModelsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Create many custom models at once, this config replaces the original models.        WARNING: This action will remove all previsions and alerts.
   * Custom Model create
   */
  async modelControllerUseCustomModelsRaw(
    requestParameters: ModelControllerUseCustomModelsRequest,
  ): Promise<runtime.ApiResponse<ModelListDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling modelControllerUseCustomModels.",
      )
    }

    if (requestParameters.modelsPostDto === null || requestParameters.modelsPostDto === undefined) {
      throw new runtime.RequiredError(
        "modelsPostDto",
        "Required parameter requestParameters.modelsPostDto was null or undefined when calling modelControllerUseCustomModels.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model/bulk-replace/{sourceId}`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsPostDtoToJSON(requestParameters.modelsPostDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelListDtoFromJSON(jsonValue))
  }

  /**
   * Create many custom models at once, this config replaces the original models.        WARNING: This action will remove all previsions and alerts.
   * Custom Model create
   */
  async modelControllerUseCustomModels(
    requestParameters: ModelControllerUseCustomModelsRequest,
  ): Promise<ModelListDto> {
    const response = await this.modelControllerUseCustomModelsRaw(requestParameters)
    return await response.value()
  }
}
