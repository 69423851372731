/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SerieAggregationEnum {
  Mean = "mean",
  Sum = "sum",
  Min = "min",
  Max = "max",
}

export function SerieAggregationEnumFromJSON(json: any): SerieAggregationEnum {
  return SerieAggregationEnumFromJSONTyped(json, false)
}

export function SerieAggregationEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerieAggregationEnum {
  return json as SerieAggregationEnum
}

export function SerieAggregationEnumToJSON(value?: SerieAggregationEnum | null): any {
  return value as any
}
