import { Divider, FormControl, FormHelperText, InputLabel, OutlinedInput } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import AppLink from "component/AppLink"
import { Formik } from "formik"
import React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { AppRoute } from "state/route"
import MadeWithLove from "../../component/MadeWithLove"
import { useConfig } from "../../config"
import { useAuth } from "../../state/auth"
import { AppButton } from "../../component/AppButton"
import { SocialLoginButtons } from "../../component/SocialLoginButtons"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login-bg.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function Login() {
  const { login } = useAuth()
  const classes = useStyles()
  const { t } = useTranslation()
  const { USER_SIGN_UP_ENABLED } = useConfig()

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Helmet>
            <title>{t("global.appTitle")} - Connexion</title>
          </Helmet>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h6">
            {t("global.appTitle")} - Connexion
          </Typography>
          <Formik
            initialValues={{ username: "", password: "" }}
            onSubmit={async (values, { setErrors }) => {
              try {
                await login(values)
              } catch (e) {
                if (e instanceof Response) {
                  setErrors({
                    password: "Erreur d'authentification, veuillez réessayer",
                  })
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Identifiant ou email"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  error={!!errors.password}
                />

                <FormControl required variant="outlined" error={!!errors.password} fullWidth>
                  <InputLabel style={{ backgroundColor: "#FFF", padding: "0 3px" }} htmlFor="password">
                    Mot de passe
                  </InputLabel>
                  <OutlinedInput
                    required
                    id="password"
                    name="password"
                    type="password"
                    labelWidth={0}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password && <FormHelperText>{errors.password}</FormHelperText>}
                </FormControl>
                <AppButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  submitting={isSubmitting}
                >
                  Connexion
                </AppButton>
              </form>
            )}
          </Formik>
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Divider style={{ border: "1px #CCC solid", width: "50%" }} />
          </div>
          <SocialLoginButtons mode="login" />
          {USER_SIGN_UP_ENABLED && (
            <div>
              <span>Pas encore de compte ? </span>
              <AppLink linkStyle={true} route={AppRoute.REGISTER}>
                Créer mon compte
              </AppLink>
            </div>
          )}
          <Box mt={5}>
            <MadeWithLove />
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}
