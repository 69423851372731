/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  CreateSourceDto,
  CreateSourceDtoFromJSON,
  CreateSourceDtoToJSON,
  CreateSourceResultDto,
  CreateSourceResultDtoFromJSON,
  CreateSourceResultDtoToJSON,
  ImportDaysDto,
  ImportDaysDtoFromJSON,
  ImportDaysDtoToJSON,
  ImportDaysResultDto,
  ImportDaysResultDtoFromJSON,
  ImportDaysResultDtoToJSON,
} from "../models"

export interface ImportControllerCreateSourceRequest {
  createSourceDto: CreateSourceDto
}

export interface ImportControllerImportDayDataRequest {
  importDaysDto: ImportDaysDto
}

export interface ImportControllerImportDayDataSourceIdRequest {
  sourceId: number
  importDaysDto: ImportDaysDto
}

export interface ImportControllerReprocessDayDataRequest {
  sourceId: number
  year: number
}

/**
 * no description
 */
export class ImportApi extends runtime.BaseAPI {
  /**
   * Creates a source, add a first batch of day data, then computes the models for the first time.     This endpoint is for large time steps, please use /v1/import-csv/create-source endpoint for smaller time steps
   * First source creation
   */
  async importControllerCreateSourceRaw(
    requestParameters: ImportControllerCreateSourceRequest,
  ): Promise<runtime.ApiResponse<CreateSourceResultDto>> {
    if (requestParameters.createSourceDto === null || requestParameters.createSourceDto === undefined) {
      throw new runtime.RequiredError(
        "createSourceDto",
        "Required parameter requestParameters.createSourceDto was null or undefined when calling importControllerCreateSource.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import/create-source`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CreateSourceDtoToJSON(requestParameters.createSourceDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => CreateSourceResultDtoFromJSON(jsonValue))
  }

  /**
   * Creates a source, add a first batch of day data, then computes the models for the first time.     This endpoint is for large time steps, please use /v1/import-csv/create-source endpoint for smaller time steps
   * First source creation
   */
  async importControllerCreateSource(
    requestParameters: ImportControllerCreateSourceRequest,
  ): Promise<CreateSourceResultDto> {
    const response = await this.importControllerCreateSourceRaw(requestParameters)
    return await response.value()
  }

  /**
   * When new data is added, we compute alerts for this data
   * Add new data to a source
   */
  async importControllerImportDayDataRaw(
    requestParameters: ImportControllerImportDayDataRequest,
  ): Promise<runtime.ApiResponse<ImportDaysResultDto>> {
    if (requestParameters.importDaysDto === null || requestParameters.importDaysDto === undefined) {
      throw new runtime.RequiredError(
        "importDaysDto",
        "Required parameter requestParameters.importDaysDto was null or undefined when calling importControllerImportDayData.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import/days`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ImportDaysDtoToJSON(requestParameters.importDaysDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ImportDaysResultDtoFromJSON(jsonValue))
  }

  /**
   * When new data is added, we compute alerts for this data
   * Add new data to a source
   */
  async importControllerImportDayData(
    requestParameters: ImportControllerImportDayDataRequest,
  ): Promise<ImportDaysResultDto> {
    const response = await this.importControllerImportDayDataRaw(requestParameters)
    return await response.value()
  }

  /**
   * When new data is added, we compute alerts for this data
   * Add new data to a source
   */
  async importControllerImportDayDataSourceIdRaw(
    requestParameters: ImportControllerImportDayDataSourceIdRequest,
  ): Promise<runtime.ApiResponse<ImportDaysResultDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling importControllerImportDayDataSourceId.",
      )
    }

    if (requestParameters.importDaysDto === null || requestParameters.importDaysDto === undefined) {
      throw new runtime.RequiredError(
        "importDaysDto",
        "Required parameter requestParameters.importDaysDto was null or undefined when calling importControllerImportDayDataSourceId.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import/{sourceId}/days`.replace(
        `{${"sourceId"}}`,
        encodeURIComponent(String(requestParameters.sourceId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ImportDaysDtoToJSON(requestParameters.importDaysDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ImportDaysResultDtoFromJSON(jsonValue))
  }

  /**
   * When new data is added, we compute alerts for this data
   * Add new data to a source
   */
  async importControllerImportDayDataSourceId(
    requestParameters: ImportControllerImportDayDataSourceIdRequest,
  ): Promise<ImportDaysResultDto> {
    const response = await this.importControllerImportDayDataSourceIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * Compute maps, alerts and closest models
   * Reprocess days from database
   */
  async importControllerReprocessDayDataRaw(
    requestParameters: ImportControllerReprocessDayDataRequest,
  ): Promise<runtime.ApiResponse<ImportDaysResultDto>> {
    if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
      throw new runtime.RequiredError(
        "sourceId",
        "Required parameter requestParameters.sourceId was null or undefined when calling importControllerReprocessDayData.",
      )
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling importControllerReprocessDayData.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import/reprocess-days/{sourceId}/{year}`
        .replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId)))
        .replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ImportDaysResultDtoFromJSON(jsonValue))
  }

  /**
   * Compute maps, alerts and closest models
   * Reprocess days from database
   */
  async importControllerReprocessDayData(
    requestParameters: ImportControllerReprocessDayDataRequest,
  ): Promise<ImportDaysResultDto> {
    const response = await this.importControllerReprocessDayDataRaw(requestParameters)
    return await response.value()
  }
}
