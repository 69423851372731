/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  ForecastAlert,
  ForecastAlertFromJSON,
  ForecastAlertToJSON,
  ForecastTask,
  ForecastTaskFromJSON,
  ForecastTaskToJSON,
  ForecastTaskInputParams,
  ForecastTaskInputParamsFromJSON,
  ForecastTaskInputParamsToJSON,
  GetManyForecastAlertResponseDto,
  GetManyForecastAlertResponseDtoFromJSON,
  GetManyForecastAlertResponseDtoToJSON,
  GetManyForecastTaskResponseDto,
  GetManyForecastTaskResponseDtoFromJSON,
  GetManyForecastTaskResponseDtoToJSON,
} from "../models"

export interface ForecastTaskControllerCreateOneRequest {
  forecastTaskInputParams: ForecastTaskInputParams
}

export interface ForecastTaskControllerDeleteOneRequest {
  forecastTaskId: number
}

export interface ForecastTaskControllerUpdateOneRequest {
  forecastTaskId: number
  forecastTaskInputParams: ForecastTaskInputParams
}

export interface GetManyBaseForecastAlertControllerForecastAlertRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetManyBaseForecastTaskControllerForecastTaskRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseForecastAlertControllerForecastAlertRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface GetOneBaseForecastTaskControllerForecastTaskRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

/**
 * no description
 */
export class ForecastApi extends runtime.BaseAPI {
  /**
   * Create a new forecast task
   */
  async forecastTaskControllerCreateOneRaw(
    requestParameters: ForecastTaskControllerCreateOneRequest,
  ): Promise<runtime.ApiResponse<ForecastTask>> {
    if (requestParameters.forecastTaskInputParams === null || requestParameters.forecastTaskInputParams === undefined) {
      throw new runtime.RequiredError(
        "forecastTaskInputParams",
        "Required parameter requestParameters.forecastTaskInputParams was null or undefined when calling forecastTaskControllerCreateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/forecast-task`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ForecastTaskInputParamsToJSON(requestParameters.forecastTaskInputParams),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ForecastTaskFromJSON(jsonValue))
  }

  /**
   * Create a new forecast task
   */
  async forecastTaskControllerCreateOne(
    requestParameters: ForecastTaskControllerCreateOneRequest,
  ): Promise<ForecastTask> {
    const response = await this.forecastTaskControllerCreateOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * delete a forecast task
   */
  async forecastTaskControllerDeleteOneRaw(
    requestParameters: ForecastTaskControllerDeleteOneRequest,
  ): Promise<runtime.ApiResponse<ForecastTask>> {
    if (requestParameters.forecastTaskId === null || requestParameters.forecastTaskId === undefined) {
      throw new runtime.RequiredError(
        "forecastTaskId",
        "Required parameter requestParameters.forecastTaskId was null or undefined when calling forecastTaskControllerDeleteOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/forecast-task/{forecastTaskId}`.replace(
        `{${"forecastTaskId"}}`,
        encodeURIComponent(String(requestParameters.forecastTaskId)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ForecastTaskFromJSON(jsonValue))
  }

  /**
   * delete a forecast task
   */
  async forecastTaskControllerDeleteOne(
    requestParameters: ForecastTaskControllerDeleteOneRequest,
  ): Promise<ForecastTask> {
    const response = await this.forecastTaskControllerDeleteOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update a forecast task params and re-run forecast, wipes out previous data
   */
  async forecastTaskControllerUpdateOneRaw(
    requestParameters: ForecastTaskControllerUpdateOneRequest,
  ): Promise<runtime.ApiResponse<ForecastTask>> {
    if (requestParameters.forecastTaskId === null || requestParameters.forecastTaskId === undefined) {
      throw new runtime.RequiredError(
        "forecastTaskId",
        "Required parameter requestParameters.forecastTaskId was null or undefined when calling forecastTaskControllerUpdateOne.",
      )
    }

    if (requestParameters.forecastTaskInputParams === null || requestParameters.forecastTaskInputParams === undefined) {
      throw new runtime.RequiredError(
        "forecastTaskInputParams",
        "Required parameter requestParameters.forecastTaskInputParams was null or undefined when calling forecastTaskControllerUpdateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/forecast-task/{forecastTaskId}`.replace(
        `{${"forecastTaskId"}}`,
        encodeURIComponent(String(requestParameters.forecastTaskId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ForecastTaskInputParamsToJSON(requestParameters.forecastTaskInputParams),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ForecastTaskFromJSON(jsonValue))
  }

  /**
   * Update a forecast task params and re-run forecast, wipes out previous data
   */
  async forecastTaskControllerUpdateOne(
    requestParameters: ForecastTaskControllerUpdateOneRequest,
  ): Promise<ForecastTask> {
    const response = await this.forecastTaskControllerUpdateOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve many ForecastAlert
   */
  async getManyBaseForecastAlertControllerForecastAlertRaw(
    requestParameters: GetManyBaseForecastAlertControllerForecastAlertRequest,
  ): Promise<runtime.ApiResponse<GetManyForecastAlertResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/forecast-alert`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyForecastAlertResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many ForecastAlert
   */
  async getManyBaseForecastAlertControllerForecastAlert(
    requestParameters: GetManyBaseForecastAlertControllerForecastAlertRequest,
  ): Promise<GetManyForecastAlertResponseDto> {
    const response = await this.getManyBaseForecastAlertControllerForecastAlertRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve many ForecastTask
   */
  async getManyBaseForecastTaskControllerForecastTaskRaw(
    requestParameters: GetManyBaseForecastTaskControllerForecastTaskRequest,
  ): Promise<runtime.ApiResponse<GetManyForecastTaskResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/forecast-task`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyForecastTaskResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many ForecastTask
   */
  async getManyBaseForecastTaskControllerForecastTask(
    requestParameters: GetManyBaseForecastTaskControllerForecastTaskRequest,
  ): Promise<GetManyForecastTaskResponseDto> {
    const response = await this.getManyBaseForecastTaskControllerForecastTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one ForecastAlert
   */
  async getOneBaseForecastAlertControllerForecastAlertRaw(
    requestParameters: GetOneBaseForecastAlertControllerForecastAlertRequest,
  ): Promise<runtime.ApiResponse<ForecastAlert>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseForecastAlertControllerForecastAlert.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/forecast-alert/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ForecastAlertFromJSON(jsonValue))
  }

  /**
   * Retrieve one ForecastAlert
   */
  async getOneBaseForecastAlertControllerForecastAlert(
    requestParameters: GetOneBaseForecastAlertControllerForecastAlertRequest,
  ): Promise<ForecastAlert> {
    const response = await this.getOneBaseForecastAlertControllerForecastAlertRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one ForecastTask
   */
  async getOneBaseForecastTaskControllerForecastTaskRaw(
    requestParameters: GetOneBaseForecastTaskControllerForecastTaskRequest,
  ): Promise<runtime.ApiResponse<ForecastTask>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseForecastTaskControllerForecastTask.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/forecast-task/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ForecastTaskFromJSON(jsonValue))
  }

  /**
   * Retrieve one ForecastTask
   */
  async getOneBaseForecastTaskControllerForecastTask(
    requestParameters: GetOneBaseForecastTaskControllerForecastTaskRequest,
  ): Promise<ForecastTask> {
    const response = await this.getOneBaseForecastTaskControllerForecastTaskRaw(requestParameters)
    return await response.value()
  }
}
