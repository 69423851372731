/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  LoginDto,
  LoginDtoFromJSON,
  LoginDtoToJSON,
  LoginResponseDto,
  LoginResponseDtoFromJSON,
  LoginResponseDtoToJSON,
  RegisterDto,
  RegisterDtoFromJSON,
  RegisterDtoToJSON,
  UserDto,
  UserDtoFromJSON,
  UserDtoToJSON,
} from "../models"

export interface UserControllerGoogleAuthRedirectRequest {
  code?: string
  scope?: string
  authuser?: string
  hd?: string
  prompt?: string
}

export interface UserControllerLinkedinAuthRedirectRequest {
  code?: string
}

export interface UserControllerLoginRequest {
  loginDto: LoginDto
}

export interface UserControllerRegisterRequest {
  registerDto: RegisterDto
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {
  /**
   */
  async userControllerGoogleAuthRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/v1/user/google/auth`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async userControllerGoogleAuth(): Promise<void> {
    await this.userControllerGoogleAuthRaw()
  }

  /**
   */
  async userControllerGoogleAuthRedirectRaw(
    requestParameters: UserControllerGoogleAuthRedirectRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code
    }

    if (requestParameters.scope !== undefined) {
      queryParameters["scope"] = requestParameters.scope
    }

    if (requestParameters.authuser !== undefined) {
      queryParameters["authuser"] = requestParameters.authuser
    }

    if (requestParameters.hd !== undefined) {
      queryParameters["hd"] = requestParameters.hd
    }

    if (requestParameters.prompt !== undefined) {
      queryParameters["prompt"] = requestParameters.prompt
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/v1/user/google/redirect`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async userControllerGoogleAuthRedirect(requestParameters: UserControllerGoogleAuthRedirectRequest): Promise<void> {
    await this.userControllerGoogleAuthRedirectRaw(requestParameters)
  }

  /**
   */
  async userControllerLinkedinAuthRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/v1/user/linkedin/auth`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async userControllerLinkedinAuth(): Promise<void> {
    await this.userControllerLinkedinAuthRaw()
  }

  /**
   */
  async userControllerLinkedinAuthRedirectRaw(
    requestParameters: UserControllerLinkedinAuthRedirectRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/v1/user/linkedin/redirect`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async userControllerLinkedinAuthRedirect(
    requestParameters: UserControllerLinkedinAuthRedirectRequest,
  ): Promise<void> {
    await this.userControllerLinkedinAuthRedirectRaw(requestParameters)
  }

  /**
   * This endpoints returns the jwt and sets a cookie with the same jwt.      This way you can use it from both an api and a browser
   * Log the user in
   */
  async userControllerLoginRaw(
    requestParameters: UserControllerLoginRequest,
  ): Promise<runtime.ApiResponse<LoginResponseDto>> {
    if (requestParameters.loginDto === null || requestParameters.loginDto === undefined) {
      throw new runtime.RequiredError(
        "loginDto",
        "Required parameter requestParameters.loginDto was null or undefined when calling userControllerLogin.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    const response = await this.request({
      path: `/v1/user/login`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: LoginDtoToJSON(requestParameters.loginDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseDtoFromJSON(jsonValue))
  }

  /**
   * This endpoints returns the jwt and sets a cookie with the same jwt.      This way you can use it from both an api and a browser
   * Log the user in
   */
  async userControllerLogin(requestParameters: UserControllerLoginRequest): Promise<LoginResponseDto> {
    const response = await this.userControllerLoginRaw(requestParameters)
    return await response.value()
  }

  /**
   * Must be authenticated to call this endpoint
   * Retrieve current user information
   */
  async userControllerMeRaw(): Promise<runtime.ApiResponse<UserDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/user/me`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue))
  }

  /**
   * Must be authenticated to call this endpoint
   * Retrieve current user information
   */
  async userControllerMe(): Promise<UserDto> {
    const response = await this.userControllerMeRaw()
    return await response.value()
  }

  /**
   */
  async userControllerRegisterRaw(
    requestParameters: UserControllerRegisterRequest,
  ): Promise<runtime.ApiResponse<LoginResponseDto>> {
    if (requestParameters.registerDto === null || requestParameters.registerDto === undefined) {
      throw new runtime.RequiredError(
        "registerDto",
        "Required parameter requestParameters.registerDto was null or undefined when calling userControllerRegister.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    const response = await this.request({
      path: `/v1/user/register`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: RegisterDtoToJSON(requestParameters.registerDto),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseDtoFromJSON(jsonValue))
  }

  /**
   */
  async userControllerRegister(requestParameters: UserControllerRegisterRequest): Promise<LoginResponseDto> {
    const response = await this.userControllerRegisterRaw(requestParameters)
    return await response.value()
  }
}
