import finalFormCalculate from "final-form-calculate"
import { Decorator } from "final-form"
import { FieldInputProps } from "formik"
import { FieldMetaState } from "react-final-form"
import * as lodash from "lodash"
import { getUniqueErrorsId, getUniqueObjectId } from "../../lib/uniq"

export type DecoratorConfig<FormState, KeyOfFieldInFormState> = KeyOfFieldInFormState extends keyof FormState
  ? {
      // on update on this field
      field: KeyOfFieldInFormState
      // do these updates
      updates: {
        [fieldToUpdate in keyof FormState]: (
          values: FormState[KeyOfFieldInFormState],
          allValues: FormState,
        ) => FormState[fieldToUpdate] | Promise<FormState[fieldToUpdate]>
      }
      isEqual?: (a: FormState[KeyOfFieldInFormState], b: FormState[KeyOfFieldInFormState]) => boolean
    }
  : never

export function createCalculator<T>(...params: DecoratorConfig<T, any>[]): Decorator<T> {
  // @ts-ignore
  return finalFormCalculate(
    // @ts-ignore
    ...params.map((decorator) => ({
      ...decorator,
      // decorate the updaters with a test to avoid
      // running updaters on form initialization
      // https://github.com/final-form/final-form-calculate/issues/26
      updates: Object.entries(decorator.updates).reduce(
        (agg, [fieldToUpdate, updater]) =>
          Object.assign(agg, {
            [fieldToUpdate]: (values: any, allValues: any, prevValues: any) => {
              if (prevValues[decorator.field] === undefined) {
                return allValues[fieldToUpdate]
              }
              return (updater as any)(values, allValues, prevValues)
            },
          }),
        {},
      ),

      // default to lodash.isEqual
      isEqual:
        decorator.isEqual ||
        ((a, b) => {
          return lodash.isEqual(a, b)
        }),
    })),
  )
}

export type CustomFieldRenderProps<FieldValue, OtherProps> = {
  input: FieldInputProps<FieldValue>
  meta: FieldMetaState<FieldValue>
} & OtherProps

type DecoratorUpdateFn<
  FormState,
  KeyOfFieldInFormState,
  KeyOfFieldToUpdate
> = KeyOfFieldInFormState extends keyof FormState
  ? KeyOfFieldToUpdate extends keyof FormState
    ? (
        values: FormState[KeyOfFieldInFormState],
        allValues: FormState,
      ) => FormState[KeyOfFieldToUpdate] | Promise<FormState[KeyOfFieldToUpdate]>
    : never
  : never

export async function applyCalculatorParams<FormState, UpdatedKeyName extends keyof FormState>(
  formState: FormState,
  calculatorsParam: DecoratorConfig<FormState, any>[],
  updatedField: UpdatedKeyName,
  updatedValue: Partial<FormState[UpdatedKeyName]>,
): Promise<FormState> {
  let currentFormState = formState
  // apply change to form
  currentFormState = {
    ...currentFormState,
    [updatedField]: { ...currentFormState[updatedField], ...updatedValue },
  }
  for (const calculatorParam of calculatorsParam) {
    if (calculatorParam.field === updatedField) {
      const fieldsToUpdate = Object.keys(calculatorParam.updates)
      const newValue = currentFormState[updatedField]
      for (const fieldToUpdate of fieldsToUpdate) {
        // @ts-ignore
        const updaterFunction = (calculatorParam.updates[fieldToUpdate] as unknown) as DecoratorUpdateFn<
          FormState,
          any,
          any
        >

        const newFieldState = await Promise.resolve(updaterFunction(newValue, currentFormState))
        currentFormState = lodash.merge(currentFormState, { [fieldToUpdate]: newFieldState })
      }
    }
  }
  return currentFormState
}

export function getFormFieldPropsMemoizeKey<FormState>(props: CustomFieldRenderProps<FormState, any>): string {
  return [
    props.input.name,
    props.label,
    props.required,
    props.meta.submitting,
    getUniqueErrorsId(props.meta.error),
    getUniqueObjectId(props.meta.submitting),
    getUniqueObjectId(props.input.onChange),
  ].join("|")
}
