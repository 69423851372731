/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
  /**
   *
   * @type {number}
   * @memberof UserDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  email: string
  /**
   * User unique login
   * @type {string}
   * @memberof UserDto
   */
  username: string
  /**
   * User display name
   * @type {string}
   * @memberof UserDto
   */
  displayName: string
}

export function UserDtoFromJSON(json: any): UserDto {
  return UserDtoFromJSONTyped(json, false)
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    email: json["email"],
    username: json["username"],
    displayName: json["displayName"],
  }
}

export function UserDtoToJSON(value?: UserDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    email: value.email,
    username: value.username,
    displayName: value.displayName,
  }
}
