/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { ModelPatchDto, ModelPatchDtoFromJSON, ModelPatchDtoFromJSONTyped, ModelPatchDtoToJSON } from "./"

/**
 *
 * @export
 * @interface ModelsPatchDto
 */
export interface ModelsPatchDto {
  /**
   * The list of models to be updated
   * @type {Array<ModelPatchDto>}
   * @memberof ModelsPatchDto
   */
  models: Array<ModelPatchDto>
}

export function ModelsPatchDtoFromJSON(json: any): ModelsPatchDto {
  return ModelsPatchDtoFromJSONTyped(json, false)
}

export function ModelsPatchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelsPatchDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    models: (json["models"] as Array<any>).map(ModelPatchDtoFromJSON),
  }
}

export function ModelsPatchDtoToJSON(value?: ModelsPatchDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    models: (value.models as Array<any>).map(ModelPatchDtoToJSON),
  }
}
