import { Tooltip } from "@material-ui/core"
import AnomalyIcon from "@material-ui/icons/ErrorOutline"
import React from "react"

export default function AlertAnomaly({ alert, large = false }: { alert: { anomaly: boolean }; large?: boolean }) {
  const text = alert.anomaly ? "Anomalie détéctée" : "Pas d'anomalie détectée"
  return (
    <div
      style={{
        color: alert.anomaly ? "#e05757" : "#ccc",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: alert.anomaly ? "#e05757" : "#ccc",
          fontSize: "30px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip title={text}>
          <AnomalyIcon color="inherit" fontSize="inherit" />
        </Tooltip>
      </div>
      {large && <div style={{ marginLeft: 5 }}>{text}</div>}
    </div>
  )
}
