import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import AppHeader from "../component/AppHeader"
import AppLink from "../component/AppLink"
import { GradientBackground } from "../component/GradientBackground"
import PageContainer from "../component/PageContainer"
import PageContent from "../component/PageContent"
import { AppRoute } from "../state/route"
import { useTheme } from "../theme"
import { AppButton } from "../component/AppButton"

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(4),
    minHeight: "80vh",
  },
  outerBox: { maxWidth: "70%", margin: "auto", padding: theme.spacing(4) },
  introText: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(40),
    lineHeight: 1,
  },
}))

export default function Page404() {
  const themeObj = useTheme()
  const classes = useStyles()
  return (
    <PageContainer title="Page non trouvée">
      <AppHeader logo={true} color={false} openable={false} />
      <PageContent padding={false}>
        <GradientBackground
          base={themeObj.palette.primary.main}
          darker={themeObj.palette.primary.dark}
          className={classes.background}
        >
          <Paper className={classes.outerBox}>
            <Grid container spacing={3} className={classes.introText}>
              <Grid item xs={12}>
                <Typography>Cette page n'existe pas - erreur 404</Typography>
                <br />
                <Typography>
                  <AppLink route={AppRoute.HOME}>
                    <AppButton color="primary" variant="outlined">
                      Revenir à l'écran d'accueil.
                    </AppButton>
                  </AppLink>{" "}
                </Typography>
                <br />
              </Grid>
            </Grid>
          </Paper>
        </GradientBackground>
      </PageContent>
    </PageContainer>
  )
}
