import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import Select from "react-select"
import { ValueType } from "react-select/src/types"

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 120,
    margin: theme.spacing(1),
    color: "black",
    cursor: "pointer",
  },
}))

export default function YearSelect({
  year,
  setYear,
  years,
}: {
  year: number
  setYear: (year: number) => void
  years: { from: number; to: number } | number[]
}) {
  const classes = useStyles()

  let selectYears: number[] = []
  if (Array.isArray(years)) {
    selectYears = years
  } else {
    if (years.to < years.from) {
      throw new Error(`Can't generate a select between ${years.from} and ${years.to}`)
    }
    selectYears = Array.from({ length: years.to - years.from + 1 }, (_, i) => i + years.from)
  }

  return (
    <div className={classes.container}>
      <Select
        onChange={(val: ValueType<{ value: number; label: number }>) => {
          if (!val) {
            throw new Error("No year selected")
          }
          if (Array.isArray(val)) {
            throw new Error("Multi-value unexpected")
          }
          const selectedId = ((val as unknown) as {
            value: number
            label: number
          }).value
          const newYear = selectYears.find((y) => y === selectedId)
          if (!newYear) {
            throw new Error("Could not find selected year")
          }
          setYear(newYear)
        }}
        value={{ label: year, value: year }}
        options={selectYears.map((i) => ({ label: i, value: i }))}
      />
    </div>
  )
}
