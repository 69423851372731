import React from "react"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PageBlock from "component/PageBlock"
import AppHeader from "../../component/AppHeader"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { useApiClient } from "../../state/api"
import { toDateObj, formatDuration } from "../../lib/date"
import { MaterialTable } from "../../component/MaterialTable"
import {
  createDateColumnConfig,
  createTypeORMCrudSort,
  createSourceIdColumnConfig,
  createTextColumnConfig,
  extractColumnConfigAndExportConfig,
  fetchAndAddSourcesToRows,
} from "../../component/MaterialTable/material-table-helpers"
import { SourceImportHistory } from "api/timelight-api"
import {
  createTypeORMCrudPage,
  Query,
  createTableExportCsv,
} from "../../component/MaterialTable/material-table-helpers"
import { EmptyStateRedirect } from "../../component/EmptyStateRedirect"
import { AppRoute } from "../../state/route"
import {
  RowWithSource,
  createTypeORMCrudFilter,
  createTypeORMCrudLimit,
} from "../../component/MaterialTable/material-table-helpers"
import { QueryResult } from "material-table"

export default function SourceImportHistoryPage() {
  // the app bar for values
  return (
    <PageContainer title={`Contrôle de l'historique des imports`}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 15,
            }}
          >
            Contrôle de l'historique des imports de données
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SourceImportHistoryDataTable />
      </PageContent>
    </PageContainer>
  )
}

/* Make it a separate memoized component to avoid re-rendering on select
   this will empty the filters
 */
const SourceImportHistoryDataTable = React.memo(function SourceImportHistoryDataTableComponent() {
  const api = useApiClient()
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false)

  type RowType = RowWithSource<SourceImportHistory>

  const { columns, exportConfig } = extractColumnConfigAndExportConfig<RowType>([
    createDateColumnConfig({
      title: "Date de l'import",
      getDate: (row) => row.execStartDate,
      field: "execStartDate",
    }),
    createTextColumnConfig({
      title: "Durée de l'import",
      getTextValue: (row) =>
        formatDuration(toDateObj(row.execEndDate).getTime() - toDateObj(row.execStartDate).getTime()),
    }),
    createSourceIdColumnConfig({
      title: "Source impactée",
      getTextValue: (row) => row.source.name,
      field: "sourceId",
    }),
    createTextColumnConfig({
      title: "Nombre de points",
      getTextValue: (row) => row.dataPointCount.toString(),
      field: "dataPointCount",
    }),
    createDateColumnConfig({
      title: "Début de période",
      getDate: (row) => row.dataStartDate,
      field: "dataStartDate",
    }),
    createDateColumnConfig({
      title: "Fin de période",
      getDate: (row) => row.dataEndDate,
      field: "dataEndDate",
    }),
  ])

  async function getData(query: Query<RowType>): Promise<QueryResult<RowType>> {
    const res = await api.getManyBaseSourceImportHistoryControllerSourceImportHistory({
      limit: createTypeORMCrudLimit(query),
      page: createTypeORMCrudPage(query),
      sort: createTypeORMCrudSort(query),
      filter: createTypeORMCrudFilter(query),
    })
    if (query.filters.length === 0 && query.search.length === 0 && res.count === 0) {
      setIsEmpty(true)
    }
    return {
      page: query.page,
      totalCount: res.total,
      data: await fetchAndAddSourcesToRows(api, res.data, (row) => row.sourceId),
    }
  }
  return (
    <>
      {isEmpty ? (
        <EmptyStateRedirect
          route={AppRoute.SOURCE_ADD_DAYS_HUB}
          actionText={"Ajouter des données à une source"}
          headerText={"Bienvenue dans le module d'audit d'import de données"}
          helpText={`Tous les nouveaux imports de données seront consolidés à cet endroit
           pour vous permettre d'auditer l'import de vos sources`}
        />
      ) : (
        <PageBlock titleInContent={true}>
          <MaterialTable<RowType>
            title={`Historique des imports de données`}
            data={getData}
            columns={columns}
            options={{
              search: false,
              filtering: true,
              exportCsv: createTableExportCsv({
                exportConfig,
                fileName: `source_import_history.csv`,
                getData,
              }),
            }}
          />
        </PageBlock>
      )}
    </>
  )
})
