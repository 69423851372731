import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import DemoIcon from "@material-ui/icons/CloudDoneOutlined"
import UploadIcon from "@material-ui/icons/CloudUpload"
import ApiIcon from "@material-ui/icons/Code"
import clsx from "clsx"
import PageBlock from "component/PageBlock"
import React from "react"
import { useTranslation } from "react-i18next"
import AppHeader from "../../component/AppHeader"
import AppLink from "../../component/AppLink"
import { LinkCss } from "../../component/AppLink"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import { useConfig } from "../../config"
import { AppRoute } from "../../state/route"
import SideMenu from "component/SideMenu"
import { useOnboardingState } from "../../state/onboarding"

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(4),
    minHeight: "80vh",
  },
  introText: {
    textAlign: "center",
    lineHeight: 1,
  },
  optionBlock: {
    width: "250px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "4em",
    marginBottom: "2em",
    minWidth: "200px",
    boxSizing: "border-box",
    backgroundColor: "rgba(247, 248, 250, 0.19)",
    boxShadow: "1px 3px 12px -5px " + theme.palette.primary.light,
    borderRadius: "5px",
    transition: "color 100ms ease, background-color 100ms ease, box-shadow 200ms ease",
    // backgroundColor: "black",
    "&:hover": {
      boxShadow: "2px 5px 28px 1px " + theme.palette.primary.main,
    },
  },
  optionBlockIcon: {
    color: theme.palette.primary.main,
    width: "2em",
    height: "2em",
  },
  optionBlockImage: {
    marginTop: "1em",
    width: "100px",
    minWidth: "100px",
    height: "30px",
    minHeight: "30px",
  },
}))

export default function SourceCreateHub() {
  const classes = useStyles()
  const { API_URL } = useConfig()
  const { t } = useTranslation()
  const { hasSource } = useOnboardingState()

  return (
    <PageContainer title="Ajouter des données">
      <AppHeader color={true} openable={true} />
      <SideMenu />
      <PageContent noMenu={true}>
        <PageBlock title={`Création de ${t("source.name")}.`}>
          {hasSource === false && (
            <p style={{ fontSize: "1.3em" }}>
              Bienvenue dans la plateforme Timelight, pour commencer à utiliser les différents outils, veuillez
              commencer par créer une source
            </p>
          )}
          <p style={{ fontSize: "1.3em" }}>
            Commencez par ajouter une source en utilisant une des méthodes ci-dessous:
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              alignItems: "stretch",
              paddingTop: "4em",
            }}
          >
            <AppLink className={classes.optionBlock} route={AppRoute.SOURCE_CREATE}>
              <Typography>Créer {t("source.onename")} avec un fichier CSV.</Typography>
              <UploadIcon className={classes.optionBlockIcon} />
            </AppLink>

            <AppLink className={classes.optionBlock} route={AppRoute.SOURCE_CREATE_MULTIPLE}>
              <Typography>Créer plusieurs {t("source.names")} avec un fichier CSV multi-valué.</Typography>
              <UploadIcon className={classes.optionBlockIcon} />
            </AppLink>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              alignItems: "stretch",
              paddingTop: "4em",
            }}
          >
            <a
              className={clsx(classes.optionBlock, LinkCss.link)}
              target="_blank"
              rel="noopener noreferrer"
              href={API_URL + "/docs/import-donnees"}
            >
              <Typography>Ajouter une source par API</Typography>
              <ApiIcon className={classes.optionBlockIcon} />
            </a>

            <AppLink className={classes.optionBlock} route={AppRoute.SOURCE_CREATE_EXAMPLE}>
              <Typography>Charger {t("source.onename")} d'exemple.</Typography>
              <DemoIcon className={classes.optionBlockIcon} />
            </AppLink>
          </div>
        </PageBlock>
      </PageContent>
    </PageContainer>
  )
}
