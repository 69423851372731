// tslint:disable:max-line-length
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import * as lodash from "lodash"
import moment from "moment"
import React from "react"
import Calendar, { CalendarProps } from "react-calendar"
import { createStrIdMap } from "../../lib/array"
import { hexToRGBA, isDarkColor } from "../../lib/color"
import { dateObjToDayString } from "../../lib/date"
import { CalendarData, InternalCalendarProps } from "./CalendarData"
import { getActivityColor } from "./util"

const useStyle = makeStyles((theme) => ({
  monthContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
    width: 300,
    margin: "auto",
  },
}))

export default function DayListYearCalendar({
  days,
  models,
  year,
  setSelectedDay,
  content,
  activityColor,
}: InternalCalendarProps) {
  const dayIndex = createStrIdMap<CalendarData>(days, (d) => d.date)
  const dates = Array.from({ length: 12 }, (_, i) => i).map((i) =>
    moment(`${year}-${`${i + 1}`.padStart(2, "0")}-01`, "YYYY-MM-DD"),
  )
  const classes = useStyle()

  const baseProps: Partial<CalendarProps> = {
    showNavigation: false,
    showNeighboringMonth: false,
    showWeekNumbers: true,
    locale: "fr-FR",
    onClickDay: (d) => setSelectedDay(dayIndex[dateObjToDayString(d)]),
    tileDisabled: ({ date: d }) => {
      return !dayIndex[dateObjToDayString(d)]
    },
  }

  const minValue = lodash.min(days.filter((d) => !d.empty).map((d) => d.value)) || 0
  const maxValue = lodash.max(days.filter((d) => !d.empty).map((d) => d.value)) || 200
  return (
    <Grid container alignContent="stretch">
      <style>
        {`
            .react-calendar {
              border: 0;
            }

            .react-calendar .react-calendar__tile--active:hover.day-anomaly, 
            .react-calendar .react-calendar__tile--active:focus.day-anomaly,
            .react-calendar .react-calendar__tile--active.day-anomaly, 
            .react-calendar .day-anomaly {
              border: 1px red solid;
              color: black;
              background: white;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=);
            }

            .react-calendar button.react-calendar__tile--active:hover.day-hashed, 
            .react-calendar button.react-calendar__tile--active:focus.day-hashed,
            .react-calendar button.react-calendar__tile--active.day-hashed, 
            .react-calendar__tile.day-hashed:enabled:hover, 
            .react-calendar__tile.day-hashed:enabled:focus,
            .react-calendar button.day-hashed {
              color: #CCC;
              background: white;
              background-color: unset;
              cursor: default;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=);
            }

            .react-calendar button.react-calendar__tile--active.day-empty,
            .react-calendar button.day-empty {
              background-color: white;
              color: #ccc;
            }
            
            .react-calendar button.react-calendar__tile--active:hover.day-empty, 
            .react-calendar button.react-calendar__tile--active:focus.day-empty,
            .react-calendar__tile.day-empty:enabled:hover, 
            .react-calendar__tile.day-empty:enabled:focus {
              background-color: #e6e6e6;
            }
            
            .react-calendar button.react-calendar__tile--active:hover.day-transparent, 
            .react-calendar button.react-calendar__tile--active:focus.day-transparent,
            .react-calendar button.react-calendar__tile--active.day-transparent, 
            .react-calendar__tile.day-transparent:enabled:hover, 
            .react-calendar__tile.day-transparent:enabled:focus,
            .react-calendar button.day-transparent {
              opacity: 0.4;
            }

            /* fix week numbers on IE */
            .react-calendar .react-calendar__month-view__weekNumbers {
              font-size: 15px;
            }
            .react-calendar .react-calendar__tile {
              padding: 10px;
            }
        `}
      </style>
      {content === "model" && (
        <>
          <style>
            {models.concat([{ id: 0, color: "#FFFFFF" }]).map(
              (m) =>
                `
            .react-calendar .react-calendar__tile--active:hover.day-model-${m.id}, 
            .react-calendar .react-calendar__tile--active:focus.day-model-${m.id},
            .react-calendar .react-calendar__tile--active.day-model-${m.id}, 
            .react-calendar .day-model-${m.id} {
              background-color: ${hexToRGBA(m.color, 0.75)};
              background: ${hexToRGBA(m.color, 0.75)};
              color: ${m.id === 0 ? "red" : isDarkColor(m.color) ? "white" : "black"};
              font-weight: ${m.id === 0 ? "bold" : "normal"};
            }
            `,
            )}
          </style>
          {dates.map((date) => {
            return (
              <Grid key={date.month()} item lg={4} md={4} sm={6} xs={12}>
                <div className={classes.monthContainer}>
                  <p>{date.format("MMMM")}</p>
                  <Calendar
                    {...baseProps}
                    activeStartDate={date.toDate()}
                    tileClassName={({ date: d }) => {
                      const calData = dayIndex[dateObjToDayString(d)]
                      const classNames: string[] = []
                      if (calData) {
                        classNames.push(
                          calData.anomaly
                            ? `day-anomaly`
                            : calData.empty
                            ? `day-empty`
                            : `day-model-${calData.model_id}`,
                        )
                        if (calData.transparent) {
                          classNames.push("day-transparent")
                        }
                      } else {
                        // no data to show -> hash
                        classNames.push("day-hashed")
                      }
                      return classNames.join(" ")
                    }}
                  />
                </div>
              </Grid>
            )
          })}
        </>
      )}

      {content === "activity" && (
        <>
          <style>
            {lodash.uniq(days.map((d) => getActivityColor(minValue, d.value, maxValue, activityColor))).map((color) => {
              const className = `day-activity-${color.replace("#", "")}`
              return `
                  .react-calendar .react-calendar__tile--active:hover.${className}, 
                  .react-calendar .react-calendar__tile--active:focus.${className},
                  .react-calendar .react-calendar__tile--active.${className}, 
                  .react-calendar .${className} {
                    background-color: ${hexToRGBA(color, 0.75)};
                    background: ${hexToRGBA(color, 0.75)};
                    color: ${isDarkColor(color) ? "white" : "black"};
                  }
              `
            })}
          </style>
          {dates.map((date) => {
            return (
              <Grid key={date.month()} item lg={4} md={4} sm={6} xs={12}>
                <div className={classes.monthContainer}>
                  <p>{date.format("MMMM")}</p>
                  <Calendar
                    {...baseProps}
                    activeStartDate={date.toDate()}
                    tileClassName={({ date: d }) => {
                      const calData = dayIndex[dateObjToDayString(d)]
                      const classNames: string[] = []
                      if (calData) {
                        const color = getActivityColor(minValue, calData.value, maxValue, activityColor)
                        const className = `day-activity-${color.replace("#", "")}`
                        classNames.push(calData.anomaly ? `day-anomaly` : calData.empty ? `day-empty` : className)
                        if (calData.transparent) {
                          classNames.push("day-transparent")
                        }
                      } else {
                        // no data to show -> hash
                        classNames.push("day-hashed")
                      }
                      return classNames.join(" ")
                    }}
                  />
                </div>
              </Grid>
            )
          })}
        </>
      )}
    </Grid>
  )
}
